import { TModernSchedulingAction } from '../../types';
import { IDataState, IRequestedUserRoles } from '../IDataState';

export const ActionType = 'ADD_ROLES';

interface IAssignedDataActionPayload {
    userRoles: IRequestedUserRoles[];
}

type TAssignedDataAction = TModernSchedulingAction<IAssignedDataActionPayload>;

const addRoles = (userRoles): TAssignedDataAction => ({
    type: ActionType,
    payload: {
        userRoles,
    },
});
export default addRoles;

export function handle(state: IDataState, { payload }: TAssignedDataAction): IDataState {
    const roles = { ...state.roles };

    payload.userRoles.forEach((roleData) => {
        roles[roleData.role.ID] = roleData.role.name;
    });

    return {
        ...state,
        roles,
    };
}
