import createCachedSelector from 're-reselect';
import { userByIdSelector } from '../../users/usersSelector';
import { stepUnitSelector } from '../../../../dateRange/selectors/stepUnitSelector';
import { startDateSelector } from '../../../../dateRange/selectors/startDateSelector';
import { recalculateDataForActiveView } from '../../../../../util/utilities';

export const availableHoursSelector = createCachedSelector(
    [userByIdSelector, stepUnitSelector, startDateSelector],
    (user, stepUnit, startDate) => {
        return recalculateDataForActiveView(user.availableHoursForPeriod, stepUnit, startDate);
    }
)((state, { userID }) => `${userID}`);
