import { Localization } from '@workfront/localize-react';
import styled, { css } from 'react-emotion';

import { Text, Tooltip, primary } from '@phoenix/all';
import HolidaySmallIcon from 'phoenix-icons/dist/HolidaySmallIcon.js';
import TimeoffSmallIcon from 'phoenix-icons/dist/TimeoffSmallIcon.js';
import { Moment } from 'moment';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { DATE_FORMAT, fullTimeOffTopBorderIndex } from '../../../../constants/dataConstatnts';
import { endDateSelector } from '../../../../data-flow/dateRange/selectors/endDateSelector';
import { startDateSelector } from '../../../../data-flow/dateRange/selectors/startDateSelector';
import { stepUnitSelector } from '../../../../data-flow/dateRange/selectors/stepUnitSelector';
import { cellWidthSelector } from '../../../../data-flow/tableSizes/selectors/cellWidthSelector';
import { IWorkSchedulingCombinedState, UnitOfTime } from '../../../../data-flow/types';
import { inlineItems, inlineItemsCentered } from '../../../../styles';
import { iconsWrapper } from '../../../styles/sharedStyles';
import { calculateLeftPosition, calculateWidth } from '../../../../util/durationStylesCalculation';
import { getTimeOffMessageKey } from '../../../../util/timeOffsService';
import { AllocationTooltipContent } from './AllocationTooltipContent';
import { getPeriodModesValues } from '../../../../util/periodUtils/periodUtils';
import { tableDataIDsSelector } from '../../../../data-flow/data/selectors/tableDataIDsSelector';
import {
    projectGroupingModeSelector,
    showAllocationsVisualizationSelector,
} from '../../../../data-flow/settings/settingsSelector';
import { fullTimeOffsTestIds } from './FullTimeOffs';

interface IFullTimeOffProps {
    isUserTimeOff: boolean | 'both';
    timeOffStartDate: Moment;
    timeOffEndDate: Moment;
    hasAllocation: boolean;
    isInUserRow: boolean;
    idExpression: string;
}

interface IFullTimeOffStateToProps {
    startDate: Moment;
    endDate: Moment;
    cellWidth: number;
    stepUnit: string;
    isAllocationsVisualizationOn: boolean;
    isInProjectGroupingMode: boolean;
    activePeriodMode: UnitOfTime;
    firstUserID: string;
}

interface IFullTimeOffStyled {
    leftPosition: number;
    width: number;
    isSingleDayTimeOff: boolean;
    isInUserRow: boolean;
    idsArray?: string[];
    isInProjectGroupingMode: boolean;
}

interface ITimeOffTextContent {
    isSingleDayTimeOff: boolean;
}

interface ITimeOffText {
    width: number;
}

interface IHolidayIconWrapper {
    isUserTimeOff: boolean | 'both';
}

const FullTimeOffComponent: React.FunctionComponent<IFullTimeOffProps & IFullTimeOffStateToProps> =
    React.memo((props) => {
        const {
            timeOffStartDate,
            timeOffEndDate,
            startDate,
            endDate,
            cellWidth,
            stepUnit,
            isUserTimeOff,
            hasAllocation,
            isInUserRow,
            idExpression,
            isAllocationsVisualizationOn,
            isInProjectGroupingMode,
            activePeriodMode,
            firstUserID,
        } = props;

        const { isWeekMode } = getPeriodModesValues(activePeriodMode);
        const idsArray = useMemo(() => idExpression.split('_'), [idExpression]);
        const isSingleDayTimeOff = !isWeekMode
            ? timeOffStartDate.format(DATE_FORMAT) === timeOffEndDate.format(DATE_FORMAT)
            : timeOffStartDate.format(DATE_FORMAT) ===
              timeOffEndDate.clone().startOf('week').format(DATE_FORMAT);

        let width = calculateWidth(
            stepUnit,
            startDate,
            endDate,
            timeOffStartDate,
            timeOffEndDate,
            cellWidth
        );

        width = isAllocationsVisualizationOn ? width + leftRightBorderWidth : width;

        const timeOffMessageKey = getTimeOffMessageKey(isUserTimeOff).messageKey;
        const timeOffFallBack = getTimeOffMessageKey(isUserTimeOff).fallBack;

        const leftPosition = calculateLeftPosition(
            stepUnit,
            startDate,
            timeOffStartDate,
            cellWidth,
            0
        );

        return (
            <>
                {isInUserRow && (
                    <FullTimeOffUpperBorderStyled
                        isFirstRowUser={isInUserRow && idsArray[0] === firstUserID}
                        isAllocationsVisualizationOn={isAllocationsVisualizationOn}
                        leftPosition={leftPosition}
                        width={width}
                    />
                )}
                <FullTimeOffStyled
                    width={width}
                    leftPosition={leftPosition}
                    isSingleDayTimeOff={isSingleDayTimeOff}
                    data-testid={
                        isUserTimeOff
                            ? fullTimeOffsTestIds.fullTimeOff
                            : fullTimeOffsTestIds.fullException
                    }
                    isInUserRow={isInUserRow}
                    idsArray={idsArray}
                    isInProjectGroupingMode={isInProjectGroupingMode}
                >
                    {!hasAllocation && isInUserRow && (
                        <TimeOffTextContent isSingleDayTimeOff={isSingleDayTimeOff}>
                            <Tooltip
                                delay={700}
                                content={
                                    <AllocationTooltipContent
                                        timeOffMessageKey={timeOffMessageKey}
                                        timeOffMessageFallback={timeOffFallBack}
                                        isForFullTimeOff
                                    />
                                }
                            >
                                <div className={iconsWrapper}>
                                    {isUserTimeOff && (
                                        <TimeoffSmallIcon color={primary.gray(500)} />
                                    )}
                                    {(!isUserTimeOff || isUserTimeOff === 'both') && (
                                        <HolidayIconWrapper isUserTimeOff={isUserTimeOff}>
                                            <HolidaySmallIcon color={primary.gray(500)} />
                                        </HolidayIconWrapper>
                                    )}
                                </div>
                            </Tooltip>
                            {!isSingleDayTimeOff && (
                                <TimeOffText width={width}>
                                    <Text.Small className={TimeOffTextColor}>
                                        <Localization<string>
                                            messageKey={timeOffMessageKey}
                                            fallback={timeOffFallBack}
                                        />
                                    </Text.Small>
                                </TimeOffText>
                            )}
                        </TimeOffTextContent>
                    )}
                </FullTimeOffStyled>
            </>
        );
    });

const mapStateToProps = (state: IWorkSchedulingCombinedState): IFullTimeOffStateToProps => ({
    stepUnit: stepUnitSelector(state),
    cellWidth: cellWidthSelector(state),
    endDate: endDateSelector(state),
    startDate: startDateSelector(state),
    isInProjectGroupingMode: projectGroupingModeSelector(state),
    activePeriodMode: stepUnitSelector(state),
    firstUserID: tableDataIDsSelector(state)[0],
    isAllocationsVisualizationOn: showAllocationsVisualizationSelector(state),
});

export const FullTimeOff = connect(mapStateToProps)(FullTimeOffComponent);

const heightCalculator = (idsArray: string[] | undefined): string => {
    if (!idsArray) {
        return '0px';
    }
    return '100%';
};

const FullTimeOffStyled = styled('div')<IFullTimeOffStyled>`
    ${inlineItems};
    top: 0;
    z-index: 1;
    position: absolute;
    left: ${(props) => props.leftPosition}px;
    width: ${(props) => props.width + 1}px;
    justify-content: ${(props) => (props.isSingleDayTimeOff ? 'center' : 'flex start')};
    background-color: rgba(69, 75, 87, 0.06);
    height: ${(props) => heightCalculator(props.idsArray)};
    cursor: default;
`;

const FullTimeOffBorderSideCommonStyles = css`
    background-color: ${primary.gray(200)};
    position: absolute;
    padding: 0;
    margin: 0;
`;

export const FullTimeOffUpperBorderStyled = styled('div')<{
    isFirstRowUser?: boolean;
    isAllocationsVisualizationOn?: boolean;
    leftPosition: number;
    width: number;
}>`
    ${FullTimeOffBorderSideCommonStyles};
    background-color: ${primary.gray(500)};
    width: ${(props) => (!props.isAllocationsVisualizationOn ? 'calc(100% + 1px)' : '100%')};
    height: 2px;
    top: ${(props) => (props.isFirstRowUser ? 0 : '-1px')};
    z-index: ${fullTimeOffTopBorderIndex};
    position: absolute;
    left: ${(props) => props.leftPosition}px;
    width: ${(props) => props.width + 1}px;
`;

const leftRightBorderWidth = 1;

const TimeOffTextContent = styled('div')<ITimeOffTextContent>`
    ${inlineItemsCentered};
    color: ${primary.gray(500)};
    padding-left: ${(props) => (props.isSingleDayTimeOff ? '0px' : '11px')};
`;

export const HolidayIconWrapper = styled('div')<IHolidayIconWrapper>`
    padding-left: ${(props) => (props.isUserTimeOff ? '4px' : '0px')};
`;

export const TimeOffTextColor = css`
    color: ${primary.gray(500)};
    line-height: 16px;
`;

const TimeOffText = styled('div')<ITimeOffText>`
    padding-right: 8px;
    width: ${(props) => props.width - 1 - 16 - 11}px;
    line-height: 12px;
    padding-left: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
