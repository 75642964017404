import { Sections } from '../../../constants/schedulingTableConstants';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'CHANGE_HAS_MORE';

interface IChangeHasMoreActionPayload {
    hasMore: boolean;
    sectionType: Sections;
}

const changeHasMore = (
    hasMore: boolean,
    sectionType: Sections
): TModernSchedulingAction<IChangeHasMoreActionPayload> => ({
    type: ActionType,
    payload: {
        hasMore,
        sectionType,
    },
});
export default changeHasMore;

export function handle(
    state: IDataState,
    { payload }: TModernSchedulingAction<IChangeHasMoreActionPayload>
): IDataState {
    if (payload.sectionType === Sections.UNASSIGNED_WORK) {
        return {
            ...state,
            hasMoreUnassignedTasks: payload.hasMore,
        };
    }
    return {
        ...state,
        hasMoreUsers: payload.hasMore,
    };
}
