import { createSelector } from 'reselect';
import { objectsDelegationDetailsSelector } from '../../dataSelectors';

export const objectDelegationsDetailsSelector = createSelector(
    objectsDelegationDetailsSelector,
    (objectsDelegationsDetails) => {
        return (id: string) => {
            return objectsDelegationsDetails[id];
        };
    }
);
