import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Field, FieldGroup } from '@workfront/panel-components';
import {
    IBaseFilterFields,
    IProjectStatusesForFilter,
} from '../../data-flow/filters/IFiltersState';
import { getFilterFields } from '../../services/api-services/filters/filterApiService';
import { Sections } from '../../constants/schedulingTableConstants';
import {
    getAdvancedFilterFields,
    mutateQuickFilterFields,
} from '../../constants/filters/filterOptions';
import { IFilterFields } from './SchedulingPanels';
import { TAreaObjCodes } from '../../data-flow/areaData/areaRelatedInitialDataState';

type TFieldsMetadataLoaderResult = {
    filterFields: IFilterFields;
    projectSortableFields: IBaseFilterFields;
};

type TFieldsMetadataLoaderContextValue = (() => Promise<TFieldsMetadataLoaderResult>) | null;

const FieldsMetadataLoaderContext = createContext<TFieldsMetadataLoaderContextValue>(null);

export function useFilterFields(): IFilterFields {
    const [filterFields, setFilterFields] = useState<IFilterFields>({
        [Sections.UNASSIGNED_WORK]: {
            quickFilterFields: [],
            advancedFilterFields: [],
        },
        [Sections.PEOPLE_WORKLOAD]: {
            quickFilterFields: [],
            advancedFilterFields: [],
        },
    });
    const load = useContext(FieldsMetadataLoaderContext);
    useEffect(() => {
        load?.().then(({ filterFields: loadedFilterFields }) => {
            setFilterFields(loadedFilterFields);
        });
    }, [load]);
    return filterFields;
}

export function useProjectSortableFields(): IBaseFilterFields {
    const [projectSortableFields, setProjectSortableFields] = useState<IBaseFilterFields>({
        quickFilterFields: [] as Field[],
        advancedFilterFields: [] as FieldGroup[],
    });
    const load = useContext(FieldsMetadataLoaderContext);
    useEffect(() => {
        load?.().then(({ projectSortableFields: loadedProjectSortableFields }) => {
            setProjectSortableFields(loadedProjectSortableFields);
        });
    }, [load]);
    return projectSortableFields;
}

type TFieldsMetadataLoaderProps = {
    projectStatuses: IProjectStatusesForFilter[];
    schedulingAreaObjCode: TAreaObjCodes;
};

export const FieldsMetadataLoader: React.FunctionComponent<TFieldsMetadataLoaderProps> = (
    props
) => {
    const { children, projectStatuses, schedulingAreaObjCode } = props;
    const loadingPromise = useRef<Promise<TFieldsMetadataLoaderResult>>();

    const load = useCallback(() => {
        if (!loadingPromise.current) {
            loadingPromise.current = getFilterFields().then((fields) => {
                const {
                    quickFilterFields,
                    unassignedQuickFilterFields,
                    advancedFilterFields,
                    projectsSortQuickFilterFields,
                    projectsSortAdvancedFilterFields,
                } = fields;

                return {
                    filterFields: {
                        [Sections.PEOPLE_WORKLOAD]: {
                            quickFilterFields: mutateQuickFilterFields(
                                Sections.PEOPLE_WORKLOAD,
                                schedulingAreaObjCode,
                                quickFilterFields,
                                projectStatuses
                            ),
                            advancedFilterFields: getAdvancedFilterFields(
                                Sections.PEOPLE_WORKLOAD,
                                advancedFilterFields
                            ),
                        },
                        [Sections.UNASSIGNED_WORK]: {
                            quickFilterFields: mutateQuickFilterFields(
                                Sections.UNASSIGNED_WORK,
                                schedulingAreaObjCode,
                                unassignedQuickFilterFields,
                                projectStatuses
                            ),
                            advancedFilterFields: getAdvancedFilterFields(
                                Sections.UNASSIGNED_WORK,
                                advancedFilterFields
                            ),
                        },
                    },
                    projectSortableFields: {
                        quickFilterFields: projectsSortQuickFilterFields,
                        advancedFilterFields: projectsSortAdvancedFilterFields,
                    },
                };
            });
        }
        return loadingPromise.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FieldsMetadataLoaderContext.Provider value={load}>
            {children}
        </FieldsMetadataLoaderContext.Provider>
    );
};
