import { toast } from '@phoenix/all';
import * as React from 'react';
import { lazy, Suspense } from 'react';
import { Popper } from 'react-popper';
import { connect } from 'react-redux';
import { OpTask, Task } from 'workfront-objcodes';
import { GlobalPropsContext } from '../../../contexts/globalContexts';
import setObjectsIntoAssignmentMode from '../../../data-flow/data/assignedDataActions/setObjectsIntoAssignmentMode';
import setUserIntoAssignmentMode from '../../../data-flow/data/assignedDataActions/setUserIntoAssignmentMode';
import {
    assignmentDialogDetailsSelector,
    loadWorkDelegationsSelector,
} from '../../../data-flow/data/selectors/dataSelectors';
import changeAssignmentDialogDetails from '../../../data-flow/data/sharedActions/changeAssignmentDialogDetails';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import { projectTimelineInfoSelector } from '../../../data-flow/projectTimelineInfo/selectors/projectTimelineInfoSelector';
import { saveAssignmentThunk } from '../../../data-flow/thunks/changeAssignmentsThunk';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
    TTypeOfIDs,
} from '../../../data-flow/types';
import { makeControllerCall } from '../../../services/api-services/api';
import { APIService } from '../../../services/api-services/apiService';
import { restructureAssignmentDetails } from '../../../util/actionsComponentService';
import {
    getOpenedDialogsDetails,
    pendoAnalyticsTracker,
} from '../../../util/analytics/pendoAnalyticsTracker';
import { getUsersIDs } from '../../../util/changeAssignmentsUtil';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { assignmentWidgetOverridingStyles } from '../../styles/ActionsComponentStyles';
import { cancelContouringActions } from './ContouringButtonsComonent';
import Portal from './portal';
import { loadObjectAssignments } from '../../../data-flow/thunks/loadObjectAssignments';
import { Sections } from '../../../constants/schedulingTableConstants';
import { objectAssignmentsDetailsSelector } from '../../../data-flow/data/selectors/reselect/objectAssignmentsDetailsSelector/objectAssignmentsDetailsSelector';
import { objectDelegationsDetailsSelector } from '../../../data-flow/data/selectors/reselect/objectDelegationsDetailsSelector/objectDelegationsDetailsSelector';
import { getContextValue } from '../../../contexts/checkContext';
import {
    IAssignmentDialogDetails,
    IAssignmentsDetails,
    IDelegationsDetails,
    IObjectState,
    IProjectTimelineInfo,
    IUnassignedTaskState,
    TMittEmitter,
} from '../../../data-flow/data/IDataState';
import { durationTypes, durationUnits } from '../../../constants/dataConstatnts';
import { getTaskSelector } from '../../../data-flow/data/selectors/reselect/getTaskSelector/getTaskSelector';
import { getUnassignedTaskSelector } from '../../../data-flow/data/selectors/reselect/getUnassignedTaskSelector/getUnassignedTaskSelector';
import { trackWorkSaved } from '../../../data-flow/thunks/cjaTracker';
import { internalEventEmitterSelector } from '../../../data-flow/instances/internalEventEmitterSelector';
import { CJA_ANALYIZING_TRACK } from '../../../constants/events';

interface IAssignmentDialogStateProps {
    objectAssignmentsDetails: IAssignmentsDetails[];
    objectDelegationsDetails: IDelegationsDetails[];
    assignmentDialogDetails: IAssignmentDialogDetails;
    advanceAssignmentData: IObjectState | IUnassignedTaskState;
    projectTimelineInfo: IProjectTimelineInfo;
    loadWorkDelegations: boolean;
    internalEventEmitter: TMittEmitter;
}

interface IAssignmentDialogProps {
    ID: string;
    idExpression: string;
    objCode: typeof Task | typeof OpTask;
    sectionType: Sections;
    isGhost: boolean;
}

const handleSaveAssignment = (changedAssignmentData, props, minixObjID): void => {
    // here ID is task or issue ID
    const { dispatch, objectAssignmentsDetails, ID, objCode, isGhost } = props;
    const assignmentDetails = restructureAssignmentDetails(objectAssignmentsDetails);
    const assignmentChangeConfig = {
        isGhost,
    };
    dispatch(
        saveAssignmentThunk(changedAssignmentData, assignmentDetails, ID, assignmentChangeConfig)
    );

    toast.success(
        localizationClient.getTextSync(
            'resourcescheduling.assignment.success.notification',
            'You successfully assigned the task'
        )
    );

    if (minixObjID === ID) {
        APIService.apiSearch(objCode, { ID }, [
            'plannedStartDate',
            'plannedCompletionDate',
            'assignments:*',
            'assignments:assignedBy:*',
            'assignments:assignedTo:*',
            'assignments:role:*',
            'teamAssignment:*',
            'teamAssignment:team:*',
            'teamAssignments:*',
            'teamAssignments:team:*',
        ]).then((data) => {
            let assignments;
            if (data[0].teamAssignments) {
                assignments = data[0].assignments.concat(data[0].teamAssignments);
            } else if (data[0].teamAssignment) {
                assignments = [...data[0].assignments, data[0].teamAssignment];
            } else {
                assignments = data[0].assignments;
            }

            window.postMessage(
                {
                    type: 'minixUpdateDetails',
                    updates: {
                        // eslint-disable-next-line no-nested-ternary
                        assignments,
                        plannedStartDate: data[0].plannedStartDate,
                        plannedCompletionDate: data[0].plannedCompletionDate,
                    },
                },
                window.location.origin
            );
        });
    }
    props.internalEventEmitter.emit(CJA_ANALYIZING_TRACK);
    dispatch(trackWorkSaved(props.advanceAssignmentData.objCode, props.sectionType));
    pendoAnalyticsTracker(getOpenedDialogsDetails('Save', props.sectionType, 'Assignment Widget'));
};

const assignmentsSaveClickFunction = (updatedUsersIDs, props): void => {
    const { dispatch, ID, objectAssignmentsDetails } = props; // ID is taskID
    const oldAssignmentData = restructureAssignmentDetails(objectAssignmentsDetails);
    const chainActions: any[] = [];
    const removedUsersIDs: string[] = getUsersIDs(oldAssignmentData.userIDs, updatedUsersIDs);

    chainActions.push(setObjectsIntoAssignmentMode([ID]));
    chainActions.push(setUserIntoAssignmentMode(removedUsersIDs.concat(updatedUsersIDs), true));
    dispatch(actionChain(chainActions));
};

const onAssignmentWidgetClose = (dispatch): void => {
    dispatch(changeAssignmentDialogDetails(null));
};

const onAssignmentFailure = (props, updatedUsersIDs): void => {
    const { dispatch, objectAssignmentsDetails } = props; // ID is taskID
    const oldAssignmentData = restructureAssignmentDetails(objectAssignmentsDetails);
    const removedUsersIDs: string[] = getUsersIDs(oldAssignmentData.userIDs, updatedUsersIDs);

    dispatch(
        actionChain([
            setObjectsIntoAssignmentMode(null),
            setUserIntoAssignmentMode(
                updatedUsersIDs ? removedUsersIDs.concat(updatedUsersIDs) : removedUsersIDs,
                false
            ),
        ])
    );
};

export const openAssignmentDialog = (
    dispatch,
    objCode: string,
    hasAssignmentsDetails: boolean,
    IDs: TTypeOfIDs,
    sectionType: Sections,
    loadWorkDelegations: boolean,
    position?: { x: number; y: number }
): void => {
    pendoAnalyticsTracker(getOpenedDialogsDetails('Open', sectionType, 'Assignment Widget'));

    const { contouringRowID, idExpression, ID } = IDs;

    let contouringActions: any = [];

    if (contouringRowID) {
        const objIDs = contouringRowID.split('_');
        // TODO show this to the author
        const objID = objIDs[objIDs.length - 1];

        contouringActions = cancelContouringActions(objID);
    }

    dispatch(
        actionChain([
            changeAssignmentDialogDetails(idExpression, position, !hasAssignmentsDetails),
            ...contouringActions,
        ])
    );

    if (!hasAssignmentsDetails) {
        dispatch(loadObjectAssignments(ID, objCode, false, loadWorkDelegations)).then(() => {
            dispatch(changeAssignmentDialogDetails(idExpression, position, false));
        });
    }
};

const getCursorXPosition = (positionX): number => {
    const assinmentDialogWidth = 343;
    const minixContainer: HTMLDivElement | null = document.querySelector(
        '[data-testid="minix-container"]'
    );
    const filterContainer: HTMLDivElement | null = document.querySelector(
        '[data-testid="filter-container"]'
    );

    let rightPanelWidth: number;
    if (minixContainer) {
        rightPanelWidth = minixContainer.offsetWidth;
    } else {
        rightPanelWidth = filterContainer ? filterContainer.offsetWidth : 0;
    }

    const widthBtwRightPanelAndMouseX =
        window.innerWidth - rightPanelWidth - positionX - assinmentDialogWidth;

    return (
        positionX -
        (widthBtwRightPanelAndMouseX < 0 ? Math.abs(widthBtwRightPanelAndMouseX) + 30 : 0)
    );
};

const AssignmentDialogComponent = lazy(() =>
    import('@workfront/widget-assignment').then((module) => ({
        default: module.AssignmentDialogComponent,
    }))
);

export const AssignmentDialogDisconnected = (
    props: IAssignmentDialogProps & IAssignmentDialogStateProps & IWorkSchedulingDispatchProp
): null | JSX.Element => {
    const globalPropsContext = React.useContext(GlobalPropsContext);
    if (
        props.assignmentDialogDetails.ID !== props.idExpression ||
        getContextValue('sharableLink')
    ) {
        return null;
    }

    const popperPositionProp: any = {};

    if (props.assignmentDialogDetails.positionX && props.assignmentDialogDetails.positionY) {
        const positionX = getCursorXPosition(props.assignmentDialogDetails.positionX);

        popperPositionProp.referenceElement = {
            clientHeight: 0,
            clientWidth: 0,
            getBoundingClientRect() {
                return {
                    width: 0,
                    height: 0,
                    top: props.assignmentDialogDetails.positionY,
                    bottom: props.assignmentDialogDetails.positionY,
                    left: positionX,
                    right: positionX,
                };
            },
        };
    }

    const {
        isDurationLocked,
        duration,
        durationType,
        durationUnit,
        isWorkRequiredLocked,
        plannedHours,
        recurrenceRuleID,
    } = props.advanceAssignmentData;

    const { objCode, ID } = props;
    return (
        <Portal container={document.body}>
            <Popper
                {...popperPositionProp}
                placement="bottom-start"
                modifiers={{
                    flip: {
                        behavior: 'flip',
                        boundariesElement: 'window',
                    },
                    computeStyle: {
                        gpuAcceleration: false,
                    },
                }}
            >
                {(popperProps) => (
                    <div
                        ref={popperProps.ref}
                        style={{ ...popperProps.style, zIndex: 5, marginRight: '20px' }}
                        data-placement={popperProps.placement}
                        className={assignmentWidgetOverridingStyles}
                        data-test-id="data-test-assignments-dialog"
                    >
                        <Suspense fallback={null}>
                            <AssignmentDialogComponent
                                assignments={props.objectAssignmentsDetails || []}
                                delegations={props.objectDelegationsDetails || []}
                                detailObject={{
                                    objCode,
                                    ID,
                                    isDurationLocked,
                                    duration,
                                    durationType,
                                    durationUnit,
                                    isWorkRequiredLocked,
                                    workRequired: plannedHours,
                                }}
                                isRecurrent={recurrenceRuleID}
                                areAssignmentsEditable
                                durationTypes={durationTypes}
                                durationUnits={durationUnits}
                                durationUnitInfo={props.projectTimelineInfo}
                                isDurationEditable
                                isDurationTypeEditable
                                isPlannedHoursEditable
                                isWorkDelegationEnabled={props.loadWorkDelegations}
                                isLoading={props.assignmentDialogDetails.showLoading}
                                currentUser={getContextValue('currentUser')}
                                isDirectUsage
                                hideAdvancedAssignment={false}
                                changesDoneFunction={(changedAssignmentData) =>
                                    handleSaveAssignment(
                                        changedAssignmentData,
                                        props,
                                        globalPropsContext.minixState
                                            ? globalPropsContext.minixState.ID
                                            : null
                                    )
                                }
                                changesBeforeDoneFunction={(users) => {
                                    assignmentsSaveClickFunction(users, props);
                                }}
                                closeAssignmentsDialog={() =>
                                    onAssignmentWidgetClose(props.dispatch)
                                }
                                controllerCallFunction={makeControllerCall}
                                api={APIService.api}
                                fixedHeight={225} // this is for classic version
                                searchResultHeight={225} // this for QS version
                                handleChangeSelectedItems={() => {
                                    popperProps.scheduleUpdate();
                                }}
                                onAssignmentFailure={(data) =>
                                    onAssignmentFailure(props, data.userIDs)
                                }
                            />
                        </Suspense>
                    </div>
                )}
            </Popper>
        </Portal>
    );
};

const mapStateToProps = (
    state: IWorkSchedulingCombinedState,
    ownProps: IAssignmentDialogProps
): IAssignmentDialogStateProps => {
    const advanceAssignmentData =
        ownProps.sectionType === Sections.PEOPLE_WORKLOAD
            ? getTaskSelector(state)(ownProps.ID)
            : getUnassignedTaskSelector(state)(ownProps.ID);
    return {
        advanceAssignmentData,
        projectTimelineInfo: projectTimelineInfoSelector(state),
        objectAssignmentsDetails: objectAssignmentsDetailsSelector(state)(ownProps.ID),
        objectDelegationsDetails: objectDelegationsDetailsSelector(state)(ownProps.ID),
        assignmentDialogDetails: assignmentDialogDetailsSelector(state),
        loadWorkDelegations: loadWorkDelegationsSelector(state),
        internalEventEmitter: internalEventEmitterSelector(state),
    };
};
export const AssignmentDialog = connect(mapStateToProps)(AssignmentDialogDisconnected);
