import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'SET_USER_INTO_ASSIGNMENT_MODE';

interface IUserAssignmentModePayload {
    userIds: string[];
    isChanged: boolean;
}

type TEditRowAction = TModernSchedulingAction<IUserAssignmentModePayload>;

const setUserIntoAssignmentMode = (userIds, isChanged): TEditRowAction => ({
    type: ActionType,
    payload: {
        userIds,
        isChanged,
    },
});
export default setUserIntoAssignmentMode;

export function handle(state: IDataState, { payload }: TEditRowAction): IDataState {
    const users = { ...state.users };
    let hasChanged = false;

    payload.userIds.forEach((userID) => {
        if (users[userID]) {
            hasChanged = true;
            users[userID] = {
                ...users[userID],
                isInAssignmentProcess: payload.isChanged,
            };
        }
    });

    return hasChanged
        ? {
              ...state,
              users,
          }
        : state;
}
