import * as React from 'react';
import { css } from 'react-emotion';
import halfTimeOff from '../../../../images/halfTimeOff.svg';
import { hourSharedStyles } from '../../../../styles';
import { Hour } from './Hour';
import { NoAllocation } from './NoAllocation';
// TODO do some refactors
interface IHalfTimeOff extends React.HTMLAttributes<HTMLDivElement> {
    hourPerDay: number; // planned hours
    workingHours: number; // available minus timeOffs and exceptions
    isHalfTimeOff: boolean;
    showRemainingHours: boolean;
    isInAssignmentProcess: boolean;
    showAllocationsInHoursMode: boolean;
    scheduleHour: number;
}

export const HalfTimeOff: React.FunctionComponent<IHalfTimeOff> = React.memo((props) => {
    return (
        <div className={halfTimeOffStyles}>
            {props.hourPerDay === 0 ? (
                <NoAllocation {...props} className={hourStyle} />
            ) : (
                <Hour {...props} className={hourStyle} />
            )}
            <div data-testid="halfTimeOff" className={halfTimeOffStyle} />
        </div>
    );
});

const halfTimeOffStyles = css`
    height: 30px;
    width: 100%;
    ${hourSharedStyles}
`;

const hourStyle = css`
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
`;

const weekEndStripes = css`
    background: url(${halfTimeOff}) 0 0 repeat;
`;

const halfTimeOffStyle = css`
    ${weekEndStripes}
    height: 12px;
    position: absolute;
    min-width: 30px;
    left: 0;
    bottom: 0;
    width: 100%;
`;
