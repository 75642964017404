import { TModernSchedulingAction } from '../../types';
import { IFiltersState, IProjectStatusesForFilter } from '../IFiltersState';

export const ActionType = 'ADD_PROJECT_STATUSES_FOR_FILTERS';

interface IAddProjectStatusesActionPayload {
    statuses: IProjectStatusesForFilter[];
}

type TAddProjectStatusesAction = TModernSchedulingAction<IAddProjectStatusesActionPayload>;

const addProjectStatusesForFilters = (
    statuses: IProjectStatusesForFilter[]
): TAddProjectStatusesAction => ({
    type: ActionType,
    payload: {
        statuses,
    },
});
export default addProjectStatusesForFilters;

export function handle(
    state: IFiltersState,
    { payload }: TAddProjectStatusesAction
): IFiltersState {
    const { statuses } = payload;

    return {
        ...state,
        projectStatuses: statuses,
    };
}
