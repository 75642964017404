import React from 'react';
import { chartComponentIndex } from '../../../constants/dataConstatnts';
import {
    chartGetY,
    getOverAllocationOpacityLevel,
    getPreviousStatus,
    isOverAllocated as isOverAllocatedCheck,
} from '../../../util/chartUtils';
import UserFocusBarChart from './items/UserFocusBarChart/UserFocusBarChart';
import UserFocusLine from './items/UserFocusLine/UserFocusLine';

interface IUserFocusChartComponentProps extends React.HTMLAttributes<HTMLDivElement> {
    availableHour: number;
    height: number;
    index: number;
    isOverTimeOff?: boolean;
    scaledHeight: number;
    userID?: string;
    prevAvailableHour: number;
    prevWeight?: number;
    prevWorkPerDay: number;
    requiredLineColor?: string;
    requiredFillColor?: string;
    weight: number;
    workPerDay: number;
}

export const testIds = {
    itemVisualizationWrapper: 'item-visualization-wrapper',
};

const UserFocusChartComponent: React.FunctionComponent<IUserFocusChartComponentProps> = ({
    availableHour,
    height,
    index,
    isOverTimeOff,
    scaledHeight,
    userID,
    prevAvailableHour,
    prevWeight,
    prevWorkPerDay,
    requiredLineColor,
    requiredFillColor,
    weight,
    workPerDay,
}) => {
    const isOverAllocated = isOverAllocatedCheck(workPerDay, availableHour);
    const overAllocationOpacityLevel = getOverAllocationOpacityLevel(workPerDay, availableHour);
    const wrapperZIndex = isOverTimeOff ? chartComponentIndex : 0;

    return (
        <svg
            data-testid={testIds.itemVisualizationWrapper}
            width="100%"
            height="101%" // a little bit more to let line go over border
            style={{ position: 'relative', zIndex: wrapperZIndex }}
        >
            <UserFocusBarChart
                x={0}
                y={chartGetY(height, scaledHeight)}
                height={scaledHeight}
                isOverAllocated={isOverAllocated}
                overAllocationOpacityLevel={overAllocationOpacityLevel}
                requiredFillColor={requiredFillColor}
            />
            <UserFocusLine
                uniqueId={`${userID || ''}_${index}`}
                start={[0, index === 0 ? '100%' : prevWeight || 0]}
                end={['100%', weight]}
                isOverAllocated={isOverAllocatedCheck(workPerDay, availableHour)}
                previousStatus={getPreviousStatus(prevWorkPerDay, workPerDay)}
                prevIsOverAllocated={
                    !!prevWorkPerDay && isOverAllocatedCheck(prevWorkPerDay, prevAvailableHour)
                }
                requiredLineColor={requiredLineColor}
                skipLeftLine={index === 0}
            />
        </svg>
    );
};

export default UserFocusChartComponent;
