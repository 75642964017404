import React, { useContext } from 'react';
import { Popper } from 'react-popper';
import { connect } from 'react-redux';
import { allocationsModifiers, errorStyle } from '../../../styles/allocationsComponentStyles';
import { ContouringButtons } from '../ContouringButtonsComonent';
import { IWorkSchedulingCombinedState } from '../../../../data-flow/types';
import {
    isBulkAssignmentPanelOpenedSelector,
    isPanelOpenedSelector,
    showRoleSummarySelector,
} from '../../../../data-flow/settings/settingsSelector';
import { Sections } from '../../../../constants/schedulingTableConstants';
import { GlobalPropsContext } from '../../../../contexts/globalContexts';

interface IAllocationModeItemsProps extends React.HTMLAttributes<HTMLDivElement> {
    isThereAfterArrow: boolean;
    isPenultimateDayInView: boolean;
    IDs: string[];
    isTheLastRow: boolean;
    areHoursNotEqualsBeforeContouringSave: boolean | undefined;
    objCode: string;
}

export const AllocationModeItemsComponent: React.FunctionComponent<IAllocationModeItemsProps> = (
    props
) => {
    /**
     * This context is needed to make component
     * rerender when minix is toggled,
     * in order to make component rerender and recalculate
     * its position on assignment's X axis
     * */
    useContext(GlobalPropsContext);

    const {
        isThereAfterArrow,
        isPenultimateDayInView,
        IDs,
        isTheLastRow,
        areHoursNotEqualsBeforeContouringSave,
        objCode,
    } = props;

    const propsForButtons = {
        isThereAfterArrow,
        isPenultimateDayInView,
        IDs,
        objCode,
    };

    const taskBarBorderWidthWithArrow = 12;
    let borderWidth = 4;
    let isArrowOrPenultimateDay = isPenultimateDayInView;
    if (isThereAfterArrow) {
        borderWidth = taskBarBorderWidthWithArrow;
        isArrowOrPenultimateDay = isThereAfterArrow;
    }
    const leftPos = borderWidth + (areHoursNotEqualsBeforeContouringSave ? 1 : 0);
    const popperPlacement = isTheLastRow ? 'top-end' : 'bottom-end';

    const paddingForMakingButtonsStickWithRightSizeOfWindow = isThereAfterArrow ? 27 : 15;

    return (
        <>
            <Popper
                placement={popperPlacement}
                modifiers={{
                    ...allocationsModifiers(isArrowOrPenultimateDay, isTheLastRow),
                    preventOverflow: {
                        enabled: true,
                        boundariesElement:
                            document.querySelector(`.scheduling-container`) || 'window',
                        padding: paddingForMakingButtonsStickWithRightSizeOfWindow,
                    },
                }}
            >
                {({ ref, style, placement }) => (
                    <span
                        ref={ref}
                        style={{
                            ...style,
                            zIndex: 5,
                            left: isArrowOrPenultimateDay ? leftPos : 0,
                        }}
                        data-placement={placement}
                        className={
                            areHoursNotEqualsBeforeContouringSave && isArrowOrPenultimateDay
                                ? errorStyle
                                : ''
                        }
                    >
                        <ContouringButtons placement={placement} {...propsForButtons} />
                    </span>
                )}
            </Popper>
        </>
    );
};

const mapStateToProps = (state: IWorkSchedulingCombinedState): any => {
    const isFilterOpen =
        state.Filters[Sections.PEOPLE_WORKLOAD].isFilterOpen ||
        state.Filters[Sections.UNASSIGNED_WORK].isFilterOpen;
    const isRoleSummaryOpen = showRoleSummarySelector(state);
    const isPanelOpened = isPanelOpenedSelector(state);
    const isBulkAssignmentPanelOpened = isBulkAssignmentPanelOpenedSelector(state);

    const doesAnyPanelStateChanges =
        isFilterOpen || isRoleSummaryOpen || isPanelOpened || isBulkAssignmentPanelOpened;
    /**
     * This prop is needed to make component
     * rerender when any of panel components is toggled,
     * in order to make component rerender and recalculate
     * its position on assignment's X axis
     * */
    return {
        shouldContouringButtonsPositionBeRecalculated: doesAnyPanelStateChanges,
    };
};

export const AllocationModeItems = connect(mapStateToProps)(AllocationModeItemsComponent);
