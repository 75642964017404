import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'ADD_WORK_PER_DAYS_BY_ASSIGNMENT_FROM_SERVICE';

interface IAddWorkPerDaysByAssignmentsPayload {
    workPerDayDataFromService: Record<string, Record<string, unknown>>;
}

type TAddWorkPerDaysByAssignmentFromServiceAction =
    TModernSchedulingAction<IAddWorkPerDaysByAssignmentsPayload>;

const addWorkPerDaysByAssignmentsFromService = (
    workPerDayDataFromService
): TAddWorkPerDaysByAssignmentFromServiceAction => ({
    type: ActionType,
    payload: {
        workPerDayDataFromService,
    },
});
export default addWorkPerDaysByAssignmentsFromService;

export function handle(
    state: IDataState,
    { payload }: TAddWorkPerDaysByAssignmentFromServiceAction
): IDataState {
    return {
        ...state,
        workPerDaysByAssignmentsFromService: {
            ...state.workPerDaysByAssignmentsFromService,
            ...payload.workPerDayDataFromService,
        },
    };
}
