import { IWorkSchedulingCombinedState } from '../../types';
import { TTableDataIDs } from '../IDataState';
import { assignedSectionSliceIndex } from '../../../constants/dataConstatnts';

export const tableDataIDsSelector = (state: IWorkSchedulingCombinedState): string[] => {
    return state.Data.tableDataIDs;
};

export const tableDataIDsSelectorWithoutHeaderSelector = (
    stateOfData: IWorkSchedulingCombinedState['Data']
): TTableDataIDs => {
    return stateOfData.tableDataIDs.slice(assignedSectionSliceIndex);
};
