export const readCookie = (name): string | null => {
    const match = document.cookie.match(new RegExp(`(^|;\\s*)(${name})=([^;]*)`));
    return match ? decodeURIComponent(match[3]) : null;
};

export const TIMEZONE_COOKIE_NAME = 'timezone';
export const COOKIE_ATTRIBUTES = '; Path=/; SameSite=None; Secure';

/**
 * Get the timezone offset for a date ignoring daylight savings time
 *
 * @return {Number} The standard timezone difference between GMT and local time, in minutes
 */
const getStandardTimezoneOffset = (date): number => {
    const jan = new Date(date.getFullYear(), 0, 1);
    const jul = new Date(date.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

/**
 * Determine whether the local time is using daylight savings time
 *
 * @return {Boolean} True if local time is using DST, otherwise false
 */
const isDaylightTime = (date): boolean => {
    return date.getTimezoneOffset() < getStandardTimezoneOffset(date);
};

/**
 * Returns array of daylight saving change dates for the given year
 * @param {Number} year
 * @returns {Array}
 */
const getDaylightSavingsDates = (year): Date[] => {
    const months: number[] = [];
    const dates: Date[] = [];
    let i = 0;
    let d;
    let daylightTime;
    let isDaylight;

    for (i; i < 12; i++) {
        d = new Date(year, i, 1);
        isDaylight = isDaylightTime(d);
        if (daylightTime === undefined) {
            daylightTime = isDaylight;
        }
        if (isDaylight !== daylightTime) {
            months.push(i);
            daylightTime = isDaylight;
        }
    }

    for (i = 0; i < months.length; ++i) {
        const month = months[i];
        const day = new Date(year, month, 1);

        isDaylight = isDaylightTime(day);

        while (isDaylightTime(day) === isDaylight) {
            day.setDate(day.getDate() - 1);
        }

        while (isDaylightTime(day) !== isDaylight) {
            day.setTime(day.getTime() + 60 * 60 * 1000);
        }

        dates.push(day);
    }
    return dates;
};

const getTimezoneCookieValue = (): string => {
    let d = new Date();
    const jan = new Date(d.getFullYear(), 0, 1);
    const jul = new Date(d.getFullYear(), 6, 1);

    const daylightSavingsDates = getDaylightSavingsDates(d.getFullYear());
    const times: number[] = [];
    for (let i = 0; i < daylightSavingsDates.length; ++i) {
        d = daylightSavingsDates[i];
        times[i] = Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes()
        );
    }
    times.push(jul.getTimezoneOffset() * 60000);
    times.push(jan.getTimezoneOffset() * 60000);

    return times.reverse().join('*');
};

export const createTimezoneCookieIfNotExists = (): void => {
    if (!readCookie(TIMEZONE_COOKIE_NAME)) {
        document.cookie = `${TIMEZONE_COOKIE_NAME}=${getTimezoneCookieValue()}${COOKIE_ATTRIBUTES}`;
    }
};
