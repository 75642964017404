import { Localization } from '@workfront/localize-react';
import * as React from 'react';
import GroupSmallIcon from 'phoenix-icons/dist/GroupIcon.js';
import { peopleWorkloadAppliedFilterCountSelector } from '../../../../../data-flow/filters/selectors/filterIDSelector';
import { useAppSelector } from '../../../../../data-flow/hooks';
import { showAllUsersSelector } from '../../../../../data-flow/settings/settingsSelector';
import { isInProjectArea } from '../../../../../util/utilities';
import {
    iconBorder,
    noUserMainText,
    NoUsersImageStyled,
    NoUsersStyled,
    noUserText,
    secondaryText,
    showAllUsersText,
} from './NoUsersStyles';
import {
    getNoUserFirstLevelMessageKey,
    getNoUserSecondLevelMessageKey,
    getNoUsersPaddingBottom,
    getNoUsersPaddingTop,
} from './noUsersUtils';

export interface INoUsers {
    width: number;
    schedulingAreaObjCode: string;
}

export const testIds = {
    noUsersOnProjectText: 'no-users-on-project-text',
    noUsersText: 'no-users-text',
};

export const NoUsers: React.FunctionComponent<INoUsers> = React.memo((props) => {
    const isInProjectScope = isInProjectArea(props.schedulingAreaObjCode);
    const noUserMessageKey = getNoUserFirstLevelMessageKey(isInProjectScope).messageKey;
    const noUserFallback = getNoUserFirstLevelMessageKey(isInProjectScope).fallBack;
    const noUserSecondLevelMessageKey = getNoUserSecondLevelMessageKey(isInProjectScope).messageKey;
    const noUserSecondLevelFallBack = getNoUserSecondLevelMessageKey(isInProjectScope).fallBack;
    const isShowAllUsers: boolean = useAppSelector((state) => showAllUsersSelector(state));
    const isFilterApplied: number = useAppSelector((state) =>
        peopleWorkloadAppliedFilterCountSelector(state)
    );

    const component = (): JSX.Element => {
        if (isInProjectScope && !isShowAllUsers && isFilterApplied === 0) {
            return (
                <>
                    <div className={noUserText} data-testid={testIds.noUsersOnProjectText}>
                        <Localization<string>
                            messageKey="workloadbalancer.no.people.assigned"
                            fallback="There are no people assigned to work on the project."
                        />
                    </div>
                    <div className={secondaryText}>
                        <span className={noUserText}>
                            <Localization<string>
                                messageKey="workloadbalancer.enable.action.main"
                                fallback="Enable the"
                            />
                        </span>
                        <span className={showAllUsersText}>
                            <Localization<string>
                                messageKey="workloadbalancer.show.all.users"
                                fallback="Show all users"
                            />
                        </span>
                        <GroupSmallIcon focusable="false" className={iconBorder} />
                        <span className={noUserText}>
                            <Localization<string>
                                messageKey="workloadbalancer.enable.action.secondary"
                                fallback="option on the toolbar to display all users and start assigning work"
                            />
                        </span>
                    </div>
                </>
            );
        }
        return (
            <>
                <NoUsersImageStyled isInProjectScope={false} />
                <div className={noUserMainText} data-testid={testIds.noUsersText}>
                    <Localization<string>
                        messageKey={getNoUserFirstLevelMessageKey(false).messageKey}
                        fallback={getNoUserFirstLevelMessageKey(false).fallBack}
                    />
                </div>
                <div className={noUserText}>
                    <Localization<string>
                        messageKey={getNoUserSecondLevelMessageKey(false).messageKey}
                        fallback={getNoUserSecondLevelMessageKey(false).fallBack}
                    />
                </div>
            </>
        );

        return (
            <>
                <NoUsersImageStyled isInProjectScope={isInProjectScope} />
                <div className={noUserMainText} data-testid={testIds.noUsersText}>
                    <Localization<string> messageKey={noUserMessageKey} fallback={noUserFallback} />
                </div>
                <div className={noUserText}>
                    <Localization<string>
                        messageKey={noUserSecondLevelMessageKey}
                        fallback={noUserSecondLevelFallBack}
                    />
                </div>
            </>
        );
    };

    return (
        <NoUsersStyled
            width={props.width}
            paddingTop={getNoUsersPaddingTop(isInProjectScope)}
            paddingBottom={getNoUsersPaddingBottom(isInProjectScope)}
        >
            {component()}
        </NoUsersStyled>
    );
});
