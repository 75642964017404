import { SortModeActions } from '../../../constants/sortUsersMode';
import { TModernSchedulingAction } from '../../types';
import { ISettingsState } from '../ISettingsState';

export const ActionType = 'SET_SORT_USERS_MODE';

interface ISetSortUsersMode {
    actionType: string;
}

type TSetSortUsersModeAction = TModernSchedulingAction<ISetSortUsersMode>;

const sortUsersMode = (actionType): TSetSortUsersModeAction => ({
    type: ActionType,
    payload: {
        actionType,
    },
});
export default sortUsersMode;

export function handle(
    state: ISettingsState,
    { payload }: TSetSortUsersModeAction
): ISettingsState {
    const { actionType } = payload;

    const sortUsersByRoleEnabled = actionType === SortModeActions.BY_ROLE;
    return {
        ...state,
        sortUsersByRoleEnabled,
    };
}
