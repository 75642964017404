import actionChain from '../../../higher-order-reducers/actionChain';
import { clearContouringRowState } from '../changeContouringRowState';
import removeTemporaryWorkPerDayHours from '../removeTemporaryWorkPerDayHours';
import showErrorRedBorder from '../showErrorRedBorder';
import changeFilterOpenState from '../../../filters/filtersActions/changeFilterOpenState';
import { Sections } from '../../../../constants/schedulingTableConstants';
import toggleSetting, { settingNames } from '../../../settings/settingsActions/settingsActions';

export const closeAllPanelsChain = (roleSummaryToggle = true): unknown[] => {
    // this function is context specific as contains isEnabled check.
    const actions: unknown[] = [...closeAllFilterWidgetsChain()];
    if (roleSummaryToggle) {
        actions.push(toggleSetting(settingNames.showRoleSummary, false));
    }
    actions.push(toggleSetting(settingNames.isPanelOpened, false));
    actions.push(toggleSetting(settingNames.isBulkAssignmentPanelOpened, false));

    return actions;
};

export const resetContouringEditModeChain = (): unknown[] => {
    return [
        clearContouringRowState(),
        removeTemporaryWorkPerDayHours(),
        showErrorRedBorder(undefined, false),
    ];
};

export const resetContouringEditMode = (): any => {
    return actionChain(resetContouringEditModeChain());
};

export const closeAllFilterWidgetsChain = (): unknown[] => {
    return [
        changeFilterOpenState(false, Sections.PEOPLE_WORKLOAD),
        changeFilterOpenState(false, Sections.UNASSIGNED_WORK),
    ];
};

export const closeAllFilterWidgets = (): any => actionChain(closeAllFilterWidgetsChain());

export const toggleRoleSummaryChain = (hardCodeValue?: boolean): any => {
    return [
        ...closeAllPanelsChain(false),
        toggleSetting(settingNames.showRoleSummary, hardCodeValue),
    ];
};

export const toggleRoleSummaryActions = (hardCodeValue?: boolean): any =>
    actionChain(toggleRoleSummaryChain(hardCodeValue));
