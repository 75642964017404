import { createSelector } from 'reselect';
import { IDateRangeState } from '../IDateRangeState';
import { endDateSelector } from './endDateSelector';
import { startDateSelector } from './startDateSelector';
import { stepUnitSelector } from './stepUnitSelector';

const stepsSelectorCached: Record<string, IDateRangeState['steps']> = {};

export const stepsSelector = createSelector(
    [stepUnitSelector, startDateSelector, endDateSelector],
    (stepUnit, startDate, endDate) => {
        const stepStartDateISO = startDate.clone().toISOString();
        const stepEndDateISO = endDate.clone().toISOString();

        const cacheKey = `${stepStartDateISO}_${stepEndDateISO}_${stepUnit}`;

        if (!stepsSelectorCached[cacheKey]) {
            let stepStartDate = startDate.clone();
            const steps: IDateRangeState['steps'] = [];

            while (stepStartDate <= endDate) {
                steps.push(stepStartDate);
                stepStartDate = stepStartDate.clone().add(1, stepUnit);
            }

            stepsSelectorCached[cacheKey] = steps;
        }

        return stepsSelectorCached[cacheKey];
    }
);
