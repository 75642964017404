import { IRoleSummaryLoading, RoleSummaryDataType, RoleSummaryKeys } from '../../IDataState';

export const roleSummaryDataSelector = (state): RoleSummaryDataType => {
    return state.Data.roleSummaryData;
};

export const roleSummaryActiveKeysSelector = (state): Array<RoleSummaryKeys | string> => {
    return state.Data.roleSummaryActiveKeys;
};

export const roleSummaryLoadingSelector = (state): IRoleSummaryLoading => {
    return state.Data.roleSummaryLoading;
};
