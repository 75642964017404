import React from 'react';
import styled from 'react-emotion';
import { Localization, LocalizationProvider } from '@workfront/localize-react';
import noAccessIcon from '../images/no-access.png';
import { clientFactory } from '../constants/LocalizationClientFactory';

const NoAccessTextBlock = styled('div')`
    background-image: url('${noAccessIcon}');
    background-repeat: no-repeat;
    height: 120px;
    min-width: 200px;
    padding-left: 150px;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
    padding-top: 30px;
    font-size: 12px;

    div:first-child {
        font-size: 14px;
        font-weight: bold;
    }
`;

const NoAccess = styled('div')`
    display: flex;
    height: calc(100vh - 52px);
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
export default function NoAccessComponent(): JSX.Element {
    return (
        <LocalizationProvider clientFactory={clientFactory}>
            <NoAccess>
                <NoAccessTextBlock>
                    <div>
                        <Localization
                            messageKey="resourceplanner.no.access.message"
                            fallback="Your access level restricts you from seeing this page."
                        />
                    </div>
                    <div>
                        <Localization
                            messageKey="resourceplanner.no.access.description"
                            fallback="If you need access, talk with your Workfront administrator."
                        />
                    </div>
                </NoAccessTextBlock>
            </NoAccess>
        </LocalizationProvider>
    );
}
