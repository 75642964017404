import moment from 'moment';
import { HoursModeActions } from '../../../constants/allocationsMode';
import { filterUserPrefKey } from '../../../constants/filters/filterConstants';
import { TimelineActions } from '../../../constants/periodEnums';
import { Sections } from '../../../constants/schedulingTableConstants';
import { setWorkSchedulingTableHeightsToLocalStorage } from '../../../util/localStorage';
import { isInProjectArea } from '../../../util/utilities';
import { IAreaState } from '../../areaData/areaRelatedInitialDataState';
import setAreaData from '../../areaData/setAreaData';
import timelineNavigation from '../../dateRange/timelineActions/timelineNavigation';
import addFilterID, { TAddFilterIDAction } from '../../filters/filtersActions/addFilterID';
import changeCollapsedMode from '../../tableSizes/actions/changeCollapsedMode';
import setProjectColorsSettingsMode from './projectColorsSettingsMode';
import toggleSetting, { settingNames } from './settingsActions';
import showAllocations from './showAllocations';
import showAllUsers from './showAllUsers';
import showRemainingHours from './showRemainingHours';
import userAllocationsMode from './userAllocationsMode';
import changeActivePeriodMode from '../../dateRange/changeActivePeriodMode';
import { TModernSchedulingAction } from '../../types';
import setProjectsSortingCriteria from './projectsSortingCriteria';

export const getNeededSettingActions = (
    settings,
    sharableLink?: boolean
): Array<TModernSchedulingAction<any>> => {
    const unassignedOpenOnInitialLoad = shouldUnassignedSectionBeOpen(
        settings.schedulingAreaData,
        !!sharableLink
    );

    const actions: any[] = [];
    if (settings.projectGroupingMode === false) {
        actions.push(toggleSetting(settingNames.projectGroupingMode, settings.projectGroupingMode));
    }
    if (settings.showAllocations) {
        actions.push(showAllocations());
    }
    if (
        settings.showAllUsers &&
        settings.schedulingAreaData &&
        isInProjectArea(settings.schedulingAreaData.schedulingAreaObjCode)
    ) {
        actions.push(showAllUsers());
    }
    if (settings.showIssues) {
        actions.push(toggleSetting(settingNames.showIssues, settings.showIssues));
    }
    if (settings.showActualProgress) {
        actions.push(toggleSetting(settingNames.showActualProgress, settings.showActualProgress));
    }
    if (settings.showCompletedWork === false) {
        actions.push(toggleSetting(settingNames.showCompletedWork));
    }
    if (settings.sortUsersByRoleEnabled) {
        actions.push(toggleSetting(settingNames.sortUsersByRoleEnabled));
    }
    if (settings.showAllocationsVisualization) {
        actions.push(
            toggleSetting(
                settingNames.showAllocationsVisualization,
                settings.showAllocationsVisualization
            )
        );
    }

    actions.push(changeActivePeriodMode(settings.stepUnit));

    if (
        settings.showRoleSummary &&
        settings.schedulingAreaData &&
        isInProjectArea(settings.schedulingAreaData.schedulingAreaObjCode)
    ) {
        actions.push(toggleSetting(settingNames.showRoleSummary, settings.showRoleSummary));
    }
    if (settings.showRemainingHours) {
        actions.push(showRemainingHours());
    }
    if (
        !sharableLink &&
        !Number.isNaN(settings.unassignedWorkHeight) &&
        !unassignedOpenOnInitialLoad
    ) {
        actions.push(changeCollapsedMode(!settings.unassignedWorkHeight));
        const unassignedWorkHeight = getUnassignedWorkHeight(settings.unassignedWorkHeight);
        setWorkSchedulingTableHeightsToLocalStorage(unassignedWorkHeight);
    }
    if (settings.timelinePeriodMode) {
        actions.push(
            timelineNavigation(
                TimelineActions.INITIAL_SETUP,
                +settings.timelinePeriodMode,
                moment(),
                settings.timelinePeriodTimeUnit
            )
        );
    }
    if (settings.showAllocationsInHoursMode === false) {
        actions.push(userAllocationsMode(HoursModeActions.PERCENTAGE));
    }

    if (settings.projectColorsMode) {
        actions.push(setProjectColorsSettingsMode(settings.projectColorsMode));
    }

    if (settings.schedulingAreaData) {
        actions.push(setAreaData(settings.schedulingAreaData));

        if (unassignedOpenOnInitialLoad) {
            actions.push(changeCollapsedMode(false));
            const unassignedWorkHeight = getUnassignedWorkHeight(
                settings.unassignedWorkHeight,
                250
            );
            setWorkSchedulingTableHeightsToLocalStorage(unassignedWorkHeight);
        }
    }

    if (settings.projectsSortingCriteria) {
        actions.push(setProjectsSortingCriteria(settings.projectsSortingCriteria));
    }

    const filtersActions = getFiltersActions(settings);

    return actions.concat(filtersActions);
};

export const getFiltersActions = (settings): TAddFilterIDAction[] => {
    const actions: any[] = [];

    if (settings[filterUserPrefKey[Sections.PEOPLE_WORKLOAD]]) {
        actions.push(
            addFilterID(
                settings[filterUserPrefKey[Sections.PEOPLE_WORKLOAD]],
                Sections.PEOPLE_WORKLOAD
            )
        );
    }

    if (settings[filterUserPrefKey[Sections.UNASSIGNED_WORK]]) {
        actions.push(
            addFilterID(
                settings[filterUserPrefKey[Sections.UNASSIGNED_WORK]],
                Sections.UNASSIGNED_WORK
            )
        );
    }

    return actions;
};

/**
 *
 * @param schedulingAreaData
 * @param sharableLink
 */
export const shouldUnassignedSectionBeOpen = (
    schedulingAreaData: IAreaState,
    sharableLink: boolean
): boolean => {
    // if not a shareable link and in project area, unassigned section should be open
    return (
        !sharableLink &&
        !!schedulingAreaData &&
        isInProjectArea(schedulingAreaData.schedulingAreaObjCode)
    );
};

/**
 *
 * @param customHeight
 * @param defaultHeight
 */
export const getUnassignedWorkHeight = (customHeight: number, defaultHeight = 0): number => {
    return customHeight || defaultHeight;
};
