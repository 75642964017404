import { Sections } from '../../../constants/schedulingTableConstants';
import { IDataState, TToggleLoadingAction } from '../IDataState';

export const ActionType = 'TOGGLE_LOADING';

const toggleLoading = (sectionType: Sections, loadingState): TToggleLoadingAction => ({
    type: ActionType,
    payload: {
        sectionType,
        loadingState,
    },
});
export default toggleLoading;

export function handle(state: IDataState, { payload }: TToggleLoadingAction): IDataState {
    const { sectionType, loadingState } = payload;

    if (sectionType === Sections.UNASSIGNED_WORK) {
        return {
            ...state,
            isInLoadingStateUnassignedWorkSection: loadingState,
        };
    }
    return {
        ...state,
        isInLoadingStatePeopleWorkloadSection: loadingState,
    };
}
