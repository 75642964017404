import { addToast } from '@phoenix/all';
import { getErrorMessage } from '../../ToastMessages';
import { TWorkSchedulingThunkAction } from '../types';
import { IUserPrefSettings } from '../settings/IUserPrefSettings';
import { settingsSelector } from '../settings/settingsActions/settingsSelector';
import { getSchedulingAreaData } from '../areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { getSchedulingArea } from '../../util/getSchedulingArea';
import { APIService } from '../../services/api-services/apiService';
import { decodeStr } from '../../util/utilities';
import actionChain from '../higher-order-reducers/actionChain';
import { getNeededSettingActions } from '../settings/settingsActions/getNeededSettingActions';

export function editSettingsThunk(): TWorkSchedulingThunkAction {
    return function _editSettingsThunk(dispatch, getState) {
        const state = getState();
        const settings: IUserPrefSettings = settingsSelector(state);
        const { schedulingAreaObjCode } = getSchedulingAreaData(state);
        const uerPrefName = getSchedulingArea(schedulingAreaObjCode);

        APIService.editSettings(settings, uerPrefName);
    };
}

export function getSettingsThunk(sharableLink: string | undefined) {
    return function _getSettingsThunk(dispatch, getState) {
        if (sharableLink) {
            const settings = decodeStr(sharableLink);

            if (settings) {
                return Promise.resolve().then(() => {
                    return dispatch(actionChain(getNeededSettingActions(settings, true))); // as above sharable link is truthy
                });
            }
        }

        const schedulingAreaData = getSchedulingAreaData(getState());
        const { schedulingAreaObjCode } = schedulingAreaData;
        const userPrefName = getSchedulingArea(schedulingAreaObjCode);
        return APIService.getSettings(userPrefName)
            .then((settings) => {
                const settingsWithState = { ...settings, schedulingAreaData };
                return dispatch(actionChain(getNeededSettingActions(settingsWithState)));
            })
            .catch(() => {
                addToast(
                    'error',
                    getErrorMessage(
                        'workloadbalancer.error.view.customizations',
                        'Your view customizations cannot be displayed. The default view displays instead. Customize the view to continue working.'
                    )
                );
            });
    };
}
