import { userHasAssignments } from '../../../util/utilities';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const removeAssignedObjectType = 'REMOVE_ASSIGNED_OBJECT';
export const ActionType = removeAssignedObjectType;

interface IRemoveAssignedObjectPayload {
    usersIDs: string[];
    taskID: string;
    projectID: string;
    projectGroupingMode: boolean;
}

type TRemoveAssignedObjectAction = TModernSchedulingAction<IRemoveAssignedObjectPayload>;

const removeAssignedObject = (
    usersIDs,
    taskID,
    projectID,
    projectGroupingMode
): TRemoveAssignedObjectAction => ({
    type: ActionType,
    payload: {
        usersIDs,
        taskID,
        projectID,
        projectGroupingMode,
    },
});
export default removeAssignedObject;

export function handle(state: IDataState, { payload }: TRemoveAssignedObjectAction): IDataState {
    const users = { ...state.users };
    const tableDataIDs = [...state.tableDataIDs];
    const deleteTableDataIDs: string[] = [];

    payload.usersIDs.forEach((userID) => {
        const nodes = { ...users[userID].nodes };
        const inaccessibleProjectIDs = [...users[userID].inaccessibleProjectIDs];
        const expandedProjectIDs = new Set(users[userID].expandedProjectIDs);
        const projectIDs = [...users[userID].projectIDs];
        const taskIDsArray = [...(nodes[payload.projectID]?.nodes || [])];
        const indexOfTask = taskIDsArray.indexOf(payload.taskID);
        const hasInaccessible = !!nodes[payload.projectID]?.inaccessibleNodesDates.length;
        const projectIDIfExists = payload.projectGroupingMode ? `_${payload.projectID}` : '';

        let expression;
        let offset;
        let initialNodesArr;

        deleteTableDataIDs.push(`${userID + projectIDIfExists}_${payload.taskID}`);

        if (indexOfTask !== -1) {
            taskIDsArray.splice(indexOfTask, 1);
        }

        if (!taskIDsArray.length) {
            const projectIdIndex = projectIDs.indexOf(payload.projectID);

            if (hasInaccessible) {
                // if there is only inaccessible task
                inaccessibleProjectIDs.push(payload.projectID);

                if (payload.projectGroupingMode) {
                    deleteTableDataIDs.push(`${userID}_${payload.projectID}_showInaccessible`);
                }
            } else {
                delete nodes[payload.projectID];
            }

            expandedProjectIDs.delete(payload.projectID);

            if (projectIdIndex !== -1) {
                projectIDs.splice(projectIdIndex, 1);
            }

            if (payload.projectGroupingMode) {
                deleteTableDataIDs.push(`${userID}_${payload.projectID}`);
            }

            offset = users[userID].offset;
            expression = userID;
            initialNodesArr = users[userID].projectIDs;
        } else {
            offset = payload.projectGroupingMode
                ? nodes[payload.projectID].offset
                : users[userID].offset;
            expression = `${userID}${projectIDIfExists}`;
            initialNodesArr = nodes[payload.projectID].nodes;
        }

        /**
         * add one more row if there is show more,
         * and after adding delete show more if not it's necessity
         */
        if (initialNodesArr.length > offset && offset > 0) {
            const showMoreIndex = tableDataIDs.indexOf(`${expression}_showMore`);
            tableDataIDs.splice(showMoreIndex, 0, `${expression}_${initialNodesArr[offset]}`);

            if (initialNodesArr.length - offset === 1) {
                deleteTableDataIDs.push(`${expression}_showMore`);
            }
        }

        if (taskIDsArray.length || hasInaccessible) {
            nodes[payload.projectID] = {
                ...nodes[payload.projectID],
                nodes: taskIDsArray,
            };
        }

        users[userID] = {
            ...users[userID],
            inaccessibleProjectIDs,
            hasAssignments: userHasAssignments(nodes),
            expanded:
                (!!projectIDs.length || !!inaccessibleProjectIDs.length || hasInaccessible) &&
                users[userID].expanded,
            nodes,
            projectIDs,
            expandedProjectIDs,
        };
    });

    deleteTableDataIDs.forEach((idExpression) => {
        const index = tableDataIDs.indexOf(idExpression);

        if (index !== -1) {
            tableDataIDs.splice(index, 1);
        }
    });

    return {
        ...state,
        users,
        tableDataIDs,
    };
}
