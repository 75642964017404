import React from 'react';
import ViewAllocationsIcon from 'phoenix-icons/dist/ViewAllocationsIcon.js';
import { Button, Tooltip } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import { prepareTestId } from '../../../util/utilities';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import showAllocations from '../../../data-flow/settings/settingsActions/showAllocations';
import { resetContouringEditModeChain } from '../../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import { TMessageKey } from '../../../data-flow/data/IDataState';
import { getHeaderItemStyles } from '../../styles/headerComponentStyles';
import { useAppDispatch, useAppSelector } from '../../../data-flow/hooks';
import { showAllocationsSelector } from '../../../data-flow/settings/settingsSelector';
import { TAreaObjCodes } from '../../../data-flow/areaData/areaRelatedInitialDataState';

interface IShowAllocationsButtonProps {
    saveSettingsAndResetContouring: () => void;
    sharableLink: boolean;
    schedulingAreaObjCode: TAreaObjCodes;
}

const getAllocationMessageKey = (isShowAllocationOn: boolean): TMessageKey => {
    return isShowAllocationOn
        ? {
              messageKey: 'workloadbalancer.hide.allocation',
              fallBack: 'Hide allocations',
          }
        : {
              messageKey: 'workloadbalancer.show.allocation',
              fallBack: 'Show allocations',
          };
};

export const ShowAllocationsButton = React.memo<IShowAllocationsButtonProps>((props) => {
    const { saveSettingsAndResetContouring, schedulingAreaObjCode, sharableLink } = props;
    const dispatch = useAppDispatch();
    const isShowAllocationOn: boolean = useAppSelector((state) => showAllocationsSelector(state));

    const handleShowAllocations = (): void => {
        dispatch(actionChain([showAllocations(), ...resetContouringEditModeChain()]));
        saveSettingsAndResetContouring();
    };

    const allocationClassName = getHeaderItemStyles(isShowAllocationOn);

    const allocationMessageKey = getAllocationMessageKey(isShowAllocationOn);

    return (
        <Localization<string[]>
            messageKeys={[
                'workloadbalancer.toggle.allocations',
                'workloadbalancer.hide.allocation',
                'workloadbalancer.show.allocation',
            ]}
        >
            {({ _t }) => {
                return (
                    <Tooltip
                        content={_t[allocationMessageKey.messageKey](allocationMessageKey.fallBack)}
                        delay={700}
                    >
                        <Button
                            text
                            className={allocationClassName}
                            data-testid={prepareTestId(
                                allocationMessageKey.messageKey,
                                sharableLink,
                                schedulingAreaObjCode,
                                '-url'
                            )}
                            onClick={handleShowAllocations}
                            aria-pressed={isShowAllocationOn}
                            aria-label={_t['workloadbalancer.toggle.allocations'](
                                'Toggle allocations'
                            )}
                        >
                            <ViewAllocationsIcon focusable="false" />
                        </Button>
                    </Tooltip>
                );
            }}
        </Localization>
    );
});
