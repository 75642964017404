import { css } from 'emotion';
import * as React from 'react';
import { ProjectNode } from './ProjectNode';
import { TaskIssueNode } from './TaskIssueNode';
import {
    IUnassignedTaskProjectValue,
    TOnUnassignedProjectArrowClick,
} from '../../../../data-flow/data/IDataState';

export interface ILeftCellContentProps {
    idExpression: string;
    onUnassignedProjectArrowClick: TOnUnassignedProjectArrowClick;
    projectGroupingMode: boolean;
    hasDataIDs: boolean;
    idsArray: string[];
    projectData: IUnassignedTaskProjectValue | undefined;
}

export class UnassignedSectionContent extends React.PureComponent<ILeftCellContentProps> {
    render(): JSX.Element {
        const {
            idExpression,
            projectGroupingMode,
            hasDataIDs,
            onUnassignedProjectArrowClick,
            idsArray,
            projectData,
        } = this.props;
        const projectId = idsArray[0];
        const userId = idsArray[1];
        let component;
        if (!idExpression) {
            if (hasDataIDs) {
                component = '';
            } else {
                component = <div className={noUnassignedTasksLeftStyle} />;
            }
        } else if (projectGroupingMode && idsArray.length === 1 && projectData) {
            component = (
                <ProjectNode
                    projectID={projectId}
                    idExpression={idExpression}
                    onUnassignedProjectArrowClick={onUnassignedProjectArrowClick}
                />
            );
        } else {
            component = (
                <TaskIssueNode idExpression={idExpression} projectID={projectId} userID={userId} />
            );
        }

        return component;
    }
}

const noUnassignedTasksLeftStyle = css`
    height: 100%;
`;
