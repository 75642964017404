import styled from 'react-emotion';

interface IShareLinkPopoverStyled {
    showPopper: boolean;
}

export const ShareLinkContainer = styled('div')`
    position: relative;
`;

export const ShareLinkPopover = styled('div')<IShareLinkPopoverStyled>`
    position: absolute;
    visibility: ${(props) => (props.showPopper ? 'visible' : 'hidden')};
    z-index: 5;
    top: 33px;
    width: 300px;
    right: -3px;
    padding: 12px 12px 12px 14px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 35, 64, 0.2);
    cursor: normal;
    opacity: ${(props) => (props.showPopper ? 1 : 0)};
    transition: opacity 0.4s;
`;

export const CheckMarkIconContainer = styled('div')`
    margin-right: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 14px;
    width: 14px;
    background-color: #50b83c;
    border-radius: 50%;
`;

export const Title = styled('div')`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #15181e;
`;

export const Url = styled('textarea')`
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    color: #595f69;
    border: none;
    background: transparent;
    box-shadow: none;
    resize: none;
    min-height: 15px; /* This is needed for classic, there textareas have min-height: 50px*/
    height: 15px;
    font-family: inherit;
    padding: 0;

    &:focus {
        outline: none;
    }

    &::selection {
        outline: none;
        appearance: none;
    }
`;
