import { Resizable } from 're-resizable';
import * as React from 'react';
import { connect } from 'react-redux';
import { ScrollParams } from 'react-virtualized/dist/es/Grid';
import { cx } from 'emotion';
import {
    LOAD_UNASSIGNED_TASKS_PROJECTS_LIMIT,
    LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT,
} from '../../constants/dataConstatnts';
import { CJA_ANALYIZING_TRACK, RECOMPUTE_GRID_SIZE } from '../../constants/events';
import { Sections, sizes, systemScrollbarSize } from '../../constants/schedulingTableConstants';
import { GlobalPropsContext } from '../../contexts/globalContexts';
import changeContouringRowState from '../../data-flow/data/assignedDataActions/changeContouringRowState';
import removeTemporaryWorkPerDayHours from '../../data-flow/data/assignedDataActions/removeTemporaryWorkPerDayHours';
import setHeightForPeopleWorkloadEmptyRow from '../../data-flow/data/assignedDataActions/setHeightForPeopleWorkloadEmptyRow';
import addLoadingRow from '../../data-flow/data/dataLoadingActions/addLoadingRow';
import toggleLoading from '../../data-flow/data/dataLoadingActions/toggleLoading';
import {
    TIsBulkAssignmentPanelOpened,
    TIsPanelOpened,
    TTableLeftSidePanelWidth,
} from '../../data-flow/data/IDataState';
import setAppearingRowsIndexes from '../../data-flow/data/nodeItemActions/setRenderedStartStopIndexes';
import {
    assignmentDialogDetailsSelector,
    contouringRowIdSelector,
    tableDataIDsForUnassignedSectionSelector,
    draggingFromAreaSelector,
    hasMoreUsersSelector,
    hasMoreUnassignedTasksSelector,
} from '../../data-flow/data/selectors/dataSelectors';
import setHeightForUnassignedSectionEmptyRow from '../../data-flow/data/unassignedDataActions/setHeightForUnassignedSectionEmptyRow';
import { endDateSelector } from '../../data-flow/dateRange/selectors/endDateSelector';
import { startDateSelector } from '../../data-flow/dateRange/selectors/startDateSelector';
import { stepUnitSelector } from '../../data-flow/dateRange/selectors/stepUnitSelector';
import { isErrorThrownSelector } from '../../data-flow/errorData/errorDataSelector';
import actionChain from '../../data-flow/higher-order-reducers/actionChain';
import { internalEventEmitterSelector } from '../../data-flow/instances/internalEventEmitterSelector';
import setInitialSettingsState from '../../data-flow/settings/settingsActions/setInitialSettingsState';
import changeCollapsedMode from '../../data-flow/tableSizes/actions/changeCollapsedMode';
import changeDraggableMode from '../../data-flow/tableSizes/actions/changeDraggableMode';
import changeResizableHeight from '../../data-flow/tableSizes/actions/changeResizableHeight';
import changeSnapToZero from '../../data-flow/tableSizes/actions/changeSnapToZero';
import setAvailableHeight from '../../data-flow/tableSizes/actions/setAvailableHeight';
import setWorkTablesHeights from '../../data-flow/tableSizes/actions/setWorkTablesHeights';
import { saveAssignmentThunk } from '../../data-flow/thunks/changeAssignmentsThunk';
import { loadInitDataThunk, loadUsersThunk } from '../../data-flow/thunks/loadDataThunk';
import { IWorkSchedulingCombinedState, IWorkSchedulingDispatchProp } from '../../data-flow/types';
import { isDateChanged } from '../../util/actionsComponentService';
import { pendoAnalyticsTracker } from '../../util/analytics/pendoAnalyticsTracker';
import { isInDateRange } from '../../util/durationStylesCalculation';
import {
    getWorkSchedulingTableHeightsFromLocalStorage,
    setLeftSidePanelWidthToLocalStorage,
    setWorkSchedulingTableHeightsToLocalStorage,
} from '../../util/localStorage';
import { minixDataRestructuring } from '../../util/minixDataRestructuring';
import {
    getAvailableHeightForTables,
    getInitialHeightsOfTables,
    getLimitForLazyLoad,
    getRowCountRelatedToHeight,
    isInProjectArea,
    getWidgetContainerWidth,
    isExistsStringInArray,
} from '../../util/utilities';
import { SchedulingHeader } from '../schedulingTable/header/SchedulingHeader';
import { NoUsers } from '../schedulingTable/right/peopleWorkLoadSection/NoUsers/NoUsers';
import {
    containerClassName,
    resizable_base_style,
    rootElementStyle,
    SchedulingWrapperStyled,
} from './SchedulingStyles';
import {
    calculateTableMinWidth,
    canShowRoleSummary,
    canShowUnassignedDropOverlay,
    getCompletenessStatusChanged,
    getWidgetWidth,
    hasAssignmentOrTeamAssignment,
    isMinixUpdated,
    isPlannedCompletionOrStartDatesChanged,
    oldOrUpdatedDataExists,
} from './schedulingUtils';
import {
    excludedUserIDsForLazyLoadSelector,
    getLoadedUsersCount,
} from '../../data-flow/data/selectors/users/usersSelector';
import {
    isBulkAssignmentPanelOpenedSelector,
    isPanelOpenedSelector,
    projectGroupingModeSelector,
    showActualProgressSelector,
    showRoleSummarySelector,
} from '../../data-flow/settings/settingsSelector';
import { IGeneralStateTypes } from '../../data-flow/IGeneralStateTypes';
import { unassignedWorkHeightSelector } from '../../data-flow/tableSizes/selectors/unassignedWorkHeightSelector';
import { periodModeSelector } from '../../data-flow/dateRange/selectors/periodModeSelector';
import { UnassignedDropOverlay } from '../schedulingTable/UnassignedDropOverlay/UnassignedDropOverlay';
import {
    tableDataIDsSelector,
    tableDataIDsSelectorWithoutHeaderSelector,
} from '../../data-flow/data/selectors/tableDataIDsSelector';
import {
    availableHeightSelector,
    isDraggingSelector,
    peopleWorkLoadHeightSelector,
    resizableHeightSelector,
    snapToZeroSelector,
    tableLeftSidePanelWidthSelector,
} from '../../data-flow/tableSizes/selectors/tableSizesSelectors';
import { tableOpenedStateSelector } from '../../data-flow/tableSizes/selectors/tableOpenedStateSelector';
import { projectIDsWithinTableDataIDsForUnassignedSectionSelector } from '../../data-flow/data/selectors/reselect/projectIDsWithinTableDataIDsForUnassignedSectionSelector';
import {
    isInLoadingStatePeopleWorkloadSectionSelector,
    loadingStateUnassignedWorkSectionSelector,
} from '../../data-flow/data/selectors/isInLoadingState/isInLoadingStateSelectors';
import { getSchedulingAreaData } from '../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { loadedUnassignedProjectsCountSelector } from '../../data-flow/data/selectors/unassignedTasksProjectsSelectors/unassignedTasksProjectsSelectors';
import { getContextValue } from '../../contexts/checkContext';
import { SchedulingTableUnassigned } from '../schedulingTable/schedulingTables/SchedulingTableUnassigned';
import { SchedulingTableAssigned } from '../schedulingTable/schedulingTables/SchedulingTableAssigned';
import { SectionsResizeHelper } from '../schedulingTable/header/SectionsResizeHelper';
import ScrollSyncCustom from './ScrollSyncCustom';
import {
    resizeHandlerDragging,
    resizeHandlerHorizontalHover,
    resizeHandlerHover,
    resizeHorizontalLine,
    leftSidePanelScroll,
} from '../schedulingTable/schedulingTables/schedulingTableStyles';
import setTableLeftSidePanelWidth from '../../data-flow/tableSizes/actions/setTableLeftSidePanelWidth';
import { horizontalResizeBarClassName } from '../../constants/schedulingConstants';
import {
    loadDataForUnassignedSection,
    loadUnassignedAssignments,
    loadUnassignedAssignmentsProjects,
} from '../../data-flow/thunks/unassignedSectionLoadDataThunk';
import { editSettingsThunk, getSettingsThunk } from '../../data-flow/thunks/settings';
import { loadInitFilterThunk } from '../../data-flow/thunks/loadFilterDataThunk/loadFilterDataThunk';
import { loadMissingDataForAssignedSectionThunk } from '../../data-flow/thunks/assignedSectionLoadDataThunk';
import { SchedulingPanels } from './SchedulingPanels';
import { loadWorkSummaryForRoles } from '../../data-flow/thunks/roleSummary/toggleRoleSummaryThunk';
import { trackAnalyzing } from '../../data-flow/thunks/cjaTracker';

type ISchedulingComponentStateToProps = Pick<
    IGeneralStateTypes,
    | 'stepUnit'
    | 'tableDataIDs'
    | 'tableDataIDsForUnassignedSection'
    | 'hasMoreUsers'
    | 'hasMoreUnassignedTasks'
    | 'isDragging'
    | 'snapToZero'
    | 'unassignedWorkHeight'
    | 'peopleWorkLoadHeight'
    | 'resizableHeight'
    | 'availableHeight'
    | 'isTableCollapsed'
    | 'loadedUsersCount'
    | 'loadedUnassignedProjectsCount'
    | 'isInLoadingStatePeopleWorkloadSection'
    | 'isInLoadingStateUnassignedWorkSection'
    | 'periodMode'
    | 'projectGrouping'
    | 'showActualProgress'
    | 'internalEventEmitter'
    | 'assignmentDialogOpenedForID'
    | 'isRoleSummaryOpen'
    | 'contouringRowID'
    | 'startDate'
    | 'endDate'
    | 'schedulingAreaData'
    | 'excludedUserIDsForLazyLoad'
    | 'draggingFromArea'
> & {
    isFilterOpen: boolean;
    loadedUnassignedTasksCount: number;
    isPanelOpened: TIsPanelOpened;
    isBulkAssignmentPanelOpened: TIsBulkAssignmentPanelOpened;
    leftSidePanelSavedWidth: TTableLeftSidePanelWidth;
    isErrorThrown: boolean;
    tableDataIDsWithoutHeaderRow: string[];
};

const dataTestIds = {
    unassigned_work: 'unassigned_work',
    unassigned_drop_overlay: 'unassigned_drop_overlay',
    people_workload: 'people_workload',
};

export type SchedulingComponentProps = IWorkSchedulingDispatchProp &
    ISchedulingComponentStateToProps;
export class SchedulingComponent extends React.PureComponent<SchedulingComponentProps, any> {
    static contextType = GlobalPropsContext;
    context!: React.ContextType<typeof GlobalPropsContext>;

    private readonly unassignedWorkRef: React.RefObject<Resizable>;
    private scrollTimeOut: number;
    private appearingSectionTimeOut: number;

    constructor(props) {
        super(props);
        this.unassignedWorkRef = React.createRef();
        this.scrollTimeOut = 0;
        this.appearingSectionTimeOut = 0;
        this.state = {
            isPeopleWorkloadScrolled: false,
            isUnassignedSectionScrolled: false,
            widgetContainerWidth: getWidgetContainerWidth(),
            areSettingsFetched: false,
            leftSidePanelWidth: sizes.tableLeftColumnWidth,
        };
    }

    componentDidMount(): void {
        const id = setTimeout(() => {
            dispatch(trackAnalyzing());
        }, 45000);
        this.props.internalEventEmitter.on(CJA_ANALYIZING_TRACK, () => {
            clearTimeout(id);
        });
        const { dispatch } = this.props;
        const sharableLink = getContextValue('sharableLink');

        if (sharableLink) {
            setWorkSchedulingTableHeightsToLocalStorage(0, 0);
        }
        const currentUser = getContextValue('currentUser');
        window.addEventListener('message', this.receivePostMessage, false);

        dispatch(
            actionChain([
                toggleLoading(Sections.UNASSIGNED_WORK, true),
                toggleLoading(Sections.PEOPLE_WORKLOAD, true),
            ])
        );
        Promise.all([
            dispatch(getSettingsThunk(sharableLink)),
            dispatch(loadInitDataThunk(currentUser)),
        ]).then(([, errMsg]) => {
            this.setState({ areSettingsFetched: true });

            dispatch(loadInitFilterThunk()).then(() => {
                const { schedulingAreaData } = this.props;

                const { peopleWorkLoadHeight, unassignedHeight } = getInitialHeightsOfTables(0);

                dispatch(loadUsersThunk(0, getRowCountRelatedToHeight(peopleWorkLoadHeight)));
                if (unassignedHeight && (!errMsg || !errMsg[1])) {
                    dispatch(loadDataForUnassignedSection());
                } else {
                    dispatch(toggleLoading(Sections.UNASSIGNED_WORK, false));
                }

                if (isInProjectArea(schedulingAreaData.schedulingAreaObjCode)) {
                    dispatch(loadWorkSummaryForRoles());
                }
            });
        });
    }

    componentWillUnmount(): void {
        window.removeEventListener('message', this.receivePostMessage, false);
        this.props.dispatch(setInitialSettingsState());
    }

    receivePostMessage = (data): void => {
        if (!isMinixUpdated(data.data.type)) {
            return;
        }

        if (this.props.contouringRowID) {
            this.props.dispatch(removeTemporaryWorkPerDayHours());
            this.props.dispatch(changeContouringRowState(null));
        }

        if (!oldOrUpdatedDataExists(data.data)) {
            return;
        }

        const { oldData, updatedData } = data.data;
        const isPlannedDatesChanged = isPlannedCompletionOrStartDatesChanged(oldData, updatedData);
        const isActualDatesChanged = isDateChanged(
            oldData.actualStartDate,
            updatedData.actualStartDate
        );

        if (!this.context.minixState || !hasAssignmentOrTeamAssignment(updatedData)) {
            return;
        }

        const isDatesChanged = isPlannedDatesChanged || isActualDatesChanged;
        const restructuredNewData = minixDataRestructuring(updatedData);
        const restructuredOldData = minixDataRestructuring(oldData);

        const afterDateChangeObjectOutOfPeriod =
            isDatesChanged &&
            !isInDateRange(
                this.props.startDate,
                this.props.endDate,
                updatedData.plannedStartDate,
                updatedData.plannedCompletionDate,
                this.props.stepUnit
            );

        const isGhost =
            !isInDateRange(
                this.props.startDate,
                this.props.endDate,
                oldData.plannedStartDate,
                oldData.plannedCompletionDate,
                this.props.stepUnit
            ) && afterDateChangeObjectOutOfPeriod;

        const completenessStatusChanged = getCompletenessStatusChanged(updatedData, oldData);

        const assignmentChangeConfig = {
            afterDateChangeObjectOutOfPeriod,
            completenessStatusChanged,
            isDatesChanged,
            isGhost,
        };
        this.props.dispatch(
            saveAssignmentThunk(
                restructuredNewData,
                restructuredOldData,
                this.context.minixState.ID,
                assignmentChangeConfig,
                true
            )
        );

        if (afterDateChangeObjectOutOfPeriod) {
            this.context.minixClose();
        }
    };

    scrollHandlerForShadow = (scrollTop: number, sectionName: string): void => {
        if (scrollTop !== 0 && !this.state[sectionName]) {
            this.setState({ [sectionName]: true });
        } else if (scrollTop === 0 && this.state[sectionName]) {
            this.setState({ [sectionName]: false });
        }
    };

    scrollHandler = (scrollArguments: ScrollParams): void => {
        this.lazyLoadAssignedData(scrollArguments);
        this.scrollHandlerForShadow(scrollArguments.scrollTop, 'isPeopleWorkloadScrolled');

        if (this.props.tableDataIDs.length) {
            clearInterval(this.scrollTimeOut);

            this.scrollTimeOut = window.setTimeout(() => {
                this.props.dispatch(loadMissingDataForAssignedSectionThunk());
            }, 200);
        }
    };

    sectionRenderedHandler = ({ rowStartIndex, rowStopIndex }): void => {
        if (this.props.tableDataIDs.length) {
            this.props.dispatch(setAppearingRowsIndexes({ rowStartIndex, rowStopIndex }));
        }
    };

    lazyLoadAssignedData = ({ clientHeight, scrollHeight, scrollTop }): void => {
        if (
            this.props.hasMoreUsers &&
            clientHeight + scrollTop + 200 > scrollHeight &&
            this.props.tableDataIDs.indexOf('_loading') === -1 &&
            !this.props.isInLoadingStatePeopleWorkloadSection
        ) {
            const { loadedUsersCount, excludedUserIDsForLazyLoad } = this.props;

            const limitForLazyLoad = getLimitForLazyLoad(
                loadedUsersCount,
                excludedUserIDsForLazyLoad.length
            );
            this.props.dispatch(loadUsersThunk(limitForLazyLoad, 10));
            this.props.dispatch(addLoadingRow(Sections.PEOPLE_WORKLOAD));
        }
    };

    loadAssignedDataAfterResize = (peopleWorkLoadHeight): void => {
        const { loadedUsersCount, dispatch } = this.props;

        clearTimeout(this.appearingSectionTimeOut);
        this.appearingSectionTimeOut = window.setTimeout(() => {
            dispatch(loadMissingDataForAssignedSectionThunk());
        }, 200);

        if (getRowCountRelatedToHeight(peopleWorkLoadHeight) >= loadedUsersCount) {
            this.lazyLoadAssignedData({ clientHeight: 0, scrollHeight: 0, scrollTop: 0 });
        }
    };

    lazyLoadUnassignedData = ({ clientHeight, scrollHeight, scrollTop }): void => {
        this.scrollHandlerForShadow(scrollTop, 'isUnassignedSectionScrolled');
        const {
            tableDataIDsForUnassignedSection,
            hasMoreUnassignedTasks,
            isInLoadingStateUnassignedWorkSection,
        } = this.props;

        if (
            clientHeight + scrollTop + 200 > scrollHeight &&
            tableDataIDsForUnassignedSection.indexOf('_loading') === -1 &&
            hasMoreUnassignedTasks &&
            !isInLoadingStateUnassignedWorkSection
        ) {
            const {
                projectGrouping,
                dispatch,
                loadedUnassignedProjectsCount,
                loadedUnassignedTasksCount,
            } = this.props;

            if (projectGrouping) {
                dispatch(
                    loadUnassignedAssignmentsProjects(
                        LOAD_UNASSIGNED_TASKS_PROJECTS_LIMIT,
                        loadedUnassignedProjectsCount
                    )
                );
            } else {
                dispatch(
                    loadUnassignedAssignments(
                        LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT,
                        loadedUnassignedTasksCount
                    )
                );
            }
            dispatch(addLoadingRow(Sections.UNASSIGNED_WORK));
        }
    };

    onTableMounted = (top): void => {
        const sharableLink = getContextValue('sharableLink');
        // - tableHeaderRowHeight as useEffect in TableHeaderUnassigned calls once and doesn't compute top properly
        const { unassignedHeight, peopleWorkLoadHeight, availableHeight } =
            getInitialHeightsOfTables(top - sizes.tableHeaderRowHeight);

        const chainedActions = [
            setWorkTablesHeights(
                unassignedHeight === 0
                    ? peopleWorkLoadHeight - sizes.tableHeaderRowHeight
                    : peopleWorkLoadHeight,
                unassignedHeight
            ),
            setAvailableHeight(availableHeight),
            changeCollapsedMode(!!sharableLink || !unassignedHeight),
        ];

        this.props.dispatch(actionChain(chainedActions));
    };

    resizeStartHandler = (): void => {
        this.props.dispatch(changeDraggableMode(true));
    };

    recomputeGridSize = (rowIndex): void => {
        this.props.internalEventEmitter.emit(RECOMPUTE_GRID_SIZE, rowIndex);
    };

    resizeHorizontalHandler = (event, dir, ref, delta): void => {
        const calculatedWidth = this.props.leftSidePanelSavedWidth + delta.width;
        const isCalculationNeeded =
            calculatedWidth >= sizes.tableLeftColumnMaxWidth ||
            calculatedWidth <= sizes.tableLeftColumnMinWidth;
        let leftSidePanelNewWidth = calculatedWidth;
        if (isCalculationNeeded) {
            leftSidePanelNewWidth =
                calculatedWidth >= sizes.tableLeftColumnMaxWidth
                    ? sizes.tableLeftColumnMaxWidth
                    : sizes.tableLeftColumnMinWidth;
        }
        this.setState(() => {
            return { leftSidePanelWidth: leftSidePanelNewWidth };
        });
    };

    resizeHorizontalStopHandler = (): void => {
        const { leftSidePanelWidth } = this.state;
        setLeftSidePanelWidthToLocalStorage(leftSidePanelWidth);
        this.props.dispatch(setTableLeftSidePanelWidth(leftSidePanelWidth));
    };

    resizeHandler = (event, dir, ref, delta): void => {
        const { height } = ref.style;
        const { availableHeight, unassignedWorkHeight, dispatch } = this.props;

        if (height !== 'auto') {
            const newHeight = parseInt(height, 10);
            const upperDirection = unassignedWorkHeight - newHeight > 0;

            const snapToZero = newHeight < 0 && (upperDirection || delta.height < 0);
            const peopleWorkLoadHeight =
                availableHeight - newHeight - (snapToZero ? sizes.tableHeaderRowHeight : 0);

            const chainedActions = [
                setWorkTablesHeights(peopleWorkLoadHeight, newHeight),
                changeSnapToZero(snapToZero),
                setHeightForUnassignedSectionEmptyRow(newHeight, this.props.projectGrouping),
            ];

            if (systemScrollbarSize > 0) {
                dispatch(
                    setHeightForPeopleWorkloadEmptyRow(
                        peopleWorkLoadHeight,
                        this.props.showActualProgress,
                        this.props.projectGrouping
                    )
                );
            }
            dispatch(actionChain(chainedActions));
            this.recomputeGridSize(0);
        }
    };

    resizeStopHandler = (): void => {
        const { snapToZero, dispatch, unassignedWorkHeight, availableHeight, projectGrouping } =
            this.props;

        if (snapToZero || unassignedWorkHeight < sizes.tableHeaderRowHeight * 2) {
            setWorkSchedulingTableHeightsToLocalStorage(0);
        } else {
            setWorkSchedulingTableHeightsToLocalStorage(unassignedWorkHeight);
        }

        const chainedActions = [
            changeDraggableMode(false),
            changeSnapToZero(false),
            snapToZero && changeCollapsedMode(true),
            setHeightForUnassignedSectionEmptyRow(unassignedWorkHeight, projectGrouping),
        ];
        if (systemScrollbarSize > 0) {
            dispatch(
                setHeightForPeopleWorkloadEmptyRow(
                    this.props.peopleWorkLoadHeight,
                    this.props.showActualProgress,
                    projectGrouping
                )
            );
        }
        dispatch(actionChain(chainedActions));
        if (snapToZero) {
            dispatch(editSettingsThunk());
        }
        this.recomputeGridSize(0);

        this.loadAssignedDataAfterResize(availableHeight - unassignedWorkHeight);
        pendoAnalyticsTracker(`VB - People WB:'NWE -' Resize Unassigned`);
    };

    onWindowResize = (top: number): void => {
        const {
            peopleWorkLoadHeight,
            unassignedWorkHeight,
            availableHeight,
            isTableCollapsed,
            dispatch,
        } = this.props;
        const newAvailableHeight = getAvailableHeightForTables(top - sizes.tableHeaderRowHeight);
        const deltaAvailableHeight = newAvailableHeight - availableHeight;

        let newPeopleWorkLoadHeight;
        let newUnassignedWorkHeight;
        if (isTableCollapsed) {
            newUnassignedWorkHeight = 0;
            newPeopleWorkLoadHeight = newAvailableHeight - sizes.tableHeaderRowHeight;
        } else {
            newUnassignedWorkHeight = unassignedWorkHeight + deltaAvailableHeight / 2;
            newPeopleWorkLoadHeight = peopleWorkLoadHeight + deltaAvailableHeight / 2;
        }

        setWorkSchedulingTableHeightsToLocalStorage(newUnassignedWorkHeight);

        const chainedActions = [
            setWorkTablesHeights(newPeopleWorkLoadHeight, newUnassignedWorkHeight),
            changeResizableHeight('auto'),
            setAvailableHeight(newAvailableHeight),
            setHeightForUnassignedSectionEmptyRow(
                newUnassignedWorkHeight,
                this.props.projectGrouping
            ),
        ];
        if (systemScrollbarSize > 0) {
            dispatch(
                setHeightForPeopleWorkloadEmptyRow(
                    peopleWorkLoadHeight,
                    this.props.showActualProgress,
                    this.props.projectGrouping
                )
            );
        }
        dispatch(actionChain(chainedActions));

        this.setState({
            widgetContainerWidth: getWidgetContainerWidth(),
        });

        this.loadAssignedDataAfterResize(newPeopleWorkLoadHeight);
    };

    toggleUnAssignedSection = (): void => {
        let unassignedHeightBeforeCollapse;
        let assignedHeight;
        let unassignedWorkHeight;
        const { availableHeight, dispatch } = this.props;
        const workTablesHeights = getWorkSchedulingTableHeightsFromLocalStorage();

        if (this.props.isTableCollapsed) {
            unassignedHeightBeforeCollapse = 0;
            unassignedWorkHeight = workTablesHeights
                ? workTablesHeights.unassignedHeightBeforeCollapse
                : 0;
            if (unassignedWorkHeight === 0) {
                assignedHeight = availableHeight / 2;
                unassignedWorkHeight = assignedHeight;
            } else {
                assignedHeight = availableHeight - unassignedWorkHeight;
            }
        } else {
            unassignedHeightBeforeCollapse = this.props.unassignedWorkHeight;
            unassignedWorkHeight = 0;
            assignedHeight = availableHeight - sizes.tableHeaderRowHeight;
        }

        setWorkSchedulingTableHeightsToLocalStorage(
            unassignedWorkHeight,
            unassignedHeightBeforeCollapse
        );

        const chainedActions = [
            setWorkTablesHeights(assignedHeight, unassignedWorkHeight),
            changeResizableHeight('auto'),
            changeCollapsedMode(!this.props.isTableCollapsed),
            setHeightForUnassignedSectionEmptyRow(unassignedWorkHeight, this.props.projectGrouping),
        ];
        if (systemScrollbarSize > 0) {
            dispatch(
                setHeightForPeopleWorkloadEmptyRow(
                    assignedHeight,
                    this.props.showActualProgress,
                    this.props.projectGrouping
                )
            );
        }
        dispatch(actionChain(chainedActions));
        dispatch(editSettingsThunk());

        this.loadAssignedDataAfterResize(assignedHeight);
    };

    render(): JSX.Element | null {
        const {
            stepUnit,
            isDragging,
            snapToZero,
            unassignedWorkHeight,
            peopleWorkLoadHeight,
            resizableHeight,
            availableHeight,
            isTableCollapsed,
            tableDataIDs,
            tableDataIDsForUnassignedSection,
            isInLoadingStatePeopleWorkloadSection,
            isInLoadingStateUnassignedWorkSection,
            isFilterOpen,
            isPanelOpened,
            isRoleSummaryOpen,
            periodMode,
            assignmentDialogOpenedForID,
            schedulingAreaData,
            draggingFromArea,
            isBulkAssignmentPanelOpened,
            leftSidePanelSavedWidth,
            isErrorThrown,
            tableDataIDsWithoutHeaderRow,
        } = this.props;
        if (!this.state.areSettingsFetched) {
            return null;
        }

        const noUserData =
            !isInLoadingStatePeopleWorkloadSection && tableDataIDsWithoutHeaderRow.length === 0;

        const gridHeightPeopleWorkLoad = peopleWorkLoadHeight - sizes.tableHeaderRowHeight;

        const gridHeightUnassigned = unassignedWorkHeight;

        const tableMinWidth = calculateTableMinWidth(stepUnit, periodMode, leftSidePanelSavedWidth);

        const isAssignmentDialogOpenForUnassigned = isExistsStringInArray(
            assignmentDialogOpenedForID,
            tableDataIDsForUnassignedSection
        );
        const isAssignmentDialogOpenForAssigned = isExistsStringInArray(
            assignmentDialogOpenedForID,
            tableDataIDs
        );

        const showRoleSummary = canShowRoleSummary(
            isRoleSummaryOpen,
            schedulingAreaData.schedulingAreaObjCode
        );

        const sharableLink = getContextValue('sharableLink');
        const isMinixOpened = this.context.minixState !== null;

        const widgetWidth = getWidgetWidth(
            isFilterOpen,
            showRoleSummary,
            isPanelOpened,
            isBulkAssignmentPanelOpened,
            isMinixOpened,
            this.state.widgetContainerWidth
        );

        const { leftSidePanelWidth } = this.state;

        const tableLeftSidePanelWidth =
            leftSidePanelWidth > 0 ? leftSidePanelWidth : leftSidePanelSavedWidth;
        const tableComponent = (
            <ScrollSyncCustom>
                {({ scrollLeft, onScroll, areasData, setAreasData }) => {
                    const { horizontal, size } = areasData[Sections.PEOPLE_WORKLOAD];
                    const horizontalScrollbarSize = horizontal ? size : 0;

                    return (
                        <>
                            <Resizable
                                className={cx(
                                    resizeHorizontalLine,
                                    scrollLeft > 0 && leftSidePanelScroll
                                )}
                                onResize={this.resizeHorizontalHandler}
                                onResizeStop={this.resizeHorizontalStopHandler}
                                handleClasses={{ right: resizeHandlerHorizontalHover }}
                                size={{
                                    height: '100%',
                                    width: tableLeftSidePanelWidth,
                                }}
                                maxHeight={`calc(100% - ${horizontalScrollbarSize}px)`}
                                maxWidth={sizes.tableLeftColumnMaxWidth}
                                minWidth={sizes.tableLeftColumnMinWidth}
                            />
                            <Resizable
                                snap={snapToZero ? { y: [0] } : undefined}
                                minHeight={sizes.tableHeaderRowHeight}
                                maxHeight={availableHeight - sizes.tableHeaderRowHeight}
                                minWidth="100%"
                                ref={this.unassignedWorkRef}
                                enable={{
                                    bottom: !isTableCollapsed && !assignmentDialogOpenedForID,
                                }}
                                onResizeStart={this.resizeStartHandler}
                                onResize={this.resizeHandler}
                                onResizeStop={this.resizeStopHandler}
                                handleClasses={{
                                    bottom: isDragging
                                        ? cx(resizeHandlerDragging, horizontalResizeBarClassName)
                                        : cx(resizeHandlerHover, horizontalResizeBarClassName),
                                }}
                                size={
                                    resizableHeight
                                        ? { height: resizableHeight, width: '100%' }
                                        : undefined
                                }
                            >
                                <div data-testid={dataTestIds.unassigned_work}>
                                    {canShowUnassignedDropOverlay(
                                        draggingFromArea,
                                        this.props.isTableCollapsed
                                    ) ? (
                                        <UnassignedDropOverlay />
                                    ) : null}

                                    <SchedulingTableUnassigned
                                        sectionType={Sections.UNASSIGNED_WORK}
                                        isTableCollapsed={isTableCollapsed}
                                        dataIDs={tableDataIDsForUnassignedSection}
                                        gridHeight={gridHeightUnassigned}
                                        tableMinWidth={tableMinWidth}
                                        leftSidePanelWidth={tableLeftSidePanelWidth}
                                        isInLoadingState={isInLoadingStateUnassignedWorkSection}
                                        isAssignmentDialogOpen={isAssignmentDialogOpenForUnassigned}
                                        toggleUnAssignedSection={this.toggleUnAssignedSection}
                                        scrollLeft={scrollLeft}
                                        onScroll={onScroll}
                                        onScrollHandler={this.lazyLoadUnassignedData}
                                        setAreasData={setAreasData}
                                        areasData={areasData}
                                    >
                                        <SectionsResizeHelper
                                            onTableMounted={this.onTableMounted}
                                            onWindowResize={this.onWindowResize}
                                        />
                                    </SchedulingTableUnassigned>
                                </div>
                            </Resizable>
                            <div className={`__resizable_base__ ${resizable_base_style}`} />
                            <div data-testid={dataTestIds.people_workload}>
                                <SchedulingTableAssigned
                                    sectionType={Sections.PEOPLE_WORKLOAD}
                                    isTableCollapsed={false}
                                    sectionRenderedHandler={this.sectionRenderedHandler}
                                    dataIDs={tableDataIDs}
                                    gridHeight={gridHeightPeopleWorkLoad}
                                    leftSidePanelWidth={tableLeftSidePanelWidth}
                                    tableMinWidth={tableMinWidth}
                                    isInLoadingState={isInLoadingStatePeopleWorkloadSection}
                                    isAssignmentDialogOpen={isAssignmentDialogOpenForAssigned}
                                    scrollLeft={scrollLeft}
                                    onScroll={onScroll}
                                    onScrollHandler={this.scrollHandler}
                                    setAreasData={setAreasData}
                                    areasData={areasData}
                                >
                                    {noUserData && !isErrorThrown && (
                                        <NoUsers
                                            width={tableMinWidth}
                                            schedulingAreaObjCode={
                                                schedulingAreaData.schedulingAreaObjCode
                                            }
                                        />
                                    )}
                                </SchedulingTableAssigned>
                            </div>
                        </>
                    );
                }}
            </ScrollSyncCustom>
        );

        return (
            <div className={rootElementStyle}>
                <SchedulingHeader onWindowResize={this.onWindowResize} />
                <div className={containerClassName}>
                    <SchedulingWrapperStyled
                        decreaseFromWidth={widgetWidth}
                        sharableLink={sharableLink}
                        isAssignmentDialogOpen={
                            isAssignmentDialogOpenForAssigned || isAssignmentDialogOpenForUnassigned
                        }
                        className="scheduling-container"
                    >
                        {tableComponent}
                    </SchedulingWrapperStyled>

                    <SchedulingPanels
                        isBulkAssignmentPanelOpened={isBulkAssignmentPanelOpened}
                        isFilterOpen={isFilterOpen}
                        isPanelOpened={isPanelOpened}
                        showRoleSummary={showRoleSummary}
                        schedulingAreaData={schedulingAreaData}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IWorkSchedulingCombinedState): ISchedulingComponentStateToProps => {
    return {
        stepUnit: stepUnitSelector(state),
        tableDataIDs: tableDataIDsSelector(state),
        tableDataIDsWithoutHeaderRow: tableDataIDsSelectorWithoutHeaderSelector(state.Data),
        tableDataIDsForUnassignedSection: tableDataIDsForUnassignedSectionSelector(state),
        hasMoreUsers: hasMoreUsersSelector(state),
        hasMoreUnassignedTasks: hasMoreUnassignedTasksSelector(state),
        isDragging: isDraggingSelector(state),
        snapToZero: snapToZeroSelector(state),
        unassignedWorkHeight: unassignedWorkHeightSelector(state),
        peopleWorkLoadHeight: peopleWorkLoadHeightSelector(state),
        resizableHeight: resizableHeightSelector(state),
        availableHeight: availableHeightSelector(state),
        isTableCollapsed: tableOpenedStateSelector(state),
        loadedUsersCount: getLoadedUsersCount(state),
        loadedUnassignedTasksCount:
            projectIDsWithinTableDataIDsForUnassignedSectionSelector(state).length,
        loadedUnassignedProjectsCount: loadedUnassignedProjectsCountSelector(state),
        isInLoadingStatePeopleWorkloadSection: isInLoadingStatePeopleWorkloadSectionSelector(state),
        isInLoadingStateUnassignedWorkSection: loadingStateUnassignedWorkSectionSelector(state),
        periodMode: periodModeSelector(state),
        projectGrouping: projectGroupingModeSelector(state),
        showActualProgress: showActualProgressSelector(state),
        internalEventEmitter: internalEventEmitterSelector(state),
        assignmentDialogOpenedForID: assignmentDialogDetailsSelector(state).ID,
        isFilterOpen:
            state.Filters[Sections.PEOPLE_WORKLOAD].isFilterOpen ||
            state.Filters[Sections.UNASSIGNED_WORK].isFilterOpen,
        isRoleSummaryOpen: showRoleSummarySelector(state),
        isPanelOpened: isPanelOpenedSelector(state),
        contouringRowID: contouringRowIdSelector(state),
        startDate: startDateSelector(state),
        endDate: endDateSelector(state),
        schedulingAreaData: getSchedulingAreaData(state),
        excludedUserIDsForLazyLoad: excludedUserIDsForLazyLoadSelector(state),
        draggingFromArea: draggingFromAreaSelector(state),
        isBulkAssignmentPanelOpened: isBulkAssignmentPanelOpenedSelector(state),
        leftSidePanelSavedWidth: tableLeftSidePanelWidthSelector(state),
        isErrorThrown: isErrorThrownSelector(state, Sections.PEOPLE_WORKLOAD),
    };
};

export const Scheduling = connect(mapStateToProps)(SchedulingComponent);
