import { Button, Ellipse, primary } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import DownCaratIcon from 'phoenix-icons/dist/DownCaratIcon.js';
import ProjectSmallIcon from 'phoenix-icons/dist/ProjectSmallIcon.js';
import RightCaratIcon from 'phoenix-icons/dist/RightCaratIcon.js';
import * as React from 'react';
import styled, { css } from 'react-emotion';
import { Sections } from '../../../../constants/schedulingTableConstants';
import { IProjectColor } from '../../../../data-flow/data/IDataState';
import { convertMinuteToHour, formatNumber, isInProjectArea } from '../../../../util/utilities';
import { localizationClient } from '../../../../constants/LocalizationClientFactory';
import {
    arrowCaret,
    arrowCaretPW,
    IProjectDivStyled,
    projectDetails,
    projectDiv,
    projectIcon,
    WorkingHoursStyled,
    workRequired,
    workRequiredHourAbbr,
} from '../../../styles/sharedStyles';
import { ProjectName } from '../ProjectName/ProjectName';
import { getContextValue } from '../../../../contexts/checkContext';

interface IProjectNodeHTML extends React.HTMLAttributes<HTMLDivElement> {
    name: string;
    isAccessible: boolean;
    expanded: boolean;
    projectID: string;
    workRequired?: number;
    handleArrowClick: () => void;
    projectType?: string;
    sectionType: Sections;
    isBottomLineHidden?: boolean;
    isLastRow: boolean;
    projectColoredData?: IProjectColor;
    projectStatusLabel?: string;
    schedulingAreaObjCode: string | undefined;
}

export const ProjectNodeHTML: React.FC<IProjectNodeHTML> = (props) => {
    const sharableLink = getContextValue('sharableLink');
    const { sectionType, schedulingAreaObjCode = '', expanded, name } = props;

    const inAssignedSection = sectionType === Sections.PEOPLE_WORKLOAD;
    const expandCollapseButtonStyle = expandCollapseStyle(schedulingAreaObjCode, sectionType);
    const dataTestIdForArrowButton = getDataTestIdForArrowButton(
        schedulingAreaObjCode,
        expanded,
        sectionType,
        sharableLink
    );
    const classNameForArrowCaret = getClassNameForArrowCaret(inAssignedSection);
    const workRequiredHours =
        sectionType === Sections.PEOPLE_WORKLOAD &&
        props.workRequired &&
        convertMinuteToHour(props.workRequired);

    return (
        <ProjectNodeWrapperStyled
            className="projectCell"
            data-testid={props.projectID}
            isBottomLineHidden={props.isBottomLineHidden}
            sectionType={sectionType}
            isLastRow={props.isLastRow}
            expanded={expanded}
        >
            {expanded && <span className={solidBorder} />}
            <div className={projectDetails} data-testid={props.projectType}>
                <div className={classNameForArrowCaret}>
                    <Button
                        text
                        onClick={props.handleArrowClick}
                        data-testid={dataTestIdForArrowButton}
                        style={expandCollapseButtonStyle}
                        aria-expanded={expanded}
                        aria-label={`${localizationClient.getTextSync(
                            'project',
                            'Project'
                        )}: ${name}`}
                    >
                        {expanded ? (
                            <DownCaratIcon color={primary.gray(500)} height={16} width={16} />
                        ) : (
                            <RightCaratIcon color={primary.gray(500)} height={16} width={16} />
                        )}
                    </Button>
                </div>
                <div className={projectIcon}>
                    <ProjectSmallIcon color={!props.isAccessible && primary.gray(400)} />
                </div>
                <ProjectName
                    isAccessible={props.isAccessible}
                    name={name}
                    ID={props.projectID}
                    projectColoredData={props.projectColoredData}
                    projectStatusLabel={props.projectStatusLabel}
                    showTooltip={!props.isAccessible}
                    shouldActAsLink={shouldActAsLink(schedulingAreaObjCode, inAssignedSection)}
                    {...(workRequiredHours && {
                        workRequiredHours,
                    })}
                    expanded={expanded}
                />
                {inAssignedSection && (
                    <WorkingHoursStyled>
                        <div className={workRequired}>
                            {props.workRequired && (
                                <Ellipse>
                                    {formatNumber(convertMinuteToHour(props.workRequired))}
                                </Ellipse>
                            )}
                        </div>
                        <div className={workRequiredHourAbbr}>
                            <Localization<string> messageKey="hour.abbr.short" fallback="h" />
                        </div>
                    </WorkingHoursStyled>
                )}
            </div>
        </ProjectNodeWrapperStyled>
    );
};

const borderBottomStyle = (
    isLastRow: boolean,
    sectionType?: string,
    expanded?: boolean
): string => {
    const borderColor = expanded ? 'transparent' : primary.gray(200);
    return isLastRow && sectionType === Sections.PEOPLE_WORKLOAD
        ? `1px solid ${borderColor}`
        : `1px dashed ${borderColor}`;
};
export const ProjectNodeWrapperStyled = styled('div')<IProjectDivStyled>`
    ${projectDiv};
    border-bottom: ${(props) =>
        borderBottomStyle(props.isLastRow, props.sectionType, props.expanded)};
    background-color: ${(props) =>
        props.sectionType === Sections.PEOPLE_WORKLOAD && primary.gray(50)};
`;

export const solidBorder = css`
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 26px;
    border-top: 1px solid ${primary.gray(200)};
`;

/**
 *
 * @param schedulingAreaObjCode
 * @param sectionType
 */
export const expandCollapseStyle = (
    schedulingAreaObjCode: string,
    sectionType: Sections
): React.CSSProperties => {
    const style: React.CSSProperties = {
        /**
         * Cypress complains that it can't click on items as in above hierarchy
         * there is set pointer-events: none;
         */
        pointerEvents: 'initial',
    };
    const isInProjectScope = isInProjectArea(schedulingAreaObjCode);
    const inAssignedSection = sectionType === Sections.PEOPLE_WORKLOAD;
    const hideExpandCollapseButton = isInProjectScope && !inAssignedSection;

    if (hideExpandCollapseButton) {
        style.display = 'none';
    }

    return style;
};

/**
 *
 * @param schedulingAreaObjCode
 * @param inAssignedSection
 */
export const shouldActAsLink = (
    schedulingAreaObjCode: string,
    inAssignedSection: boolean
): boolean => {
    return !(isInProjectArea(schedulingAreaObjCode) && !inAssignedSection);
};

/**
 *
 * @param schedulingAreaObjCode
 * @param expandedStatus
 * @param sectionType
 * @param sharableLink
 */
export const getDataTestIdForArrowButton = (
    schedulingAreaObjCode: string,
    expandedStatus: boolean,
    sectionType?: Sections,
    sharableLink?: string | boolean
): string => {
    const sType = sectionType ?? '';
    // another test id generation logic in utilities.prepareTestId and utilities.prepareSettingsIconTestId
    // the logic should become unique with separate tech story

    if (expandedStatus) {
        return `${sType}_project_${
            sharableLink ? 'collapse-url' : 'collapse'
        }${schedulingAreaObjCode}`;
    }
    return `${sType}_project_${sharableLink ? 'expand-url' : 'expand'}${schedulingAreaObjCode}`;
};

/**
 *
 * @param inAssignedSection
 */
export const getClassNameForArrowCaret = (inAssignedSection: boolean): string => {
    return inAssignedSection ? arrowCaretPW : arrowCaret;
};
