import { DragSource } from 'react-dnd';
import React, { CSSProperties, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { RGBA_COLORS } from '../../../constants/appColors';
import { osTypes } from '../../../constants/dataConstatnts';
import {
    Sections,
    sizes,
    assignmentPopoverSizes,
} from '../../../constants/schedulingTableConstants';
import { useAssignmentPopover } from '../../../common-hooks/useAssignmentPopover';
import AssignmentPopover from './AssignmentPopover';

import { GlobalPropsContext, IGlobalPropsContext } from '../../../contexts/globalContexts';
import changeContouringRowState from '../../../data-flow/data/assignedDataActions/changeContouringRowState';
import {
    closeAllPanelsChain,
    resetContouringEditMode,
} from '../../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import {
    IFullTimeOffSteps,
    IObjectState,
    IUnassignedTaskState,
    TOrUndefined,
    TWorkPerDays,
} from '../../../data-flow/data/IDataState';
import {
    changedAssignmentSelector,
    contouringRowIdSelector,
    loadWorkDelegationsSelector,
} from '../../../data-flow/data/selectors/dataSelectors';
import changeIDExpressionForMinixState from '../../../data-flow/data/sharedActions/changeIDExpressionForMinixState';
import { IDateRangeState } from '../../../data-flow/dateRange/IDateRangeState';
import { endDateSelector } from '../../../data-flow/dateRange/selectors/endDateSelector';
import { startDateSelector } from '../../../data-flow/dateRange/selectors/startDateSelector';
import { stepUnitSelector } from '../../../data-flow/dateRange/selectors/stepUnitSelector';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import { cellWidthSelector } from '../../../data-flow/tableSizes/selectors/cellWidthSelector';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
    TTypeOfIDs,
} from '../../../data-flow/types';
import { checkShowContouring } from '../../../util/actionsComponentService';
import {
    getOpenedDialogsDetails,
    pendoAnalyticsTracker,
    setNameForDoubleClickEventTracking,
} from '../../../util/analytics/pendoAnalyticsTracker';
import {
    calculateAssignmentWidth,
    calcWidthAndLeftPosition,
    isAfterPeriod,
    isBeforePeriod,
    shouldBeArrow,
} from '../../../util/durationStylesCalculation';
import { getObjectBackgroundColor } from '../../../util/getObjectBackgroundColor';
import { getOS, isIssue, transformRGBAColorKey } from '../../../util/utilities';
import { AssignmentPreWrapperStyled } from '../../styles/allocationsComponentStyles';
import { StyledAssignmentContainer } from '../../styles/AssignmentComponentStyles';
import { ActualProgressBlockWrapper } from './ActualProgress/ActualProgressBlockWrapper';
import { openAssignmentDialog } from './AssignmentDialog';
import {
    collect,
    dragConfiguration,
    DragDropTypes,
    ICollect,
} from '../../../dragAndDrop/dragAndDropConfigurations';
import { AssignmentBarComponent } from './AssignmentBar';
import { getIsDraggingEnabled } from '../../../util/dragAndDropUtilities';
import { internalEventEmitterSelector } from '../../../data-flow/instances/internalEventEmitterSelector';
import {
    projectGroupingModeSelector,
    showActualProgressSelector,
    showAllocationsSelector,
} from '../../../data-flow/settings/settingsSelector';
import { peopleWorkLoadHeightSelector } from '../../../data-flow/tableSizes/selectors/tableSizesSelectors';
import { getUnassignedTaskSelector } from '../../../data-flow/data/selectors/reselect/getUnassignedTaskSelector/getUnassignedTaskSelector';
import { getTaskSelector } from '../../../data-flow/data/selectors/reselect/getTaskSelector/getTaskSelector';
import { objectAssignmentsDetailsSelector } from '../../../data-flow/data/selectors/reselect/objectAssignmentsDetailsSelector/objectAssignmentsDetailsSelector';
import { workPerDaysSelectorForPeriodUnassignedSectionService } from '../../../data-flow/data/selectors/rereselect/workPerDaysSelectorForPeriodUnassignedSectionService';
import { workPerDaysSelectorForPeriodFromService } from '../../../data-flow/data/selectors/rereselect/workPerDaysSelectorForPeriodFromService';
import { projectColorDeciderSelector } from '../../../data-flow/data/selectors/projectStatusSelectors/projectStatusSelectors';
import { getContextValue } from '../../../contexts/checkContext';
import { draggableItemZIndex } from '../../../constants/zIndexes';
import { dragAndDropIDs } from '../../../dragAndDrop/dragAndDropTestIDs';
import { IGeneralStateTypes } from '../../../data-flow/IGeneralStateTypes';
import { draggingOpacity } from './DraggingOpacityConstant';
import {
    getAssignmentBackground,
    getAssignmentBackgroundColor,
    getAssignmentTextColor,
} from '../utils';
import { readyToStart } from '../../../util/readyToStart';

const { durationPadding } = sizes;

interface IAssignmentComponentProps
    extends IAssignmentProps,
        ICollect,
        IAssignmentStateProps,
        IWorkSchedulingDispatchProp {}

interface IAssignmentProps extends Pick<IDateRangeState, 'stepUnit'> {
    ID: string;
    isComplete?: boolean;
    isAssigned: boolean;
    isTheLastRow: boolean;
    startDate: Moment;
    endDate: Moment;
    cellWidth: number;
    sectionType: Sections;
    idExpression: string;
    fullTimeOffsStepsByDay?: IFullTimeOffSteps;
    userScheduleID?: string;
    userID?: string;
}

type IAssignmentStateProps = Pick<
    IGeneralStateTypes,
    | 'stepUnit'
    | 'peopleWorkLoadHeight'
    | 'cellWidth'
    | 'startDate'
    | 'endDate'
    | 'nodeData'
    | 'contouringRowID'
    | 'showActualProgress'
    | 'projectColoredData'
    | 'projectGroupingMode'
    | 'internalEventEmitter'
> & {
    isObjectInAssignmentMode: boolean;
    isShowAllocationOn: boolean;
    contouringEditAccess: boolean;
    hasAssignmentsDetails: boolean;
    loadWorkDelegations: boolean;
    crossedOutHoursForPeriod?: number[];
    workPerDays: TWorkPerDays;
};

interface IOnClickHandlerProps extends IAssignmentComponentProps {
    globalPropsContext: IGlobalPropsContext;
    editContouring: boolean;
}

interface IOnClickHandlerStateProps {
    clickTimeout: number;
}

const AssignmentComponent: React.FunctionComponent<IAssignmentComponentProps> = React.memo(
    function AssignmentComponent(props) {
        const onClickHandlerState: IOnClickHandlerStateProps = { clickTimeout: 0 };
        const globalPropsContext = React.useContext(GlobalPropsContext);

        const {
            ID,
            isAssigned,
            isComplete,
            startDate,
            endDate,
            cellWidth,
            stepUnit,
            idExpression,
            isObjectInAssignmentMode,
            isShowAllocationOn,
            sectionType,
            contouringRowID,
            contouringEditAccess,
            showActualProgress,
            nodeData,
            projectColoredData,
            connectDragSource,
            userScheduleID,
            fullTimeOffsStepsByDay,
            isDragging,
            workPerDays,
            crossedOutHoursForPeriod,
            connectDragPreview,
            isTheLastRow,
            getItem,
            userID = '',
        } = props;

        useEffect(() => {
            /**
             * Disabling the native drag preview
             * */
            connectDragPreview(getEmptyImage());
        }, [connectDragPreview]);

        // now used only TaskAssignmentStyled
        const {
            objCode,
            name: title,
            assignAccess,
            projectID,
            plannedStartDate,
            plannedCompletionDate,
            projectedStartDate,
            projectedCompletionDate,
            actualCompletionDate,
            areHoursNotEqualsBeforeContouringSave,
            projectName,
            parent,
            predecessors,
        } = nodeData;

        const { workRequired, assignmentUserIDToStatus } = nodeData as IObjectState;

        const assignmentStartDate = moment(plannedStartDate);
        const assignmentEndDate = moment(plannedCompletionDate);

        const [assignmentWidth, leftPosition] = calcWidthAndLeftPosition(
            stepUnit,
            startDate,
            endDate,
            assignmentStartDate,
            assignmentEndDate,
            cellWidth,
            durationPadding
        );

        const isThereBeforeArrow = shouldBeArrow(
            assignmentWidth,
            isBeforePeriod(stepUnit, startDate, assignmentStartDate)
        );
        const isThereAfterArrow = shouldBeArrow(
            assignmentWidth,
            isAfterPeriod(stepUnit, endDate, assignmentEndDate)
        );

        const calculatedAssignmentWidth = calculateAssignmentWidth(
            isThereBeforeArrow,
            isThereAfterArrow,
            assignmentWidth,
            sizes.assignmentArrowSize
        );

        const editContouring = checkShowContouring(
            assignAccess,
            sectionType,
            contouringEditAccess,
            calculatedAssignmentWidth > 0
        );

        const allocationsEditingMode = !!contouringRowID && contouringRowID === idExpression;
        const unassignedSection = sectionType === Sections.UNASSIGNED_WORK;
        const shouldShowActualProgress = !unassignedSection && showActualProgress;

        const ids = props.idExpression.split('_');
        const taskIssueId = ids[ids.length - 1];
        const shouldMarkAsCompleted: boolean = !allocationsEditingMode && !!actualCompletionDate;

        const canApplyNewColors = !!projectColoredData && !isIssue(objCode);

        const opacity = 15;

        const isAssignmentOnDrag = idExpression === getItem?.idExpression;
        const assignmentColorBGForProjected = canApplyNewColors
            ? projectColoredData.taskRGBA[opacity]
            : RGBA_COLORS[
                  transformRGBAColorKey(
                      getObjectBackgroundColor(
                          objCode,
                          isObjectInAssignmentMode,
                          isAssignmentOnDrag
                      ),
                      opacity
                  )
              ];

        const onClickHandler = getOnClickHandler(
            {
                ...props,
                globalPropsContext,
                editContouring,
            },
            onClickHandlerState
        );

        const isDraggingEnabled = getIsDraggingEnabled(
            assignAccess,
            getContextValue('sharableLink')
        );
        const isInDisableMode = isObjectInAssignmentMode;
        const assignmentColorBG = getAssignmentBackgroundColor(
            canApplyNewColors,
            projectColoredData,
            objCode,
            isInDisableMode,
            isAssignmentOnDrag
        );
        const assignmentBackground = getAssignmentBackground(
            canApplyNewColors,
            projectColoredData,
            assignmentColorBG,
            isInDisableMode,
            isAssigned,
            sectionType,
            objCode
        );

        const assignmentBarProps = {
            assignmentWidth,
            calculatedAssignmentWidth,
            editContouring,
            isAssigned,
            isComplete,
            idExpression,
            isShowAllocationOn,
            isInDisableMode,
            isTheLastRow,
            onClickHandler,
            shouldMarkAsCompleted,
            sectionType,
            ID,
            userScheduleID,
            fullTimeOffsStepsByDay,
            workPerDays,
            crossedOutHoursForPeriod,
            nodeData,
            projectColoredData,
            isThereBeforeArrow,
            isThereAfterArrow,
            canApplyNewColors,
            allocationsEditingMode,
            startDate,
            endDate,
            cellWidth,
            stepUnit,
            contouringRowID,
            assignmentColorBG,
            assignmentBackground,
        };

        const actualProgressBlockWrapperTextColor = projectColoredData?.taskTextColorDark || '';
        const actualProgressBlockWrapperOnClickHandler = !calculatedAssignmentWidth
            ? onClickHandler
            : undefined;

        const draggableStyle = getDraggableItemStyle(
            isDragging,
            isObjectInAssignmentMode,
            isAssignmentOnDrag
        );

        const isProjectedStartDateBetweenPlannedStartAndEndDates = moment(
            projectedStartDate
        ).isBetween(plannedStartDate, plannedCompletionDate);

        const isStartDateBetweenPlannedAndProjectedDurations =
            moment(startDate).isBetween(plannedStartDate, plannedCompletionDate) &&
            moment(startDate).isBetween(projectedStartDate, projectedCompletionDate);

        const isEndDateBetweenPlannedAndProjectedDurations =
            moment(endDate).isBetween(plannedStartDate, plannedCompletionDate) &&
            moment(endDate).isBetween(projectedStartDate, projectedCompletionDate);

        const plannedAndActualAssignmentsOverlap =
            isProjectedStartDateBetweenPlannedStartAndEndDates &&
            isStartDateBetweenPlannedAndProjectedDurations &&
            isEndDateBetweenPlannedAndProjectedDurations;

        const actualProgressBlockWrapperTitle = calculatedAssignmentWidth <= 0 ? title : null;
        const assignmentColorText = getAssignmentTextColor(canApplyNewColors, projectColoredData);

        const popperOptions = useMemo(
            () => ({
                enterDelay: 1000,
                exitDelay: 200,
                parentContainerSelector: '.scheduling-container',
                scrollContainerSelector: '.ReactVirtualized__Grid',
                popperSizes: assignmentPopoverSizes,
                disabled: allocationsEditingMode,
            }),
            [allocationsEditingMode]
        );
        const { popperProps, showPopper, hidePopper, updateCoords } =
            useAssignmentPopover(popperOptions);

        useEffect(() => {
            if (isDragging) {
                hidePopper();
            }
        }, [isDragging, hidePopper]);

        const { isPopperOpen, arrowPosRight, referenceElement } = popperProps;

        const isReadyToStart = isAssigned && readyToStart(nodeData);

        return (
            <StyledAssignmentContainer
                allocationsEditingMode={allocationsEditingMode}
                showActualProgress={shouldShowActualProgress}
            >
                {isPopperOpen && (
                    <AssignmentPopover
                        show={showPopper}
                        referenceElement={referenceElement}
                        arrowPosRight={arrowPosRight}
                        hide={hidePopper}
                        taskTitle={title}
                        objCode={objCode}
                        projectName={projectName}
                        workRequired={workRequired}
                        userID={userID}
                        assignmentUserIDToStatus={assignmentUserIDToStatus}
                        taskColor={assignmentColorBG}
                        textColor={assignmentColorText}
                        taskID={ID}
                        plannedStartDate={plannedStartDate}
                        plannedCompletionDate={plannedCompletionDate}
                        parent={parent}
                        predecessors={predecessors}
                        projectID={projectID}
                        isAssigned={isAssigned}
                        canStart={isReadyToStart}
                    />
                )}
                {shouldShowActualProgress ? (
                    <ActualProgressBlockWrapper
                        allocationsEditingMode={allocationsEditingMode}
                        color={assignmentColorBGForProjected}
                        height={sizes.taskAssignmentHeight}
                        isAccessible
                        idExpression={idExpression}
                        ID={taskIssueId}
                        isCompleted={shouldMarkAsCompleted}
                        isTherePlannedBeforeArrow={isThereBeforeArrow}
                        isTherePlannedAfterArrow={isThereAfterArrow}
                        projectedCompletionDate={projectedCompletionDate}
                        projectedStartDate={projectedStartDate}
                        title={actualProgressBlockWrapperTitle}
                        textColor={actualProgressBlockWrapperTextColor}
                        onClickHandler={actualProgressBlockWrapperOnClickHandler}
                        isDraggingEnabled={isDraggingEnabled}
                        objCode={objCode}
                        sectionType={sectionType}
                        taskAssignmentBackgroundColor={assignmentColorBG}
                        plannedAndActualAssignmentsOverlap={plannedAndActualAssignmentsOverlap}
                        onMouseOver={showPopper}
                        onMouseMove={updateCoords}
                        onMouseLeave={hidePopper}
                        durationPadding={durationPadding}
                    />
                ) : null}
                <AssignmentPreWrapperStyled
                    areHoursNotEqualsBeforeContouringSave={areHoursNotEqualsBeforeContouringSave}
                    allocationsEditingMode={allocationsEditingMode}
                    leftPosition={leftPosition}
                    isThereBeforeArrow={isThereBeforeArrow}
                    onMouseOver={showPopper}
                    onMouseMove={updateCoords}
                    onMouseLeave={hidePopper}
                >
                    {isDraggingEnabled ? (
                        connectDragSource(
                            <div style={draggableStyle} data-testid={dragAndDropIDs.draggable}>
                                <AssignmentBarComponent
                                    {...assignmentBarProps}
                                    isDraggingEnabled
                                    assignmentColorText={assignmentColorText}
                                    assignmentColorBG={assignmentColorBG}
                                />
                            </div>
                        )
                    ) : (
                        <AssignmentBarComponent
                            {...assignmentBarProps}
                            assignmentColorText={assignmentColorText}
                            assignmentColorBG={assignmentColorBG}
                        />
                    )}
                </AssignmentPreWrapperStyled>
            </StyledAssignmentContainer>
        );
    }
);

const mapStateToProps = (
    state: IWorkSchedulingCombinedState,
    ownProps: {
        ID: string;
        sectionType: Sections;
        idExpression: string;
    }
): IAssignmentStateProps => {
    let nodeData: IObjectState | IUnassignedTaskState;
    let workPerDays: TWorkPerDays = [];
    let crossedOutHoursForPeriod: TOrUndefined<number[]>;
    if (ownProps.sectionType === Sections.UNASSIGNED_WORK) {
        nodeData = getUnassignedTaskSelector(state)(ownProps.ID);
        workPerDays = workPerDaysSelectorForPeriodUnassignedSectionService(state, {
            taskID: ownProps.ID,
        });
    } else {
        nodeData = getTaskSelector(state)(ownProps.ID);
        const data = {
            taskID: ownProps.ID,
            userID: ownProps.idExpression.split('_')[0],
        };
        const workPerDaysWithCrossedOutHours = workPerDaysSelectorForPeriodFromService(state, data);
        crossedOutHoursForPeriod = workPerDaysWithCrossedOutHours.crossedOutHoursForPeriod;

        workPerDays = workPerDaysWithCrossedOutHours.workPerDaysForPeriod;
    }

    const projectColoredData = projectColorDeciderSelector(
        state,
        nodeData.projectID,
        nodeData.projectGroupID,
        nodeData.projectStatus
    );

    const objectsInAssignmentMode = changedAssignmentSelector(state);

    return {
        stepUnit: stepUnitSelector(state),
        cellWidth: cellWidthSelector(state),
        endDate: endDateSelector(state),
        startDate: startDateSelector(state),
        nodeData,
        isObjectInAssignmentMode: !!(
            objectsInAssignmentMode && objectsInAssignmentMode.includes(ownProps.ID)
        ),
        isShowAllocationOn: showAllocationsSelector(state),
        contouringRowID: contouringRowIdSelector(state),
        contouringEditAccess: state.currentUser.contouringEditAccess,
        showActualProgress: showActualProgressSelector(state),
        hasAssignmentsDetails: !!objectAssignmentsDetailsSelector(state)(ownProps.ID),
        projectColoredData,
        // peopleWorkLoadHeight, projectGroupingMode, internalEventEmitter, need for dragging
        peopleWorkLoadHeight: peopleWorkLoadHeightSelector(state),
        projectGroupingMode: projectGroupingModeSelector(state),
        internalEventEmitter: internalEventEmitterSelector(state),
        workPerDays,
        crossedOutHoursForPeriod,
        loadWorkDelegations: loadWorkDelegationsSelector(state),
    };
};

const dragService = DragSource(
    DragDropTypes.ASSIGNMENT,
    dragConfiguration,
    collect
)(AssignmentComponent);

export const Assignment = connect(mapStateToProps)(dragService);

const getDraggableItemStyle = (
    isDragging: boolean,
    isObjectInAssignmentMode: boolean,
    isAssignmentOnDrag: boolean
): TOrUndefined<CSSProperties> => {
    if ((isDragging || isObjectInAssignmentMode) && isAssignmentOnDrag) {
        return {
            opacity: draggingOpacity,
            zIndex: draggableItemZIndex,
        };
    }
};

const doubleClickHandler = (props: IOnClickHandlerProps): void => {
    const {
        editContouring,
        contouringRowID,
        idExpression,
        isShowAllocationOn,
        isObjectInAssignmentMode,
        dispatch,
    } = props;

    if (
        isObjectInAssignmentMode ||
        !editContouring ||
        (editContouring && contouringRowID === idExpression) ||
        getContextValue('sharableLink')
    ) {
        return;
    }

    pendoAnalyticsTracker(
        setNameForDoubleClickEventTracking('Double Click Contouring', isShowAllocationOn)
    );
    dispatch(resetContouringEditMode());
    dispatch(changeContouringRowState(idExpression));
};

export const openMinix = (props: IOnClickHandlerProps): void => {
    const { globalPropsContext, ID, nodeData, idExpression, sectionType, dispatch } = props;

    const { objCode } = nodeData;

    if (globalPropsContext.minixState) {
        pendoAnalyticsTracker(getOpenedDialogsDetails('Open', sectionType, ' sidebar icon click'));
    } else {
        pendoAnalyticsTracker(
            getOpenedDialogsDetails('Open', sectionType, 'Summary from task click')
        );
    }

    globalPropsContext.minixOpen({ objCode, ID });

    dispatch(
        actionChain([changeIDExpressionForMinixState(idExpression), ...closeAllPanelsChain()])
    );
};

const getOnClickHandler =
    (props: IOnClickHandlerProps, state: IOnClickHandlerStateProps) =>
    (e): void => {
        const {
            ID,
            nodeData,
            idExpression,
            contouringRowID,
            hasAssignmentsDetails,
            sectionType,
            dispatch,
            loadWorkDelegations,
        } = props;

        const { assignAccess, objCode } = nodeData;

        const { clientX } = e;
        const { clientY } = e;
        const cmdKey = e.metaKey;
        const { ctrlKey } = e;

        const os = getOS();
        const isCmdPressed = (os === osTypes.mac && cmdKey) || (os !== osTypes.mac && ctrlKey);

        if (state.clickTimeout !== 0 && !isCmdPressed) {
            doubleClickHandler(props);
            clearTimeout(state.clickTimeout);
            state.clickTimeout = 0;
        } else {
            state.clickTimeout = window.setTimeout(() => {
                if (assignAccess && isCmdPressed) {
                    const IDs: TTypeOfIDs = {
                        ID,
                        idExpression,
                        contouringRowID,
                    };
                    openAssignmentDialog(
                        dispatch,
                        objCode,
                        hasAssignmentsDetails,
                        IDs,
                        sectionType,
                        loadWorkDelegations,
                        {
                            x: clientX,
                            y: clientY,
                        }
                    );
                } else {
                    openMinix(props);
                }
                state.clickTimeout = 0;
            }, 200);
        }
    };
