import { TModernSchedulingAction } from '../../types';
import { IAssignmentsDetails, IDataState } from '../IDataState';

export const ActionType = 'ADD_ASSIGNMENTS_DETAILS_TO_OBJECT';

interface IAddAssignmentsDetailsToObjectPayload {
    id: string;
    assignmentsDetails: IAssignmentsDetails[];
}

type TAddAssignmentsDetailsToObjectAction =
    TModernSchedulingAction<IAddAssignmentsDetailsToObjectPayload>;

const addAssignmentsDetailsToObject = (
    id,
    assignmentsDetails: IAssignmentsDetails[]
): TAddAssignmentsDetailsToObjectAction => ({
    type: ActionType,
    payload: {
        id,
        assignmentsDetails,
    },
});
export default addAssignmentsDetailsToObject;

export function handle(
    state: IDataState,
    { payload }: TAddAssignmentsDetailsToObjectAction
): IDataState {
    const { id, assignmentsDetails } = payload;

    return {
        ...state,
        objectAssignmentDetails: { ...state.objectAssignmentDetails, [id]: assignmentsDetails },
    };
}
