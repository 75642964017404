import { Localization } from '@workfront/localize-react';
import * as React from 'react';

export enum HoursModeActions {
    HOURS = 'hours',
    PERCENTAGE = 'percentage',
}

export const ALLOCATIONS_MODES = {
    hours: {
        messageKey: 'resourceplanner.businesscase.hours',
        fallBack: 'Hours',
        ariaMessageKey: 'workloadbalancer.user.allocations.as.hours',
        ariaFallBack: 'Display user allocation as hours',
        type: HoursModeActions.HOURS,
        width: 50, // TODO remove
        descriptionForSwitchButton: (
            <Localization<string>
                messageKey="workloadbalancer.workload.remaining.hours.description"
                fallback="Display the difference between the allocated and available hours"
            />
        ),
    },
    percentage: {
        messageKey: 'reporting.show.results.as.percentage',
        fallBack: 'Percentage',
        ariaMessageKey: 'workloadbalancer.user.allocations.as.percentage',
        ariaFallBack: 'Display user allocation as a percentage value',
        type: HoursModeActions.PERCENTAGE,
        descriptionForSwitchButton: (
            <Localization<string>
                messageKey="workloadbalancer.workload.remaining.percentage.description"
                fallback="Display the difference between the allocated and available time as a percentage"
            />
        ),
    },
};
