import { ISettingsState } from '../ISettingsState';

export const ActionType = 'SHOW_ALL_USERS';

const showAllUsers = () => ({
    type: ActionType,
    payload: {},
});
export default showAllUsers;

export function handle(state: ISettingsState): ISettingsState {
    return {
        ...state,
        showAllUsers: !state.showAllUsers,
    };
}
