import * as React from 'react';
import NoAccessComponent from './NoAccessComponent';
import { Scheduling } from './Scheduling/Scheduling';
import { getContextValue } from '../contexts/checkContext';

export const ModernWorkScheduling: React.FunctionComponent = () => {
    const noAccess = getContextValue('noAccess');
    if (noAccess) {
        return <NoAccessComponent />;
    }

    return <Scheduling />;
};
