import moment, { Moment } from 'moment';
import { TimeUnit } from '../../constants/periodEnums';
import { getEndOfPeriod, getStartOfPeriod } from '../../util/utilities';
import { getFilledPeriods } from '../../data-flow/dateRange/selectors/periodsSelector';
import { RoleSummaryKeys } from '../../data-flow/data/IDataState';
import { LOCALE_CODE } from '../../constants/LocalizationClientFactory';

export const getRoleSummaryKeys = (startDate: Moment): string[] => {
    return getFilledPeriods(12, TimeUnit.MONTH, startDate).map((monthPeriod) =>
        getRoleSummaryDate(monthPeriod.unit)
    );
};

const getRoleSummaryDate = (date: Moment): string => {
    const dateMoment = new Date();
    dateMoment.setDate(date.date());
    dateMoment.setMonth(date.month());
    dateMoment.setFullYear(date.year());
    const newDate = dateMoment.toLocaleDateString(LOCALE_CODE, { month: 'long', year: 'numeric' });
    return newDate;
};

export const getStartEndDateFromKey = (key?: string): Moment[] => {
    return [
        moment(getStartOfPeriod(moment(`01 ${key}`), TimeUnit.MONTH)),
        moment(getEndOfPeriod(moment(`01 ${key}`), TimeUnit.MONTH)),
    ];
};

export const shouldUpdateMonthRoleSummaryAllocation = (
    collapseOpenCloseStatus: boolean,
    key?: string,
    areaID?: string
): boolean => {
    return !!(collapseOpenCloseStatus && key !== RoleSummaryKeys.TOTAL && areaID);
};
