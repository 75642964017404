import { TModernSchedulingAction } from '../../types';
import { ITableSizesState } from '../ITableSizesState';

interface IChangeSnapToZeroActionPayload {
    snapToZero: boolean;
}

export type TChangeSnapToZeroAction = TModernSchedulingAction<IChangeSnapToZeroActionPayload>;

export const ActionType = 'CHANGE_SNAP_TO_ZERO';

const changeSnapToZero = (snapToZero: boolean): TChangeSnapToZeroAction => ({
    type: ActionType,
    payload: {
        snapToZero,
    },
});
export default changeSnapToZero;

export function handle(state: ITableSizesState, action: TChangeSnapToZeroAction): ITableSizesState {
    return {
        ...state,
        snapToZero: action.payload.snapToZero,
    };
}
