import { Button, Tooltip } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import { TLocalizationMessageArgWithFallback } from '@workfront/localize-react/dist/typings/Localization';
import LeftCaratIcon from 'phoenix-icons/dist/LeftCaratIcon.js';
import RightCaratIcon from 'phoenix-icons/dist/RightCaratIcon.js';
import { cx } from 'emotion';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Project, Task } from 'workfront-objcodes';
import { TimelineActions, TimeUnit } from '../../../constants/periodEnums';
import { Sections } from '../../../constants/schedulingTableConstants';
import { GlobalPropsContext } from '../../../contexts/globalContexts';
import {
    resetContouringEditMode,
    resetContouringEditModeChain,
    toggleRoleSummaryChain,
} from '../../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import removeTableDataIDs from '../../../data-flow/data/assignedDataActions/removeTableDataIDs';
import removeUsersData from '../../../data-flow/data/assignedDataActions/removeUsersData';
import toggleLoading from '../../../data-flow/data/dataLoadingActions/toggleLoading';
import { TContouringRowID } from '../../../data-flow/data/IDataState';
import changeIDExpressionForMinixState from '../../../data-flow/data/sharedActions/changeIDExpressionForMinixState';
import removeProjectsAndTasks from '../../../data-flow/data/sharedActions/removeProjectsAndTasks';
import changeUnassignedDataRequestMode from '../../../data-flow/data/unassignedDataActions/changeUnassignedDataRequestMode';
import removeTableDataIDsForUnassignedSection from '../../../data-flow/data/unassignedDataActions/removeTableDataIDsForUnassignedSection';
import timelineNavigation from '../../../data-flow/dateRange/timelineActions/timelineNavigation';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import { isAbleToReloadSelector } from '../../../data-flow/reloadState/reloadDataSelector';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
    UnitOfTime,
} from '../../../data-flow/types';
import { getFormattedDateRange, isInProjectArea, prepareTestId } from '../../../util/utilities';
import { Settings } from '../../Settings';
import {
    headerButtonStyles,
    headerIconButtonStyles,
    headerPrevButtonStyles,
    headerStyleQS,
    NavBarSeparator,
    navigationBar,
    tableHeaderRightSection,
    tableHeaderLeftSection,
    disabledStyleForButtons,
    politeMessageStyles,
} from '../../styles/headerComponentStyles';
import { padding_rl_6, rightPadding } from '../../styles/sharedStyles';
import { FullScreenMode } from './FullScreenMode';
import { ShareWorkloadBalancer } from './ShareWorkloadBalancer/ShareWorkloadBalancer';
import { ShowAllUsersButton } from './ShowAllUsersButton';
import { Timeline } from './Timeline/Timeline';
import { PeriodModeButtons } from './PeriodModeButtons/PeriodModeButtons';
import { RoleSummarySetting } from './RoleSummarySetting';
import { IAreaState } from '../../../data-flow/areaData/areaRelatedInitialDataState';
import { SummarySetting } from './SummarySetting';
import {
    projectGroupingModeSelector,
    showRoleSummarySelector,
} from '../../../data-flow/settings/settingsSelector';
import { tableOpenedStateSelector } from '../../../data-flow/tableSizes/selectors/tableOpenedStateSelector';
import { unassignedWorkHeightSelector } from '../../../data-flow/tableSizes/selectors/unassignedWorkHeightSelector';
import { getSchedulingAreaData } from '../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { getContextValue } from '../../../contexts/checkContext';
import { BulkAssignmentsButton } from './BulkAssignmentsButton';
import { contouringRowIdSelector } from '../../../data-flow/data/selectors/dataSelectors';
import { ShowAllocationsButton } from './ShowAllocationsButton';
import { ShowAllocationsVisualizationButton } from './ShowAllocationsVisualizationButton';
import { loadMissingDataForUnassignedSectionThunk } from '../../../data-flow/thunks/unassignedSectionLoadDataThunk';
import { editSettingsThunk } from '../../../data-flow/thunks/settings';
import { loadMissingDataForAssignedSectionThunk } from '../../../data-flow/thunks/assignedSectionLoadDataThunk';
import { startDateSelector } from '../../../data-flow/dateRange/selectors/startDateSelector';
import { endDateSelector } from '../../../data-flow/dateRange/selectors/endDateSelector';
import { stepUnitSelector } from '../../../data-flow/dateRange/selectors/stepUnitSelector';
import { monthsMessageKeys } from '../../../messageKeysForDate';

interface IHeaderStateProps {
    isTodayActive: boolean;
    unassignedWorkHeight: number;
    projectGroupingMode: boolean;
    isTableCollapsed: boolean;
    workloadBalancerRoleSummaryOn: boolean;
    schedulingAreaData: IAreaState;
    contouringRowID: TContouringRowID;
    startDate: Moment;
    endDate: Moment;
    stepUnit: UnitOfTime;
    isAbleToReload: boolean;
}

export interface IHeaderProps {
    onWindowResize: (top: number) => void;
    toolbarWidth: string | number;
    headerRef: React.MutableRefObject<HTMLElement | null>;
}

export interface IHandleTimelineNavigationArgs {
    action: TimelineActions;
    options: IHandleTimelineNavigationOptions;
}

export interface IHandleTimelineNavigationOptions {
    isTodayActive?: boolean;
    periodMode?: number;
    startDateProp?: Moment;
    timeUnit?: TimeUnit;
    _t?: Record<string, TLocalizationMessageArgWithFallback>;
}

const handleTimelineNavigationInitialOptions = {
    isTodayActive: false,
    periodMode: 0,
    startDateProp: moment(),
    timeUnit: TimeUnit.WEEK,
    _t: {},
};

export interface IHandleTimelineNavigationFunc {
    handleTimelineNavigation: (
        action: IHandleTimelineNavigationArgs['action'],
        options: IHandleTimelineNavigationOptions
    ) => void;
}

export class HeaderComponent extends React.PureComponent<
    IHeaderProps & IHeaderStateProps & IWorkSchedulingDispatchProp
> {
    static contextType = GlobalPropsContext;
    context!: React.ContextType<typeof GlobalPropsContext>;
    private timelineClickTimeOut = 0;
    private politeMessageRef: React.RefObject<HTMLDivElement> = React.createRef();

    handleTimelineNavigation = (
        action: IHandleTimelineNavigationArgs['action'],
        options: IHandleTimelineNavigationOptions = handleTimelineNavigationInitialOptions
    ): void => {
        const { minixClose } = this.context;

        const { startDateProp, isTodayActive, periodMode, timeUnit, _t } = options;

        if (isTodayActive) {
            return;
        }

        clearInterval(this.timelineClickTimeOut);

        if (minixClose) minixClose();

        this.props.dispatch(
            actionChain([
                ...resetContouringEditModeChain(),
                timelineNavigation(action, periodMode, startDateProp, timeUnit),
                toggleLoading(Sections.PEOPLE_WORKLOAD, true),
            ])
        );

        if (!this.props.isTableCollapsed) {
            this.props.dispatch(toggleLoading(Sections.UNASSIGNED_WORK, true));
        }

        this.timelineClickTimeOut = window.setTimeout(() => {
            this.props.dispatch(
                actionChain([
                    removeUsersData(this.props.projectGroupingMode),
                    removeProjectsAndTasks(Sections.PEOPLE_WORKLOAD),
                    removeTableDataIDs(''),
                    removeTableDataIDsForUnassignedSection(''),
                    changeUnassignedDataRequestMode(false, Project),
                    changeUnassignedDataRequestMode(false, Task),
                ])
            );

            const loadAssignedSection = async (): Promise<void> => {
                await this.props.dispatch(loadMissingDataForAssignedSectionThunk());

                // should call twice because users data is related to period
                // and rendered section users IDS length becoming clear after loading data for the period
                await this.props.dispatch(loadMissingDataForAssignedSectionThunk());
            };

            const promises = [loadAssignedSection()];

            if (this.props.unassignedWorkHeight) {
                promises.push(this.props.dispatch(loadMissingDataForUnassignedSectionThunk()));
            }

            Promise.all(promises).then(() => {
                if (action === TimelineActions.NEXT) {
                    this.politeMessageRef.current!.textContent = `${_t![
                        'workloadbalancer.select.next.period.loaded'
                    ]('Next period loaded. Currently selected dates are')} ${getFormattedDateRange(
                        this.props.startDate,
                        this.props.endDate,
                        this.props.stepUnit
                    )}`;
                } else if (action === TimelineActions.PREVIOUS) {
                    this.politeMessageRef.current!.textContent = `${_t![
                        'workloadbalancer.select.previous.period.loaded'
                    ](
                        'Previous period loaded. Currently selected dates are'
                    )} ${getFormattedDateRange(
                        this.props.startDate,
                        this.props.endDate,
                        this.props.stepUnit
                    )}`;
                }
            });
        }, 200);
    };

    saveSettingsAndResetContouring = (): void => {
        const { dispatch, contouringRowID } = this.props;
        if (!getContextValue('sharableLink')) {
            dispatch(editSettingsThunk());
        }
        if (contouringRowID) {
            dispatch(resetContouringEditMode());
        }
    };

    shareLinkButtonClicked = (): void => {
        const { dispatch, contouringRowID } = this.props;
        if (contouringRowID) {
            dispatch(resetContouringEditMode());
        }
    };

    handleMinixOpen = (): void => {
        if (this.context.minixState) {
            this.context.minixClose();
            this.props.dispatch(changeIDExpressionForMinixState(null));
        } else {
            this.props.dispatch(
                actionChain([
                    ...toggleRoleSummaryChain(false),
                    changeIDExpressionForMinixState('emptyID'),
                ])
            );

            this.context.minixOpen({
                objCode: Task,
                ID: [],
                messageForEmptySelection: {
                    messageKey: 'workloadbalancer.minixselecttaskorissue',
                    fallback: 'Click the bar of a task or issue to view details',
                },
            });
        }
    };

    render(): JSX.Element {
        const {
            isTodayActive,
            workloadBalancerRoleSummaryOn,
            schedulingAreaData,
            onWindowResize,
            headerRef,
            toolbarWidth,
        } = this.props;

        const sharableLink = getContextValue('sharableLink');

        const headerTodayButtonClassName = cx(
            headerButtonStyles,
            isTodayActive ? disabledStyleForButtons : '',
            padding_rl_6
        );
        const headerPrevButtonClassName = cx(
            headerIconButtonStyles,
            headerPrevButtonStyles,
            'navigate-previous'
        );
        const headerNextButtonClassName = cx(headerIconButtonStyles, 'navigate-next');

        const { schedulingAreaObjCode } = schedulingAreaData;

        return (
            <Localization<string[]>
                messageKeys={[
                    'workloadbalancer.select.today',
                    'resourcescheduling.previous.period',
                    'workloadbalancer.select.previous.period',
                    'resourcescheduling.next.period',
                    'workloadbalancer.select.next.period',
                    'action.settings',
                    'workloadbalancer.select.next.period.loaded',
                    'workloadbalancer.select.previous.period.loaded',
                    ...monthsMessageKeys,
                ]}
            >
                {({ _t }) => {
                    return (
                        <header
                            ref={headerRef}
                            style={{
                                maxWidth: toolbarWidth,
                            }}
                            className={cx(headerStyleQS)}
                        >
                            <div
                                ref={this.politeMessageRef}
                                aria-live="polite"
                                className={politeMessageStyles}
                            />
                            {/* <FilterDropDown /> */}
                            <div className={navigationBar}>
                                <div className={tableHeaderLeftSection}>
                                    <NavBarSeparator />
                                    <div>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://workfront.az1.qualtrics.com/jfe/form/SV_cIy41xRHPVKpYAS"
                                        >
                                            <Button>
                                                <Localization
                                                    messageKey="feedback"
                                                    fallback="Feedback"
                                                />
                                            </Button>
                                        </a>
                                        <NavBarSeparator />
                                    </div>

                                    <Button
                                        text
                                        className={headerTodayButtonClassName}
                                        data-testid={prepareTestId(
                                            'navigate_today',
                                            sharableLink,
                                            schedulingAreaObjCode
                                        )}
                                        onClick={() =>
                                            this.handleTimelineNavigation(TimelineActions.TODAY, {
                                                isTodayActive,
                                            })
                                        }
                                        aria-label={`${_t['workloadbalancer.select.today'](
                                            'Select date of today'
                                        )}, ${moment().format('LL')}`}
                                        aria-disabled={isTodayActive}
                                        aria-pressed={isTodayActive}
                                    >
                                        <Localization<string> messageKey="today" fallback="Today" />
                                    </Button>
                                    <Tooltip
                                        content={_t['resourcescheduling.previous.period'](
                                            'Previous period'
                                        )}
                                        delay={700}
                                    >
                                        <Button
                                            text
                                            className={headerPrevButtonClassName}
                                            data-testid={prepareTestId(
                                                'navigate_previous',
                                                sharableLink,
                                                schedulingAreaObjCode
                                            )}
                                            onClick={() =>
                                                this.handleTimelineNavigation(
                                                    TimelineActions.PREVIOUS,
                                                    {
                                                        _t,
                                                    }
                                                )
                                            }
                                            aria-label={_t[
                                                'workloadbalancer.select.previous.period'
                                            ]('Select previous period')}
                                            disabled={!this.props.isAbleToReload}
                                        >
                                            <LeftCaratIcon
                                                height={20}
                                                width={20}
                                                focusable={false}
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        content={_t['resourcescheduling.next.period'](
                                            'Next period'
                                        )}
                                        delay={700}
                                    >
                                        <Button
                                            text
                                            className={headerNextButtonClassName}
                                            data-testid={prepareTestId(
                                                'navigate_next',
                                                sharableLink,
                                                schedulingAreaObjCode
                                            )}
                                            onClick={() =>
                                                this.handleTimelineNavigation(
                                                    TimelineActions.NEXT,
                                                    {
                                                        _t,
                                                    }
                                                )
                                            }
                                            aria-label={_t['workloadbalancer.select.next.period'](
                                                'Select next period'
                                            )}
                                            disabled={!this.props.isAbleToReload}
                                        >
                                            <RightCaratIcon
                                                height={20}
                                                width={20}
                                                focusable={false}
                                            />
                                        </Button>
                                    </Tooltip>
                                    <NavBarSeparator />
                                    <Timeline
                                        handleTimelineNavigation={this.handleTimelineNavigation}
                                    />
                                    <NavBarSeparator />
                                    <PeriodModeButtons
                                        sharableLink={sharableLink}
                                        saveSettingsAndResetContouring={
                                            this.saveSettingsAndResetContouring
                                        }
                                    />
                                </div>
                                {!sharableLink && (
                                    <>
                                        <BulkAssignmentsButton />
                                        <NavBarSeparator />
                                    </>
                                )}
                                <div className={cx(rightPadding, tableHeaderRightSection)}>
                                    <>
                                        {isInProjectArea(schedulingAreaObjCode) && (
                                            <>
                                                <ShowAllUsersButton
                                                    saveSettingsAndResetContouring={
                                                        this.saveSettingsAndResetContouring
                                                    }
                                                    sharableLink={sharableLink}
                                                    schedulingAreaObjCode={schedulingAreaObjCode}
                                                />
                                                <NavBarSeparator />
                                            </>
                                        )}
                                        <ShowAllocationsVisualizationButton
                                            saveSettingsAndResetContouring={
                                                this.saveSettingsAndResetContouring
                                            }
                                            sharableLink={sharableLink}
                                            schedulingAreaObjCode={schedulingAreaObjCode}
                                        />
                                        <NavBarSeparator />
                                        <ShowAllocationsButton
                                            saveSettingsAndResetContouring={
                                                this.saveSettingsAndResetContouring
                                            }
                                            sharableLink={sharableLink}
                                            schedulingAreaObjCode={schedulingAreaObjCode}
                                        />
                                        <NavBarSeparator />
                                    </>
                                    {!sharableLink && (
                                        <>
                                            <ShareWorkloadBalancer
                                                shareLinkButtonClicked={this.shareLinkButtonClicked}
                                            />
                                            <NavBarSeparator />
                                        </>
                                    )}
                                    <Tooltip
                                        content={_t['action.settings']('Settings')}
                                        delay={700}
                                    >
                                        <Settings />
                                    </Tooltip>
                                    {isInProjectArea(schedulingAreaObjCode) ? (
                                        <>
                                            <NavBarSeparator />
                                            <RoleSummarySetting
                                                sharableLink={sharableLink}
                                                schedulingArea={schedulingAreaObjCode}
                                                workloadBalancerRoleSummaryOn={
                                                    workloadBalancerRoleSummaryOn
                                                }
                                            />
                                        </>
                                    ) : null}
                                    {!getContextValue('sharableLink') && (
                                        <>
                                            <NavBarSeparator />
                                            <SummarySetting
                                                minixState={this.context.minixState}
                                                handleMinixOpen={this.handleMinixOpen}
                                                schedulingAreaObjCode={schedulingAreaObjCode}
                                            />
                                        </>
                                    )}
                                    <>
                                        <NavBarSeparator />
                                        <FullScreenMode onWindowResize={onWindowResize} />
                                    </>
                                </div>
                            </div>
                        </header>
                    );
                }}
            </Localization>
        );
    }
}

const mapStateToProps = (state: IWorkSchedulingCombinedState): IHeaderStateProps => {
    return {
        isTodayActive: state.DateRange.isTodayActive,
        projectGroupingMode: projectGroupingModeSelector(state),
        unassignedWorkHeight: unassignedWorkHeightSelector(state),
        startDate: startDateSelector(state),
        endDate: endDateSelector(state),
        stepUnit: stepUnitSelector(state),
        isTableCollapsed: tableOpenedStateSelector(state),
        workloadBalancerRoleSummaryOn: showRoleSummarySelector(state),
        schedulingAreaData: getSchedulingAreaData(state),
        contouringRowID: contouringRowIdSelector(state),
        isAbleToReload: isAbleToReloadSelector(state, Sections.PEOPLE_WORKLOAD),
    };
};

export const Header = connect(mapStateToProps)(HeaderComponent);
