import { Localization } from '@workfront/localize-react';
import React from 'react';
import { AllocationToolbarContainer } from '@workfront/pandora-components';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { GlobalPropsContext } from '../../contexts/globalContexts';
import { RightWidgetHOC } from '../hoc/RightWidget/RightWidgetHOC';
import { toggleRoleSummaryChain } from '../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import { IWorkSchedulingCombinedState, IWorkSchedulingDispatchProp } from '../../data-flow/types';
import {
    RoleSummaryDataType,
    RoleSummaryKeys,
    IRoleSummaryLoading,
} from '../../data-flow/data/IDataState';
import { isEspProductEnabled } from '../../data-flow/data/selectors/dataSelectors';
import actionChain from '../../data-flow/higher-order-reducers/actionChain';
import { startDateSelector } from '../../data-flow/dateRange/selectors/startDateSelector';
import {
    getRoleSummaryKeys,
    getStartEndDateFromKey,
    shouldUpdateMonthRoleSummaryAllocation,
} from './utils';
import setRoleSummaryActiveKey from '../../data-flow/data/assignedDataActions/roleSummary/setRoleSummaryActiveKey';
import { loadProjectAssignments } from '../../data-flow/thunks/loadProjectAssignmentsThunk/loadProjectAssignmentsThunk';
import { IAreaState } from '../../data-flow/areaData/areaRelatedInitialDataState';
import {
    roleSummaryActiveKeysSelector,
    roleSummaryDataSelector,
    roleSummaryLoadingSelector,
} from '../../data-flow/data/selectors/roleSummary/roleSummarySelectors';
import { getSchedulingAreaData } from '../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { getContextValue } from '../../contexts/checkContext';
import { editSettingsThunk } from '../../data-flow/thunks/settings';

export const testIDs = {
    role_summary_container: 'role-summary-container',
};

interface IRoleSummaryContainerComponent {
    roleSummaryData: RoleSummaryDataType;
    startDate: Moment;
    roleSummaryActiveKeys: Array<RoleSummaryKeys | string>;
    schedulingAreaData: IAreaState;
    roleSummaryLoading: IRoleSummaryLoading;
    espProductEnabled: boolean;
}

export class RoleSummaryContainerComponent extends React.PureComponent<
    IRoleSummaryContainerComponent & IWorkSchedulingDispatchProp
> {
    static contextType = GlobalPropsContext;
    context!: React.ContextType<typeof GlobalPropsContext>;

    handleRoleSummaryToggle = (): void => {
        const { dispatch } = this.props;

        dispatch(actionChain(toggleRoleSummaryChain(false)));

        if (!getContextValue('sharableLink')) {
            dispatch(editSettingsThunk());
        }

        this.context.minixClose();
    };

    isOpenHandler = (status: boolean, key?: string): void => {
        const { dispatch, schedulingAreaData, espProductEnabled } = this.props;

        dispatch(setRoleSummaryActiveKey(status, key));

        const areaID = schedulingAreaData.schedulingAreaID;
        if (shouldUpdateMonthRoleSummaryAllocation(status, key, areaID)) {
            const [startDate, endDate] = getStartEndDateFromKey(key);
            dispatch(loadProjectAssignments(areaID!, startDate, endDate, espProductEnabled, key));
        }
    };

    render(): JSX.Element {
        const { startDate, roleSummaryActiveKeys, roleSummaryData, roleSummaryLoading } =
            this.props;

        const monthlyPeriodKeys = getRoleSummaryKeys(startDate);
        const monthlyPreparedData = monthlyPeriodKeys.map((monthlyPeriodKey) => {
            const bodyRows = roleSummaryData[monthlyPeriodKey] || [];

            return {
                allocationPeriod: monthlyPeriodKey,
                open: roleSummaryActiveKeys.includes(monthlyPeriodKey),
                bodyRows,
                key: monthlyPeriodKey,
                isLoading: bodyRows.length === 0 && roleSummaryLoading[monthlyPeriodKey],
            };
        });

        return (
            <RightWidgetHOC testID={testIDs.role_summary_container}>
                <Localization messageKeys={['workloadbalancer.project.totals']}>
                    {({ _t }) => {
                        return (
                            <AllocationToolbarContainer
                                onClose={this.handleRoleSummaryToggle}
                                isOpenHandler={this.isOpenHandler}
                                allocationPeriods={[
                                    {
                                        allocationPeriod: `${_t['workloadbalancer.project.totals'](
                                            'Project totals'
                                        )}`,
                                        bodyRows: roleSummaryData[RoleSummaryKeys.TOTAL],
                                        open: roleSummaryActiveKeys.includes(RoleSummaryKeys.TOTAL),
                                        key: RoleSummaryKeys.TOTAL,
                                    },
                                    ...monthlyPreparedData,
                                ]}
                            />
                        );
                    }}
                </Localization>
            </RightWidgetHOC>
        );
    }
}

const mapStateToProps = (state: IWorkSchedulingCombinedState): IRoleSummaryContainerComponent => {
    return {
        roleSummaryData: roleSummaryDataSelector(state),
        roleSummaryActiveKeys: roleSummaryActiveKeysSelector(state),
        startDate: startDateSelector(state),
        schedulingAreaData: getSchedulingAreaData(state),
        roleSummaryLoading: roleSummaryLoadingSelector(state),
        espProductEnabled: isEspProductEnabled(state),
    };
};

export const RoleSummaryContainer = connect(mapStateToProps)(RoleSummaryContainerComponent);
