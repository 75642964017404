import { primary } from '@phoenix/all';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { css } from 'react-emotion';
import { connect } from 'react-redux';
import { FOCUSED_INPUT_BG_COLOR } from '../../../constants/colors';
import addTemporaryWorkPerDayHoursAfterEditing from '../../../data-flow/data/assignedDataActions/addTemporaryWorkPerDayHoursAfterEditing';
import changeContouringRowState from '../../../data-flow/data/assignedDataActions/changeContouringRowState';
import showErrorRedBorder from '../../../data-flow/data/assignedDataActions/showErrorRedBorder';
import { IFullTimeOffSteps, IWorkDays } from '../../../data-flow/data/IDataState';
import {
    contouringRowIdSelector,
    temporaryWorkPerDaysSelector,
} from '../../../data-flow/data/selectors/dataSelectors';
import { stepUnitSelector } from '../../../data-flow/dateRange/selectors/stepUnitSelector';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../data-flow/types';
import {
    convertHourToMinute,
    convertHourToPercent,
    convertMinuteToHour,
    convertPercentToHour,
    enumerateDaysBetweenDates,
    getHoursBySteps,
    roundNumber,
} from '../../../util/utilities';
import { allocationHourStyle } from '../../styles/allocationHourComponentStyles';
import {
    projectGroupingModeSelector,
    showAllocationsInHoursModeSelector,
} from '../../../data-flow/settings/settingsSelector';
import { stepsSelector } from '../../../data-flow/dateRange/selectors/stepsSelector';
import { getAriaLabelForHour } from '../../../util/ariaUtils/ariaUtils';
import { getTaskSelector } from '../../../data-flow/data/selectors/reselect/getTaskSelector/getTaskSelector';
import { compareHoursAfterContouring } from '../../../util/contouringValidation';
import { workPerDaysByAssignmentsFromServiceSelector } from '../../../data-flow/data/selectors/workPerDaysByAssignmentsFromServiceSelector';
import { getPeriodModesValues } from '../../../util/periodUtils/periodUtils';
import { IGeneralStateTypes } from '../../../data-flow/IGeneralStateTypes';

interface IAllocationHourComponentProps
    extends IAllocationHourProps,
        IAllocationHourStateProps,
        IWorkSchedulingDispatchProp {}

type IAllocationHourStateProps = Pick<
    IGeneralStateTypes,
    | 'stepUnit'
    | 'projectGrouping'
    | 'contouringRowID'
    | 'showAllocationsInHoursMode'
    | 'steps'
    | 'workRequired'
    | 'temporaryWorkPerDays'
    | 'workPerDayByAssignments'
>;

interface IAllocationHourProps {
    assignmentStartStep: Moment;
    backGroundColor?: string;
    cellWidth: number;
    fullTimeOffsStepsByDay: IFullTimeOffSteps;
    height: number;
    hour: number;
    IDs: string[];
    index: number;
    isThereBeforeArrow: boolean;
    isThereAfterArrow: boolean;
    isTimeOff: boolean;
    isSinglePeriodTask: boolean;
    objCode: string;
    plannedCompletionDate: Moment;
    plannedStartDate: Moment;
    scheduleWorkDays: IWorkDays;
    availableHour: number;
    scheduleHour: number;
    textColor?: string;
    isFromAssignmentBar: boolean;
}

export const EditableAllocationsComponent: React.FunctionComponent<
    IAllocationHourComponentProps
> = (props) => {
    const {
        backGroundColor,
        cellWidth,
        contouringRowID,
        index,
        isSinglePeriodTask,
        isThereBeforeArrow,
        isThereAfterArrow,
        isTimeOff,
        height,
        showAllocationsInHoursMode,
        availableHour,
        scheduleHour,
        textColor,
        dispatch,
        steps,
        stepUnit,
        workRequired,
        temporaryWorkPerDays,
        workPerDayByAssignments,
        isFromAssignmentBar,
    } = props;

    const hoursDiff = compareHoursAfterContouring(
        workPerDayByAssignments,
        temporaryWorkPerDays,
        getPeriodModesValues(stepUnit).isWeekMode
    ).roundedDiff;

    const hour = showAllocationsInHoursMode
        ? convertMinuteToHour(props.hour)
        : roundNumber(convertHourToPercent(availableHour, props.hour, scheduleHour), 100);

    const [value, setValue] = useState(hour.toString());
    let inputFirstEl: HTMLInputElement | null = null;

    const handleClick = React.useCallback(
        () => {
            dispatch(changeContouringRowState(contouringRowID, { scheduleHour, availableHour }));
        },
        // "dispatch" reference is never going to change
        [availableHour, contouringRowID, scheduleHour] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        if (inputFirstEl && contouringRowID) {
            inputFirstEl.focus();

            if (!showAllocationsInHoursMode) {
                handleClick();
            }
        }
    }, [contouringRowID, handleClick, inputFirstEl, showAllocationsInHoursMode]);

    const changeHandler = getChangeHandler(props, setValue);

    const onBlur = (e): void => {
        const rx = /^[0][0-9]*$/;
        if (!e.target.value) {
            setValue('0');
        } else if (e.target.value.match(rx)) {
            const valueWithoutZerosFromStart = +e.target.value;
            setValue(`${valueWithoutZerosFromStart}`);
        }
    };

    const handleKeyPress = (e): void => {
        if (e.keyCode === 9) {
            handleClick();
        }
    };

    const inputProps = !showAllocationsInHoursMode
        ? {
              onKeyUp: handleKeyPress,
              onClick: handleClick,
          }
        : {};

    const txtColor = textColor || primary.grey(0);

    return (
        <div
            className={allocationHourStyle(
                cellWidth,
                isThereBeforeArrow,
                isThereAfterArrow,
                height,
                isSinglePeriodTask,
                isFromAssignmentBar
            )}
        >
            <div className={inputWrapperStyle(isTimeOff && hour === 0, txtColor)}>
                <input
                    className={inputStyle(backGroundColor, txtColor)}
                    aria-label={getAriaLabelForHour(
                        steps[index],
                        stepUnit,
                        hoursDiff,
                        workRequired
                    )}
                    type="text"
                    value={value}
                    onChange={changeHandler}
                    onBlur={(e) => onBlur(e)}
                    ref={(input) => {
                        if (index === 0) {
                            inputFirstEl = input;
                        }
                    }}
                    {...inputProps}
                />
            </div>
        </div>
    );
};

const getChangeHandler = (props: IAllocationHourComponentProps, setValue): any => {
    let typingDelay;

    return (e): void => {
        const rx = /^\d*\.?\d{0,2}$/;
        if (e.target.value && !e.target.value.match(rx)) {
            return;
        }

        const inputValue = e.target.value;
        setValue(inputValue);

        // typingDelay is not working as this component reRendered on each change of input
        // improve it when will do rendering performance story
        if (typingDelay) {
            clearTimeout(typingDelay);
        }

        typingDelay = setTimeout(function () {
            const {
                projectGrouping,
                IDs,
                showAllocationsInHoursMode,
                availableHour,
                scheduleHour,
                assignmentStartStep,
                stepUnit,
                fullTimeOffsStepsByDay,
                scheduleWorkDays,
                plannedStartDate,
                plannedCompletionDate,
                dispatch,
                contouringRowID,
            } = props;
            const taskID = projectGrouping ? IDs[2] : IDs[1];
            const userID = IDs[0];

            const convertedInputValue = showAllocationsInHoursMode
                ? convertHourToMinute(parseFloat(inputValue))
                : convertPercentToHour(availableHour, parseFloat(inputValue), scheduleHour);

            const taskDuration = convertedInputValue || 0;

            const stepEnd = assignmentStartStep.clone().endOf(stepUnit);

            const daysBetweenDates = enumerateDaysBetweenDates(
                plannedStartDate < assignmentStartStep ? assignmentStartStep : plannedStartDate,
                stepEnd > plannedCompletionDate ? plannedCompletionDate : stepEnd
            );
            const hoursBySteps = getHoursBySteps(
                daysBetweenDates,
                fullTimeOffsStepsByDay,
                scheduleWorkDays,
                taskDuration,
                stepUnit
            );

            dispatch(showErrorRedBorder(taskID, false));
            dispatch(
                addTemporaryWorkPerDayHoursAfterEditing({
                    userID,
                    hoursBySteps,
                    taskID,
                    projectGrouping,
                    contouringRowID,
                })
            );
        }, 200);
    };
};

const inputWrapperStyle = (isTimeOff: boolean, textColor?: string): string => css`
    width: 100%;
    border-bottom: 1px solid ${textColor};
    border-radius: 1px;
    height: 22px;
    & > input {
        opacity: ${isTimeOff ? 0.5 : 1};
    }
`;

const inputStyle = (backGroundColor?: string, textColor?: string): string => css`
    background-color: inherit;
    width: 100% !important;
    height: 15px;
    line-height: 15px;
    background-color: ${backGroundColor};
    color: ${textColor};
    padding: 0px;
    box-sizing: border-box;
    box-shadow: none;
    border: none;
    text-align: center;
    font-size: 14px;
    outline: none;

    &:focus {
        border-radius: 2px;
        background-color: ${FOCUSED_INPUT_BG_COLOR};
    }

    &::-ms-clear {
        display: none;
    }

    &::selection {
        background: #8bb1cf; /* default color for selected text */
    }
`;

const mapStateToProps = (
    state: IWorkSchedulingCombinedState,
    ownProps: IAllocationHourProps
): IAllocationHourStateProps => {
    const { IDs } = ownProps;
    const task = getTaskSelector(state)(ownProps.IDs[ownProps.IDs.length - 1]);

    return {
        projectGrouping: projectGroupingModeSelector(state),
        stepUnit: stepUnitSelector(state),
        contouringRowID: contouringRowIdSelector(state),
        showAllocationsInHoursMode: showAllocationsInHoursModeSelector(state),
        steps: stepsSelector(state),
        workRequired: IDs && IDs.length && task.workRequired[IDs[0]],
        temporaryWorkPerDays: temporaryWorkPerDaysSelector(state),
        workPerDayByAssignments: workPerDaysByAssignmentsFromServiceSelector(state),
    };
};

export const EditableAllocations = connect(mapStateToProps)(EditableAllocationsComponent);
