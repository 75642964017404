import _, { flatten } from 'lodash';

import { Project, Task as taskObjCode } from 'workfront-objcodes';
import { apiDateToDate } from '@workfront/fns';
import {
    LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT,
    unassignedTasksSortObject,
} from '../../constants/dataConstatnts';
import { RECOMPUTE_GRID_SIZE } from '../../constants/events';
import { Sections } from '../../constants/schedulingTableConstants';
import { APIService } from '../../services/api-services/apiService';
import {
    getUnassignedAssignmentsProjectsFilter,
    getUnassignedIssuesProjectsFilter,
    getUnassignedTasksFilter,
    isFilterAppliedInUnassignedSection,
    prepareAreaAdditionalSimpleFilterForUnassignedProjects,
    prepareAreaAdditionalSimpleFilterForUnassignedTasks,
} from '../../util/filters/filterUtil';
import {
    isColorModeProject,
    isColorModeProjectStatus,
    isFirstLoadByOffset,
    isInProjectArea,
    prepareColorsByProjectData,
    prepareColorsByProjectGroupData,
    sortObject,
} from '../../util/utilities';
import { isInProjectAreaAndGroupingMode } from '../../util/utilitiesForState';
import addProjectGroupColors from '../data/assignedDataActions/addProjectGroupColors/addProjectGroupColors';
import changeHasMore from '../data/assignedDataActions/changeHasMore';
import removeRow from '../data/dataLoadingActions/removeRow';
import toggleLoading from '../data/dataLoadingActions/toggleLoading';
import {
    IErrorDetail,
    IRequestedUnassignedProjectData,
    IUnassignedAssignment,
    IUnassignedTasksProjects,
    TEndDate,
    TProjectID,
    TStartDate,
    TTableDataIDs,
    TTaskOrIssueID,
    TUnassignedWorkHeight,
} from '../data/IDataState';
import {
    expandedUnassignedProjectIDsSelector,
    projectGroupColorsSelector,
    tableDataIDsForUnassignedSectionWithoutHeader,
} from '../data/selectors/dataSelectors';
import addTableDataIDsForUnassignedSection from '../data/unassignedDataActions/addTableDataIDsForUnassignedSection';
import addUnassignedProjects from '../data/unassignedDataActions/addUnassignedProjects';
import changeUnassignedDataRequestMode from '../data/unassignedDataActions/changeUnassignedDataRequestMode';
import changeUnassignedNodeArrowState from '../data/unassignedDataActions/changeUnassignedNodeArrowState';
import removeTableDataIDsForUnassignedSection from '../data/unassignedDataActions/removeTableDataIDsForUnassignedSection';
import setHeightForUnassignedSectionEmptyRow, {
    calcUnassignedWorkEmptyRowHeight,
} from '../data/unassignedDataActions/setHeightForUnassignedSectionEmptyRow';
import { endDateSelector } from '../dateRange/selectors/endDateSelector';
import changeErrorState from '../errorData/changeErrorState';
import { getInitialErrorState } from '../errorData/getErrorInitialState';
import actionChain from '../higher-order-reducers/actionChain';
import { internalEventEmitterSelector } from '../instances/internalEventEmitterSelector';
import { TWorkSchedulingThunkAction } from '../types';
import { loadAssignmentsByTaskIDsFormService } from './loadWorkPerDayDataThunk';
import removeProjectsAndTasks from '../data/sharedActions/removeProjectsAndTasks';
import {
    projectColorsModeSelector,
    projectGroupingModeSelector,
    showIssuesSelector,
} from '../settings/settingsSelector';
import { getSchedulingAreaData } from '../areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { unassignedWorkHeightSelector } from '../tableSizes/selectors/unassignedWorkHeightSelector';
import { projectColorsSelector } from '../projectColors/selectors/projectColorsSelector/projectColorsSelector';
import addProjectColors from '../projectColors/actions/addProjectColors/addProjectColors';
import { hasMore } from './loadDataThunk';
import {
    ITasksAndProjects,
    IUnassignedTasksAndProjects,
    sortUnassignedProjectsByTasksDetails,
} from '../../util/changeProjectsSortingUtil';
import { getUnassignedTasksProjectsSelector } from '../data/selectors/unassignedTasksProjectsSelectors/unassignedTasksProjectsSelectors';
import { startDateSelector } from '../dateRange/selectors/startDateSelector';
import { loadingStateUnassignedWorkSectionSelector } from '../data/selectors/isInLoadingState/isInLoadingStateSelectors';
import { resetUnassignedSectionData } from '../data/unassignedDataActions/commonActionGroups/resetUnassignedSection';
import { arrowCollapsedStateActions } from '../../components/schedulingTable/utils';
import { IAreaState } from '../areaData/areaRelatedInitialDataState';
import setProjectsSortingCriteria from '../settings/settingsActions/projectsSortingCriteria';

export function loadDataForUnassignedSection() {
    return function _loadDataForUnassignedSection(dispatch, getState) {
        const schedulingAreaData = getSchedulingAreaData(getState());

        const noFilter = !isFilterAppliedInUnassignedSection(getState());

        if (noFilter && !schedulingAreaData.schedulingAreaID) {
            dispatch(
                actionChain([
                    changeHasMore(false, Sections.UNASSIGNED_WORK),
                    toggleLoading(Sections.UNASSIGNED_WORK, false),
                ])
            );
            return;
        }

        const state = getState();
        const projectGroupingMode = projectGroupingModeSelector(state);
        if (projectGroupingMode) {
            return dispatch(loadUnassignedAssignmentsProjects());
        }
        return dispatch(loadUnassignedAssignments());
    };
}

export function loadUnassignedAssignments(
    limit: number = LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT,
    offset: number = 0
): TWorkSchedulingThunkAction<Promise<void>> {
    return function _loadUnassignedAssignments(dispatch, getState) {
        dispatch(changeUnassignedDataRequestMode(true, taskObjCode));
        const state = getState();
        const startDate = startDateSelector(state);
        const endDate = endDateSelector(state);

        const { filterExpression } = getState().Filters[Sections.UNASSIGNED_WORK];
        const schedulingAreaData = getSchedulingAreaData(getState());
        const additionalSimpleFilter =
            prepareAreaAdditionalSimpleFilterForUnassignedTasks(schedulingAreaData);
        const { filter, filterOptask } = getUnassignedTasksFilter(
            filterExpression,
            additionalSimpleFilter,
            startDate,
            endDate,
            null,
            limit,
            offset
        );

        const isIssuesIncluded = showIssuesSelector(state);
        return Promise.all([
            APIService.loadUnassignedAssignments(filter),
            isIssuesIncluded
                ? APIService.loadUnassignedIssuesAssignments(filterOptask)
                : Promise.resolve([]),
        ])
            .then(([unassignedTasks, unassignedIssues]) => {
                const newState = getState();
                if (startDate === newState.DateRange.startDate) {
                    const unassignedAssignments = isIssuesIncluded
                        ? unassignedTasks.concat(unassignedIssues)
                        : unassignedTasks;
                    const projectColorMode = projectColorsModeSelector(newState);
                    const colorModeProjectStatus = isColorModeProjectStatus(projectColorMode);
                    const colorModeProject = isColorModeProject(projectColorMode);
                    const loadedGroupData = {};
                    const loadedProjectIDs: TProjectID[] = [];

                    if (colorModeProjectStatus) {
                        const projectGroupColors = projectGroupColorsSelector(state);
                        unassignedAssignments.forEach((unassignedTask) => {
                            const groupID = unassignedTask.project?.groupID;
                            const groupIDAlreadyExists = projectGroupColors?.[groupID];
                            if (!groupIDAlreadyExists && groupID) {
                                loadedGroupData[groupID] = groupID;
                            }
                        });
                    } else if (colorModeProject) {
                        const projectColorList = projectColorsSelector(state);
                        unassignedAssignments.forEach((unassignedTask) => {
                            const projectID = unassignedTask.project?.ID;
                            const alreadyExists = projectColorList?.[projectID];
                            if (!alreadyExists && projectID) {
                                loadedProjectIDs.push(projectID);
                            }
                        });
                    }

                    const loadedGroupDataIDs = Object.keys(loadedGroupData);
                    APIService.getColorsByGroupIds(loadedGroupDataIDs).then((groupsColors) => {
                        const args = {
                            oldUnassignedTasks: getState().Data.unassignedTasks,
                            oldUnassignedSectionDataIds:
                                tableDataIDsForUnassignedSectionWithoutHeader(getState().Data),
                            unassignedTasks: unassignedAssignments,
                            startDate,
                            endDate,
                            limit,
                            offset,
                            projectGroupingMode: projectGroupingModeSelector(newState),
                            unassignedWorkHeight: newState.TableSizes.unassignedWorkHeight,
                        };

                        const chainActions = finalActionsForLoadingUnassignedSection(args);

                        if (colorModeProjectStatus) {
                            const groupsColorsRefactored = prepareColorsByProjectGroupData(
                                loadedGroupDataIDs,
                                groupsColors
                            );
                            chainActions.push(addProjectGroupColors(groupsColorsRefactored));
                        } else if (colorModeProject && loadedProjectIDs.length) {
                            const colorsByProjectID = prepareColorsByProjectData(loadedProjectIDs);
                            dispatch(addProjectColors(colorsByProjectID));
                        }

                        dispatch(loadAssignmentsByTaskIDsFormService(args, chainActions));
                    });
                }
                dispatch(
                    changeErrorState(
                        Sections.UNASSIGNED_WORK,
                        getInitialErrorState()[Sections.UNASSIGNED_WORK]
                    )
                );
            })
            .catch((error) => {
                dispatch(actionChain(actionsWhenError(error, Sections.UNASSIGNED_WORK)));
            });
    };
}

// Create external object to collect data from all recursive calls made by loadUnassignedAssignmentsProjects function
interface IUnassignedProjectsData {
    tasksAndIssuesProjects: IRequestedUnassignedProjectData[];
    groupsColors: any[];
    unassignedAssignments: IUnassignedAssignment[];
}
let unassignedProjectsData: IUnassignedProjectsData = {
    tasksAndIssuesProjects: [],
    groupsColors: [],
    unassignedAssignments: [],
};

export const loadUnassignedAssignmentsProjects = (
    limit: number = LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT,
    offset: number = 0
): TWorkSchedulingThunkAction<Promise<void>> => {
    let reloaded = false;
    return function _loadUnassignedAssignmentsProjects(dispatch, getState) {
        const state = getState();
        const startDate = startDateSelector(state);
        const endDate = endDateSelector(state);
        const groupingMode = projectGroupingModeSelector(state);
        const { projectsSortingCriteria } = state.SettingsState;

        const { filterExpression } = getState().Filters[Sections.UNASSIGNED_WORK];
        const schedulingAreaData = getSchedulingAreaData(getState());

        const filter = getUnassignedAssignmentsProjectsFilter(
            filterExpression,
            prepareAreaAdditionalSimpleFilterForUnassignedProjects(schedulingAreaData, false),
            startDate,
            endDate,
            limit,
            offset,
            projectsSortingCriteria
        );

        const isIssuesIncluded = showIssuesSelector(state);

        let issuesFilter;
        if (isIssuesIncluded) {
            issuesFilter = getUnassignedIssuesProjectsFilter(
                filterExpression,
                prepareAreaAdditionalSimpleFilterForUnassignedProjects(schedulingAreaData, true),
                startDate,
                endDate,
                limit,
                offset,
                projectsSortingCriteria
            );
        }
        const promises: any[] = [
            APIService.loadUnassignedTasksProjects(filter),
            isIssuesIncluded
                ? APIService.loadUnassignedIssuesProjects(issuesFilter)
                : Promise.resolve([]),
        ];

        return Promise.all(promises)
            .then(async ([unassignedProjects, unassignedIssuesProjects]: any[][]) => {
                const projectColorMode = projectColorsModeSelector(getState());

                const colorModeProjectStatus = isColorModeProjectStatus(projectColorMode);
                const colorModeProject = isColorModeProject(projectColorMode);
                const projectGroupColors = projectGroupColorsSelector(state);
                const projectColorList = projectColorsSelector(state);
                const loadedProjectIDs: TProjectID[] = [];
                const loadedGroupData = {};
                const projectIDs = unassignedProjects.map((unassignedProject) => {
                    if (colorModeProjectStatus) {
                        const groupIDAlreadyExists =
                            projectGroupColors?.[unassignedProject.groupID];
                        if (!groupIDAlreadyExists && unassignedProject.groupID) {
                            loadedGroupData[unassignedProject.groupID] = unassignedProject.groupID;
                        }
                    } else if (colorModeProject) {
                        const alreadyExists = projectColorList[unassignedProject.ID];
                        if (!alreadyExists && unassignedProject.ID) {
                            loadedProjectIDs.push(unassignedProject.ID);
                        }
                    }
                    return unassignedProject.ID;
                });

                const loadedGroupDataIDs = Object.keys(loadedGroupData);
                if (startDate === getState().DateRange.startDate) {
                    const unassignedIssuesProjectsIds = unassignedIssuesProjects.map(
                        (project) => project.ID
                    );
                    const [groupsColors, unassignedTasksByProjectsIDs] = await Promise.all([
                        APIService.getColorsByGroupIds(loadedGroupDataIDs),
                        dispatch(
                            loadUnassignedTasksAndIssues(
                                startDate,
                                endDate,
                                projectIDs,
                                unassignedIssuesProjectsIds
                            )
                        ),
                    ]);

                    if (startDate === getState().DateRange.startDate) {
                        const unassignedAssignments: IUnassignedAssignment[] = flatten(
                            unassignedTasksByProjectsIDs
                        );
                        const actions: any = [];
                        if (loadingStateUnassignedWorkSectionSelector(getState())) {
                            actions.push(removeProjectsAndTasks(Sections.UNASSIGNED_WORK));
                        }
                        const tasksAndIssuesProjects: IRequestedUnassignedProjectData[] = _.uniqBy(
                            unassignedProjects.concat(unassignedIssuesProjects),
                            'ID'
                        );

                        dispatch(actionChain(actions));

                        const newState = getState();

                        const expandedUnassignedProjectIDs =
                            expandedUnassignedProjectIDsSelector(newState);

                        if (isInProjectAreaAndGroupingMode(newState)) {
                            // even though schedulingAreaID can not be empty string as isInProjectAreaAndGroupingMode checks that
                            // but linter could not understand that
                            const { schedulingAreaID = '' } = schedulingAreaData;
                            expandedUnassignedProjectIDs.add(schedulingAreaID);
                        }

                        const unassignedWorkHeight = unassignedWorkHeightSelector(state);

                        // Update external object, add new data
                        unassignedProjectsData = {
                            tasksAndIssuesProjects: [
                                ...unassignedProjectsData.tasksAndIssuesProjects,
                                ...tasksAndIssuesProjects,
                            ],
                            groupsColors: [...unassignedProjectsData.groupsColors, ...groupsColors],
                            unassignedAssignments: [
                                ...unassignedProjectsData.unassignedAssignments,
                                ...unassignedAssignments,
                            ],
                        };

                        // Add previously collected data data to state
                        dispatch(
                            addUnassignedProjects(
                                unassignedProjectsData.tasksAndIssuesProjects,
                                unassignedProjectsData.unassignedAssignments
                            )
                        );

                        const unassignedTasksProjects = getUnassignedTasksProjectsSelector(
                            getState()
                        );

                        const addableTableDataIDsCurrent =
                            unassignedProjectsData.tasksAndIssuesProjects.reduce(
                                (dataIds, project) => {
                                    if (
                                        unassignedTasksProjects[project.ID] &&
                                        unassignedTasksProjects[project.ID].nodes.length
                                    ) {
                                        dataIds.push(project.ID);
                                    }

                                    return dataIds;
                                },
                                [] as string[]
                            );

                        if (hasMore(limit, unassignedProjects.length)) {
                            const unassignedWorkEmptyRowHeight = calcUnassignedWorkEmptyRowHeight(
                                addableTableDataIDsCurrent,
                                unassignedWorkHeight,
                                groupingMode
                            );

                            const shouldLoadAgain = unassignedWorkEmptyRowHeight > 0;

                            if (shouldLoadAgain) {
                                dispatch(
                                    loadUnassignedAssignmentsProjects(
                                        limit,
                                        offset + LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT
                                    )
                                );
                                return;
                            }
                        }

                        const args = {
                            endDate,
                            expandedUnassignedProjectIDs,
                            groupingMode,
                            limit,
                            offset,
                            startDate,
                            unassignedTasks: unassignedProjectsData.unassignedAssignments,
                            unassignedProjects: unassignedProjectsData.tasksAndIssuesProjects,
                            unassignedTasksProjects: getUnassignedTasksProjectsSelector(getState()),
                            unassignedWorkHeight,
                            schedulingAreaData,
                            isSortingCriteriaSet: !!projectsSortingCriteria?.key,
                        };

                        const { actions: chainActions } =
                            finalActionsForLoadingUnassignedProjects(args);

                        if (colorModeProjectStatus) {
                            const groupsColorsRefactored = prepareColorsByProjectGroupData(
                                loadedGroupDataIDs,
                                unassignedProjectsData.groupsColors
                            );
                            chainActions.push(addProjectGroupColors(groupsColorsRefactored));
                        } else if (colorModeProject && loadedProjectIDs.length) {
                            const colorsByProjectID = prepareColorsByProjectData(loadedProjectIDs);
                            dispatch(addProjectColors(colorsByProjectID));
                        }

                        dispatch(changeUnassignedDataRequestMode(true, Project));

                        // reset external object after finalizing
                        unassignedProjectsData = {
                            tasksAndIssuesProjects: [],
                            groupsColors: [],
                            unassignedAssignments: [],
                        };
                        await dispatch(loadAssignmentsByTaskIDsFormService(args, chainActions));
                        return;
                    }
                }

                dispatch(changeUnassignedDataRequestMode(true, Project));
            })
            .catch((error) => {
                // Reload once if sorting criteria custom field is deleted and we get 422 error
                if (error?.status === 422 && !reloaded) {
                    reloaded = true;
                    dispatch(setProjectsSortingCriteria(undefined));
                    _loadUnassignedAssignmentsProjects(dispatch, getState);
                } else {
                    dispatch(
                        actionChain(actionsWhenError(error.message, Sections.UNASSIGNED_WORK))
                    );
                }
            });
    };
};

export const loadUnassignedSectionMonthPeriodModeToggleThunk = (): TWorkSchedulingThunkAction<
    Promise<void>
> => {
    return function _loadAssignedSectionMonthPeriodModeToggleThunk(dispatch, getState) {
        const oldState = getState();
        const expandedUnassignedProjectIDsBeforeNewDataIsGet =
            oldState.Data.expandedUnassignedProjectIDs;

        dispatch(actionChain(resetUnassignedSectionData()));
        return dispatch(loadDataForUnassignedSection()).then(() => {
            const state = getState();
            const projectGroupingMode = projectGroupingModeSelector(state);
            const isNotUnderProjectArea = !isInProjectArea(state.areaData.schedulingAreaObjCode);

            /**
             * Unassigned section is always expanded under Project area,
             * so we don't need to expand previously expanded projects after
             * period change.
             * */
            if (projectGroupingMode && isNotUnderProjectArea) {
                const actions: any[] = [];

                const unassignedTasksProjects = getUnassignedTasksProjectsSelector(state);
                const unassignedWorkHeight = unassignedWorkHeightSelector(state);

                expandedUnassignedProjectIDsBeforeNewDataIsGet.forEach((projId) => {
                    if (unassignedTasksProjects[projId]) {
                        actions.push(
                            changeUnassignedNodeArrowState(projId),
                            ...arrowCollapsedStateActions(
                                projId,
                                unassignedTasksProjects[projId].nodes,
                                unassignedWorkHeight,
                                projectGroupingMode
                            )
                        );
                    }
                });

                dispatch(actionChain(actions));
            }
        });
    };
};

export const actionsWhenError = (error: IErrorDetail, section: Sections): any[] => {
    return [
        toggleLoading(section, false),
        changeErrorState(section, { ...error, isErrorThrown: true }),
    ];
};

export interface IFinalActionsForLoadingUnassignedProjects {
    unassignedProjects: IRequestedUnassignedProjectData[];
    unassignedTasks: IUnassignedAssignment[];
    unassignedTasksProjects: IUnassignedTasksProjects;
    unassignedWorkHeight: TUnassignedWorkHeight;
    schedulingAreaData: IAreaState;
    offset: number;
    groupingMode: boolean;
    isSortingCriteriaSet: boolean;
    limit: number;
    startDate: TStartDate;
    endDate: TEndDate;
    expandedUnassignedProjectIDs: Set<TProjectID>;
}

export function finalActionsForLoadingUnassignedProjects(
    args: IFinalActionsForLoadingUnassignedProjects
): {
    actions: any[];
    addableTableDataIDs: TTableDataIDs;
} {
    const {
        unassignedProjects,
        unassignedTasks,
        limit,
        offset,
        groupingMode,
        unassignedTasksProjects,
        expandedUnassignedProjectIDs,
        unassignedWorkHeight,
        schedulingAreaData,
        startDate,
        endDate,
        isSortingCriteriaSet,
    } = args;

    const chainedActions: any[] = [];
    let addableTableDataIDs: TTableDataIDs = [];
    const newlyGotUnassignedProjects: ITasksAndProjects['projects'] = {};

    const expandedProjectsTasksIds: { [id: TProjectID]: TTaskOrIssueID[] } = {};

    unassignedProjects.forEach((project) => {
        newlyGotUnassignedProjects[project.ID] = {
            name: project.name,
        };

        if (
            unassignedTasksProjects[project.ID] &&
            unassignedTasksProjects[project.ID].nodes.length
        ) {
            addableTableDataIDs.push(project.ID);
            if (
                limit &&
                expandedUnassignedProjectIDs.size !== 0 &&
                expandedUnassignedProjectIDs.has(project.ID)
            ) {
                const innerDataIDs = unassignedTasksProjects[project.ID].nodes.map(
                    (node) => `${project.ID}_${node}`
                );
                chainedActions.push(changeUnassignedNodeArrowState(project.ID));
                expandedProjectsTasksIds[project.ID] = innerDataIDs;
            }
        }
    });

    let actions: any[] = [
        ...chainedActions,
        changeHasMore(limit <= unassignedProjects.length, Sections.UNASSIGNED_WORK),
        toggleLoading(Sections.UNASSIGNED_WORK, false),
    ];

    if (isFirstLoadByOffset(offset)) {
        actions.push(removeTableDataIDsForUnassignedSection(''));
    }

    const inProjectArea = isInProjectArea(schedulingAreaData.schedulingAreaObjCode);
    if (!inProjectArea) {
        const unassignedTasksIds: IUnassignedTasksAndProjects['tasks'] = unassignedTasks.reduce(
            (acc, task) => {
                acc[task.ID] = task;
                return acc;
            },
            {}
        );

        const unassignedTasksAndProjects: IUnassignedTasksAndProjects = {
            tasks: unassignedTasksIds,
            projects: newlyGotUnassignedProjects,
        };

        let sortedTableDataIDs = addableTableDataIDs;
        if (!isSortingCriteriaSet) {
            sortedTableDataIDs = sortUnassignedProjectsByTasksDetails(
                addableTableDataIDs,
                unassignedTasksAndProjects,
                startDate,
                endDate,
                unassignedTasksProjects
            );
        }

        expandedUnassignedProjectIDs.forEach((id) => {
            /**
             * The expanded projects tasks and issues are
             * added to SORTED projects' ids
             * */
            const doesExpandedProjectExistInNewlySelectedTimeframe = newlyGotUnassignedProjects[id];
            if (doesExpandedProjectExistInNewlySelectedTimeframe) {
                const sliceIndex = sortedTableDataIDs.indexOf(id) + 1;

                sortedTableDataIDs = [
                    ...sortedTableDataIDs.slice(0, sliceIndex),
                    ...expandedProjectsTasksIds[id],
                    ...sortedTableDataIDs.slice(sliceIndex),
                ];
            }
        });
        addableTableDataIDs = sortedTableDataIDs;
    } else {
        addableTableDataIDs = addableTableDataIDs.concat(
            flatten(Object.values(expandedProjectsTasksIds))
        );
    }

    actions = [
        ...actions,
        addTableDataIDsForUnassignedSection(addableTableDataIDs),
        removeRow('_loading', Sections.UNASSIGNED_WORK),
        setHeightForUnassignedSectionEmptyRow(unassignedWorkHeight, groupingMode),
    ];

    return {
        actions,
        addableTableDataIDs,
    };
}

let oldOffset = 0;
let unassignedTasksIdsTail: string[] = [];
function finalActionsForLoadingUnassignedSection(args): any[] {
    let {
        oldUnassignedTasks,
        unassignedTasks,
        limit,
        offset,
        unassignedWorkHeight,
        projectGroupingMode,
        oldUnassignedSectionDataIds,
    } = args;
    let actions: any[] = [changeHasMore(limit <= unassignedTasks.length, Sections.UNASSIGNED_WORK)];

    if (isFirstLoadByOffset(offset)) {
        actions.push(removeTableDataIDsForUnassignedSection(''));
        unassignedTasksIdsTail = [];
    }
    if (offset === 0) {
        offset = 20;
        oldOffset = 0;
    } else {
        oldOffset = offset;
        offset += 20;
    }

    const newUnassignedTasksIds: string[] = [];
    const unassignedTasksObj: ITasksAndProjects['tasks'] = unassignedTasks.reduce(
        (acc: ITasksAndProjects['tasks'], task) => {
            newUnassignedTasksIds.push(task.ID);
            acc[task.ID] = {
                ...task,
                plannedStartDate: apiDateToDate(task.plannedStartDate),
                plannedCompletionDate: apiDateToDate(task.plannedCompletionDate),
            };
            return acc;
        },
        {}
    );

    const unassignedTasksObjTail = unassignedTasksIdsTail.reduce((acc, item) => {
        acc[item] = oldUnassignedTasks[item];
        return acc;
    }, {});

    const tailPlusNewDataIds = unassignedTasksIdsTail.concat(newUnassignedTasksIds);
    const tailPlusNewUnassignedTasksObj = { ...unassignedTasksObjTail, ...unassignedTasksObj };

    tailPlusNewDataIds.sort(sortObject(tailPlusNewUnassignedTasksObj, unassignedTasksSortObject));

    const allUnassignedDataIds = oldUnassignedSectionDataIds
        .filter((id) => id !== '_loading')
        .concat(tailPlusNewDataIds);

    unassignedTasksIdsTail = allUnassignedDataIds.slice(offset);

    const newDataIdsBatch = allUnassignedDataIds.slice(oldOffset, offset);

    actions = [
        ...actions,
        addTableDataIDsForUnassignedSection(newDataIdsBatch),
        toggleLoading(Sections.UNASSIGNED_WORK, false),
        removeRow('_loading', Sections.UNASSIGNED_WORK),
        setHeightForUnassignedSectionEmptyRow(unassignedWorkHeight, projectGroupingMode),
    ];

    return actions;
}

function loadUnassignedTasksAndIssues(
    startDate,
    endDate,
    projectIds,
    issuesProjectIds,
    taskId = null
) {
    return function _loadUnassignedTasks(dispatch, getState) {
        const state = getState();
        const { filterExpression } = state.Filters[Sections.UNASSIGNED_WORK];
        const schedulingAreaData = getSchedulingAreaData(state);
        const additionalSimpleFilter =
            prepareAreaAdditionalSimpleFilterForUnassignedTasks(schedulingAreaData);
        const { filter, filterOptask } = getUnassignedTasksFilter(
            filterExpression,
            additionalSimpleFilter,
            startDate,
            endDate,
            taskId
        );

        return APIService.loadUnassignedAssignmentsByProjects(
            filter,
            filterOptask,
            projectIds,
            issuesProjectIds,
            showIssuesSelector(state)
        );
    };
}

export function loadMissingDataForUnassignedSectionThunk(): TWorkSchedulingThunkAction<
    Promise<void>
> {
    return function _loadMissingDataForUnassignedSectionThunk(dispatch, getState) {
        dispatch(removeTableDataIDsForUnassignedSection(''));
        internalEventEmitterSelector(getState()).emit(RECOMPUTE_GRID_SIZE);

        return dispatch(loadDataForUnassignedSection());
    };
}
