import { Sections } from '../../../../constants/schedulingTableConstants';
import { settingNames } from '../../../settings/settingsActions/settingsActions';
import toggleLoading from '../../dataLoadingActions/toggleLoading';
import removeProjectsAndTasks from '../../sharedActions/removeProjectsAndTasks';
import removeTableDataIDs from '../removeTableDataIDs';
import removeUsersData from '../removeUsersData';
import removeUsersProjects from '../removeUsersProjects';
import removeExpandedFlagsForUnassignedSection from '../../unassignedDataActions/removeExpandedFlagsForUnassignedSection';
import removeTableDataIDsForUnassignedSection from '../../unassignedDataActions/removeTableDataIDsForUnassignedSection';

export const resetUsersData = (settingName?: string): any[] => {
    const actions = [
        toggleLoading(Sections.PEOPLE_WORKLOAD, true),
        removeUsersData(true),
        removeProjectsAndTasks(Sections.PEOPLE_WORKLOAD),
        removeUsersProjects(),
        removeTableDataIDs(''),
    ];
    if (settingName === settingNames.showIssues) {
        actions.push(
            toggleLoading(Sections.UNASSIGNED_WORK, true),
            removeExpandedFlagsForUnassignedSection(),
            removeProjectsAndTasks(Sections.UNASSIGNED_WORK),
            removeTableDataIDsForUnassignedSection('')
        );
    }
    return actions;
};
