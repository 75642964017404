import { sizes } from '../../../constants/schedulingTableConstants';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

interface ISetHeightForPeopleWorkloadSectionEmptyRow {
    peopleWorkloadHeight: number;
    isProjectGroupingTurnedOn?: boolean;
    showActualProgress: boolean;
}

export type TSetHeightForPeopleWorkloadSectionEmptyRow =
    TModernSchedulingAction<ISetHeightForPeopleWorkloadSectionEmptyRow>;

export const ActionType = 'SET_HEIGHT_FOR_PEOPLE_WORKLOAD_SECTION_EMPTY_ROW';

export const setHeightForPeopleWorkloadEmptyRow = (
    peopleWorkloadHeight,
    showActualProgress: boolean,
    isProjectGroupingTurnedOn = false
): TSetHeightForPeopleWorkloadSectionEmptyRow => ({
    type: ActionType,
    payload: {
        peopleWorkloadHeight,
        isProjectGroupingTurnedOn,
        showActualProgress,
    },
});
export default setHeightForPeopleWorkloadEmptyRow;

export function handle(
    state: IDataState,
    action: TSetHeightForPeopleWorkloadSectionEmptyRow
): IDataState {
    const { tableDataIDs } = state;
    const { tableRowTaskHeight, tableRowProjectHeight, tableRowHeight, tableHeaderRowHeight } =
        sizes;

    const { peopleWorkloadHeight, isProjectGroupingTurnedOn } = action.payload;

    const subtractiveHeightForEveryDataID: number = tableDataIDs.reduce(
        (acc: number, dataID: string) => {
            const dataIDPartsQty: number = dataID.split('_').length;
            if (dataIDPartsQty === 1) {
                return acc + tableRowHeight;
            }
            if (isProjectGroupingTurnedOn) {
                if (dataIDPartsQty === 2) {
                    return acc + tableRowProjectHeight;
                }
                return acc + tableRowTaskHeight;
            }
            return acc + tableRowTaskHeight;
        },
        tableHeaderRowHeight
    );

    return {
        ...state,
        peopleWorkloadEmptyRowHeight: subtractiveHeightForEveryDataID < peopleWorkloadHeight,
    };
}
