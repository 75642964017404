import _ from 'lodash';
import moment, { Moment } from 'moment';
import { apiDateToDate } from '@workfront/fns';
import { unassignedTasksSortObject, usersTasksSortObject } from '../constants/dataConstatnts';
import { IAreaState } from '../data-flow/areaData/areaRelatedInitialDataState';
import { isInProjectArea, sortObject } from './utilities';
import {
    IObjectState,
    IUnassignedAssignment,
    IUnassignedTasksProjects,
    TasksAndIssuesRecord,
    TEndDate,
    TProjectID,
    TShowActualProgress,
    TStartDate,
    TTaskOrIssueID,
    TUserID,
    TUsers,
} from '../data-flow/data/IDataState';

export interface ITasksAndProjects {
    tasks: TasksAndIssuesRecord;
    projects: Record<TProjectID, { name: string }>;
}

export interface IUnassignedTasksAndProjects {
    tasks: Record<TTaskOrIssueID, IUnassignedAssignment>;
    projects: Record<TProjectID, { name: string }>;
}

export const sortProjectsByTasksDetails = (
    projectIDs: TProjectID[],
    users: TUsers,
    userID: TUserID,
    tasksAndProjects: ITasksAndProjects,
    { startDate, endDate }: { startDate: TStartDate; endDate: TEndDate },
    showActualProgress: TShowActualProgress,
    schedulingAreaData: IAreaState
): TProjectID[] => {
    const userProjects = users[userID].nodes;
    if (!Object.keys(userProjects).length) {
        return [];
    }

    const projectDataForSorting: any = {};
    const { schedulingAreaObjCode, schedulingAreaID } = schedulingAreaData;
    const inProjectArea = isInProjectArea(schedulingAreaObjCode);

    projectIDs.forEach((projectID) => {
        const projectNodes = userProjects[projectID].nodes;
        if (!projectNodes.length) {
            return;
        }

        let {
            tasksPlannedStartDates,
            tasksPlannedCompletionDates,
            planedCompletionDateOfLatestTask,
            planedStartDateOfFirstNode,
        } = getPlannedDates(projectNodes, tasksAndProjects, showActualProgress, startDate, endDate);

        if (!showActualProgress) {
            planedStartDateOfFirstNode = getPlanedStartDateOfFirstNode(
                tasksAndProjects,
                projectNodes
            );
        }

        const projectStartDateForPeriod = getProjectStartDateForPeriod(
            showActualProgress,
            startDate,
            planedStartDateOfFirstNode,
            tasksPlannedStartDates
        );

        const projectEndDateForPeriod = getProjectEndDateForPeriod(
            planedCompletionDateOfLatestTask,
            tasksPlannedCompletionDates
        );

        projectDataForSorting[projectID] = {
            plannedStartDate: projectStartDateForPeriod.format(),
            plannedCompletionDate: projectEndDateForPeriod.format(),
            name: tasksAndProjects.projects[projectID].name,
        };
    });

    projectIDs.sort(sortObject(projectDataForSorting, usersTasksSortObject));

    if (shouldApplyProjectAreaSorting(inProjectArea, schedulingAreaID)) {
        projectIDs.sort(sortByProjectID(schedulingAreaID));
    }

    return projectIDs;
};

export const sortUnassignedProjectsByTasksDetails = (
    tableDataIDs: TProjectID[],
    unassignedTasksAndProjects: IUnassignedTasksAndProjects,
    startDate: TStartDate,
    endDate: TEndDate,
    unassignedTasksProjects: IUnassignedTasksProjects
): TProjectID[] => {
    const addableTableDataIDs = Array.from(tableDataIDs);
    if (!addableTableDataIDs.length) {
        return addableTableDataIDs;
    }

    const projectDataForSorting: any = {};

    addableTableDataIDs.forEach((projectID) => {
        const projectNodes = unassignedTasksProjects[projectID].nodes;
        if (!projectNodes.length) {
            return;
        }

        const {
            tasksPlannedStartDates,
            tasksPlannedCompletionDates,
            planedCompletionDateOfLatestTask,
        } = getPlannedDatesForUnassign(
            projectNodes,
            unassignedTasksAndProjects,
            false,
            startDate,
            endDate
        );

        const planedStartDateOfFirstNode = getUnassignedPlanedStartDateOfFirstNode(
            unassignedTasksAndProjects,
            projectNodes
        );

        const projectStartDateForPeriod = getProjectStartDateForPeriod(
            false,
            startDate,
            planedStartDateOfFirstNode,
            tasksPlannedStartDates
        );

        const projectEndDateForPeriod = getProjectEndDateForPeriod(
            planedCompletionDateOfLatestTask,
            tasksPlannedCompletionDates
        );

        projectDataForSorting[projectID] = {
            plannedStartDate: projectStartDateForPeriod.format(),
            plannedCompletionDate: projectEndDateForPeriod.format(),
            name: unassignedTasksAndProjects.projects[projectID].name,
        };
    });

    addableTableDataIDs.sort(sortObject(projectDataForSorting, unassignedTasksSortObject));
    return addableTableDataIDs;
};

interface IGetPlannedDates {
    tasksPlannedStartDates: Moment;
    tasksPlannedCompletionDates: Moment;
    planedCompletionDateOfLatestTask: Moment;
    planedStartDateOfFirstNode: Moment;
}

export const getPlannedDates = (
    projectNodes: string[],
    tasksAndProjects: ITasksAndProjects,
    showActualProgress: TShowActualProgress,
    startDate: TStartDate,
    endDate: TEndDate
): IGetPlannedDates => {
    const tasksPlannedStartDates: any = [];
    const tasksPlannedCompletionDates: any = [];
    let planedCompletionDateOfLatestTask: any = {};
    let planedStartDateOfFirstNode: any = {};

    projectNodes.forEach((node) => {
        const task = tasksAndProjects.tasks[node];
        const {
            plannedCompletionDateMoment,
            plannedStartDateMoment,
            projectedCompletionDateMoment,
            projectedStartDateMoment,
        } = convertTaskDatesToMoment(task);

        if (showActualProgress) {
            if (plannedCompletionDateMoment.isBefore(startDate)) {
                tasksPlannedStartDates.push(projectedStartDateMoment);
                tasksPlannedCompletionDates.push(projectedCompletionDateMoment);
            } else {
                tasksPlannedStartDates.push(plannedStartDateMoment);
                tasksPlannedCompletionDates.push(plannedCompletionDateMoment);
            }
            if (
                plannedStartDateMoment.isBefore(startDate) &&
                plannedCompletionDateMoment.isAfter(startDate)
            ) {
                planedStartDateOfFirstNode = startDate;
            }
        } else {
            tasksPlannedCompletionDates.push(plannedCompletionDateMoment);
        }

        if (plannedCompletionDateMoment.isAfter(endDate)) {
            planedCompletionDateOfLatestTask = endDate;
        }
    });

    return {
        tasksPlannedStartDates,
        tasksPlannedCompletionDates,
        planedCompletionDateOfLatestTask,
        planedStartDateOfFirstNode,
    };
};

export const getPlannedDatesForUnassign = (
    projectNodes: string[],
    tasksAndProjects: IUnassignedTasksAndProjects,
    showActualProgress: TShowActualProgress,
    startDate: TStartDate,
    endDate: TEndDate
): Omit<IGetPlannedDates, 'planedStartDateOfFirstNode'> => {
    const tasksPlannedStartDates: any = [];
    const tasksPlannedCompletionDates: any = [];
    let planedCompletionDateOfLatestTask: any = {};

    projectNodes.forEach((node) => {
        const task = tasksAndProjects.tasks[node];
        const plannedCompletionDateMoment = moment(apiDateToDate(task.plannedCompletionDate));

        tasksPlannedCompletionDates.push(plannedCompletionDateMoment);

        if (plannedCompletionDateMoment.isAfter(endDate)) {
            planedCompletionDateOfLatestTask = endDate;
        }
    });

    return {
        tasksPlannedStartDates,
        tasksPlannedCompletionDates,
        planedCompletionDateOfLatestTask,
    };
};

interface IConvertTaskDatesToMoment {
    plannedCompletionDateMoment: Moment;
    plannedStartDateMoment: Moment;
    projectedCompletionDateMoment: Moment;
    projectedStartDateMoment: Moment;
}

export const convertTaskDatesToMoment = (task: IObjectState): IConvertTaskDatesToMoment => {
    return {
        plannedCompletionDateMoment: moment(task.plannedCompletionDate),
        plannedStartDateMoment: moment(task.plannedStartDate),
        projectedCompletionDateMoment: moment(task.projectedCompletionDate),
        projectedStartDateMoment: moment(task.projectedStartDate),
    };
};

export const getPlanedStartDateOfFirstNode = (
    tasksAndProjects: ITasksAndProjects,
    projectNodes: string[]
): Moment => {
    const firstNode = tasksAndProjects.tasks[projectNodes[0]];
    return firstNode && moment(firstNode.plannedStartDate);
};

export const getUnassignedPlanedStartDateOfFirstNode = (
    tasksAndProjects: IUnassignedTasksAndProjects,
    projectNodes: string[]
): Moment => {
    const firstNode = tasksAndProjects.tasks[projectNodes[0]];
    return firstNode && moment(apiDateToDate(firstNode.plannedStartDate));
};

export const getProjectStartDateForPeriod = (
    showActualProgress: TShowActualProgress,
    startDate: TStartDate,
    planedStartDateOfFirstNode: Moment,
    tasksPlannedStartDates: Moment
): Moment => {
    let projectStartDateForPeriod: moment.Moment = planedStartDateOfFirstNode;
    if (showActualProgress) {
        projectStartDateForPeriod = !_.isEmpty(planedStartDateOfFirstNode)
            ? startDate
            : moment.min(tasksPlannedStartDates);
    }

    return projectStartDateForPeriod;
};

export const getProjectEndDateForPeriod = (
    planedCompletionDateOfLatestTask: any,
    tasksPlannedCompletionDates: Moment
): Moment => {
    return !_.isEmpty(planedCompletionDateOfLatestTask)
        ? planedCompletionDateOfLatestTask
        : moment.max(tasksPlannedCompletionDates);
};

export const shouldApplyProjectAreaSorting = (
    inProjectArea: boolean,
    schedulingAreaID?: string
): boolean => {
    // in project are project where balancer integrated should be on top
    // skipping for sorting, after will be added as a first element
    return !!(inProjectArea && schedulingAreaID);
};

export const sortByProjectID = (schedulingAreaID?: string, getter = (item) => item) => {
    return (projectID_1, projectID_2): -1 | 1 | 0 => {
        if (getter(projectID_1) === schedulingAreaID && getter(projectID_2) !== schedulingAreaID) {
            return -1;
        }
        if (getter(projectID_1) !== schedulingAreaID && getter(projectID_2) === schedulingAreaID) {
            return 1;
        }

        return 0;
    };
};
