import { TModernSchedulingAction } from '../../types';
import { ITableSizesState } from '../ITableSizesState';

interface ISetWorkTablesHeightsActionPayload {
    peopleWorkLoadHeight: number;
    unassignedWorkHeight: number;
}

export type TSetWorkTablesHeightsAction =
    TModernSchedulingAction<ISetWorkTablesHeightsActionPayload>;

export const ActionType = 'CHANGE_WORK_TABLES_HEIGHTS';

const setWorkTablesHeights = (
    peopleWorkLoadHeight: number,
    unassignedWorkHeight: number
): TSetWorkTablesHeightsAction => ({
    type: ActionType,
    payload: {
        peopleWorkLoadHeight,
        unassignedWorkHeight,
    },
});
export default setWorkTablesHeights;

export function handle(
    state: ITableSizesState,
    action: TSetWorkTablesHeightsAction
): ITableSizesState {
    return {
        ...state,
        peopleWorkLoadHeight: action.payload.peopleWorkLoadHeight,
        unassignedWorkHeight: action.payload.unassignedWorkHeight,
    };
}
