import { css, injectGlobal } from 'react-emotion';

export const assignmentActionsStyles = css`
    margin-top: 6px;
    & button {
        display: none;
    }
`;

// TODO remove overriding styles when this code will work only in QS
export const assignmentWidgetOverridingStyles = css`
    box-shadow: 8px 9px 22px 0 rgba(0, 0, 0, 0.3);

    & div[data-test-id='data-test-assignments-pill-container'] {
        box-sizing: border-box;
        & input {
            width: 100%;
            box-shadow: none;
        }
    }
    & div[data-test-id='data-test-assignments-search-results'] {
        & div[data-testid='avatar'] {
            & span[data-testid='default-avatar-tooltip'] {
                & > span {
                    box-sizing: border-box;
                }
            }
        }
    }
`; // it is for making avatars behave properly in kamino

// tslint:disable-next-line
injectGlobal`
	div#options-container > div[data-testid="undefined-options"] > ul > li{
		&:active {
			background-color: rgb(0, 120, 215) !important;
		}
	}
`;
