import { Assignment } from 'workfront-objcodes';
import { forEach, map } from 'lodash';
import setRoleSummaryActiveKey from '../../data/assignedDataActions/roleSummary/setRoleSummaryActiveKey';
import { editSettingsThunk } from '../settings';
import { showRoleSummarySelector } from '../../settings/settingsSelector';
import { getSchedulingAreaData } from '../../areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { toggleRoleSummaryActions } from '../../data/assignedDataActions/commonActionGroups/contouringActionGroup';
import { TWorkSchedulingThunkAction } from '../../types';
import { isEspProductEnabled } from '../../data/selectors/dataSelectors';
import { getWorkRequiredSummaryForRoleFilter } from '../../../util/filters/filterUtil';
import { APIService } from '../../../services/api-services/apiService';
import { RoleSummaryHourKey } from '../../../constants/RoleSummaryHourKey';
import actionChain from '../../higher-order-reducers/actionChain';
import setRoleSummary from '../../data/assignedDataActions/roleSummary/setRoleSummary';
import { IWorkRequiredRoleSummaryData, RoleSummaryKeys } from '../../data/IDataState';
import { convertMinuteToHour, sortByTitleCallback } from '../../../util/utilities';

export function toggleRoleSummary(toggleRoleSummaryHard?: boolean, roleSummaryMonthKey?: string) {
    return function _toggleRoleSummary(dispatch, getState, context) {
        const state = getState();

        const isRoleSummaryOpen = showRoleSummarySelector(state);
        const schedulingAreaData = getSchedulingAreaData(state);
        const isInSharableLink = context.sharableLink;

        if (!isInSharableLink) {
            context.minixClose();
        }

        dispatch(setRoleSummaryActiveKey(true, roleSummaryMonthKey, true));

        const areWeGoingToOpenRoleSummary = !isRoleSummaryOpen;
        if (areWeGoingToOpenRoleSummary && schedulingAreaData.schedulingAreaID) {
            dispatch(loadWorkSummaryForRoles());
        }

        dispatch(toggleRoleSummaryActions(toggleRoleSummaryHard));

        if (!isInSharableLink) {
            dispatch(editSettingsThunk());
        }
    };
}

interface IWorkActualRoleSummaryDataFromAPI {
    ID: string;
    fteHours: number;
    name: string;
    objCode: string;
    roleID: string;
}

export interface IWorkPlannedRoleSummaryDataFromAPI {
    dcount_ID: number;
    roleID: string;
    role_name: string;
    sum_workRequired: number;
}

export const getPlannedRoleSummaryStructured = (
    workPlannedData: Record<string, Record<string, IWorkPlannedRoleSummaryDataFromAPI>>,
    espProductEnabled: boolean
): Record<string, IWorkRequiredRoleSummaryData> => {
    const workRequiredRoleSummary: Record<string, IWorkRequiredRoleSummaryData> = {};

    forEach(workPlannedData, (dataByRole: Record<string, IWorkPlannedRoleSummaryDataFromAPI>) => {
        forEach(dataByRole, (summaryPerRoleData: IWorkPlannedRoleSummaryDataFromAPI) => {
            workRequiredRoleSummary[summaryPerRoleData.roleID] = {
                roleID: summaryPerRoleData.roleID,
                roleTitle: summaryPerRoleData.role_name,
                [RoleSummaryHourKey.PLANNED_HOUR]: convertMinuteToHour(
                    summaryPerRoleData.sum_workRequired
                ),
            };

            if (espProductEnabled) {
                workRequiredRoleSummary[summaryPerRoleData.roleID][
                    RoleSummaryHourKey.APPROVED_HOUR
                ] = undefined;
            }
        });
    });

    return workRequiredRoleSummary;
};

export const restructureAndSortRoleSummary = (
    workRequiredRoleSummary: Record<string, IWorkRequiredRoleSummaryData>
): IWorkRequiredRoleSummaryData[] => {
    return map(workRequiredRoleSummary, (workRequiredRole) => workRequiredRole).sort(
        sortByTitleCallback
    );
};

export const loadWorkSummaryForRoles = (): TWorkSchedulingThunkAction<Promise<void>> => {
    return function _loadWorkSummaryForRoles(dispatch, getState) {
        const state = getState();

        const espProductEnabled = isEspProductEnabled(state);
        const schedulingAreaData = getSchedulingAreaData(state);
        const { schedulingAreaID: projectID = '' } = schedulingAreaData;

        const queryFilter = getWorkRequiredSummaryForRoleFilter(projectID);

        const roleSummaryPromises = [APIService.apiReport(Assignment, queryFilter)];
        if (espProductEnabled) {
            roleSummaryPromises.push(
                APIService.apiSearch('INTROL', { projectID }, ['roleID', 'fteHours'])
            );
        }

        return Promise.all(roleSummaryPromises).then((values) => {
            const workPlannedData: Record<
                string,
                Record<string, IWorkPlannedRoleSummaryDataFromAPI>
            > = values[0];
            const workRequiredRoleSummary = getPlannedRoleSummaryStructured(
                workPlannedData,
                espProductEnabled
            );

            if (espProductEnabled) {
                // append approved hours to planned hours structure
                const workApprovedData: IWorkActualRoleSummaryDataFromAPI[] = values[1];
                workApprovedData.forEach((workApproved) => {
                    if (workRequiredRoleSummary[workApproved.roleID]) {
                        workRequiredRoleSummary[workApproved.roleID][
                            RoleSummaryHourKey.APPROVED_HOUR
                        ] = workApproved.fteHours;
                    } else {
                        workRequiredRoleSummary[workApproved.roleID] = {
                            roleID: workApproved.roleID,
                            roleTitle: workApproved.name,
                            [RoleSummaryHourKey.APPROVED_HOUR]: workApproved.fteHours,
                        };
                    }
                });
            }

            const workRequiredRoleSummaryArr =
                restructureAndSortRoleSummary(workRequiredRoleSummary);

            dispatch(
                actionChain([setRoleSummary(workRequiredRoleSummaryArr, RoleSummaryKeys.TOTAL)])
            );
        });
    };
};
