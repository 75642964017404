import { Button, Tooltip } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import * as React from 'react';
import SummaryIcon from 'phoenix-icons/dist/SummaryIcon.js';
import { getHeaderItemStyles } from '../../styles/headerComponentStyles';

interface ISummarySetting {
    minixState: { ID: string } | null;
    schedulingAreaObjCode: string;
    handleMinixOpen: () => void;
}

export const SummarySetting: React.FunctionComponent<ISummarySetting> = ({
    minixState,
    schedulingAreaObjCode,
    handleMinixOpen,
}) => {
    const isMinixOpen = minixState !== null;
    const summaryIconKey = isMinixOpen ? 'minix.close' : 'minix.open';

    const handleOnClick = (): void => {
        handleMinixOpen();
    };

    const summarySettingClassName = getHeaderItemStyles(isMinixOpen);

    return (
        <Localization<string[]> messageKeys={['minix.close', 'minix.open']}>
            {({ _t }) => {
                return (
                    <Tooltip content={_t[summaryIconKey]('Open/Close Summary')} delay={700}>
                        <Button
                            text
                            className={summarySettingClassName}
                            data-testid={`summaryButton${schedulingAreaObjCode}`}
                            onClick={handleOnClick}
                            aria-expanded={isMinixOpen}
                            aria-label={_t['minix.open']('Open Summary')}
                        >
                            <SummaryIcon focusable="false" />
                        </Button>
                    </Tooltip>
                );
            }}
        </Localization>
    );
};
