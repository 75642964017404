import { Font, primary } from '@phoenix/all';
import { Moment } from 'moment';
import * as React from 'react';
import { css, cx } from 'react-emotion';
import { TimeUnit } from '../../../constants/periodEnums';
import { IDateRangeState } from '../../../data-flow/dateRange/IDateRangeState';
import { TableHeaderPeriodCell } from './TableHeaderPeriodCell';
import {
    borderBottomRemovedStyle,
    borderTopRemovedStyle,
    tableHeaderRightWrapper,
} from './TableHeaderStyles';
import { TableHeaderDayContent } from './TabelHeaderContent/TableHeaderDayContent';
import { TableHeaderWeekContent } from './TabelHeaderContent/TableHeaderWeekContent';
import { TableHeaderMonthContent } from './TabelHeaderContent/Month/TableHeaderMonthContent';
import {
    getRoleSummaryMonthKey,
    getStartOfPeriodClean,
    isInProjectArea,
} from '../../../util/utilities';
import { RoleSummaryDataType, IRoleSummaryLoading } from '../../../data-flow/data/IDataState';
import { toggleRoleSummary } from '../../../data-flow/thunks/roleSummary/toggleRoleSummaryThunk';
import { useAppDispatch } from '../../../data-flow/hooks';

interface ITableHeaderPeriodsProps
    extends React.HTMLAttributes<HTMLDivElement>,
        Pick<IDateRangeState, 'periods'>,
        Pick<IDateRangeState, 'stepUnit'> {
    changeCellToSmallMode?: boolean;
    endDateOfPeriod: Moment;
    schedulingAreaObjCode?: string;
    onMonthRoleAllocationOpen: (start: Moment, end: Moment, roleSummaryMonthKey: string) => void;
    roleSummaryMonthlyLoading: IRoleSummaryLoading;
    roleSummaryData: RoleSummaryDataType;
}

const text = css`
    color: ${primary.gray(600)} !important;
    line-height: 35px !important;
    font-weight: 600 !important;
`;

export const TableHeaderPeriods: React.FunctionComponent<ITableHeaderPeriodsProps> = React.memo(
    (props) => {
        const dispatch = useAppDispatch();
        const cellContent = (unit, key, isLastOne: boolean): React.ReactElement => {
            if (props.stepUnit === TimeUnit.DAY) {
                return <TableHeaderDayContent unit={unit} keyVal={key} />;
            }
            if (props.stepUnit === TimeUnit.WEEK) {
                return (
                    <TableHeaderWeekContent endDateOfPeriod={props.endDateOfPeriod} unit={unit} />
                );
            }

            const roleSummaryMonthKey = getRoleSummaryMonthKey(
                getStartOfPeriodClean(unit, TimeUnit.MONTH)
            );

            const isLoading = props.roleSummaryMonthlyLoading[roleSummaryMonthKey];

            return (
                <TableHeaderMonthContent
                    toggleAbleHeader={isInProjectArea(props.schedulingAreaObjCode)}
                    onMonthRoleAllocationOpen={(startDate, endDate) =>
                        props.onMonthRoleAllocationOpen(startDate, endDate, roleSummaryMonthKey)
                    }
                    roleSummaryMonthlyLoading={isLoading}
                    unit={unit}
                    onMoreClick={() => dispatch(toggleRoleSummary(true, roleSummaryMonthKey))}
                    roleSummaryMonthData={props.roleSummaryData[roleSummaryMonthKey]}
                    isLastOne={isLastOne}
                />
            );
        };

        return (
            <div
                role="row"
                data-testid="grid-header-periods"
                className={cx(
                    tableHeaderRightWrapper,
                    borderBottomRemovedStyle,
                    borderTopRemovedStyle
                )}
            >
                {props.periods.map((period, key) => {
                    const isLastOne = key === props.periods.length - 1;
                    return (
                        <TableHeaderPeriodCell
                            role="columnheader"
                            key={`${props.stepUnit}-${period.unit.unix()}`}
                            periodCellWidth={period.periodCellWidth}
                            periodCellMinWidth={period.periodCellMinWidth}
                        >
                            <Font type="textSmall" className={text}>
                                {cellContent(period.unit, key, isLastOne)}
                            </Font>
                        </TableHeaderPeriodCell>
                    );
                })}
            </div>
        );
    }
);
