import { cx } from 'emotion';
import * as React from 'react';
import { Moment } from 'moment';
import {
    cellWithoutBorderBottom,
    cellWrapper,
    emptyRowRightCell,
} from '../../../styles/sharedStyles';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    steps: Moment[];
}

export const EmptyRow: React.FunctionComponent<IProps> = React.memo((props) => {
    return (
        <div className={cx(cellWrapper)} data-testid="unassigned_assignments_empty_row">
            {props.steps.map((step) => {
                return (
                    <div
                        key={step.unix()}
                        className={cx(cellWithoutBorderBottom, emptyRowRightCell())}
                    />
                );
            })}
        </div>
    );
});
