import { css } from 'emotion';
import { useMemo } from 'react';
import { leftCell } from '../left/LeftCell';
import {
    cell,
    flexCell,
    greyCell,
    weekEndStyle,
    weekEndStyleGrey,
} from '../../styles/sharedStyles';
import { hex_B3D6F3, hex_E6F1FB, RGBA_COLORS } from '../../../constants/appColors';
import { transformRGBAColorKey } from '../../../util/colorUtils';
import { assignmentActionsStyles } from '../../styles/ActionsComponentStyles';
import { assignmentShadow } from '../../styles/AssignmentComponentStyles';
import { userDropOverlayBorderZIndex } from '../../../constants/zIndexes';
import { ICellStyle } from '../schedulingTables/SchedulingTableAssigned';

// user-row-style-on-allocation-visualization-selector is for the case when you hover and wants a hover style be attached to that row for visualization view
export const hoverStyles = (
    idForShadowStyle: string,
    allocationsEditingMode: boolean,
    isInDragState: boolean
): string => css`
    .${leftCell},
        .${greyCell(false)},
        .${cell()},
        .${flexCell()},
        .user-row-style-on-allocation-visualization-selector,
    div[data-testid='showMoreRightCell'],
    div[data-testid='assignment-cell-weekday'] {
        background-color: #f0f7fd !important;
    }

    .projectCell,
    .inaccessibleCell,
    .taskCell,
    .showMoreCell {
        background-color: #eef5fb;
    }

    .${cell()}:not(:first-child),
    .${greyCell(false)}:not(:first-child),
    .${weekEndStyleGrey(false)}:not(:first-child),
    div[data-testid='assignment-cell-weekday']:not(:first-child),
    div[data-testid='assignment-cell-weekend']:not(:first-child) {
        border-left: 1px solid #efefef !important;
    }

    .${weekEndStyle()}, .${weekEndStyleGrey(false)}, div[data-testid='assignment-cell-weekend'],
    div[data-testid='showMoreStriped'] {
        background-color: ${RGBA_COLORS[transformRGBAColorKey(hex_E6F1FB, 60)]} !important;
    }

    .user-not-overallocated {
        &:after {
            background-color: ${RGBA_COLORS[transformRGBAColorKey(hex_B3D6F3, 50)]};
        }
    }

    .${assignmentActionsStyles} {
        & button {
            display: flex;
        }
    }

    .duration-container-selector {
        display: none;
    }

    #${idForShadowStyle} {
        ${!allocationsEditingMode && !isInDragState ? assignmentShadow : ''}
    }
`;

export const rowHoverClass = css`
    background-color: #eef5fb !important;
`;

const getZIndex = (shouldBeOver: boolean): string =>
    shouldBeOver ? `z-index: ${userDropOverlayBorderZIndex}` : '';
const getDurationContainerDisplay = (showAssignmentActions: boolean): string =>
    showAssignmentActions ? 'none' : 'flex';

export const tableRowStyles = (
    shouldBeOver: boolean,
    hoverStyle: string,
    showAssignmentActions: boolean
): string => css`
    ${getZIndex(shouldBeOver)};
    &:hover {
        ${hoverStyle};
    }

    .duration-container-selector {
        display: ${getDurationContainerDisplay(showAssignmentActions)};
    }
`;

export const useMemoizedStyle = (style: ICellStyle): ICellStyle => {
    return useMemo(() => {
        return {
            height: style.height,
            width: style.width,
            top: style.top,
            left: style.left,
            position: style.position,
        };
    }, [style.height, style.width, style.left, style.top, style.position]);
};
