import React, { useEffect } from 'react';
import ColumnChartIcon from 'phoenix-icons/dist/ColumnChartIcon.js';
import { Button, Tooltip } from '@phoenix/all';
import { connect } from 'react-redux';
import { Localization } from '@workfront/localize-react';
import mitt from 'mitt';
import { prepareTestId } from '../../../util/utilities';
import { showAllocationsVisualizationSelector } from '../../../data-flow/settings/settingsSelector';
import { IWorkSchedulingCombinedState } from '../../../data-flow/types';
import { TAreaObjCodes } from '../../../data-flow/areaData/areaRelatedInitialDataState';
import { TMessageKey } from '../../../data-flow/data/IDataState';
import { getHeaderItemStyles } from '../../styles/headerComponentStyles';
import toggleSetting, {
    settingNames,
} from '../../../data-flow/settings/settingsActions/settingsActions';
import setIntoHighlightingMode from '../../../data-flow/data/assignedDataActions/setIntoHighlightingMode';
import { RECOMPUTE_GRID_SIZE } from '../../../constants/events';
import { useAppDispatch } from '../../../data-flow/hooks';
import { internalEventEmitterSelector } from '../../../data-flow/instances/internalEventEmitterSelector';

interface IShowVisualizationButtonStateProps {
    isAllocationsVisualizationOn: boolean;
    internalEventEmitter: mitt.Emitter;
}

interface IShowVisualizationButtonProps {
    saveSettingsAndResetContouring: () => void;
    sharableLink: boolean;
    schedulingAreaObjCode: TAreaObjCodes;
}

const getAllocationsVisualizationKey = (isAllocationsVisualizationOn: boolean): TMessageKey => {
    return isAllocationsVisualizationOn
        ? {
              messageKey: 'workloadbalancer.hide.allocations.visualization',
              fallBack: 'Hide chart',
          }
        : {
              messageKey: 'workloadbalancer.show.allocations.visualization',
              fallBack: 'Show chart',
          };
};

export const ShowAllocationsVisualizationButtonComponent = React.memo<
    IShowVisualizationButtonStateProps & IShowVisualizationButtonProps
>((props) => {
    const {
        isAllocationsVisualizationOn,
        sharableLink,
        schedulingAreaObjCode,
        saveSettingsAndResetContouring,
    } = props;
    const allocationsVisualizationKey = getAllocationsVisualizationKey(
        isAllocationsVisualizationOn
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        props.internalEventEmitter.emit(RECOMPUTE_GRID_SIZE);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAllocationsVisualizationOn]);

    const handleAllocationsVisualization = (): void => {
        dispatch(toggleSetting(settingNames.showAllocationsVisualization));
        dispatch(setIntoHighlightingMode([], [''])); // clean highlighted users list from state
        saveSettingsAndResetContouring();
    };

    const allocationVisualizationClassName = getHeaderItemStyles(isAllocationsVisualizationOn);

    return (
        <Localization<string[]>
            messageKeys={[
                'workloadbalancer.toggle.allocations.visualization',
                'workloadbalancer.hide.allocations.visualization',
                'workloadbalancer.show.allocations.visualization',
            ]}
        >
            {({ _t }) => {
                return (
                    <Tooltip
                        content={_t[allocationsVisualizationKey.messageKey](
                            allocationsVisualizationKey.fallBack
                        )}
                        delay={700}
                    >
                        <Button
                            text
                            className={allocationVisualizationClassName}
                            data-testid={prepareTestId(
                                allocationsVisualizationKey.messageKey,
                                sharableLink,
                                schedulingAreaObjCode
                            )}
                            aria-pressed={isAllocationsVisualizationOn}
                            onClick={handleAllocationsVisualization}
                            aria-label={_t['workloadbalancer.toggle.allocations.visualization'](
                                'Toggle chart view'
                            )}
                        >
                            <ColumnChartIcon focusable={false} />
                        </Button>
                    </Tooltip>
                );
            }}
        </Localization>
    );
});

const mapStateToProps = (
    state: IWorkSchedulingCombinedState
): IShowVisualizationButtonStateProps => {
    return {
        isAllocationsVisualizationOn: showAllocationsVisualizationSelector(state),
        internalEventEmitter: internalEventEmitterSelector(state),
    };
};

export const ShowAllocationsVisualizationButton = connect(mapStateToProps)(
    ShowAllocationsVisualizationButtonComponent
);
