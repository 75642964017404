import { usersTasksSortObject } from '../../../constants/dataConstatnts';
import { sortObject } from '../../../util/utilities';
import { TModernSchedulingAction } from '../../types';
import { IDataState, INodesDetailsState } from '../IDataState';

export const ActionType = 'SORT_USERS_TASKS';

interface ISortUsersTasksActionPayload {
    usersIds: string[];
    projectId: string;
}

type TSortUsersTasksAction = TModernSchedulingAction<ISortUsersTasksActionPayload>;

const sortUsersTasks = (usersIds, projectId): TSortUsersTasksAction => ({
    type: ActionType,
    payload: {
        usersIds,
        projectId,
    },
});
export default sortUsersTasks;

export function handle(state: IDataState, { payload }: TSortUsersTasksAction): IDataState {
    const users = { ...state.users };
    const tasksAndIssues = { ...state.tasksAndIssues };

    payload.usersIds.forEach((userID: string) => {
        const nodesDetails: INodesDetailsState = { ...users[userID].nodes };
        const projectNodes = [...nodesDetails[payload.projectId].nodes].sort(
            sortObject(tasksAndIssues, usersTasksSortObject)
        );

        nodesDetails[payload.projectId] = {
            ...nodesDetails[payload.projectId],
            nodes: projectNodes,
        };

        users[userID] = {
            ...users[userID],
            nodes: nodesDetails,
        };
    });

    return {
        ...state,
        tasksAndIssues,
        users,
    };
}
