import _ from 'lodash';
import { convertMinuteToHour } from './utilities';
import { INumberComparison } from '../data-flow/data/IDataState';

export const changedAssignment = (obj, taskID): string | undefined => {
    return Object.keys(obj).find((value) => obj[value] === taskID);
};

export const compareHoursAfterContouring = (
    workPerDayHours,
    tempHours,
    isWeekView: boolean
): INumberComparison => {
    return isWeekView
        ? weekComparison(workPerDayHours, tempHours)
        : dayComparison(workPerDayHours, tempHours);
};

export const USER_EDIT_MAX_DIFF = 1.8; // +(0.03 * 60).toFixed(1)

export const letUserToHaveDiff = (diff: number): number => {
    return Math.abs(diff) <= USER_EDIT_MAX_DIFF ? 0 : diff;
};

export const weekComparison = (workPerDayHours, tempHours): INumberComparison => {
    const { assignmentID, data } = tempHours;
    let sumOfTemporaryHours = 0;
    let sumOfRoundedWorkPerDayHours = 0;
    let sumOfWorkPerDayHours = 0;

    let unitCounter = 1;
    _.forEach(data, (minute, date) => {
        if (unitCounter % 7 === 0) {
            sumOfRoundedWorkPerDayHours = convertMinuteToHour(sumOfRoundedWorkPerDayHours) * 60;
        }

        sumOfTemporaryHours += minute;
        const WPDForAssignmentAndDateExists =
            workPerDayHours[assignmentID] && workPerDayHours[assignmentID][date] !== undefined;
        const WPDByAssigment = WPDForAssignmentAndDateExists
            ? workPerDayHours[assignmentID][date]
            : 0;

        sumOfRoundedWorkPerDayHours += WPDByAssigment;
        sumOfWorkPerDayHours += WPDByAssigment;

        unitCounter += 1;
    });

    const sumRoundedDiff = sumOfTemporaryHours - sumOfRoundedWorkPerDayHours;
    const sumDiff = sumOfTemporaryHours - sumOfWorkPerDayHours;

    return {
        roundedDiff: letUserToHaveDiff(sumRoundedDiff),
        roundedDiffClean: sumRoundedDiff,
        diff: letUserToHaveDiff(sumDiff),
        diffClean: sumDiff,
    };
};

export const dayComparison = (workPerDayHours, tempHours): INumberComparison => {
    const { assignmentID } = tempHours;
    let roundedDiff = 0;
    let diff = 0;

    _.forEach(tempHours.data, (minute, date) => {
        let WPDByAssigment = 0;
        let roundedWPD = 0;
        const WPDForAssignmentAndDateExists =
            workPerDayHours[assignmentID] && workPerDayHours[assignmentID][date] !== undefined;
        if (WPDForAssignmentAndDateExists) {
            WPDByAssigment = workPerDayHours[assignmentID][date];
            roundedWPD = convertMinuteToHour(WPDByAssigment) * 60;
        }

        roundedDiff = +(roundedDiff + minute - roundedWPD).toFixed(1);
        diff = +(diff + minute - WPDByAssigment).toFixed(1);
        /**
         * here used +(..).toFixed(1) for
         * fixing Floating-Point Problems in JavaScript
         * 0.1 + 0.2 is not 0.3
         */
    });

    return {
        roundedDiff: letUserToHaveDiff(roundedDiff),
        roundedDiffClean: roundedDiff,
        diff: letUserToHaveDiff(diff),
        diffClean: diff,
    };
};
