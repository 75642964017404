import { createSelector } from 'reselect';
import { objectsAssignmentDetailsSelector } from '../../dataSelectors';

export const objectAssignmentsDetailsSelector = createSelector(
    objectsAssignmentDetailsSelector,
    (objectsAssignmentsDetails) => {
        return (id: string) => {
            return objectsAssignmentsDetails[id];
        };
    }
);
