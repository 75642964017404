import { primary } from '@phoenix/all';
import styled, { css } from 'react-emotion';
import { sizes } from '../../../../../constants/schedulingTableConstants';
import arrowUp from '../../../../../images/arrow-up.svg';
import noUsers from '../../../../../images/noUsers.svg';

export const NoUsersStyled = styled('div')<any>`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100% - ${sizes.tableHeaderRowHeight}px);
`;

export const NoUsersImageStyled = styled('div')<{
    isInProjectScope: boolean;
}>`
    background: url(${(props) => getNoUserSVGImage(props.isInProjectScope)}) 0 0 no-repeat;
    width: 100%;
    height: 72px;
    background-position: center;
    margin-top: 20px;
    position: relative;
`;

export const textSharedStyle = css`
    font-size: 14px;
    text-align: center;
    height: auto;
    line-height: 25px;
`;

export const noUserText = css`
    color: ${primary.gray(400)};
    ${textSharedStyle}
`;

export const iconBorder = css`
    color: ${primary.gray(400)};
    border: 1px solid;
    border-radius: 4px;
    margin: 2px 3px 0 4px;
    padding: 3px;
`;
export const showAllUsersText = css`
    ${noUserText};
    margin-left: 5px;
    font-weight: bold;
`;
export const secondaryText = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
export const noUserMainText = css`
    color: ${primary.gray(500)};
    font-weight: 600;
    margin-top: 16px;
    ${textSharedStyle}
`;

/**
 *
 * @param isInProjectScope
 */
export const getNoUserSVGImage = (isInProjectScope: boolean): string => {
    return isInProjectScope ? arrowUp : noUsers;
};
