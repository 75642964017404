import { IDataState } from '../IDataState';

export const ActionType = 'REMOVE_WORK_PER_DAY_HOURS_ON_DRAG_HOVER';

const removeWorkPerDayHoursOnDragHover = () => ({
    type: ActionType,
    payload: {},
});
export default removeWorkPerDayHoursOnDragHover;

export function handle(state: IDataState): IDataState {
    const isEmpty = Object.keys(state.workPerDaysOnDragHover).length === 0;

    if (isEmpty) {
        return state;
    }

    return {
        ...state,
        workPerDaysOnDragHover: {},
    };
}
