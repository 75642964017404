import React from 'react';
import { TitleStyle } from '../../styles/sharedStyles';
import { TOrUndefined } from '../../../data-flow/data/IDataState';

interface IAssignmentTitle {
    assignmentColorText: TOrUndefined<string>;
    shouldMarkAsCompleted: boolean;
    title: string;
    assignmentWidth: number;
    testID?: string;
    textWeight?: number;
}
export const assignmentTitleTestID = 'assignmentTitle';
export const AssignmentTitle: React.FunctionComponent<IAssignmentTitle> = React.memo((props) => {
    const {
        assignmentColorText,
        shouldMarkAsCompleted,
        title,
        assignmentWidth,
        testID,
        textWeight,
    } = props;

    return (
        <TitleStyle
            assignmentWidth={assignmentWidth}
            color={assignmentColorText}
            isCompleted={shouldMarkAsCompleted}
            data-testid={testID || assignmentTitleTestID}
            textWeight={textWeight}
        >
            {title}
        </TitleStyle>
    );
});
