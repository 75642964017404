import { TModernSchedulingAction } from '../../../types';
import { IDataState, RoleSummaryKeys } from '../../IDataState';

export const ActionType = 'SET_ROLE_SUMMARY_LOADING';

interface IRoleSummaryLoadingActionPayload {
    status: boolean;
    key: RoleSummaryKeys | string;
}

type TRoleSummaryLoadingAction = TModernSchedulingAction<IRoleSummaryLoadingActionPayload>;

const setRoleSummaryLoading = (
    status,
    key: string = RoleSummaryKeys.TOTAL
): TRoleSummaryLoadingAction => ({
    type: ActionType,
    payload: {
        status,
        key,
    },
});

export function handle(state: IDataState, { payload }: TRoleSummaryLoadingAction): IDataState {
    const roleSummaryLoading = { ...state.roleSummaryLoading };
    roleSummaryLoading[payload.key] = payload.status;

    return {
        ...state,
        roleSummaryLoading,
    };
}

export default setRoleSummaryLoading;
