import { primary } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import ProjectSmallIcon from 'phoenix-icons/dist/ProjectSmallIcon.js';
import { css, cx } from 'emotion';
import * as React from 'react';
import { connect } from 'react-redux';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../../data-flow/types';
import { convertMinuteToHour, formatNumber } from '../../../../util/utilities';
import { localizationClient } from '../../../../constants/LocalizationClientFactory';
import { projectIcon } from '../../../styles/sharedStyles';
import { ProjectName } from '../ProjectName/ProjectName';
import { inaccessibleItemsWorkRequiredPerProjectSelector } from '../../../../data-flow/data/selectors/rereselect/workRequiredSelectors/workRequiredSelectors';

interface IInaccessibleProjectNodeComponents extends React.HTMLAttributes<HTMLDivElement> {
    idExpression: string;
    workRequired: number;
}

export type IInaccessibleProjectNodeComponentProps = IWorkSchedulingDispatchProp;

function InaccessibleProjectNodeComponent(
    props: IInaccessibleProjectNodeComponents & IInaccessibleProjectNodeComponentProps
) {
    return (
        <div className={cx('inaccessibleCell', projectNodeStyles)} data-testid={props.idExpression}>
            <div className={projectDetails}>
                <div className={projectIcon}>
                    <ProjectSmallIcon color={primary.gray(400)} />
                </div>
                <ProjectName
                    isAccessible={false}
                    showTooltip={false}
                    name={localizationClient.getTextSync(
                        'resourcescheduling.inaccessible.project',
                        'Inaccessible projects'
                    )}
                />
            </div>

            <div className={workRequired}>
                {formatNumber(convertMinuteToHour(props.workRequired))}
                <Localization<string> messageKey="hour.abbr.short" fallback="h" />
            </div>
        </div>
    );
}

function mapStateToProps(state: IWorkSchedulingCombinedState, ownProps) {
    return {
        workRequired: state.Data.users[ownProps.userID].inaccessibleProjectIDs.reduce(
            (accumulator, projectID) =>
                accumulator +
                inaccessibleItemsWorkRequiredPerProjectSelector(state, {
                    userID: ownProps.userID,
                    projectID,
                }),
            0
        ),
    };
}

export const InaccessibleProjectNode = connect(mapStateToProps)(InaccessibleProjectNodeComponent);

const projectDetails = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 44px;
	& :last-child > span > span {
		'font-weight: 600;'
	}
`;

const projectNodeStyles = css`
    background-color: ${primary.gray(50)};
    display: flex;
    height: 100%;
    border-bottom: 1px solid ${primary.gray(200)};
    justify-content: space-between;
    position: relative;
`;

const workRequired = css`
    font-size: 12px;
    color: ${primary.gray(300)};
    line-height: 40px;
    margin-right: 8px;
`;
