import * as React from 'react';
import { connect } from 'react-redux';
import { Sections } from '../../../../constants/schedulingTableConstants';
import { IProjectColor, THandleProjectArrowClick } from '../../../../data-flow/data/IDataState';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../../data-flow/types';
import { ProjectNodeHTML } from '../ProjectNodeHTML/ProjectNodeHTML';
import { isLastTaskRowSelector } from '../../../../data-flow/data/selectors/reselect/isLastTaskRowSelector/isLastTaskRowSelector';
import { projectWorkRequiredSelector } from '../../../../data-flow/data/selectors/rereselect/workRequiredSelectors/workRequiredSelectors';
import { getSchedulingAreaData } from '../../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import {
    projectColorDeciderSelector,
    projectStatusLabelSelector,
} from '../../../../data-flow/data/selectors/projectStatusSelectors/projectStatusSelectors';

interface IProjectNodeComponentsForPeopleWorkloadSection
    extends React.HTMLAttributes<HTMLDivElement> {
    idExpression: string;
    name: string;
    isAccessible: boolean;
    expanded: boolean;
    projectID: string;
    userID: string;
    workRequired: number;
    isLastRow: boolean;
    projectColoredData?: IProjectColor;
    projectStatusLabel?: string;
    schedulingAreaObjCode: string | undefined;
    handleProjectArrowClick: THandleProjectArrowClick;
}

export type IProjectNodeComponentProps = IWorkSchedulingDispatchProp;

export const ProjectNodeComponentForPeopleWorkloadSection: React.FunctionComponent<
    IProjectNodeComponentsForPeopleWorkloadSection & IProjectNodeComponentProps
> = (props) => {
    const { handleProjectArrowClick, userID, expanded, projectID } = props;

    const onHandleProjectArrowClick = React.useCallback(() => {
        handleProjectArrowClick(userID, expanded, projectID);
    }, [handleProjectArrowClick, userID, expanded, projectID]);

    return (
        <ProjectNodeHTML
            name={props.name}
            isAccessible={props.isAccessible}
            expanded={props.expanded}
            projectID={props.projectID}
            workRequired={props.workRequired}
            handleArrowClick={onHandleProjectArrowClick}
            sectionType={Sections.PEOPLE_WORKLOAD}
            isLastRow={props.isLastRow}
            projectColoredData={props.projectColoredData}
            projectStatusLabel={props.projectStatusLabel}
            schedulingAreaObjCode={props.schedulingAreaObjCode}
        />
    );
};

function mapStateToProps(state: IWorkSchedulingCombinedState, ownProps) {
    const isLastTaskRow = isLastTaskRowSelector(state)(ownProps.idExpression);
    const projectData = state.Data.projects[ownProps.projectID] || {};
    const { expandedProjectIDs } = state.Data.users[ownProps.userID];
    return {
        name: projectData.name,
        isAccessible: !!projectData.isAccessible,
        expanded: expandedProjectIDs.has(ownProps.projectID),
        workRequired: projectWorkRequiredSelector(state, {
            userID: ownProps.userID,
            projectID: ownProps.projectID,
        }),
        isLastRow: isLastTaskRow,
        projectColoredData: projectColorDeciderSelector(
            state,
            projectData.ID,
            projectData.groupID,
            projectData.status
        ),
        projectStatusLabel: projectStatusLabelSelector(
            state,
            projectData.groupID,
            projectData.status
        ),
        schedulingAreaObjCode: getSchedulingAreaData(state).schedulingAreaObjCode,
    };
}

export const ProjectNode = connect(mapStateToProps)(ProjectNodeComponentForPeopleWorkloadSection);
