import _, { findLastIndex } from 'lodash';
import moment, { Moment } from 'moment';
import { OpTask, Project, Team, TObjCode } from 'workfront-objcodes';
import { TLocalizationSingleProps } from '@workfront/localize-react';
import { apiDateToDate } from '@workfront/fns';
import { getIsUnifiedShellEnabled } from '@wf-mfe/unified-shell-bootstrapper';
import page from '@adobe/exc-app/page';
import { TASK_BG_COLOR, TASK_BG_IMAGE } from '../constants/colors';
import {
    DATE_FORMAT,
    osTypes,
    PERIOD_MODE_FOR_NEXT_PREV,
    usersTasksSortObject,
    WIDGET_BREAKPOINT,
    BIG_WIDGET_SIZE,
    SMALL_WIDGET_SIZE,
    DATE_FORMAT_MONTH,
} from '../constants/dataConstatnts';
import { TimeUnit } from '../constants/periodEnums';
import { Sections, sizes, systemScrollbarSize } from '../constants/schedulingTableConstants';
import { IAreaState } from '../data-flow/areaData/areaRelatedInitialDataState';
import {
    IFullTimeOffSteps,
    IRequestedAssignment,
    IRequestedUserData,
    IWorkDays,
    TDate,
    TMessageKey,
} from '../data-flow/data/IDataState';
import { LOCALE_CODE, localizationClient } from '../constants/LocalizationClientFactory';
import { getWorkSchedulingTableHeightsFromLocalStorage } from './localStorage';
import { apiRequestMessageKeysPerCode } from '../messageKeys';
import { getPeriodModesValues, transformStepDataForMonthView } from './periodUtils/periodUtils';
import { tableHeaderRow, tableHeaderTopRow } from '../constants/zIndexes';
import { IUsersState } from './dataStructures/IAssignmentIds';
import { removeAssignedObjectType } from '../data-flow/data/assignedDataActions/removeAssignedObject';
import { TModernSchedulingAction, UnitOfTime } from '../data-flow/types';
import { isInDateRange } from './durationStylesCalculation';

export {
    transformRGBAColorKey,
    getRGBAColors,
    convertHexToRGBA,
    isColorModeDefault,
    isColorModeProject,
    isColorModeProjectStatus,
    prepareColorsByProjectGroupData,
    prepareColorsByProjectData,
} from './colorUtils';

export const getDateMessageKey = (messageKeysList: string[], index: number | string): string => {
    return messageKeysList[index];
};

export const getUnitOfTime = (unit: UnitOfTime): UnitOfTime => {
    return unit === TimeUnit.MONTH ? TimeUnit.MONTH : TimeUnit.WEEK;
};
export const selectUserIDFromExpression = (idExpression): string => idExpression.split('_')[0];

export const findLastIndexUtil = (arrayExpressions: string[], idExpression: string): number => {
    return findLastIndex(arrayExpressions, (expression) => expression.indexOf(idExpression) !== -1);
};

export const getStartOfDay = (date: Moment): string => {
    return getStartOfPeriod(date, TimeUnit.DAY);
};

export const getEndOfDay = (date: Moment): string => {
    return getEndOfPeriod(date, TimeUnit.DAY);
};

export const getStartOfPeriod = (date: Moment, period: UnitOfTime, format?: string): string => {
    const clonedDate = getStartOfPeriodClean(date, period);
    return format ? clonedDate.format(format) : clonedDate.format();
};

export const getEndOfPeriod = (date: Moment, period: UnitOfTime, format?: string): string => {
    const clonedDate = getEndOfPeriodClean(date, period);
    return format ? clonedDate.format(format) : clonedDate.format();
};

export const getStartOfPeriodClean = (date: Moment, period: UnitOfTime): Moment => {
    return date.clone().startOf(period);
};

export const getEndOfPeriodClean = (date: Moment, period: UnitOfTime): Moment => {
    return date.clone().endOf(period);
};

export const getRoleSummaryMonthKey = (date: Moment): string => {
    return date.clone().format(DATE_FORMAT_MONTH);
};

export const getESPFormatQueryDate = (date: Moment): string => {
    return date.clone().format('YYYY-MM');
};

export const sortByTitleCallback = (a, b): number => (a.roleTitle >= b.roleTitle ? 1 : -1);

export const addSubtractDate = (
    momentObject: Moment,
    unit: UnitOfTime,
    isSubtraction = false
): Moment => {
    const newMomentObject = momentObject.clone();
    if (isSubtraction) {
        newMomentObject.subtract(PERIOD_MODE_FOR_NEXT_PREV, getUnitOfTime(unit));
    } else {
        newMomentObject.add(PERIOD_MODE_FOR_NEXT_PREV, getUnitOfTime(unit));
    }

    return newMomentObject;
};

export const roundNumber = (number: number, decimal: number): number => {
    return Math.round(number * decimal) / decimal;
};

export const formatNumber = (number: number = 0): string => {
    return new Intl.NumberFormat(LOCALE_CODE).format(number);
};

export const convertMinuteToHour = (number: number): number => {
    return roundNumber(number / 60, 100); // 100 is for rounding up to 2 digits after comma
};

export const convertHourToMinute = (number: number): number => {
    return number * 60;
};

export const setPrefixOnAllocations = (isOverAllocated, number, showRemaining = true): string => {
    if (!showRemaining || number === 0) {
        return '';
    }
    return showRemaining && isOverAllocated ? '+' : '-';
};

export const getHourToShow = (
    showRemaining: boolean,
    totalAvailableHours: number,
    allocatedHours = 0
): string => {
    const numberToShow = roundNumber(
        showRemaining ? totalAvailableHours - allocatedHours : allocatedHours,
        100
    );
    const prefix = setPrefixOnAllocations(numberToShow < 0, numberToShow, showRemaining);
    return prefix + formatNumber(Math.abs(numberToShow));
};

export const getPercentToShow = (
    totalAvailableHours = 0,
    allocatedHours = 0,
    scheduleHours = 0,
    showRemaining = false,
    notWorkingDay = false
): string => {
    let calculatedPercent = Math.round(
        ((allocatedHours || 0) / (totalAvailableHours || scheduleHours || 1)) * 100
    );
    let prefix = showRemaining && notWorkingDay ? '+' : '';
    if (showRemaining && !notWorkingDay) {
        prefix = setPrefixOnAllocations(
            calculatedPercent > 100,
            totalAvailableHours - allocatedHours
        );
        calculatedPercent = 100 - calculatedPercent;
    }
    return `${prefix + Math.abs(calculatedPercent)}%`;
};

export const convertHourToPercent = (
    totalAvailableHours = 0,
    allocatedHours = 0,
    scheduleHours = 0
): number => {
    return ((allocatedHours || 0) / (totalAvailableHours || scheduleHours || 1)) * 100;
};

export const convertPercentToHour = (
    totalAvailableHours = 0,
    allocatedPercent = 0,
    scheduleHours = 0
): number => {
    return ((allocatedPercent || 0) * (totalAvailableHours || scheduleHours || 1)) / 100;
};

export const getTotalPercentForTooltip = (
    halfTimeOffCondition,
    isNotWorkingDayOrTimeOff,
    scheduleHour,
    availableHourForUser
): string => {
    if (halfTimeOffCondition) {
        return `${Math.round((availableHourForUser / scheduleHour) * 100)}%`;
    }
    if (isNotWorkingDayOrTimeOff) {
        return '0%';
    }
    return '100%';
};

export const getRowCountRelatedToHeight = (height: number): number => {
    return Math.round(height / sizes.tableRowHeight);
};

export const sortByStartDate = (
    date1: { startDate: TDate | string },
    date2: { startDate: TDate | string }
): number => {
    return moment(date1.startDate) > moment(date2.startDate) ? 1 : -1;
};

export const sortByEndDate = (date1: { endDate: string }, date2: { endDate: string }): number => {
    return moment(date1.endDate) < moment(date2.endDate) ? 1 : -1;
};

export const parseTimeToMinute = (timeString: string): number => {
    const time = timeString.split(':');

    return parseInt(time[0], 10) * 60 + parseInt(time[1], 10);
};

export const convertToHourString = (TimeByMinutes: number): string => {
    const minutes = TimeByMinutes % 60;
    const hours = parseInt(`${TimeByMinutes / 60}`, 10);
    const convertedHours = hours < 10 ? `0${hours}` : hours;
    const convertedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${convertedHours}:${convertedMinutes}`;
};

export const enumerateDaysBetweenDates = (startDate: Moment, endDate: Moment): string[] => {
    // Returns an array of dates between the two dates
    const start = startDate.clone().startOf('day');
    const end = endDate.clone().endOf('day');
    const dates: string[] = [];

    while (start.isBefore(end) || start.isSame(end)) {
        dates.push(start.format(DATE_FORMAT));
        start.add(1, 'days');
    }

    return dates;
};

export const isWorkingDayBySchedule = (scheduleWorkingDays: IWorkDays, step: Moment): boolean => {
    const weekDayName = getDayOfWeek(step);
    return scheduleWorkingDays && !!scheduleWorkingDays[weekDayName].length;
};

export const isThereAnyWorkingDayInWeek = (scheduleWorkingDays: IWorkDays): any =>
    _.find(scheduleWorkingDays, (day) => !!day.length);

export const getDayOfWeek = (date: Moment): string => {
    // TODO here can be error related to locale
    return date.clone().locale('en').format('dddd');
};

export const intersectWithScheduleWorkingHours = (
    scheduleHours: string[],
    hours: string[]
): string[] | null => {
    const intersectedHours: number[] = [];

    for (let i = 0; i <= scheduleHours.length - 2; i += 2) {
        const scheduleStartHour = parseTimeToMinute(scheduleHours[i]);
        const scheduleEndHour = parseTimeToMinute(scheduleHours[i + 1]);

        const hourStart = parseTimeToMinute(hours[0]);
        const hourEnd = parseTimeToMinute(hours[1]);

        if (hourEnd <= scheduleStartHour || hourStart >= scheduleEndHour) {
            continue;
        }

        if (hourStart <= scheduleStartHour && hourEnd > scheduleStartHour) {
            intersectedHours.push(scheduleStartHour);
        } else {
            intersectedHours.push(hourStart);
        }

        if (hourEnd <= scheduleEndHour && hourEnd > scheduleStartHour) {
            intersectedHours.push(hourEnd);
        } else {
            intersectedHours.push(scheduleEndHour);
        }
    }

    const formattedHours = intersectedHours.map((hoursByMinute) =>
        convertToHourString(hoursByMinute)
    );

    if (scheduleHours.length && JSON.stringify(formattedHours) === JSON.stringify(scheduleHours)) {
        return [];
    }

    return formattedHours.length ? formattedHours : null;
};

export const recalculateDataForActiveView = (
    hours: number[],
    activePeriodMode: UnitOfTime,
    startDate: Moment
): number[] => {
    const { isWeekMode, isMonthMode } = getPeriodModesValues(activePeriodMode);

    if (isWeekMode) {
        return transFormStepDataForWeekView(hours);
    }
    if (isMonthMode) {
        return transformStepDataForMonthView(hours, startDate);
    }

    return hours;
};

export function transFormStepDataForWeekView(transformableArray: number[] | Uint16Array): number[] {
    // todo must be figured out doesn't it brake performance that every time we calculate this
    return _.reduce(
        transformableArray,
        function (accumulator: number[], iteratee: number, i: number) {
            const newIndex = Math.floor(i / 7);
            accumulator[newIndex] = (accumulator[newIndex] ? accumulator[newIndex] : 0) + iteratee;
            return accumulator;
        },
        []
    );
}

export const calculateArrowWidth = (before: boolean, after: boolean): number => {
    let sum = 0;

    if (before) {
        sum += sizes.assignmentArrowSize;
    }

    if (after) {
        sum += sizes.assignmentArrowSize;
    }

    return sum;
};

interface IDatesType {
    startDate: TDate;
    endDate: TDate;
    projectedStartDate?: TDate;
    projectedEndDate?: TDate;
}

export const combineDates = (datesArray: IDatesType[], stepUnit): IDatesType[] => {
    const combinedDates: IDatesType[] = [];

    datesArray.sort(sortByStartDate);

    datesArray.forEach((item) => {
        const combinedLastItem = combinedDates[combinedDates.length - 1];
        if (combinedLastItem) {
            const lastItemEndDateMoment = moment(combinedLastItem.endDate).endOf(stepUnit);
            const itemStartMoment = moment(item.startDate).startOf(stepUnit);
            const itemEndMoment = moment(item.endDate).endOf(stepUnit);

            if (itemStartMoment.diff(lastItemEndDateMoment, stepUnit) < 1) {
                combinedLastItem.endDate =
                    lastItemEndDateMoment >= itemEndMoment
                        ? combinedLastItem.endDate
                        : item.endDate;
            } else {
                combinedDates.push(item);
            }
        } else {
            combinedDates.push(item);
        }
    });

    return combinedDates;
};

export const checkIsTableScrollable = (
    sectionType,
    { peopleWorkloadEmptyRowHeight, unassignedWorkEmptyRowHeight }
): boolean => {
    return (
        (sectionType === Sections.PEOPLE_WORKLOAD && !peopleWorkloadEmptyRowHeight) ||
        (sectionType === Sections.UNASSIGNED_WORK && unassignedWorkEmptyRowHeight <= 0)
    );
};

export const checkIsSecondTableScrollable = (
    dataIDs,
    assignmentDialogOpenedForID,
    { unassignedWorkEmptyRowHeight, peopleWorkloadEmptyRowHeight }
): boolean => {
    return (
        (dataIDs.indexOf(assignmentDialogOpenedForID) === -1 &&
            unassignedWorkEmptyRowHeight === 0) ||
        (dataIDs.indexOf(assignmentDialogOpenedForID) === -1 && !peopleWorkloadEmptyRowHeight)
    );
};

export const getGridWidth = (
    width,
    isTableScrollable,
    isAssignmentDialogOpened,
    isSecondTableScrollable
): number => {
    if (systemScrollbarSize > 0) {
        if (!isTableScrollable || (isAssignmentDialogOpened && !isSecondTableScrollable)) {
            return width;
        }
        return width + systemScrollbarSize;
    }
    return width;
};

export const convertArrayToMap = (array): any => {
    const objFromArray = {};

    array.forEach((value, key) => {
        objFromArray[value] = key;
    });

    return objFromArray;
};

export const userHasAssignments = (nodesDetails): boolean => {
    return (
        _.size(nodesDetails) > 0 &&
        !_.every(
            nodesDetails,
            (projectItem) =>
                projectItem.nodes.length === 0 && projectItem.inaccessibleNodesDates.length === 0
        )
    );
};

export const sortObject: any = (
    object,
    sortBy: Array<{ prop: string; type: 'date' | 'string' }>
) => {
    return (a, b) =>
        sortBy
            .map((orderItem) => {
                const taskObjectA = { ...object[a] };
                const taskObjectB = { ...object[b] };

                let dir = 1;
                if (orderItem.prop[0] === '-') {
                    dir = -1;
                    orderItem.prop = orderItem.prop.substring(1);
                }

                if (orderItem.type === 'date') {
                    taskObjectA[orderItem.prop] = moment(taskObjectA[orderItem.prop]).startOf(
                        'day'
                    );
                    taskObjectB[orderItem.prop] = moment(taskObjectB[orderItem.prop]).startOf(
                        'day'
                    );
                }

                if (taskObjectA[orderItem.prop] > taskObjectB[orderItem.prop]) {
                    return dir;
                }
                return taskObjectA[orderItem.prop] < taskObjectB[orderItem.prop] ? -dir : 0;
            })
            .reduce((p, n) => p || n, 0);
};

function extractTaskObject(value: any, object: any): any {
    let result: any;
    if (value.task) {
        result = { ...value.task };
    } else {
        result = value.opTask ? { ...value.opTask } : { ...object[value] };
    }
    return result;
}

function normalizeTaskObjectPlannedOrProjectedDates(taskObject, orderItem, startDate): void {
    if (!(taskObject[orderItem.prop] instanceof Date)) {
        taskObject[orderItem.prop] = apiDateToDate(taskObject[orderItem.prop]);
    }
    if (!(taskObject[orderItem.secondProp] instanceof Date)) {
        taskObject[orderItem.secondProp] = apiDateToDate(taskObject[orderItem.secondProp]);
    }
    if (!(taskObject.plannedCompletionDate instanceof Date)) {
        taskObject.plannedCompletionDate = apiDateToDate(taskObject.plannedCompletionDate);
    }

    taskObject[orderItem.prop] = moment(taskObject.plannedCompletionDate).isBefore(startDate)
        ? moment(taskObject[orderItem.secondProp]).startOf('day')
        : moment(taskObject[orderItem.prop]).startOf('day');
}

// there is also sortObject more generic function, before using this check that one.
export const sortObjectByPlannedAndProjectedDates: any = (
    object,
    sortBy: Array<{ prop: string; type: 'date' | 'string'; secondProp: string }>,
    startDate
) => {
    return (a, b) =>
        sortBy
            .map((orderItem) => {
                const taskObjectA: any = extractTaskObject(a, object);
                const taskObjectB: any = extractTaskObject(b, object);
                let dir = 1;
                if (orderItem.prop[0] === '-') {
                    dir = -1;
                    orderItem.prop = orderItem.prop.substring(1);
                }

                if (orderItem.type === 'date') {
                    normalizeTaskObjectPlannedOrProjectedDates(taskObjectA, orderItem, startDate);
                    normalizeTaskObjectPlannedOrProjectedDates(taskObjectB, orderItem, startDate);
                }

                if (taskObjectA[orderItem.prop] > taskObjectB[orderItem.prop]) {
                    return dir;
                }
                return taskObjectA[orderItem.prop] < taskObjectB[orderItem.prop] ? -dir : 0;
            })
            .reduce((p, n) => p || n, 0);
};

export const sortObjectsArray: any = (sortBy: Array<{ prop: string; type: 'date' | 'string' }>) => {
    return (a, b) => {
        return sortBy
            .map((orderItem) => {
                const objectA = a.task || a.opTask;
                const objectB = b.task || b.opTask;

                let dir = 1;
                if (orderItem.prop[0] === '-') {
                    dir = -1;
                    orderItem.prop = orderItem.prop.substring(1);
                }

                let itemA = objectA[orderItem.prop];
                let itemB = objectB[orderItem.prop];

                if (orderItem.type === 'date') {
                    itemA = moment(objectA[orderItem.prop]).startOf('day');
                    itemB = moment(objectB[orderItem.prop]).startOf('day');
                }

                if (itemA > itemB) {
                    return dir;
                }
                return itemA < itemB ? -dir : 0;
            })
            .reduce((p, n) => p || n, 0);
    };
};

export const findLastIndexByExpression = (strArray: string[], expression: string): number => {
    let lastIndex = 0;

    strArray.forEach((item, index) => {
        if (item.indexOf(expression) !== -1 && item.indexOf(`${expression}_showMore`) === -1) {
            lastIndex = index;
        }
    });

    return lastIndex;
};

export const isSinglePeriod = (
    startDate: Moment,
    endDate: Moment,
    stepUnit: UnitOfTime
): boolean => {
    return startDate.diff(endDate, stepUnit) === 0;
};

export const getInitialHeightsOfTables = (top): any => {
    const availableHeight = window.innerHeight - top;
    const workTablesHeights = getWorkSchedulingTableHeightsFromLocalStorage();
    const unassignedHeight = workTablesHeights ? workTablesHeights.unassignedHeight : 0;
    const peopleWorkLoadHeight = availableHeight - unassignedHeight;

    return { unassignedHeight, peopleWorkLoadHeight, availableHeight };
};

export const getAvailableHeightForTables = (top, innerHeight = window.innerHeight): number => {
    return innerHeight - top;
};

export const getWorkingAndNonWorkingDates = (
    steps,
    timeOffSteps,
    scheduleWorkingDays,
    isInDayMode
): {
    workingDates: string[];
    noneWorkingDates: string[];
} => {
    const workingDates: string[] = [];
    const noneWorkingDates: string[] = [];

    steps.forEach((step) => {
        const isWorkingDay =
            !timeOffSteps[step] && isWorkingDayBySchedule(scheduleWorkingDays, moment(step));
        if (!isInDayMode && !isWorkingDay) {
            noneWorkingDates.push(step);
        } else {
            workingDates.push(step);
        }
    });

    return { workingDates, noneWorkingDates };
};

interface IWorkingDatesType {
    workingDates: string[];
    noneWorkingDates: string[];
}

export const getHoursBySteps = (
    steps: string[],
    timeOffSteps: IFullTimeOffSteps,
    scheduleWorkingDays: IWorkDays,
    hour: number,
    stepUnit: UnitOfTime
): Record<string, number> => {
    const hoursByDates = {};
    const { isDayMode } = getPeriodModesValues(stepUnit);

    let { workingDates, noneWorkingDates }: IWorkingDatesType = getWorkingAndNonWorkingDates(
        steps,
        timeOffSteps,
        scheduleWorkingDays,
        isDayMode
    );

    // When contouring non-working week
    if (!isDayMode && !workingDates.length) {
        ({ workingDates, noneWorkingDates } = getWorkingAndNonWorkingDates(
            steps,
            timeOffSteps,
            scheduleWorkingDays,
            false
        ));
    }

    const hourForDay = workingDates.length ? hour / workingDates.length : 0;

    workingDates.forEach((date) => {
        hoursByDates[date] = hourForDay;
    });

    noneWorkingDates.forEach((date) => {
        hoursByDates[date] = 0;
    });

    return hoursByDates;
};

export const getWorkingDays = (
    scheduleWorkingDays: IWorkDays,
    fullTimeOffsStepsByDay: IFullTimeOffSteps,
    startDate: Moment,
    endDate: Moment
): { [index: string]: string } => {
    const startStep = startDate.clone();
    const workingDays = {};

    while (startStep <= endDate) {
        const stepStr = startStep.format(DATE_FORMAT);

        if (
            isWorkingDayBySchedule(scheduleWorkingDays, startStep) &&
            !fullTimeOffsStepsByDay[stepStr]
        ) {
            workingDays[stepStr] = stepStr;
        }

        startStep.add(1, 'day');
    }

    return workingDays;
};

export const combineInaccessibleAssignments = (
    assignments1: null | any[],
    assignments2: null | any[]
): any[] => {
    const combinedAssignments = assignments1 ? [...assignments1] : [];

    if (assignments2) {
        assignments2.forEach((item, index) => {
            combinedAssignments[index].result.push(...item.result);
        });
    }

    return combinedAssignments;
};

export const combineAccessibleAssignments = (assignments1, assignments2): any[] => {
    const objectsAssignments = assignments1 ? [...assignments1] : [];

    if (assignments2) {
        assignments2.forEach((item, index) => {
            objectsAssignments[index].push(...item);
            objectsAssignments[index].sort(sortObjectsArray(usersTasksSortObject));
        });
    }

    return objectsAssignments;
};

export const encodeObj = (obj): string => {
    return btoa(JSON.stringify(obj));
};

const leftBrackets = '<{';
const rightBrackets = '}>';
export const decodeStr = (str: string): boolean | any => {
    try {
        const decodedUri = decodeURI(str);
        /**
         * split is used to get Base64String ['<{', 'Base64String', '}>'] from decodedUri
         * */
        const base64EncodedString = decodedUri.split(leftBrackets)[1].split(rightBrackets)[0];
        if (base64EncodedString) {
            const base64 = decodeURIComponent(base64EncodedString);
            return JSON.parse(atob(base64));
        }
        // In case customers have sharable links without new tags `<{}>`
        return JSON.parse(atob(str));
    } catch (e) {
        return false;
    }
};

/**
 *
 * @param label
 */
export const getSafeLabel = (label: string): string => {
    const safeLabel = String(label);

    // we clean up here + sign as in core package we have +{any number}, which should return +{any number}
    // it is not the best solution, but others can have more risks
    return safeLabel.replace(/\+/g, '');
};

export const getOS = (): string | void => {
    const { platform, userAgent } = window.navigator;
    const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (macPlatforms.indexOf(platform) !== -1) {
        return osTypes.mac;
    }

    if (iosPlatforms.indexOf(platform) !== -1) {
        return osTypes.iOS;
    }

    if (windowsPlatforms.indexOf(platform) !== -1) {
        return osTypes.windows;
    }

    if (/Android/.test(userAgent)) {
        return osTypes.android;
    }

    if (/Linux/.test(platform)) {
        return osTypes.linux;
    }
};

/**
 *
 * @param initialTestId
 * @param fromSharableLink
 * @param postFix
 * @param schedulingArea
 */
export const prepareTestId = (
    initialTestId,
    fromSharableLink,
    schedulingArea,
    postFix = '_url'
): string => {
    // another test id generation logic in ProjectNodeHTML.getDataTestIdForArrowButton and utilities.prepareSettingsIconTestId
    // the logic should become unique with separate tech story

    const dataTestIdByArea = initialTestId + schedulingArea;
    return fromSharableLink ? `${dataTestIdByArea}${postFix}` : dataTestIdByArea;
};

export const prepareSettingsIconTestId = (
    sharableLink: boolean,
    schedulingAreaObjCode = ''
): string => {
    // another test id generation logic in ProjectNodeHTML.getDataTestIdForArrowButton and utilities.prepareTestId
    // the logic should become unique with separate tech story

    return `${sharableLink ? 'setting-icon-url' : 'setting-icon'}${schedulingAreaObjCode}`;
};

export const getOffset = (offset, defaultOffset): number => {
    return offset > 0 ? offset : defaultOffset;
};

export const calculateMultipleScheduleHours = (hoursArray, minutes): number => {
    for (let i = 0; i < hoursArray.length; i += 2) {
        minutes +=
            convertStringToMinutes(hoursArray[i + 1]) - convertStringToMinutes(hoursArray[i]);
    }
    return minutes;
};

export const convertStringToMinutes = (stringDate): number => {
    const date = new Date();
    const [hours, minutes] = stringDate.split(':');
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.getTime() / 1000 / 60;
};

export const getAverageOfWeekInMinutes = (hoursArray): number => {
    let averageOfMinutes = 0;
    hoursArray.forEach((hours) => {
        if (hours.length) {
            averageOfMinutes = calculateMultipleScheduleHours(hours, averageOfMinutes);
        }
    });
    return averageOfMinutes;
};

/**
 *
 * @param objCode
 */
export const isIssue = (objCode: TObjCode): boolean => {
    return objCode === OpTask;
};

export const isInGlobalArea = (areaObjCode?: string): boolean => {
    return !areaObjCode;
};

export const isInProjectArea = (areaObjCode?: string): boolean => {
    return areaObjCode === Project;
};

export const isInTeamArea = (areaObjCode?: string): boolean => {
    return areaObjCode === Team;
};

export interface IAdditionalConfigForShareURL {
    [key: string]: any;
}

export const prepareShareUrl = (
    settings,
    schedulingAreaData: IAreaState,
    additionalConfig: IAdditionalConfigForShareURL
): string => {
    const encodedData = encodeObj({ ...settings, schedulingAreaData, additionalConfig });
    let url: string;
    const wbMFERoute = `/workload-balancer-shareable-link?p=${leftBrackets}${encodedData}${rightBrackets}`;
    if (getIsUnifiedShellEnabled()) {
        url = page.generateShellUrl({
            path: wbMFERoute,
        });
    } else {
        url = `${window.location.protocol}//${window.location.host}${wbMFERoute}`;
    }
    return encodeURI(url);
};

/**
 *
 * @param darkerColor
 * @param lighterColor
 */
export const getUnassignedBackgroundColor = (
    darkerColor = TASK_BG_COLOR,
    lighterColor = TASK_BG_IMAGE
): string => {
    const backgroundSize = `background-size: 15px 15px;`;
    return `background-image: ${setBackgroundImage(darkerColor, lighterColor)} ${backgroundSize}`;
};

/**
 *
 * @param bgColor
 */
export const getAssignedBackgroundColor = (bgColor = ''): string => {
    return bgColor ? `background-color: ${bgColor}` : '';
};

/**
 *
 * @param backgroundColor
 * @param gradientColor
 */
export const setBackgroundImage = (backgroundColor: string, gradientColor: string): string => {
    return `linear-gradient(45deg,
			${backgroundColor} 25%,
			${gradientColor} 25%,
			${gradientColor} 50%,
			${backgroundColor} 50%,
			${backgroundColor} 75%,
			${gradientColor} 75%,
			${gradientColor} 100%);`;
};

/**
 *
 * @param assignment
 */
export const getTaskIssueProjectIDFromAssignment = (
    assignment: IRequestedAssignment
): string | undefined => {
    const object = assignment.task ? assignment.task : assignment.opTask;
    return object ? object.projectID : undefined;
};

/**
 *
 * @param offset
 */
export const isFirstLoadByOffset = (offset: number): boolean => {
    return offset === 0;
};

export const checkHasMoreUsers = (
    limit: number,
    usersData: IRequestedUserData[],
    usersAddedByAssignmentID: string[],
    prevHasMoreUsers: boolean
): boolean => {
    return limit === usersData.length || (usersAddedByAssignmentID && prevHasMoreUsers);
};

export const getLimitForLazyLoad = (
    loadedUsersCount: number,
    excludedUserIDsForLazyLoad: number
): number => {
    return loadedUsersCount - excludedUserIDsForLazyLoad;
};

export const getMessageKeyByAPIStatusCode = (errorCode = 500): TMessageKey => {
    return apiRequestMessageKeysPerCode[errorCode]
        ? apiRequestMessageKeysPerCode[errorCode]
        : {
              messageKey: 'kickstart.error',
              fallBack: 'An error occurred while generating your Kickstart',
          };
};

export const getServiceNameByKey = (serviceKey: string, errorCode = 500): string => {
    let serviceName = serviceKey ? localizationClient.getTextSync(serviceKey, 'Error') : '';
    if (serviceName && errorCode === 403) {
        serviceName = serviceName.toLowerCase();
    }

    return serviceName;
};

export const getWidgetContainerWidth = (): number => {
    return window.innerWidth > WIDGET_BREAKPOINT ? BIG_WIDGET_SIZE : SMALL_WIDGET_SIZE;
};

export const getZIndexForHeaderTopRow = (assignmentDialogOpenedForID: boolean): number => {
    // when assignment dialog open it should be over top row,
    // otherwise top row should have higher z index because of month view.
    return assignmentDialogOpenedForID ? tableHeaderRow : tableHeaderTopRow;
};

const sumReducer = (accumulator: number, currentValue: number): number =>
    accumulator + currentValue;

export const arraySum = (arr: number[]): number => {
    return arr.reduce(sumReducer);
};

export const getAffectedUsersAfterObjectDurationChange = (
    usersIDs: string[],
    users: IUsersState,
    projectID: string
): string[] => {
    return usersIDs.filter((user) => {
        return users[user].nodes[projectID];
    });
};

export const getRemovedUsersFromAssignment = (
    actions: Array<TModernSchedulingAction<any>>
): string[] => {
    const removeAssignedObjectAction = actions.find(
        (action) => action.type === removeAssignedObjectType
    );
    return removeAssignedObjectAction?.payload.usersIDs || [];
};

type PlannedDates = {
    plannedStartDate?: TDate;
    plannedCompletionDate?: TDate;
};

type TimeLineDates = {
    startDate: Moment;
    endDate: Moment;
};

export const isTaskIssueGhost = (
    plannedDates: PlannedDates,
    timeLineDates: TimeLineDates,
    stepUnit: UnitOfTime
): boolean => {
    let isGhost = false;
    const { plannedStartDate, plannedCompletionDate } = plannedDates;

    if (plannedStartDate && plannedCompletionDate) {
        isGhost = !isInDateRange(
            timeLineDates.startDate,
            timeLineDates.endDate,
            plannedStartDate,
            plannedCompletionDate,
            stepUnit
        );
    }

    return isGhost;
};

export const getByMessageKeySync = (
    key: string,
    fallback: string,
    args?: TLocalizationSingleProps<any>['args']
): string => {
    return localizationClient.getTextSync(key, fallback, args);
};

export const isExistsStringInArray = (string: string | null, array: string[]): boolean => {
    return !!string && array.indexOf(string) !== -1;
};

export const isGreaterThanZero = (value?: number): boolean => {
    return !!value && value > 0;
};

export const permissionsDataExists = (sharableLink: boolean): boolean => !sharableLink;

export const mergeTwoArrays = <T>(twoArrays: T[][]): T[] => {
    const [array1, array2] = twoArrays;
    return array1.concat(array2);
};

// List of supported locales where day is before month (e.g. 16 Aug)
const isDayBeforeMonthLocale = {
    'pt-BR': true,
    it: true,
    de: true,
    fr: true,
    es: true,
};

// List of supported locales where year is before month (e.g. 2022, Aug 16)
const isYearBeforeMonthLocale = {
    zh: true,
    'zh-TW': true,
    ja: true,
};

export const getFormattedDateRange = (
    startDate: Moment,
    endDate: Moment,
    stepUnit: UnitOfTime,
    useDaysStepUnit?: boolean
): string => {
    const currentLocale = localizationClient.getTranslationLocale();
    const timeUnit = useDaysStepUnit ? stepUnit : getUnitOfTime(stepUnit);

    const startOfPeriod = startDate.clone().startOf(timeUnit);
    const startDateFirstDay = startOfPeriod.format('DD');
    const currentMonth = startOfPeriod.month();
    const currentYear = startOfPeriod.year();

    const endOfPeriod = endDate.clone().endOf(timeUnit);
    const endOfPeriodMonth = endOfPeriod.month();
    const endOfPeriodYear = endOfPeriod.year();
    const endDateLastDay = endOfPeriod.format('DD');

    const isSameYear = currentYear === endOfPeriodYear;
    const isSameMonth = isSameYear && currentMonth === endOfPeriodMonth;
    const isSameDay = isSameMonth && isSameYear && startDateFirstDay === endDateLastDay;

    const isStartMonthVisible = !isSameMonth || !isDayBeforeMonthLocale[currentLocale];
    const isEndMonthVisible = !isSameMonth || isDayBeforeMonthLocale[currentLocale];
    const isYearFromStart = isYearBeforeMonthLocale[currentLocale];

    let endDateString = '';
    let startDateString = '';
    let startEndSeparator = '';

    if (isSameDay) {
        // Display full date string if no range
        startDateString = dateToStringCurrentLocale(startOfPeriod.toString(), {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    } else {
        startEndSeparator = ' - ';
        startDateString = dateToStringCurrentLocale(startOfPeriod.toString(), {
            day: '2-digit',
            month: isStartMonthVisible ? 'short' : undefined,
            year: !isSameYear || isYearFromStart ? 'numeric' : undefined,
        });

        const isEndYearVisible = !isSameYear || !isYearFromStart;
        endDateString = getEndDateString(endOfPeriod, isEndMonthVisible, isEndYearVisible);
    }

    return `${startDateString}${startEndSeparator}${endDateString}`;
};

function getEndDateString(
    endDate: Moment,
    isEndMonthVisible: boolean,
    isEndYearVisible: boolean
): string {
    const endDay = dateToStringCurrentLocale(endDate.toString(), {
        day: '2-digit',
    });
    const endYear = dateToStringCurrentLocale(endDate.toString(), {
        year: 'numeric',
    });
    const endDateNoMonth = isEndYearVisible ? `${endDay}, ${endYear}` : endDay;
    // check if end month visible to format correctly
    // otherwise we would have Aug 16 - 2022(day: 188) instead of Aug 16-18, 2022
    return isEndMonthVisible
        ? dateToStringCurrentLocale(endDate.toString(), {
              day: '2-digit',
              month: 'short',
              year: isEndYearVisible ? 'numeric' : undefined,
          })
        : endDateNoMonth;
}

function dateToStringCurrentLocale(
    date: string | Date,
    formatOptions: Intl.DateTimeFormatOptions
): string {
    const currentLocale = localizationClient.getTranslationLocale();
    const dateToFormat = new Date(date);
    return dateToFormat.toLocaleDateString(currentLocale, formatOptions);
}
