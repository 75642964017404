import { systemScrollbarSize } from '../../../constants/schedulingTableConstants';
import { TModernSchedulingAction } from '../../types';
import { ITableSizesState } from '../ITableSizesState';

interface ITSetTableWidthActionPayload {
    tableWidth: number;
}

export type TSetTableWidthAction = TModernSchedulingAction<ITSetTableWidthActionPayload>;

export const ActionType = 'SET_TABLE_WIDTH';

const setTableWidth = (tableWidth: number): TSetTableWidthAction => ({
    type: ActionType,
    payload: {
        tableWidth,
    },
});
export default setTableWidth;

export function handle(state: ITableSizesState, action: TSetTableWidthAction): ITableSizesState {
    return {
        ...state,
        tableWidth:
            systemScrollbarSize > 0
                ? action.payload.tableWidth - systemScrollbarSize
                : action.payload.tableWidth,
    };
}
