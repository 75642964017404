import { getSystemScrollbarSize } from '@wf-titan/dom-util';
import { getLeftSidePanelWidthToLocalStorage } from '../util/localStorage';

export enum Sections {
    UNASSIGNED_WORK = 'unassignedWork', // THE CHANGE OF IT WILL BRING DATA LOSE IN FILTERS (DON'T DO IT)
    PEOPLE_WORKLOAD = 'peopleWorkload', // THE CHANGE OF IT WILL BRING DATA LOSE IN FILTERS (DON'T DO IT)
}
const tableRowTaskHeight = 36;
const borderMainWidthForGridView = 1;
const tableRowProjectHeight = 40 + borderMainWidthForGridView;
const tableRowTaskDiffForActualProgress = 10;
const tableRowProjDiffForActualProgress = 8;
export const projectMarginBottomForActualProgressRedesign = 1;

export const sizes: Record<string, number> = {
    tableLeftColumnWidth: getLeftSidePanelWidthToLocalStorage() || 290,
    tableLeftColumnMinWidth: 110,
    tableLeftColumnMaxWidth: 600,
    tableRowHeight: 42,
    tableRowHeightChartView: 65,
    tableGapFromTopChartView: 12,
    tableRowTaskHeight,
    tableRowTaskDiffForActualProgress,
    tableRowTaskHeightForActualProgress:
        tableRowTaskHeight + tableRowTaskDiffForActualProgress - borderMainWidthForGridView,
    tableRowProjectHeight,
    tableRowProjectHeightForActualProgress:
        tableRowProjectHeight + tableRowProjDiffForActualProgress - borderMainWidthForGridView,
    tableHeaderRowHeight: 38,
    cellMinWidthDayMode: 39,
    cellMinWidthDayModeRedesign: 50,
    cellMinWidthWeekMode: 60,
    cellMinWidthMonthMode: 106,
    cellMinWidthWeeksMode: 95,
    footerHeight: 28,
    durationPadding: 4,
    taskAssignmentHeight: 26,
    projectAssignmentHeight: 30,
    projectAssignmentHeightRedesign: 41,
    assignmentArrowSize: 10,
    noUserDataHeight: 250,
    borderWidth: 4,
    gridLightGreyBorderWidth: 1,
    borderMainWidthForGridView,
    chartSmallDiffForVisual: 0.5,
    dragOverlayBorderSize: 2,
};

export const taskPaddings = {
    unassignedSectionPGOn: 32,
    unassignedSectionPGOff: 16,
    peopleWorkloadSectionPGOn: 44,
    peopleWorkloadSectionPGOFF: 36,
};

export const systemScrollbarSize = getSystemScrollbarSize();

export const assignmentPopoverSizes = {
    assignmentPopperWidth: 368,
    arrowDistance: 24,
    paddingFromContainer: 12,
};

export enum TaskBarBorder {
    WITH_ARROW = 12,
    WITHOUT_ARROW = 4,
}

export enum ChartViewMaxHours {
    MAX_HOURS_DAILY = 600, // 10 * 60 minutes;
    MAX_HOURS_WEEKLY = 3000, // MAX_HOURS_FOR_CHART_VIEW_DAILY * 5 days per week
    MAX_HOURS_MONTHLY = 12000, // MAX_HOURS_FOR_CHART_VIEW_WEEKLY * 4 weeks per month
}
