import { Button, Tooltip } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import GroupIcon from 'phoenix-icons/dist/GroupIcon.js';
import React from 'react';
import { Sections } from '../../../constants/schedulingTableConstants';
import { TAreaObjCodes } from '../../../data-flow/areaData/areaRelatedInitialDataState';
import removeTableDataIDs from '../../../data-flow/data/assignedDataActions/removeTableDataIDs';
import toggleLoading from '../../../data-flow/data/dataLoadingActions/toggleLoading';
import { TMessageKey } from '../../../data-flow/data/IDataState';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import { useAppDispatch, useAppSelector } from '../../../data-flow/hooks';
import showAllUsers from '../../../data-flow/settings/settingsActions/showAllUsers';
import { showAllUsersSelector } from '../../../data-flow/settings/settingsSelector';
import { loadUsersOnProjectThunk, loadUsersThunk } from '../../../data-flow/thunks/loadDataThunk';
import {
    getInitialHeightsOfTables,
    getRowCountRelatedToHeight,
    prepareTestId,
} from '../../../util/utilities';
import { getHeaderItemStyles } from '../../styles/headerComponentStyles';

interface IShowAllUsersButtonProps {
    sharableLink: boolean;
    schedulingAreaObjCode: TAreaObjCodes;
    saveSettingsAndResetContouring: () => void;
}

const getAllUsersKey = (isShowAllocationOn: boolean): TMessageKey => {
    return isShowAllocationOn
        ? {
              messageKey: 'workloadbalancer.show.project.users',
              fallBack: 'Show only users on this project',
          }
        : {
              messageKey: 'workloadbalancer.show.all.users',
              fallBack: 'Show all users',
          };
};

export const ShowAllUsersButton = React.memo<IShowAllUsersButtonProps>((props) => {
    const { saveSettingsAndResetContouring, schedulingAreaObjCode, sharableLink } = props;
    const dispatch = useAppDispatch();
    const isShowAllUsers: boolean = useAppSelector((state) => showAllUsersSelector(state));

    const handleShowAllUsers = (): void => {
        dispatch(
            actionChain([
                showAllUsers(),
                removeTableDataIDs('all'),
                toggleLoading(Sections.PEOPLE_WORKLOAD, true),
            ])
        );
        const { peopleWorkLoadHeight } = getInitialHeightsOfTables(0);

        dispatch(loadUsersThunk(0, getRowCountRelatedToHeight(peopleWorkLoadHeight)));
        saveSettingsAndResetContouring();
    };

    if (isShowAllUsers) {
        dispatch(loadUsersOnProjectThunk());
    }

    const showAllUsersClassName = getHeaderItemStyles(isShowAllUsers);
    const showUsersMessageKey = getAllUsersKey(isShowAllUsers);

    return (
        <Localization<string[]>
            messageKeys={['workloadbalancer.show.project.users', 'workloadbalancer.show.all.users']}
        >
            {({ _t }) => {
                return (
                    <Tooltip
                        content={_t[showUsersMessageKey.messageKey](showUsersMessageKey.fallBack)}
                        delay={700}
                    >
                        <Button
                            text
                            className={showAllUsersClassName}
                            data-testid={prepareTestId(
                                showUsersMessageKey.messageKey,
                                sharableLink,
                                schedulingAreaObjCode,
                                '-url'
                            )}
                            onClick={handleShowAllUsers}
                            aria-pressed={isShowAllUsers}
                            aria-label={_t[showUsersMessageKey.messageKey](
                                showUsersMessageKey.fallBack
                            )}
                        >
                            <GroupIcon focusable="false" />
                        </Button>
                    </Tooltip>
                );
            }}
        </Localization>
    );
});
