import * as React from 'react';
import { connect } from 'react-redux';
import mitt from 'mitt';
import { Sections } from '../../../../constants/schedulingTableConstants';
import { isErrorThrownSelector } from '../../../../data-flow/errorData/errorDataSelector';
import { AssignmentLoading } from '../AssignmentLoading';
import { Assignments } from './Assignments';
import { UserHours } from './UserHours';
import { UserLoading } from './UserLoading';
import { IWorkSchedulingDispatchProp } from '../../../../data-flow/types';
import { IGeneralStateTypes } from '../../../../data-flow/IGeneralStateTypes';
import { userByIdSelector } from '../../../../data-flow/data/selectors/users/usersSelector';
import { isInLoadingStatePeopleWorkloadSectionSelector } from '../../../../data-flow/data/selectors/isInLoadingState/isInLoadingStateSelectors';

interface IPeopleWorkloadSectionRightCellProps
    extends React.HTMLAttributes<HTMLDivElement>,
        IWorkSchedulingDispatchProp {
    idExpression: string;
    isTheLastRow: boolean;
    dataIDsLength: number;
    internalEventEmitter: mitt.Emitter;
}

type IPeopleWorkloadSectionRightCellStateToProps = Pick<
    IGeneralStateTypes,
    'isInLoadingStatePeopleWorkloadSection' | 'userData'
> & {
    isUserDataLoaded: boolean;
    isErrorThrown: boolean;
};

export const PeopleWorkloadSectionRightCellComponent: React.FunctionComponent<
    IPeopleWorkloadSectionRightCellProps & IPeopleWorkloadSectionRightCellStateToProps
> = React.memo((props) => {
    const { isInLoadingStatePeopleWorkloadSection, idExpression, isUserDataLoaded, isErrorThrown } =
        props;
    const idsArray = idExpression && idExpression.split('_');

    const isUserRow =
        isInLoadingStatePeopleWorkloadSection ||
        idsArray.length === 1 ||
        (!idsArray[0] && idsArray[1] === 'loading');
    const loadingRow = idsArray && idsArray.indexOf('loading') !== -1;

    if (isErrorThrown) {
        return null;
    }
    if (isUserRow) {
        if (isInLoadingStatePeopleWorkloadSection || !isUserDataLoaded || loadingRow) {
            return <UserLoading idExpression={idExpression} />;
        }

        return <UserHours userID={idsArray[0]} idExpression={idExpression} />;
    }
    if (loadingRow) {
        return <AssignmentLoading />;
    }
    return <Assignments idExpression={idExpression} isTheLastRow={props.isTheLastRow} />;
});

function mapStateToProps(state, ownProps): IPeopleWorkloadSectionRightCellStateToProps {
    const userData = userByIdSelector(state, { userID: ownProps.idExpression });

    return {
        isInLoadingStatePeopleWorkloadSection: isInLoadingStatePeopleWorkloadSectionSelector(state),
        isErrorThrown: isErrorThrownSelector(state, Sections.PEOPLE_WORKLOAD),
        isUserDataLoaded: userData && userData.userDataRequestsState.isDataLoaded,
        userData,
    };
}

export const PeopleWorkloadSectionRightCell = connect(mapStateToProps)(
    PeopleWorkloadSectionRightCellComponent
);
