import { TWorkSchedulingThunkAction } from '../../types';
import { APIService } from '../../../services/api-services/apiService';
import setEspProductEnabled from '../../data/assignedDataActions/espProductEnabled/setEspProductEnabled';

export const loadCustomerProductEnabled = (): TWorkSchedulingThunkAction<Promise<void>> => {
    return function _loadCustomerProductEnabled(dispatch) {
        return APIService.loadESPProductEnabled().then(({ data }) => {
            dispatch(setEspProductEnabled(data.result));
        });
    };
};
