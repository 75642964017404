import { Field } from '@workfront/panel-components';
import { IWorkSchedulingCombinedState } from '../types';
import {
    IProjectGroupingMode,
    TIsPanelOpened,
    TOrUndefined,
    TProjectColorsMode,
    TShowActualProgress,
} from '../data/IDataState';

export const showAllocationsSelector = (state: IWorkSchedulingCombinedState): boolean => {
    return state.SettingsState.showAllocations;
};

export const showAllUsersSelector = (state: IWorkSchedulingCombinedState): boolean => {
    return state.SettingsState.showAllUsers;
};

export const showAllocationsVisualizationSelector = (
    state: IWorkSchedulingCombinedState
): boolean => {
    return state.SettingsState.showAllocationsVisualization;
};

export const showRoleSummarySelector = (state: IWorkSchedulingCombinedState): boolean => {
    return state.SettingsState.showRoleSummary;
};

export const isPanelOpenedSelector = (state: IWorkSchedulingCombinedState): TIsPanelOpened => {
    return state.SettingsState.isPanelOpened;
};

export const isBulkAssignmentPanelOpenedSelector = (
    state: IWorkSchedulingCombinedState
): TIsPanelOpened => {
    return state.SettingsState.isBulkAssignmentPanelOpened;
};

export const showRemainingHoursSelector = (state: IWorkSchedulingCombinedState): boolean => {
    return state.SettingsState.showRemainingHours;
};

export const showIssuesSelector = (state: IWorkSchedulingCombinedState): boolean => {
    return state.SettingsState.showIssues;
};

export const showActualProgressSelector = (
    state: IWorkSchedulingCombinedState
): TShowActualProgress => {
    return state.SettingsState.showActualProgress;
};

export const showAllocationsInHoursModeSelector = (
    state: IWorkSchedulingCombinedState
): boolean => {
    return state.SettingsState.showAllocationsInHoursMode;
};

export const projectColorsModeSelector = (
    state: IWorkSchedulingCombinedState
): TProjectColorsMode => {
    return state.SettingsState.projectColorsMode;
};

export const showCompletedWorkSelector = (state: IWorkSchedulingCombinedState): boolean => {
    return state.SettingsState.showCompletedWork;
};

export const sortUsersByRoleSelector = (state: IWorkSchedulingCombinedState): boolean => {
    return state.SettingsState.sortUsersByRoleEnabled;
};

export const projectsSortingCriteriaSelector = (
    state: IWorkSchedulingCombinedState
): TOrUndefined<Field> => {
    return state.SettingsState.projectsSortingCriteria;
};

export const projectGroupingModeSelector = (
    state: IWorkSchedulingCombinedState
): IProjectGroupingMode => {
    return state.SettingsState.projectGroupingMode;
};
