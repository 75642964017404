import * as React from 'react';
import moment, { Moment } from 'moment';
import { Project } from 'workfront-objcodes';
import { size } from 'lodash';
import { Sections } from '../../../constants/schedulingTableConstants';
import { UnitOfTime } from '../../../data-flow/types';
import { AllocationHour } from './AllocationHour';
import { getPeriodModesValues } from '../../../util/periodUtils/periodUtils';
import { EditableAllocations } from './EditableAllocations';
import {
    getWorkingDays,
    isThereAnyWorkingDayInWeek,
    isWorkingDayBySchedule,
} from '../../../util/utilities';
import {
    IFullTimeOffSteps,
    ITimeOffsState,
    IWorkDays,
    TOrUndefined,
    TWorkPerDays,
} from '../../../data-flow/data/IDataState';
import { getFullTimeOffSteps } from '../../../data-flow/data/selectors/dataSelectors';
import { DATE_FORMAT } from '../../../constants/dataConstatnts';

interface IAllocationHoursProps {
    scheduleWorkDays: IWorkDays;
    stepUnit: UnitOfTime;
    activePeriodMode: UnitOfTime;
    showActualProgress: boolean;
    showAllocationsInHoursMode: boolean;
    availableHours: TOrUndefined<number[]>;
    scheduleHours: TOrUndefined<number[]>;
    assignmentStartDate: Moment;
    assignmentEndDate: Moment;
    plannedStartDate: string;
    plannedCompletionDate: string;
    workPerDays: TOrUndefined<TWorkPerDays>;
    stepStartDate: Moment;
    fullTimeOffsStepsByDay: IFullTimeOffSteps;
    sectionType: Sections;
    allocationsEditingMode: boolean | string | null;
    cellWidth: number;
    isThereBeforeArrow: boolean;
    isThereAfterArrow: boolean;
    height: number;
    objCode: string;
    assignmentActualEndDate: Moment | null;
    crossedOutHoursForPeriod?: number[];
    textColor?: string;
    backgroundColor: string;
    IDs: string[];
    isFromAssignmentBar: boolean;
    steps: Moment[];
    timeOffs: ITimeOffsState;
}

interface IDefaultProps {
    hour: number;
    availableHour: number;
    crossedOutHour: number;
    scheduleHour: number;
    fullTimeOffsStepsByDay: IFullTimeOffSteps;
    scheduleWorkDays: IWorkDays;
    cellWidth: number;
    isThereBeforeArrow: boolean;
    isThereAfterArrow: boolean;
    height: number;
    isSinglePeriodTask: boolean;
    objCode: string;
}

interface ITimeOffProps {
    sectionType: Sections;
    scheduleWorkDays: IWorkDays;
    fullTimeOffsStepsByDay: IFullTimeOffSteps;
    startStep: Moment;
    startDate: Moment;
    endStep: Moment;
    endDate: Moment;
}

const shouldBeWorkingDay = (
    isDayMode: boolean,
    scheduleWorkDays: IWorkDays,
    step: Moment
): boolean => {
    return isDayMode
        ? isWorkingDayBySchedule(scheduleWorkDays, step)
        : isThereAnyWorkingDayInWeek(scheduleWorkDays);
};

export const AllocationHours: React.FC<IAllocationHoursProps> = React.memo((props) => {
    const {
        assignmentStartDate,
        assignmentEndDate,
        stepStartDate,
        stepUnit,
        sectionType,
        scheduleWorkDays,
        fullTimeOffsStepsByDay,
        showActualProgress,
        objCode,
        assignmentActualEndDate,
        crossedOutHoursForPeriod,
        allocationsEditingMode,
        activePeriodMode,
        showAllocationsInHoursMode,
        plannedStartDate,
        plannedCompletionDate,
        workPerDays = [],
        availableHours = [],
        scheduleHours = [],
        textColor,
        cellWidth,
        isThereBeforeArrow,
        isThereAfterArrow,
        height,
        backgroundColor,
        IDs,
        isFromAssignmentBar,
        timeOffs,
    } = props;

    const isSinglePeriodTask = assignmentStartDate.diff(assignmentEndDate, stepUnit) === 0;

    const defaultProps: IDefaultProps = {
        hour: 0,
        availableHour: 0,
        crossedOutHour: 0,
        scheduleHour: 0,
        fullTimeOffsStepsByDay,
        scheduleWorkDays,
        cellWidth,
        isThereBeforeArrow,
        isThereAfterArrow,
        height,
        isSinglePeriodTask,
        objCode,
    };

    const assignmentStartStep = assignmentStartDate.clone().startOf(stepUnit);
    const plannedStart = moment(plannedStartDate);
    const plannedCompletion = moment(plannedCompletionDate);

    const hoursList: JSX.Element[] = [];
    let index = 0;
    let allocationHourKey = 0;

    while (assignmentStartStep <= assignmentEndDate) {
        let component;
        const diff = assignmentStartStep.diff(stepStartDate, stepUnit);
        const stepEnd = assignmentStartStep.clone().endOf(stepUnit);

        const isTimeOff = checkIsTimeOff({
            sectionType,
            scheduleWorkDays,
            fullTimeOffsStepsByDay,
            startStep: assignmentStartStep,
            startDate: assignmentStartDate,
            endStep: stepEnd,
            endDate: assignmentEndDate,
        });
        defaultProps.hour = workPerDays[diff];
        defaultProps.availableHour = availableHours[diff];
        defaultProps.scheduleHour = scheduleHours[diff];
        if (showActualProgress && objCode === Project && crossedOutHoursForPeriod) {
            defaultProps.hour -= crossedOutHoursForPeriod[diff];
        }

        if (crossedOutHoursForPeriod) {
            defaultProps.crossedOutHour = crossedOutHoursForPeriod[diff];
        }

        const showCrossedOutStyle =
            showActualProgress &&
            sectionType === Sections.PEOPLE_WORKLOAD &&
            assignmentActualEndDate &&
            assignmentActualEndDate.isBefore(assignmentStartStep);
        const { isDayMode, isWeekMode } = getPeriodModesValues(activePeriodMode);
        const fullTimeOffsSteps = getFullTimeOffSteps(timeOffs, isWeekMode);
        const isWorkingDay = shouldBeWorkingDay(isDayMode, scheduleWorkDays, assignmentStartStep);
        const stepDateString = assignmentStartStep.format(DATE_FORMAT);
        const isWeekEnd = !isWorkingDay || fullTimeOffsSteps[stepDateString];

        if (!allocationsEditingMode) {
            component = (
                <AllocationHour
                    {...defaultProps}
                    isTimeOff={isTimeOff}
                    isWeekView={isWeekMode}
                    showCrossedOutStyle={showCrossedOutStyle}
                    showAllocationsInHoursMode={showAllocationsInHoursMode}
                    textColor={textColor}
                    isFromAssignmentBar={isFromAssignmentBar}
                    isWorkingDay={!isWeekEnd}
                    key={`allocationHour-${allocationHourKey}`}
                />
            );
            allocationHourKey += 1;
        } else {
            component = (
                <EditableAllocations
                    key={`editableAllocations-${index}`}
                    plannedStartDate={plannedStart}
                    plannedCompletionDate={plannedCompletion}
                    assignmentStartStep={assignmentStartStep.clone()}
                    {...defaultProps}
                    backGroundColor={backgroundColor}
                    IDs={IDs}
                    index={index}
                    isFromAssignmentBar={isFromAssignmentBar}
                    isTimeOff={isTimeOff}
                    textColor={textColor}
                />
            );
            index += 1;
        }
        hoursList.push(component);
        assignmentStartStep.add(1, stepUnit);
    }

    return <>{hoursList}</>;
});

const checkIsTimeOff = (props: ITimeOffProps): boolean => {
    const {
        sectionType,
        scheduleWorkDays,
        fullTimeOffsStepsByDay,
        startStep,
        startDate,
        endStep,
        endDate,
    } = props;

    if (sectionType !== Sections.PEOPLE_WORKLOAD) {
        return false;
    }

    const workingDays = getWorkingDays(
        scheduleWorkDays,
        fullTimeOffsStepsByDay,
        startStep < startDate ? startDate : startStep,
        endStep > endDate ? endDate : endStep
    );
    return size(workingDays) === 0;
};
