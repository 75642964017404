import { createSelector } from 'reselect';
import { tableDataIDsSelector } from '../tableDataIDsSelector';

export const isLastRowUnderUserSelector = createSelector(
    tableDataIDsSelector,
    (tableDataIDs) => (idExpression: string) => {
        // assumed that idExpression is not user's one level
        const expressionIndex = tableDataIDs.indexOf(idExpression);
        const nextExpression = tableDataIDs[expressionIndex + 1];

        if (!nextExpression) {
            return true;
        }
        const userId = idExpression.split('_')[0];
        return nextExpression.indexOf(userId) === -1;
    }
);
