import { TModernSchedulingAction } from '../../types';
import { IDataState, TContouringRowID } from '../IDataState';

export const ActionType = 'CHANGE_ID_EXPRESSION_FOR_MINIX_STATE';

interface IEditRowPayload {
    rowID: TContouringRowID;
}

type TEditRowAction = TModernSchedulingAction<IEditRowPayload>;

const changeIDExpressionForMinixState = (rowID): TEditRowAction => ({
    type: ActionType,
    payload: {
        rowID,
    },
});
export default changeIDExpressionForMinixState;

export function handle(state: IDataState, { payload }: TEditRowAction): IDataState {
    const { rowID } = payload;
    return {
        ...state,
        IDExpressionForMinixState: rowID,
    };
}
