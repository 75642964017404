import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'CHANGE_UNASSIGNED_NODE_ARROW_STATE';

interface IChangeUnassignedNodeArrowStatePayload {
    ID: string;
}

type TChangeUnassignedNodeArrowStateAction =
    TModernSchedulingAction<IChangeUnassignedNodeArrowStatePayload>;

const changeUnassignedNodeArrowState = (ID): TChangeUnassignedNodeArrowStateAction => ({
    type: ActionType,
    payload: {
        ID,
    },
});
export default changeUnassignedNodeArrowState;

export function handle(
    state: IDataState,
    { payload }: TChangeUnassignedNodeArrowStateAction
): IDataState {
    const unassignedTasksProjects = { ...state.unassignedTasksProjects };
    const newExpandedUnassignedProjectIDs: Set<string> = new Set(
        state.expandedUnassignedProjectIDs
    );
    if (unassignedTasksProjects[payload.ID].details.expanded) {
        newExpandedUnassignedProjectIDs.delete(payload.ID);
    } else {
        newExpandedUnassignedProjectIDs.add(payload.ID);
    }

    unassignedTasksProjects[payload.ID] = {
        ...unassignedTasksProjects[payload.ID],
        details: {
            ...unassignedTasksProjects[payload.ID].details,
            expanded: !unassignedTasksProjects[payload.ID].details.expanded,
        },
    };

    return {
        ...state,
        unassignedTasksProjects,
        expandedUnassignedProjectIDs: newExpandedUnassignedProjectIDs,
    };
}
