import { primary } from '@phoenix/all';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef } from 'react';
import { css, cx } from 'react-emotion';
import { connect } from 'react-redux';
import { sizes } from '../../../../constants/schedulingTableConstants';
import removeFromHighlightingMode from '../../../../data-flow/data/assignedDataActions/removeFromHighlightingMode';
import {
    IAffectingDuration,
    ITimeOffsState,
    TDraggingFromArea,
    TUserID,
} from '../../../../data-flow/data/IDataState';
import {
    draggingFromAreaSelector,
    highlightingModeUserSelector,
} from '../../../../data-flow/data/selectors/dataSelectors';
import { IDateRangeState } from '../../../../data-flow/dateRange/IDateRangeState';
import { endDateSelector } from '../../../../data-flow/dateRange/selectors/endDateSelector';
import { startDateSelector } from '../../../../data-flow/dateRange/selectors/startDateSelector';
import { stepsSelector } from '../../../../data-flow/dateRange/selectors/stepsSelector';
import { cellWidthSelector } from '../../../../data-flow/tableSizes/selectors/cellWidthSelector';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
    UnitOfTime,
} from '../../../../data-flow/types';
import { calculateLeftPosition, calculateWidth } from '../../../../util/durationStylesCalculation';

import { cellWrapper } from '../../../styles/sharedStyles';
import { getPeriodModesValues } from '../../../../util/periodUtils/periodUtils';
import { stepUnitSelector } from '../../../../data-flow/dateRange/selectors/stepUnitSelector';
import { getUserSelector } from '../../../../data-flow/data/selectors/users/usersSelector';
import { tableDataIDsSelector } from '../../../../data-flow/data/selectors/tableDataIDsSelector';
import { showAllocationsVisualizationSelector } from '../../../../data-flow/settings/settingsSelector';
import { timeOffsSelectorForWeekMonthViewCached } from '../../../../data-flow/data/selectors/rereselect/timeOffsSelectorForWeekMonthView';
import { animationStyles } from '../../../styles/userHoursStyles';
import { purAvailableHoursSelector } from '../../../../data-flow/data/selectors/reselect/purAvailableHoursSelector/purAvailableHoursSelector';
import { isInAssignmentProcessSelector } from '../../../../data-flow/data/selectors/reselect/isInAssignmentProcessSelector';
import { userWorkPerDaysSelectorForPeriodFromService } from '../../../../data-flow/data/selectors/rereselect/userWorkPerDaysSelectorForPeriodFromService';
import { timeOffsSelectorCached } from '../../../../data-flow/data/selectors/rereselect/timeOffsSelector';
import { dragOverlayBackground } from '../../../../constants/appColors';
import { dragAndDropIDs } from '../../../../dragAndDrop/dragAndDropTestIDs';
import { Steps } from './Steps';

interface IUserHoursStateProps extends Pick<IDateRangeState, 'steps'> {
    workPerDay: number[];
    purAvailableHours: number[];
    timeOffs: ITimeOffsState;
    activePeriodMode: UnitOfTime;
    stepUnit: UnitOfTime;
    isInAssignmentProcess: boolean;
    periodStartDate: Moment;
    periodEndDate: Moment;
    highlightingDetails: IAffectingDuration;
    isFirstUser: boolean;
    cellWidth: number;
    isAllocationsVisualizationOn: boolean;
    draggingFromArea: TDraggingFromArea;
}

export interface IUserHoursProps
    extends React.HTMLAttributes<HTMLDivElement>,
        IUserHoursStateProps {
    userID: TUserID;
}

export const dataTestIds = {
    assignmentHighlight: 'assignment-highlight',
    assignmentOverlays: 'assignment-overlays',
    userHoursWeekend: 'user-hours-weekend',
    userHoursWeekday: 'user-hours-weekday',
};

export const UserHoursComponent: React.FunctionComponent<
    IUserHoursProps & IWorkSchedulingDispatchProp
> = React.memo((props) => {
    const highlightingDivRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    const {
        dispatch,
        steps,
        workPerDay,
        timeOffs,
        stepUnit,
        isInAssignmentProcess,
        highlightingDetails,
        periodStartDate,
        periodEndDate,
        cellWidth,
        isFirstUser,
        isAllocationsVisualizationOn,
        userID,
        draggingFromArea,
        activePeriodMode,
    } = props;

    useEffect(() => {
        const removeUserHighlighting = (): void => {
            highlightingDivRef?.current?.className.replace('', noneHighlightingMode);
            dispatch(removeFromHighlightingMode([userID]));
        };

        const highlightingDivCurrent = highlightingDivRef?.current;
        highlightingDivCurrent?.addEventListener('animationend', removeUserHighlighting);
        return function cleanup() {
            highlightingDivCurrent?.removeEventListener('animationend', removeUserHighlighting);
        };
    }, [userID, dispatch]);

    let overlays: number[][] = [];
    let highlightingClassName = noneHighlightingMode;

    if (highlightingDetails) {
        const assignmentWidth = calculateWidth(
            stepUnit,
            periodStartDate,
            periodEndDate,
            moment(highlightingDetails.affectingDurationStartDate),
            moment(highlightingDetails.affectingDurationEndDate),
            cellWidth
        );

        const leftPosition = calculateLeftPosition(
            stepUnit,
            periodStartDate,
            moment(highlightingDetails.affectingDurationStartDate),
            cellWidth,
            0
        );

        if (draggingFromArea) {
            overlays = [
                [sizes.borderMainWidthForGridView, leftPosition - sizes.borderMainWidthForGridView],
                [leftPosition + assignmentWidth + sizes.borderMainWidthForGridView, -1], // -1 as position absolute right -1
            ];
        } else {
            highlightingClassName = getHighlightingModeClassName(
                assignmentWidth,
                leftPosition,
                isFirstUser,
                isInAssignmentProcess
            );
        }
    }

    return (
        <div data-testid={dragAndDropIDs.droppable}>
            <div data-testid={`${userID}-rightCell`}>
                {getOverlays(overlays, overlayRef)}
                <div
                    ref={highlightingDivRef}
                    className={highlightingClassName}
                    data-testid={dataTestIds.assignmentHighlight}
                />
                <div className={cellWrapper}>
                    <Steps
                        steps={steps}
                        activePeriodMode={activePeriodMode}
                        workPerDay={workPerDay}
                        timeOffs={timeOffs}
                        isAllocationsVisualizationOn={isAllocationsVisualizationOn}
                        stepUnit={stepUnit}
                        userID={userID}
                        isInAssignmentProcess={isInAssignmentProcess}
                    />
                </div>
            </div>
        </div>
    );
});

const mapStateToProps = (state: IWorkSchedulingCombinedState, ownProps): IUserHoursStateProps => {
    const activePeriodMode = stepUnitSelector(state);
    const { isDayMode } = getPeriodModesValues(activePeriodMode);

    const user = getUserSelector(state)(ownProps.userID);
    const isFirstUser = tableDataIDsSelector(state).indexOf(ownProps.userID) === 0;
    const timeOffs = isDayMode
        ? timeOffsSelectorCached(state, { userID: ownProps.userID })
        : timeOffsSelectorForWeekMonthViewCached(state, {
              scheduleID: user.scheduleID,
              userID: ownProps.userID,
          });

    return {
        activePeriodMode,
        timeOffs,
        stepUnit: stepUnitSelector(state),
        steps: stepsSelector(state),
        periodEndDate: endDateSelector(state),
        periodStartDate: startDateSelector(state),
        workPerDay: userWorkPerDaysSelectorForPeriodFromService(state, {
            userID: ownProps.userID,
        }),
        purAvailableHours: purAvailableHoursSelector(state, { userID: ownProps.userID }),
        isInAssignmentProcess: isInAssignmentProcessSelector(state)(ownProps.userID),
        highlightingDetails: highlightingModeUserSelector(state, { userID: ownProps.userID }),
        cellWidth: cellWidthSelector(state),
        isFirstUser,
        isAllocationsVisualizationOn: showAllocationsVisualizationSelector(state),
        draggingFromArea: draggingFromAreaSelector(state),
    };
};

export const UserHours = connect(mapStateToProps)(UserHoursComponent);

const getOverlays = (
    overlays: number[][],
    overlayRef: React.MutableRefObject<any>
): Array<JSX.Element | null> | null => {
    return overlays.map((overlay, i) => {
        const secondOne = i === 1;
        const isOverlayActive = secondOne || overlay[1] > 0;

        if (!isOverlayActive) {
            return null;
        }

        return (
            <div
                ref={overlayRef}
                className={getOverlayClassName(overlay[1] - overlay[0], overlay[0], secondOne)}
                data-testid={dataTestIds.assignmentOverlays}
            />
        );
    });
};

const getOverlayWidth = (width: number, lastOne: boolean): string => {
    return !lastOne ? `width: ${width + 1}px` : `right: ${-1}px`;
};

const getOverlayClassName = (width: number, leftPosition: number, lastOne: boolean): string => {
    if (width === null) {
        return '';
    }

    return css`
        position: absolute;
        ${getOverlayWidth(width, lastOne)};
        height: 100%;
        top: 0;
        left: ${leftPosition}px;
        z-index: 4;
        background-color: ${dragOverlayBackground};
    `;
};

const getHighlightingModeClassName = (
    width: number,
    leftPosition: number,
    isFirstUser: boolean,
    isUserHoveredForDropping: unknown
): string => {
    if (width === null) {
        return '';
    }

    const blueBorderStyles = css`
        position: absolute;
        width: ${width + 1}px;
        height: 100%;
        top: ${isFirstUser ? 0 : -1}px;
        left: ${leftPosition + sizes.gridLightGreyBorderWidth}px;
        border: 1px dashed ${primary.blue(400)};
        z-index: 3;
    `;

    if (isUserHoveredForDropping) {
        return blueBorderStyles;
    }

    return cx(animationStyles, blueBorderStyles);
};

const noneHighlightingMode = css`
    display: none;
    background: transparent;
    border: 0;
`;
