import { Task } from 'workfront-objcodes';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'CHANGE_UNASSIGNED_DATA_REQUEST_MODE';

interface IChangeUnassignedDataRequestModePayload {
    isUnassignedTasksProjectsRequestSend: boolean;
    objCode: string;
}

const changeUnassignedDataRequestMode = (
    isUnassignedTasksProjectsRequestSend: boolean,
    objCode: string
): TModernSchedulingAction<IChangeUnassignedDataRequestModePayload> => ({
    type: ActionType,
    payload: {
        isUnassignedTasksProjectsRequestSend,
        objCode,
    },
});
export default changeUnassignedDataRequestMode;

export function handle(
    state: IDataState,
    { payload }: TModernSchedulingAction<IChangeUnassignedDataRequestModePayload>
): IDataState {
    if (payload.objCode === Task) {
        return {
            ...state,
            isUnassignedTasksRequestSend: payload.isUnassignedTasksProjectsRequestSend,
        };
    }
    return {
        ...state,
        isUnassignedTasksProjectsRequestSend: payload.isUnassignedTasksProjectsRequestSend,
    };
}
