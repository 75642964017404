import { createReducer } from '../createReducer';
import { IDateRangeState } from './IDateRangeState';
import * as timelineNavigation from './timelineActions/timelineNavigation';
import * as changePeriodStartDate from './changePeriodStartDate';
import * as changeActivePeriodMode from './changeActivePeriodMode';

export function getDateRangeReducer(initialState: IDateRangeState) {
    return createReducer(
        [timelineNavigation, changeActivePeriodMode, changePeriodStartDate],
        initialState
    );
}
