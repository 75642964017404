import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'ADD_OBJECT_INTO_ASSIGNMENT_MODE';

interface IAddObjectsIntoAssignmentMode {
    objectIDs: string[];
}

type TEditRowAction = TModernSchedulingAction<IAddObjectsIntoAssignmentMode>;

const addObjectsIntoAssignmentMode = (objectIDs): TEditRowAction => ({
    type: ActionType,
    payload: {
        objectIDs,
    },
});
export default addObjectsIntoAssignmentMode;

export function handle(state: IDataState, { payload }: TEditRowAction): IDataState {
    const { objectIDs } = payload;
    const objectsInAssignmentMode = state.objectsInAssignmentMode ?? [];
    return {
        ...state,
        objectsInAssignmentMode: [...objectsInAssignmentMode, ...objectIDs],
    };
}
