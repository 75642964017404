import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'CHANGE_UNASSIGNED_ALL_NODES_ARROW_STATE';

interface IChangeUnassignedNodeArrowStatePayload {
    isOneExpanded: boolean;
    unassignedTasksProjectsOffset: number;
}

type TChangeUnassignedNodeArrowStateAction =
    TModernSchedulingAction<IChangeUnassignedNodeArrowStatePayload>;

const changeUnassignedAllNodesArrowState = (
    isOneExpanded,
    unassignedTasksProjectsOffset = 0
): TChangeUnassignedNodeArrowStateAction => ({
    type: ActionType,
    payload: {
        isOneExpanded,
        unassignedTasksProjectsOffset,
    },
});
export default changeUnassignedAllNodesArrowState;

export function handle(
    state: IDataState,
    { payload }: TChangeUnassignedNodeArrowStateAction
): IDataState {
    const unassignedTasksProjects = { ...state.unassignedTasksProjects };

    const unassignedTasksProjectsIds = Object.keys(unassignedTasksProjects).filter(
        (projectId) => unassignedTasksProjects[projectId].nodes.length
    );

    for (
        let id = payload.unassignedTasksProjectsOffset;
        id < unassignedTasksProjectsIds.length;
        id++
    ) {
        unassignedTasksProjects[unassignedTasksProjectsIds[id]] = {
            ...unassignedTasksProjects[unassignedTasksProjectsIds[id]],
            details: {
                ...unassignedTasksProjects[unassignedTasksProjectsIds[id]].details,
                expanded: !payload.isOneExpanded,
            },
        };
    }

    return {
        ...state,
        unassignedTasksProjects,
        expandedUnassignedProjectIDs: new Set(unassignedTasksProjectsIds),
    };
}
