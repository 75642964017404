import { Localization } from '@workfront/localize-react';
import { Moment } from 'moment';
import * as React from 'react';
import { WeekStyled } from './WeekModeContent';

interface ISmallModeContentProps extends React.HTMLAttributes<HTMLDivElement> {
    step: Moment;
    isSmallMode: boolean;
}

export const MonthModeContent = React.memo<ISmallModeContentProps>((props) => {
    const { step, isSmallMode } = props;

    return (
        <div>
            <WeekStyled isSmallMode={isSmallMode}>
                <Localization<string>
                    messageKey="resourceplanner.week.number"
                    args={[step.clone().isoWeek(), '']}
                    fallback="Week"
                />
            </WeekStyled>
        </div>
    );
});
