import { css } from 'emotion';
import * as React from 'react';
import { Button } from '@phoenix/all';
import { sizes } from '../../../constants/schedulingTableConstants';
import { getByMessageKeySync } from '../../../util/utilities';

interface IErrorPageProps {
    tableViewportWidth?: number;
    leftSidePanelWidth?: number;
    isInAssignedSection?: boolean;
}

const headerErrorMessages: string = getByMessageKeySync(
    'workloadbalancer.error.general',
    'We cannot display your information'
);

const bodyErrorMessages: string = getByMessageKeySync(
    'workloadbalancer.error.description',
    'There was a problem loading your information. Wait a few minutes and try again. If the problem continues, let us know.'
);

const errorTextMainStyle: string = css`
    font-size: 14px;
    line-height: 24px;
    text-align: center;
`;

const errorTextHeaderStyle: string = css`
    ${errorTextMainStyle};
    font-weight: 700;
`;

const errorTextBodyStyle: string = css`
    ${errorTextMainStyle};
    font-weight: 400;
`;

const errorPageContentWidth: number = 672;

const contentContainerForAssigned: string = css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100% - ${sizes.tableHeaderRowHeight}px);
    align-items: center;
    margin-top: 38px;
    z-index: 2;
    background: white;
`;

const contentContainerForUnassigned = (
    tableViewportWidth: number,
    leftSidePanelWidth: number
): string => {
    return css`
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: ${(tableViewportWidth - errorPageContentWidth) / 2 - leftSidePanelWidth}px;
        margin-top: -7%;
    `;
};
export const ErrorPage: React.FunctionComponent<IErrorPageProps> = React.memo(function ErrorPage(
    props
) {
    const {
        tableViewportWidth = 0,
        leftSidePanelWidth = 0,
        isInAssignedSection = false,
        children,
    } = props;

    const mainContent = (
        <>
            <div style={{ margin: '16px 0' }}>
                <div className={errorTextHeaderStyle}>{headerErrorMessages}</div>
                <div className={errorTextBodyStyle}>{bodyErrorMessages}</div>
            </div>
            {/**
             Can't use `window.parent.location.reload()` function as workfront is
             used as an iframe inside unified shell, and their domains are different.
             */}
            <Button onClick={() => window.parent.location.replace('scheduling')} secondary>
                {getByMessageKeySync('refresh', 'Refresh')}
            </Button>
        </>
    );
    return (
        <div
            className={
                isInAssignedSection
                    ? contentContainerForAssigned
                    : contentContainerForUnassigned(tableViewportWidth, leftSidePanelWidth)
            }
        >
            {children}
            {mainContent}
        </div>
    );
});
