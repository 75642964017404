import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';
import { unassignedSectionHeaderDataID } from '../../../constants/dataConstatnts';
import { tableDataIDsForUnassignedSectionWithoutHeader } from '../selectors/dataSelectors';

export const ActionType = 'REMOVE_TABLE_DATA_IDS_FOR_UNASSIGNED_SECTION';

interface ITableDataIDsActionPayload {
    idExpression: string;
}

export type TTableDataIDsAction = TModernSchedulingAction<ITableDataIDsActionPayload>;

const removeTableDataIDsForUnassignedSection = (idExpression): TTableDataIDsAction => ({
    type: ActionType,
    payload: {
        idExpression,
    },
});
export default removeTableDataIDsForUnassignedSection;

export function handle(state: IDataState, { payload }: TTableDataIDsAction): IDataState {
    const { idExpression } = payload;
    let newDataIDs: string[] = [];

    if (idExpression === '') {
        return {
            ...state,
            tableDataIDsForUnassignedSection: unassignedSectionHeaderDataID.concat(newDataIDs),
        };
    }

    const withoutHeaderDataIDs = tableDataIDsForUnassignedSectionWithoutHeader(state);

    const subNodeIDs = withoutHeaderDataIDs.filter((item) => item.indexOf(idExpression) !== -1);

    newDataIDs = [
        ...withoutHeaderDataIDs.slice(0, withoutHeaderDataIDs.indexOf(subNodeIDs[0])),
        ...withoutHeaderDataIDs.slice(
            withoutHeaderDataIDs.indexOf(subNodeIDs[0]) + subNodeIDs.length
        ),
    ];

    return {
        ...state,
        tableDataIDsForUnassignedSection: unassignedSectionHeaderDataID.concat(newDataIDs),
    };
}
