import _ from 'lodash';
import { Sections } from '../../constants/schedulingTableConstants';
import { IFiltersState } from './IFiltersState';

export const initialState = {
    [Sections.UNASSIGNED_WORK]: {
        filterExpression: undefined,
        filterID: undefined,
        filterName: undefined,
        isFilterOpen: false,
        appliedFiltersCount: 0,
        rawData: undefined,
    },
    [Sections.PEOPLE_WORKLOAD]: {
        assignmentsFilter: {},
        usersFilter: {},
        filterID: undefined,
        filterName: undefined,
        isFilterOpen: false,
        appliedFiltersCount: 0,
        rawData: undefined,
    },
    projectStatuses: [],
};

export function getInitialFiltersState(): IFiltersState {
    const state = _.cloneDeep(initialState);
    state[Sections.PEOPLE_WORKLOAD].filterID = [] as any;
    state[Sections.UNASSIGNED_WORK].filterID = [] as any;
    return state;
}
