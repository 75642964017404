import { addToast } from '@phoenix/all';

import { flatten, map } from 'lodash';
import {
    Schedule as scheduleObjCode,
    Task as taskObjCode,
    User as userObjCode,
} from 'workfront-objcodes';
import {
    LOAD_TASKS_ISSUES_LIMIT,
    LOAD_USERS_LIMIT,
    USER_FIELDS,
    usersTasksSortObject,
} from '../../constants/dataConstatnts';
import { filterNotFoundErrKey } from '../../constants/filters/filterConstants';
import { Sections, systemScrollbarSize } from '../../constants/schedulingTableConstants';
import { APIService } from '../../services/api-services/apiService';
import { getProjectsStatuses } from '../../services/api-services/filters/filterApiService';
import { getErrorMessage } from '../../ToastMessages';
import { sortByProjectID } from '../../util/changeProjectsSortingUtil';
import {
    getUsersFilter,
    prepareAreaAdditionalSimpleFilterForUserApi,
} from '../../util/filters/filterUtil';
import {
    checkHasMoreUsers,
    getAffectedUsersAfterObjectDurationChange,
    getMessageKeyByAPIStatusCode,
    getRemovedUsersFromAssignment,
    getServiceNameByKey,
    getTaskIssueProjectIDFromAssignment,
    isColorModeProject,
    isColorModeProjectStatus,
    isFirstLoadByOffset,
    isInProjectArea,
    prepareColorsByProjectData,
    prepareColorsByProjectGroupData,
    sortObjectByPlannedAndProjectedDates,
} from '../../util/utilities';
import changeUserContouringEditAccess from '../currentUser/currentUserActions/changeUserContouringEditAccess';
import changeWorkRequiredPermission from '../currentUser/currentUserActions/changeWorkRequiredPermission';
import addAssignments from '../data/assignedDataActions/addAssignments';
import addDefaultScheduleID from '../data/assignedDataActions/addDefaultScheduleID';
import addInaccessibleAssignments from '../data/assignedDataActions/addInaccessibleAssignments';
import addInaccessibleWorkingHoursForPeriod from '../data/assignedDataActions/addInaccessibleWorkingHoursForPeriod';
import addProjectGroupColors from '../data/assignedDataActions/addProjectGroupColors/addProjectGroupColors';
import addRoles from '../data/assignedDataActions/addRoles';
import addSchedulesWorkDays from '../data/assignedDataActions/addSchedulesWorkDays';
import addTableDataIDs from '../data/assignedDataActions/addTableDataIDs';
import addUsers from '../data/assignedDataActions/addUsers';
import changeHasMore from '../data/assignedDataActions/changeHasMore';
import changeUserNodesOffset from '../data/assignedDataActions/changeOffset';
import removeTableDataIDs from '../data/assignedDataActions/removeTableDataIDs';
import removeUserAssignments from '../data/assignedDataActions/removeUsersAssignments';
import removeWorkPerDaysByUsersAssignments from '../data/assignedDataActions/removeWorkPerDaysByUsersAssignments';
import setCalculateFTEAutomatic from '../data/assignedDataActions/setCalculateFTEAutomatic';
import setHeightForPeopleWorkloadEmptyRow from '../data/assignedDataActions/setHeightForPeopleWorkloadEmptyRow';
import setObjectsIntoAssignmentMode from '../data/assignedDataActions/setObjectsIntoAssignmentMode';
import setUserIntoAssignmentMode from '../data/assignedDataActions/setUserIntoAssignmentMode';
import setUsersOnProject from '../data/assignedDataActions/setUsersOnProject';
import removeRow from '../data/dataLoadingActions/removeRow';
import toggleLoading from '../data/dataLoadingActions/toggleLoading';
import {
    IObjCode,
    IRequestedUserData,
    IRequestedUserRoles,
    ISchedule,
    TProjectID,
    TTaskOrIssueID,
    TUserID,
} from '../data/IDataState';
import changeNodeArrowState from '../data/nodeItemActions/changeNodeArrowState';
import {
    projectGroupColorsSelector,
    tasksAndIssuesSelector,
    unassignedTasksSelector,
} from '../data/selectors/dataSelectors';
import removeWorkingHoursForPeriod from '../data/sharedActions/removeWorkingHoursForPeriod';
import setProjectLifeAfterDeath from '../data/sharedActions/setProjectLifeAfterDeath';
import { endDateSelector } from '../dateRange/selectors/endDateSelector';
import { stepsSelector } from '../dateRange/selectors/stepsSelector';
import addProjectStatusesForFilters from '../filters/filtersActions/addProjectStatusesForFilters';
import actionChain from '../higher-order-reducers/actionChain';
import getProjectTimelineInfo from '../projectTimelineInfo/actions/getProjectTimelineInfo';
import { TWorkSchedulingThunkAction } from '../types';
import { checkShouldAddUserByAssignment } from '../../util/changeAssignmentsUtil';
import sortUsersAddedByAssignment from '../data/assignedDataActions/sortUsersAddedByAssignment';
import { loadWorkPerDayListForUsers } from './loadWorkPerDayDataThunk';
import removeAssignedObject from '../data/assignedDataActions/removeAssignedObject';
import { loadCustomerProductEnabled } from './loadCustomerProductEnabledThunk/loadCustomerProductEnabledThunk';
import { usersSelector } from '../data/selectors/users/usersSelector';
import {
    projectColorsModeSelector,
    projectGroupingModeSelector,
    showActualProgressSelector,
    showAllUsersSelector,
    sortUsersByRoleSelector,
} from '../settings/settingsSelector';
import { peopleWorkLoadHeightSelector } from '../tableSizes/selectors/tableSizesSelectors';
import { getSchedulingAreaData } from '../areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { projectColorsSelector } from '../projectColors/selectors/projectColorsSelector/projectColorsSelector';
import addProjectColors from '../projectColors/actions/addProjectColors/addProjectColors';
import { ICurrentUser } from '../../util/currentUserMapper';
import { isProjectAccessible } from '../../components/schedulingTable/utils';
import { loadMissingDataForAssignedSectionThunk } from './assignedSectionLoadDataThunk';
import { actionsWhenError } from './unassignedSectionLoadDataThunk';
import setLoadWorkDelegations from '../workDelegations/setLoadWorkDelegations';
import setProjectsSortingCriteria from '../settings/settingsActions/projectsSortingCriteria';
import { IAssignmentsObjects } from '../../util/dataStructures/IAssignmentaObjects';

export function loadProjectPreferencesThunk(): TWorkSchedulingThunkAction<Promise<void>> {
    return function _loadProjectPreferencesThunk(dispatch) {
        const apiResponse = APIService.getProjectPreferences()
            .then((data) => {
                const projectTimelineInfo = {
                    hoursPerDay: data.hoursPerWorkday.value,
                    daysPerWeek: data.workdaysPerWeek.value,
                    weeksPerMonth: 4,
                };
                dispatch(getProjectTimelineInfo(projectTimelineInfo));
                return data.lifeAfterDeathAfterCompleteOrDeath.value[0].selected;
            })
            .catch(() => {
                return false; // can not edit
            });

        return apiResponse.then((hasProjectLifeAfterDeath) => {
            dispatch(setProjectLifeAfterDeath(!!hasProjectLifeAfterDeath));
        });
    };
}

export function loadCustomerPreferencesThunk(groupId): TWorkSchedulingThunkAction<Promise<void>> {
    return function _loadCustomerPreferencesThunk(dispatch) {
        return APIService.getCustomerPreferences(groupId).then((data) => {
            dispatch(setCalculateFTEAutomatic(data.data[0].value === 'true'));
            dispatch(setLoadWorkDelegations(data.data[1].value === 'true'));
        });
    };
}

export function loadAllSchedulesThunk(): TWorkSchedulingThunkAction<Promise<void>> {
    return function _loadDefaultScheduleThunk(dispatch, getState) {
        return APIService.apiSearch(scheduleObjCode, { $$LIMIT: 2000 }, ['ID, isDefault'])
            .then((schedules: ISchedule[]) => {
                const { startDate } = getState().DateRange;
                const endDate = endDateSelector(getState());

                const scheduleIDs = schedules.map((schedule) => {
                    if (schedule.isDefault) {
                        dispatch(addDefaultScheduleID(schedule.ID));
                    }
                    return schedule.ID;
                });
                return APIService.getAllSchedules(scheduleIDs, startDate, endDate).then(
                    (scheduleTimeZonedData) => {
                        dispatch(addSchedulesWorkDays(scheduleTimeZonedData.result));
                    }
                );
            })
            .catch((error) => {
                dispatch(actionChain(actionsWhenError(error, Sections.PEOPLE_WORKLOAD)));
            });
    };
}

export function loadProjectsStatusesThunk(): TWorkSchedulingThunkAction<Promise<void>> {
    return function _loadProjectsStatusesThunk(dispatch) {
        return getProjectsStatuses().then((data) => {
            const statuses = map(data, (item) => {
                return { ID: item.value, name: item.label };
            });
            dispatch(addProjectStatusesForFilters(statuses));
        });
    };
}

export function loadUserAccessLevelPermissionsThunk(
    currentUser: ICurrentUser
): TWorkSchedulingThunkAction<Promise<void>> {
    return function _loadUserAccessLevelPermissionsThunk(dispatch): Promise<void> {
        const accessLevel = currentUser?.accessLevel;
        if (accessLevel) {
            const { license } = currentUser;
            dispatch(
                actionChain([
                    changeUserContouringEditAccess(accessLevel, license),
                    changeWorkRequiredPermission(accessLevel),
                ])
            );
        }
        return Promise.resolve();
    };
}

export function loadInitDataThunk(
    currentUser: ICurrentUser
): TWorkSchedulingThunkAction<Promise<any>> {
    return function _loadInitDataThunk(dispatch, getState): Promise<void[]> {
        const state = getState();
        const { schedulingAreaObjCode } = state.areaData;

        const promises: Array<Promise<void>> = [
            dispatch(loadAllSchedulesThunk()),
            dispatch(loadProjectPreferencesThunk()),
            dispatch(loadCustomerPreferencesThunk(currentUser.homeGroupId)),
            dispatch(loadUserAccessLevelPermissionsThunk(currentUser)),
        ];

        // TODO make batch call for init data

        if (isInProjectArea(schedulingAreaObjCode)) {
            promises.push(dispatch(loadCustomerProductEnabled()));
        }

        return Promise.all(promises);
    };
}

export function loadUsersOnProjectThunk(
    offset: number = 0,
    limit: number = LOAD_USERS_LIMIT
): TWorkSchedulingThunkAction<Promise<void>> {
    return function _loadUsersOnProjectThunk(dispatch, getState): Promise<void> {
        const schedulingAreaData = getSchedulingAreaData(getState());
        const userFilter = {
            $$LIMIT: limit,
            $$FIRST: offset,
            isActive: 1,
            'userAssignments:projectID': [schedulingAreaData.schedulingAreaID],
            'userAssignments:projectID_Mod': 'in',
        };

        return APIService.apiSearch(userObjCode, userFilter, ['ID']).then(
            (usersData: IRequestedUserData[]) => {
                dispatch(setUsersOnProject(usersData.map((el) => el.ID)));
            }
        );
    };
}
export function loadUsersThunk(
    offset: number = 0,
    limit: number = LOAD_USERS_LIMIT,
    usersAddedByAssignmentIDs: string[] = [],
    shouldCleanUpTableDataIds = false
): TWorkSchedulingThunkAction<Promise<void>> {
    let reloaded = false;
    return function _loadUsersThunk(dispatch, getState) {
        const userFilterExpression =
            getState().Filters[Sections.PEOPLE_WORKLOAD].usersFilter.filterExpression;
        const schedulingAreaData = getSchedulingAreaData(getState());
        const additionalSimpleFilter = prepareAreaAdditionalSimpleFilterForUserApi(
            schedulingAreaData,
            showAllUsersSelector(getState())
        );

        const excludedUsersIDs = getState().Data.addedUsersByAssignment;
        const sortUsersByRoleEnabled = sortUsersByRoleSelector(getState());

        const userFilter = getUsersFilter(
            userFilterExpression,
            limit,
            offset,
            { usersAddedByAssignmentIDs, excludedUsersIDs },
            sortUsersByRoleEnabled,
            additionalSimpleFilter
        );

        return APIService.apiSearch(userObjCode, userFilter, USER_FIELDS)
            .then((usersData: IRequestedUserData[]) => {
                const state = getState();

                if (!usersData.length) {
                    dispatch(
                        actionChain([
                            toggleLoading(Sections.PEOPLE_WORKLOAD, false),
                            removeRow('_loading', Sections.PEOPLE_WORKLOAD),
                            changeHasMore(false, Sections.PEOPLE_WORKLOAD),
                        ])
                    );

                    return;
                }
                const prevHasMoreUsersState = state.Data.hasMoreUsers;
                const hasMoreUsers = checkHasMoreUsers(
                    limit,
                    usersData,
                    usersAddedByAssignmentIDs,
                    prevHasMoreUsersState
                );
                const userRolesIndex = 0;
                const userIDsIndex = 1;

                const [userRoles, usersIDs] = usersData.reduce(
                    (acc, element) => {
                        acc[userRolesIndex] = acc[userRolesIndex].concat(element.userRoles);
                        acc[userIDsIndex].push(element.ID);
                        return acc;
                    },
                    [[], []] as [IRequestedUserRoles[], TUserID[]]
                );

                dispatch(
                    actionChain([
                        addUsers(usersData),
                        changeHasMore(hasMoreUsers, Sections.PEOPLE_WORKLOAD),
                        addRoles(userRoles),
                    ])
                );

                if (
                    (isFirstLoadByOffset(offset) && !usersAddedByAssignmentIDs.length) ||
                    shouldCleanUpTableDataIds
                ) {
                    dispatch(removeTableDataIDs('all'));
                }

                dispatch(addTableDataIDs(usersIDs, projectGroupingModeSelector(state)));
                const { filterExpression } = state.Filters[Sections.PEOPLE_WORKLOAD].usersFilter;

                const filterRules = filterExpression?.rules;

                if (
                    usersAddedByAssignmentIDs.length &&
                    checkShouldAddUserByAssignment(
                        state.areaData.schedulingAreaObjCode,
                        filterRules
                    )
                ) {
                    dispatch(sortUsersAddedByAssignment(usersAddedByAssignmentIDs));
                }
                dispatch(loadMissingDataForAssignedSectionThunk(usersIDs));

                if (systemScrollbarSize > 0) {
                    dispatch(
                        setHeightForPeopleWorkloadEmptyRow(
                            peopleWorkLoadHeightSelector(state),
                            showActualProgressSelector(state),
                            projectGroupingModeSelector(state)
                        )
                    );
                }
            })
            .catch((error) => {
                // Reload once if sorting criteria custom field is deleted and we get 422 error
                if (error?.status === 422 && !reloaded) {
                    reloaded = true;
                    dispatch(setProjectsSortingCriteria(undefined));
                    _loadUsersThunk(dispatch, getState);
                } else {
                    dispatch(
                        actionChain(actionsWhenError(error?.message, Sections.PEOPLE_WORKLOAD))
                    );
                }
            });
    };
}

export function loadUsersAssignments(
    usersIDs: TUserID[],
    objectID: TTaskOrIssueID | null, // it can be task or issue ID,
    actions: any[] = []
): TWorkSchedulingThunkAction<Promise<void>> {
    return function _loadUsersAssignments(dispatch, getState) {
        const state = getState();
        const { startDate } = state.DateRange;
        const endDate = endDateSelector(state);
        const periodStart = state.DateRange.startDate;
        const chainActions: any[] = [];
        let objectDetails: null | IObjCode = null;

        if (objectID) {
            const objCode = state.Data.tasksAndIssues[objectID]
                ? state.Data.tasksAndIssues[objectID].objCode
                : state.Data.unassignedTasks[objectID].objCode;

            objectDetails = { ID: objectID, objCode };
            chainActions.push(
                removeWorkPerDaysByUsersAssignments(usersIDs, objectID),
                removeUserAssignments(usersIDs, objectID)
            );
        }
        const { filterExpression } = state.Filters[Sections.PEOPLE_WORKLOAD].assignmentsFilter;

        return APIService.loadUsersAssignments(
            usersIDs,
            filterExpression,
            objectDetails,
            startDate,
            endDate,
            state.SettingsState
        )
            .then(([accessibleAssignments, inaccessibleAssignments]) => {
                const flattenAccessibleAssignments = flatten(accessibleAssignments);
                if (objectID) {
                    const removedUsersFromAssignment = getRemovedUsersFromAssignment(actions);

                    if (!removedUsersFromAssignment.length && usersIDs.length) {
                        const assignmentType =
                            objectDetails && objectDetails.objCode === taskObjCode
                                ? 'task'
                                : 'opTask';
                        const accessibleTasksAndIssues = flattenAccessibleAssignments.map(
                            (assignment) => assignment[assignmentType].ID
                        );

                        if (!accessibleTasksAndIssues.length) {
                            const taskAndIssues = tasksAndIssuesSelector(state)[objectID];
                            if (taskAndIssues) {
                                const projectWithChangedDuration = taskAndIssues.projectID;
                                const affectedUsersIDsWithAssignments =
                                    getAffectedUsersAfterObjectDurationChange(
                                        usersIDs,
                                        usersSelector(state),
                                        projectWithChangedDuration
                                    );
                                chainActions.push(
                                    removeAssignedObject(
                                        affectedUsersIDsWithAssignments,
                                        objectID,
                                        state.Data.tasksAndIssues[objectID].projectID,
                                        projectGroupingModeSelector(state)
                                    )
                                );
                            }
                        }
                    }
                }

                // this check periodStart === getState().DateRange.startDate prevent duplications of result when quick clicking on next/prev button
                if (periodStart === getState().DateRange.startDate) {
                    if (flattenAccessibleAssignments.length) {
                        const projectColorMode = projectColorsModeSelector(state);
                        const colorModeProjectStatus = isColorModeProjectStatus(projectColorMode);
                        const colorModeProject = isColorModeProject(projectColorMode);
                        const loadedGroupData = {};
                        const loadedProjectIDs: TProjectID[] = [];

                        // coloring logic related to project color and project status color
                        if (colorModeProjectStatus) {
                            const projectGroupColors = projectGroupColorsSelector(state);
                            flattenAccessibleAssignments.forEach((assigment) => {
                                const groupID = assigment?.task?.project?.groupID;
                                const groupIDAlreadyExists = projectGroupColors?.[groupID];
                                if (!groupIDAlreadyExists && groupID) {
                                    loadedGroupData[groupID] = groupID;
                                }
                            });

                            const loadedGroupDataIDs = Object.keys(loadedGroupData);
                            APIService.getColorsByGroupIds(loadedGroupDataIDs).then(
                                (groupsColors) => {
                                    const groupsColorsRefactored = prepareColorsByProjectGroupData(
                                        loadedGroupDataIDs,
                                        groupsColors
                                    );
                                    dispatch(addProjectGroupColors(groupsColorsRefactored));
                                }
                            );
                        } else if (colorModeProject) {
                            const projectColorList = projectColorsSelector(state);
                            flattenAccessibleAssignments.forEach((assigment) => {
                                const projectID = assigment?.task?.project?.ID;
                                const alreadyExists = projectColorList?.[projectID];
                                if (!alreadyExists && projectID) {
                                    loadedProjectIDs.push(projectID);
                                }
                            });

                            if (loadedProjectIDs.length) {
                                const colorsByProjectID =
                                    prepareColorsByProjectData(loadedProjectIDs);
                                dispatch(addProjectColors(colorsByProjectID));
                            }
                        }
                    }

                    const steps = stepsSelector(state);

                    accessibleAssignments.forEach((item) => {
                        const schedulingAreaData = getSchedulingAreaData(getState());
                        item.sort(
                            sortObjectByPlannedAndProjectedDates(
                                item,
                                usersTasksSortObject,
                                startDate
                            )
                        );
                        if (isInProjectArea(schedulingAreaData.schedulingAreaObjCode)) {
                            item.sort(
                                sortByProjectID(
                                    schedulingAreaData.schedulingAreaID,
                                    getTaskIssueProjectIDFromAssignment
                                )
                            );
                        }
                    });
                    chainActions.push(
                        removeWorkingHoursForPeriod(usersIDs),
                        addAssignments(accessibleAssignments, usersIDs, !!objectID)
                    );
                    if (!objectID) {
                        chainActions.push(
                            addInaccessibleAssignments(inaccessibleAssignments, usersIDs),
                            addInaccessibleWorkingHoursForPeriod(
                                steps,
                                usersIDs,
                                inaccessibleAssignments
                            )
                        );
                    }
                    chainActions.push(
                        setObjectsIntoAssignmentMode(null),
                        setUserIntoAssignmentMode(usersIDs, false),
                        ...actions
                    );

                    const dates = { startDate, endDate };

                    const assignmentsIDs: IAssignmentsObjects[] = flattenAccessibleAssignments.map(
                        (assignmentItem) => {
                            return {
                                objID: assignmentItem.ID,
                                objCode: assignmentItem.objCode,
                            };
                        }
                    );

                    if (assignmentsIDs.length) {
                        return dispatch(
                            loadWorkPerDayListForUsers(
                                assignmentsIDs,
                                usersIDs,
                                dates,
                                chainActions,
                                objectID
                            )
                        );
                    }
                    // when removing all assignments from task/issue should recalculate workingHours

                    dispatch(actionChain(chainActions));
                }
            })
            .catch((error) => {
                dispatch(actionChain(actionsWhenError(error, Sections.PEOPLE_WORKLOAD)));
            });
    };
}

export const hasMore = (limit: number, unassignedProjectsLength: number): boolean => {
    return limit === unassignedProjectsLength;
};

export function openProjectTasks(newLoadedUsersIds: TUserID[]) {
    return function _openProjectTasks(dispatch, getState): void {
        const actions: any[] = [];
        const state = getState();
        const { users, tableDataIDs } = state.Data;
        const projectGroupingMode = true;
        const getNodeIDsLimited = (nodesIDs: string[]): string[] => {
            return nodesIDs.slice(0, LOAD_TASKS_ISSUES_LIMIT);
        };

        tableDataIDs.forEach((id: string) => {
            if (isProjectAccessible(id)) {
                const [userId, projectId] = id.split('_');
                if (newLoadedUsersIds.includes(userId) && projectId !== 'showMore') {
                    const nodesIDs = users[userId].nodes[projectId].nodes;

                    actions.push(
                        changeNodeArrowState(userId, projectId),
                        changeUserNodesOffset(id, LOAD_TASKS_ISSUES_LIMIT),
                        addTableDataIDs(getNodeIDsLimited(nodesIDs), projectGroupingMode, {
                            idExpression: id,
                            showMore: nodesIDs.length > LOAD_TASKS_ISSUES_LIMIT,
                        }),
                        setHeightForPeopleWorkloadEmptyRow(
                            state.TableSizes.peopleWorkLoadHeight,
                            state.SettingsState.showActualProgress,
                            projectGroupingMode
                        )
                    );
                }
            }
        });

        dispatch(actionChain(actions));
    };
}

export function decideColorModeDispatcher(
    colorMode: string
): TWorkSchedulingThunkAction<Promise<void>> {
    return function _decideColorModeDispatcher(dispatch): Promise<void> {
        if (isColorModeProjectStatus(colorMode)) {
            return Promise.resolve(dispatch(loadProjectsGroupColors()));
        }

        return Promise.resolve(dispatch(prepareProjectColorsAndAdd()));
    };
}

export function loadProjectsGroupColors(): TWorkSchedulingThunkAction<Promise<void>> {
    return function _loadProjectsGroupColors(dispatch, getState): Promise<void> {
        const loadedGroupData = {};

        const state = getState();
        const tasksAndIssues = tasksAndIssuesSelector(state);
        const unassignedTasks = unassignedTasksSelector(state);
        const merged = { ...tasksAndIssues, ...unassignedTasks };
        const projectGroupColors = projectGroupColorsSelector(state);

        Object.keys(merged).forEach((key) => {
            const task = merged[key];
            const groupIDAlreadyExists =
                projectGroupColors && projectGroupColors[task.projectGroupID];
            if (!groupIDAlreadyExists && task.projectGroupID) {
                loadedGroupData[task.projectGroupID] = task.projectGroupID;
            }
        });

        const loadedGroupDataIDs = Object.keys(loadedGroupData);
        return APIService.getColorsByGroupIds(loadedGroupDataIDs)
            .then((groupsColors) => {
                const groupsColorsRefactored = prepareColorsByProjectGroupData(
                    loadedGroupDataIDs,
                    groupsColors
                );
                dispatch(actionChain([addProjectGroupColors(groupsColorsRefactored)]));
            })
            .catch(() => {
                addToast(
                    'error',
                    getErrorMessage(
                        'workloadbalancer.error.project.colors',
                        'There was an error loading your project colors. Default color is applied'
                    )
                );
            });
    };
}

export function prepareProjectColorsAndAdd(): TWorkSchedulingThunkAction<void> {
    return function _prepareProjectColorsAndAdd(dispatch, getState): void {
        const state = getState();

        const projectsToBeGeneratedColor = {};
        const tasksAndIssues = tasksAndIssuesSelector(state);
        const unassignedTasks = unassignedTasksSelector(state);
        const merged = { ...tasksAndIssues, ...unassignedTasks };

        const projectColors = projectColorsSelector(state);

        Object.keys(merged).forEach((key) => {
            const task = merged[key];
            const colorAlreadyExists = projectColors && projectColors[task.projectID];
            if (!colorAlreadyExists) {
                projectsToBeGeneratedColor[task.projectID] = task.projectID;
            }
        });
        const projectIDList = Object.keys(projectsToBeGeneratedColor);
        if (projectIDList.length) {
            const colorsByProjectID = prepareColorsByProjectData(projectIDList);

            dispatch(actionChain([addProjectColors(colorsByProjectID)]));
        }
    };
}

export const toastByErrorCode = (errorCode = 500, serviceKey = ''): void => {
    const { messageKey } = getMessageKeyByAPIStatusCode(errorCode);
    const fallback = getMessageKeyByAPIStatusCode(errorCode).fallBack;
    const serviceName = getServiceNameByKey(serviceKey, errorCode);

    addToast('error', getErrorMessage(messageKey, fallback, [serviceName]));
};

export const throwErrorInToastAndRemoveFilter = (sectionType: Sections): void => {
    const { messageKey } = filterNotFoundErrKey[sectionType];
    const fallback = filterNotFoundErrKey[sectionType].fallBack;
    if (messageKey) {
        addToast('error', getErrorMessage(messageKey, fallback));
    }
};
