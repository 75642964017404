import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { isInDateRange } from '../../../../util/durationStylesCalculation';
import {
    userByIdSelector,
    userInaccessibleProjectsSelector,
    userProjectNodesByIdSelector,
    userProjectNodeSelector,
} from '../users/usersSelector';
import { stepUnitSelector } from '../../../dateRange/selectors/stepUnitSelector';
import { startDateSelector } from '../../../dateRange/selectors/startDateSelector';
import { endDateSelector } from '../../../dateRange/selectors/endDateSelector';
import { showActualProgressSelector } from '../../../settings/settingsSelector';
import { combineDates } from '../../../../util/utilities';
import { IStartEndDates, TDate } from '../../IDataState';
import { tasksAndIssuesSelector, unassignedTasksSelector } from '../dataSelectors';
import { getUnassignedTasksProjectSelector } from '../unassignedTasksProjectsSelectors/unassignedTasksProjectsSelectors';

const durationsSelector = (
    inaccessibleNodes,
    rangeStartDate,
    rangeEndDates,
    stepUnit,
    showActualProgress
) => {
    const inaccessiblePlannedDates: any = [];
    const inaccessibleProjectedDates: any = [];
    let isCompleted: boolean = true;

    _.forEach(inaccessibleNodes, (nodeItem) => {
        nodeItem.inaccessibleNodesDates.forEach((inaccessibleDates) => {
            if (
                isInDateRange(
                    rangeStartDate,
                    rangeEndDates,
                    inaccessibleDates.startDate,
                    inaccessibleDates.endDate,
                    stepUnit
                )
            ) {
                inaccessiblePlannedDates.push({
                    startDate: inaccessibleDates.startDate,
                    endDate: inaccessibleDates.endDate,
                });
                if (!inaccessibleDates.actualCompletionDate) {
                    isCompleted = false;
                }
            }

            if (
                showActualProgress &&
                isInDateRange(
                    rangeStartDate,
                    rangeEndDates,
                    inaccessibleDates.projectedStartDate,
                    inaccessibleDates.projectedCompletionDate,
                    stepUnit
                )
            ) {
                inaccessibleProjectedDates.push({
                    startDate: inaccessibleDates.projectedStartDate,
                    endDate: inaccessibleDates.projectedCompletionDate,
                });
                if (!inaccessibleDates.actualCompletionDate) {
                    isCompleted = false;
                }
            }
        });
    });

    return { inaccessiblePlannedDates, inaccessibleProjectedDates, isCompleted };
};

export const inaccessibleProjectsWorkDurationSelector = createCachedSelector(
    [
        userInaccessibleProjectsSelector,
        userByIdSelector,
        stepUnitSelector,
        startDateSelector,
        endDateSelector,
        showActualProgressSelector,
    ],
    (inaccessibleProjects, user, stepUnit, startDate, endDate, showActualProgress) => {
        const inaccessibleNodes: any[] = [];

        inaccessibleProjects.forEach((projectID) => {
            inaccessibleNodes.push(user.nodes[projectID]);
        });

        const { inaccessiblePlannedDates, inaccessibleProjectedDates, isCompleted } =
            durationsSelector(inaccessibleNodes, startDate, endDate, stepUnit, showActualProgress);

        return {
            durations: combineDates(inaccessiblePlannedDates, stepUnit),
            projectedDurations: combineDates(inaccessibleProjectedDates, stepUnit),
            isCompleted,
        };
        // }
    }
)((state, { userID }) => `${userID}`);

export const inaccessibleWorkDurationSelector = createCachedSelector(
    [
        (state, obj) => obj.projectID,
        (state, obj) =>
            obj.projectID ? userProjectNodeSelector(state, obj) : userByIdSelector(state, obj),
        stepUnitSelector,
        showActualProgressSelector,
        startDateSelector,
        endDateSelector,
    ],
    (projectID, projectOrUserData, stepUnit, showActualProgress, startDate, endDate) => {
        const inaccessibleNodes: any = projectID ? [projectOrUserData] : projectOrUserData.nodes;
        const { inaccessiblePlannedDates, inaccessibleProjectedDates, isCompleted } =
            durationsSelector(inaccessibleNodes, startDate, endDate, stepUnit, showActualProgress);

        return {
            durations: combineDates(inaccessiblePlannedDates, stepUnit),
            projectedDurations: combineDates(inaccessibleProjectedDates, stepUnit),
            isCompleted,
        };
    }
)((state, { userID, projectID }) => `${userID}:${projectID || ''}`);

export const projectDurationSelector = createCachedSelector(
    [
        userProjectNodesByIdSelector,
        tasksAndIssuesSelector,
        stepUnitSelector,
        startDateSelector,
        endDateSelector,
        inaccessibleWorkDurationSelector,
        showActualProgressSelector,
    ],
    (
        projectTasks,
        tasksAndIssues,
        stepUnit,
        startDate,
        endDate,
        inaccessibleWorkDurations,
        showActualProgress
    ) => {
        const plannedDates: IStartEndDates[] = [];
        const projectedDates: IStartEndDates[] = [];
        let isCompleted: boolean = true;

        _.forEach(projectTasks, (objectID) => {
            if (
                showActualProgress &&
                isInDateRange(
                    startDate,
                    endDate,
                    tasksAndIssues[objectID].projectedStartDate,
                    tasksAndIssues[objectID].projectedCompletionDate,
                    stepUnit
                )
            ) {
                projectedDates.push({
                    startDate: tasksAndIssues[objectID].projectedStartDate,
                    endDate: tasksAndIssues[objectID].projectedCompletionDate,
                });
                if (!tasksAndIssues[objectID].actualCompletionDate) {
                    isCompleted = false;
                }
            }

            if (
                isInDateRange(
                    startDate,
                    endDate,
                    tasksAndIssues[objectID].plannedStartDate,
                    tasksAndIssues[objectID].plannedCompletionDate,
                    stepUnit
                )
            ) {
                plannedDates.push({
                    startDate: tasksAndIssues[objectID].plannedStartDate,
                    endDate: tasksAndIssues[objectID].plannedCompletionDate,
                });
                if (!tasksAndIssues[objectID].actualCompletionDate) {
                    isCompleted = false;
                }
            }
        });

        if (inaccessibleWorkDurations) {
            inaccessibleWorkDurations.durations.forEach((item) => {
                plannedDates.push({ ...item });
            });

            // here inaccessibleWorkDurations.projectedDurations will be empty if showActualProgress is false
            inaccessibleWorkDurations.projectedDurations.forEach((item) => {
                projectedDates.push({ ...item });
            });

            if (!inaccessibleWorkDurations.isCompleted) {
                isCompleted = inaccessibleWorkDurations.isCompleted;
            }
        }

        // projectedDates will be empty if showActualProgress is false
        return {
            plannedDurations: combineDates(plannedDates, stepUnit),
            projectedDurations: combineDates(projectedDates, stepUnit),
            isCompleted,
        };
    }
)((state, { userID, projectID }) => `${userID}:${projectID}`);

export const projectDurationForUnassignedSectionSelector = createCachedSelector(
    [
        // caching getUnassignedTasksProjectSelector selector brought error,
        // when assigning task from unassigned to assigned,
        // project grouping on case, do not cache this selector, if there is no required need
        getUnassignedTasksProjectSelector,
        unassignedTasksSelector,
        stepUnitSelector,
    ],
    (projectTasks, tasks, stepUnit) => {
        const dates: Array<{ startDate: TDate; endDate: TDate }> = [];
        let isCompleted: boolean = true;

        _.forEach(projectTasks.nodes, (taskID) => {
            dates.push({
                startDate: tasks[taskID].plannedStartDate,
                endDate: tasks[taskID].plannedCompletionDate,
            });
            if (!tasks[taskID].actualCompletionDate) {
                isCompleted = false;
            }
        });
        const combinedDates = combineDates(dates, stepUnit);

        return {
            durations: combinedDates,
            isCompleted,
        };
    }
)((state, { projectID }) => `${projectID}`);
