import { sizes, Sections } from '../constants/schedulingTableConstants';

export const getRowCount = (
    sectionType: Sections,
    tableHeight: number,
    projectGroupingMode: boolean,
    dataIDs: number,
    inLoadingState: boolean
) => {
    if (sectionType === Sections.UNASSIGNED_WORK) {
        return calculateRowCountForUnassignedSection(
            tableHeight,
            projectGroupingMode,
            dataIDs,
            inLoadingState
        );
    }
    return calculateRowCountForPeopleWorkloadSection(tableHeight, dataIDs, inLoadingState);
};

const calculateRowCountForUnassignedSection = (
    tableHeight: number,
    projectGroupingMode: boolean,
    dataIDs: number,
    inLoadingState: boolean
) => {
    const rowHeight: number = projectGroupingMode
        ? sizes.tableRowProjectHeight
        : sizes.tableRowTaskHeight;
    if (inLoadingState) {
        return Math.ceil(tableHeight / rowHeight);
    }
    if (Math.ceil(tableHeight / rowHeight) >= dataIDs) {
        return dataIDs + 1;
    }
    return dataIDs;
};

const calculateRowCountForPeopleWorkloadSection = (
    tableHeight: number,
    dataIDs: number,
    loadingState: boolean
) => {
    if (loadingState) {
        return Math.ceil(tableHeight / sizes.tableRowHeight);
    }
    return dataIDs;
};
