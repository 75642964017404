import { Localization } from '@workfront/localize-react';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { cx } from 'emotion';
import { Button, ButtonDropdown } from '@phoenix/all';
import { prepareTestId } from '../../../../util/utilities';
import {
    MONTH_COUNT_IN_PERIOD,
    TimelineActions,
    TimeUnit,
    WEEKS,
} from '../../../../constants/periodEnums';
import {
    headerButtonsDisabledStyles,
    headerButtonsSelectedStyles,
    periodModeButtonsStyle,
} from '../../../styles/headerComponentStyles';
import changeActivePeriodMode from '../../../../data-flow/dateRange/changeActivePeriodMode';
import { IWorkSchedulingDispatchProp, UnitOfTime } from '../../../../data-flow/types';
import {
    checkStartDateShouldBeChanged,
    getPeriodModeOptions,
    getPeriodModesValues,
    getSelectedPeriodModeIndex,
    getPeriodBtnTabIndex,
    isOneWeekMode,
    isWeekRangeMode,
} from '../../../../util/periodUtils/periodUtils';
import actionChain from '../../../../data-flow/higher-order-reducers/actionChain';
import { stepUnitSelector } from '../../../../data-flow/dateRange/selectors/stepUnitSelector';
import timelineNavigation from '../../../../data-flow/dateRange/timelineActions/timelineNavigation';
import toggleLoading from '../../../../data-flow/data/dataLoadingActions/toggleLoading';
import { Sections } from '../../../../constants/schedulingTableConstants';
import { tableOpenedStateSelector } from '../../../../data-flow/tableSizes/selectors/tableOpenedStateSelector';
import { userIDsSelector } from '../../../../data-flow/data/selectors/users/usersSelector';
import { getSchedulingAreaData } from '../../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { testIdsPeriodModes } from './PeriodModeButtonsConstants';
import { loadUnassignedSectionMonthPeriodModeToggleThunk } from '../../../../data-flow/thunks/unassignedSectionLoadDataThunk';
import { loadAssignedSectionMonthPeriodModeToggleThunk } from '../../../../data-flow/thunks/assignedSectionLoadDataThunk';
import { periodModeSelector } from '../../../../data-flow/dateRange/selectors/periodModeSelector';
import { periodTimeUnitSelector } from '../../../../data-flow/dateRange/selectors/periodTimeUnitSelector';

import {
    timelinePeriodDropDownClosedStyles,
    timelinePeriodDropDownOpenedStyles,
    periodModeDropdownStyles,
} from '../../../styles/timelineStyles';
import { thresholdForLargeScreen } from '../TimelineDateRangePicker/TimelineDateRangePicker';

interface IPeriodModeButtonsProps extends IWorkSchedulingDispatchProp {
    sharableLink?: string;
    saveSettingsAndResetContouring: () => void;
}

interface IPeriodModeButtonsStateToProps {
    activePeriodMode: UnitOfTime;
    isUnassignedTableCollapsed: boolean;
    schedulingArea?: string;
    userIDs: string[];
    periodMode: number;
    periodTimeUnit: TimeUnit;
}

const rightArrow = 'ArrowRight';
const leftArrow = 'ArrowLeft';

const PeriodModeButtonsComponent: React.FunctionComponent<
    IPeriodModeButtonsProps & IPeriodModeButtonsStateToProps
> = (props) => {
    const { activePeriodMode, sharableLink, schedulingArea, periodMode, periodTimeUnit } = props;
    const { isDayMode, isWeekMode, isMonthMode } = getPeriodModesValues(activePeriodMode);
    const clickTimeout = useRef(0);

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > thresholdForLargeScreen);
    const [isOpenedPeriodModeDropdown, setOpen] = useState(false);

    const toggleDropDownOpenState = (): void => setOpen((open) => !open);

    const weekRangeMode = isWeekRangeMode(periodMode, periodTimeUnit);
    const oneWeekMode = isOneWeekMode(periodMode, periodTimeUnit);

    const handleActivePeriodModeChange = (stepUnit): void => {
        const { dispatch, saveSettingsAndResetContouring, userIDs, isUnassignedTableCollapsed } =
            props;

        if (activePeriodMode === stepUnit) {
            return;
        }

        dispatch(changeActivePeriodMode(stepUnit));

        const startDateShouldBeChanged = checkStartDateShouldBeChanged(stepUnit, activePeriodMode);

        if (startDateShouldBeChanged) {
            /*
             * We need to toggle loading here because `loadAssignedSectionMonthPeriodModeToggleThunk`
             * call is being made after 500 ms, and user sees unresponsiveness
             * between his click and actual row loading
             * */
            const actions: any[] = [toggleLoading(Sections.PEOPLE_WORKLOAD, true)];

            if (!isUnassignedTableCollapsed) {
                actions.push(toggleLoading(Sections.UNASSIGNED_WORK, true));
            }

            dispatch(actionChain(actions));

            if (clickTimeout.current !== 0) {
                clearTimeout(clickTimeout.current);
                clickTimeout.current = 0;
            } else {
                clickTimeout.current = window.setTimeout(() => {
                    dispatch(loadAssignedSectionMonthPeriodModeToggleThunk(userIDs, stepUnit));

                    if (!isUnassignedTableCollapsed) {
                        dispatch(loadUnassignedSectionMonthPeriodModeToggleThunk());
                    }

                    clickTimeout.current = 0;
                }, 500);
            }
        }

        if (stepUnit === TimeUnit.MONTH) {
            dispatch(
                timelineNavigation(
                    TimelineActions.PERIOD_CHANGE,
                    MONTH_COUNT_IN_PERIOD,
                    undefined,
                    TimeUnit.MONTH
                )
            );
        } else if (periodTimeUnit === TimeUnit.MONTH) {
            dispatch(
                timelineNavigation(
                    TimelineActions.PERIOD_CHANGE,
                    WEEKS.W_12,
                    undefined,
                    TimeUnit.WEEK
                )
            );
        }

        saveSettingsAndResetContouring();
    };

    const dayViewButton = useRef<HTMLButtonElement>();
    const weekViewButton = useRef<HTMLButtonElement>();
    const monthViewButton = useRef<HTMLButtonElement>();

    const handleKeyDown = (e): void => {
        const tabs = [dayViewButton.current, weekViewButton.current, monthViewButton.current];
        const activeElements = tabs.filter((el) => !el?.hasAttribute('disabled'));
        const enabledElementsCount = activeElements.length;

        let tabFocus = activeElements.findIndex((el) => el?.getAttribute('tabindex') === '0');

        if (e.key === rightArrow || e.key === leftArrow) {
            activeElements[tabFocus]?.setAttribute('tabindex', '-1');
            if (e.key === rightArrow) {
                tabFocus += 1;
                if (tabFocus >= enabledElementsCount) {
                    tabFocus = 0;
                }
            } else if (e.key === leftArrow) {
                tabFocus -= 1;
                if (tabFocus < 0) {
                    tabFocus = enabledElementsCount - 1;
                }
            }
            activeElements[tabFocus]?.setAttribute('tabindex', '0');
            activeElements[tabFocus]?.focus();
        }
    };

    useEffect(() => {
        const onWindowResize = (): void => {
            const width = window.innerWidth;

            if (isLargeScreen && width < thresholdForLargeScreen) {
                setIsLargeScreen(false);
            } else if (!isLargeScreen && width >= thresholdForLargeScreen) {
                setIsLargeScreen(true);
            }
        };
        onWindowResize();
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, [isLargeScreen]);

    const periodModeOptions = getPeriodModeOptions(
        sharableLink,
        schedulingArea,
        oneWeekMode,
        weekRangeMode
    );

    const selectedPeriodMode = periodModeOptions[getSelectedPeriodModeIndex(isDayMode, isWeekMode)];

    const periodModeDropdownFinalStyles = cx(
        periodModeDropdownStyles,
        isOpenedPeriodModeDropdown
            ? timelinePeriodDropDownOpenedStyles
            : timelinePeriodDropDownClosedStyles
    );

    return (
        <Localization<string[]>
            messageKeys={[
                'resourcescheduling.switch.to.day.view',
                'resourcescheduling.switch.to.week.view',
                'resourcescheduling.switch.to.month.view',
                'workloadbalancer.select.timeline.view',
            ]}
        >
            {({ _t }) => {
                return isLargeScreen ? (
                    <div role="tablist" className={periodModeButtonsStyle}>
                        <Button
                            data-testid={prepareTestId(
                                testIdsPeriodModes.dayView,
                                sharableLink,
                                schedulingArea
                            )}
                            ref={dayViewButton}
                            text
                            role="tab"
                            tabIndex={getPeriodBtnTabIndex(isDayMode)}
                            aria-selected={isDayMode}
                            className={
                                !isDayMode
                                    ? headerButtonsSelectedStyles
                                    : headerButtonsDisabledStyles
                            }
                            aria-disabled={isDayMode}
                            onClick={() => handleActivePeriodModeChange(TimeUnit.DAY)}
                            onKeyDown={handleKeyDown}
                            aria-label={_t['resourcescheduling.switch.to.day.view'](
                                'Switch to day view'
                            )}
                        >
                            <Localization<string> messageKey="day" fallback="Day" />
                        </Button>
                        <Button
                            data-testid={prepareTestId(
                                testIdsPeriodModes.weekView,
                                sharableLink,
                                schedulingArea
                            )}
                            ref={weekViewButton}
                            text
                            role="tab"
                            tabIndex={getPeriodBtnTabIndex(isWeekMode)}
                            className={
                                !isWeekMode
                                    ? headerButtonsSelectedStyles
                                    : headerButtonsDisabledStyles
                            }
                            disabled={oneWeekMode}
                            aria-disabled={oneWeekMode || isWeekMode}
                            aria-selected={isWeekMode}
                            onKeyDown={handleKeyDown}
                            onClick={() => handleActivePeriodModeChange(TimeUnit.WEEK)}
                            aria-label={_t['resourcescheduling.switch.to.week.view'](
                                'Switch to week view'
                            )}
                        >
                            <Localization<string> messageKey="week" fallback="Week" />
                        </Button>
                        <Button
                            data-testid={prepareTestId(
                                testIdsPeriodModes.monthView,
                                sharableLink,
                                schedulingArea
                            )}
                            ref={monthViewButton}
                            text
                            role="tab"
                            tabIndex={getPeriodBtnTabIndex(isMonthMode)}
                            className={
                                !isMonthMode
                                    ? headerButtonsSelectedStyles
                                    : headerButtonsDisabledStyles
                            }
                            disabled={weekRangeMode}
                            aria-disabled={weekRangeMode || isMonthMode}
                            aria-selected={isMonthMode}
                            onKeyDown={handleKeyDown}
                            onClick={() => handleActivePeriodModeChange(TimeUnit.MONTH)}
                            aria-label={_t['resourcescheduling.switch.to.month.view'](
                                'Switch to month view'
                            )}
                        >
                            <Localization<string> messageKey="month" fallback="Month" />
                        </Button>
                    </div>
                ) : (
                    <ButtonDropdown
                        text
                        className={periodModeDropdownFinalStyles}
                        selector="global_scheduling.period_mode"
                        testID="period_mode"
                        options={periodModeOptions}
                        selectedOptions={[selectedPeriodMode]}
                        onChange={(selectedMode): void =>
                            handleActivePeriodModeChange(selectedMode.value)
                        }
                        onIsOpenChanged={toggleDropDownOpenState}
                        aria-expanded={isOpenedPeriodModeDropdown}
                        aria-haspopup="true"
                        aria-label={`${_t['workloadbalancer.select.timeline.view'](
                            'Select timeline view, Currently selected value:'
                        )} ${selectedPeriodMode.label}`}
                    >
                        {selectedPeriodMode.label}
                    </ButtonDropdown>
                );
            }}
        </Localization>
    );
};

function mapStateToProps(state): IPeriodModeButtonsStateToProps {
    return {
        activePeriodMode: stepUnitSelector(state),
        isUnassignedTableCollapsed: tableOpenedStateSelector(state),
        schedulingArea: getSchedulingAreaData(state).schedulingAreaObjCode,
        userIDs: userIDsSelector(state),
        periodMode: periodModeSelector(state),
        periodTimeUnit: periodTimeUnitSelector(state),
    };
}

export const PeriodModeButtons = connect(mapStateToProps)(PeriodModeButtonsComponent);
