import { createSelector } from 'reselect';
import { IWorkSchedulingCombinedState } from '../../../types';
import {
    INodesDetailsItemState,
    INodesDetailsState,
    IUserAssignmentsState,
    IUserState,
    TAddedUsersByAssignment,
    TProjectID,
    TUserName,
    TUserRoleId,
    TUserRoleIds,
    TUsers,
} from '../../IDataState';
import { tableDataIDsSelector } from '../tableDataIDsSelector';
import { appearingRowsIndexesSelector } from '../appearingRowsIndexesSelector';
import { assignedSectionSliceIndex } from '../../../../constants/dataConstatnts';

export const usersSelector = (state: IWorkSchedulingCombinedState): TUsers => {
    return state.Data.users;
};

export const userByIdSelector = (state: IWorkSchedulingCombinedState, { ...obj }): IUserState => {
    return state.Data.users[obj.userID];
};

export const userNameByIdSelector = (
    state: IWorkSchedulingCombinedState,
    { ...obj }
): TUserName => {
    return state.Data.users[obj.userID].name;
};

export const userProjectNodesByIdSelector = (
    state: IWorkSchedulingCombinedState,
    { ...obj }
): string[] => {
    return state.Data.users[obj.userID].nodes[obj.projectID].nodes;
};

export const userProjectNodeSelector = (
    state: IWorkSchedulingCombinedState,
    { ...obj }
): INodesDetailsItemState => {
    return state.Data.users[obj.userID].nodes[obj.projectID];
};

export const userAllProjectsSelector = (
    state: IWorkSchedulingCombinedState,
    { ...obj }
): TProjectID[] => {
    return state.Data.users[obj.userID].projectIDs;
};

export const userProjectsSelector = (
    state: IWorkSchedulingCombinedState,
    { ...obj }
): INodesDetailsState => {
    return state.Data.users[obj.userID].nodes;
};

export const userInaccessibleProjectsSelector = (
    state: IWorkSchedulingCombinedState,
    { ...obj }
): TProjectID[] => {
    return state.Data.users[obj.userID].inaccessibleProjectIDs;
};

export function userAssignmentsSelector(
    state: IWorkSchedulingCombinedState,
    obj
): IUserAssignmentsState {
    return state.Data.users[obj.userID].assignments;
}

export function userRoleIdsSelector(state: IWorkSchedulingCombinedState, obj): TUserRoleIds {
    return state.Data.users[obj.userID].userRolesIDs;
}

export function userRoleIdSelector(state: IWorkSchedulingCombinedState, obj): TUserRoleId {
    return state.Data.users[obj.userID].roleID;
}

export const getUserSelector = createSelector(
    usersSelector,
    (users): ((id: string) => IUserState) => {
        return (id: string) => users[id];
    }
);

export const userIDsSelector = createSelector(usersSelector, (users): string[] =>
    Object.keys(users)
);

export const getLoadedUsersCount = createSelector(userIDsSelector, (users): number => {
    return users.length;
});

export const selectUsersIDsForRenderedSection = createSelector(
    tableDataIDsSelector,
    appearingRowsIndexesSelector,
    usersSelector,
    (tableDataIDs, renderedIndexes, users) => {
        // getting users
        // which are in rendered section and
        // which are expanded and placed above the renderedStartIndex (need this for scroll place)
        const usersWhichDataNotLoaded: string[] = [];
        const dataIDs = tableDataIDs.slice(assignedSectionSliceIndex);

        const usersAboveRenderedStartIndex = dataIDs
            .slice(0, renderedIndexes.renderedStartIndex)
            .filter((ID) => {
                return (
                    users[ID] &&
                    !users[ID].userDataRequestsState.isRequestSend &&
                    users[ID].expanded
                );
            });

        const renderedUsersIDs = dataIDs.slice(
            renderedIndexes.renderedStartIndex,
            renderedIndexes.renderedStopIndex + 1
        );

        renderedUsersIDs.forEach((userID) => {
            if (users[userID] && !users[userID].userDataRequestsState.isRequestSend) {
                usersWhichDataNotLoaded.push(userID);
            }
        });

        return [...usersWhichDataNotLoaded, ...usersAboveRenderedStartIndex];
    }
);

export const selectExpandedUsersIDs = createSelector(
    usersSelector,
    tableDataIDsSelector,
    (users: { [id: string]: IUserState }) => {
        return (usersIDs: string[]) => {
            return usersIDs.filter((ID) => {
                return !!users[ID] && users[ID].expanded;
            });
        };
    }
);

export const excludedUserIDsForLazyLoadSelector = (state): TAddedUsersByAssignment => {
    return state.Data.addedUsersByAssignment;
};
