import { primary } from '@phoenix/all';
import { css } from 'emotion';
import * as React from 'react';
import { sizes, Sections } from '../../../constants/schedulingTableConstants';
import { PeopleWorkloadSectionLeftCell } from './peopleWorkLoadSection/PeopleWorkloadSectionLeftCell';
import { ShowMore } from './ShowMore';
import { UnassignedWorkSectionLeftCell } from './unassignedSection/UnassignedWorkSectionLeftCell';
import {
    THandleProjectArrowClick,
    TOnUnassignedProjectArrowClick,
    TOnUserArrowClick,
} from '../../../data-flow/data/IDataState';
import { TShowProjectAndOrTasks } from '../../../data-flow/IGeneralStateTypes';

export const leftCellEmptyRow = css`
    background-color: ${primary.white()};
    flex: 0 0 ${sizes.tableLeftColumnWidth}px;
    overflow: hidden;
`;

export const leftCell = css`
    ${leftCellEmptyRow};
    text-overflow: ellipsis;
    white-space: nowrap;
`;

interface ICellLeftProps extends React.HTMLAttributes<HTMLDivElement> {
    idExpression: string;
    onUnassignedProjectArrowClick: TOnUnassignedProjectArrowClick;
    sectionType: Sections;
    hasDataIDs: boolean;
    isInLoadingState: boolean;
    showProjectAndOrTasks: TShowProjectAndOrTasks;
    handleProjectArrowClick: THandleProjectArrowClick;
    onUserArrowClick: TOnUserArrowClick;
    idsArray?: string[];
}

export const LeftCell: React.FunctionComponent<ICellLeftProps> = React.memo((props) => {
    const {
        idExpression,
        onUnassignedProjectArrowClick,
        sectionType,
        isInLoadingState,
        showProjectAndOrTasks,
        handleProjectArrowClick,
        onUserArrowClick,
    } = props;
    let component;

    const className = !idExpression && !isInLoadingState ? leftCellEmptyRow : leftCell;

    if (idExpression && idExpression.indexOf('showMore') !== -1) {
        const expressionWithoutShowMore = idExpression.substring(0, idExpression.length - 9);
        const idExpressionArr = expressionWithoutShowMore.split('_');
        component = (
            <ShowMore
                idExpression={idExpression}
                idExpressionArr={expressionWithoutShowMore.split('_')}
                expressionWithoutShowMore={expressionWithoutShowMore}
                idExpressionLength={idExpressionArr.length}
                sectionType={sectionType}
            />
        );
    } else if (sectionType === Sections.UNASSIGNED_WORK) {
        component = (
            <UnassignedWorkSectionLeftCell
                idExpression={idExpression}
                onUnassignedProjectArrowClick={onUnassignedProjectArrowClick}
                hasDataIDs={props.hasDataIDs}
                idsArray={props.idsArray}
            />
        );
    } else {
        component = (
            <PeopleWorkloadSectionLeftCell
                idExpression={idExpression}
                showProjectAndOrTasks={showProjectAndOrTasks}
                handleProjectArrowClick={handleProjectArrowClick}
                onUserArrowClick={onUserArrowClick}
            />
        );
    }

    return (
        <div
            style={{ height: 'inherit' }}
            role="rowheader"
            className={className}
            data-testid="left-cell"
            id={`left_cell_${idExpression}`}
        >
            {component}
        </div>
    );
});
