import { Tooltip } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import HelpIcon from 'phoenix-icons/dist/HelpIcon.js';
import * as React from 'react';
import { APIService } from '../../../services/api-services/apiService';
import { rightPadding } from '../../styles/sharedStyles';

export class ContextSensitiveHelp extends React.Component<
    Record<string, never>,
    { contextSensitiveLink: string }
> {
    constructor(props) {
        super(props);
        this.state = {
            contextSensitiveLink: '',
        };
    }

    componentDidMount(): void {
        APIService.api
            .namedQuery('CXTHP', 'helpLinkByPage', { pageID: 'csh-content-workload-balancer' }, [
                'url',
            ])
            .then((data) => {
                this.setState({ contextSensitiveLink: data[0].url });
            })
            .catch(() => {
                this.setState({
                    contextSensitiveLink:
                        'https://experienceleague.adobe.com/docs/workfront/using/manage-resources/the-workload-balancer/overview-workload-balancer.html',
                });
            });
    }

    // now the links of new experience is hard coded as workload balancer doesn't have appropriate link in back office
    render(): JSX.Element {
        return (
            <Localization<string[]> messageKeys={['resourcescheduling.context.sensitive.help']}>
                {({ _t }) => {
                    return (
                        <Tooltip
                            content={_t['resourcescheduling.context.sensitive.help'](
                                'Help on the Workload Balancer'
                            )}
                        >
                            <a
                                href={this.state.contextSensitiveLink}
                                data-testid="context-sensitive-help"
                                target="_blank"
                                rel="noreferrer"
                                className={rightPadding}
                                aria-label={_t['resourcescheduling.context.sensitive.help'](
                                    'Help on the Workload Balancer'
                                )}
                            >
                                <HelpIcon focusable={false} />
                            </a>
                        </Tooltip>
                    );
                }}
            </Localization>
        );
    }
}
