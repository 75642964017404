import { primary } from '@phoenix/all';
import * as React from 'react';
import styled, { css } from 'react-emotion';
import { animationStyle } from '../../../styles/sharedStyles';

export interface IUserLoading {
    isLazyLoaded?: boolean;
}

export const UserLoading: React.FunctionComponent<IUserLoading> = (props) => {
    return (
        <WrapperDivStyled isLazyLoaded={props.isLazyLoaded} data-testid="left-cell-user-loading">
            <span className={avatarLoadingGrey} />
            <div className={divWrapper}>
                <DivContentGrey width={props.isLazyLoaded ? 194 : 210} />
                <DivContentGrey width={90} />
            </div>
        </WrapperDivStyled>
    );
};

interface IWrapperDivStyled {
    isLazyLoaded?: boolean;
}

const WrapperDivStyled = styled('div')<IWrapperDivStyled>`
    height: 100%;
    align-items: center;
    display: flex;
    position: relative;
    padding-left: ${(props) => (props.isLazyLoaded ? '37px' : '16px')};
    padding-top: 6px;
    padding-bottom: 5px;
    border-bottom: 1px solid ${primary.gray(200)};
`;

const avatarLoadingGrey = css`
    display: inline-flex;
    position: relative;
    height: 32px;
    width: 32px;
    background-color: ${primary.gray(200)};
    border-radius: 50%;
    ${animationStyle}
`;

const divWrapper = css`
    display: flex;
    flex-direction: column;
`;

const DivContentGrey = styled('div')<any>`
    width: ${(props) => props.width}px;
    height: 8px;
    margin: 6px 0 0 13px;
    background-color: ${primary.gray(200)};
    border-radius: 3px;
    ${animationStyle}
`;
