import { IDataState, RoleSummaryKeys } from './IDataState';
import {
    assignedSectionHeaderDataID,
    unassignedSectionHeaderDataID,
} from '../../constants/dataConstatnts';

export const initialEditedWorkPerDays = {
    userID: null,
    assignmentID: null,
    data: {},
};

export function getInitialDataState(): IDataState {
    const tablesState = {
        tableDataIDs: assignedSectionHeaderDataID,
        tableDataIDsForUnassignedSection: unassignedSectionHeaderDataID,
    };

    return {
        users: {},
        usersOnProject: [],
        addedUsersByAssignment: [],
        ...tablesState,
        objectAssignmentDetails: {},
        objectDelegationDetails: {},
        roles: {},
        hasMoreUsers: false,
        hasMoreUnassignedTasks: false,
        tasksAndIssues: {},
        unassignedTasks: {},
        projects: {},
        projectGroupColors: {},
        isInLoadingStatePeopleWorkloadSection: false,
        isInLoadingStateUnassignedWorkSection: false,
        schedulesWorkDays: {},
        defaultScheduleID: '',
        appearingRowIndexes: {
            renderedStartIndex: 0,
            renderedStopIndex: 10,
        },
        expandedUnassignedProjectIDs: new Set<string>(),
        unassignedTasksProjects: {},
        isUnassignedTasksProjectsRequestSend: false,
        isUnassignedTasksRequestSend: false,
        unassignedWorkEmptyRowHeight: 0,
        dropdownOpened: null,
        peopleWorkloadEmptyRowHeight: false,
        workPerDaysByAssignments: {},
        workPerDaysByAssignmentsFromService: {},
        contouredAssignments: {},
        temporaryWorkPerDays: initialEditedWorkPerDays,
        workPerDaysOnDragHover: {},
        objectsInAssignmentMode: null,
        IDExpressionForMinixState: null,
        inHighlightingModeUsersAfterAssignment: {
            user: {},
            object: {},
        },
        assignmentDialogDetails: {
            ID: null,
            positionX: 0,
            positionY: 0,
            showLoading: false,
        },
        hasProjectLifeAfterDeath: false,
        contouringRowState: {
            contouringRowID: null,
            contouringCellInfo: undefined,
        },
        roleSummaryData: {
            [RoleSummaryKeys.TOTAL]: [],
        },
        roleSummaryLoading: {
            [RoleSummaryKeys.TOTAL]: false,
            [RoleSummaryKeys.MONTHLY]: false,
        },
        roleSummaryActiveKeys: [RoleSummaryKeys.TOTAL],
        espProductEnabled: false,
        draggingFromArea: null,
        loadWorkDelegations: false,
        calculateFTEAutomatic: true,
    };
}
