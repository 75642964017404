import { primary } from '@phoenix/all';
import * as React from 'react';
import { css, cx } from 'react-emotion';
import { connect } from 'react-redux';
import { IDateRangeState } from '../../../data-flow/dateRange/IDateRangeState';
import { stepsSelector } from '../../../data-flow/dateRange/selectors/stepsSelector';
import { IWorkSchedulingCombinedState } from '../../../data-flow/types';
import {
    animationStyle,
    cell,
    cellWrapper,
    userRowStyleOnAllocationVisualization,
} from '../../styles/sharedStyles';
import { showAllocationsVisualizationSelector } from '../../../data-flow/settings/settingsSelector';

interface ITaskLoadingComponentStateToProps extends Pick<IDateRangeState, 'steps'> {
    isAllocationsVisualizationOn: boolean;
}

const AssignmentLoadingComponent: React.FunctionComponent<ITaskLoadingComponentStateToProps> =
    React.memo((props) => {
        const { steps, isAllocationsVisualizationOn } = props;

        return (
            <React.Fragment>
                <div
                    className={loadingTaskStyledGrey}
                    data-testid="right-cell-task/project-loading"
                />
                <div className={cellWrapper}>
                    {steps.map((step) => (
                        <div
                            className={
                                isAllocationsVisualizationOn
                                    ? cx(
                                          userRowStyleOnAllocationVisualization(false),
                                          'user-row-style-on-allocation-visualization-selector'
                                      )
                                    : cell()
                            }
                            key={step.unix()}
                        />
                    ))}
                </div>
            </React.Fragment>
        );
    });

const loadingTaskStyledGrey = css`
    height: 26px;
    width: 100%;
    border-radius: 3px;
    background-color: ${primary.gray(200)};
    margin: 0 16px;
    z-index: 2;
    ${animationStyle}
`;

const mapStateToProps = (
    state: IWorkSchedulingCombinedState
): ITaskLoadingComponentStateToProps => ({
    steps: stepsSelector(state),
    isAllocationsVisualizationOn: showAllocationsVisualizationSelector(state),
});

export const AssignmentLoading = connect(mapStateToProps)(AssignmentLoadingComponent);
