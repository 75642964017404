import { TModernSchedulingAction } from '../../types';
import { IFiltersState } from '../IFiltersState';
import { Sections } from '../../../constants/schedulingTableConstants';

export const ActionType = 'RENAME_FILTER';

interface IAddFiltersActionPayload {
    filterName: string | undefined;
    section: string;
}

type TAddFiltersAction = TModernSchedulingAction<IAddFiltersActionPayload>;

const renameFilter = (filterName: string | undefined, section: Sections): TAddFiltersAction => ({
    type: ActionType,
    payload: {
        filterName,
        section,
    },
});
export default renameFilter;

export function handle(state: IFiltersState, { payload }: TAddFiltersAction): IFiltersState {
    const { filterName, section } = payload;

    return {
        ...state,
        [section]: {
            ...state[section],
            filterName,
        },
    };
}
