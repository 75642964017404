import { IUnassignedAssignment } from '../data-flow/data/IDataState';
import { IAssignmentsObjects } from './dataStructures/IAssignmentaObjects';

export const getUnassignedTasksInfo = (
    unassignedTasks: IUnassignedAssignment[]
): [IAssignmentsObjects[], IAssignmentsObjects[]] => {
    const unassignedTasksAssignmentsIDs = 0;
    const unassignedTasksWithNoAssignments = 1;

    return unassignedTasks.reduce(
        (acc: [IAssignmentsObjects[], IAssignmentsObjects[]], unassignedTask) => {
            if (!unassignedTask.assignments?.length) {
                acc[unassignedTasksWithNoAssignments].push({
                    objID: unassignedTask.ID,
                    objCode: unassignedTask.objCode,
                });
            }
            unassignedTask.assignments.forEach((assignment) => {
                if (assignment.assignedToID === null) {
                    acc[unassignedTasksAssignmentsIDs].push({
                        objID: assignment.ID,
                        objCode: assignment.objCode,
                    });
                }
            });

            return acc;
        },
        [[], []]
    );
};
