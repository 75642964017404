import { useDragLayer, XYCoord } from 'react-dnd';
import React, { useMemo } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { css } from 'react-emotion';
import {
    calculateAssignmentWidth,
    calcWidthAndLeftPosition,
    isAfterPeriod,
    isBeforePeriod,
    shouldBeArrow,
} from '../../util/durationStylesCalculation';
import { Sections, sizes } from '../../constants/schedulingTableConstants';
import { getIsDraggingEnabled } from '../../util/dragAndDropUtilities';
import { IWorkSchedulingCombinedState } from '../../data-flow/types';
import { getUnassignedTaskSelector } from '../../data-flow/data/selectors/reselect/getUnassignedTaskSelector/getUnassignedTaskSelector';
import { getTaskSelector } from '../../data-flow/data/selectors/reselect/getTaskSelector/getTaskSelector';
import { stepUnitSelector } from '../../data-flow/dateRange/selectors/stepUnitSelector';
import { cellWidthSelector } from '../../data-flow/tableSizes/selectors/cellWidthSelector';
import { endDateSelector } from '../../data-flow/dateRange/selectors/endDateSelector';
import { startDateSelector } from '../../data-flow/dateRange/selectors/startDateSelector';
import { AssignmentBarSkeleton } from '../schedulingTable/right/AssignmentBarSkeleton';
import { DragDropTypes } from '../../dragAndDrop/dragAndDropConfigurations';
import {
    IObjectState,
    IProjectColor,
    IUnassignedTaskState,
    TProjectColorsMode,
    TProjectID,
} from '../../data-flow/data/IDataState';
import { IGeneralStateTypes } from '../../data-flow/IGeneralStateTypes';
import { getContextValue } from '../../contexts/checkContext';
import { projectColorsModeSelector } from '../../data-flow/settings/settingsSelector';
import { isColorModeProject, isColorModeProjectStatus } from '../../util/colorUtils';
import { projectColorCachedSelector } from '../../data-flow/projectColors/selectors/projectColorSelector/projectColorSelector';
import { getProjectStatusSelector } from '../../data-flow/data/selectors/projectStatusSelectors/projectStatusSelectors';

type TDragCoordinates = XYCoord | null;

interface IDraggableItemStyles {
    willChange?: string;
    display?: string;
    transform?: string;
}

const getDraggableItemStyles = (
    initialOffset: TDragCoordinates,
    currentOffset: TDragCoordinates,
    clientOffset: TDragCoordinates
): IDraggableItemStyles => {
    if (!initialOffset || !currentOffset || !clientOffset) {
        return {
            display: 'none',
        };
    }
    const { x, y } = currentOffset;
    const diffOfMousePointerAndAssignmentStartEdge = clientOffset.x - x;

    const transform = `translate3d(${x + diffOfMousePointerAndAssignmentStartEdge}px, ${y}px, 0px)`;

    return {
        willChange: 'translate3d',
        transform,
    };
};

const layerStyles = css`
    position: fixed;
    pointer-events: none;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

export const customLayerDataTestIds = {
    customLayerContainer: 'custom-layer-container',
};

interface ICustomDragLayerProps
    extends Pick<IGeneralStateTypes, 'stepUnit' | 'endDate' | 'startDate'> {
    getUnassignedTask: (id: string) => IUnassignedTaskState;
    getAssignedTask: (id: string) => IObjectState;
    cellWidth: number;
    getProjectStatusColor: (
        key: string,
        projectGroupId?: string,
        projectStatus?: string
    ) => IProjectColor | undefined;
    getProjectColor: (projectID: TProjectID) => IProjectColor | undefined;
    colorMode: TProjectColorsMode;
}

export const CustomDragLayerComponent: React.FunctionComponent<ICustomDragLayerProps> = (props) => {
    const { item, itemType, initialOffset, currentOffset, clientOffset, isDragging } = useDragLayer(
        (monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            clientOffset: monitor.getClientOffset(),
            isDragging: monitor.isDragging(),
        })
    );

    const renderItem = useMemo(() => {
        if (itemType === DragDropTypes.ASSIGNMENT) {
            const {
                startDate,
                endDate,
                cellWidth,
                stepUnit,
                getUnassignedTask,
                getAssignedTask,
                colorMode,
                getProjectStatusColor,
                getProjectColor,
            } = props;
            const { durationPadding, assignmentArrowSize } = sizes;
            const { ID, sectionType } = item;
            let nodeData;
            if (sectionType === Sections.UNASSIGNED_WORK) {
                nodeData = getUnassignedTask(ID);
            } else {
                nodeData = getAssignedTask(ID);
            }

            const { assignAccess, plannedStartDate, plannedCompletionDate, actualCompletionDate } =
                nodeData;

            const assignmentStartDate = moment(plannedStartDate);
            const assignmentEndDate = moment(plannedCompletionDate);

            const [assignmentWidth] = calcWidthAndLeftPosition(
                stepUnit,
                startDate,
                endDate,
                assignmentStartDate,
                assignmentEndDate,
                cellWidth,
                durationPadding
            );

            const isThereBeforeArrow = shouldBeArrow(
                assignmentWidth,
                isBeforePeriod(stepUnit, startDate, assignmentStartDate)
            );
            const isThereAfterArrow = shouldBeArrow(
                assignmentWidth,
                isAfterPeriod(stepUnit, endDate, assignmentEndDate)
            );

            const calculatedAssignmentWidth = calculateAssignmentWidth(
                isThereBeforeArrow,
                isThereAfterArrow,
                assignmentWidth,
                assignmentArrowSize
            );
            const shouldMarkAsCompleted: boolean = !!actualCompletionDate;

            let projectColoredData;
            if (isColorModeProjectStatus(colorMode)) {
                projectColoredData = getProjectStatusColor(
                    'colors',
                    nodeData.projectGroupID,
                    nodeData.projectStatus
                );
            } else if (isColorModeProject(colorMode)) {
                projectColoredData = getProjectColor(nodeData.projectID);
            }

            const isDraggingEnabled = getIsDraggingEnabled(
                assignAccess,
                getContextValue('sharableLink')
            );

            const assignmentBarProps = {
                isAssigned: sectionType === Sections.PEOPLE_WORKLOAD,
                isInDisableMode: false,
                nodeData,
                endDate,
                assignmentWidth,
                stepUnit,
                projectColoredData,
                sectionType,
                calculatedAssignmentWidth,
                ID,
                shouldMarkAsCompleted,
                isThereBeforeArrow,
                isThereAfterArrow,
                isDraggingEnabled,
            };

            return <AssignmentBarSkeleton {...assignmentBarProps} />;
        }
        return null;
    }, [itemType, item, props]);

    if (!isDragging) {
        return null;
    }

    return (
        <div className={layerStyles} data-testid={customLayerDataTestIds.customLayerContainer}>
            <div style={getDraggableItemStyles(initialOffset, currentOffset, clientOffset)}>
                {renderItem}
            </div>
        </div>
    );
};

const mapStateToProps = (state: IWorkSchedulingCombinedState): ICustomDragLayerProps => {
    return {
        stepUnit: stepUnitSelector(state),
        cellWidth: cellWidthSelector(state),
        endDate: endDateSelector(state),
        startDate: startDateSelector(state),
        getUnassignedTask: getUnassignedTaskSelector(state),
        getAssignedTask: getTaskSelector(state),
        colorMode: projectColorsModeSelector(state),
        getProjectStatusColor: getProjectStatusSelector(state),
        getProjectColor: (projectID: TProjectID): IProjectColor | undefined =>
            projectColorCachedSelector(state, {
                projectID,
            }),
    };
};

export const CustomDragLayer = connect(mapStateToProps)(CustomDragLayerComponent);
