import * as React from 'react';
import { connect } from 'react-redux';
import {
    ACCESSIBLE_PROJECT_PRIMARY_DARK_BACKGROUND_COLOR,
    ACCESSIBLE_PROJECT_PRIMARY_LIGHT_BACKGROUND_COLOR,
    ACCESSIBLE_PROJECT_PRIMARY_TEXT_COLOR,
} from '../../../../constants/colors';
import {
    IProjectColor,
    IStartEndDates,
    TOrUndefined,
    TWorkPerDays,
} from '../../../../data-flow/data/IDataState';
import { IWorkSchedulingCombinedState } from '../../../../data-flow/types';
import { afterProject, beforeProject } from '../../../styles/sharedStyles';
import { AssignmentDuration } from '../AssignmentDuration';
import { Sections } from '../../../../constants/schedulingTableConstants';
import { showAllocationsSelector } from '../../../../data-flow/settings/settingsSelector';
import { projectDurationForUnassignedSectionSelector } from '../../../../data-flow/data/selectors/durations/durationsSelector';
import { workPerDaysSelectorForPeriodUnassignedSectionService } from '../../../../data-flow/data/selectors/rereselect/workPerDaysSelectorForPeriodUnassignedSectionService';
import { projectColorDeciderSelector } from '../../../../data-flow/data/selectors/projectStatusSelectors/projectStatusSelectors';
import { getUnassignedTasksProjectSelector } from '../../../../data-flow/data/selectors/unassignedTasksProjectsSelectors/unassignedTasksProjectsSelectors';

interface IProjectAssignmentProps {
    idExpression: string;
    isAssigned: boolean;
    sectionType: Sections;
}

interface IProjectAssignmentMapStateToProps {
    isCompleted: boolean;
    durations: IStartEndDates[];
    name: string;
    expanded: boolean;
    workPerDays: TOrUndefined<TWorkPerDays>;
    isAccessible: boolean;
    scheduleID?: string;
    projectColoredData?: IProjectColor;
}

export const ProjectAssignmentComponent: React.FunctionComponent<
    IProjectAssignmentProps & IProjectAssignmentMapStateToProps
> = (props) => {
    const { projectColoredData } = props;

    const colorDark = ACCESSIBLE_PROJECT_PRIMARY_DARK_BACKGROUND_COLOR;
    const colorLight = ACCESSIBLE_PROJECT_PRIMARY_LIGHT_BACKGROUND_COLOR;
    const textColor = projectColoredData?.textColor
        ? projectColoredData.textColor
        : ACCESSIBLE_PROJECT_PRIMARY_TEXT_COLOR;

    const leftArrowCssClass = beforeProject(projectColoredData?.RGB);
    const rightArrowCssClass = afterProject(projectColoredData?.RGB);

    return (
        <AssignmentDuration
            idExpression={props.idExpression}
            durations={props.durations}
            isProject
            isAccessible={props.isAccessible}
            isCompleted={props.isCompleted}
            name={props.name}
            colorDark={colorDark}
            colorLight={colorLight}
            textColor={textColor}
            rightArrowCssClass={rightArrowCssClass}
            leftArrowCssClass={leftArrowCssClass}
            isAssigned={props.isAssigned}
            sectionType={props.sectionType}
            workPerDays={props.workPerDays}
            scheduleID={props.scheduleID!}
            projectColoredData={projectColoredData}
        />
    );
};

function mapStateToProps(
    state: IWorkSchedulingCombinedState,
    ownProps
): IProjectAssignmentMapStateToProps {
    const idsArray = ownProps.idExpression.split('_');
    const nodeData = getUnassignedTasksProjectSelector(state, { projectID: idsArray[0] });
    const isShowAllocationOn = showAllocationsSelector(state);

    let workPerDays: TOrUndefined<TWorkPerDays>;

    const durationData = projectDurationForUnassignedSectionSelector(state, {
        projectID: idsArray[0],
    });

    if (isShowAllocationOn) {
        workPerDays = workPerDaysSelectorForPeriodUnassignedSectionService(state, {
            projectID: idsArray[0],
        });
    }

    const projectColoredData = projectColorDeciderSelector(
        state,
        nodeData.details.ID,
        nodeData.details.groupID,
        nodeData.details.status
    );

    return {
        isCompleted: durationData.isCompleted,
        durations: durationData.durations,
        name: nodeData.details.name,
        expanded: nodeData.details.expanded,
        workPerDays,
        isAccessible: true,
        scheduleID: nodeData.details.scheduleID,
        projectColoredData,
    };
}

export const ProjectAssignment = connect(mapStateToProps)(ProjectAssignmentComponent);
