import { TModernSchedulingAction } from '../../types';
import { IDataState, TUserID } from '../IDataState';

export const ActionType = 'CHANGE_ALL_NODE_ARROW_STATE';

interface IChangeAllNodeArrowStatePayload {
    isOneExpanded: boolean;
    usersIds?: TUserID[];
}

type TChangeNodeArrowStateAction = TModernSchedulingAction<IChangeAllNodeArrowStatePayload>;

const changeAllNodeArrowState = (
    isOneExpanded = false,
    usersIds: TUserID[] = []
): TChangeNodeArrowStateAction => ({
    type: ActionType,
    payload: {
        isOneExpanded,
        usersIds,
    },
});
export default changeAllNodeArrowState;

export function handle(state: IDataState, { payload }: TChangeNodeArrowStateAction): IDataState {
    const users = { ...state.users };
    const { isOneExpanded, usersIds = [] } = payload;
    const userKeys = usersIds.length ? usersIds : Object.keys(users);

    for (const id of userKeys) {
        users[id] = {
            ...users[id],
            expanded: !isOneExpanded,
        };
    }

    return {
        ...state,
        users,
    };
}
