import { Action } from 'redux';
import { createReducer } from '../createReducer';
import * as changeCollapsedMode from './actions/changeCollapsedMode';
import * as changeDraggableMode from './actions/changeDraggableMode';
import * as changeResizableHeight from './actions/changeResizableHeight';
import * as changeSnapToZero from './actions/changeSnapToZero';
import * as setAvailableHeight from './actions/setAvailableHeight';
import * as setTableWidth from './actions/setTableWidth';
import * as setWorkTablesHeights from './actions/setWorkTablesHeights';
import { ITableSizesState } from './ITableSizesState';
import * as setTableLeftSidePanelWidth from './actions/setTableLeftSidePanelWidth';

export function getTableSizesReducer(
    initialState: ITableSizesState
): (state: ITableSizesState | undefined, action: Action) => ITableSizesState {
    return createReducer(
        [
            setTableWidth,
            changeDraggableMode,
            changeSnapToZero,
            setWorkTablesHeights,
            changeResizableHeight,
            setAvailableHeight,
            changeCollapsedMode,
            setTableLeftSidePanelWidth,
        ],
        initialState
    );
}
