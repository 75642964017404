import { TModernSchedulingAction } from '../../types';
import { IFiltersState } from '../IFiltersState';

export const ActionType = 'ADD_FILTER_ID';

interface IAddFilterIDActionPayload {
    filterID: string;
    section: string;
}

export type TAddFilterIDAction = TModernSchedulingAction<IAddFilterIDActionPayload>;

const addFilterID = (filterID: string, section: string): TAddFilterIDAction => ({
    type: ActionType,
    payload: {
        filterID,
        section,
    },
});
export default addFilterID;

export function handle(state: IFiltersState, { payload }: TAddFilterIDAction): IFiltersState {
    const { filterID, section } = payload;

    return {
        ...state,
        [section]: {
            ...state[section],
            filterID,
        },
    };
}
