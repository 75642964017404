import { primary } from '@phoenix/all';
import { getRGBAColors, transformRGBAColorKey } from '../util/utilities';

const primary_blue_400 = primary.blue(400); // #0D66D0
const hex_F5FAFD = '#F5FAFD';
const hex_F1F8FD = '#F1F8FD';
const hex_E5F2FC = '#E5F2FC';
const hex_CB6C79 = '#CB6C79';
const hex_79A6D7 = '#79A6D7';
const hex_C7E1F6 = '#C7E1F6'; // rgb(199, 225, 246)
const hex_DBECF9 = '#DBECF9'; // rgb(219, 236, 249)
const hex_DC4042 = '#DC4042'; // rgb(220, 64, 66)
const hex_E06264 = '#E06264'; // rgb(224, 98, 100)
const hex_CD4C55 = '#CD4C55'; // rgb(224, 98, 100)
export const hex_E0E1E3 = '#E0E1E3';
export const hex_ECECED = '#ECECED';
export const hex_E6F1FB = '#E6F1FB';
export const hex_B3D6F3 = '#B3D6F3';
const hex_3393DF = '#3393DF';
const hex_0060ac = '#0060ac';
const hex_E7C3D0 = '#E7C3D0';
const hex_B13764 = '#B13764';
const primary_blue_100_hex = primary.blue(100); // #B3D6F3
const primary_blue_500_hex = primary.blue(500);
const primary_gray_300_hex = primary.gray(300);
const primary_gray_0_hex = primary.gray(0);

const colorsToTransformInoRGBA = [
    { hex: hex_E0E1E3, opacity: 30 },
    { hex: hex_E0E1E3, opacity: 50 },
    { hex: hex_E0E1E3, opacity: 80 },
    { hex: hex_ECECED, opacity: 18 },
    { hex: hex_E6F1FB, opacity: 60 },
    { hex: hex_B3D6F3, opacity: 50 },
    { hex: hex_B3D6F3, opacity: 25 },
    { hex: hex_B3D6F3, opacity: 15 },
    { hex: hex_B3D6F3, opacity: 35 },
    { hex: primary_blue_400, opacity: 63 },
    { hex: hex_CD4C55, opacity: 80 },
    { hex: hex_0060ac, opacity: 35 },
    { hex: hex_0060ac, opacity: 15 },
    { hex: hex_3393DF, opacity: 15 },
    { hex: hex_3393DF, opacity: 35 },
    { hex: hex_E7C3D0, opacity: 15 },
    { hex: hex_E7C3D0, opacity: 35 },
    { hex: hex_B13764, opacity: 15 },
    { hex: hex_B13764, opacity: 35 },
    { hex: primary_blue_500_hex, opacity: 15 },
    { hex: primary_blue_500_hex, opacity: 35 },
    { hex: primary_gray_300_hex, opacity: 15 },
    { hex: primary_gray_300_hex, opacity: 35 },
    { hex: primary_gray_0_hex, opacity: 90 },
    { hex: hex_E5F2FC, opacity: 100 },
    { hex: hex_E5F2FC, opacity: 50 },
    { hex: hex_C7E1F6, opacity: 74 },
    { hex: hex_DBECF9, opacity: 62 },
    { hex: hex_DC4042, opacity: 51 },
    { hex: hex_DC4042, opacity: 71 },
    { hex: hex_E06264, opacity: 57 },
    { hex: primary_blue_100_hex, opacity: 100 },
    { hex: primary_blue_100_hex, opacity: 50 },
    { hex: primary_blue_100_hex, opacity: 69 },
    { hex: primary_blue_100_hex, opacity: 82 },
];

export const RGBA_COLORS = getRGBAColors(colorsToTransformInoRGBA);

const appColors = {
    underAllocated: {
        hex: primary_blue_400,
        '0.63': RGBA_COLORS[transformRGBAColorKey(primary_blue_400, 63)],
    },

    primary_blue_400: {
        hex: primary_blue_400,
    },

    overAllocated: {
        hex: hex_CD4C55,
        '0.8': RGBA_COLORS[transformRGBAColorKey(hex_CD4C55, 80)],
    },

    F5FAFD: {
        hex: hex_F5FAFD,
    },
    F1F8FD: {
        hex: hex_F1F8FD,
    },
    CB6C79: {
        hex: hex_CB6C79,
    },
    '79A6D7': {
        hex: hex_79A6D7,
    },
    E5F2FC: {
        hex: hex_E5F2FC,
        rgb: RGBA_COLORS[transformRGBAColorKey(hex_E5F2FC, 100)],
        '0.5': RGBA_COLORS[transformRGBAColorKey(hex_E5F2FC, 50)],
    },
    C7E1F6: {
        hex: hex_C7E1F6,
        '0.74': RGBA_COLORS[transformRGBAColorKey(hex_C7E1F6, 74)],
    },
    DBECF9: {
        hex: hex_DBECF9,
        '0.62': RGBA_COLORS[transformRGBAColorKey(hex_DBECF9, 62)],
    },
    DC4042: {
        hex: hex_DC4042,
        '0.51': RGBA_COLORS[transformRGBAColorKey(hex_DC4042, 51)],
        '0.71': RGBA_COLORS[transformRGBAColorKey(hex_DC4042, 71)],
    },
    E06264: {
        hex: hex_E06264,
        '0.57': RGBA_COLORS[transformRGBAColorKey(hex_E06264, 57)],
    },
    primary_blue_100: {
        hex: primary_blue_100_hex,
        rgb: RGBA_COLORS[transformRGBAColorKey(primary_blue_100_hex, 100)],
        '0.5': RGBA_COLORS[transformRGBAColorKey(primary_blue_100_hex, 50)],
        '0.69': RGBA_COLORS[transformRGBAColorKey(primary_blue_100_hex, 69)],
        '0.82': RGBA_COLORS[transformRGBAColorKey(primary_blue_100_hex, 82)],
    },
    primary_gray_0: {
        '0.9': RGBA_COLORS[transformRGBAColorKey(primary_gray_0_hex, 90)],
    },
    visualizationOnItemHover: {
        rgb: `rgba(0, 35, 64)`,
        '0.16': `rgba(0, 35, 64, 0.16) `,
    },
};

export const dragOverlayBackground = appColors.primary_gray_0['0.9'];

export default appColors;
