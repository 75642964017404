import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { userAssignmentsSelector } from '../../users/usersSelector';
import { tasksAndIssuesSelector } from '../../dataSelectors';

export const tasksFromAssignmentsSelector = createCachedSelector(
    [userAssignmentsSelector, tasksAndIssuesSelector],
    (assignments, tasks) => {
        const userTasks: any = {};
        _.forEach(assignments, (taskID) => {
            userTasks[taskID] = tasks[taskID];
        });
        return userTasks;
    }
)((state, { userID }) => `${userID}`);
