import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'SET_APPEARING_ROWS_INDEXES';

export interface ISetAppearingRowIndexesPayload {
    indexes: {
        rowStartIndex: number;
        rowStopIndex: number;
    };
}

type TSetAppearingRowIndexesAction = TModernSchedulingAction<ISetAppearingRowIndexesPayload>;

const setRenderedStartStopIndexes = (indexes): TSetAppearingRowIndexesAction => ({
    type: ActionType,
    payload: {
        indexes,
    },
});
export default setRenderedStartStopIndexes;

export function handle(state: IDataState, { payload }: TSetAppearingRowIndexesAction): IDataState {
    const { rowStartIndex, rowStopIndex } = payload.indexes;
    return {
        ...state,
        appearingRowIndexes: {
            renderedStartIndex: rowStartIndex,
            renderedStopIndex: rowStopIndex,
        },
    };
}
