import moment, { Moment } from 'moment';
import { sizes } from '../constants/schedulingTableConstants';
import { TDate } from '../data-flow/data/IDataState';
import { UnitOfTime } from '../data-flow/types';
import { getPeriodModesValues } from './periodUtils/periodUtils';

export const isBeforePeriod = (stepUnit, startDate, assignmentStartDate) => {
    return assignmentStartDate
        .clone()
        .startOf(stepUnit)
        .isBefore(startDate.clone().startOf(stepUnit));
};

export const isAfterPeriod = (stepUnit, endDate, assignmentEndDate) => {
    return assignmentEndDate.clone().endOf(stepUnit).isAfter(endDate.clone().endOf(stepUnit));
};

export const isInDateRange = (
    dateRangeStart: Moment,
    dateRangeEnd: Moment,
    itemStartDate: TDate,
    itemEndDate: TDate,
    stepUnit
) => {
    const itemStartDateMoment = moment(itemStartDate).startOf(stepUnit);
    const itemEndDateMoment = moment(itemEndDate).endOf(stepUnit);

    return (
        itemEndDateMoment.isSameOrAfter(dateRangeStart) &&
        itemStartDateMoment.isSameOrBefore(dateRangeEnd)
    );
};
/**
 *
 * @param assignmentWidth
 * @param isBeforeOrAfterPeriod
 */
export const shouldBeArrow = (assignmentWidth, isBeforeOrAfterPeriod) => {
    return assignmentWidth > 0 && isBeforeOrAfterPeriod;
};

export const isInLastTreeCells = (stepUnit, endDate, assignmentStartDate) => {
    return assignmentStartDate.clone().endOf(stepUnit).isAfter(endDate.clone().endOf(stepUnit));
};

const getEndOfGivenDate = (date, stepUnit) => {
    return date.clone().add(1, stepUnit).endOf(stepUnit);
};

export const calculateWidth = (
    stepUnit,
    startDate,
    endDate,
    assignmentStartDate,
    assignmentEndDate,
    cellWidth
) => {
    const isBeforeStartDate = isBeforePeriod(stepUnit, startDate, assignmentStartDate);
    const isAfterEndPeriod = isAfterPeriod(stepUnit, endDate, assignmentEndDate);

    const isCompletelyBeforeStartDate = isBeforePeriod(stepUnit, startDate, assignmentEndDate);
    const isCompletelyAfterStartDate = isAfterPeriod(stepUnit, endDate, assignmentStartDate);

    // this check needs for the cases, when projected is in the period, but planned is far from,
    // so we do not need to show that
    if (isCompletelyBeforeStartDate || isCompletelyAfterStartDate) {
        return 0;
    }

    let diff;
    if (isBeforeStartDate && isAfterEndPeriod) {
        diff = startDate.startOf(stepUnit).diff(getEndOfGivenDate(endDate, stepUnit), stepUnit);
    } else if (isBeforeStartDate && !isAfterEndPeriod) {
        diff = startDate
            .startOf(stepUnit)
            .diff(getEndOfGivenDate(assignmentEndDate, stepUnit), stepUnit);
    } else if (isAfterEndPeriod && !isBeforeStartDate) {
        diff = assignmentStartDate
            .startOf(stepUnit)
            .diff(getEndOfGivenDate(endDate, stepUnit), stepUnit);
    } else {
        diff = assignmentStartDate
            .startOf(stepUnit)
            .diff(getEndOfGivenDate(assignmentEndDate, stepUnit), stepUnit);
    }

    return diff ? Math.ceil(Math.abs(diff) * cellWidth) : 0;
};

export const calculateLeftPosition = (
    stepUnit,
    startDate,
    assignmentStartDate,
    cellWidth,
    GETTER = 0
): number => {
    let leftPosition = 0;

    if (!isBeforePeriod(stepUnit, startDate, assignmentStartDate)) {
        leftPosition = Math.abs(
            startDate.diff(assignmentStartDate.clone().startOf(stepUnit).endOf(stepUnit), stepUnit)
        );
    }

    return Math.round(leftPosition * cellWidth + GETTER);
};

export const calcWidthAndLeftPosition = (
    stepUnit,
    startDate,
    endDate,
    assignmentStartDate,
    assignmentEndDate,
    cellWidth,
    GETTER = 0
): [number, number] => {
    const width = calculateWidth(
        stepUnit,
        startDate,
        endDate,
        assignmentStartDate,
        assignmentEndDate,
        cellWidth
    );

    let leftPosition = 0;
    const isCompletelyBeforeStartDate = isBeforePeriod(stepUnit, startDate, assignmentEndDate);
    const isCompletelyAfterStartDate = isAfterPeriod(stepUnit, endDate, assignmentStartDate);

    if (!isCompletelyBeforeStartDate && !isCompletelyAfterStartDate) {
        leftPosition = calculateLeftPosition(
            stepUnit,
            startDate,
            assignmentStartDate,
            cellWidth,
            GETTER
        );
    }

    return [Number.isNaN(width) ? 0 : width, Number.isNaN(leftPosition) ? 0 : leftPosition];
};

export const calculateLastCellAllocationWidth = (
    cellWidth,
    isThereAfterArrow,
    isThereBeforeArrow,
    isSinglePeriodTask
) => {
    let lastCellWidth = cellWidth - sizes.durationPadding * 3; // minus inner padding and one side outer padding

    if (isSinglePeriodTask) {
        lastCellWidth -= sizes.durationPadding; // minus outer padding
    }

    if (isThereAfterArrow || (isSinglePeriodTask && isThereBeforeArrow)) {
        lastCellWidth -= sizes.assignmentArrowSize; // minus outer padding
    }

    return lastCellWidth;
};

export const checkIsPenultimateDayInView = (
    stepUnit: UnitOfTime,
    endDate: Moment,
    assignmentEndDate: Moment
): boolean => {
    const { isDayMode } = getPeriodModesValues(stepUnit);
    if (isDayMode) {
        return isAfterPeriod(stepUnit, endDate, assignmentEndDate.clone().add(3, stepUnit));
    }
    return isAfterPeriod(stepUnit, endDate, assignmentEndDate.clone().add(1, stepUnit));
};

export const calculateAssignmentWidth = (
    before: boolean,
    after: boolean,
    initialWidth: number,
    arrowWidth: number
): number => {
    if (!initialWidth) {
        return 0;
    }
    if (!before && !after) {
        return initialWidth;
    }
    if (before && after) {
        return initialWidth - 2 * arrowWidth;
    }
    return initialWidth - arrowWidth;
};
