import { Localization, LocalizationProvider } from '@workfront/localize-react';
import * as React from 'react';
import { clientFactory } from './constants/LocalizationClientFactory';

export const getErrorMessage = (
    msg: string,
    fallback: string,
    args: string[] = []
): JSX.Element => {
    return (
        <LocalizationProvider clientFactory={clientFactory}>
            <Localization<string> messageKey={msg} fallback={fallback} args={args} />
        </LocalizationProvider>
    );
};
