import { primary } from '@phoenix/all';
import moment, { Moment } from 'moment';
import * as React from 'react';
import styled, { css } from 'react-emotion';
import { connect, ConnectedProps } from 'react-redux';
import { Project } from 'workfront-objcodes';
import { widgetBottomLineZIndex, widgetTransparentBgZIndex } from '../../../constants/zIndexes';
import { RGBA_COLORS } from '../../../constants/appColors';
import { INACCESSIBLE_DURATION_COLOR } from '../../../constants/colors';
import { sizes, Sections } from '../../../constants/schedulingTableConstants';
import {
    IFullTimeOffSteps,
    IProjectColor,
    IStartEndDates,
    TOrUndefined,
    TStartDate,
    TWorkPerDays,
} from '../../../data-flow/data/IDataState';
import { endDateSelector } from '../../../data-flow/dateRange/selectors/endDateSelector';
import { startDateSelector } from '../../../data-flow/dateRange/selectors/startDateSelector';
import { stepUnitSelector } from '../../../data-flow/dateRange/selectors/stepUnitSelector';
import { cellWidthSelector } from '../../../data-flow/tableSizes/selectors/cellWidthSelector';
import { IWorkSchedulingCombinedState, UnitOfTime } from '../../../data-flow/types';
import {
    calculateLeftPosition,
    calculateWidth,
    calcWidthAndLeftPosition,
    isAfterPeriod,
    isBeforePeriod,
    shouldBeArrow,
} from '../../../util/durationStylesCalculation';
import { calculateArrowWidth, transformRGBAColorKey } from '../../../util/utilities';
import {
    AssigmentPositioningStyled,
    AssignmentPreWrapperStyled,
} from '../../styles/allocationsComponentStyles';
import { sharedStyle } from '../../styles/sharedStyles';
import { ActualProgressBlockWrapper } from './ActualProgress/ActualProgressBlockWrapper';
import { Allocations } from './Allocations';
import { DoneMark, MsgKeyCompleted } from './DoneMark';
import { getPeriodModesValues } from '../../../util/periodUtils/periodUtils';
import { showAllocationsSelector } from '../../../data-flow/settings/settingsSelector';
import { IGeneralStateTypes } from '../../../data-flow/IGeneralStateTypes';
import { getFontType } from '../../../util/getFontType';
import { getLinkRelatedProps } from '../left/ProjectName/ProjectName';
import { ProjectAssignmentTitle } from './ProjectAssignmentTitle';

const { durationPadding } = sizes;

type TInaccessibleWorkProps = Pick<
    IGeneralStateTypes,
    'stepUnit' | 'activePeriodMode' | 'cellWidth' | 'startDate' | 'endDate' | 'isShowAllocationOn'
>;

interface InaccessibleWorkProps extends ConnectedProps<typeof connector> {
    idExpression: string;
    name: string;
    durations: IStartEndDates[];
    projectedDurations?: IStartEndDates[];
    isProject: boolean;
    isAccessible?: boolean;
    isCompleted?: boolean;
    colorDark: string;
    colorLight: string;
    textColor: string;
    rightArrowCssClass: string;
    leftArrowCssClass: string;
    isAssigned: boolean;
    workPerDays: TOrUndefined<TWorkPerDays>;
    fullTimeOffsStepsByDay?: IFullTimeOffSteps;
    userScheduleID?: string;
    sectionType?: Sections;
    showActualProgress?: boolean;
    crossedOutHoursForPeriod?: number[];
    userID?: string;
    scheduleID?: string;
    projectColoredData?: IProjectColor;
}

interface IStyledAssignment {
    isProject: boolean;
    assignmentWidth: number;
    leftPosition?: number;
    colorLight?: string;
    colorDark: string;
    isAssigned: boolean;
    isAccessible?: boolean;
}

const AssignmentDurationComponent: React.FunctionComponent<InaccessibleWorkProps> = React.memo(
    (props) => {
        const {
            idExpression,
            durations,
            startDate,
            endDate,
            projectedDurations,
            cellWidth,
            stepUnit,
            isProject,
            isAccessible,
            isCompleted = false,
            colorDark,
            colorLight,
            textColor,
            name,
            rightArrowCssClass,
            leftArrowCssClass,
            isAssigned,
            activePeriodMode,
            workPerDays,
            crossedOutHoursForPeriod,
            isShowAllocationOn,
            fullTimeOffsStepsByDay,
            userScheduleID,
            sectionType,
            showActualProgress,
            userID,
            scheduleID,
            projectColoredData,
        } = props;

        const durationsLength = durations.length;
        let assignmentStartDateMoment = startDate;
        let isThereBeforeArrow = false;
        let isThereAfterArrow = false;
        let calculatedAssignmentWidth = 0;
        let assignmentWidth = 0;
        let assignmentLeftPosition = 0;

        if (durationsLength) {
            const assignmentStartDate = durations[0].startDate;
            const assignmentEndDate = durations[durationsLength - 1].endDate;

            assignmentStartDateMoment = moment(assignmentStartDate).startOf('day');
            const assignmentEndDateMoment = moment(assignmentEndDate).endOf('day');

            [assignmentWidth, assignmentLeftPosition] = calcWidthAndLeftPosition(
                stepUnit,
                startDate,
                endDate,
                assignmentStartDateMoment,
                assignmentEndDateMoment,
                cellWidth
            );

            isThereBeforeArrow = shouldBeArrow(
                assignmentWidth,
                isBeforePeriod(stepUnit, startDate, assignmentStartDateMoment)
            );
            isThereAfterArrow = shouldBeArrow(
                assignmentWidth,
                isAfterPeriod(stepUnit, endDate, assignmentEndDateMoment)
            );

            calculatedAssignmentWidth =
                assignmentWidth - calculateArrowWidth(isThereBeforeArrow, isThereAfterArrow);
        }

        const showLockIcon = shouldShowLockIcon(
            activePeriodMode,
            calculatedAssignmentWidth,
            cellWidth,
            isAccessible
        );

        const shouldShowActualProgress = shouldBeShowActualProgress(
            sectionType,
            showActualProgress
        );
        const [color, projectColor] = getColors(
            isProject,
            colorDark,
            !!isAccessible,
            projectColoredData
        );

        const height = isProject
            ? sizes.projectAssignmentHeightRedesign
            : sizes.taskAssignmentHeight;

        const fontComponentDetails = getFontType(isAccessible, idExpression, Project);
        const nameAsLinkProps = getLinkRelatedProps(true, fontComponentDetails);

        return (
            <>
                {shouldShowActualProgress
                    ? // here projectedDurations are array as we can have many projected durations in period
                      // known as Project gaps (the sam can be for inaccessible)
                      projectedDurations &&
                      projectedDurations.map((projectedDuration, i) => (
                          <ActualProgressBlockWrapper
                              key={`project-${projectedDuration.startDate}-${projectedDuration.endDate}`}
                              color={color}
                              textColor={getColorByKey('textColorDark', projectColoredData)}
                              height={height}
                              isAccessible={isAccessible}
                              ID={idExpression}
                              idExpression={idExpression}
                              isForProject={isProject}
                              isTherePlannedBeforeArrow={isThereBeforeArrow}
                              isTherePlannedAfterArrow={isThereAfterArrow}
                              projectedCompletionDate={projectedDuration.endDate}
                              projectedStartDate={projectedDuration.startDate}
                              isCompleted={isCompleted}
                              title={getNameForFirstBlock(name, calculatedAssignmentWidth, i)}
                              sectionType={sectionType}
                              durationPadding={durationPadding}
                          />
                      ))
                    : null}

                <AssignmentPreWrapperStyled
                    leftPosition={assignmentLeftPosition}
                    isThereBeforeArrow={isThereBeforeArrow}
                    showActualProgress={shouldShowActualProgress}
                >
                    <AssigmentPositioningStyled data-testid={`${idExpression}-styled`}>
                        {isThereBeforeArrow && (
                            <span data-testid="beforePeriod" className={leftArrowCssClass} />
                        )}

                        {calculatedAssignmentWidth > 0 ? (
                            <WhallDuration
                                isThereBeforeArrow={isThereBeforeArrow}
                                isThereAfterArrow={isThereAfterArrow}
                                data-testid={idExpression}
                                assignmentWidth={calculatedAssignmentWidth}
                                isProject={isProject}
                                colorDark={colorDark}
                                colorLight={colorLight}
                                isAssigned={isAssigned}
                                isAccessible={isAccessible}
                            >
                                {durations.map((item, index) => {
                                    const itemMomentStartDate = moment(item.startDate).startOf(
                                        'day'
                                    );
                                    const itemMomentEndDate = moment(item.endDate).endOf('day');

                                    const isFirstItemCondition = index === 0;
                                    const isLastItemCondition = index === durationsLength - 1;

                                    const inaccessibleDurationWidth =
                                        calculateWidth(
                                            stepUnit,
                                            startDate,
                                            endDate,
                                            itemMomentStartDate,
                                            itemMomentEndDate,
                                            cellWidth
                                        ) -
                                        calculateArrowWidth(
                                            isFirstItemCondition && isThereBeforeArrow,
                                            isLastItemCondition && isThereAfterArrow
                                        );

                                    return (
                                        <WorkingDurations
                                            data-testid="assignment_duration"
                                            isAssigned={isAssigned}
                                            isProject={isProject}
                                            key={`item-${item.startDate}-${item.endDate}`}
                                            isLastOrWithArrowItem={
                                                isLastItemCondition && !isThereAfterArrow
                                            }
                                            isFirstOrWithArrowItem={
                                                isFirstItemCondition && !isThereBeforeArrow
                                            }
                                            assignmentWidth={inaccessibleDurationWidth}
                                            colorDark={colorDark}
                                            leftPosition={getLeftPosition(
                                                stepUnit,
                                                isThereBeforeArrow,
                                                startDate,
                                                assignmentStartDateMoment,
                                                itemMomentStartDate,
                                                cellWidth,
                                                isFirstItemCondition
                                            )}
                                            statusColor={getStatusColor(
                                                isProject,
                                                projectColor,
                                                projectColoredData
                                            )}
                                        >
                                            <div className={transparentWidgetWrapper}>
                                                <WidgetTransparentBg
                                                    colorDark={colorDark}
                                                    statusColor={getStatusColor(
                                                        isProject,
                                                        projectColor,
                                                        projectColoredData
                                                    )}
                                                />
                                                <WidgetBottomLine
                                                    colorDark={colorDark}
                                                    statusColor={getStatusColor(
                                                        isProject,
                                                        projectColor,
                                                        projectColoredData
                                                    )}
                                                >
                                                    {!isThereBeforeArrow && (
                                                        <span
                                                            className={WdBottomLineLeftSquare(
                                                                colorDark,
                                                                getStatusColor(
                                                                    isProject,
                                                                    projectColor,
                                                                    projectColoredData
                                                                )
                                                            )}
                                                        />
                                                    )}
                                                    {!isThereAfterArrow && (
                                                        <span
                                                            className={WdBottomLineRightSquare(
                                                                colorDark,
                                                                getStatusColor(
                                                                    isProject,
                                                                    projectColor,
                                                                    projectColoredData
                                                                )
                                                            )}
                                                        />
                                                    )}
                                                </WidgetBottomLine>
                                            </div>

                                            {isShowAllocationOn && (
                                                <Allocations
                                                    workPerDays={workPerDays}
                                                    crossedOutHoursForPeriod={
                                                        crossedOutHoursForPeriod
                                                    }
                                                    stepStartDate={startDate}
                                                    assignmentStartDate={getBiggerDate(
                                                        startDate,
                                                        itemMomentStartDate
                                                    )}
                                                    assignmentEndDate={getSmallestDate(
                                                        endDate,
                                                        itemMomentEndDate
                                                    )}
                                                    cellWidth={cellWidth}
                                                    isCompleted={isCompleted}
                                                    isThereBeforeArrow={isThereBeforeArrow}
                                                    isThereAfterArrow={isThereAfterArrow}
                                                    height={height}
                                                    isProject={isProject}
                                                    fullTimeOffsStepsByDay={fullTimeOffsStepsByDay}
                                                    userScheduleID={userScheduleID}
                                                    isAccessible={isAccessible}
                                                    sectionType={sectionType}
                                                    objCode={Project}
                                                    userID={userID}
                                                    scheduleID={scheduleID}
                                                    textColor={textColor}
                                                />
                                            )}
                                            {index === 0 && !isShowAllocationOn && (
                                                <ProjectAssignmentTitle
                                                    showLockIcon={showLockIcon}
                                                    isAccessible={Boolean(isAccessible)}
                                                    title={name}
                                                    testID="inaccessibleTextBlock"
                                                    nameAsLinkProps={nameAsLinkProps}
                                                />
                                            )}
                                        </WorkingDurations>
                                    );
                                })}
                            </WhallDuration>
                        ) : null}
                        {isCompleted && calculatedAssignmentWidth > 0 ? (
                            <DoneMark
                                msg={MsgKeyCompleted.PROJECT}
                                isThereAfterArrow={isThereAfterArrow}
                            />
                        ) : null}
                        {isThereAfterArrow && (
                            <span data-testid="afterPeriod" className={rightArrowCssClass} />
                        )}
                    </AssigmentPositioningStyled>
                </AssignmentPreWrapperStyled>
            </>
        );
    }
);

function mapStateToProps(state: IWorkSchedulingCombinedState): TInaccessibleWorkProps {
    return {
        stepUnit: stepUnitSelector(state),
        activePeriodMode: stepUnitSelector(state),
        cellWidth: cellWidthSelector(state),
        startDate: startDateSelector(state),
        endDate: endDateSelector(state),
        isShowAllocationOn: showAllocationsSelector(state),
    };
}
const connector = connect(mapStateToProps);

export const AssignmentDuration = connector(AssignmentDurationComponent);

const shouldShowLockIcon = (
    activePeriodMode: UnitOfTime,
    calculatedAssignmentWidth: number,
    cellWidth: number,
    isAccessible?: boolean
): boolean => {
    const { isWeekMode, isMonthMode } = getPeriodModesValues(activePeriodMode);
    return (
        (!isAccessible && (isWeekMode || isMonthMode)) ||
        (!isAccessible && calculatedAssignmentWidth > cellWidth)
    );
};

const shouldBeShowActualProgress = (
    sectionType?: Sections,
    showActualProgress?: boolean
): boolean => {
    return sectionType !== Sections.UNASSIGNED_WORK && !!showActualProgress;
};

const getColors = (
    isProject: boolean,
    colorDark: string,
    isAccessible: boolean,
    projectColoredData: TOrUndefined<IProjectColor>
): string[] => {
    const color = getRGBAColor(colorDark, isProject ? 35 : 15, projectColoredData);
    const projectColor = getProjectColor(isProject, projectColoredData, isAccessible);

    return [color, projectColor];
};

const getRGBAColor = (
    colorDark: string,
    opacity: number,
    projectColoredData?: IProjectColor
): string => {
    return projectColoredData
        ? projectColoredData.RGBA[opacity]
        : RGBA_COLORS[transformRGBAColorKey(colorDark, opacity)];
};

const getProjectColor = (
    isProject: boolean,
    projectColoredData?: IProjectColor,
    isAccessible?: boolean
): string => {
    if (!isAccessible) {
        return INACCESSIBLE_DURATION_COLOR;
    }

    return isProject && projectColoredData ? projectColoredData.RGB : '';
};

const getColorByKey = (key: string, projectColoredData?: IProjectColor): string => {
    return projectColoredData ? projectColoredData[key] : '';
};

const getNameForFirstBlock = (
    name: string,
    calculatedAssignmentWidth: number,
    index: number
): string | null => {
    return calculatedAssignmentWidth <= 0 && index === 0 ? name : null;
};

const getLeftPosition = (
    stepUnit: UnitOfTime,
    isThereBeforeArrow: boolean,
    startDate: TStartDate,
    assignmentStartDate: Moment,
    itemMomentStartDate: Moment,
    cellWidth: number,
    isFirstItemCondition: boolean
): number => {
    return calculateLeftPosition(
        stepUnit,
        isThereBeforeArrow ? startDate : assignmentStartDate,
        itemMomentStartDate,
        cellWidth,
        isThereBeforeArrow && !isFirstItemCondition ? -sizes.assignmentArrowSize : 0
    );
};

const getStatusColor = (
    isProject: boolean,
    projectColor: string,
    projectColoredData?: IProjectColor
): any => {
    return isProject && projectColoredData
        ? {
              dark: projectColor,
              light: projectColoredData.RGB_lighter,
          }
        : {};
};

const getBiggerDate = (date1: Moment, date2: Moment): Moment => {
    return date1 > date2 ? date1 : date2;
};

const getSmallestDate = (date1: Moment, date2: Moment): Moment => {
    return date1 < date2 ? date1 : date2;
};

export const projectTextBlock = (): string => css`
    z-index: 55;
    display: flex;
    align-items: center;
    margin-left: 8px;
    overflow: hidden;
    padding-right: 8px;
    position: relative;
    height: 100%;
    width: 100%;
`;

export const truncateTextWrapper = css`
    max-width: calc(100% - 18px);
    overflow: hidden;
`;

export const textAndProjectIconColor = (isAccessible?: boolean): string => {
    return primary.gray(isAccessible ? 700 : 500);
};

export const truncateText = (isAccessible?: boolean): string => css`
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    color: ${textAndProjectIconColor(isAccessible)};
    padding-left: 5px;
    position: relative;
    cursor: ${isAccessible ? 'pointer' : ''};
    &:hover {
        text-decoration: ${isAccessible ? 'underline' : 'none'};
    }
`;

const WhallDuration = styled('div')<
    IStyledAssignment & {
        isThereBeforeArrow: boolean;
        isThereAfterArrow: boolean;
    }
>`
    ${sharedStyle};
    padding: 0 !important;
    height: ${(props) =>
        props.isProject ? sizes.projectAssignmentHeightRedesign : sizes.taskAssignmentHeight}px;
    width: ${(props) => props.assignmentWidth}px;
    margin-left: ${(props) => props.leftPosition}px;
    ${(props) =>
        props.isThereAfterArrow ? 'border-top-right-radius: 0; border-bottom-right-radius: 0' : ''};
    ${(props) =>
        props.isThereBeforeArrow ? 'border-top-left-radius: 0; border-bottom-left-radius: 0' : ''};
`;

const WorkingDurations = styled('div')<
    IStyledAssignment & {
        isLastOrWithArrowItem: boolean;
        isFirstOrWithArrowItem: boolean;
        statusColor?: { dark?: string; light?: string };
    }
>`
    position: absolute;
    top: 0;
    height: ${(props): number =>
        props.isProject ? sizes.projectAssignmentHeightRedesign : sizes.taskAssignmentHeight}px;
    left: ${(props) => props.leftPosition}px;
    width: ${(props) => props.assignmentWidth}px;
`;

const transparentWidgetWrapper = css`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
`;

const WidgetTransparentBg = styled('div')<{
    colorDark: string;
    statusColor?: { dark?: string; light?: string };
}>`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: ${(props): string =>
        props.statusColor && props.statusColor.dark ? props.statusColor.dark : props.colorDark};
    opacity: 0.2;
    z-index: ${widgetTransparentBgZIndex};
`;

const WidgetBottomLine = styled('div')<{
    colorDark: string;
    statusColor?: { dark?: string; light?: string };
}>`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 2px solid
        ${(props): string =>
            props.statusColor && props.statusColor.dark ? props.statusColor.dark : props.colorDark};
    z-index: ${widgetBottomLineZIndex};
`;

const WdBottomLineLeftSquare = (
    colorDark: string,
    statusColor?: { dark?: string; light?: string }
): string => css`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 0 8px;
    border-color: transparent transparent transparent
        ${statusColor && statusColor.dark ? statusColor.dark : colorDark};
`;

const WdBottomLineRightSquare = (
    colorDark: string,
    statusColor?: { dark?: string; light?: string }
): string => css`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 8px 8px;
    border-color: transparent transparent
        ${statusColor && statusColor.dark ? statusColor.dark : colorDark} transparent;
`;
