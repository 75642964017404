import { Action } from 'redux';
import { createReducer } from '../createReducer';
import { ISettingsState } from './ISettingsState';
import * as projectColorsSettingsMode from './settingsActions/projectColorsSettingsMode';
import * as setInitialSettingsState from './settingsActions/setInitialSettingsState';
import * as settingsState from './settingsActions/settingsActions';
import * as showAllocations from './settingsActions/showAllocations';
import * as showAllUsers from './settingsActions/showAllUsers';
import * as showRemainingHours from './settingsActions/showRemainingHours';
import * as userAllocationsMode from './settingsActions/userAllocationsMode';
import * as sortUsersMode from './settingsActions/sortUsersMode';
import * as projectsSortingCriteria from './settingsActions/projectsSortingCriteria';

type TSettingsReducer = (state: ISettingsState | undefined, action: Action<any>) => ISettingsState;

export function getSettingsReducer(initialState: ISettingsState): TSettingsReducer {
    return createReducer(
        [
            projectColorsSettingsMode,
            settingsState,
            showAllocations,
            showRemainingHours,
            userAllocationsMode,
            sortUsersMode,
            projectsSortingCriteria,
            setInitialSettingsState,
            showAllUsers,
        ],
        initialState
    );
}
