import { createSelector } from 'reselect';
import { tableDataIDsSelector } from '../../tableDataIDsSelector';
import { isLastNodeForProject, tasksAndIssuesSelector } from '../../dataSelectors';
import { projectGroupingModeSelector } from '../../../../settings/settingsSelector';
import { isInProjectArea } from '../../../../../util/utilities';
import { getSchedulingAreaData } from '../../../../areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';

export const isLastTaskRowSelector = createSelector(
    [
        tableDataIDsSelector,
        getSchedulingAreaData,
        projectGroupingModeSelector,
        tasksAndIssuesSelector,
    ],
    (tableDataIDs, schedulingAreaData, projectGroupingMode, tasksAndIssues) =>
        (idExpression: string) => {
            // assumed that idExpression is not user's one level
            const parentTaskIdExpression = idExpression.slice(0, idExpression.lastIndexOf('_'));
            const expressionIndex = tableDataIDs.indexOf(idExpression);
            const nextExpression = tableDataIDs[expressionIndex + 1];

            if (tableDataIDs.length - 1 <= expressionIndex) {
                return true;
            }
            let isLastRow = false;

            if (!projectGroupingMode && isInProjectArea(schedulingAreaData.schedulingAreaObjCode)) {
                const taskIssueID = idExpression.split('_')[1];
                const nextTaskAndIssueID = nextExpression.split('_')[1];
                isLastRow = isLastNodeForProject(
                    tasksAndIssues,
                    taskIssueID,
                    nextTaskAndIssueID,
                    schedulingAreaData.schedulingAreaID
                );
            }
            return (
                isLastRow ||
                !(
                    nextExpression.indexOf('_loading') !== -1 ||
                    nextExpression.indexOf(parentTaskIdExpression) !== -1
                )
            );
        }
);
