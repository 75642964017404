import { Ellipse, Tooltip, primary } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import UnlockSmallIcon from 'phoenix-icons/dist/UnlockSmallIcon.js';
import EditIcon from 'phoenix-icons/dist/EditIcon.js';
import * as React from 'react';
import { cx } from 'react-emotion';
import { connect } from 'react-redux';
import { Sections } from '../../../constants/schedulingTableConstants';
import { TDate } from '../../../data-flow/data/IDataState';
import {
    changedAssignmentSelector,
    dropdownOpenedSelector,
} from '../../../data-flow/data/selectors/dataSelectors';
import { IWorkSchedulingCombinedState } from '../../../data-flow/types';
import { convertMinuteToHour, formatNumber } from '../../../util/utilities';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { assignmentActionsStyles } from '../../styles/ActionsComponentStyles';
import {
    iconsWrapper,
    taskIssueDuration,
    taskIssueDurationHourAbbr,
    taskIssueNodeContainer,
    tooltipContentStyles,
    unlockIconStyles,
    WorkingHoursStyled,
} from '../../styles/sharedStyles';
import { AssignmentActionsComponent } from '../right/AssignmentActionsComponent';

interface ITaskIssueNodeHoursAndActionsComponentStateToProps {
    isObjectInAssignmentMode: boolean;
    dropdownOpened: string | null;
}

interface ITaskIssueNodeHoursAndActionsComponentProps {
    ID: string;
    idExpression: string;
    workRequired?: number;
    isInaccessible: boolean;
    sectionType?: Sections;
    showUnlockIcon?: boolean;
    objCode: string;
    assignAccess: boolean;
    plannedStartDate: TDate;
    plannedCompletionDate: TDate;
    isContoured: boolean;
}

export const TaskIssueNodeHoursAndActionsComponent: React.FC<
    ITaskIssueNodeHoursAndActionsComponentProps & ITaskIssueNodeHoursAndActionsComponentStateToProps
> = (props) => {
    const {
        assignAccess,
        dropdownOpened,
        ID,
        idExpression,
        isInaccessible,
        objCode,
        isObjectInAssignmentMode,
        plannedCompletionDate,
        plannedStartDate,
        sectionType,
        showUnlockIcon,
        workRequired,
        isContoured,
    } = props;

    const shouldOpenAssignmentActionsComponent =
        assignAccess && !isObjectInAssignmentMode && !isInaccessible;

    return (
        <div className={taskIssueNodeContainer}>
            {sectionType === Sections.PEOPLE_WORKLOAD && (
                <>
                    {isContoured && (
                        <Tooltip
                            content={localizationClient.getTextSync(
                                'workloadbalancer.contoured.tooltip',
                                'This allocation has been manually adjusted'
                            )}
                        >
                            <div className={iconsWrapper} data-testid="contoured-icon">
                                <EditIcon color={primary.gray(300)} height={17} />
                            </div>
                        </Tooltip>
                    )}
                    {showUnlockIcon && (
                        <Tooltip
                            content={
                                <div className={tooltipContentStyles}>
                                    {localizationClient.getTextSync(
                                        'workloadbalancer.simple.task.notification',
                                        'This is a Simple Duration Type task. Changing the allocations updates the Planned Hours to match.'
                                    )}
                                </div>
                            }
                        >
                            <div className={unlockIconStyles} data-testid="unlock-icon">
                                <UnlockSmallIcon color={primary.gray(300)} />
                            </div>
                        </Tooltip>
                    )}
                    <div
                        className={cx({
                            'duration-container-selector': shouldOpenAssignmentActionsComponent,
                        })}
                    >
                        <WorkingHoursStyled>
                            <div className={taskIssueDuration} data-testid="taskIssueDuration">
                                {workRequired && (
                                    <Ellipse>
                                        {formatNumber(convertMinuteToHour(workRequired))}
                                    </Ellipse>
                                )}
                            </div>
                            <div className={taskIssueDurationHourAbbr}>
                                <Localization<string> messageKey="hour.abbr.short" fallback="h" />
                            </div>
                        </WorkingHoursStyled>
                    </div>
                </>
            )}

            {shouldOpenAssignmentActionsComponent && (
                <div className={assignmentActionsStyles} data-testid="task-actions">
                    <AssignmentActionsComponent
                        assignAccess={assignAccess}
                        dropdownOpened={dropdownOpened}
                        ID={ID}
                        idExpression={idExpression}
                        objCode={objCode}
                        plannedCompletionDate={plannedCompletionDate}
                        plannedStartDate={plannedStartDate}
                        sectionType={sectionType}
                    />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (
    state: IWorkSchedulingCombinedState,
    ownProps: ITaskIssueNodeHoursAndActionsComponentProps
): ITaskIssueNodeHoursAndActionsComponentStateToProps => {
    const objectsInAssignmentMode = changedAssignmentSelector(state);
    return {
        isObjectInAssignmentMode: !!(
            objectsInAssignmentMode && objectsInAssignmentMode.includes(ownProps.ID)
        ),
        dropdownOpened: dropdownOpenedSelector(state),
    };
};

export const TaskIssueNodeHoursAndActions = connect(mapStateToProps)(
    TaskIssueNodeHoursAndActionsComponent
);
