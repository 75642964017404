import { createReducer } from '../createReducer';
import * as addAccessibleWorkingHoursForPeriod from './assignedDataActions/addAccessibleWorkingHoursForPeriod';
import * as addAssignments from './assignedDataActions/addAssignments';
import * as addAvailableHoursForPeriod from './assignedDataActions/addAvailableHoursForPeriod';
import * as addDefaultScheduleID from './assignedDataActions/addDefaultScheduleID';
import * as addInaccessibleAssignments from './assignedDataActions/addInaccessibleAssignments';
import * as addInaccessibleWorkingHoursForPeriod from './assignedDataActions/addInaccessibleWorkingHoursForPeriod';
import * as addProjectGroupColors from './assignedDataActions/addProjectGroupColors/addProjectGroupColors';
import * as addProjectsDetails from './assignedDataActions/addProjectsDetails';
import * as addRoles from './assignedDataActions/addRoles';
import * as addSchedulesWorkDays from './assignedDataActions/addSchedulesWorkDays';
import * as addTableDataIDs from './assignedDataActions/addTableDataIDs';
import * as addTemporaryWorkPerDayHoursAfterEditing from './assignedDataActions/addTemporaryWorkPerDayHoursAfterEditing';
import * as addTimeOffs from './assignedDataActions/addTimeOffs';
import * as addUserProjectID from './assignedDataActions/addUserProjectID';
import * as addUsers from './assignedDataActions/addUsers';
import * as addWorkPerDaysByAssignments from './assignedDataActions/addWorkPerDaysByAssignments';
import * as changeAssignmentWorkPerDayHours from './assignedDataActions/changeAssignmentWorkPerDayHours';
import * as changeContouringRowState from './assignedDataActions/changeContouringRowState';
import * as changeHasMore from './assignedDataActions/changeHasMore';
import * as changeUserNodesOffset from './assignedDataActions/changeOffset';
import * as changeProjectIDsSorting from './assignedDataActions/changeProjectIDsSorting';
import * as changeUsersDataRequestsState from './assignedDataActions/changeUsersDataRequestsState';
import * as removeAllUsers from './assignedDataActions/removeAllUsers';
import * as removeAssignedObject from './assignedDataActions/removeAssignedObject';
import * as removeFromHighlightingMode from './assignedDataActions/removeFromHighlightingMode';
import * as removeInaccessibleProject from './assignedDataActions/removeInaccessibleProject';
import * as removeTableDataIDs from './assignedDataActions/removeTableDataIDs';
import * as removeTemporaryWorkPerDayHours from './assignedDataActions/removeTemporaryWorkPerDayHours';
import * as removeUsersAssignments from './assignedDataActions/removeUsersAssignments';
import * as removeUsersData from './assignedDataActions/removeUsersData';
import * as removeUsersProjects from './assignedDataActions/removeUsersProjects';
import * as removeWorkPerDaysByUsersAssignments from './assignedDataActions/removeWorkPerDaysByUsersAssignments';
import * as setCalculateFTEAutomatic from './assignedDataActions/setCalculateFTEAutomatic';
import * as setHeightForPeopleWorkloadEmptyRow from './assignedDataActions/setHeightForPeopleWorkloadEmptyRow';
import * as setIntoHighlightingMode from './assignedDataActions/setIntoHighlightingMode';
import * as setObjectsIntoAssignmentMode from './assignedDataActions/setObjectsIntoAssignmentMode';
import * as addObjectsIntoAssignmentMode from './assignedDataActions/addObjectsIntoAssignmentMode';
import * as removeObjectsFromAssignmentMode from './assignedDataActions/removeObjectsFromAssignmentMode';
import * as setUserIntoAssignmentMode from './assignedDataActions/setUserIntoAssignmentMode';
import * as showErrorRedBorder from './assignedDataActions/showErrorRedBorder';
import * as sortUsersAddedByAssignment from './assignedDataActions/sortUsersAddedByAssignment';
import * as sortUsersTasks from './assignedDataActions/sortUsersTasks';
import * as updateTaskAndIssue from './assignedDataActions/updateTaskAndIssue';
import * as updateWorkDetails from './assignedDataActions/updateWorkDetails';
import * as setUsersOnProject from './assignedDataActions/setUsersOnProject';
import * as addLoadingRow from './dataLoadingActions/addLoadingRow';
import * as removeRow from './dataLoadingActions/removeRow';
import * as toggleLoading from './dataLoadingActions/toggleLoading';
import { IDataState } from './IDataState';
import * as changeNodeArrowState from './nodeItemActions/changeNodeArrowState';
import * as changeAllNodeArrowState from './nodeItemActions/changeAllNodeArrowState';
import * as changeProjectsDataRequestsState from './nodeItemActions/changeProjectsDataRequestsState';
import * as setAppearingRowsIndexes from './nodeItemActions/setRenderedStartStopIndexes';
import * as addAssignmentsDetailsToObject from './sharedActions/addAssignmentsDetailsToObject';
import * as addDelegationsDetailsToObject from './sharedActions/addDelegationsDetailsToObject';
import * as setLoadWorkDelegations from '../workDelegations/setLoadWorkDelegations';
import * as addWorkPerDaysByAssignmentsFromService from './sharedActions/addWorkPerDaysByAssignmentsFromService';
import * as addContouredAssignments from './sharedActions/addContouredAssignments';
import * as changeAssignmentDialogDetails from './sharedActions/changeAssignmentDialogDetails';
import * as changeDropDownOpenedState from './sharedActions/changeDropdownOpenedState';
import * as changeIDExpressionForMinixState from './sharedActions/changeIDExpressionForMinixState';
import * as removeObjectAssignmentsDetails from './sharedActions/removeObjectAssignmentsDetails';
import * as removeProjectsAndTasks from './sharedActions/removeProjectsAndTasks';
import * as removeWorkingHoursForPeriod from './sharedActions/removeWorkingHoursForPeriod';
import * as setProjectLifeAfterDeath from './sharedActions/setProjectLifeAfterDeath';
import * as addTableDataIDsForUnassignedSection from './unassignedDataActions/addTableDataIDsForUnassignedSection';
import * as addUnassignedAssignments from './unassignedDataActions/addUnassignedAssignments';
import * as addUnassignedTasksByProject from './unassignedDataActions/addUnassignedProjects';
import * as addWorkPerDaysForTasksFromService from './unassignedDataActions/addWorkPerDaysForTasksFromService';
import * as changeUnassignedDataRequestMode from './unassignedDataActions/changeUnassignedDataRequestMode';
import * as changeUnassignedNodeArrowState from './unassignedDataActions/changeUnassignedNodeArrowState';
import * as changeUnassignedProjectOffset from './unassignedDataActions/changeUnassignedProjectOffset';
import * as removeExpandedFlagsForUnassignedSection from './unassignedDataActions/removeExpandedFlagsForUnassignedSection';
import * as removeTableDataIDsForUnassignedSection from './unassignedDataActions/removeTableDataIDsForUnassignedSection';
import * as removeUnassignedObject from './unassignedDataActions/removeUnassignedObject';
import * as setHeightForUnassignedSectionEmptyRow from './unassignedDataActions/setHeightForUnassignedSectionEmptyRow';
import * as sortUnassignedProjectsTasks from './unassignedDataActions/sortUnassignedProjectsTasks';
import * as addUsersByAssignment from './assignedDataActions/addUsersByAssignment';
import * as removeUsersAddedByAssignments from './assignedDataActions/removeUsersAddedByAssignments';
import * as setRoleSummary from './assignedDataActions/roleSummary/setRoleSummary';
import * as setRoleSummaryLoading from './assignedDataActions/roleSummary/setRoleSummaryLoading';
import * as setRoleSummaryActiveKey from './assignedDataActions/roleSummary/setRoleSummaryActiveKey';
import * as updateRoleSummaryItem from './assignedDataActions/roleSummary/updateRoleSummaryItem';
import * as setEspProductEnabled from './assignedDataActions/espProductEnabled/setEspProductEnabled';
import * as addTemporaryWorkPerDayHoursAfterDragHover from './sharedActions/addTemporaryWorkPerDayHoursAfterDragHover';
import * as setDraggingFromArea from './assignedDataActions/dragAndDrop/setDraggingFromArea';
import * as removeWorkPerDayHoursOnDragHover from './sharedActions/removeWorkPerDayHoursOnDragHover';
import * as changeUnassignedALLNodeSArrowState from './unassignedDataActions/changeUnassignedTasksNodesArrowState';

export function getDataReducer(initialState: IDataState): any {
    return createReducer(
        [
            addRoles,
            addUsers,
            setUsersOnProject,
            addTableDataIDs,
            addTableDataIDsForUnassignedSection,
            addLoadingRow,
            addSchedulesWorkDays,
            addInaccessibleWorkingHoursForPeriod,
            changeHasMore,
            removeRow,
            removeUsersData,
            changeNodeArrowState,
            changeAllNodeArrowState,
            addProjectGroupColors,
            addProjectsDetails,
            removeTableDataIDs,
            removeTableDataIDsForUnassignedSection,
            removeExpandedFlagsForUnassignedSection,
            addAvailableHoursForPeriod,
            toggleLoading,
            addTimeOffs,
            addDefaultScheduleID,
            changeProjectsDataRequestsState,
            addAssignments,
            addInaccessibleAssignments,
            addWorkPerDaysByAssignments,
            addUnassignedAssignments,
            setAppearingRowsIndexes,
            changeUserNodesOffset,
            removeUsersProjects,
            changeProjectIDsSorting,
            addUnassignedTasksByProject,
            changeUnassignedNodeArrowState,
            changeUnassignedALLNodeSArrowState,
            removeProjectsAndTasks,
            changeUnassignedProjectOffset,
            setCalculateFTEAutomatic,
            setHeightForUnassignedSectionEmptyRow,
            setHeightForPeopleWorkloadEmptyRow,
            changeUsersDataRequestsState,
            changeUnassignedDataRequestMode,
            addAssignmentsDetailsToObject,
            addDelegationsDetailsToObject,
            setLoadWorkDelegations,
            changeAssignmentDialogDetails,
            changeDropDownOpenedState,
            addAccessibleWorkingHoursForPeriod,
            sortUsersTasks,
            updateTaskAndIssue,
            sortUnassignedProjectsTasks,
            setObjectsIntoAssignmentMode,
            addObjectsIntoAssignmentMode,
            removeObjectsFromAssignmentMode,
            setUserIntoAssignmentMode,
            removeUnassignedObject,
            removeAssignedObject,
            changeIDExpressionForMinixState,
            removeInaccessibleProject,
            removeWorkingHoursForPeriod,
            addUserProjectID,
            setIntoHighlightingMode,
            removeFromHighlightingMode,
            removeUsersAssignments,
            removeObjectAssignmentsDetails,
            removeWorkPerDaysByUsersAssignments,
            removeAllUsers,
            addWorkPerDaysByAssignmentsFromService,
            addWorkPerDaysForTasksFromService,
            addContouredAssignments,
            changeContouringRowState,
            addTemporaryWorkPerDayHoursAfterEditing,
            addTemporaryWorkPerDayHoursAfterDragHover,
            removeTemporaryWorkPerDayHours,
            removeWorkPerDayHoursOnDragHover,
            changeAssignmentWorkPerDayHours,
            showErrorRedBorder,
            updateWorkDetails,
            setProjectLifeAfterDeath,
            addUsersByAssignment,
            sortUsersAddedByAssignment,
            removeUsersAddedByAssignments,
            setRoleSummary,
            setRoleSummaryLoading,
            updateRoleSummaryItem,
            setRoleSummaryActiveKey,
            setEspProductEnabled,
            setDraggingFromArea,
        ],
        initialState
    );
}
