/**
 * This file should contain utilities for reusable function which depends mostly on state
 * At least one of the function parameters should be STATE, otherwise, you can use utilities.ts file
 */

import { isInProjectArea } from './utilities';
import { projectGroupingModeSelector } from '../data-flow/settings/settingsSelector';
import { getSchedulingAreaData } from '../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';

/**
 *
 * @param state
 */
export const isInProjectAreaAndGroupingMode = (state) => {
    // in unassigned section, in project area project should be expanded and can not be toggled
    // also there is no need for Show more

    const schedulingAreaData = getSchedulingAreaData(state);

    return (
        isInProjectArea(schedulingAreaData.schedulingAreaObjCode) &&
        projectGroupingModeSelector(state) &&
        schedulingAreaData.schedulingAreaID
    );
};
