import { addToast, primary } from '@phoenix/all';
import CheckmarkIcon from 'phoenix-icons/dist/CheckmarkIcon.js';
import CloseIcon from 'phoenix-icons/dist/CloseIcon.js';
import * as React from 'react';
import styled from 'react-emotion';
import { connect } from 'react-redux';
import { Task } from 'workfront-objcodes';
import { isEmpty } from 'lodash';
import { Sections } from '../../../constants/schedulingTableConstants';
import { GlobalPropsContext } from '../../../contexts/globalContexts';
import addObjectsIntoAssignmentMode from '../../../data-flow/data/assignedDataActions/addObjectsIntoAssignmentMode';
import removeObjectsFromAssignmentMode from '../../../data-flow/data/assignedDataActions/removeObjectsFromAssignmentMode';
import changeAssignmentWorkPerDayHours from '../../../data-flow/data/assignedDataActions/changeAssignmentWorkPerDayHours';
import changeContouringRowState from '../../../data-flow/data/assignedDataActions/changeContouringRowState';
import { resetContouringEditMode } from '../../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import removeTemporaryWorkPerDayHours from '../../../data-flow/data/assignedDataActions/removeTemporaryWorkPerDayHours';
import setIntoHighlightingMode from '../../../data-flow/data/assignedDataActions/setIntoHighlightingMode';
import showErrorRedBorder from '../../../data-flow/data/assignedDataActions/showErrorRedBorder';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import changeReloadState from '../../../data-flow/reloadState/changeReloadState';
import { changeAssignmentWorkThunk } from '../../../data-flow/thunks/changeAssignmentWorkThunk';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../data-flow/types';
import { APIService } from '../../../services/api-services/apiService';
import { LoggerService } from '../../../services/logger/LoggerService';
import { compareHoursAfterContouring } from '../../../util/contouringValidation';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { allocationButtonStyle } from '../../styles/allocationsComponentStyles';
import { getPeriodModesValues } from '../../../util/periodUtils/periodUtils';
import { stepUnitSelector } from '../../../data-flow/dateRange/selectors/stepUnitSelector';
import updateRoleSummaryItem from '../../../data-flow/data/assignedDataActions/roleSummary/updateRoleSummaryItem';
import { convertMinuteToHour } from '../../../util/utilities';
import { RoleSummaryHourKey } from '../../../constants/RoleSummaryHourKey';
import { IObjectState, TUserID } from '../../../data-flow/data/IDataState';
import { projectGroupingModeSelector } from '../../../data-flow/settings/settingsSelector';
import { getTaskSelector } from '../../../data-flow/data/selectors/reselect/getTaskSelector/getTaskSelector';
import { getSchedulingAreaData } from '../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { hasWorkRequiredEditAccessSelector } from '../../../data-flow/currentUser/selectors/hasWorkRequiredEditAccessSelector';
import {
    loadWorkDelegationsSelector,
    temporaryWorkPerDaysSelector,
} from '../../../data-flow/data/selectors/dataSelectors';
import { workPerDaysByAssignmentsFromServiceSelector } from '../../../data-flow/data/selectors/workPerDaysByAssignmentsFromServiceSelector';
import { getAriaLabelForSaveButton } from '../../../util/ariaUtils/ariaUtils';
import { IGeneralStateTypes } from '../../../data-flow/IGeneralStateTypes';
import { trackAllocationSaved } from '../../../data-flow/thunks/cjaTracker';
import { internalEventEmitterSelector } from '../../../data-flow/instances/internalEventEmitterSelector';
import { CJA_ANALYIZING_TRACK } from '../../../constants/events';

type IContouringButtonsPropsType = IContouringButtonsStateProps &
    IWorkSchedulingDispatchProp &
    IContouringButtonsComponentProps;

type IContouringButtonsStateProps = Pick<
    IGeneralStateTypes,
    | 'workRequired'
    | 'activePeriodMode'
    | 'temporaryWorkPerDays'
    | 'workPerDayByAssignments'
    | 'internalEventEmitter'
> & {
    taskID: string;
    task: IObjectState;
    hasWorkRequiredEditAccess: boolean;
    schedulingAreaID?: string;
    loadWorkDelegations: boolean;
};

interface IContouringButtonsComponentProps {
    isThereAfterArrow: boolean;
    isPenultimateDayInView: boolean;
    IDs: string[];
    placement: string | null;
    objCode: string;
}

interface IContouringIconsWrapper {
    isThereAfterArrow: boolean;
    isPenultimateDayInView: boolean;
    placement: string | null;
}

interface IUpdateRoleSummary extends IWorkSchedulingDispatchProp {
    assignments: any[];
    userID: TUserID;
    task: IObjectState;
    schedulingAreaID?: string;
    hoursDiff: any;
}

export const cancelContouringActions = (ID): any[] => {
    return [
        removeTemporaryWorkPerDayHours(),
        changeContouringRowState(null),
        showErrorRedBorder(ID, false),
    ];
};

const ContouringButtonsComponent: React.FunctionComponent<IContouringButtonsPropsType> = (
    props
) => {
    const globalPropsContext = React.useContext(GlobalPropsContext);

    const handleClickCancel = (): void => {
        props.dispatch(actionChain(cancelContouringActions(props.taskID)));
    };

    const { isWeekMode } = getPeriodModesValues(props.activePeriodMode);
    const hoursDiff = compareHoursAfterContouring(
        props.workPerDayByAssignments,
        props.temporaryWorkPerDays,
        isWeekMode
    );

    const isSaveDisabled = hoursDiff.roundedDiff !== 0 && !props.hasWorkRequiredEditAccess;

    const handleClickSave = (): void => {
        if (isEmpty(props.temporaryWorkPerDays.data)) {
            props.dispatch(resetContouringEditMode());
            return;
        }

        if (isSaveDisabled) {
            props.dispatch(showErrorRedBorder(props.taskID, true));
            return;
        }

        props.dispatch(
            actionChain([
                showErrorRedBorder(props.taskID, false),
                addObjectsIntoAssignmentMode([props.taskID]),
                changeContouringRowState(null),
                changeAssignmentWorkPerDayHours(props.temporaryWorkPerDays, props.IDs),
                changeReloadState(Sections.PEOPLE_WORKLOAD, { isAbleToReload: false }),
            ])
        );
        props.dispatch(trackAllocationSaved(props.task.durationType, props.task.status));
        props.internalEventEmitter.emit(CJA_ANALYIZING_TRACK);
        const { temporaryWorkPerDays } = props;
        const { assignmentID } = temporaryWorkPerDays;
        // even though we let user to have USER_EDIT_MAX_DIFF diff, but clean diff data should only got to back end
        APIService.editWorkPerDaysOfAssignment(
            assignmentID,
            temporaryWorkPerDays.data,
            hoursDiff.diff === 0
        )
            .then(() => {
                const userID = props.IDs[0];
                const chain = [
                    removeObjectsFromAssignmentMode([props.taskID]),
                    setIntoHighlightingMode([userID], [props.taskID]),
                ];

                const work =
                    props.workRequired + hoursDiff.diffClean < 0
                        ? 0
                        : props.workRequired + hoursDiff.diffClean;

                if (hoursDiff.diffClean !== 0 && props.hasWorkRequiredEditAccess) {
                    props
                        .dispatch(
                            changeAssignmentWorkThunk(
                                props.IDs,
                                work,
                                chain,
                                props.loadWorkDelegations,
                                assignmentID
                            )
                        )
                        .then((data) => {
                            if (data.assignments) {
                                updateRoleSummary({
                                    assignments: data.assignments,
                                    userID,
                                    task: props.task,
                                    schedulingAreaID: props.schedulingAreaID,
                                    dispatch: props.dispatch,
                                    hoursDiff,
                                });
                            }

                            if (
                                globalPropsContext.minixState &&
                                globalPropsContext.minixState.ID === props.taskID
                            ) {
                                window.postMessage(
                                    {
                                        type: 'minixUpdateDetails',
                                        updates: {
                                            plannedStartDate: data.plannedStartDate,
                                            plannedCompletionDate: data.plannedCompletionDate,
                                        },
                                    },
                                    window.location.origin
                                );
                            }
                        })
                        .finally(() => {
                            props.dispatch(
                                changeReloadState(Sections.PEOPLE_WORKLOAD, {
                                    isAbleToReload: true,
                                })
                            );
                        });
                } else {
                    props.dispatch(actionChain(chain));
                    props.dispatch(
                        changeReloadState(Sections.PEOPLE_WORKLOAD, { isAbleToReload: true })
                    );
                }
            })
            .catch((error) => {
                changeReloadState(Sections.PEOPLE_WORKLOAD, { isAbleToReload: true });
                LoggerService.log.error(error);
                addToast('error', error.message);
                props.dispatch(removeObjectsFromAssignmentMode([props.taskID]));
                handleClickCancel();
            });
    };

    const objectType = props.objCode === Task ? 'task' : 'issue';

    return (
        <ContouringIconsWrapper
            isThereAfterArrow={props.isThereAfterArrow}
            isPenultimateDayInView={props.isPenultimateDayInView}
            placement={props.placement}
        >
            {/* TODO: resolve eslint issue */}
            {/* eslint-disable-next-line react/button-has-type */}
            <button
                className={allocationButtonStyle}
                data-testid={`contouring-save-button-${objectType}`}
                aria-label={getAriaLabelForSaveButton(
                    isSaveDisabled,
                    hoursDiff.roundedDiff,
                    props.workRequired
                )}
                onClick={() => handleClickSave()}
            >
                <CheckmarkIcon color={primary.blue(400)} />
            </button>
            <ButtonsSeparator />
            {/* TODO: resolve eslint issue */}
            {/* eslint-disable-next-line react/button-has-type */}
            <button
                className={allocationButtonStyle}
                data-testid={`contouring-cancel-button-${objectType}`}
                aria-label={localizationClient.getTextSync('action.cancel', 'Cancel')}
                onClick={() => handleClickCancel()}
            >
                <CloseIcon color={primary.gray(500)} />
            </button>
        </ContouringIconsWrapper>
    );
};

const updateRoleSummary = (props: IUpdateRoleSummary): void => {
    const { assignments, userID, task, schedulingAreaID, dispatch, hoursDiff } = props;

    const changedTaskInCurrentProject = schedulingAreaID === task?.projectID;
    if (!changedTaskInCurrentProject) {
        return;
    }

    const assigmentToUserWhichHasRole = assignments.find(
        (assignment) => assignment.assignedToID === userID && assignment.roleID
    );
    if (assigmentToUserWhichHasRole) {
        dispatch(
            updateRoleSummaryItem(
                {
                    roleID: assigmentToUserWhichHasRole.roleID,
                    diff: convertMinuteToHour(hoursDiff.diffClean),
                },
                RoleSummaryHourKey.PLANNED_HOUR
            )
        );
    }
};

export const editModeShadowWithoutBottomStyle = `box-shadow: 1px -6px 20px 0px rgba(2,16,27,0.16);`;
export const editModeShadowWithoutTopStyle = `box-shadow: 0px 20px 20px 0 rgba(2,16,27,0.16), 3px 13px 16px 3px rgba(13,45,70,0.17);`;

const borderAndPaddingStyles = (placement: string | null): string => {
    return ` border: 4px solid white;
	  border-radius: ${placement === 'top-end' ? '3px 3px 0 0' : '0 0 3px 3px'};
	  padding-left: 0;
	  ${placement === 'top-end' ? editModeShadowWithoutBottomStyle : editModeShadowWithoutTopStyle}`;
};

const ContouringIconsWrapper = styled('div')<IContouringIconsWrapper>`
    display: flex;
    background-color: white;
    padding-left: 8px;
    ${(props) =>
        props.isThereAfterArrow || props.isPenultimateDayInView
            ? borderAndPaddingStyles(props.placement)
            : ''}
`;

const mapStateToProps = (
    state: IWorkSchedulingCombinedState,
    ownProps
): IContouringButtonsStateProps => {
    const projectGroupingMode = projectGroupingModeSelector(state);
    const { IDs } = ownProps;
    const task = getTaskSelector(state)(IDs[IDs.length - 1]);

    return {
        workRequired: IDs && IDs.length && task.workRequired[IDs[0]],
        activePeriodMode: stepUnitSelector(state),
        taskID: projectGroupingMode ? IDs[2] : IDs[1],
        task,
        temporaryWorkPerDays: temporaryWorkPerDaysSelector(state),
        workPerDayByAssignments: workPerDaysByAssignmentsFromServiceSelector(state),
        hasWorkRequiredEditAccess: hasWorkRequiredEditAccessSelector(state, task),
        schedulingAreaID: getSchedulingAreaData(state).schedulingAreaID,
        loadWorkDelegations: loadWorkDelegationsSelector(state),
        internalEventEmitter: internalEventEmitterSelector(state),
    };
};

export const ButtonsSeparator = styled('span')`
    border-right: 1px solid ${primary.gray(100)};
    width: 0px;
    height: 20px;
    margin: 0 4px;
    align-self: center;
    justify-self: center;
`;

export const ContouringButtons = connect(mapStateToProps)(ContouringButtonsComponent);
