import { TObjCode } from 'workfront-objcodes';
import { primary } from '@phoenix/all';
import { Sections, sizes } from '../../constants/schedulingTableConstants';
import { getObjectBackgroundColor } from '../../util/getObjectBackgroundColor';
import {
    getAssignedBackgroundColor,
    getUnassignedBackgroundColor,
    isIssue,
} from '../../util/utilities';
import { TASK_BG_COLOR, TASK_BG_IMAGE } from '../../constants/colors';
import { IProjectColor, TOrUndefined } from '../../data-flow/data/IDataState';
import changeUnassignedProjectOffset, {
    TChangeUnassignedNodesOffset,
} from '../../data-flow/data/unassignedDataActions/changeUnassignedProjectOffset';
import { LOAD_TASKS_ISSUES_LIMIT } from '../../constants/dataConstatnts';
import addTableDataIDsForUnassignedSection, {
    TTableDataAction,
} from '../../data-flow/data/unassignedDataActions/addTableDataIDsForUnassignedSection';
import setHeightForUnassignedSectionEmptyRow, {
    TSetHeightForUnassignedSectionEmptyRow,
} from '../../data-flow/data/unassignedDataActions/setHeightForUnassignedSectionEmptyRow';
import removeTableDataIDsForUnassignedSection, {
    TTableDataIDsAction,
} from '../../data-flow/data/unassignedDataActions/removeTableDataIDsForUnassignedSection';

export const getSizesRowHeight = (isAllocationsVisualizationOn: boolean): number => {
    return isAllocationsVisualizationOn ? sizes.tableRowHeightChartView : sizes.tableRowHeight;
};

export const getTableRowHeightForUnassignedSectionProjectGroupingCase = (
    projectGroupingMode: boolean
): number => {
    return projectGroupingMode ? sizes.tableRowProjectHeight : sizes.tableRowTaskHeight;
};

export const getTableRowHeightForAssignedSection = (
    idsArray: string[],
    sizesRowHeight: number,
    projectGroupingMode: boolean
): number => {
    if (idsArray.length === 1 && idsArray[0] === 'headerRow') {
        return sizes.tableHeaderRowHeight;
    }

    if (idsArray.length === 1) {
        return sizesRowHeight;
    }

    if (idsArray.length === 2 && projectGroupingMode) {
        return sizes.tableRowProjectHeight;
    }

    return sizes.tableRowTaskHeight;
};

export const getAssignmentBackground = (
    canApplyNewColors: TOrUndefined<boolean>,
    projectColoredData: TOrUndefined<IProjectColor>,
    backgroundColor: TOrUndefined<string>,
    isInDisableMode: boolean,
    isAssigned: boolean,
    sectionType: Sections,
    objCode: TObjCode
): string => {
    if (
        sectionType === Sections.UNASSIGNED_WORK &&
        !isAssigned &&
        !isInDisableMode &&
        !isIssue(objCode)
    ) {
        const darkColor = canApplyNewColors ? projectColoredData?.taskRGB : TASK_BG_COLOR;
        const lightColor = canApplyNewColors ? projectColoredData?.taskRGB_lighter : TASK_BG_IMAGE;
        return getUnassignedBackgroundColor(darkColor, lightColor);
    }
    return getAssignedBackgroundColor(backgroundColor);
};

export const getAssignmentBackgroundColor = (
    canApplyNewColors: TOrUndefined<boolean>,
    projectColoredData: TOrUndefined<IProjectColor>,
    objCode: TObjCode,
    isInDisableMode: boolean,
    isAssignmentOnDrag?: boolean
): TOrUndefined<string> => {
    return canApplyNewColors
        ? projectColoredData?.taskRGB
        : getObjectBackgroundColor(objCode, isInDisableMode, isAssignmentOnDrag);
};

export const getAssignmentTextColor = (
    canApplyNewColors: TOrUndefined<boolean>,
    projectColoredData: TOrUndefined<IProjectColor>
): TOrUndefined<string> => {
    return canApplyNewColors ? projectColoredData?.taskColor : primary.white();
};

export const arrowCollapsedStateActions = (
    idExpression: string,
    nodesIDs: string[],
    unassignedWorkHeight: number,
    projectGroupingMode: boolean
): [TChangeUnassignedNodesOffset, TTableDataAction, TSetHeightForUnassignedSectionEmptyRow] => {
    return [
        changeUnassignedProjectOffset(idExpression, LOAD_TASKS_ISSUES_LIMIT),
        addTableDataIDsForUnassignedSection(
            nodesIDs.slice(0, LOAD_TASKS_ISSUES_LIMIT),
            idExpression,
            nodesIDs.length > LOAD_TASKS_ISSUES_LIMIT
        ),
        setHeightForUnassignedSectionEmptyRow(unassignedWorkHeight, projectGroupingMode),
    ];
};

export const arrowExpandedStateActions = (
    idExpression: string,
    unassignedWorkHeight: number,
    projectGroupingMode: boolean
): [TChangeUnassignedNodesOffset, TTableDataIDsAction, TSetHeightForUnassignedSectionEmptyRow] => {
    return [
        changeUnassignedProjectOffset(idExpression, 0),
        removeTableDataIDsForUnassignedSection(`${idExpression}_`),
        setHeightForUnassignedSectionEmptyRow(unassignedWorkHeight, projectGroupingMode),
    ];
};

export const isProjectAccessible = (id: string): boolean => {
    const idItems = id.split('_');
    return idItems.length === 2 && idItems[1] !== 'showInaccessible';
};
