import { getInitialSettingsState } from '../getInitialSettingsState';
import { ISettingsState } from '../ISettingsState';

export const ActionType = 'SET_INITIAL_SETTINGS_STATE';

const setInitialSettingsState = () => ({
    type: ActionType,
    payload: {},
});
export default setInitialSettingsState;

export function handle(/* state: ISettingsState */): ISettingsState {
    return {
        ...getInitialSettingsState(),
    };
}
