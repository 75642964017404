import moment, { Moment } from 'moment';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'ADD_INACCESSIBLE_WORKING_HOURS';

interface IAddInaccessibleWorkingHoursPayload {
    assignmentsData: any;
    steps: Moment[];
    usersIDs: string[];
}

type TAddInaccessibleWorkingHoursAction =
    TModernSchedulingAction<IAddInaccessibleWorkingHoursPayload>;

const addInaccessibleWorkingHoursForPeriod = (
    steps: Moment[],
    usersIDs: string[],
    assignmentsData: any
): TAddInaccessibleWorkingHoursAction => ({
    type: ActionType,
    payload: {
        assignmentsData,
        steps,
        usersIDs,
    },
});
export default addInaccessibleWorkingHoursForPeriod;

export function handle(
    state: IDataState,
    { payload }: TAddInaccessibleWorkingHoursAction
): IDataState {
    const users = { ...state.users };

    payload.usersIDs.forEach((userID: string, index: number) => {
        const workPerDaySums: any = [...users[userID].userWorkPerDayForPeriod];

        payload.assignmentsData[index].result.forEach((assignmentItem) => {
            const assignmentObject = assignmentItem.task || assignmentItem.opTask;
            const startDate = assignmentItem.plannedStartDate || assignmentObject.plannedStartDate;
            const assignmentStartDate = moment(startDate).startOf('day');

            payload.steps.forEach((step, stepIndex: number) => {
                const diffByDay = step.startOf('day').diff(assignmentStartDate, 'day');
                const workPerDayHours = assignmentItem.workPerDay[diffByDay] || 0;
                workPerDaySums[stepIndex] = (workPerDaySums[stepIndex] || 0) + workPerDayHours;
            });
        });

        users[userID] = {
            ...users[userID],
            userWorkPerDayForPeriod: workPerDaySums,
        };
    });

    return {
        ...state,
        users,
    };
}
