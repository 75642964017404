import { assignedSectionHeaderDataID } from '../../../constants/dataConstatnts';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';
import { tableDataIDsSelectorWithoutHeaderSelector } from '../selectors/tableDataIDsSelector';

export const ActionType = 'SORT_USERS_ADDED_BY_ASSIGNMENT';

interface ISortUsersAddedByAssignmentActionPayload {
    addedUsersByAssignment: string[];
}

type ISortUsersAddedByAssignmentAction =
    TModernSchedulingAction<ISortUsersAddedByAssignmentActionPayload>;

const sortUsersAddedByAssignment = (addedUsersByAssignment): ISortUsersAddedByAssignmentAction => ({
    type: ActionType,
    payload: {
        addedUsersByAssignment,
    },
});
export default sortUsersAddedByAssignment;

export function handle(
    state: IDataState,
    { payload }: ISortUsersAddedByAssignmentAction
): IDataState {
    const withoutHeaderDataIDs = tableDataIDsSelectorWithoutHeaderSelector(state);

    const tableDataIDs = withoutHeaderDataIDs.sort(function (userId1, userId2) {
        return (
            payload.addedUsersByAssignment.indexOf(userId2) -
            payload.addedUsersByAssignment.indexOf(userId1)
        );
    });

    return {
        ...state,
        tableDataIDs: assignedSectionHeaderDataID.concat(tableDataIDs),
    };
}
