import { IDataState } from '../data/IDataState';
import { TModernSchedulingAction } from '../types';

export const ActionType = 'SET_LOAD_WORK_DELEGATIONS';

interface ISetLoadWorkDelegationsActionPayload {
    loadWorkDelegations: boolean;
}

type TSetLoadWorkDelegationsAction = TModernSchedulingAction<ISetLoadWorkDelegationsActionPayload>;

const setLoadWorkDelegations = (loadWorkDelegations: boolean): TSetLoadWorkDelegationsAction => ({
    type: ActionType,
    payload: {
        loadWorkDelegations,
    },
});
export default setLoadWorkDelegations;

export function handle(state: IDataState, { payload }: TSetLoadWorkDelegationsAction): IDataState {
    const { loadWorkDelegations } = payload;

    return { ...state, loadWorkDelegations };
}
