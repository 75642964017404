import {
    IAssignmentsDetails,
    IUnassignedTasksAssignment,
    TAssignmentID,
    TRoleIDs,
    TTemporaryWorkPerDays,
    TWorkPerDays,
} from '../data-flow/data/IDataState';

export const getIsDraggingEnabled = (
    assignAccess: boolean,
    sharableLink: string | undefined
): boolean => {
    return assignAccess && !sharableLink;
};

export const cancelActionsOnHover = (
    userID: string,
    draggedFromUserID: string,
    temporaryWorkPerDays: TTemporaryWorkPerDays,
    workPerDays: TWorkPerDays,
    dragHoverTimeout: NodeJS.Timeout | null
): boolean => {
    return (
        !workPerDays ||
        userID === draggedFromUserID ||
        dragHoverTimeout !== null ||
        isUserAlreadyHovered(temporaryWorkPerDays, userID)
    );
};

export const isUserAlreadyHovered = (
    temporaryWorkPerDays: TTemporaryWorkPerDays,
    userID: string
): boolean => {
    return temporaryWorkPerDays[userID] !== undefined && temporaryWorkPerDays[userID].length > 0;
};

export const checkIsRoleApplicable = (
    assignmentRoleIDs: string[],
    userRolesIDs: string[]
): boolean => {
    if (!assignmentRoleIDs.length) return false;
    return userRolesIDs.some((ID) => assignmentRoleIDs.includes(ID));
};

export const updateRoleAssignments = (
    assignmentRoleIDs: TRoleIDs,
    userRolesIDs: TRoleIDs,
    roleID: string,
    userID: string
): [string[], { [key: string]: string[] }] => {
    let replacedRoles;
    const primaryRoleIndex = assignmentRoleIDs.indexOf(roleID);
    if (primaryRoleIndex !== -1) {
        replacedRoles = { [assignmentRoleIDs[primaryRoleIndex]]: [userID] };
        assignmentRoleIDs.splice(primaryRoleIndex, 1);
    } else {
        const secondaryRoleIndex = userRolesIDs.map((ID) => assignmentRoleIDs.indexOf(ID))[0];
        replacedRoles = { [assignmentRoleIDs[secondaryRoleIndex]]: [userID] };
        assignmentRoleIDs.splice(secondaryRoleIndex, 1);
    }
    return [assignmentRoleIDs, replacedRoles];
};

export const getRoleAssignmentAfterDropping = (
    assignmentsDetails: IAssignmentsDetails[],
    draggedFromUserID: string
): IAssignmentsDetails | undefined => {
    return assignmentsDetails.find((assignment) => {
        return assignment.assignedTo && assignment.assignedTo.ID === draggedFromUserID;
    });
};

export const getAssignmentsCount = (
    assignments: IUnassignedTasksAssignment[],
    userRoles: TRoleIDs
): number => {
    let assignmentsCount = assignments.length;
    if (!assignmentsCount) return 1;
    const roleIDs = assignments.map((assignment) => assignment.roleID);
    if (!checkIsRoleApplicable(roleIDs, userRoles)) {
        assignmentsCount += 1;
    }
    return assignmentsCount;
};

export const getFinalWorkPerDaysOnHover = (
    workPerDays: TWorkPerDays,
    assignmentsCount: number
): TWorkPerDays => {
    return workPerDays.map((hour) => {
        return hour / assignmentsCount;
    });
};

export const isUserAlreadyAssigned = (
    usersAssignments: TAssignmentID[],
    assignmentID: TAssignmentID
): boolean => {
    return usersAssignments.indexOf(assignmentID) !== -1;
};
