import { primary } from '@phoenix/all';
import styled from 'react-emotion';
import { TimeUnit } from '../../../constants/periodEnums';
import { sizes } from '../../../constants/schedulingTableConstants';

interface ITableHeaderStepCellProps {
    isToday: boolean;
    isHalfEndWeek: boolean;
    stepUnit: string;
}

const lastChildStyle = (isHalfEndWeek: boolean): string => {
    return isHalfEndWeek
        ? `&:last-child {
        border-right: 1px solid ${primary.gray(200)} !important;
    }`
        : '';
};

export const TableHeaderStepCell = styled('div')<ITableHeaderStepCellProps>`
    padding: 0 5px;
    border-left: 1px solid ${primary.gray(200)};
    background-color: ${(props) => (props.isToday ? '#F5FAFD' : '')};
    color: ${primary.gray(500)};
    font-size: 12px;
    border-top: ${(props) => (props.isToday ? '2px solid #0078D7' : '')};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: ${(props) =>
        props.stepUnit === TimeUnit.WEEK
            ? sizes.cellMinWidthWeeksMode
            : sizes.cellMinWidthDayModeRedesign}px;
    ${(props) => lastChildStyle(props.isHalfEndWeek)}
`;
