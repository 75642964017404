import { StatusBadge } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import * as React from 'react';
import styled from 'react-emotion';
import { doneMarkZIndex } from '../../../constants/zIndexes';

interface IDoneMarkProps {
    msg: string;
    status?: BadgeStatus;
    type?: BadgeType;
    showTooltip?: boolean;
    isThereAfterArrow?: boolean;
}

export enum MsgKeyCompleted {
    TASK = 'completed',
    PROJECT = 'resourcescheduling.completed.for.this.time.frame',
}

export enum BadgeType {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export enum BadgeStatus {
    APPROVED = 'Approved',
    CHANGES = 'Changes',
    PENDING = 'Pending',
    REJECTED = 'Rejected',
    NO_DECISION = 'No Decision',
    WARNING = 'Warning',
    TIME_OFF = 'Time Off',
}

const doneMarkTestID = 'done-mark';

export const DoneMark: React.FunctionComponent<IDoneMarkProps> = React.memo(
    ({
        msg,
        status = BadgeStatus.APPROVED,
        type = BadgeType.SMALL,
        showTooltip = true,
        isThereAfterArrow = false,
    }) => {
        return (
            <BadgeWrapperStyled data-testid={doneMarkTestID} isThereAfterArrow={isThereAfterArrow}>
                <StatusBadge
                    showTooltip={showTooltip}
                    status={status}
                    statusTooltipContent={<Localization<string> messageKey={msg} fallback="" />}
                    type={type}
                />
            </BadgeWrapperStyled>
        );
    }
);

export const BadgeWrapperStyled = styled('div')<{ isThereAfterArrow: boolean }>`
    position: absolute;
    z-index: ${doneMarkZIndex};
    top: -6px;
    right: ${(props) => (props.isThereAfterArrow ? 4 : -6)}px;
    & div {
        top: 0;
        right: 0;
        bottom: initial;
        display: flex;
        border-color: white;
    }
    & svg {
        top: 0;
    }
`;
