import { Sections } from '../../../constants/schedulingTableConstants';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'REMOVE_PROJECTS_AND_TASKS';

interface IRemoveProjectsAndTasksActionPayload {
    sectionType: Sections;
}

type TRemoveUsersProjectsAction = TModernSchedulingAction<IRemoveProjectsAndTasksActionPayload>;

const removeProjectsAndTasks = (sectionType: Sections): TRemoveUsersProjectsAction => ({
    type: ActionType,
    payload: {
        sectionType,
    },
});
export default removeProjectsAndTasks;

export function handle(state: IDataState, { payload }): IDataState {
    const emptyState = {
        [Sections.PEOPLE_WORKLOAD]: {
            tasksAndIssues: {},
            projects: {},
        },
        [Sections.UNASSIGNED_WORK]: {
            unassignedTasksProjects: {},
            unassignedTasks: {},
        },
    };
    return {
        ...state,
        ...emptyState[payload.sectionType],
    };
}
