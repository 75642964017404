import { Localization } from '@workfront/localize-react';
import React from 'react';
import { Text, toast } from '@phoenix/all';
import { useDrop } from 'react-dnd';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { dropOverlayTextStyle, UnassignedDropOverlayStyled } from './UnassignedDropOverlayStyles';
import {
    DragDropTypes,
    IDraggableAssignment,
} from '../../../dragAndDrop/dragAndDropConfigurations';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import { setIntoAssignmentModeActions } from '../../../data-flow/data/assignedDataActions/commonActionGroups/unassignActions';
import setUserIntoAssignmentMode from '../../../data-flow/data/assignedDataActions/setUserIntoAssignmentMode';
import { IAssignmentsDetails } from '../../../data-flow/data/IDataState';
import { restructureAssignmentDetails } from '../../../util/actionsComponentService';
import { assignUserAfterDropping } from '../../../data-flow/thunks/changeAssignmentsThunk';
import setDraggingFromArea from '../../../data-flow/data/assignedDataActions/dragAndDrop/setDraggingFromArea';
import { getRoleAssignmentAfterDropping } from '../../../util/dragAndDropUtilities';
import { useAppDispatch, useAppSelector } from '../../../data-flow/hooks';
import { tableLeftSidePanelWidthSelector } from '../../../data-flow/tableSizes/selectors/tableSizesSelectors';
import { IAssignmentData } from '../../../util/dataStructures/IAssignmentIds';
import { Sections } from '../../../constants/schedulingTableConstants';
import {
    tasksAndIssuesSelector,
    unassignedTasksSelector,
} from '../../../data-flow/data/selectors/dataSelectors';

const dataTestIds = {
    unassigned_drop_overlay: 'unassigned_drop_overlay',
};

export const UnassignedDropOverlay: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const leftSidePanelWidth: number = useAppSelector((state) =>
        tableLeftSidePanelWidthSelector(state)
    );
    const [tasksAndIssues, unassignedTasks] = useAppSelector((state) => [
        tasksAndIssuesSelector(state),
        unassignedTasksSelector(state),
    ]);
    const handleDrop = (item: IDraggableAssignment): void => {
        const { ID, draggedFromUserID, objCode, sectionType } = item;

        const users = [draggedFromUserID];
        dispatch(actionChain([setUserIntoAssignmentMode(users, true), setDraggingFromArea()]));

        const projectID =
            sectionType === Sections.PEOPLE_WORKLOAD
                ? tasksAndIssues[ID].projectID
                : unassignedTasks[ID].projectID;

        item.loadTaskAssignments.then((assignmentsDetails: IAssignmentsDetails[] | null) => {
            if (assignmentsDetails) {
                const assignmentDetails = restructureAssignmentDetails(assignmentsDetails);
                const assignmentRoleIDs = assignmentDetails.roleIDs;
                let usersRemovedFromRole = {};
                const updatedRoleAssignment: IAssignmentsDetails | undefined =
                    getRoleAssignmentAfterDropping(assignmentsDetails, draggedFromUserID);

                if (updatedRoleAssignment?.role) {
                    assignmentRoleIDs.push(updatedRoleAssignment.role.ID);
                    usersRemovedFromRole = { [draggedFromUserID]: [updatedRoleAssignment.role.ID] };
                }

                const changedAssignmentData = {
                    ...assignmentDetails,
                    userIDs: [
                        ...assignmentDetails.userIDs.filter(
                            (userID) => userID !== draggedFromUserID
                        ),
                    ],
                    roleIDs: assignmentRoleIDs,
                };

                const assignmentData: IAssignmentData = {
                    USER: changedAssignmentData.userIDs,
                    ROLE: changedAssignmentData.roleIDs,
                    TEAMOB: changedAssignmentData.teamID ? [changedAssignmentData.teamID] : [],
                    replacedRoles: {},
                    usersRemovedFromRole,
                };
                const assignmentFormData = {
                    assignments: JSON.stringify(assignmentData),
                    projectId: projectID,
                };

                dispatch(
                    assignUserAfterDropping(
                        changedAssignmentData,
                        assignmentFormData,
                        ID,
                        draggedFromUserID,
                        objCode,
                        assignmentDetails
                    )
                ).then(() => {
                    toast.success(
                        localizationClient.getTextSync(
                            'resourcescheduling.assignment.success.notification',
                            'You successfully assigned the task'
                        )
                    );
                });
            } else {
                dispatch(
                    actionChain([
                        setUserIntoAssignmentMode(users, false),
                        ...setIntoAssignmentModeActions(ID),
                    ])
                );
            }
        });
    };

    const [{ isActive }, dragRef] = useDrop({
        accept: DragDropTypes.ASSIGNMENT,
        drop: handleDrop,
        collect: (monitor) => ({
            isActive: monitor.canDrop() && monitor.isOver(),
        }),
    });

    return (
        <div ref={dragRef}>
            <UnassignedDropOverlayStyled
                leftSidePanelWidth={leftSidePanelWidth}
                data-testid={dataTestIds.unassigned_drop_overlay}
                style={{ opacity: isActive ? 1 : 0 }}
            >
                <Text.Large className={dropOverlayTextStyle}>
                    <Localization<string>
                        messageKey="workloadbalancer.drop.here.to.unassign.the.task.from.the.user"
                        fallback="Drop here to unassign the task from the user"
                    />
                </Text.Large>
            </UnassignedDropOverlayStyled>
        </div>
    );
};
