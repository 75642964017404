import { createReducer } from '../createReducer';
import * as changeUserContouringEditAccess from './currentUserActions/changeUserContouringEditAccess';
import * as changeWorkRequiredPermission from './currentUserActions/changeWorkRequiredPermission';

export function currentUserReducer(initialFilterState) {
    return createReducer(
        [changeWorkRequiredPermission, changeUserContouringEditAccess],
        initialFilterState
    );
}
