import { findLastIndexUtil } from '../../../util/utilities';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';
import { unassignedSectionHeaderDataID } from '../../../constants/dataConstatnts';
import { tableDataIDsForUnassignedSectionWithoutHeader } from '../selectors/dataSelectors';

export const ActionType = 'ADD_DATA_IDS_FOR_UNASSIGNED_SECTION';

export type TTableDataAction = TModernSchedulingAction<ITableDataActionPayload>;

interface ITableDataActionPayload {
    IDs: string[];
    idExpression: string;
    showMore: boolean;
    sliceIndex: number | null;
}

const addTableDataIDsForUnassignedSection = (
    IDs: string[],
    idExpression = '',
    showMore = false,
    sliceIndex: number | null = null
): TTableDataAction => ({
    type: ActionType,
    payload: {
        IDs,
        idExpression,
        showMore,
        sliceIndex,
    },
});
export default addTableDataIDsForUnassignedSection;

export function handle(state: IDataState, { payload }: TTableDataAction): IDataState {
    const { IDs, idExpression, showMore } = payload;

    const withoutHeaderDataIDs = tableDataIDsForUnassignedSectionWithoutHeader(state);
    const prefix = idExpression ? '_' : '';
    const loadedIDs = IDs.map((dataID) => `${idExpression}${prefix}${dataID}`);
    let sliceIndex;

    if (payload.sliceIndex != null) {
        sliceIndex = payload.sliceIndex;
    } else {
        sliceIndex = idExpression
            ? findLastIndexUtil(withoutHeaderDataIDs, idExpression) + 1
            : withoutHeaderDataIDs.length;
    }

    if (showMore) {
        loadedIDs.push(`${idExpression}_showMore`);
    }

    const newDataIDs = [
        ...withoutHeaderDataIDs.slice(0, sliceIndex),
        ...loadedIDs,
        ...withoutHeaderDataIDs.slice(sliceIndex),
    ];

    return {
        ...state,
        tableDataIDsForUnassignedSection: unassignedSectionHeaderDataID.concat(newDataIDs),
    };
}
