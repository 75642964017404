import { primary } from '@phoenix/all';
import * as React from 'react';
import styled, { cx } from 'react-emotion';
import { connect } from 'react-redux';
import { DATE_FORMAT } from '../../../constants/dataConstatnts';
import { Sections, sizes } from '../../../constants/schedulingTableConstants';
import { ITimeOffsState, IWorkDays } from '../../../data-flow/data/IDataState';
import {
    getFullTimeOffRangeWithCount,
    getFullTimeOffSteps,
} from '../../../data-flow/data/selectors/dataSelectors';
import { IDateRangeState } from '../../../data-flow/dateRange/IDateRangeState';
import { stepsSelector } from '../../../data-flow/dateRange/selectors/stepsSelector';
import { IWorkSchedulingCombinedState, UnitOfTime } from '../../../data-flow/types';
import { isWorkingDayBySchedule, selectUserIDFromExpression } from '../../../util/utilities';
import { cell, cellWrapper, weekEndStyle } from '../../styles/sharedStyles';
import { getPeriodModesValues } from '../../../util/periodUtils/periodUtils';
import { stepUnitSelector } from '../../../data-flow/dateRange/selectors/stepUnitSelector';
import { getUserSelector } from '../../../data-flow/data/selectors/users/usersSelector';
import { showAllocationsVisualizationSelector } from '../../../data-flow/settings/settingsSelector';
import { timeOffsSelectorForWeekMonthViewCached } from '../../../data-flow/data/selectors/rereselect/timeOffsSelectorForWeekMonthView';
import { scheduleWorkDaysSelector } from '../../../data-flow/data/selectors/reselect/scheduleWorkDaysSelector/scheduleWorkDaysSelector';
import { timeOffsSelectorCached } from '../../../data-flow/data/selectors/rereselect/timeOffsSelector';
import { RangeFullTimeOffStyled } from './peopleWorkLoadSection/FullTimeOffs';

interface IShowMoreStateToProps extends Pick<IDateRangeState, 'steps'> {
    scheduleWorkDays: IWorkDays;
    timeOffs: ITimeOffsState;
    showAllocationsVisualization: boolean;
    activePeriodMode: UnitOfTime;
}

interface IShowMoreProps
    extends React.HTMLAttributes<HTMLDivElement>,
        Pick<IDateRangeState, 'steps'> {
    idExpression: string;
    scheduleWorkDays: IWorkDays;
    isWeekView: boolean;
    sectionType: Sections;
    timeOffs: ITimeOffsState;
    showAllocationsVisualization: boolean;
}

const weekEndStyles = cx(cell(), weekEndStyle());

const ShowMoreComponent: React.FunctionComponent<IShowMoreProps & IShowMoreStateToProps> = (
    props
) => {
    const {
        steps,
        scheduleWorkDays,
        isWeekView,
        timeOffs,
        showAllocationsVisualization,
        sectionType,
    } = props;
    const fullTimeOffsSteps = getFullTimeOffSteps(timeOffs, isWeekView);

    const fullTimeOffWithRange = getFullTimeOffRangeWithCount(timeOffs);
    const fullTimeOff = isWeekView ? fullTimeOffsSteps : fullTimeOffWithRange;

    return (
        <>
            <div className={cellWrapper}>
                {steps.map((step) => {
                    const stepDateString = step.format(DATE_FORMAT);
                    let isStriped = false;

                    if (props.sectionType === Sections.PEOPLE_WORKLOAD) {
                        if (!isWeekView) {
                            isStriped =
                                !isWorkingDayBySchedule(scheduleWorkDays, step) ||
                                !!fullTimeOffsSteps[stepDateString];
                        } else {
                            isStriped = !!fullTimeOffsSteps[stepDateString];
                        }
                    }

                    const timeOffStep = fullTimeOff?.[stepDateString];

                    let testId = 'showMoreRightCell';
                    let className = cell();
                    if (isStriped) {
                        testId = 'showMoreStriped';
                        className = weekEndStyles;
                    }

                    if (timeOffStep) {
                        const totalCount = isWeekView
                            ? 1
                            : (timeOffStep.totalDayOffCount as number);
                        return (
                            <ShowMoreStyled
                                showAllocationsVisualization={showAllocationsVisualization}
                                sectionType={sectionType}
                                key={step.unix()}
                                data-testid={testId}
                                className={className}
                            >
                                <RangeFullTimeOffStyled
                                    style={{ backgroundColor: 'unset' }}
                                    stepCount={totalCount}
                                    hasTopBorder={false}
                                    isAllocationsVisualizationOn={showAllocationsVisualization}
                                />
                            </ShowMoreStyled>
                        );
                    }

                    return (
                        <ShowMoreStyled
                            showAllocationsVisualization={showAllocationsVisualization}
                            sectionType={sectionType}
                            key={step.unix()}
                            data-testid={testId}
                            className={className}
                        />
                    );
                })}
            </div>
        </>
    );
};

const mapStateToProps = (state: IWorkSchedulingCombinedState, ownProps): IShowMoreStateToProps => {
    const userID = selectUserIDFromExpression(ownProps.idExpression);
    const user = getUserSelector(state)(userID);
    const activePeriodMode = stepUnitSelector(state);
    const showAllocationsVisualization = showAllocationsVisualizationSelector(state);

    let timeOffs: ITimeOffsState = {};
    let scheduleWorkDays = {} as IWorkDays;

    if (ownProps.sectionType === Sections.PEOPLE_WORKLOAD) {
        scheduleWorkDays = scheduleWorkDaysSelector(state)(user.scheduleID);
        const { isDayMode } = getPeriodModesValues(activePeriodMode);
        timeOffs = !isDayMode
            ? timeOffsSelectorCached(state, { userID })
            : timeOffsSelectorForWeekMonthViewCached(state, {
                  scheduleID: user.scheduleID,
                  userID: ownProps.userID,
              });
    }

    return {
        steps: stepsSelector(state),
        scheduleWorkDays,
        activePeriodMode,
        timeOffs,
        showAllocationsVisualization,
    };
};

export const ShowMore = connect(mapStateToProps)(ShowMoreComponent);

const ShowMoreStyled = styled('div')<{
    sectionType: Sections;
    showAllocationsVisualization: boolean;
}>`
    background-color: ${(props) =>
        props.sectionType === Sections.PEOPLE_WORKLOAD && primary.gray(50)} !important;
    border-left: ${(props) =>
        props.showAllocationsVisualization &&
        props.sectionType === Sections.PEOPLE_WORKLOAD &&
        'none !important'};
    &:first-child {
        border-left: ${sizes.gridLightGreyBorderWidth}px solid ${primary.gray(200)} !important;
    }
`;
