import { TModernSchedulingAction } from '../../types';
import { IDelegationsDetails, IDataState } from '../IDataState';

export const ActionType = 'ADD_DELEGATIONS_DETAILS_TO_OBJECT';

interface IAddDelegationsDetailsToObjectActionPayload {
    id: string;
    delegationsDetails: IDelegationsDetails[];
}

type TAddDelegationsDetailsToObjectAction =
    TModernSchedulingAction<IAddDelegationsDetailsToObjectActionPayload>;

const addDelegationsDetailsToObject = (
    id,
    delegationsDetails: IDelegationsDetails[]
): TAddDelegationsDetailsToObjectAction => ({
    type: ActionType,
    payload: {
        id,
        delegationsDetails,
    },
});
export default addDelegationsDetailsToObject;

export function handle(
    state: IDataState,
    { payload }: TAddDelegationsDetailsToObjectAction
): IDataState {
    const { id, delegationsDetails } = payload;

    return {
        ...state,
        objectDelegationDetails: { ...state.objectDelegationDetails, [id]: delegationsDetails },
    };
}
