import { isInProjectArea } from './util/utilities';

export const apiRequestMessageKeysPerCode = {
    401: {
        messageKey: 'login.error.session',
        fallBack: 'You are not currently logged in.',
    },
    403: {
        messageKey: 'login.error.nolongeraccess',
        fallBack: 'You can no longer access the {0}.',
    },
    404: {
        messageKey: 'exception.item.notfound',
        fallBack: '{0} not found.',
    },
    500: {
        messageKey: 'unknown.server.error',
        fallBack: 'The server encountered an unknown error.',
    },
};

export const apiRequestMessageKeys: string[] = Object.values(apiRequestMessageKeysPerCode).map(
    (obj) => obj.messageKey
);

export const serviceNamesPerKey = {
    workperday: 'allocation.plural',
    filter: 'filter',
};

export const serviceNameKeys = Object.values(serviceNamesPerKey);

const filtersWildcards = [
    'filters.user.wildcards.me',
    'filters.user.wildcards.team',
    'filters.user.wildcards.teams',
    'filters.user.wildcards.role',
    'filters.user.wildcards.roles',
    'filters.user.wildcards.company',
    'filters.user.wildcards.Group',
    'filters.user.wildcards.groups',
    'filters.user.wildcards.customForm',
    'filters.user.wildcards.access',
];

export const filtersMessageKeysForContent = [
    // do not change ordering of keys
    'resourcescheduling.assigned.work',
    'resourcescheduling.unassigned.work',
    'action.cancel',
    'action.delete',
    'resourceplanner.filter.delete.dialog.message',
    'workloadbalancer.filter.unsavedFilterLabel',
    'unsaved',
    'me',
    'jobrole.primary.my',
    'workloadbalancer.users.on.projects',
    'workloadbalancer.default.filter',
    'workloadbalancer.this.project.work.items',
    'workloadbalancer.this.project',
    ...filtersWildcards,
];

const assginmentPopoverMessageKeys = [
    'filter.readytostart',
    'notready',
    'task.lowercase.plural',
    'task.lowercase',
    'parenttask',
    'successor.dependency',
];

export const MessageKeys = [
    'allocation',
    'alphabetically',
    'april.abbr',
    'august.abbr',
    'action.settings',
    'action.cancel',
    'action.save',
    'capacity',
    'day',
    'december.abbr',
    'double.click',
    'enum.baselinepropertyenum.workrequired',
    'february.abbr',
    'friday.abbr',
    'hour.abbr.short',
    'hour.total',
    'january.abbr',
    'june.abbr',
    'july.abbr',
    'kickstart.error',
    'name',
    'march.abbr',
    'may.abbr',
    'monday.abbr',
    'month',
    'minix.open',
    'minix.close',
    'november.abbr',
    'october.abbr',
    'project',
    'resourceplanner.week.number',
    'resourcescheduling.assignment.success.notification',
    'resourcescheduling.assigned.work',
    'resourcescheduling.completed.for.this.time.frame',
    'resourcescheduling.context.sensitive.help',
    'resourcescheduling.next.period',
    'resourcescheduling.previous.period',
    'resourcescheduling.unassigned.work',
    'resourcescheduling.holiday',
    'resourcescheduling.inaccessible.project',
    'resourcescheduling.inaccessible.project.description',
    'resourcescheduling.inaccessible.work',
    'resourcescheduling.unassigned.work',
    'resourcescheduling.timeoff',
    'resourcescheduling.timeoff.and.holiday',
    'schedule.detail.tab.working.hours',
    'resourcescheduling.project.grouping',
    'resourcescheduling.assign.this.to',
    'resourcescheduling.show.more.lowercase',
    'resourcescheduling.show.more.projects.lowercase',
    'resourcescheduling.start.empty.team.message',
    'resourcescheduling.switch.to.day.view',
    'resourcescheduling.switch.to.week.view',
    'resourcescheduling.switch.to.month.view',
    'resourcescheduling.url.copy',
    'resourcescheduling.url.sharable.link',
    'resourcescheduling.settings.include.issues',
    'resourcescheduling.settings.progress.actual',
    'resourcescheduling.settings.work.completed',
    'saturday.abbr',
    'status',
    'schedule.fully.allocated',
    'schedule.overallocated',
    'schedule.underallocated',
    'september.abbr',
    'show.more.tasks',
    'sunday.abbr',
    'timeoff',
    'today',
    'thursday.abbr',
    'tuesday.abbr',
    'filter.action.add',
    'wednesday.abbr',
    'week',
    'workloadbalancer.add.unassigned.filter.description',
    'workloadbalancer.contoured.tooltip',
    'workloadbalancer.edit.allocation',
    'workloadbalancer.filter.not.found',
    'workloadbalancer.filter.not.found.unassigned',
    'workloadbalancer.generate.sharable.link',
    'workloadbalancer.generate.url.copy',
    'workloadbalancer.hide.allocation',
    'workloadbalancer.hide.allocations.visualization',
    'workloadbalancer.mac.assignment.key',
    'workloadbalancer.minixselecttaskorissue',
    'workloadbalancer.no.unassigned.filter.description',
    'workloadbalancer.open.settings',
    'workloadbalancer.open.role.allocation',
    'workloadbalancer.select.calendar.date',
    'workloadbalancer.select.time.period',
    'workloadbalancer.select.timeline.view',
    'workloadbalancer.select.today',
    'workloadbalancer.select.previous.period',
    'workloadbalancer.select.next.period',
    'workloadbalancer.show.allocation',
    'workloadbalancer.show.allocations.visualization',
    'workloadbalancer.save.allocation',
    'workloadbalancer.allocation.does.not.equal',
    'workloadbalancer.toggle.allocations',
    'workloadbalancer.toggle.allocations.visualization',
    'workloadbalancer.workload.header',
    'workloadbalancer.workload.allocated.hours',
    'workloadbalancer.workload.allocated.hours.description',
    'workloadbalancer.windows.assignment.key',
    'workloadbalancer.workload.remaining.hours',
    'workloadbalancer.simple.task.notification',
    'workloadbalancer.expand.unassigned.section',
    'workloadbalancer.expand.unassigned.section.tooltip',
    'workloadbalancer.collapse.unassigned.section',
    'workloadbalancer.collapse.unassigned.section.tooltip',
    'workloadbalancer.collapse.assigned.section.tooltip',
    'workloadbalancer.expand.assigned.section.tooltip',
    'workloadbalancer.open.unassigned.filters',
    'workloadbalancer.open.unassigned.nofilters',
    'workloadbalancer.open.assigned.nofilters',
    'workloadbalancer.open.assigned.filters',
    'workloadbalancer.select.next.period.loaded',
    'workloadbalancer.select.previous.period.loaded',
    'uifilter',
    'nofilter.applied',
    'project',
    'task',
    'issue',
    'user',
    'workloadbalancer.open.task.menu',
    'workloadbalancer.open.issue.menu',
    'workload.balancer.close.settings.panel',
    'workloadbalancer.total.work',
    'resourceplanner.no.filter.results',
    'widgets.daterangepicker.1week',
    'widgets.daterangepicker.2week',
    'widgets.daterangepicker.4week',
    'widgets.daterangepicker.6week',
    'widgets.daterangepicker.12week',
    'widgets.daterangepicker.3months',
    'filters.search.no-matches',
    'filters.equals',
    'filters.contains',
    'filters.blank',
    'filters.not_blank',
    'workloadbalancer.allocation.mode.title',
    'workloadbalancer.color.theme.title',
    'workloadbalancer.drop.here.to.unassign.the.task.from.the.user',
    'workloadbalancer.drop.here.to.assign.this.to',
    'resourceplanner.businesscase.hours',
    'workloadbalancer.user.allocations.as.hours',
    'workloadbalancer.workload.remaining.hours.description',
    'reporting.show.results.as.percentage',
    'workloadbalancer.user.allocations.as.percentage',
    'workloadbalancer.workload.remaining.percentage.description',
    'workloadbalancer.settings.general',
    'workloadbalancer.settings.sort.alphabetically.description',
    'workloadbalancer.settings.sort.by.primary.role',
    'workloadbalancer.settings.sort.by.role.description',
    'workloadbalancer.settings.sort.users',
    'workloadbalancer.settings.sorting.preferences',
    'workloadbalancer.settings.sort.projects.by',
    'workloadbalancer.settings.sort.projects.by.description',
    'workloadbalancer.settings.suggested.fields',
    'filters.filter.allFields',
    'refresh',
    'workloadbalancer.error.general',
    'workloadbalancer.error.description',
    'workloadbalancer.error.view.customizations',
    'workloadbalancer.error.project.colors',
    'workloadbalancer.on.the.project',
    'workloadbalancer.show.project.users',
    'workloadbalancer.show.all.users',
    'workloadbalancer.no.people.assigned',
    'workloadbalancer.enable.action.main',
    'workloadbalancer.show.all.users',
    'workloadbalancer.maximize.timeline',
    'workloadbalancer.minimize.timeline',
    'close',
    ...assginmentPopoverMessageKeys,
    ...filtersMessageKeysForContent,
    ...apiRequestMessageKeys,
    ...serviceNameKeys,
];

export const projectAreaRelatedKeys = [
    'workloadbalancer.hide.role.allocation',
    'workloadbalancer.show.role.allocation',
    'workloadbalancer.project.totals',
];

/**
 *
 * @param initialOptions
 */
export const getMessageKeys = (initialOptions): string[] => {
    const areaRelatedKeys = getAreaRelatedMessageKeys(initialOptions);
    return [...MessageKeys, ...areaRelatedKeys, ...workloadBalancerBulkAssignmentsKeys];
};

export const workloadBalancerBulkAssignmentsKeys = [
    'workloadbalancer.bulk.assignments',
    'workloadbalancer.bulk.assignments.no.items',
    'workloadbalancer.select.project.tasks',
    'workloadbalancer.replace.user',
    'workload.balancer.assign.user',
    'workloadbalancer.unassign.user',
    'resourcescheduling.swaperoo.action',
    'workloadbalancer.role.assignment',
    'workloadbalancer.select.role',
    'workloadbalancer.user.to.assign',
    'workloadbalancer.select.user',
    'workloadbalancer.cancel',
    'workloadbalancer.assign',
    'workloadbalancer.replace',
    'workloadbalancer.unassign',
    'workloadbalancer.assigned.user',
    'workloadbalancer.assigned.user.role',
    'workloadbalancer.you.successfully.assigned.work.items',
    'workloadbalancer.you.successfully.replace.work.items',
    'workloadbalancer.you.successfully.unassigned.work.items',
    'assignments.assigntome',
    'workloadbalancer.bulk.info.assign',
    'workloadbalancer.bulk.info.assign.without.role',
    'workloadbalancer.bulk.info.assign.unmatched.role',
    'workloadbalancer.bulk.info.replace',
    'workloadbalancer.bulk.info.replace.without.role',
    'workloadbalancer.bulk.info.unassign',
    'workloadbalancer.bulk.info.unassign.without.role',
    'workloadbalancer.user.to.unassign',
    'workloadbalancer.bulk.filter.field',
    'workloadbalancer.bulk.filter.operator',
    'workloadbalancer.bulk.filter.values',
    'workloadbalancer.bulk.filter-rule.remove',
    'workloadbalancer.bulk.assignments.search.all.users',
    'suggested.assignments',
    'otherassignment.plural',
];

export const getAreaRelatedMessageKeys = (initialOptions): string[] => {
    // area, team or global area specific message keys.
    if (
        initialOptions &&
        initialOptions.schedulingAreaData &&
        isInProjectArea(initialOptions.schedulingAreaData.objCode)
    ) {
        return projectAreaRelatedKeys;
    }
    return [];
};
