import { forEach } from 'lodash';
import { changedAssignment } from '../../../util/contouringValidation';
import { TModernSchedulingAction } from '../../types';
import { IDataState, IProjectGroupingMode, TContouringRowID } from '../IDataState';

export const ActionType = 'ADD_TEMPORARY_WORK_PER_DAY_HOURS_AFTER_EDITING';

interface IAddTemporaryWorkPerDayHoursAfterEditingStatePayload {
    args: {
        userID: string;
        hoursBySteps: { [stepDate: string]: number };
        taskID: string;
        projectGrouping: IProjectGroupingMode;
        contouringRowID: TContouringRowID;
    };
}

type TUsersDataRequestsStateAction =
    TModernSchedulingAction<IAddTemporaryWorkPerDayHoursAfterEditingStatePayload>;

const addTemporaryWorkPerDayHoursAfterEditing = (args): TUsersDataRequestsStateAction => ({
    type: ActionType,
    payload: { args },
});
export default addTemporaryWorkPerDayHoursAfterEditing;

export function handle(
    state: IDataState,
    { payload }: TModernSchedulingAction<IAddTemporaryWorkPerDayHoursAfterEditingStatePayload>
): IDataState {
    const { userID, hoursBySteps, taskID, projectGrouping, contouringRowID } = payload.args;
    const projectID = contouringRowID && projectGrouping ? contouringRowID.split('_')[1] : '';
    const assignments = { ...state.users[userID].assignments };
    let temporaryWorkPerDays = {
        ...state.temporaryWorkPerDays,
        data: { ...state.temporaryWorkPerDays.data },
    };

    const changedAssignmentID = changedAssignment(assignments, taskID);

    if (changedAssignmentID) {
        if (temporaryWorkPerDays.assignmentID !== changedAssignmentID) {
            temporaryWorkPerDays = {
                userID: null,
                assignmentID: null,
                data: {},
            };
        }

        if (projectGrouping) temporaryWorkPerDays.projectID = projectID;
        temporaryWorkPerDays.assignmentID = changedAssignmentID;
        temporaryWorkPerDays.userID = userID;

        forEach(hoursBySteps, (hour, dateStr) => {
            temporaryWorkPerDays.data[dateStr] = hour;
        });
    }
    return {
        ...state,
        temporaryWorkPerDays,
    };
}
