import React, { createContext } from 'react';

let isEnabledForExportedFunction;

export const isEnabled = (feature) =>
    isEnabledForExportedFunction ? isEnabledForExportedFunction(feature) : undefined;

export const FeatureToggle = createContext(isEnabled);

interface IFeatureTogglesProps {
    children: any;
    features: {
        [featureToggleName: string]: boolean;
    };
}

export class FeatureToggles extends React.Component<IFeatureTogglesProps, any> {
    constructor(props) {
        super(props);

        isEnabledForExportedFunction = (feature) => props.features[feature];

        this.state = {
            isEnabled: isEnabledForExportedFunction,
        };
    }

    render() {
        return (
            <FeatureToggle.Provider value={this.state.isEnabled}>
                {this.props.children}
            </FeatureToggle.Provider>
        );
    }
}
