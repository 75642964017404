import { Moment } from 'moment';
import * as React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { connect } from 'react-redux';
import { Sections, TaskBarBorder } from '../../../constants/schedulingTableConstants';
import {
    IFullTimeOffSteps,
    ITimeOffsState,
    IWorkDays,
    TContouringRowID,
    TOrUndefined,
    TWorkPerDays,
} from '../../../data-flow/data/IDataState';
import { contouringRowIdSelector } from '../../../data-flow/data/selectors/dataSelectors';
import { stepUnitSelector } from '../../../data-flow/dateRange/selectors/stepUnitSelector';
import { IWorkSchedulingCombinedState, UnitOfTime } from '../../../data-flow/types';
import { getObjectBackgroundColor } from '../../../util/getObjectBackgroundColor';
import { AllocationsStyled, hoursListStyle } from '../../styles/allocationsComponentStyles';
import { AllocationModeItems } from './peopleWorkLoadSection/AllocationModeItems';
import { AllocationTooltipContouring } from './peopleWorkLoadSection/AllocationTooltipContouring';
import {
    showActualProgressSelector,
    showAllocationsInHoursModeSelector,
} from '../../../data-flow/settings/settingsSelector';
import { availableHoursSelector } from '../../../data-flow/data/selectors/reselect/availableHoursSelector/availableHoursSelector';
import { scheduleWorkDaysSelector } from '../../../data-flow/data/selectors/reselect/scheduleWorkDaysSelector/scheduleWorkDaysSelector';
import { scheduleHoursSelector } from '../../../data-flow/data/selectors/rereselect/scheduleHoursSelector/scheduleHoursSelector';
import { AllocationHours } from './AllocationHours';
import { stepsSelector } from '../../../data-flow/dateRange/selectors/stepsSelector';
import { timeOffsSelectorCached } from '../../../data-flow/data/selectors/rereselect/timeOffsSelector';
import { timeOffsSelectorForWeekMonthViewCached } from '../../../data-flow/data/selectors/rereselect/timeOffsSelectorForWeekMonthView';
import { getPeriodModesValues } from '../../../util/periodUtils/periodUtils';
import { getUserSelector } from '../../../data-flow/data/selectors/users/usersSelector';

interface IAllocationsStateProps {
    scheduleWorkDays: IWorkDays;
    stepUnit: UnitOfTime;
    contouringRowID: TContouringRowID;
    activePeriodMode: UnitOfTime;
    showActualProgress: boolean;
    showAllocationsInHoursMode: boolean;
    availableHours: TOrUndefined<number[]>;
    scheduleHours: TOrUndefined<number[]>;
    steps: Moment[];
    timeOffs: ITimeOffsState;
}

interface IAllocationsHoursProps extends IAllocationsStateProps {
    assignmentStartDate: Moment;
    assignmentEndDate: Moment;
    backGroundColor?: string;
    plannedStartDate: string;
    plannedCompletionDate: string;
    workPerDays: TOrUndefined<TWorkPerDays>;
    cellWidth: number;
    stepStartDate: Moment;
    isCompleted: boolean;
    isThereBeforeArrow: boolean;
    isThereAfterArrow: boolean;
    isPenultimateDayInView: boolean;
    height: number;
    isProject?: boolean;
    fullTimeOffsSteps: IFullTimeOffSteps;
    fullTimeOffsStepsByDay: IFullTimeOffSteps;
    userScheduleID: string;
    isAccessible: boolean;
    sectionType: Sections;
    workRequired: number;
    allocationsEditingMode: boolean | string | null;
    isTheLastRow: boolean;
    isStartDateInLastTreeCells: boolean;
    areHoursNotEqualsBeforeContouringSave: boolean | undefined;
    objCode: string;
    assignmentActualEndDate: Moment | null;
    crossedOutHoursForPeriod?: number[];
    textColor?: string;
    isFromAssignmentBar: boolean;
}

export const AllocationsComponent: React.FC<IAllocationsHoursProps> = React.memo((props) => {
    const {
        backGroundColor,
        isCompleted,
        isThereBeforeArrow,
        isThereAfterArrow,
        isPenultimateDayInView,
        isProject,
        isAccessible,
        contouringRowID,
        allocationsEditingMode,
        isTheLastRow,
        isStartDateInLastTreeCells,
        areHoursNotEqualsBeforeContouringSave,
        objCode,
        showAllocationsInHoursMode,
        isFromAssignmentBar,
    } = props;

    let IDs;
    if (contouringRowID) {
        IDs = contouringRowID.split('_');
    }

    const bgColor = backGroundColor || getObjectBackgroundColor(objCode);

    return (
        <>
            <Manager>
                <Reference>
                    {({ ref }) => (
                        <AllocationsStyled
                            innerRef={ref}
                            isProject={isProject}
                            isAccessible={isAccessible}
                            isCompleted={isCompleted}
                            allocationsEditingMode={allocationsEditingMode}
                            data-testid="allocations_duration"
                            isFromAssignmentBar={isFromAssignmentBar}
                        >
                            <div
                                className={hoursListStyle(
                                    isThereAfterArrow,
                                    isThereBeforeArrow,
                                    allocationsEditingMode,
                                    bgColor
                                )}
                            >
                                <AllocationHours
                                    {...props}
                                    backgroundColor={bgColor}
                                    IDs={IDs}
                                    isFromAssignmentBar={isFromAssignmentBar}
                                />
                            </div>
                        </AllocationsStyled>
                    )}
                </Reference>

                {allocationsEditingMode && IDs && (
                    <>
                        <AllocationModeItems
                            isThereAfterArrow={isThereAfterArrow}
                            isPenultimateDayInView={isPenultimateDayInView}
                            isTheLastRow={isTheLastRow}
                            areHoursNotEqualsBeforeContouringSave={
                                areHoursNotEqualsBeforeContouringSave
                            }
                            IDs={IDs}
                            objCode={objCode}
                        />
                        <Popper
                            placement="top-start"
                            modifiers={{
                                preventOverflow: {
                                    boundariesElement:
                                        document.querySelector(`.peopleWorkload_BottomRightGrid`) ||
                                        'window',
                                    padding:
                                        (isThereBeforeArrow
                                            ? TaskBarBorder.WITH_ARROW
                                            : TaskBarBorder.WITHOUT_ARROW) + 2,
                                },
                            }}
                        >
                            {({ ref, style, placement }) => (
                                <div ref={ref} style={style} data-placement={placement}>
                                    <AllocationTooltipContouring
                                        IDs={IDs}
                                        isThereBeforeArrow={isThereBeforeArrow}
                                        isStartDateInLastTreeCells={isStartDateInLastTreeCells}
                                        showAllocationsInHoursMode={showAllocationsInHoursMode}
                                    />
                                </div>
                            )}
                        </Popper>
                    </>
                )}
            </Manager>
        </>
    );
});

const mapStateToProps = (state: IWorkSchedulingCombinedState, ownProps): IAllocationsStateProps => {
    const showAllocationsInHoursMode = showAllocationsInHoursModeSelector(state);
    let scheduleHours: TOrUndefined<number[]>;
    let availableHours: TOrUndefined<number[]>;

    if (!showAllocationsInHoursMode) {
        if (ownProps.sectionType === Sections.PEOPLE_WORKLOAD) {
            scheduleHours = scheduleHoursSelector(state, {
                scheduleID: ownProps.userScheduleID,
            }).scheduleHours;
            availableHours = availableHoursSelector(state, { userID: ownProps.userID });
        } else {
            scheduleHours = scheduleHoursSelector(state, {
                scheduleID: ownProps.scheduleID,
                sectionType: Sections.UNASSIGNED_WORK,
            }).scheduleHours;
        }
    }
    const user = getUserSelector(state)(ownProps.userID);
    const activePeriodMode = stepUnitSelector(state);
    const { isDayMode } = getPeriodModesValues(activePeriodMode);
    const timeOffs = isDayMode
        ? timeOffsSelectorCached(state, { userID: ownProps.userID })
        : timeOffsSelectorForWeekMonthViewCached(state, {
              scheduleID: user?.scheduleID,
              userID: ownProps.userID,
          });
    return {
        stepUnit: stepUnitSelector(state),
        scheduleWorkDays: scheduleWorkDaysSelector(state)(
            ownProps.userScheduleID || ownProps.scheduleID
        ),
        contouringRowID: contouringRowIdSelector(state),
        activePeriodMode,
        showActualProgress: showActualProgressSelector(state),
        showAllocationsInHoursMode,
        availableHours,
        scheduleHours,
        steps: stepsSelector(state),
        timeOffs,
    };
};

export const Allocations = connect(mapStateToProps)(AllocationsComponent);
