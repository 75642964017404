import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const modernDnDContext = 'modern-dnd-context';

export const DndWrapper = (props): JSX.Element => {
    /**
     * We bound HTML5Backend to Modern's context, to avoid HTML5Backend duplicate errors
     * */
    const context = document.getElementById(modernDnDContext);
    return (
        <DndProvider backend={HTML5Backend} options={{ rootElement: context }}>
            {props.children}
        </DndProvider>
    );
};
