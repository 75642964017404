import { IProjectTimelineInfo } from '../data/IDataState';

const PROJECT_DEFAULT_TIMELINE_INFO = {
    hoursPerDay: 8,
    daysPerWeek: 5,
    weeksPerMonth: 4,
};

export function getInitialStateProjectTimelineState(): IProjectTimelineInfo {
    return PROJECT_DEFAULT_TIMELINE_INFO;
}
