import { A } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import * as React from 'react';
import { Sections } from '../../../../constants/schedulingTableConstants';
import { GlobalPropsContext } from '../../../../contexts/globalContexts';
import { IAreaState } from '../../../../data-flow/areaData/areaRelatedInitialDataState';
import {
    closeAllPanelsChain,
    resetContouringEditModeChain,
} from '../../../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import changeFilterOpenState from '../../../../data-flow/filters/filtersActions/changeFilterOpenState';
import actionChain from '../../../../data-flow/higher-order-reducers/actionChain';
import {
    addFilterCss,
    coffeeCupImageCss,
    emptyUnassignedTextStyle,
    EmptyUnassignedWrapper,
    noFilterImageCss,
} from '../../../styles/EmptyUnassignedSectionStyles';
import { resetButtonStyle } from '../../../styles/headerComponentStyles';
import { useAppDispatch } from '../../../../data-flow/hooks';

interface IEmptyUnassignedSectionProps {
    tableViewportWidth?: number;
    leftSidePanelWidth: number;
    schedulingAreaID: IAreaState['schedulingAreaID'];
    isFilterApplied: boolean;
}

const emptySectionOptions = {
    filterExists: {
        image: coffeeCupImageCss,
        descriptionKey: 'resourcescheduling.no.unassigned.tasks.message',
        fallbackForDescriptionKey: 'There is no unassigned work for this date range',
        subDescriptionKey: '',
        fallbackForSubDescriptionKey: '',
    },
    filterNotExists: {
        image: noFilterImageCss,
        descriptionKey: 'workloadbalancer.no.unassigned.filter.description',
        fallbackForDescriptionKey:
            'There is unassigned work from all departments in your organization.',
        subDescriptionKey: 'workloadbalancer.add.unassigned.filter.description',
        fallbackForSubDescriptionKey: 'Add filters to view work that is relevant to you.',
    },
};

export const emptyUnassignedSectionComponentTestIds = {
    resetButton: 'reset-button',
    emptyUnassignedWrapper: 'empty-unassigned-wrapper',
};

export const EmptyUnassignedSection = React.memo<IEmptyUnassignedSectionProps>(
    function EmptyUnassignedSection(props) {
        const { minixState, minixClose } = React.useContext(GlobalPropsContext);
        const dispatch = useAppDispatch();

        const openNewFilter = (): void => {
            if (minixState) {
                minixClose();
            }

            dispatch(
                actionChain([
                    ...resetContouringEditModeChain(),
                    ...closeAllPanelsChain(),
                    changeFilterOpenState(true, Sections.UNASSIGNED_WORK),
                ])
            );
        };

        const { isFilterApplied, schedulingAreaID, tableViewportWidth, leftSidePanelWidth } = props;

        const options =
            isFilterApplied || schedulingAreaID
                ? emptySectionOptions.filterExists
                : emptySectionOptions.filterNotExists;

        let showAddFilter = !isFilterApplied;
        if (schedulingAreaID) {
            showAddFilter = false;
        }

        return tableViewportWidth ? (
            <EmptyUnassignedWrapper
                isFilterApplied={isFilterApplied}
                data-testid={emptyUnassignedSectionComponentTestIds.emptyUnassignedWrapper}
                leftSidePanelWidth={leftSidePanelWidth}
                tableViewportWidth={tableViewportWidth}
            >
                <div className={options.image} />
                <div className={emptyUnassignedTextStyle}>
                    <Localization<string>
                        messageKey={options.descriptionKey}
                        fallback={options.fallbackForDescriptionKey}
                    />
                    {showAddFilter && (
                        <>
                            <div>
                                <Localization<string>
                                    messageKey={options.subDescriptionKey}
                                    fallback={options.fallbackForSubDescriptionKey}
                                />
                            </div>
                            <div className={addFilterCss}>
                                <Localization<string[]> messageKeys={['filter.action.add']}>
                                    {({ _t }) => {
                                        return (
                                            <A
                                                href="javascript://" // eslint-disable-line no-script-url
                                                onClick={openNewFilter}
                                                role="button"
                                                data-testid={
                                                    emptyUnassignedSectionComponentTestIds.resetButton
                                                }
                                                className={resetButtonStyle}
                                                aria-label={_t['filter.action.add']('Add Filter')}
                                            >
                                                {_t['filter.action.add']('Add Filter')}
                                            </A>
                                        );
                                    }}
                                </Localization>
                            </div>
                        </>
                    )}
                </div>
            </EmptyUnassignedWrapper>
        ) : null;
    }
);
