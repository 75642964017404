import { ISettingsState } from '../ISettingsState';

export const ActionType = 'SHOW_REMAINING_HOURS';

const showRemainingHours = () => ({
    type: ActionType,
    payload: {},
});
export default showRemainingHours;

export function handle(state: ISettingsState): ISettingsState {
    return {
        ...state,
        showRemainingHours: !state.showRemainingHours,
    };
}
