import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'CHANGE_PROJECT_IDS_SORTING';

interface IChangeProjectIdsSortingActionPayload {
    projectIDs: string[];
    userID: string;
}

type TChangeProjectIdsSorting = TModernSchedulingAction<IChangeProjectIdsSortingActionPayload>;
const changeProjectIDsSorting = (
    projectIDs: string[],
    userID: string
): TChangeProjectIdsSorting => ({
    type: ActionType,
    payload: {
        projectIDs,
        userID,
    },
});
export default changeProjectIDsSorting;

export function handle(state: IDataState, { payload }: TChangeProjectIdsSorting): IDataState {
    const users = { ...state.users };

    users[payload.userID] = {
        ...users[payload.userID],
        projectIDs: payload.projectIDs,
    };

    return {
        ...state,
        users,
    };
}
