import ApprovalsLineIcon from 'phoenix-icons/dist/ApprovalsLineIcon.js';
import React from 'react';
import { primary } from '@phoenix/all';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { cx } from 'react-emotion';
import {
    AssigmentPositioningStyled,
    AssignmentWrapperStyled,
} from '../../styles/allocationsComponentStyles';
import {
    AfterPeriod,
    assignmentDetails,
    beforePeriodStyle,
    CompleteStyle,
} from '../../styles/sharedStyles';
import { AssignmentStyled, doubleClickHandlerDiv } from '../../styles/AssignmentComponentStyles';
import { Allocations } from './Allocations';
import { sizes, Sections } from '../../../constants/schedulingTableConstants';
import {
    checkIsPenultimateDayInView,
    isAfterPeriod,
    isInLastTreeCells,
} from '../../../util/durationStylesCalculation';
import { DoneMark, MsgKeyCompleted } from './DoneMark';
import { IWorkSchedulingCombinedState, UnitOfTime } from '../../../data-flow/types';
import {
    IFullTimeOffSteps,
    IObjectState,
    IUnassignedTaskState,
    TAssignmentDialogID,
    TContouringRowID,
    TDate,
    TDropdownOpened,
    TOrUndefined,
    TWorkPerDays,
} from '../../../data-flow/data/IDataState';
import {
    assignmentDialogDetailsSelector,
    dropdownOpenedSelector,
} from '../../../data-flow/data/selectors/dataSelectors';
import { resetContouringEditMode } from '../../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import { GlobalPropsContext } from '../../../contexts/globalContexts';
import { showAllocationsSelector } from '../../../data-flow/settings/settingsSelector';
import { AssignmentTitle } from './AssignmentTitle';

export interface IAssignmentBarProps {
    nodeData: IObjectState | IUnassignedTaskState;
    assignmentWidth: number;
    isInDisableMode: boolean;
    sectionType: Sections;
    isAssigned: boolean;
    calculatedAssignmentWidth: number;
    ID: string;
    isComplete?: boolean;
    isTheLastRow: boolean | number | undefined;
    onClickHandler: (e: any) => void;
    dispatch: any;
    editContouring: boolean;
    fullTimeOffsStepsByDay?: IFullTimeOffSteps;
    shouldMarkAsCompleted: boolean;
    userScheduleID?: string;
    crossedOutHoursForPeriod?: number[];
    workPerDays: TWorkPerDays;
    idExpression: string;
    isDraggingEnabled?: boolean;
    dragLayer?: boolean;
    isThereBeforeArrow: boolean;
    isThereAfterArrow: boolean;
    allocationsEditingMode: boolean;
    startDate: Moment;
    endDate: Moment;
    cellWidth: number;
    stepUnit: UnitOfTime;
    contouringRowID: TContouringRowID;
    assignmentColorBG: TOrUndefined<string>;
    assignmentBackground: string;
    assignmentColorText?: string;
}

interface IAssignmentBarStateProps {
    assignmentDialogOpenedForID: TAssignmentDialogID;
    dropdownOpened: TDropdownOpened;
    IDExpressionForMinixState: string | null;
    isShowAllocationOn: boolean;
}

const AssignmentBar: React.FunctionComponent<IAssignmentBarProps & IAssignmentBarStateProps> =
    React.memo((props) => {
        const {
            contouringRowID,
            nodeData,
            idExpression,
            startDate,
            endDate,
            assignmentWidth,
            stepUnit,
            isInDisableMode,
            sectionType,
            isAssigned,
            calculatedAssignmentWidth,
            ID,
            isComplete,
            IDExpressionForMinixState,
            assignmentDialogOpenedForID,
            isTheLastRow,
            dropdownOpened,
            onClickHandler,
            isShowAllocationOn,
            dispatch,
            editContouring,
            cellWidth,
            crossedOutHoursForPeriod,
            fullTimeOffsStepsByDay,
            shouldMarkAsCompleted,
            userScheduleID,
            workPerDays,
            isDraggingEnabled,
            dragLayer,
            isThereBeforeArrow,
            isThereAfterArrow,
            allocationsEditingMode,
            assignmentColorBG,
            assignmentBackground,
            assignmentColorText,
        } = props;

        const {
            objCode,
            name: title,
            plannedStartDate,
            plannedCompletionDate,
            areHoursNotEqualsBeforeContouringSave,
            scheduleID,
            actualCompletionDate,
        } = nodeData;

        const globalPropsContext = React.useContext(GlobalPropsContext);

        const assignmentStartDate = React.useMemo(
            () => moment(plannedStartDate),
            [plannedStartDate]
        );
        const assignmentEndDate = React.useMemo(
            () => moment(plannedCompletionDate),
            [plannedCompletionDate]
        );

        const assignmentStartDateInPeriod = getAssignmentStartDateInPeriod(
            startDate,
            assignmentStartDate
        );
        const assignmentEndDateInPeriod = getAssignmentEndDateInPeriod(endDate, assignmentEndDate);
        const assignmentActualEndDate = getAssignmentActualEndDate(actualCompletionDate);

        const isPenultimateDayInView = checkIsPenultimateDayInView(
            stepUnit,
            endDate,
            assignmentEndDate
        );

        const isStartDateInLastTreeCells = isInLastTreeCells(
            stepUnit,
            endDate,
            assignmentStartDate.clone().add(3, 'days')
        );

        const preventDoubleClickHandlerCall = (e): void => {
            if (contouringRowID === idExpression) {
                e.stopPropagation();
            }
        };

        const keyUpHandler = (e): void => {
            if (e.key === 'Escape') {
                dispatch(resetContouringEditMode());
            }
        };
        const ids = props.idExpression.split('_');

        const allocationClassName = getAllocationClassName(
            allocationsEditingMode,
            isShowAllocationOn
        );
        const shouldShowAllocations = isShowAllocations(
            isShowAllocationOn,
            allocationsEditingMode,
            editContouring,
            dragLayer
        );
        const shouldShowTitle = isShowTitle(isShowAllocationOn, allocationsEditingMode, dragLayer);

        return (
            <AssigmentPositioningStyled
                allocationsEditingMode={allocationsEditingMode}
                areHoursNotEqualsBeforeContouringSave={areHoursNotEqualsBeforeContouringSave}
                data-testid={`${ID}-styled`}
            >
                {isThereBeforeArrow && (
                    <span
                        data-testid="beforePeriod"
                        className={cx(
                            beforePeriodStyle(
                                assignmentColorBG,
                                allocationsEditingMode,
                                isInDisableMode
                            ),
                            'beforePeriodStyle'
                        )}
                    />
                )}
                <AssignmentWrapperStyled
                    allocationsEditingMode={allocationsEditingMode}
                    data-testid={`${ID}-wrapper`}
                >
                    {calculatedAssignmentWidth > 0 ? (
                        <AssignmentStyled
                            backGroundColor={assignmentBackground}
                            isThereBeforeArrow={isThereBeforeArrow}
                            isThereAfterArrow={isThereAfterArrow}
                            isPenultimateDayInView={isPenultimateDayInView}
                            data-testid={ID}
                            id={`${isAssigned}_${ID}`}
                            isAssigned={isAssigned}
                            isComplete={isComplete}
                            assignmentWidth={calculatedAssignmentWidth}
                            idExpression={idExpression}
                            minixState={globalPropsContext.minixState}
                            isInDisableMode={isInDisableMode}
                            IDExpressionForMinixState={IDExpressionForMinixState}
                            isThreeDotItemClicked={
                                assignmentDialogOpenedForID === idExpression ||
                                dropdownOpened === idExpression
                            }
                            areHoursNotEqualsBeforeContouringSave={
                                areHoursNotEqualsBeforeContouringSave
                            }
                            allocationsEditingMode={allocationsEditingMode}
                            onClick={onClickHandler}
                            onKeyUp={keyUpHandler}
                            showPointerCursor={!!globalPropsContext.minixState || isDraggingEnabled}
                            dragLayer={dragLayer}
                        >
                            {/* TODO resolve eslint issue */}
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                            <div
                                className={allocationClassName}
                                onClick={preventDoubleClickHandlerCall}
                            >
                                {shouldShowAllocations && (
                                    <Allocations
                                        assignmentActualEndDate={assignmentActualEndDate}
                                        allocationsEditingMode={allocationsEditingMode}
                                        areHoursNotEqualsBeforeContouringSave={
                                            areHoursNotEqualsBeforeContouringSave
                                        }
                                        assignmentEndDate={assignmentEndDateInPeriod}
                                        assignmentStartDate={assignmentStartDateInPeriod}
                                        backGroundColor={assignmentColorBG}
                                        cellWidth={cellWidth}
                                        crossedOutHoursForPeriod={crossedOutHoursForPeriod}
                                        fullTimeOffsStepsByDay={fullTimeOffsStepsByDay}
                                        height={sizes.taskAssignmentHeight}
                                        idExpression={props.idExpression}
                                        isAccessible
                                        isCompleted={shouldMarkAsCompleted}
                                        isPenultimateDayInView={isPenultimateDayInView}
                                        isStartDateInLastTreeCells={isStartDateInLastTreeCells}
                                        isTheLastRow={isTheLastRow}
                                        isThereAfterArrow={isThereAfterArrow}
                                        isThereBeforeArrow={isThereBeforeArrow}
                                        objCode={objCode}
                                        plannedCompletionDate={plannedCompletionDate}
                                        plannedStartDate={plannedStartDate}
                                        sectionType={sectionType}
                                        stepStartDate={startDate}
                                        userScheduleID={userScheduleID}
                                        workPerDays={workPerDays}
                                        userID={ids[0]}
                                        scheduleID={scheduleID}
                                        textColor={assignmentColorText}
                                        isFromAssignmentBar
                                    />
                                )}

                                {shouldShowTitle && (
                                    <div className={assignmentDetails}>
                                        <AssignmentTitle
                                            assignmentWidth={assignmentWidth}
                                            assignmentColorText={assignmentColorText}
                                            shouldMarkAsCompleted={shouldMarkAsCompleted}
                                            title={title}
                                        />
                                    </div>
                                )}

                                {isComplete && (
                                    <CompleteStyle
                                        isAfterPeriod={isAfterPeriod(
                                            stepUnit,
                                            endDate,
                                            assignmentEndDate
                                        )}
                                    >
                                        <ApprovalsLineIcon color={primary.white()} />
                                    </CompleteStyle>
                                )}
                            </div>
                        </AssignmentStyled>
                    ) : null}
                </AssignmentWrapperStyled>
                {shouldMarkAsCompleted && calculatedAssignmentWidth > 0 ? (
                    <DoneMark msg={MsgKeyCompleted.TASK} isThereAfterArrow={isThereAfterArrow} />
                ) : null}
                {isThereAfterArrow && (
                    <AfterPeriod
                        data-testid="afterPeriod"
                        borderColor={assignmentColorBG}
                        isInDisableMode={isInDisableMode}
                        className="afterPeriodStyle"
                    />
                )}
            </AssigmentPositioningStyled>
        );
    });

const mapStateToProps = (state: IWorkSchedulingCombinedState): IAssignmentBarStateProps => {
    return {
        assignmentDialogOpenedForID: assignmentDialogDetailsSelector(state).ID,
        dropdownOpened: dropdownOpenedSelector(state),
        IDExpressionForMinixState: state.Data.IDExpressionForMinixState,
        isShowAllocationOn: showAllocationsSelector(state),
    };
};

export const AssignmentBarComponent = connect(mapStateToProps)(AssignmentBar);

const getAssignmentStartDateInPeriod = (startDate: Moment, assignmentStartDate: Moment): Moment => {
    return assignmentStartDate <= startDate ? startDate : assignmentStartDate;
};

const getAssignmentEndDateInPeriod = (endDate: Moment, assignmentEndDate: Moment): Moment => {
    return assignmentEndDate >= endDate ? endDate : assignmentEndDate;
};

const getAssignmentActualEndDate = (actualCompletionDate?: TDate): Moment | null => {
    return actualCompletionDate ? moment(actualCompletionDate) : null;
};

const getAllocationClassName = (
    allocationsEditingMode: boolean,
    isShowAllocationOn: boolean
): string => {
    return !(allocationsEditingMode || isShowAllocationOn) ? doubleClickHandlerDiv : '';
};

const isShowAllocations = (
    isShowAllocationOn: boolean,
    allocationsEditingMode: boolean,
    editContouring: boolean,
    dragLayer?: boolean
): boolean => {
    return (isShowAllocationOn || (allocationsEditingMode && editContouring)) && !dragLayer;
};

const isShowTitle = (
    isShowAllocationOn: boolean,
    allocationsEditingMode: boolean,
    dragLayer?: boolean
): boolean => {
    return (!isShowAllocationOn && !allocationsEditingMode) || !!dragLayer;
};
