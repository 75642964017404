import _ from 'lodash';
import { OpTask, Project, Task, TObjCode, User } from 'workfront-objcodes';
import {
    AssignedToID,
    AssignmentFilteringCriteria,
    AssignmentObjectFilteringCriteria,
    BulkActions,
    completeStatusFilter,
    CONDITIONS_DATA,
    ConditionType,
    FieldKeyTypes,
    FieldObjectsTypes,
    RoleID,
} from '../constants/bulkAssignmentsConstants';
import {
    TCommonFilter,
    IBulkAssignmentsFilterField,
    IFilterConditions,
    IFirstTask,
    IProjectAssignmentAssignedTo,
    IProjectAssignmentRole,
    IProjectFilter,
    IRelevantAssignment,
    IRelevantAssignments,
    ISearchResult,
    TTaskToProjectIDs,
    ISelectedUserData,
} from '../components/BulkAssignments/types';
import {
    TBulkAssignmentUserOption,
    TOrUndefined,
    TProjectID,
    TRoleIDs,
    TTaskOrIssueID,
    TUserID,
} from '../data-flow/data/IDataState';
import { getByMessageKeySync } from './utilities';
import { RecommendedAssignmentsResponseUser } from '../services/api-services/bulkAssignmentsService';
import { ICurrentUser } from './currentUserMapper';

const getTaskOrIssueID = (assignment): string => assignment.task?.ID || assignment.opTask?.ID;

export const getBulkAssignmentFilters = (
    condition: IFilterConditions,
    selectedField: IBulkAssignmentsFilterField,
    value: string | string[] = ''
): TCommonFilter => {
    if (isStatusFiled(selectedField)) {
        return {
            [selectedField.key]: value,
            [`${[selectedField.key]}_Mod`]: condition.conditionType,
        };
    }
    const searchKey = getFilterSearchKey(condition, selectedField.groupLabel);
    return {
        [searchKey]: value,
        [`${[searchKey]}_Mod`]: condition.conditionType,
    };
};

export const getChangedValue = (
    values: ISearchResult[],
    selectedItems: string[]
): ISearchResult | undefined => {
    if (!selectedItems.length) {
        return values[0];
    }
    return values.find((value) => {
        return selectedItems.indexOf(value.ID) === -1;
    });
};

export const getRemovedItem = (
    values: ISearchResult[],
    selectedValue: ISearchResult[]
): string | undefined => {
    const newItems = values.map((el) => el.ID);
    const existingItems = selectedValue.map((el) => el.ID);
    return existingItems.find((ID) => {
        return newItems.indexOf(ID) === -1;
    });
};

export const isStatusFiled = (selectedField: IBulkAssignmentsFilterField): boolean => {
    if (selectedField) {
        return (
            selectedField.key === FieldKeyTypes.PROJECT_STATUS ||
            selectedField.key === FieldKeyTypes.TASK_STATUS
        );
    }
    return false;
};

export const getConditions = (selectedField: IBulkAssignmentsFilterField): IFilterConditions[] => {
    if (isStatusFiled(selectedField)) {
        return [...CONDITIONS_DATA.defaultConditions, ...CONDITIONS_DATA.statusConditions];
    }
    return [...CONDITIONS_DATA.defaultConditions, ...CONDITIONS_DATA.nameConditions];
};

export const getDefaultConditionType = (): IFilterConditions => {
    return CONDITIONS_DATA.defaultConditions[0];
};

export const hideResultsDropdown = (condition: IFilterConditions): boolean => {
    if (condition.conditionType) {
        return (
            condition.conditionType === ConditionType.IS_BLANK ||
            condition.conditionType === ConditionType.NOT_BLANK
        );
    }
    return false;
};

export const proceedFilterValue = (
    selectedCriteria: ISearchResult | ISearchResult[] | undefined,
    condition: IFilterConditions
): string | string[] | undefined => {
    let criteria = 'ID';
    if (condition.conditionType === ConditionType.CONTAINS) {
        criteria = 'name';
    }
    if (selectedCriteria) {
        if (Array.isArray(selectedCriteria)) {
            return selectedCriteria.map((el) => el[criteria]);
        }
        return selectedCriteria[criteria];
    }
};

export const getAssignmentsPerCriteria = (
    assignmentList: IRelevantAssignments,
    criteria: AssignmentFilteringCriteria
): IRelevantAssignment[] => {
    const assignments = combineProjectAssignments(assignmentList);
    if (assignments?.length) {
        return assignments.filter((assignment) => {
            if (criteria === RoleID) {
                return (
                    assignment[RoleID] !== null &&
                    assignment.role?.ID &&
                    assignment[AssignedToID] === null
                );
            }

            return assignment[AssignedToID] !== null;
        });
    }
    return [];
};

export const getAssignmentsForAllCriteria = (
    relevantAssignments: IRelevantAssignments,
    criteria: AssignmentFilteringCriteria[]
): {
    [RoleID]: IRelevantAssignment[];
    [AssignedToID]: IRelevantAssignment[];
} => {
    const assignmentsObjByCriteria: {
        [RoleID]: IRelevantAssignment[];
        [AssignedToID]: IRelevantAssignment[];
    } = {
        [RoleID]: [],
        [AssignedToID]: [],
    };

    criteria.forEach((criterion) => {
        assignmentsObjByCriteria[criterion] = getAssignmentsPerCriteria(
            relevantAssignments,
            criterion
        );
    });

    return assignmentsObjByCriteria;
};

export const concatFilterResults = (values): IRelevantAssignment[] => {
    return _.flatten(Object.values(values));
};

export const combineProjectAssignments = (assignments: any): IRelevantAssignment[] => {
    const existedAssignmentData =
        assignments[Task] && Object.keys(assignments[Task]).length > 0
            ? assignments[Task]
            : assignments[Project];
    return concatFilterResults(existedAssignmentData);
};

export const getTaskSearchFilter = (projectFilter: IProjectFilter): TCommonFilter => {
    if (isStatusFiled(projectFilter.selectedField)) {
        return {
            [projectFilter.selectedField.key]: projectFilter.result,
            [`${[projectFilter.selectedField.key]}_Mod`]: projectFilter.condition.conditionType,
        };
    }
    const searchCriteria = getProjectFilterKey(projectFilter.condition);
    return {
        [searchCriteria]: projectFilter.result,
        [`${searchCriteria}_Mod`]: projectFilter.condition.conditionType,
    };
};

export const checkShouldCloseTaskFilter = (
    objCode: TObjCode,
    projectFilter: IProjectFilter | undefined,
    condition
): boolean => {
    if (objCode !== Task || !projectFilter) {
        return false;
    }

    return projectFilter && !projectFilter.result?.length && hideResultsDropdown(condition);
};

export const getInitialBulkAssignmentsState = (): IRelevantAssignments => {
    return {
        [Project]: {},
    };
};

export const clearTaskAssignmentsState = (
    relevantAssignments: IRelevantAssignments
): IRelevantAssignments => {
    const assignments = { ...relevantAssignments };
    delete assignments[Task];

    return assignments;
};

export const excludeFilterResult = (
    object: IRelevantAssignments,
    keyName: string
): Partial<IRelevantAssignments> => {
    return _.omit(object, [keyName] || '');
};

export const getObjectsFromAssignments = (
    assignmentsPerCriteria: IRelevantAssignment[],
    criteria: AssignmentObjectFilteringCriteria
): IProjectAssignmentRole[] => {
    const uniqueValuesSet = new Set();
    return assignmentsPerCriteria
        .filter((assignment) => {
            const itemID = assignment[criteria]?.ID;
            const isPresentInSet = uniqueValuesSet.has(itemID);
            uniqueValuesSet.add(itemID);
            return !isPresentInSet;
        })
        .map((assignment) => {
            const rolesNames = assignment.assignedTo?.userRoles
                ?.map((userRole) => userRole.role.name)
                .join(', ');
            if (criteria === 'assignedTo' && assignment[criteria] !== null) {
                assignment[criteria]!.avatarURL = `/internal/user/avatar?size=MEDIUM&ID=${
                    assignment[criteria]!.ID
                }&time=${assignment[criteria]!.avatarDate}`;
                assignment[criteria]!.subLabel = rolesNames;
            }
            return assignment[criteria] as IProjectAssignmentRole | IProjectAssignmentAssignedTo;
        });
};

export const getTaskWithProjectIdsFilteredByCriteria = (
    assignmentsPerRole: IRelevantAssignment[],
    itemID: string,
    criteria: AssignmentObjectFilteringCriteria
): TTaskToProjectIDs[] => {
    return _.uniqBy(
        assignmentsPerRole.reduce((filteredAssignments, assignment: IRelevantAssignment) => {
            if (assignment[criteria]?.ID === itemID && (assignment.task || assignment.opTask)) {
                filteredAssignments.push({
                    taskOrIssueID: getTaskOrIssueID(assignment),
                    projectID: assignment.project.ID,
                });
            }
            return filteredAssignments;
        }, [] as TTaskToProjectIDs[]),
        'taskOrIssueID'
    );
};

export const isSaveButtonActive = (
    bulkAction: BulkActions,
    changeFrom: string | IProjectAssignmentRole,
    selectedUser: null | TBulkAssignmentUserOption
): boolean => {
    if (bulkAction === BulkActions.ASSIGN_USER) {
        return !!(typeof changeFrom !== 'string' && changeFrom.ID && selectedUser?.ID);
    }
    if (bulkAction === BulkActions.REPLACE_USER) {
        return !!(selectedUser?.ID && typeof changeFrom !== 'string' && changeFrom.ID);
    }
    return !!(typeof changeFrom !== 'string' && changeFrom.ID);
};

export const prepareOptions = (
    user: RecommendedAssignmentsResponseUser,
    currentUserID: TUserID
): TBulkAssignmentUserOption => {
    const name =
        currentUserID === user.ID
            ? getByMessageKeySync(`assignments.assigntome`, `Assign to me`)
            : `${user.firstName} ${user.lastName}`;
    const rolesNames = (): string | undefined => {
        if (user.roles) {
            return user.roles?.map((role) => role.name).join(', ');
        }
        return user.userRoles?.map((userRole) => userRole.role.name).join(', ') || user.role;
    };

    return {
        ID: user.ID,
        name,
        avatarURL: `/internal/user/avatar?size=MEDIUM&ID=${user.ID}&time=${user.avatarDate}`,
        subLabel: rolesNames(),
    };
};

export const getAffectedUserIdsFromAssignments = (
    assignmentsPerRole: IRelevantAssignment[],
    relevantAssignments: IRelevantAssignments
): TUserID[] => {
    const affectedTasksOrIssues = assignmentsPerRole.map(getTaskOrIssueID);
    const assignments = combineProjectAssignments(relevantAssignments);

    return assignments
        .filter((assignment) => {
            if (!assignment.assignedToID) {
                return false;
            }

            if (assignment.task?.ID) {
                return affectedTasksOrIssues.includes(assignment.task?.ID);
            }

            if (assignment.opTask?.ID) {
                return affectedTasksOrIssues.includes(assignment.opTask?.ID);
            }

            return false;
        })
        .map((assignment) => assignment.assignedToID) as TUserID[];
};

const shouldUpdateAssignment = (
    assignedTasks,
    filteredTasks,
    selectedRole,
    assignment
): boolean => {
    const taskOrIssueID = getTaskOrIssueID(assignment);
    return (
        assignment.assignedToID === null &&
        typeof selectedRole !== 'string' &&
        assignment.roleID === selectedRole.ID &&
        !assignedTasks[taskOrIssueID] && // task is already assigned once
        (!filteredTasks || filteredTasks.includes(taskOrIssueID)) // task filter is not active or task is in filtered list
    );
};

const getUpdatedAssignment = (
    assignment: IRelevantAssignment,
    assignedUser: TBulkAssignmentUserOption | null
): IRelevantAssignment => ({
    ...assignment,
    assignedToID: assignedUser?.ID || null,
    assignedTo: assignedUser ? { ...assignedUser, avatarURL: undefined, objCode: User } : null,
});

export const getCleanedUpAssignments = (
    relevantAssignments: IRelevantAssignments,
    selectedRole: string | IProjectAssignmentRole,
    assignedUser: TBulkAssignmentUserOption | null
): IRelevantAssignments => {
    const newProjectAssignments = {
        [Project]: {},
    };
    Object.keys(relevantAssignments).forEach((projOrTaskKey) => {
        const projOrTaskRelevantAssignments = relevantAssignments[projOrTaskKey];
        const alreadyAssignedTasks = {};
        const filteredTaskIDs = relevantAssignments[Task]
            ? Object.keys(relevantAssignments[Task]!)
            : null;

        Object.keys(projOrTaskRelevantAssignments).forEach((projOrTaskID) => {
            const projAssignments = projOrTaskRelevantAssignments[projOrTaskID];
            projAssignments.forEach((assignment) => {
                if (!newProjectAssignments[projOrTaskKey]) {
                    newProjectAssignments[projOrTaskKey] = {};
                }

                const assignmentShouldBeUpdated = shouldUpdateAssignment(
                    alreadyAssignedTasks,
                    filteredTaskIDs,
                    selectedRole,
                    assignment
                );

                let updatedAssgn = assignment;
                if (assignmentShouldBeUpdated) {
                    updatedAssgn = getUpdatedAssignment(assignment, assignedUser);
                    alreadyAssignedTasks[getTaskOrIssueID(assignment)] = true;
                }

                if (!newProjectAssignments[projOrTaskKey][projOrTaskID]) {
                    newProjectAssignments[projOrTaskKey][projOrTaskID] = [updatedAssgn];
                } else {
                    newProjectAssignments[projOrTaskKey][projOrTaskID].push(updatedAssgn);
                }
            });
        });
    });

    return newProjectAssignments;
};

export const getProjectFilterKey = (condition: IFilterConditions): FieldKeyTypes => {
    return condition.conditionType === ConditionType.CONTAINS
        ? FieldKeyTypes.PROJECT_NAME
        : FieldKeyTypes.PROJECT_ID;
};

export const getFilterSearchKey = (
    condition: IFilterConditions,
    objectType: FieldObjectsTypes
): FieldKeyTypes => {
    if (objectType === FieldObjectsTypes.Project) {
        return getProjectFilterKey(condition);
    }
    return condition.conditionType === ConditionType.CONTAINS
        ? FieldKeyTypes.TASK_NAME
        : FieldKeyTypes.TASK_ID;
};

export const getUserName = (user: TBulkAssignmentUserOption, currentUser: ICurrentUser): string => {
    return user.ID === currentUser.ID ? currentUser.name : user.name;
};

export const getFirstTask = (assignmentsPerRole: IRelevantAssignment[]): IFirstTask => {
    if (assignmentsPerRole[0]?.task?.ID) {
        return {
            ID: assignmentsPerRole[0].task.ID,
            objCode: Task,
        };
    }

    return {
        ID: assignmentsPerRole[0]?.opTask?.ID,
        objCode: OpTask,
    };
};

export const getAssignmentHeaderOptions = (): Record<string, TBulkAssignmentUserOption> => ({
    suggestedAssignmentsOption: {
        ID: 'suggested',
        name: getByMessageKeySync('suggested.assignments', 'Suggested assignments'),
        disabled: true,
        avatarURL: '',
    },
    otherAssignmentsOption: {
        ID: 'other',
        name: getByMessageKeySync('otherassignment.plural', 'Other assignments'),
        disabled: true,
        avatarURL: '',
    },
});

export const getUserOptions = (
    recommendedUsers: RecommendedAssignmentsResponseUser[],
    otherUsers: RecommendedAssignmentsResponseUser[],
    currentUserID: string
): TBulkAssignmentUserOption[] => {
    const { suggestedAssignmentsOption, otherAssignmentsOption } = getAssignmentHeaderOptions();
    if (recommendedUsers?.length || otherUsers?.length)
        return [
            ...(recommendedUsers.length ? [suggestedAssignmentsOption] : []),
            ...recommendedUsers.map((user) => prepareOptions(user, currentUserID)),
            ...(otherUsers.length ? [otherAssignmentsOption] : []),
            ...otherUsers.map((user) => prepareOptions(user, currentUserID)),
        ];

    return [];
};

export const canUserBeAssign = (
    user: null | TBulkAssignmentUserOption,
    role: string | IProjectAssignmentRole
): boolean => {
    return !!user && !!role && typeof role !== 'string';
};

export const getUserRolesByAssignments = (
    assignmentsPerUser: IRelevantAssignment[],
    changeFrom: IProjectAssignmentAssignedTo | string
): IProjectAssignmentRole[] => {
    const userRoles: IProjectAssignmentRole[] = [];
    assignmentsPerUser.forEach((assignment) => {
        if (
            !assignment.roleID ||
            (typeof changeFrom !== 'string' && assignment.assignedToID !== changeFrom.ID)
        ) {
            return;
        }
        const isRoleExists = userRoles.find((role) => role.ID === assignment.roleID);
        if (isRoleExists) {
            isRoleExists.assignmentsCount! += 1;
        } else {
            const role = {
                ...assignment.role,
                assignmentsCount: 1,
            } as IProjectAssignmentRole;
            userRoles.push(role);
        }
    });
    return userRoles;
};

export const assignmentPerCriteriaBySelectedRoles = (
    assignmentPerCriteria: IRelevantAssignment[],
    userRoles: IProjectAssignmentRole[]
): IRelevantAssignment[] => {
    const roleIDs = userRoles.map((role) => role.ID);

    return assignmentPerCriteria.filter((assignment) => {
        return assignment.roleID && roleIDs.includes(assignment.roleID);
    });
};

export const getObjectIDsFromTaskToProjectIDs = (
    taskToProjectIDs: TTaskToProjectIDs[]
): { taskOrIssueIDs: TTaskOrIssueID[]; projectIDs: TProjectID[] } => {
    const taskOrIssueIDs: TTaskOrIssueID[] = [];
    const projectIDs = new Set<TProjectID>();

    taskToProjectIDs.forEach((item) => {
        taskOrIssueIDs.push(item.taskOrIssueID);
        projectIDs.add(item.projectID);
    });

    return { taskOrIssueIDs, projectIDs: [...projectIDs] };
};

export const getAdditionalProjectFilter = (
    projectFilter: TOrUndefined<IProjectFilter>
): TCommonFilter => {
    return projectFilter ? getTaskSearchFilter(projectFilter) : {};
};

export const getUserAssignmentIDs = (
    assignmentsPerUser: IRelevantAssignment[],
    userToUnassign: TUserID
): TTaskOrIssueID[] => {
    return assignmentsPerUser.reduce(
        (acc: TTaskOrIssueID[], assignment) =>
            assignment.assignedTo?.ID === userToUnassign ? [...acc, assignment.ID] : acc,
        []
    );
};

export const userAssignmentsExists = (assignmentsPerUser: IRelevantAssignment[]): boolean => {
    return assignmentsPerUser.length > 0;
};

export const getUserRolesDetails = (
    roles: IProjectAssignmentRole[]
): { roleIDs: TRoleIDs; roleNames: string[] } => {
    const roleIDs: TRoleIDs = [];
    const roleNames: string[] = [];
    roles.forEach((role) => {
        roleIDs.push(role.ID);
        roleNames.push(` ${role.name}`);
    });

    return { roleIDs, roleNames };
};

export const getFilterForObject = (
    objCode: TObjCode,
    projectFilter?: IProjectFilter
): TCommonFilter => {
    if (objCode === Task) {
        if (projectFilter) {
            return {
                ...getTaskSearchFilter(projectFilter),
                ...completeStatusFilter.filterForObjCode,
            };
        }
        return {};
    }
    return { ...completeStatusFilter.filterForObjCode };
};

export const getUserRoleToBeAssigned = (
    userData: ISelectedUserData,
    changeFrom: IProjectAssignmentRole
): IProjectAssignmentRole | undefined => {
    return userData.userRoles.find(({ role: { ID } }) => ID === changeFrom.ID)?.role;
};
