import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'CHANGE_UNASSIGNED_PROJECT_OFFSET';

interface IChangeUnaasignedNodesOffsetActionPayload {
    idExpression: string;
    offset: number;
}

export type TChangeUnassignedNodesOffset =
    TModernSchedulingAction<IChangeUnaasignedNodesOffsetActionPayload>;
const changeUnassignedProjectOffset = (
    idExpression: string,
    offset: number
): TChangeUnassignedNodesOffset => ({
    type: ActionType,
    payload: {
        idExpression,
        offset,
    },
});
export default changeUnassignedProjectOffset;

export function handle(state: IDataState, { payload }: TChangeUnassignedNodesOffset): IDataState {
    const unassignedTasksProjects = { ...state.unassignedTasksProjects };

    const ids = payload.idExpression.split('_');

    unassignedTasksProjects[ids[0]] = {
        ...unassignedTasksProjects[ids[0]],
        details: {
            ...unassignedTasksProjects[ids[0]].details,
            offset: payload.offset,
        },
    };

    return {
        ...state,
        unassignedTasksProjects,
    };
}
