import { TModernSchedulingAction } from '../../types';
import { IProjectTimelineInfo } from '../../data/IDataState';

export const ActionType = 'GET_PROJECT_TIMELINE_INFO';

interface IGetProjectTimelineInfo {
    projectTimelineInfo: IProjectTimelineInfo;
}

export type TGetProjectTimelineInfoPayload = TModernSchedulingAction<IGetProjectTimelineInfo>;

const getProjectTimelineInfo = (
    projectTimelineInfo: IProjectTimelineInfo
): TGetProjectTimelineInfoPayload => ({
    type: ActionType,
    payload: {
        projectTimelineInfo,
    },
});

export default getProjectTimelineInfo;

export function handle(
    state: IProjectTimelineInfo,
    { payload }: TGetProjectTimelineInfoPayload
): IProjectTimelineInfo {
    return {
        ...state,
        ...payload.projectTimelineInfo,
    };
}
