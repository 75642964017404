import * as React from 'react';
import { DivContentGrey, wrapperDivStyled } from '../../styles/sharedStyles';

export const LeftCellTaskLoading: React.FunctionComponent = () => {
    return (
        <div className={wrapperDivStyled} data-testid="left-cell-task-loading">
            <DivContentGrey loadingProjectItem={false} />
        </div>
    );
};
