import _ from 'lodash';
import { apiDateToDate } from '@workfront/fns';
import { userHasAssignments } from '../../../util/utilities';
import { TModernSchedulingAction } from '../../types';
import {
    IDataState,
    INodesDetailsState,
    IRequestedAssignment,
    IUserAssignmentsState,
} from '../IDataState';
import {
    getProjectedStartCompletionFromNode,
    getProjectIDs,
    getTaskIssueNodeFromAssignment,
    flattenPredecessors,
    mapAssignedUserIDToStatus,
    mapAssignedUserIDToAssignmentID,
} from './addAssignmentsUtils';

export const ActionType = 'ADD_ASSIGNMENTS';

interface IAddAssignmentsActionPayload {
    accessibleAssignments: IRequestedAssignment[][];
    usersIDs: string[];
    addProjectFromStart: boolean;
}

type TAddAssignmentsAction = TModernSchedulingAction<IAddAssignmentsActionPayload>;

const addAssignments = (
    accessibleAssignments,
    usersIDs,
    addProjectFromStart
): TAddAssignmentsAction => ({
    type: ActionType,
    payload: {
        accessibleAssignments,
        usersIDs,
        addProjectFromStart,
    },
});
export default addAssignments;
export const initialProjectNode = {
    nodes: [],
    inaccessibleNodesWorkRequired: 0,
    inaccessibleNodesDates: [],
    inaccessibleNodesWorkPerDays: [],
    inaccessibleNodesWorkPerDaysFromService: {},
    offset: 0,
};

export const isPendingApproval = (status = ''): boolean => {
    return status.indexOf(':A') !== -1;
};

export const getWorkRequiredEditAccess = (objectDetails, hasProjectLifeAfterDeath): boolean => {
    const taskPermissions = objectDetails.permissions.actions;

    const hasEditAccessCondition =
        objectDetails.durationType === 'S' &&
        (taskPermissions.indexOf('EDIT') !== -1 || taskPermissions.indexOf('DELETE') !== -1);

    if (hasEditAccessCondition) {
        // todo should check also dead and complete cases for object and project
        const isObjPending = isPendingApproval(objectDetails.status);
        if (isObjPending) {
            // when task or issue is pending for approval
            // no matter what is project status or project life, no access
            return false;
        }
        // when task or issue is not pending for approval
        // check project pending for approval, if true, return project life after death status
        const isObjProjectPending =
            objectDetails.project && isPendingApproval(objectDetails.project.status);
        if (isObjProjectPending) {
            return hasProjectLifeAfterDeath;
        }
    }

    // when task or issue is not pending for approval
    // when project is not pending ofr approval
    // return global edit access value
    return hasEditAccessCondition;
};

// TODO separate adding task details from adding assignments
export function handle(state: IDataState, { payload }: TAddAssignmentsAction): IDataState {
    const users = { ...state.users };
    const tasksAndIssues = { ...state.tasksAndIssues };

    _.forEach(payload.usersIDs, (userID: string, index: number) => {
        const nodesDetails: INodesDetailsState = { ...users[userID].nodes };
        const projectIDs = getProjectIDs(users[userID].projectIDs);
        const assignments: IUserAssignmentsState = {};

        // adding accessibleAssignments
        payload.accessibleAssignments[index].forEach((assignmentItem) => {
            const taskIssueNode: any = getTaskIssueNodeFromAssignment(assignmentItem);

            if (projectIDs.indexOf(taskIssueNode.projectID) === -1) {
                if (payload.addProjectFromStart) {
                    projectIDs.unshift(taskIssueNode.projectID);
                } else {
                    projectIDs.push(taskIssueNode.projectID);
                }
            }

            assignments[assignmentItem.ID] = taskIssueNode.ID;

            if (!nodesDetails[taskIssueNode.projectID]) {
                nodesDetails[taskIssueNode.projectID] = initialProjectNode;
            }

            nodesDetails[taskIssueNode.projectID] = {
                ...nodesDetails[taskIssueNode.projectID],
                nodes: _.uniq([...nodesDetails[taskIssueNode.projectID].nodes, taskIssueNode.ID]),
            };

            const { projectedStartDate, projectedCompletionDate } =
                getProjectedStartCompletionFromNode(taskIssueNode);

            if (!tasksAndIssues[taskIssueNode.ID]) {
                tasksAndIssues[taskIssueNode.ID] = {
                    name: taskIssueNode.name,
                    projectID: taskIssueNode.projectID,
                    projectGroupID: taskIssueNode.project && taskIssueNode.project.groupID,
                    projectStatus: taskIssueNode.project && taskIssueNode.project.status,
                    projectName: taskIssueNode.project && taskIssueNode.project.name,
                    plannedCompletionDate: apiDateToDate(taskIssueNode.plannedCompletionDate),
                    plannedStartDate: apiDateToDate(taskIssueNode.plannedStartDate),
                    objCode: taskIssueNode.objCode,
                    workRequired: { [userID]: assignmentItem.workRequired },
                    workRequiredEditAccess: getWorkRequiredEditAccess(
                        taskIssueNode,
                        state.hasProjectLifeAfterDeath
                    ),
                    parent: taskIssueNode.parent,
                    canStart: taskIssueNode.canStart,
                    predecessors: flattenPredecessors(taskIssueNode.predecessors),
                    assignmentUserIDToStatus: mapAssignedUserIDToStatus(taskIssueNode.assignments),
                    assignmentUserIDToAssignmentID: mapAssignedUserIDToAssignmentID(
                        taskIssueNode.assignments
                    ),
                    isWorkRequiredLocked: taskIssueNode.isWorkRequiredLocked,
                    plannedHours: taskIssueNode.workRequired,
                    duration: taskIssueNode.duration,
                    durationType: taskIssueNode.durationType,
                    status: taskIssueNode.status,
                    durationUnit: taskIssueNode.durationUnit,
                    isDurationLocked: taskIssueNode.isDurationLocked,
                    recurrenceRuleID: taskIssueNode.recurrenceRuleID,
                    assignAccess:
                        taskIssueNode.permissions.actions.indexOf('EDIT_ASSIGNMENTS') !== -1,
                    areHoursNotEqualsBeforeContouringSave: false,
                    projectedStartDate,
                    projectedCompletionDate,
                    actualCompletionDate: apiDateToDate(taskIssueNode.actualCompletionDate),
                };
            } else {
                tasksAndIssues[taskIssueNode.ID] = {
                    ...tasksAndIssues[taskIssueNode.ID],
                    // The task/issue dates can be changed after assignment or from Minix and Advance Assignments
                    plannedCompletionDate: apiDateToDate(taskIssueNode.plannedCompletionDate),
                    plannedStartDate: apiDateToDate(taskIssueNode.plannedStartDate),
                    projectedStartDate,
                    projectedCompletionDate,
                    assignmentUserIDToAssignmentID: mapAssignedUserIDToAssignmentID(
                        taskIssueNode.assignments
                    ),
                    workRequired: {
                        ...tasksAndIssues[taskIssueNode.ID].workRequired,
                        [userID]: assignmentItem.workRequired,
                    },
                    isWorkRequiredLocked: taskIssueNode.isWorkRequiredLocked,
                    plannedHours: taskIssueNode.workRequired,
                    duration: taskIssueNode.duration,
                    status: taskIssueNode.status,
                    durationType: taskIssueNode.durationType,
                    durationUnit: taskIssueNode.durationUnit,
                    isDurationLocked: taskIssueNode.isDurationLocked,
                };
            }
        });

        users[userID] = {
            ...users[userID],
            assignments: { ...users[userID].assignments, ...assignments },
            projectIDs: _.uniq(projectIDs),
            nodes: nodesDetails,
            hasAssignments: userHasAssignments(nodesDetails),
        };
    });

    return {
        ...state,
        tasksAndIssues,
        users,
    };
}
