import { Project, Task } from 'workfront-objcodes';
import toggleLoading from '../../dataLoadingActions/toggleLoading';
import { Sections } from '../../../../constants/schedulingTableConstants';
import removeProjectsAndTasks from '../../sharedActions/removeProjectsAndTasks';
import removeTableDataIDsForUnassignedSection from '../../unassignedDataActions/removeTableDataIDsForUnassignedSection';
import changeUnassignedDataRequestMode from '../../unassignedDataActions/changeUnassignedDataRequestMode';
import removeExpandedFlagsForUnassignedSection from '../../unassignedDataActions/removeExpandedFlagsForUnassignedSection';
import removeWorkPerDaysByUsersAssignments from '../removeWorkPerDaysByUsersAssignments';
import removeAllUsers from '../removeAllUsers';
import removeTableDataIDs from '../removeTableDataIDs';
import removeUsersAddedByAssignments from '../removeUsersAddedByAssignments';

export const cleanUpUnassignedBoardDataActions = (): any[] => {
    // TODO may be should remove workPerDaysByAssignments for unassigned
    // TODO be  carefull with state
    return [
        toggleLoading(Sections.UNASSIGNED_WORK, true),
        removeProjectsAndTasks(Sections.UNASSIGNED_WORK),
        removeTableDataIDsForUnassignedSection(''),
        changeUnassignedDataRequestMode(false, Project),
        changeUnassignedDataRequestMode(false, Task),
        removeExpandedFlagsForUnassignedSection(),
    ];
};

export const cleanUpAssignedBoardDataActions = (): any[] => {
    return [
        toggleLoading(Sections.PEOPLE_WORKLOAD, true),
        removeWorkPerDaysByUsersAssignments(),
        removeProjectsAndTasks(Sections.PEOPLE_WORKLOAD),
        removeAllUsers(),
        removeTableDataIDs('all'),
        removeUsersAddedByAssignments(),
    ];
};
