import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'SHOW_ERROR_RED_BORDER';

interface IEditRowPayload {
    ID: string;
    status: boolean;
}

type TEditRowAction = TModernSchedulingAction<IEditRowPayload>;

const showErrorRedBorder = (ID, status): TEditRowAction => ({
    type: ActionType,
    payload: {
        ID,
        status,
    },
});
export default showErrorRedBorder;

export function handle(state: IDataState, { payload }: TEditRowAction): IDataState {
    const { ID } = payload;
    const tasksAndIssues = { ...state.tasksAndIssues };
    let shouldUpdate = false;

    if (ID) {
        if (tasksAndIssues[ID].areHoursNotEqualsBeforeContouringSave !== payload.status) {
            shouldUpdate = true;
            tasksAndIssues[ID].areHoursNotEqualsBeforeContouringSave = payload.status;
        }
    } else {
        for (const objectID in tasksAndIssues) {
            if (tasksAndIssues[objectID] !== undefined) {
                const task = tasksAndIssues[objectID];
                if (task.areHoursNotEqualsBeforeContouringSave !== payload.status) {
                    shouldUpdate = true;
                    task.areHoursNotEqualsBeforeContouringSave = payload.status;
                }
            }
        }
    }

    if (!shouldUpdate) {
        // why make a copy , if we didn't make any change?
        return state;
    }

    return {
        ...state,
        tasksAndIssues,
    };
}
