import { useLocalization } from '@workfront/localize-react';
import React from 'react';
import { Moment } from 'moment';
import { getDayViewPeriodContent } from '../../../../util/periodUtils/periodUtils';

interface ITableHeaderDayContentProps {
    unit: Moment;
    keyVal: number;
}

export const TableHeaderDayContent: React.FunctionComponent<ITableHeaderDayContentProps> =
    React.memo((props: ITableHeaderDayContentProps) => {
        const { unit, keyVal } = props;
        const weekNumber = unit.isoWeek() + 1;
        const dayViewPeriodContent = getDayViewPeriodContent(unit, keyVal);

        const [week] = useLocalization('week', 'Week');
        const [weekWithNumber] = useLocalization(
            'resourceplanner.week.number',
            'W{0} {1}',
            weekNumber,
            ''
        );

        return (
            <div
                aria-label={`${week} ${weekNumber} ${unit.format('MMMM')}`}
                id={`${weekNumber}-week`}
            >
                {weekWithNumber + dayViewPeriodContent}
            </div>
        );
    });
