import { TModernSchedulingAction } from '../../types';
import { ITableSizesState } from '../ITableSizesState';

interface IChangeDraggableModeActionPayload {
    isDragging: boolean;
}

export type TChangeDraggableModeAction = TModernSchedulingAction<IChangeDraggableModeActionPayload>;

export const ActionType = 'CHANGE_DRAGGABLE_MODE';

const changeDraggableMode = (isDragging: boolean): TChangeDraggableModeAction => ({
    type: ActionType,
    payload: {
        isDragging,
    },
});
export default changeDraggableMode;

export function handle(
    state: ITableSizesState,
    action: TChangeDraggableModeAction
): ITableSizesState {
    return {
        ...state,
        isDragging: action.payload.isDragging,
    };
}
