import { primary, secondary } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import * as React from 'react';
import styled, { css } from 'react-emotion';
import { connect } from 'react-redux';
import { TaskBarBorder } from '../../../../constants/schedulingTableConstants';
import {
    IContouringCellInfo,
    IRequestedWorkPerDayFromService,
} from '../../../../data-flow/data/IDataState';
import {
    contouringCellInfoSelector,
    temporaryWorkPerDaysSelector,
} from '../../../../data-flow/data/selectors/dataSelectors';
import { IWorkSchedulingCombinedState, UnitOfTime } from '../../../../data-flow/types';
import { compareHoursAfterContouring } from '../../../../util/contouringValidation';
import {
    convertHourToPercent,
    convertMinuteToHour,
    formatNumber,
    roundNumber,
} from '../../../../util/utilities';
import { getPeriodModesValues } from '../../../../util/periodUtils/periodUtils';
import { stepUnitSelector } from '../../../../data-flow/dateRange/selectors/stepUnitSelector';
import { getTaskSelector } from '../../../../data-flow/data/selectors/reselect/getTaskSelector/getTaskSelector';
import { hasWorkRequiredEditAccessSelector } from '../../../../data-flow/currentUser/selectors/hasWorkRequiredEditAccessSelector';
import { workPerDaysByAssignmentsFromServiceSelector } from '../../../../data-flow/data/selectors/workPerDaysByAssignmentsFromServiceSelector';

interface IAllocationTooltipProps extends React.HTMLAttributes<HTMLDivElement> {
    workRequired: number;
    isThereBeforeArrow: boolean;
    isStartDateInLastTreeCells: boolean;
    workPerDayByAssignments: IRequestedWorkPerDayFromService;
    temporaryWorkPerDays: IRequestedWorkPerDayFromService;
    activePeriodMode: UnitOfTime;
    IDs: string[];
    hasWorkRequiredEditAccess: boolean;
    showAllocationsInHoursMode: boolean;
    contouringCellInfo?: IContouringCellInfo;
}

interface ITooltipStyled {
    isThereBeforeArrow: boolean;
    isStartDateInLastTreeCells: boolean;
}

const AllocationTooltipContouringComponent: React.FunctionComponent<IAllocationTooltipProps> = (
    props
) => {
    const {
        workPerDayByAssignments,
        temporaryWorkPerDays,
        activePeriodMode,
        workRequired,
        isStartDateInLastTreeCells,
        isThereBeforeArrow,
        hasWorkRequiredEditAccess,
        showAllocationsInHoursMode,
        contouringCellInfo,
    } = props;

    const { isWeekMode } = getPeriodModesValues(activePeriodMode);

    const hoursDiff = compareHoursAfterContouring(
        workPerDayByAssignments,
        temporaryWorkPerDays,
        isWeekMode
    );
    const signOfNumber = hoursDiff.roundedDiff > 0 ? '+' : '';

    if (hoursDiff.roundedDiff === 0) {
        return null;
    }

    let diff = 0;
    let total;
    let message;

    if (showAllocationsInHoursMode) {
        diff = convertMinuteToHour(hoursDiff.roundedDiff);
        total = convertMinuteToHour(workRequired);
        message = <Localization<string> messageKey="hour.abbr.short" fallback="h" />;
    } else {
        total = 100;
        message = '%';
        // contouringCellInfo should not be undefined when we editing percent view
        // it can be undefined only when editing in hourView
        if (contouringCellInfo) {
            const { availableHour, scheduleHour } = contouringCellInfo;
            diff = roundNumber(
                convertHourToPercent(availableHour, hoursDiff.diff, scheduleHour),
                100
            );
        }
    }

    return (
        <TooltipStyled
            data-testid="allocation-tooltip"
            isStartDateInLastTreeCells={isStartDateInLastTreeCells}
            isThereBeforeArrow={isThereBeforeArrow}
        >
            <div className={hoursWrapper}>
                <span className={!hasWorkRequiredEditAccess ? errorStyle : ''}>
                    {signOfNumber}
                    {formatNumber(diff)}
                    {message}
                </span>
                <span className={hourStyle}>
                    {` / ${formatNumber(total)}`}
                    {message}
                </span>
            </div>
        </TooltipStyled>
    );
};

const TaskBarHeightInEditMode = 36;
const DistanceBetweenTooltipAndTaskBar = 3;

const tooltipSidesStyle = (
    isStartDateInLastTreeCells: boolean,
    isThereBeforeArrow: boolean
): string => {
    if (isStartDateInLastTreeCells) {
        return 'right: -12px;';
    }
    return `left: ${
        isThereBeforeArrow ? -TaskBarBorder.WITH_ARROW : -TaskBarBorder.WITHOUT_ARROW
    }px;`;
};

const TooltipStyled = styled('div')<ITooltipStyled>`
    position: absolute;
    width: max-content;
    ${(props) => tooltipSidesStyle(props.isStartDateInLastTreeCells, props.isThereBeforeArrow)}
    top: -${TaskBarHeightInEditMode + DistanceBetweenTooltipAndTaskBar}px;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: white;
    color: ${primary.gray()};
    box-shadow: 0 1px 4px 0 rgba(0, 35, 64, 0.12), 0 1px 3px 1px rgba(0, 35, 64, 0.24);
    &:after {
        content: '';
        left: ${(props) => (props.isStartDateInLastTreeCells ? 80 : 5)}%;
        position: absolute;
        border-width: 5px;
        border-style: solid;
        border-color: white transparent transparent transparent;
    }
`;

const errorStyle = css`
    color: ${secondary.red(400)};
`;

const hourStyle = css`
    white-space: pre;
`;

const hoursWrapper = css`
    display: flex;
    line-height: 20px;
    padding: 4px 8px;
`;

const mapStateToProps = (state: IWorkSchedulingCombinedState, ownProps): any => {
    const { IDs } = ownProps;
    const task = getTaskSelector(state)(IDs[IDs.length - 1]);

    return {
        temporaryWorkPerDays: temporaryWorkPerDaysSelector(state),
        workPerDayByAssignments: workPerDaysByAssignmentsFromServiceSelector(state),
        activePeriodMode: stepUnitSelector(state),
        workRequired: IDs && IDs.length && task.workRequired[IDs[0]],
        hasWorkRequiredEditAccess: hasWorkRequiredEditAccessSelector(state, task),
        contouringCellInfo: contouringCellInfoSelector(state),
    };
};

export const AllocationTooltipContouring = connect(mapStateToProps)(
    AllocationTooltipContouringComponent
);
