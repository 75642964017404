import {
    Assignment,
    Company,
    Group,
    OpTask,
    Portfolio,
    Program,
    Project,
    Role,
    Task,
    Team,
    TObjCode,
    User,
} from 'workfront-objcodes';
import { Sections } from '../schedulingTableConstants';

export const FIELDS_GROUP_KEYS = {
    [Portfolio]: 'portfolio',
    [Project]: 'project',
    [Team]: 'team',
    [Role]: 'role',
    [User]: 'user',
    [Program]: 'program',
    [Company]: 'company',
    [OpTask]: 'opTask',
    [Task]: 'task',
    [Assignment]: 'assignment',
    [Group]: 'homeGroup',
};

export const usersOnProjectGroupKey = 'userAssignments';

export const usersRelatedGroupKeys = [
    FIELDS_GROUP_KEYS[User],
    FIELDS_GROUP_KEYS[Role],
    FIELDS_GROUP_KEYS[Team],
    usersOnProjectGroupKey,
    FIELDS_GROUP_KEYS[Group],
    FIELDS_GROUP_KEYS[Company],
];

export const filterKeyPostfix = 'Wlb';
export const customFieldPrefix = 'DE';
export const customTypeaheadObjectCode = 'REF';

export const usersSortByRoleFilter = {
    'role:name_Sort': 'ciasc',
};

export const usersSortAlphabeticallyFilter = {
    name_Sort: 'ciasc',
};

const unassignedTaskBaseFilterKey = {
    portfolio: {
        key: 'project',
        searchField: 'portfolioID',
        skipKey: false,
    },
    program: {
        key: 'project',
        searchField: 'programID',
        skipKey: false,
    },
    'project:status': {
        key: 'project',
        skipKey: false,
        searchField: 'status',
    },
    team: { skipKey: true, searchField: 'teamID' },
    role: { key: 'assignments', skipKey: false, searchField: 'roleID' },
    task: { skipKey: true },
    company: { skipKey: false, key: 'projectCompanyMM' },
};

export const filterKeys = {
    users: {
        team: { key: 'teams', skipKey: false },
        role: { key: 'roles', skipKey: false },
        user: { skipKey: true },
        userAssignments: { key: 'userAssignments', searchField: 'projectID', skipKey: false },
    },
    assignments: {
        portfolio: {
            key: 'project',
            searchField: 'portfolioID',
            skipKey: false,
        },
        program: {
            key: 'project',
            searchField: 'programID',
            skipKey: false,
        },
        'project:status': {
            key: 'project',
            skipKey: false,
            searchField: 'status',
        },
    },

    unassignedProject: {
        portfolio: {
            searchField: 'portfolioID',
            skipKey: true,
        },
        'project:status': {
            skipKey: true,
            searchField: 'status',
        },
        project: { skipKey: true },
        team: { key: 'unassignedTasks', skipKey: false, searchField: 'teamID' },
        role: { key: 'assignments', skipKey: false, searchField: 'roleID' },
        task: { skipKey: false, key: 'tasks' },
        homeGroup: { skipKey: false, key: 'group' },
    },

    unassignedTasks: {
        ...unassignedTaskBaseFilterKey,
        homeGroup: { skipKey: false, key: 'group' },
    },

    unassignedIssues: {
        ...unassignedTaskBaseFilterKey,
        homeGroup: { skipKey: false, key: 'groupMM' },
    },
};

export const filterLocalStorageKey = {
    [Sections.PEOPLE_WORKLOAD]: 'workloadBalancer.filterID',
    [Sections.UNASSIGNED_WORK]: 'workloadBalancer.unassignedSection.filterID',
};

export const filterUserPrefKey = {
    [Sections.PEOPLE_WORKLOAD]: 'filterID',
    [Sections.UNASSIGNED_WORK]: 'unassignedSection.filterID',
};

export const unsavedFilterPrefix = {
    [Sections.PEOPLE_WORKLOAD]: 'assigned_',
    [Sections.UNASSIGNED_WORK]: 'unassigned_',
};

export const filterNotFoundErrKey = {
    [Sections.PEOPLE_WORKLOAD]: {
        messageKey: 'workloadbalancer.filter.not.found',
        fallBack: 'Filter not found for assigned section',
    },

    [Sections.UNASSIGNED_WORK]: {
        messageKey: 'workloadbalancer.filter.not.found.unassigned',
        fallBack: 'Filter not found for unassigned section',
    },
};

export const UNSAVED_FILTER_DATA = 'UNSAVED_FILTER_DATA';
export const ALL_USER_TASKS_DEFAULT_FILTER = 'allUserTasksDefaultFilter';

/**
 * These objCodes are excluded by this variable `excludedAdvancedFiltersObjCodes`
 * When included, below objCode must be uncommented
 * */
export const objCodesFilterFields: TObjCode[] = [
    OpTask,
    Task,
    Portfolio,
    Team,
    Role,
    User,
    Program,
    Company,
    Assignment,
    Group,
    Project,
];

export const SHARED_QUICK_FILTER_FIELDS_PER_OBJECT = {
    [Project]: ['name', 'status'],
    [Portfolio]: ['ID'],
    [User]: ['name'],
};

export const QUICK_FILTER_FIELDS_PER_OBJECT = {
    ...SHARED_QUICK_FILTER_FIELDS_PER_OBJECT,
    [Team]: ['name'],
    [Role]: ['name'],
};

export const UNASSIGNED_QUICK_FILTER_FIELDS_PER_OBJECT = {
    ...SHARED_QUICK_FILTER_FIELDS_PER_OBJECT,
    [Team]: ['ID'],
    [Role]: ['ID'],
};

export const PROJECTS_SORT_QUICK_FILTER_FIELDS_PER_OBJECT = [
    'plannedStartDate',
    'portfolioPriority',
    'plannedCompletionDate',
    'companyID',
    'groupID',
];

export enum FilterGroup {
    users = 'users',
    assignments = 'assignments',
    unassignedProject = 'unassignedProject',
    unassignedTasks = 'unassignedTasks',
    unassignedIssues = 'unassignedIssues',
}

export const unassignedFilterGroups = new Set([
    FilterGroup.unassignedProject,
    FilterGroup.unassignedTasks,
    FilterGroup.unassignedIssues,
]);

/**
 * Created filters with these fields doesn't work as these
 * fields create 3 level deep filter with Balancer Api implementation.
 * 3 level deep filters currently are not supported.
 * */
export const projectFieldsToExclude = [
    'accessLevelMM',
    'actualDuration',
    'actualWork',
    'allDocumentsOM',
    'allHoursOM',
    'allNotesOM',
    'assignments',
    'awaitingApprovalStepApproversMM',
    'duration',
    'favoritedByUsersMM',
    'goalsMM',
    'isComplete',
    'notes',
    'objectCategoriesMM',
    'opTasks',
    'opTasksOM',
    'openOpTasks',
    'pendingApproval',
    'pendingIssues',
    'projectID',
    'projectUserIDs',
    'projectUserRolesRolesMM',
    'projectUserRolesUsersMM',
    'projectUsersMM',
    'resourceManagerIDs',
    'resourcePoolIDs',
    'roleIDs',
    'statusEquatesWith',
    'statusModifier',
    'unassignedTasks',
];
