import { IDataState } from '../IDataState';
import { TModernSchedulingAction } from '../../types';

export const ActionType = 'REMOVE_TEMPORARY_WORK_PER_DAY_HOURS';
type TRemoveTemporaryWorkPerDayHoursActionPayload = Record<string, never>;

type TRemoveTemporaryWorkPerDayHours =
    TModernSchedulingAction<TRemoveTemporaryWorkPerDayHoursActionPayload>;

const removeTemporaryWorkPerDayHours = (): TRemoveTemporaryWorkPerDayHours => ({
    type: ActionType,
    payload: {},
});
export default removeTemporaryWorkPerDayHours;

export function handle(state: IDataState): IDataState {
    const isEmpty = Object.keys(state.temporaryWorkPerDays).length === 0;

    if (isEmpty) {
        return state;
    }

    return {
        ...state,
        temporaryWorkPerDays: {
            userID: null,
            assignmentID: null,
            data: {},
        },
    };
}
