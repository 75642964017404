import { primary } from '@phoenix/all';

export const INACCESSIBLE_DURATION_COLOR = primary.gray(300);
export const INACCESSIBLE_GAPS_COLOR = primary.gray(200);
export const INACCESSIBLE_TEXT_COLOR = primary.gray(500);

export const ACCESSIBLE_PROJECT_PRIMARY_DARK_BACKGROUND_COLOR = primary.blue(500);
export const ACCESSIBLE_PROJECT_PRIMARY_LIGHT_BACKGROUND_COLOR = primary.blue(50);
export const ACCESSIBLE_PROJECT_PRIMARY_TEXT_COLOR = primary.white();

export const TASK_BG_COLOR = '#3393DF';
export const TASK_BG_IMAGE = '#288DDD';
export const ISSUE_BG_COLOR = '#B13764';
export const INACCESSIBLE_ISSUE_BG_COLOR = '#E7C3D0';
export const FOCUSED_INPUT_BG_COLOR = 'rgba(255, 255, 255, 0.2)';
export const INACCESSIBLE_TASK_BG_COLOR = '#B3D6F3';
