import { createSelector } from 'reselect';
import { sizes } from '../../../constants/schedulingTableConstants';
import { stepsSelector } from '../../dateRange/selectors/stepsSelector';
import { tableWidthSelector } from './tableWidthSelector';
import { getPeriodModesValues } from '../../../util/periodUtils/periodUtils';
import { stepUnitSelector } from '../../dateRange/selectors/stepUnitSelector';
import { tableLeftSidePanelWidthSelector } from './tableSizesSelectors';

export const cellWidthSelector = createSelector(
    [stepUnitSelector, tableWidthSelector, stepsSelector, tableLeftSidePanelWidthSelector],
    (stepUnit, tableWidth, steps, tableLeftSidePanelWidth) => {
        const { isWeekMode } = getPeriodModesValues(stepUnit);

        const minWidth = isWeekMode
            ? sizes.cellMinWidthWeeksMode
            : sizes.cellMinWidthDayModeRedesign;

        const cellWidth = (tableWidth - tableLeftSidePanelWidth - 2) / steps.length;

        return cellWidth < minWidth ? minWidth : cellWidth;
    }
);
