import { IWorkSchedulingCombinedState } from '../../../types';
import {
    IUnassignedTaskProjectValue,
    IUnassignedTasksProjects,
    TLoadedUnassignedProjects,
} from '../../IDataState';

export const getUnassignedTasksProjectSelector = (
    state: IWorkSchedulingCombinedState,
    { ...obj }
): IUnassignedTaskProjectValue => {
    return state.Data.unassignedTasksProjects[obj.projectID];
};

export const getUnassignedTasksProjectsSelector = (
    state: IWorkSchedulingCombinedState
): IUnassignedTasksProjects => {
    return state.Data.unassignedTasksProjects;
};

export const loadedUnassignedProjectsCountSelector = (
    state: IWorkSchedulingCombinedState
): TLoadedUnassignedProjects => {
    return Object.keys(state.Data.unassignedTasksProjects).length;
};
