import { Sections } from '../../../constants/schedulingTableConstants';
import {
    generateGroupKey,
    mergeFilterExpressions,
    separateUsersAndAssignmentsFilters,
} from '../../../util/filters/filterUtil';
import { TModernSchedulingAction } from '../../types';
import { IFiltersState, ITritonFilter } from '../IFiltersState';

export const ActionType = 'ADD_FILTERS';

interface IAddFiltersActionPayload {
    filterData: ITritonFilter[];
    section: Sections;
}

export type TAddFiltersAction = TModernSchedulingAction<IAddFiltersActionPayload>;

const addFilters = (filterData: ITritonFilter[], section: Sections): TAddFiltersAction => ({
    type: ActionType,
    payload: {
        filterData,
        section,
    },
});
export default addFilters;

export function handle(state: IFiltersState, { payload }: TAddFiltersAction): IFiltersState {
    const { filterData, section } = payload;

    const filterDetails = filterData as ITritonFilter[];
    const mergedFilterExpression = mergeFilterExpressions(filterDetails);
    const filterExceptions =
        section === Sections.PEOPLE_WORKLOAD
            ? separateUsersAndAssignmentsFilters(mergedFilterExpression)
            : {
                  filterExpression: {
                      ...generateGroupKey(mergedFilterExpression).filterExpression,
                  },
              };
    return {
        ...state,
        [section]: {
            ...state[section],
            filterName: filterDetails.length === 1 ? filterData[0].name : '',
            filterID: filterDetails.map((filter) => filter.ID).filter(Boolean),
            ...filterExceptions,
            rawData: filterDetails,
            appliedFiltersCount: filterDetails.length,
        },
    };
}
