import { TModernSchedulingAction } from '../../types';
import { IDataState, TObjectsInAssignmentMode } from '../IDataState';

export const ActionType = 'REMOVE_OBJECT_FROM_ASSIGNMENT_MODE';

interface IRemoveObjectsFromAssignmentMode {
    objectIDs: string[];
}

type TEditRowAction = TModernSchedulingAction<IRemoveObjectsFromAssignmentMode>;

const removeObjectsFromAssignmentMode = (objectIDs): TEditRowAction => ({
    type: ActionType,
    payload: {
        objectIDs,
    },
});
export default removeObjectsFromAssignmentMode;

export function handle(state: IDataState, { payload }: TEditRowAction): IDataState {
    const { objectIDs } = payload;

    let objectsInAssignmentMode: TObjectsInAssignmentMode = (
        state.objectsInAssignmentMode || []
    ).filter((objID) => !objectIDs.includes(objID));

    if (objectsInAssignmentMode.length === 0) objectsInAssignmentMode = null;

    return {
        ...state,
        objectsInAssignmentMode,
    };
}
