import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Sections } from '../../constants/schedulingTableConstants';
import { BulkAssignmentWrapperForLazy } from '../BulkAssignments/BulkAssignmentWrapperForLazy';
import { FilterContainer } from '../filters/FilterContainer';
import { RoleSummaryContainer } from '../roleSummary/RoleSummaryContainer';
import {
    TIsBulkAssignmentPanelOpened,
    TIsPanelOpened,
    TShowRoleSummary,
} from '../../data-flow/data/IDataState';
import { IAreaState } from '../../data-flow/areaData/areaRelatedInitialDataState';
import { projectStatusesSelector } from '../../data-flow/filters/selectors/filterIDSelector';
import {
    IBaseFilterFields,
    IProjectStatusesForFilter,
} from '../../data-flow/filters/IFiltersState';
import { FieldsMetadataLoader } from './FieldsMetadataLoader';

const SettingsPanel = lazy(() =>
    import('../Settings/SettingsPanel').then((module) => ({ default: module.SettingsPanel }))
);

interface ISchedulingPanelProps {
    isFilterOpen: boolean;
    showRoleSummary: TShowRoleSummary;
    isPanelOpened: TIsPanelOpened;
    isBulkAssignmentPanelOpened: TIsBulkAssignmentPanelOpened;
    schedulingAreaData: IAreaState;
}

interface ISchedulingPanelPropsFromState {
    projectStatuses: IProjectStatusesForFilter[];
}

export interface IFilterFields {
    [Sections.UNASSIGNED_WORK]: IBaseFilterFields;
    [Sections.PEOPLE_WORKLOAD]: IBaseFilterFields;
}

const SchedulingPanelsComponent: React.FunctionComponent<
    ISchedulingPanelProps & ISchedulingPanelPropsFromState
> = (props) => {
    const {
        isFilterOpen,
        isPanelOpened,
        isBulkAssignmentPanelOpened,
        showRoleSummary,
        schedulingAreaData,
        projectStatuses,
    } = props;

    return (
        <FieldsMetadataLoader
            projectStatuses={projectStatuses}
            schedulingAreaObjCode={schedulingAreaData.schedulingAreaObjCode}
        >
            {isFilterOpen && <FilterContainer />}
            {showRoleSummary ? <RoleSummaryContainer /> : null}

            {isPanelOpened && (
                <Suspense fallback={null}>
                    <SettingsPanel />
                </Suspense>
            )}

            {isBulkAssignmentPanelOpened && (
                <BulkAssignmentWrapperForLazy schedulingAreaData={schedulingAreaData} />
            )}
        </FieldsMetadataLoader>
    );
};

const mapStateToProps = (state): ISchedulingPanelPropsFromState => ({
    projectStatuses: projectStatusesSelector(state),
});

export const SchedulingPanels = connect(mapStateToProps)(SchedulingPanelsComponent);
