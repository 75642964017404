import { TModernSchedulingAction } from '../types';
import { IAreaState } from './areaRelatedInitialDataState';
import { getInitialAreaDataState } from './getInitialAreaDataState';

export const ActionType = 'SET_AREA_DATA';

interface ISetAreaDataActionPayload {
    areaData: IAreaState;
}

type TSetAreaDataAction = TModernSchedulingAction<ISetAreaDataActionPayload>;

const setAreaData = (areaData: IAreaState): TSetAreaDataAction => ({
    type: ActionType,
    payload: {
        areaData,
    },
});
export default setAreaData;

export function handle(state: IAreaState, { payload }: TSetAreaDataAction): IAreaState {
    const { areaData = getInitialAreaDataState() } = payload;

    return {
        ...areaData,
    };
}
