import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { projectGroupingModeSelector } from '../../../settings/settingsSelector';
import { stepsSelector } from '../../../dateRange/selectors/stepsSelector';
import { userInaccessibleProjectsSelector, userProjectsSelector } from '../users/usersSelector';
import { stepUnitSelector } from '../../../dateRange/selectors/stepUnitSelector';
import { INodesDetailsItemState, INodesDetailsState } from '../../IDataState';
import { getInacessibleItems } from '../../../../util/getWorkPerDayForPeriod';
import { DATE_FORMAT } from '../../../../constants/dataConstatnts';
import { recalculateDataForActiveView } from '../../../../util/utilities';

export const inaccessibleWorkPerDaysSelectorFromServiceForPeriod = createCachedSelector(
    [
        projectGroupingModeSelector,
        (state, obj) => obj.projectID,
        stepsSelector,
        userProjectsSelector,
        userInaccessibleProjectsSelector,
        stepUnitSelector,
    ],
    (
        isProjectGroupingMode,
        projectID,
        steps,
        projects: INodesDetailsState,
        inAccessibleProjectIDs,
        stepUnit
    ) => {
        const inAccessibleItems = getInacessibleItems(
            isProjectGroupingMode,
            projectID,
            projects,
            inAccessibleProjectIDs
        );
        const workPerDaysForPeriod: number[] = [];
        const stepEndDate = steps[steps.length - 1].clone().endOf(stepUnit);
        const startDate = steps[0];

        _.forEach(inAccessibleItems, (project: INodesDetailsItemState) => {
            const stepStartDate = startDate.clone().startOf(stepUnit);
            let index = 0;
            while (stepStartDate < stepEndDate) {
                const dayWithWPDHour = stepStartDate.format(DATE_FORMAT);
                const workPerDayHours =
                    project.inaccessibleNodesWorkPerDaysFromService[dayWithWPDHour] || 0;

                workPerDaysForPeriod[index] = (workPerDaysForPeriod[index] || 0) + workPerDayHours;
                stepStartDate.add(1, 'day');
                index += 1;
            }
        });
        return recalculateDataForActiveView(workPerDaysForPeriod, stepUnit, startDate);
    }
)(
    (state, { projectID, userID }) =>
        `${userID}:${projectID || 'inaccessible'}:${projectGroupingModeSelector(state)}`
);
