import { IDataState } from '../IDataState';

export const ActionType = 'REMOVE_EXPANDED_FLAGS_FOR_UNASSIGNED_SECTION';

const removeExpandedFlagsForUnassignedSection = () => ({
    type: ActionType,
    payload: {},
});
export default removeExpandedFlagsForUnassignedSection;

export function handle(state: IDataState): IDataState {
    const newUnassignedTasksProjects = { ...state.unassignedTasksProjects };

    Object.keys(newUnassignedTasksProjects).forEach((projectID) => {
        newUnassignedTasksProjects[projectID].details.expanded = false;
    });

    return {
        ...state,
        unassignedTasksProjects: newUnassignedTasksProjects,
        expandedUnassignedProjectIDs: new Set(),
    };
}
