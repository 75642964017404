import { createSelector } from 'reselect';
import { TimeUnit, WEEKS } from '../../../constants/periodEnums';
import { sizes } from '../../../constants/schedulingTableConstants';
import { stepsSelector } from '../../dateRange/selectors/stepsSelector';
import { stepUnitSelector } from '../../dateRange/selectors/stepUnitSelector';
import { tableWidthSelector } from './tableWidthSelector';
import { tableLeftSidePanelWidthSelector } from './tableSizesSelectors';

export const isSmallModeSelector = createSelector(
    [tableWidthSelector, stepUnitSelector, stepsSelector, tableLeftSidePanelWidthSelector],
    (tableWidth, stepUnit, steps, leftSidePanelWidth) => {
        if (stepUnit === TimeUnit.WEEK && steps.length === WEEKS.W_12) {
            return true;
        }
        const minWidth: number =
            stepUnit === TimeUnit.DAY ? sizes.cellMinWidthWeekMode : sizes.cellMinWidthWeeksMode;

        return (tableWidth - leftSidePanelWidth) / steps.length < minWidth;
    }
);
