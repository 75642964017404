import { getCurrentCustomer$ } from '@wf-mfe/auth';
import { first } from 'rxjs';
import { DATE_FORMAT, LOAD_ASSIGNMENTS_LIMIT } from '../../../constants/dataConstatnts';
import { IAssignmentsObjects } from '../../../util/dataStructures/IAssignmentaObjects';
import {
    getCountOfChunk,
    modifyContouredAssignmentsResponseData,
    modifyWorkPerDayResponseData,
    responsesToJSON,
} from '../../../util/workPerDayUtil';

interface IHeaders extends Record<string, string> {
    'Content-Type': string;
    'wf-customer-id': string;
    'wf-user-timezone': string;
    'wf-customer-timezone': string;
    'wf-lane': string;
}

interface IBody {
    objects: IAssignmentsObjects[];
    fromDate?: string;
    toDate?: string;
}

export const getHeader = (): IHeaders => {
    const header = {} as IHeaders;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let customerData;
    getCurrentCustomer$()
        .pipe(first())
        .subscribe((currentCustomerData) => {
            customerData = currentCustomerData;
        });

    header['Content-Type'] = 'application/json';
    header['wf-customer-id'] = customerData.ID;
    header['wf-user-timezone'] = userTimeZone;
    header['wf-customer-timezone'] = customerData.timeZone;
    header['wf-lane'] = customerData.currentLane;

    return header;
};

export const getOptions = (
    dates,
    assignmentsObjects,
    i
): {
    body: string;
    method: string;
    headers: IHeaders;
} => {
    let body: IBody = {
        objects: assignmentsObjects,
    };
    if (i !== null) {
        body = {
            toDate: dates.endDate.format(DATE_FORMAT),
            fromDate: dates.startDate.format(DATE_FORMAT),
            objects: assignmentsObjects.slice(
                i * LOAD_ASSIGNMENTS_LIMIT,
                i * LOAD_ASSIGNMENTS_LIMIT + LOAD_ASSIGNMENTS_LIMIT
            ),
        };
    }

    return {
        body: JSON.stringify(body),
        method: 'POST',
        headers: getHeader(),
    };
};

export const WorkPerDayService = {
    getWorkPerDayServiceApiCall: (assignmentIDs: IAssignmentsObjects[], dates) => {
        const promisesForLoadingWPDByLimits: Array<Promise<any>> = [];
        const countOfChunk = getCountOfChunk(assignmentIDs);
        for (let i = 0; i < countOfChunk; i++) {
            promisesForLoadingWPDByLimits.push(
                fetch('/wpd/work', getOptions(dates, assignmentIDs, i))
            );
        }

        return Promise.all(promisesForLoadingWPDByLimits)
            .then((data) => responsesToJSON(data))
            .then((jsonObjects) => modifyWorkPerDayResponseData(jsonObjects));
    },
    getContouredDataServiceApiCall: (assignmentsIDs: IAssignmentsObjects[]) => {
        return fetch('/wpd/contour/areObjectsContoured', getOptions(null, assignmentsIDs, null))
            .then((data) => data.json())
            .then((jsonObjects) => modifyContouredAssignmentsResponseData(jsonObjects));
    },
};
