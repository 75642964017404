import { createSelector } from 'reselect';
import { sizes } from '../../../constants/schedulingTableConstants';
import { cellWidthSelector } from '../../tableSizes/selectors/cellWidthSelector';
import { IDateRangeState } from '../IDateRangeState';
import { periodModeSelector } from './periodModeSelector';
import { startDateSelector } from './startDateSelector';
import { stepUnitSelector } from './stepUnitSelector';
import { getPeriodModesValues } from '../../../util/periodUtils/periodUtils';
import { DAYS_COUNT_IN_WEEK } from '../../../constants/dataConstatnts';
import { MONTH_COUNT_IN_PERIOD } from '../../../constants/periodEnums';
import { getUnitOfTime } from '../../../util/utilities';

export const periodsSelector = createSelector(
    [stepUnitSelector, startDateSelector, periodModeSelector, cellWidthSelector],
    (stepUnit, startDate, periodMode, cellWidth) => {
        const { isWeekMode } = getPeriodModesValues(stepUnit);
        if (!isWeekMode) {
            return getFilledPeriods(periodMode, stepUnit, startDate, cellWidth);
        }
        const periods: IDateRangeState['periods'] = [];
        periods.push({
            unit: startDate.clone(),
            periodCellWidth: periodMode * cellWidth,
            periodCellMinWidth: periodMode * sizes.cellMinWidthDayModeRedesign,
        });

        return periods;
    }
);

export const getFilledPeriods = (
    periodMode,
    unitOfTime,
    startDate,
    cellWidth?
): IDateRangeState['periods'] => {
    const { isDayMode, isMonthMode } = getPeriodModesValues(unitOfTime);
    const periodCount = isMonthMode ? MONTH_COUNT_IN_PERIOD : periodMode;
    const periods: IDateRangeState['periods'] = [];

    let cellWidthByCount = 0;
    let cellMinWidth = 0;
    if (cellWidth) {
        cellWidthByCount = isDayMode ? cellWidth * DAYS_COUNT_IN_WEEK : cellWidth;
        cellMinWidth = isDayMode ? sizes.cellMinWidthDayMode * DAYS_COUNT_IN_WEEK : cellWidth;
    }

    for (let i = 0; i < periodCount; i++) {
        periods.push({
            unit: startDate.clone().add(i, getUnitOfTime(unitOfTime)),
            periodCellWidth: cellWidthByCount,
            periodCellMinWidth: cellMinWidth,
        });
    }
    return periods;
};
