export enum ConditionType {
    IS_BLANK = 'isblank',
    NOT_BLANK = 'notblank',
    CONTAINS = 'cicontains',
    NOT_CONTAINS = 'cinotcontains',
    EQUALS = 'in',
    NOT_EQUALS = 'ne',
}

export enum FieldKeyTypes {
    PROJECT_NAME = 'project:name',
    PROJECT_ID = 'project:ID',
    PROJECT_STATUS = 'project:status',
    TASK_NAME = 'task:name',
    TASK_ID = 'task:ID',
    TASK_STATUS = 'task:status',
}

export enum FieldObjectsTypes {
    Task = 'Task',
    Project = 'Project',
}

export enum LabelsTypes {
    Name = 'Name',
    Status = 'Status',
}

export const CompleteStatus = 'CPL';

export const completeStatusFilter = {
    filterForObjCode: {
        statusEquatesWith: CompleteStatus,
        statusEquatesWith_Mod: ConditionType.NOT_EQUALS,
    },
    filterForProjectTask: {
        'task:statusEquatesWith': CompleteStatus,
        'task:statusEquatesWith_Mod': ConditionType.NOT_EQUALS,
    },
    filterForProjectIssue: {
        'opTask:statusEquatesWith': 'CLS',
        'opTask:statusEquatesWith_Mod': ConditionType.NOT_EQUALS,
    },
};

export const CONDITIONS_DATA = {
    defaultConditions: [
        {
            conditionType: ConditionType.EQUALS,
            labelMessage: { key: 'filters.equals', fallback: 'Equals' },
        },
    ],
    nameConditions: [
        {
            conditionType: ConditionType.CONTAINS,
            labelMessage: { key: 'filters.contains', fallback: 'Contains' },
        },
    ],
    statusConditions: [
        {
            conditionType: ConditionType.IS_BLANK,
            labelMessage: { key: 'filters.blank', fallback: 'Is blank' },
        },
        {
            conditionType: ConditionType.NOT_BLANK,
            labelMessage: { key: 'filters.not_blank', fallback: 'Is not blank' },
        },
    ],
};

export const bulkAssignmentsProjectFields = [
    {
        key: FieldKeyTypes.PROJECT_NAME,
        label: LabelsTypes.Name,
        groupLabel: FieldObjectsTypes.Project,
    },
    {
        key: FieldKeyTypes.PROJECT_STATUS,
        label: LabelsTypes.Status,
        groupLabel: FieldObjectsTypes.Project,
    },
];

export const bulkAssignmentsTaskFields = [
    {
        key: FieldKeyTypes.TASK_NAME,
        label: LabelsTypes.Name,
        groupLabel: FieldObjectsTypes.Task,
    },
    {
        key: FieldKeyTypes.TASK_STATUS,
        label: LabelsTypes.Status,
        groupLabel: FieldObjectsTypes.Task,
    },
];

export enum BulkActions {
    ASSIGN_USER = 'assign-user',
    REPLACE_USER = 'replace-user',
    UN_ASSIGN_USER = 'unassign-user',
}

export const RoleID = 'roleID';
export const AssignedToID = 'assignedToID';
export const RoleFilter = 'role';
export const AssignedToFilter = 'assignedTo';
export type AssignmentFilteringCriteria = typeof RoleID | typeof AssignedToID;
export type AssignmentObjectFilteringCriteria = typeof RoleFilter | typeof AssignedToFilter;

export const ConfirmButtonDetails = {
    [BulkActions.ASSIGN_USER]: {
        messageKey: 'workloadbalancer.assign',
        fallback: 'Assign',
    },
    [BulkActions.REPLACE_USER]: {
        messageKey: 'workloadbalancer.replace',
        fallback: 'Replace',
    },
    [BulkActions.UN_ASSIGN_USER]: {
        messageKey: 'workloadbalancer.unassign',
        fallback: 'Unassign',
    },
};

export const SelectedUserRequiredFields = ['role', 'userRoles:role'];

export const ASSIGN_TO_MAXIMUM_SUGGESTIONS = 50;
