import _ from 'lodash';
import { TModernSchedulingAction } from '../../types';
import { IDataState, IUserState } from '../IDataState';

export const ActionType = 'REMOVE_USERS_PROJECTS';

type TRemoveUsersProjectsActionPayload = Record<string, never>;

type TRemoveUsersProjectsAction = TModernSchedulingAction<TRemoveUsersProjectsActionPayload>;

const removeUsersProjects = (): TRemoveUsersProjectsAction => ({
    type: ActionType,
    payload: {},
});
export default removeUsersProjects;

// TODO should be refactored when will be added issues or project grouping
export function handle(state: IDataState): IDataState {
    const users = { ...state.users };
    const { tableDataIDs } = state;

    _.map(users, (userItem: IUserState) => {
        const { ID, ...restData } = userItem;
        users[ID] = {
            ID,
            ...restData,
            projectsDataRequestsState: {
                isRequestSend: false,
                isDataLoaded: false,
            },
            expanded: false,
            expandedProjectIDs: new Set(),
            /* projectIDs: [],
			inaccessibleProjectIDs: [],
			*/
        };
    });

    const newTableDataIDs = tableDataIDs.filter((dataIDs) => dataIDs.split('_').length === 1);

    return {
        ...state,
        tableDataIDs: newTableDataIDs,
        users,
    };
}
