interface IWorkSchedulingHeight {
    unassignedHeight: number;
    unassignedHeightBeforeCollapse: number;
}

export const setWorkSchedulingTableHeightsToLocalStorage = (
    unassignedHeight: number,
    unassignedHeightBeforeCollapse = 0
): void => {
    const tableHeightObject = { unassignedHeight, unassignedHeightBeforeCollapse };
    localStorage.setItem('workSchedulingTable', JSON.stringify(tableHeightObject));
};

export const getWorkSchedulingTableHeightsFromLocalStorage = (): IWorkSchedulingHeight => {
    const tableProperties = localStorage.getItem('workSchedulingTable');

    return tableProperties ? JSON.parse(tableProperties) : undefined;
};

export const setLeftSidePanelWidthToLocalStorage = (leftSidePanelWidth: number): void => {
    localStorage.setItem('leftSidePanelWidth', JSON.stringify(leftSidePanelWidth));
};

export const getLeftSidePanelWidthToLocalStorage = (): number | undefined => {
    const leftSidePanelWidth = localStorage.getItem('leftSidePanelWidth');

    return leftSidePanelWidth ? JSON.parse(leftSidePanelWidth) : undefined;
};
