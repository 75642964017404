import { TModernSchedulingAction } from '../../types';
import { IDataState, TContouringRowID } from '../IDataState';

export const ActionType = 'CHANGE_DROPDOWN_OPENED_STATE';

interface IEditRowPayload {
    rowID: TContouringRowID;
}

type TEditRowAction = TModernSchedulingAction<IEditRowPayload>;

const changeDropdownOpenedState = (rowID): TEditRowAction => ({
    type: ActionType,
    payload: {
        rowID,
    },
});
export default changeDropdownOpenedState;

export function handle(state: IDataState, { payload }: TEditRowAction): IDataState {
    const { rowID } = payload;

    return {
        ...state,
        dropdownOpened: rowID,
    };
}
