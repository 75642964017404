import { TModernSchedulingAction } from '../../types';
import { ITableSizesState } from '../ITableSizesState';

interface IChangeResizableHeightActionPayload {
    resizableHeight: string;
}

export type TChangeResizableHeightAction =
    TModernSchedulingAction<IChangeResizableHeightActionPayload>;

export const ActionType = 'CHANGE_RESIZABLE_HEIGHT';

const changeResizableHeight = (resizableHeight: string): TChangeResizableHeightAction => ({
    type: ActionType,
    payload: {
        resizableHeight,
    },
});
export default changeResizableHeight;

export function handle(
    state: ITableSizesState,
    action: TChangeResizableHeightAction
): ITableSizesState {
    return {
        ...state,
        resizableHeight: action.payload.resizableHeight,
    };
}
