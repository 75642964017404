import moment, { Moment } from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { DragSource } from 'react-dnd';
import mitt from 'mitt';
import { Sections, sizes } from '../../../../constants/schedulingTableConstants';
import { GlobalPropsContext } from '../../../../contexts/globalContexts';
import { IDateRangeState } from '../../../../data-flow/dateRange/IDateRangeState';
import { endDateSelector } from '../../../../data-flow/dateRange/selectors/endDateSelector';
import { startDateSelector } from '../../../../data-flow/dateRange/selectors/startDateSelector';
import { stepUnitSelector } from '../../../../data-flow/dateRange/selectors/stepUnitSelector';
import { cellWidthSelector } from '../../../../data-flow/tableSizes/selectors/cellWidthSelector';
import { IWorkSchedulingCombinedState } from '../../../../data-flow/types';
import {
    calcWidthAndLeftPosition,
    isAfterPeriod,
    isBeforePeriod,
    shouldBeArrow,
} from '../../../../util/durationStylesCalculation';
import {
    collect,
    dragConfiguration,
    DragDropTypes,
} from '../../../../dragAndDrop/dragAndDropConfigurations';
import { internalEventEmitterSelector } from '../../../../data-flow/instances/internalEventEmitterSelector';
import { projectColorsModeSelector } from '../../../../data-flow/settings/settingsSelector';
import { isGreaterThanZero } from '../../../../util/utilities';
import { TDate, TOrUndefined, TProjectColorsMode } from '../../../../data-flow/data/IDataState';
import { ActualAssignmentBar } from './ActualAssignmentBar';
import { inlineItemsCentered } from '../../../../styles';
import { IPopoverState } from '../../../../common-hooks/useAssignmentPopover';

export interface IPopperEvents {
    onMouseOver?: IPopoverState['showPopper'];
    onMouseLeave?: IPopoverState['hidePopper'];
    onMouseMove?: IPopoverState['updateCoords'];
}

interface IActualProgressBlockWrapperStateProps extends Pick<IDateRangeState, 'stepUnit'> {
    cellWidth: number;
    colorsMode: TProjectColorsMode;
    endDate: Moment;
    startDate: Moment;
    internalEventEmitter: mitt.Emitter;
}

export interface IActualProgressBlockWrapper
    extends IActualProgressBlockWrapperStateProps,
        IPopperEvents {
    allocationsEditingMode?: boolean;
    clickHandler?: () => void;
    color: string;
    connectDragSource: any;
    height: number;
    isForProject?: boolean;
    isCompleted: boolean;
    isDraggingEnabled?: boolean;
    isTherePlannedBeforeArrow: boolean;
    isTherePlannedAfterArrow: boolean;
    onClickHandler?: (event: any) => void;
    projectedCompletionDate: TDate;
    projectedStartDate: TDate;
    textColor?: string;
    title: string | null;
    isAccessible?: boolean;
    objCode?: string; // provided for drag and drop functionality
    ID: string;
    idExpression: string;
    sectionType?: Sections; // this is for drag and drop - dragConfiguration, do not remove
    taskAssignmentBackgroundColor?: TOrUndefined<string>;
    plannedAndActualAssignmentsOverlap?: boolean;
    durationPadding: number;
}

export const dataTestIds = {
    left_arrow_actual_progress: 'left_arrow_actual_progress',
    right_arrow_actual_progress: 'right_arrow_actual_progress',
    title_actual_progress: 'title_actual_progress',
    blockId: (ID) => `${ID}_actual_progress_block`,
};

export const ActualProgressBlockWrapperComponent: React.FunctionComponent<IActualProgressBlockWrapper> =
    React.memo(function ActualProgressBlockWrapperComponent(props) {
        const {
            cellWidth,
            endDate,
            isForProject,
            projectedCompletionDate,
            projectedStartDate,
            startDate,
            stepUnit,
            title,
            connectDragSource,
            isDraggingEnabled,
            ID,
            plannedAndActualAssignmentsOverlap,
        } = props;

        const globalPropsContext = React.useContext(GlobalPropsContext);

        const projectedStartDateFormatted = getFormattedDate(projectedStartDate);
        const projectedEndDateFormatted = getFormattedDate(projectedCompletionDate);

        const [width, fromLeft] = calcWidthAndLeftPosition(
            stepUnit,
            startDate,
            endDate,
            projectedStartDateFormatted,
            projectedEndDateFormatted,
            cellWidth,
            sizes.durationPadding
        );

        if (!isGreaterThanZero(width)) {
            return null;
        }

        const onClickHandler = (event): void => {
            if (props.onClickHandler) {
                props.onClickHandler(event);
            }
        };

        const isThereProjectedBeforeArrow = shouldBeArrow(
            width,
            isBeforePeriod(stepUnit, startDate, projectedStartDateFormatted)
        );
        const isThereProjectedAfterArrow = shouldBeArrow(
            width,
            isAfterPeriod(stepUnit, endDate, projectedEndDateFormatted)
        );

        const [calculatedAssignmentWidth, calculatedLeft] = calculateAssignmentWidth(
            isThereProjectedBeforeArrow,
            isThereProjectedAfterArrow,
            width,
            fromLeft,
            sizes.assignmentArrowSize
        );

        const isGhostAssignmentDraggable = Boolean(isDraggingEnabled && title);
        const showPointerCursor = !!(
            (globalPropsContext.minixState && title && !isForProject) ||
            isGhostAssignmentDraggable
        );

        const assignmentBar = (
            <ActualAssignmentBar
                {...props}
                isThereProjectedBeforeArrow={isThereProjectedBeforeArrow}
                isThereProjectedAfterArrow={isThereProjectedAfterArrow}
                calculatedAssignmentWidth={calculatedAssignmentWidth}
                calculatedLeft={calculatedLeft}
                showPointerCursor={showPointerCursor}
                {...(!isForProject && {
                    plannedAndActualAssignmentsOverlap,
                })}
            />
        );

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
                data-testid={dataTestIds.blockId(ID)}
                onClick={onClickHandler}
                className={inlineItemsCentered}
            >
                {isGhostAssignmentDraggable
                    ? connectDragSource(<div className={inlineItemsCentered}>{assignmentBar}</div>)
                    : assignmentBar}
            </div>
        );
    });

const mapStateToProps = (
    state: IWorkSchedulingCombinedState
): IActualProgressBlockWrapperStateProps => {
    const colorsMode = projectColorsModeSelector(state);

    return {
        cellWidth: cellWidthSelector(state),
        colorsMode,
        endDate: endDateSelector(state),
        startDate: startDateSelector(state),
        stepUnit: stepUnitSelector(state),
        internalEventEmitter: internalEventEmitterSelector(state),
    };
};

const dragService = DragSource(
    DragDropTypes.ASSIGNMENT,
    dragConfiguration,
    collect
)(ActualProgressBlockWrapperComponent);

export const ActualProgressBlockWrapper = connect(mapStateToProps)(dragService);

const getFormattedDate = (date: TDate): Moment => {
    return moment(date || '');
};

const calculateAssignmentWidth = (
    before: boolean,
    after: boolean,
    initialWidth: number,
    initialLeft: number,
    arrowWidth: number
): number[] => {
    let data = [0, 0];

    if (!initialWidth) {
        return data;
    }
    const correctedWidth = initialWidth - 2 * sizes.durationPadding;

    if (!before && !after) {
        data = [correctedWidth, initialLeft];
    } else if (before && after) {
        data = [correctedWidth - 2 * arrowWidth, initialLeft + arrowWidth];
    } else {
        data = [correctedWidth - arrowWidth, initialLeft + (before ? arrowWidth : 0)];
    }

    return data;
};
