import { MultiGrid } from 'react-virtualized/dist/es/MultiGrid';
import _ from 'lodash';
import { IObjectStateWithID, TasksAndIssuesRecord } from '../../../data-flow/data/IDataState';
import { Sections } from '../../../constants/schedulingTableConstants';
import {
    assignedSectionHeaderDataID,
    unassignedSectionHeaderDataID,
} from '../../../constants/dataConstatnts';
import { hideScrollbar } from './schedulingTableStyles';
import { ScrollSyncCustomChildProps } from '../../Scheduling/ScrollSyncCustom';

export const recomputeGridColumn = (): ((
    max: number,
    currentGridRef: MultiGrid | null
) => void) => {
    let lastMaxValue = 0;
    return (max, currentGridRef): void => {
        if (lastMaxValue !== 0 && lastMaxValue !== max) {
            lastMaxValue = max;
            if (currentGridRef) {
                currentGridRef.recomputeGridSize({ columnIndex: 1 });
            }
        }
        lastMaxValue = max;
    };
};

export const filterProjectTaskAndIssuesById = (
    userTasks: TasksAndIssuesRecord,
    projectID: string
): IObjectStateWithID[] => {
    const projectTaskAndIssues: IObjectStateWithID[] = [];

    _.forEach(userTasks, (task, ID) => {
        if (task.projectID === projectID) {
            projectTaskAndIssues.push({ ...task, ID: ID ? `${ID}` : '' });
        }
    });

    return projectTaskAndIssues;
};

export const hasLoadedDataIDs = (dataIDs: string[], section: Sections): boolean => {
    if (section === Sections.PEOPLE_WORKLOAD) {
        return dataIDs.length > assignedSectionHeaderDataID.length;
    }

    return dataIDs.length > unassignedSectionHeaderDataID.length;
};

export const getHideScrollbarClass = (
    isInLoadingState: boolean,
    isAssignmentDialogOpen: boolean
): string => {
    return isInLoadingState || isAssignmentDialogOpen ? hideScrollbar : '';
};

export const getVerticalScrollWidthPerArea = (
    areasData: ScrollSyncCustomChildProps['areasData']
): {
    assignedScrollWidth: number;
    unassignedScrollWidth: number;
} => {
    const unassignedParams = areasData[Sections.UNASSIGNED_WORK];
    const assignedParams = areasData[Sections.PEOPLE_WORKLOAD];

    return {
        assignedScrollWidth: assignedParams.vertical ? assignedParams.size : 0,
        unassignedScrollWidth: unassignedParams.vertical ? unassignedParams.size : 0,
    };
};

export const shouldHeaderHaveScrollBarPlaceholder = (
    assignedScrollWidth: number,
    unassignedScrollWidth: number
): boolean => {
    return assignedScrollWidth > 0 || unassignedScrollWidth > 0;
};

export const shouldBodyHaveScrollBarPlaceholder = (
    assignedScrollWidth: number,
    unassignedScrollWidth: number,
    section: Sections
): boolean => {
    if (section === Sections.PEOPLE_WORKLOAD) {
        return unassignedScrollWidth > 0 && assignedScrollWidth <= 0;
    }
    return assignedScrollWidth > 0 && unassignedScrollWidth <= 0;
};

export const getWidthPerColumn = (
    columnIndex: number,
    tableMinWidth: number,
    width: number,
    leftSidePanelWidth: number,
    scrollBarSize: number
): number => {
    if (columnIndex === 0) {
        return leftSidePanelWidth;
    }

    const max = Math.max(tableMinWidth, width);
    const widthSubtraction = width > tableMinWidth ? scrollBarSize : 0;

    return max - leftSidePanelWidth - widthSubtraction;
};
