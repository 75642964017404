import { Localization } from '@workfront/localize-react';
import styled from 'react-emotion';

import { Text, Tooltip, primary } from '@phoenix/all';
import HolidaySmallIcon from 'phoenix-icons/dist/HolidaySmallIcon.js';
import TimeoffSmallIcon from 'phoenix-icons/dist/TimeoffSmallIcon.js';
import React from 'react';
import { getTimeOffMessageKey } from '../../../../util/timeOffsService';
import { AllocationTooltipContent } from './AllocationTooltipContent';
import { HolidayIconWrapper, TimeOffTextColor } from './FullTimeOff';
import { IFullTimeOffStepsWithTimestampInfo } from '../../../../data-flow/data/IDataState';

interface IFullTimeOffsProps {
    timeOffStepCount: number;
    timeOffAtStep: IFullTimeOffStepsWithTimestampInfo['stepDate'];
}
export const fullTimeOffsTestIds = {
    timeOffSmallIcon: 'TimeOffSmallIcon',
    holidaySmallIcon: 'HolidaySmallIcon',
    fullTimeOff: 'fullTimeOff',
    fullException: 'fullException',
};

export const FullTimeOffs: React.FunctionComponent<IFullTimeOffsProps> = React.memo((props) => {
    const { timeOffAtStep, timeOffStepCount } = props;
    const { isUserTimeOff } = timeOffAtStep;
    const timeOffMessageKey = getTimeOffMessageKey(isUserTimeOff).messageKey;
    const timeOffFallBack = getTimeOffMessageKey(isUserTimeOff).fallBack;

    return (
        <>
            <Tooltip
                delay={700}
                content={
                    <AllocationTooltipContent
                        timeOffMessageKey={timeOffMessageKey}
                        timeOffMessageFallback={timeOffFallBack}
                        isForFullTimeOff
                    />
                }
                style={{ display: 'flex' }}
            >
                <>
                    {isUserTimeOff && (
                        <TimeoffSmallIcon
                            color={primary.gray(500)}
                            data-testid={fullTimeOffsTestIds.timeOffSmallIcon}
                        />
                    )}
                    {(!isUserTimeOff || isUserTimeOff === 'both') && (
                        <HolidayIconWrapper isUserTimeOff={isUserTimeOff}>
                            <HolidaySmallIcon
                                color={primary.gray(500)}
                                data-testid={fullTimeOffsTestIds.holidaySmallIcon}
                            />
                        </HolidayIconWrapper>
                    )}
                </>
            </Tooltip>
            {timeOffStepCount > 1 && (
                <TimeOffText stepCount={timeOffStepCount}>
                    <Text.Small className={TimeOffTextColor}>
                        <Localization<string>
                            messageKey={timeOffMessageKey}
                            fallback={timeOffFallBack}
                        />
                    </Text.Small>
                </TimeOffText>
            )}
        </>
    );
});

export const TimeOffText = styled('div')<{
    stepCount: number;
}>`
    padding-right: 8px;
    width: ${(props) => `calc(${props.stepCount * 100}% - 1 - 16 - 11}px)`};
    line-height: 12px;
    padding-left: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const RangeFullTimeOffStyled = styled('div')<{
    stepCount: number;
    hasTopBorder: boolean;
    isAllocationsVisualizationOn: boolean;
}>`
    position: absolute;
    height: 100%;
    width: ${(props) =>
        `calc(${props.stepCount * 100}% + ${
            props.isAllocationsVisualizationOn ? 0 : props.stepCount
        }px)`};
    top: 0;
    left: 0;
    background-color: rgba(69, 75, 87, 0.06);
    border-top: ${(props) => (props.hasTopBorder ? `2px solid ${primary.gray(500)}` : '')};
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.stepCount === 1 ? 'center' : 'flex-start')};
    z-index: 1;
    padding-left: ${(props) => (props.stepCount === 1 ? '0px' : '11px')};
`;

export const NonUserFullTimeOffStyled = styled('div')`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(69, 75, 87, 0.06);
    z-index: 1;
`;
