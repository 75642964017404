import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'CHANGE_USER_NODES_OFFSET';

interface IChangeUserNodesOffsetActionPayload {
    idExpression: string;
    offset: number;
    allExcept?: boolean;
}

export type TChangeUserNodesOffset = TModernSchedulingAction<IChangeUserNodesOffsetActionPayload>;

const changeOffset = (
    idExpression: string,
    offset: number,
    allExcept = false
): TChangeUserNodesOffset => ({
    type: ActionType,
    payload: {
        idExpression,
        offset,
        allExcept,
    },
});
export default changeOffset;

export function handle(state: IDataState, { payload }: TChangeUserNodesOffset): IDataState {
    const users = { ...state.users };
    const ids = payload.idExpression.split('_');

    if (ids.length === 1) {
        if (payload.allExcept) {
            Object.keys(users).forEach((userID) => {
                if (userID !== ids[0]) {
                    users[userID] = {
                        ...users[userID],
                        offset: payload.offset,
                    };
                }
            });
        } else {
            users[ids[0]] = {
                ...users[ids[0]],
                offset: payload.offset,
            };
        }
    } else {
        const nodeID = ids[1];
        const nodes = {
            ...users[ids[0]].nodes,
            [nodeID]: {
                ...users[ids[0]].nodes[nodeID],
                offset: payload.offset,
            },
        };

        users[ids[0]] = {
            ...users[ids[0]],
            nodes,
        };
    }

    return {
        ...state,
        users,
    };
}
