import { TModernSchedulingAction } from '../../types';
import { ITableSizesState } from '../ITableSizesState';

interface IChangeCollapsedModeActionPayload {
    isTableCollapsed: boolean;
}

export type TChangeCollapsedModeAction = TModernSchedulingAction<IChangeCollapsedModeActionPayload>;

export const ActionType = 'CHANGE_COLLAPSED_MODE';

const changeCollapsedMode = (isTableCollapsed: boolean): TChangeCollapsedModeAction => ({
    type: ActionType,
    payload: {
        isTableCollapsed,
    },
});
export default changeCollapsedMode;

export function handle(
    state: ITableSizesState,
    action: TChangeCollapsedModeAction
): ITableSizesState {
    return {
        ...state,
        isTableCollapsed: action.payload.isTableCollapsed,
    };
}
