import { Localization } from '@workfront/localize-react';
import { Moment } from 'moment';
import * as React from 'react';
import styled from 'react-emotion';
import { monthsMessageKeys, monthsMessageKeysFallBacks } from '../../../messageKeysForDate';
import { getDateMessageKey } from '../../../util/utilities';

interface ISmallModeContentProps extends React.HTMLAttributes<HTMLDivElement> {
    step: Moment;
    isSmallMode?: boolean;
}

export const WeekStyled = styled('span')<{ isSmallMode: boolean }>`
    font-weight: 600;
    ${(props) => (props.isSmallMode ? 'display: block;' : '')}
`;

export const WeekModeContent = React.memo<ISmallModeContentProps>((props) => {
    const { step, isSmallMode = false } = props;
    const startOfCurrentWeek: Moment = step.clone().startOf('week');
    const currentMonth: string = step.clone().format('M');
    const endOfCurrentWeek: Moment = startOfCurrentWeek.clone().endOf('week');
    const endOfWeeksMonth: string = endOfCurrentWeek.clone().format('M');
    const isWeekExceedingMonth: boolean = currentMonth !== endOfWeeksMonth;

    return (
        <div>
            <WeekStyled isSmallMode={isSmallMode}>
                <Localization<string>
                    messageKey="resourceplanner.week.number"
                    fallback="W{0} {1}"
                    args={[step.clone().isoWeek() + 1, '']}
                />
            </WeekStyled>
            <span>
                <Localization<string>
                    messageKey={getDateMessageKey(
                        monthsMessageKeys,
                        parseInt(currentMonth, 10) - 1
                    )}
                    fallback={getDateMessageKey(
                        monthsMessageKeysFallBacks,
                        parseInt(currentMonth, 10) - 1
                    )}
                />
                {` ${startOfCurrentWeek.format('DD')}`}
            </span>
            {` - `}
            <span>
                {isWeekExceedingMonth && (
                    <Localization<string>
                        messageKey={getDateMessageKey(
                            monthsMessageKeys,
                            parseInt(endOfWeeksMonth, 10) - 1
                        )}
                        fallback={getDateMessageKey(
                            monthsMessageKeysFallBacks,
                            parseInt(endOfWeeksMonth, 10) - 1
                        )}
                    />
                )}
                {` ${endOfCurrentWeek.format('DD')}`}
            </span>
        </div>
    );
});
