import { apiDateToDate } from '@workfront/fns';
import { TModernSchedulingAction } from '../../types';
import { IDataState, IUnassignedAssignment } from '../IDataState';
import { flattenPredecessors } from '../assignedDataActions/addAssignmentsUtils';

export const ActionType = 'ADD_UNASSIGNED_ASSIGNMENTS';

interface IAddUnassignedAssignmentsPayload {
    unassignedTasks: IUnassignedAssignment[];
}

type TAddUnassignedAssignmentsAction = TModernSchedulingAction<IAddUnassignedAssignmentsPayload>;

const addUnassignedAssignments = (unassignedTasks): TAddUnassignedAssignmentsAction => ({
    type: ActionType,
    payload: {
        unassignedTasks,
    },
});
export default addUnassignedAssignments;

export function handle(
    state: IDataState,
    { payload }: TAddUnassignedAssignmentsAction
): IDataState {
    const unassignedTasks = { ...state.unassignedTasks };

    payload.unassignedTasks.forEach((assignmentItem) => {
        const taskUnassignedAssignments =
            assignmentItem.assignments &&
            assignmentItem.assignments.filter((el) => el.assignedToID === null);

        unassignedTasks[assignmentItem.ID] = {
            name: assignmentItem.name,
            plannedCompletionDate: apiDateToDate(assignmentItem.plannedCompletionDate),
            plannedStartDate: apiDateToDate(assignmentItem.plannedStartDate),
            projectGroupID: assignmentItem.project && assignmentItem.project.groupID,
            projectID: assignmentItem.projectID,
            projectStatus: assignmentItem.project && assignmentItem.project.status,
            projectName: assignmentItem.project && assignmentItem.project.name,
            parent: assignmentItem.parent,
            predecessors: flattenPredecessors(assignmentItem.predecessors),
            objCode: assignmentItem.objCode,
            assignAccess: assignmentItem.permissions.actions.indexOf('EDIT_ASSIGNMENTS') !== -1,
            assignments:
                taskUnassignedAssignments &&
                taskUnassignedAssignments.map((el) => {
                    return {
                        ID: el.ID,
                        roleID: el.roleID,
                    };
                }),
            workPerDayList: assignmentItem.workPerDayList,
            workPerDayListFromService: {},
            scheduleID: assignmentItem.project.scheduleID,
            isWorkRequiredLocked: assignmentItem.isWorkRequiredLocked,
            plannedHours: assignmentItem.workRequired,
            duration: assignmentItem.duration,
            status: assignmentItem.status,
            durationType: assignmentItem.durationType,
            durationUnit: assignmentItem.durationUnit,
            isDurationLocked: assignmentItem.isDurationLocked,
            recurrenceRuleID: assignmentItem.recurrenceRuleID,
        };
    });

    return {
        ...state,
        unassignedTasks,
    };
}
