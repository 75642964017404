import { Sections } from '../../constants/schedulingTableConstants';
import { IReloadState } from '../data/IDataState';

const DEFAULT_RELOAD_STATE: IReloadState = {
    [Sections.PEOPLE_WORKLOAD]: {
        isAbleToReload: true,
    },
    [Sections.UNASSIGNED_WORK]: {
        isAbleToReload: true,
    },
};

export function getInitialReloadState(): IReloadState {
    return DEFAULT_RELOAD_STATE;
}
