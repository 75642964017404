import {
    Assignment as assignmentObjCode,
    Role as roleObjCode,
    Team as teamObjCode,
    User as userObjCode,
} from 'workfront-objcodes';
import { TWorkSchedulingThunkAction } from '../types';
import { IAssignmentsDetails } from '../data/IDataState';
import { APIService } from '../../services/api-services/apiService';
import addAssignmentsDetailsToObject from '../data/sharedActions/addAssignmentsDetailsToObject';
import addDelegationsDetailsToObject from '../data/sharedActions/addDelegationsDetailsToObject';
import { makeControllerCall } from '../../services/api-services/api';

export function loadObjectAssignments(
    id: string,
    objCode: string,
    isDragDrop: boolean = false,
    loadWorkDelegations: boolean = false
): TWorkSchedulingThunkAction<Promise<IAssignmentsDetails[] | null>> {
    return function _loadObjectAssignments(dispatch) {
        const fields = [
            'assignments:isPrimary',
            'assignments:work',
            'assignments:role:name',
            'assignments:assignedTo:name',
            'assignments:assignedTo:userRoles:role',
            'assignments:assignmentPercent',
            'assignments:assignedTo:avatarDate',
            'teamAssignment:team',
            'teamAssignments:team',
        ];

        const promise = loadWorkDelegations
            ? makeControllerCall(
                  `/getAssignmentsAndDelegationsDataForWork?objCode=${objCode}&ID=${id}`,
                  'GET'
              )
            : APIService.apiSearch(objCode, { ID: id }, fields);

        return promise
            .then((data) => {
                const assignmentsData = loadWorkDelegations ? [data.assignmentsData] : data;

                const assignmentsDetails: IAssignmentsDetails[] = [];

                if (assignmentsData.length) {
                    if (!assignmentsData[0].teamAssignments && assignmentsData[0].teamAssignment) {
                        assignmentsDetails.push({
                            ID: assignmentsData[0].teamAssignment.ID,
                            objCode: assignmentObjCode,
                            type: teamObjCode,
                            team: { ...assignmentsData[0].teamAssignment.team, type: teamObjCode },
                        });
                    }

                    assignmentsData[0].assignments.forEach((details) => {
                        assignmentsDetails.push({
                            ID: details.ID,
                            isPrimary: details.isPrimary,
                            work: details.work,
                            type: details.assignedTo ? userObjCode : roleObjCode,
                            objCode: assignmentObjCode,
                            assignedTo: details.assignedTo
                                ? { ...details.assignedTo, type: userObjCode }
                                : null,
                            assignmentPercent: details.assignmentPercent,
                            role: details.role ? { ...details.role, type: roleObjCode } : null,
                            roles:
                                details.role && details.assignedTo
                                    ? details.assignedTo.userRoles.map((obj) => obj.role)
                                    : null,
                        });
                    });

                    assignmentsData[0].teamAssignments?.forEach((details) => {
                        assignmentsDetails.push({
                            ID: details.ID,
                            objCode: assignmentObjCode,
                            type: teamObjCode,
                            team: details.team,
                        });
                    });
                }

                if (!isDragDrop) {
                    dispatch(addAssignmentsDetailsToObject(id, assignmentsDetails));
                    if (loadWorkDelegations) {
                        dispatch(addDelegationsDetailsToObject(id, data.delegationsData));
                    }
                }

                return assignmentsDetails;
            })
            .catch(() => {
                return null;
            });
    };
}
