import styled, { css } from 'react-emotion';
import { sizes } from '../../constants/schedulingTableConstants';
import { allocationsEditingModeType } from './allocationsComponentStyles';
import { setOpacity, sharedStyle } from './sharedStyles';

const { durationPadding } = sizes;
const arrowWidth = 16;
const buttonsWidth = 53;

export interface IStyledAssignment {
    backGroundColor?: string;
    isThereAfterArrow: boolean;
    isThereBeforeArrow: boolean;
    isPenultimateDayInView: boolean;
    isAssigned?: boolean;
    isComplete?: boolean;
    assignmentWidth: number;
    idExpression: string;
    minixState: { ID: string } | null;
    isInDisableMode: boolean;
    IDExpressionForMinixState: string | null;
    isThreeDotItemClicked: boolean;
    allocationsEditingMode: boolean | string | null;
    areHoursNotEqualsBeforeContouringSave: boolean | undefined;
    showPointerCursor?: boolean;
    dragLayer?: boolean;
    isDragging?: boolean;
}

interface IStyledAssignmentContainer {
    allocationsEditingMode: boolean | string | null;
    showActualProgress: boolean;
}

export const assignmentShadow = `box-shadow: rgba(0, 35, 64, 0.21) 4px 4px 14px 0, rgba(0, 35, 64, 0.21) 0px 4px 14px -4px;
		transition: box-shadow 0.3s ease-out, transform 0.3s ease-out, opacity 0.2s ease-out !important;`;

export const editModeShadowStyle = `box-shadow: rgba(0, 35, 64, 0.3) 8px 9px 22px 0, rgba(0, 35, 64, 0.3) 0px 9px 22px -4px !important;`;

export const doubleClickHandlerDiv = css`
    overflow: hidden;
`;

export const taskShadowClass = css`
    ${assignmentShadow}
`;

const allocationEditingModeStyle = (allocationsEditingMode: allocationsEditingModeType): string => {
    return allocationsEditingMode
        ? `&:hover {.beforePeriodStyle, .afterPeriodStyle {
						z-index: 5;
					}`
        : '';
};

// It is for show shadow even when hover on assignment arrow
export const StyledAssignmentContainer = styled('div')<IStyledAssignmentContainer>`
    position: relative;
    display: flex;
    align-items: center;
    ${(props) => allocationEditingModeStyle(props.allocationsEditingMode)};
`;

const ifEditModeShadowStyle = (
    minixState: { ID: string } | null,
    isInDisableMode: boolean,
    idExpression: string,
    IDExpressionForMinixState: string | null,
    allocationsEditingMode: allocationsEditingModeType
): boolean => {
    return !!(
        minixState &&
        !isInDisableMode &&
        idExpression === IDExpressionForMinixState &&
        allocationsEditingMode
    );
};

const afterArrowOrPenultimateDayInViewStyle = (
    isThereAfterArrow: boolean,
    isPenultimateDayInView: boolean
): number => {
    return isThereAfterArrow || isPenultimateDayInView ? -arrowWidth : buttonsWidth;
};

export const getIsDraggingStyle = (isDragging?: boolean): string =>
    isDragging
        ? 'transition: width 0.5s linear; box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.3);'
        : '';

export const AssignmentStyled = styled('div')<IStyledAssignment>`
    ${sharedStyle};
    line-height: ${sizes.taskAssignmentHeight}px;
    ${(props) =>
        props.isThereAfterArrow ? 'border-top-right-radius: 0; border-bottom-right-radius: 0' : ''};
    ${(props) =>
        props.isThereBeforeArrow ? 'border-top-left-radius: 0; border-bottom-left-radius: 0;' : ''};
    position: relative;
    opacity: ${(props) => setOpacity(props.isComplete)};
    cursor: ${(props) => (props.showPointerCursor ? 'pointer' : 'default')} !important;
    ${(props) => props.backGroundColor};
    width: ${(props) =>
        props.assignmentWidth +
        (props.allocationsEditingMode
            ? 2 * sizes.borderWidth +
              afterArrowOrPenultimateDayInViewStyle(
                  props.isThereAfterArrow,
                  props.isPenultimateDayInView
              )
            : -2 * durationPadding)}px;
    transition: 0s visibility;
    will-change: width;
    opacity: ${(props) => (props.isInDisableMode ? 0.7 : 1)};
    ${(props) => getIsDraggingStyle(props.isDragging)}
    ${(props) =>
        props.isThreeDotItemClicked ||
        ifEditModeShadowStyle(
            props.minixState,
            props.isInDisableMode,
            props.idExpression,
            props.IDExpressionForMinixState,
            props.allocationsEditingMode
        )
            ? editModeShadowStyle
            : ''}
`;
