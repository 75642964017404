import { Role as roleObjCode, Team as teamObjCode, User as userObjCode } from 'workfront-objcodes';
import { osTypes } from '../constants/dataConstatnts';
import { Sections } from '../constants/schedulingTableConstants';
import { IAssignmentsDetails, IDropdownOption } from '../data-flow/data/IDataState';
import { IAssignmentIds } from './dataStructures/IAssignmentIds';
import { getOS } from './utilities';

export const getDropDownOptions = (
    dropdownLabels,
    assignAccess,
    sectionType,
    contouringEditAccess,
    isGhost
): IDropdownOption[] => {
    const os = getOS();
    const dropDownOptions = [
        {
            messageKey: 'resourcescheduling.assign.this.to',
            label: dropdownLabels['resourcescheduling.assign.this.to'],
            value: 'assignmentWidgetOpen',
            toolTipContent:
                os === osTypes.mac
                    ? {
                          messageKey: 'workloadbalancer.mac.assignment.key',
                          fallBack: 'Cmd + click',
                      }
                    : {
                          messageKey: 'workloadbalancer.windows.assignment.key',
                          fallBack: 'Ctrl + click',
                      },
        },
        {
            messageKey: 'workloadbalancer.edit.allocation',
            label: dropdownLabels['workloadbalancer.edit.allocation'],
            value: 'editAllocations',
            toolTipContent: {
                messageKey: 'double.click',
                fallBack: 'Double-click',
            },
        },
        {
            messageKey: 'minix.open',
            label: dropdownLabels['minix.open'],
            value: 'minixOpen',
        },
    ];

    const showContouring = checkShowContouring(
        assignAccess,
        sectionType,
        contouringEditAccess,
        !isGhost
    );
    const options: any = [];

    if (assignAccess) {
        options.push(dropDownOptions[0]);
    }

    if (showContouring) {
        options.push(dropDownOptions[1]);
    }

    options.push(dropDownOptions[2]);

    return options;
};

export const restructureAssignmentDetails = (
    assignmentsDetails: IAssignmentsDetails[]
): IAssignmentIds => {
    const objectOfIds: IAssignmentIds = {
        teamID: null,
        roleIDs: [],
        userIDs: [],
        teamIDs: [],
    };

    assignmentsDetails.forEach((data) => {
        if (data.type === teamObjCode && data.team) {
            objectOfIds.teamID = data.team.ID;
        } else if (data.type === userObjCode && data.assignedTo) {
            objectOfIds.userIDs.push(data.assignedTo.ID);
        } else if (data.type === roleObjCode && data.role) {
            objectOfIds.roleIDs.push(data.role.ID);
        }
    });

    return objectOfIds;
};

export const isDateChanged = (oldDate: string | null, newDate: string | null): boolean => {
    return !!(oldDate && newDate && oldDate !== newDate);
};

export const checkShowContouring = (
    assignAccess,
    sectionType,
    contouringEditAccess,
    isNotGhost = true
): boolean => {
    return (
        assignAccess &&
        sectionType === Sections.PEOPLE_WORKLOAD &&
        contouringEditAccess &&
        isNotGhost
    );
};
