import { forEach } from 'lodash';
import moment, { Moment } from 'moment';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'ADD_ACCESSIBLE_WORKING_HOURS';

interface IAddAccessibleEWorkingHoursPayload {
    steps: Moment[];
    usersIDs: string[];
    showActualProgress: boolean;
}

type TAddAccessibleWorkingHoursAction = TModernSchedulingAction<IAddAccessibleEWorkingHoursPayload>;

const addAccessibleWorkingHoursForPeriod = (
    steps: Moment[],
    usersIDs: string[],
    showActualProgress
): TAddAccessibleWorkingHoursAction => ({
    type: ActionType,
    payload: {
        steps,
        usersIDs,
        showActualProgress,
    },
});
export default addAccessibleWorkingHoursForPeriod;

export function handle(
    state: IDataState,
    { payload }: TAddAccessibleWorkingHoursAction
): IDataState {
    const users = { ...state.users };
    const taskIssues = { ...state.tasksAndIssues };
    const workPerDayData = { ...state.workPerDaysByAssignments };

    payload.usersIDs.forEach((userID: string) => {
        const workPerDaySums: number[] = [...users[userID].userWorkPerDayForPeriod];

        forEach(users[userID].assignments, (objectID, assignmentID) => {
            const workPerDay = workPerDayData[assignmentID];
            const startDate = moment(state.tasksAndIssues[objectID].plannedStartDate).startOf(
                'day'
            );
            const assignmentActualCompletionDate = taskIssues[objectID].actualCompletionDate;

            payload.steps.forEach((step, stepIndex: number) => {
                if (
                    moment(assignmentActualCompletionDate).isBefore(step) &&
                    payload.showActualProgress
                ) {
                    return;
                }
                const diffByDay = step.startOf('day').diff(startDate, 'day');

                const workPerDayHours = workPerDay[diffByDay] || 0;
                workPerDaySums[stepIndex] = (workPerDaySums[stepIndex] || 0) + workPerDayHours;
            });
        });

        users[userID] = {
            ...users[userID],
            userWorkPerDayForPeriod: workPerDaySums,
        };
    });

    return {
        ...state,
        users,
    };
}
