import { isInProjectAreaAndGroupingMode } from '../../util/utilitiesForState';
import { IWorkSchedulingCombinedState } from '../types';

export const shouldAddTableDataIDsForUnassignedSection = (
    nodes: any[],
    offset?: number
): boolean => {
    return !!(offset && nodes.length >= offset && offset > 0);
};

export const getTableDataIDsForUnassignedSectionAndShowMore = (
    state: IWorkSchedulingCombinedState,
    nodes: any[],
    offset?: number
): {
    tableDataIDsForUnassignedSection: string[];
    showMore: boolean;
} => {
    let tableDataIDsForUnassignedSection: string[] = [];
    let showMore = false;
    if (!isInProjectAreaAndGroupingMode(state)) {
        tableDataIDsForUnassignedSection = [nodes[offset! - 1]];
        showMore = nodes.length > offset!;
    }

    return { tableDataIDsForUnassignedSection, showMore };
};
