import { TAreaObjCodes } from '../data-flow/areaData/areaRelatedInitialDataState';
import { isInProjectArea } from './utilities';
import { hasLoadedDataIDs } from '../components/schedulingTable/schedulingTables/schedulingTableUtils';
import { Sections } from '../constants/schedulingTableConstants';

export const checkIsButtonVisible = (
    isSectionCollapsed: boolean,
    projectGroupingMode: boolean,
    tableDataIDsForUnassignedSection: string[],
    schedulingAreaObjCode: TAreaObjCodes
): boolean => {
    const doesUnassignedSectionHaveItems = hasLoadedDataIDs(
        tableDataIDsForUnassignedSection,
        Sections.UNASSIGNED_WORK
    );

    return (
        isSectionCollapsed &&
        projectGroupingMode &&
        doesUnassignedSectionHaveItems &&
        !isInProjectArea(schedulingAreaObjCode)
    );
};
