import { Field, FieldGroup } from '@workfront/panel-components';
import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { debounce, DebouncedFunc } from 'lodash';
import { Sections } from '../../constants/schedulingTableConstants';
import { getContextValue } from '../../contexts/checkContext';
import { IAreaState } from '../../data-flow/areaData/areaRelatedInitialDataState';
import changeContouringRowState from '../../data-flow/data/assignedDataActions/changeContouringRowState';
import removeTemporaryWorkPerDayHours from '../../data-flow/data/assignedDataActions/removeTemporaryWorkPerDayHours';
import toggleLoading from '../../data-flow/data/dataLoadingActions/toggleLoading';
import { contouringRowIdSelector } from '../../data-flow/data/selectors/dataSelectors';
import deleteFilter from '../../data-flow/filters/filtersActions/deleteFilter';
import { IFilterRuleGroup, IProjectStatusesForFilter } from '../../data-flow/filters/IFiltersState';
import actionChain from '../../data-flow/higher-order-reducers/actionChain';
import { loadUsersThunk } from '../../data-flow/thunks/loadDataThunk';
import { IWorkSchedulingCombinedState, IWorkSchedulingDispatchProp } from '../../data-flow/types';
import { generateUnsavedFilterPrefix, getFilterNameByArea } from '../../util/filters/filterUtil';
import { getInitialHeightsOfTables, getRowCountRelatedToHeight } from '../../util/utilities';
import {
    cleanUpAssignedBoardDataActions,
    cleanUpUnassignedBoardDataActions,
} from '../../data-flow/data/assignedDataActions/commonActionGroups/cleanUpBoardDataActions';
import { ICurrentCustomerState } from '../../data-flow/currentCustomer/ICurrentCustomerState';
import { currentCustomerSelector } from '../../data-flow/currentCustomer/selectors/currentCustomer';
import { TContouringRowID } from '../../data-flow/data/IDataState';
import { getFiltersFilterExpression } from '../../data-flow/thunks/loadFilterDataThunk/loadFilterDataThunk';
import { loadDataForUnassignedSection } from '../../data-flow/thunks/unassignedSectionLoadDataThunk';
import { editSettingsThunk } from '../../data-flow/thunks/settings';

interface IFilterContentProps {
    sectionType: Sections;
    labels: any[];
    projectStatuses: IProjectStatusesForFilter[];
    onClose: () => void;
    schedulingAreaData: IAreaState;
    quickFilterFields?: Field[];
    advancedFilterFields?: FieldGroup[];
}

interface IFilterContentComponentStateToProps {
    contouringRowID: TContouringRowID;
    currentCustomer: ICurrentCustomerState;
}

const Filters = lazy(() =>
    import('@workfront/filters-ui').then((module) => ({ default: module.Filters }))
);

class FilterContentComponent extends React.PureComponent<
    IWorkSchedulingDispatchProp & IFilterContentProps & IFilterContentComponentStateToProps
> {
    private readonly debouncedFiltersCallback: DebouncedFunc<
        (appliedFilters, filterExpression) => void
    >;

    constructor(props) {
        super(props);
        this.debouncedFiltersCallback = debounce(
            (appliedFilters, filterExpression) =>
                this.onApplyFiltersCallback(appliedFilters, filterExpression),
            1500
        );
    }

    getApplyAction = (): {
        cleanUpActions: () => any[];
        updateAction: () => void;
    } => {
        let cleanUpActions;
        let updateAction;

        const { sectionType } = this.props;

        if (sectionType === Sections.PEOPLE_WORKLOAD) {
            cleanUpActions = cleanUpAssignedBoardDataActions;
            updateAction = this.updateAssignedBoardData;
        } else {
            cleanUpActions = cleanUpUnassignedBoardDataActions;
            updateAction = this.updateUnassignedBoardData;
        }

        return {
            cleanUpActions,
            updateAction,
        };
    };

    clearAllHandler = (): void => {
        const { dispatch, sectionType, schedulingAreaData } = this.props;
        const { cleanUpActions, updateAction } = this.getApplyAction();
        /**
         * `deleteFilter` action must be called BEFORE `editSettingsThunk`
         * as `editSettingsThunk` uses state changes from `deleteFilter`
         * */
        dispatch(actionChain([deleteFilter(sectionType), ...cleanUpActions()]));
        localStorage.removeItem(
            generateUnsavedFilterPrefix(sectionType, schedulingAreaData.schedulingAreaObjCode)
        );
        if (!getContextValue('sharableLink')) {
            dispatch(editSettingsThunk());
        }
        dispatch(updateAction);
    };

    updateAssignedBoardData = (): any => {
        const { dispatch } = this.props;
        const { peopleWorkLoadHeight } = getInitialHeightsOfTables(0);

        return dispatch(
            loadUsersThunk(0, getRowCountRelatedToHeight(peopleWorkLoadHeight), undefined, true)
        );
    };

    updateUnassignedBoardData = (): any => {
        const { dispatch } = this.props;
        return dispatch(loadDataForUnassignedSection());
    };

    applyFilterFinalTouch = (msg: { error: string }): void => {
        const { cleanUpActions, updateAction } = this.getApplyAction();
        const { dispatch } = this.props;

        if (!getContextValue('sharableLink')) {
            dispatch(editSettingsThunk());
        }
        const errorExists = msg && msg.error;

        if (!errorExists) {
            dispatch(actionChain(cleanUpActions()));
            updateAction();
        }
    };

    applyFilters = (
        area: IAreaState,
        appliedFilterIDs: string[],
        filterExpression: IFilterRuleGroup
    ): void => {
        const { dispatch, sectionType, contouringRowID, schedulingAreaData } = this.props;
        if (contouringRowID) {
            dispatch(removeTemporaryWorkPerDayHours());
            dispatch(changeContouringRowState(null));
        }

        if (filterExpression) {
            localStorage.setItem(
                generateUnsavedFilterPrefix(sectionType, schedulingAreaData.schedulingAreaObjCode),
                JSON.stringify({ filterExpression })
            );
        } else {
            localStorage.removeItem(
                generateUnsavedFilterPrefix(sectionType, schedulingAreaData.schedulingAreaObjCode)
            );
        }

        dispatch(
            getFiltersFilterExpression(area, sectionType, appliedFilterIDs, filterExpression)
        ).then((msg) => {
            this.applyFilterFinalTouch(msg);
        });
    };

    onApplyFiltersCallback = (appliedFilters, filterExpression): void => {
        if (appliedFilters.length > 0 || filterExpression) {
            const appliedFilterID = appliedFilters.map((filter) => filter.ID);
            this.applyFilters(this.props.schedulingAreaData, appliedFilterID, filterExpression);
        }
        if (appliedFilters.length === 0 && !filterExpression) {
            this.clearAllHandler();
        }
    };

    onApplyFiltersCallbackWithDebounce = (appliedFilters, filterExpression): void => {
        const { dispatch, sectionType } = this.props;
        dispatch(toggleLoading(sectionType, true));
        this.debouncedFiltersCallback(appliedFilters, filterExpression);
    };

    render(): JSX.Element {
        const [assignedWorkLabel, unassignedWorkLabel] = this.props.labels;

        const {
            schedulingAreaData,
            sectionType,
            onClose,
            quickFilterFields,
            advancedFilterFields,
            currentCustomer,
        } = this.props;

        const sectionLabel =
            sectionType === Sections.UNASSIGNED_WORK
                ? unassignedWorkLabel('Unassigned Work')
                : assignedWorkLabel('Assigned Work');

        const { schedulingAreaObjCode } = schedulingAreaData;
        return (
            <Suspense fallback={null}>
                <Filters
                    key={sectionType}
                    filterKey={getFilterNameByArea(sectionType, schedulingAreaObjCode)}
                    quickFilterFields={quickFilterFields!}
                    advancedFieldGroups={advancedFilterFields!}
                    isAutoApply={false}
                    applyFiltersCallback={this.onApplyFiltersCallbackWithDebounce}
                    onCloseCallback={onClose}
                    currentUser={getContextValue('currentUser')}
                    subtitle={sectionLabel}
                    isOperatorsDisabled
                    isTextModeSupported={false}
                    customQuarters={currentCustomer.customQuarters}
                />
            </Suspense>
        );
    }
}

const mapStateToProps = (
    state: IWorkSchedulingCombinedState
): IFilterContentComponentStateToProps => ({
    contouringRowID: contouringRowIdSelector(state),
    currentCustomer: currentCustomerSelector(state),
});

export const FilterContent = connect(mapStateToProps)(FilterContentComponent);
