import _ from 'lodash';
import { addToast } from '@phoenix/all';
import { TLocalizationSingleProps } from '@workfront/localize-react';
import { apiDateToDate } from '@workfront/fns';
import { Action } from 'redux';
import { OpTask } from 'workfront-objcodes';
import {
    LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT,
    unassignedTasksSortObject,
} from '../../constants/dataConstatnts';
import { RECOMPUTE_GRID_SIZE } from '../../constants/events';
import { Sections } from '../../constants/schedulingTableConstants';
import { APIService } from '../../services/api-services/apiService';
import {
    checkShouldAddUserByAssignment,
    getUsersIDs,
    isRolesChanged,
    mergeWithLoadedUsersIDs,
    separateThreadPoolDelay,
} from '../../util/changeAssignmentsUtil';
import { getUserNodesIDs } from '../../util/dataService';
import { IAssignmentIds, IAssignmentFormData } from '../../util/dataStructures/IAssignmentIds';
import {
    getUnassignedAssignmentsProjectsFilter,
    getUnassignedIssuesProjectsFilter,
    getUnassignedTasksFilter,
    isFilterAppliedInUnassignedSection,
    prepareAreaAdditionalSimpleFilterForUnassignedProjects,
    prepareAreaAdditionalSimpleFilterForUnassignedTasks,
} from '../../util/filters/filterUtil';
import {
    getAffectedUsersAfterObjectDurationChange,
    getByMessageKeySync,
    isInProjectArea,
    isTaskIssueGhost,
    sortObject,
} from '../../util/utilities';
import { isInProjectAreaAndGroupingMode } from '../../util/utilitiesForState';
import addProjectsDetails from '../data/assignedDataActions/addProjectsDetails';
import addTableDataIDs from '../data/assignedDataActions/addTableDataIDs';
import changeOffset from '../data/assignedDataActions/changeOffset';
import removeAssignedObject from '../data/assignedDataActions/removeAssignedObject';
import removeInaccessibleProject from '../data/assignedDataActions/removeInaccessibleProject';
import setIntoHighlightingMode from '../data/assignedDataActions/setIntoHighlightingMode';
import setObjectsIntoAssignmentMode from '../data/assignedDataActions/setObjectsIntoAssignmentMode';
import setUserIntoAssignmentMode from '../data/assignedDataActions/setUserIntoAssignmentMode';
import sortUsersTasks from '../data/assignedDataActions/sortUsersTasks'; // TODO change name as it is not only for tasks
import updateTaskAndIssue from '../data/assignedDataActions/updateTaskAndIssue';
import removeRow from '../data/dataLoadingActions/removeRow';
import {
    IRequestedProjectData,
    IUserState,
    TBulkAssignmentUserOption,
    TProjectID,
    TTaskOrIssueID,
    TUserID,
    TUserRoleId,
} from '../data/IDataState';
import {
    hasMoreUnassignedTasksSelector,
    tableDataIDsForUnassignedSectionSelector,
    tableDataIDsForUnassignedSectionWithoutHeader,
    tasksAndIssuesSelector,
    unassignedTasksSelector,
} from '../data/selectors/dataSelectors';
import removeObjectAssignmentsDetails from '../data/sharedActions/removeObjectAssignmentsDetails';
import addTableDataIDsForUnassignedSection from '../data/unassignedDataActions/addTableDataIDsForUnassignedSection';
import addUnassignedProjects from '../data/unassignedDataActions/addUnassignedProjects';
import changeUnassignedProjectOffset from '../data/unassignedDataActions/changeUnassignedProjectOffset';
import removeUnassignedObject from '../data/unassignedDataActions/removeUnassignedObject';
import setHeightForUnassignedSectionEmptyRow from '../data/unassignedDataActions/setHeightForUnassignedSectionEmptyRow';
import sortUnassignedProjectsTasks from '../data/unassignedDataActions/sortUnassignedProjectsTasks';
import { endDateSelector } from '../dateRange/selectors/endDateSelector';
import actionChain from '../higher-order-reducers/actionChain';
import { internalEventEmitterSelector } from '../instances/internalEventEmitterSelector';
import { TModernSchedulingAction, TWorkSchedulingThunkAction } from '../types';
import { loadUsersAssignments, loadUsersThunk } from './loadDataThunk';
import addUsersByAssignment from '../data/assignedDataActions/addUsersByAssignment';
import { loadAssignmentsByTaskIDsFormService } from './loadWorkPerDayDataThunk';
import {
    getTableDataIDsForUnassignedSectionAndShowMore,
    shouldAddTableDataIDsForUnassignedSection,
} from './changeAssignmentsThunkUtils';
import changeNodeArrowState from '../data/nodeItemActions/changeNodeArrowState';
import { startDateSelector } from '../dateRange/selectors/startDateSelector';
import { stepUnitSelector } from '../dateRange/selectors/stepUnitSelector';
import { setIntoAssignmentModeActions } from '../data/assignedDataActions/commonActionGroups/unassignActions';
import removeTemporaryWorkPerDayHours from '../data/assignedDataActions/removeTemporaryWorkPerDayHours';
import { TShowProjectAndOrTasks } from '../IGeneralStateTypes';
import {
    getUserSelector,
    userAllProjectsSelector,
    userByIdSelector,
    usersSelector,
} from '../data/selectors/users/usersSelector';
import {
    tableDataIDsSelector,
    tableDataIDsSelectorWithoutHeaderSelector,
} from '../data/selectors/tableDataIDsSelector';
import {
    projectGroupingModeSelector,
    showIssuesSelector,
    showRoleSummarySelector,
} from '../settings/settingsSelector';
import { unassignedWorkHeightSelector } from '../tableSizes/selectors/unassignedWorkHeightSelector';
import { projectIDsWithinTableDataIDsForUnassignedSectionSelector } from '../data/selectors/reselect/projectIDsWithinTableDataIDsForUnassignedSectionSelector';
import { getUnassignedTaskSelector } from '../data/selectors/reselect/getUnassignedTaskSelector/getUnassignedTaskSelector';
import { getSchedulingAreaData } from '../areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import removeWorkPerDayHoursOnDragHover from '../data/sharedActions/removeWorkPerDayHoursOnDragHover';
import { cleanUpUnassignedBoardDataActions } from '../data/assignedDataActions/commonActionGroups/cleanUpBoardDataActions';
import removeUserAssignments from '../data/assignedDataActions/removeUsersAssignments';
import removeWorkPerDaysByUsersAssignments from '../data/assignedDataActions/removeWorkPerDaysByUsersAssignments';
import toggleSetting, { settingNames } from '../settings/settingsActions/settingsActions';
import {
    IProjectAssignmentAssignedTo,
    IProjectAssignmentRole,
    TTaskToProjectIDs,
} from '../../components/BulkAssignments/types';
import { BulkActions } from '../../constants/bulkAssignmentsConstants';
import {
    getObjectIDsFromTaskToProjectIDs,
    getUserRolesDetails,
} from '../../util/bulkAssignmentsUtil';
import {
    loadDataForUnassignedSection,
    loadUnassignedAssignments,
    loadUnassignedAssignmentsProjects,
} from './unassignedSectionLoadDataThunk';
import toggleLoading from '../data/dataLoadingActions/toggleLoading';
import { ConvertedFilterExpression } from '../../util/filters/filterUtilTypes';
import { loadWorkSummaryForRoles } from './roleSummary/toggleRoleSummaryThunk';

interface IAssignmentChangeConfig {
    afterDateChangeObjectOutOfPeriod?: boolean;
    completenessStatusChanged?: ICompletenessStatusChanged;
    isDatesChanged?: boolean;
    isGhost?: boolean;
    highlightTask?: boolean;
}

export interface ICompletenessStatusChanged {
    changed: boolean;
    newValue?: string;
}

export function saveAssignmentThunk(
    updatedAssignmentData: IAssignmentIds,
    oldAssignmentData: IAssignmentIds,
    objectID: string, // is task or issue id
    assignmentChangeConfig: IAssignmentChangeConfig,
    assignmentFromMinix = false,
    assignmentFromDrag: boolean = false
): TWorkSchedulingThunkAction<Promise<any>> {
    const {
        afterDateChangeObjectOutOfPeriod = false,
        completenessStatusChanged = {
            changed: false,
        },
        isDatesChanged = false,
        isGhost = false,
        highlightTask = false,
    } = assignmentChangeConfig;

    return function _saveAssignmentThunk(dispatch, getState) {
        let updatedUserIDs = updatedAssignmentData.userIDs;
        const isSavedFromAdvanced = !updatedUserIDs && updatedAssignmentData.assignments;
        if (isSavedFromAdvanced) {
            updatedUserIDs = updatedAssignmentData.assignments!.map(
                (assignment) => assignment.assignedToID
            );
        }
        const state = getState();

        const loadedUsers = usersSelector(state);

        const addedUsersIDs: string[] = getUsersIDs(updatedUserIDs, oldAssignmentData.userIDs);

        const removedUsersIDs: string[] = afterDateChangeObjectOutOfPeriod
            ? oldAssignmentData.userIDs
            : getUsersIDs(oldAssignmentData.userIDs, updatedUserIDs);

        const isTeamChanged = oldAssignmentData.teamID !== updatedAssignmentData.teamID;
        const isRoleChanged = isRolesChanged(updatedAssignmentData, oldAssignmentData);

        if (
            !addedUsersIDs.length &&
            !removedUsersIDs.length &&
            !isTeamChanged &&
            !isRoleChanged &&
            !isDatesChanged &&
            !completenessStatusChanged.changed &&
            !isSavedFromAdvanced
        ) {
            return Promise.resolve();
        }

        const objectDetails =
            unassignedTasksSelector(state)[objectID] || tasksAndIssuesSelector(state)[objectID];
        const { projectID } = objectDetails;

        const affectedUsersIDs = _.uniq(
            mergeWithLoadedUsersIDs(updatedUserIDs.concat(removedUsersIDs), loadedUsers)
        );

        if (assignmentFromMinix || isSavedFromAdvanced) {
            dispatch(
                actionChain([
                    setObjectsIntoAssignmentMode([objectID]),
                    setUserIntoAssignmentMode(affectedUsersIDs, true),
                ])
            );
        }

        // Should load assignment for all users which are assigned to Task or issue
        // As adding or removing new user from task/issue will affect on other assigned users workPerDays
        const removedUsersMergedWithLoaded = mergeWithLoadedUsersIDs(removedUsersIDs, loadedUsers);

        const makeActionsAfterAssignment = (): Promise<void> => {
            const promises: any[] = [
                dispatch(
                    loadUsersAssignments(affectedUsersIDs, objectID, [
                        removeAssignedObject(
                            removedUsersMergedWithLoaded,
                            objectID,
                            projectID,
                            projectGroupingModeSelector(state)
                        ),
                    ])
                ),
            ];

            const { filterExpression } = getState().Filters[Sections.PEOPLE_WORKLOAD].usersFilter;
            const filterRules = filterExpression?.rules;
            const schedulingAreaData = getSchedulingAreaData(getState());

            if (
                checkShouldAddUserByAssignment(
                    schedulingAreaData.schedulingAreaObjCode,
                    filterRules
                )
            ) {
                promises.push(dispatch(addUsersByAssignmentThunk(addedUsersIDs)));
            }

            const isFilterApplied = isFilterAppliedInUnassignedSection(state);

            if (isFilterApplied || schedulingAreaData.schedulingAreaObjCode) {
                promises.push(dispatch(addUnassignedObjectThunk(objectID, projectID)));
            }

            return Promise.all(promises).then(() => {
                /**
                 * We call `removeObjectAssignmentsDetails` action, so that
                 * on next assignment widget open case, assignments data is again get from server
                 * */

                const actions: Action[] = [removeObjectAssignmentsDetails([objectID])];
                if (!isGhost) {
                    actions.push(
                        setIntoHighlightingMode(affectedUsersIDs, [objectID], {
                            objectDetails: afterDateChangeObjectOutOfPeriod
                                ? { objectID: objectDetails }
                                : null,
                            highlightTask,
                        })
                    );
                }

                // or marked completed, or completed status reverted
                if (completenessStatusChanged.changed) {
                    actions.push(
                        updateTaskAndIssue(objectID, {
                            actualCompletionDate: completenessStatusChanged.newValue,
                        })
                    );
                }
                dispatch(actionChain(actions));

                const taskAndIssuesIds = Object.keys(tasksAndIssuesSelector(getState()));
                const shouldAddAssignmentFromDrag =
                    !assignmentFromDrag || taskAndIssuesIds.includes(objectID);
                // it is for assigned section
                if (addedUsersIDs.length && shouldAddAssignmentFromDrag) {
                    dispatch(
                        addAssignmentsThunk(
                            mergeWithLoadedUsersIDs(addedUsersIDs, loadedUsers),
                            objectID,
                            projectID
                        )
                    );
                }

                if (removedUsersIDs.length) {
                    internalEventEmitterSelector(state).emit(RECOMPUTE_GRID_SIZE);
                }

                const newState = getState();
                const isRoleSummaryOpen = showRoleSummarySelector(newState);

                if (
                    isInProjectArea(schedulingAreaData.schedulingAreaObjCode) &&
                    schedulingAreaData.schedulingAreaID &&
                    isRoleSummaryOpen
                ) {
                    dispatch(loadWorkSummaryForRoles());
                }
            });
        };

        if (assignmentFromDrag) {
            return makeActionsAfterAssignment();
        }

        return separateThreadPoolDelay().then(() => {
            return makeActionsAfterAssignment();
        });
    };
}

export const assignUserToRoleOnTasksThunk = (
    taskToProjectIDs: TTaskToProjectIDs[],
    swapUserID: TUserID,
    swapRoleID: TUserRoleId,
    affectedUserIDs: TUserID[],
    searchedOnlyByProjects: boolean,
    lockToRole: boolean
): TWorkSchedulingThunkAction<Promise<any>> => {
    return function _assignUserToRoleOnTasksThunk(dispatch): Promise<any> {
        const { taskOrIssueIDs, projectIDs } = getObjectIDsFromTaskToProjectIDs(taskToProjectIDs);

        dispatch(
            actionChain([
                setObjectsIntoAssignmentMode(taskOrIssueIDs),
                setUserIntoAssignmentMode(affectedUserIDs, true),
            ])
        );

        const assignApi = searchedOnlyByProjects
            ? APIService.assignUserToRoleOnProjects(projectIDs, swapUserID, swapRoleID, lockToRole)
            : APIService.assignUserToRoleOnTasks(
                  taskOrIssueIDs,
                  swapUserID,
                  swapRoleID,
                  lockToRole
              );

        return assignApi
            .then(() => {
                const notificationDetails = {
                    messageKey: `workloadbalancer.you.successfully.assigned.work.items`,
                    fallback: `You successfully assigned { count, plural, one {# work item} other {# work items}}`,
                    args: { count: taskOrIssueIDs.length },
                };
                return dispatch(
                    actionsAfterBulkAction(
                        affectedUserIDs,
                        taskOrIssueIDs,
                        taskToProjectIDs,
                        [swapUserID],
                        notificationDetails,
                        BulkActions.ASSIGN_USER
                    )
                );
            })
            .catch((error) => {
                const errorMessage =
                    error ??
                    getByMessageKeySync(
                        `unknown.server.error`,
                        `The server encountered an unknown error.`
                    );
                addToast('error', errorMessage);
            })
            .finally(() => {
                dispatch(
                    actionChain([
                        removeObjectAssignmentsDetails(taskOrIssueIDs),
                        setUserIntoAssignmentMode(affectedUserIDs, false),
                    ])
                );
            });
    };
};

const getOffset = (
    userData: IUserState,
    projectID: string,
    projectGroupingMode: boolean
): number => {
    return projectGroupingMode ? userData.nodes[projectID].offset : userData.offset;
};

function addAssignmentsThunk(
    addedUsersIDs: string[],
    objectID: string, // task/issue ID
    projectID: TProjectID,
    isBulkAssignment: boolean = false
): TWorkSchedulingThunkAction<void> {
    return function _addAssignmentsThunk(dispatch, getState): void {
        const state = getState();
        const addedUsersIDsWithAssignments = getAffectedUsersAfterObjectDurationChange(
            addedUsersIDs,
            usersSelector(state),
            projectID
        );
        dispatch(sortUsersTasks(addedUsersIDsWithAssignments, projectID)); // sorting tasks and issues by plannedCompletion, plannedStart dates and name

        const projectGroupingMode = projectGroupingModeSelector(state);
        const tableDataIds = tableDataIDsSelectorWithoutHeaderSelector(state.Data);
        const chainActions: any[] = [];
        let projectDetailsPromise: Promise<Array<{ result: IRequestedProjectData[] }>> =
            Promise.resolve([]);

        if (projectGroupingMode && !state.Data.projects[projectID]) {
            projectDetailsPromise = APIService.loadProjectsDetails([[projectID]], undefined);
        }

        projectDetailsPromise.then((usersProjectDetails) => {
            if (usersProjectDetails.length && projectGroupingMode) {
                chainActions.push(addProjectsDetails(usersProjectDetails[0].result));
            }

            let countAddedRows = 0;

            addedUsersIDs.forEach((userID) => {
                const userData: IUserState = userByIdSelector(state, { userID });
                const idExpression = userID + (projectGroupingMode ? `_${projectID}` : '');

                // adding project row if user expanded
                if (
                    userData.expanded &&
                    projectGroupingMode &&
                    tableDataIDsSelector(getState()).indexOf(idExpression) === -1
                ) {
                    chainActions.push(
                        addTableDataIDs([projectID], true, {
                            idExpression: userID,
                            showMore: false,
                            sliceIndex: tableDataIds.indexOf(userID) + 1 + countAddedRows,
                        })
                    );

                    chainActions.push(changeOffset(userID, userData.offset + 1));

                    countAddedRows += 1;
                }

                if (userData.inaccessibleProjectIDs.indexOf(projectID) !== -1) {
                    chainActions.push(removeInaccessibleProject(userID, projectID));
                    if (projectGroupingMode && userData.inaccessibleProjectIDs.length === 1) {
                        chainActions.push(
                            removeRow(`${userID}_showInaccessible`, Sections.PEOPLE_WORKLOAD)
                        );

                        countAddedRows -= 1;
                    }
                }

                // adding task/issue row if user expanded
                if (
                    userData.expanded &&
                    (!projectGroupingMode || userData.expandedProjectIDs.has(projectID))
                ) {
                    const schedulingAreaData = getSchedulingAreaData(state);

                    const nodes = projectGroupingMode
                        ? userData.nodes[projectID].nodes
                        : getUserNodesIDs(userData.nodes, schedulingAreaData);
                    const objectIDIndex = nodes.indexOf(objectID);
                    const offset = getOffset(userData, projectID, projectGroupingMode);

                    if (
                        objectIDIndex + 1 <= offset ||
                        tableDataIds.indexOf(`${idExpression}_showMore`) === -1
                    ) {
                        let addingIndex =
                            objectIDIndex === 0
                                ? tableDataIds.indexOf(idExpression)
                                : tableDataIds.indexOf(
                                      `${idExpression}_${nodes[objectIDIndex - 1]}`
                                  );

                        addingIndex += 1 + countAddedRows;

                        chainActions.push(
                            addTableDataIDs([objectID], projectGroupingMode, {
                                idExpression,
                                showMore: false,
                                sliceIndex: isBulkAssignment
                                    ? tableDataIds.indexOf(idExpression) + 1 + countAddedRows
                                    : addingIndex,
                            })
                        );

                        chainActions.push(changeOffset(idExpression, offset + 1));

                        countAddedRows += 1;
                    }
                }
            });

            dispatch(actionChain(chainActions));
            internalEventEmitterSelector(getState()).emit(RECOMPUTE_GRID_SIZE);
        });
    };
}

function addUnassignedObjectThunk(
    taskID: string,
    projectID: string
): TWorkSchedulingThunkAction<Promise<void>> {
    return function _addUnassignedObjectThunk(dispatch, getState) {
        const state = getState();
        const { startDate } = state.DateRange;
        const endDate = endDateSelector(state);

        if (
            !unassignedWorkHeightSelector(state) &&
            !tableDataIDsForUnassignedSectionSelector(state).length
        ) {
            return Promise.resolve();
        }

        if (!projectGroupingModeSelector(state)) {
            return dispatch(
                addUnassignedObjectWhenProjectGroupingOff(taskID, projectID, startDate, endDate)
            );
        }

        return dispatch(
            addUnassignedObjectWhenProjectGroupingOn(taskID, projectID, startDate, endDate)
        );
    };
}

export function removeUnassignedProjectAndTaskThunk(
    projectID: string,
    taskID
): TWorkSchedulingThunkAction<void> {
    return function _removeUnassignedProjectAndTaskThunk(dispatch, getState) {
        const state = getState();

        dispatch(
            actionChain([
                removeUnassignedObject(taskID, projectID, projectGroupingModeSelector(state)),
                setHeightForUnassignedSectionEmptyRow(
                    unassignedWorkHeightSelector(state),
                    projectGroupingModeSelector(state)
                ),
            ])
        );

        // adding of row instead of removed and hide showMore if necessary
        if (projectGroupingModeSelector(state)) {
            const newState = getState();
            const projectsCount =
                projectIDsWithinTableDataIDsForUnassignedSectionSelector(state).length;
            const projectsCountAfterRemove =
                projectIDsWithinTableDataIDsForUnassignedSectionSelector(newState).length;

            if (projectsCount !== projectsCountAfterRemove && state.Data.hasMoreUnassignedTasks) {
                dispatch(loadUnassignedAssignmentsProjects(1, projectsCountAfterRemove));
            }

            if (
                projectsCount === projectsCountAfterRemove &&
                newState.Data.unassignedTasksProjects[projectID]
            ) {
                const unassignedProjectNode = newState.Data.unassignedTasksProjects[projectID];
                const { offset } = unassignedProjectNode.details;
                const { nodes } = unassignedProjectNode;

                if (shouldAddTableDataIDsForUnassignedSection(nodes, offset)) {
                    const { tableDataIDsForUnassignedSection, showMore } =
                        getTableDataIDsForUnassignedSectionAndShowMore(state, nodes, offset);

                    dispatch(
                        actionChain([
                            removeRow(`${projectID}_showMore`, Sections.UNASSIGNED_WORK),
                            addTableDataIDsForUnassignedSection(
                                tableDataIDsForUnassignedSection,
                                projectID,
                                showMore
                            ),
                        ])
                    );
                }
            }
        }

        if (!projectGroupingModeSelector(state) && state.Data.hasMoreUnassignedTasks) {
            dispatch(
                loadUnassignedAssignments(
                    1,
                    getState().Data.tableDataIDsForUnassignedSection.length
                )
            );
        }
    };
}

function addUnassignedObjectWhenProjectGroupingOff(
    taskID,
    projectID,
    startDate,
    endDate
): TWorkSchedulingThunkAction<Promise<void>> {
    return function _addUnassignedObjectWhenProjectGroupingOff(dispatch, getState) {
        const state = getState();

        const { filterExpression } = state.Filters[Sections.UNASSIGNED_WORK];
        const schedulingAreaData = getSchedulingAreaData(getState());
        const additionalSimpleFilter =
            prepareAreaAdditionalSimpleFilterForUnassignedTasks(schedulingAreaData);
        const { filter, filterOptask } = getUnassignedTasksFilter(
            filterExpression,
            additionalSimpleFilter,
            startDate,
            endDate,
            taskID,
            1,
            0
        );

        const callMethodBasedOnObjCode = (): {
            method: string;
            requestFilter: ConvertedFilterExpression;
        } => {
            if (
                unassignedTasksSelector(state)[taskID]?.objCode === OpTask ||
                tasksAndIssuesSelector(state)[taskID]?.objCode === OpTask
            ) {
                return {
                    method: 'loadUnassignedIssuesAssignments',
                    requestFilter: filterOptask,
                };
            }

            return {
                method: 'loadUnassignedAssignments',
                requestFilter: filter,
            };
        };

        const { method, requestFilter } = callMethodBasedOnObjCode();
        return APIService[method](requestFilter).then((unassignedTasks) => {
            const newState = getState();
            const tableDataIds = [...tableDataIDsForUnassignedSectionSelector(newState)];
            const unassignedTasksInState = { ...unassignedTasksSelector(newState) };

            unassignedTasks.forEach((task) => {
                unassignedTasksInState[task.ID] = {
                    ...task,
                    plannedStartDate: apiDateToDate(task.plannedStartDate),
                    plannedCompletionDate: apiDateToDate(task.plannedCompletionDate),
                };
            });

            const args = {
                unassignedTasks,
                startDate,
                endDate,
                taskID,
                tableDataIds,
                unassignedTasksInState,
                hasMoreUnassignedTasks: hasMoreUnassignedTasksSelector(newState),
                unassignedWorkHeight: unassignedWorkHeightSelector(newState),
                projectGroupingMode: projectGroupingModeSelector(newState),
            };
            const chainActions = finalActionsForLoadingUnassignedTasks(args);

            dispatch(loadAssignmentsByTaskIDsFormService(args, chainActions));

            if (
                !unassignedTasks.length &&
                tableDataIDsForUnassignedSectionSelector(newState).indexOf(taskID) !== -1
            ) {
                dispatch(removeUnassignedProjectAndTaskThunk(projectID, taskID));
            }
        });
    };
}

function finalActionsForLoadingUnassignedTasks(args): any[] {
    const chainActions: any[] = [];
    const {
        unassignedTasks,
        taskID,
        tableDataIds,
        unassignedTasksInState,
        hasMoreUnassignedTasks,
        unassignedWorkHeight,
        projectGroupingMode,
    } = args;

    if (unassignedTasks.length && tableDataIds.indexOf(taskID) === -1) {
        tableDataIds.push(taskID);
        tableDataIds.sort(sortObject(unassignedTasksInState, unassignedTasksSortObject));

        const lastIndex = tableDataIds.length - 1;
        const newAddedTaskIndex = tableDataIds.indexOf(taskID);

        if (newAddedTaskIndex !== lastIndex || !hasMoreUnassignedTasks) {
            chainActions.push(
                addTableDataIDsForUnassignedSection([taskID], '', false, newAddedTaskIndex),
                setHeightForUnassignedSectionEmptyRow(unassignedWorkHeight, projectGroupingMode)
            );
        }
    }

    return chainActions;
}

function addUnassignedObjectWhenProjectGroupingOn(
    taskID,
    projectID,
    startDate,
    endDate
): TWorkSchedulingThunkAction<Promise<void>> {
    return function _addUnassignedObjectWhenProjectGroupingOn(dispatch, getState) {
        const state = getState();
        const unassignedProject = state.Data.unassignedTasksProjects[projectID];
        const { filterExpression } = getState().Filters[Sections.UNASSIGNED_WORK];
        const schedulingAreaData = getSchedulingAreaData(getState());
        const additionalSimpleFilter =
            prepareAreaAdditionalSimpleFilterForUnassignedTasks(schedulingAreaData);
        const { filter, filterOptask } = getUnassignedTasksFilter(
            filterExpression,
            additionalSimpleFilter,
            startDate,
            endDate,
            taskID
        );

        return APIService.loadUnassignedAssignmentsByProjects(
            filter,
            filterOptask,
            [projectID],
            [projectID],
            showIssuesSelector(state)
        ).then((unassignedData) => {
            if (startDate === state.DateRange.startDate) {
                const unassignedTasks = _.uniqBy(unassignedData.flat(), 'ID');

                if (unassignedTasks.length) {
                    const args = {
                        unassignedTasks,
                        startDate,
                        endDate,
                        projectID,
                        taskID,
                    };

                    if (unassignedProject) {
                        dispatch(addUnassignedTaskThunk(args));
                    } else {
                        dispatch(addUnassignedProjectThunk(args));
                    }
                } else {
                    dispatch(removeUnassignedProjectAndTaskThunk(projectID, taskID));
                }
            }
        });
    };
}

function addUnassignedTaskThunk(args): TWorkSchedulingThunkAction<void> {
    return function _addUnassignedTaskThunk(dispatch, getState) {
        const { projectID, unassignedTasks, taskID } = args;
        const state = getState();
        const tableDataIds = [...tableDataIDsForUnassignedSectionWithoutHeader(state.Data)];
        const unassignedProject = state.Data.unassignedTasksProjects[projectID];
        const projectNodes = [...unassignedProject.nodes];
        const unassignedTasksFromState = { ...state.Data.unassignedTasks };
        const chainActions: any[] = [];

        chainActions.push(addUnassignedProjects([], unassignedTasks));
        chainActions.push(sortUnassignedProjectsTasks(projectID));

        if (unassignedProject.nodes.indexOf(taskID) === -1) {
            projectNodes.push(taskID);
            unassignedTasksFromState[taskID] = unassignedTasks[0];
        }

        // should sort as  dates can be  changed
        projectNodes.sort(sortObject(unassignedTasksFromState, unassignedTasksSortObject));

        const isExpanded =
            unassignedProject.details.expanded || isInProjectAreaAndGroupingMode(state);

        if (isExpanded && unassignedProject.nodes.indexOf(taskID) === -1) {
            const taskIDIndex = projectNodes.indexOf(taskID);
            const offset = unassignedProject.details.offset || 0;

            if (taskIDIndex + 1 <= offset || tableDataIds.indexOf(`${projectID}_showMore`) === -1) {
                let addingIndex;

                if (taskIDIndex === 0) {
                    addingIndex = tableDataIds.indexOf(projectID) + 1;
                } else {
                    addingIndex =
                        tableDataIds.indexOf(`${projectID}_${projectNodes[taskIDIndex - 1]}`) + 1;
                }

                chainActions.push(
                    addTableDataIDsForUnassignedSection([taskID], projectID, false, addingIndex),
                    changeUnassignedProjectOffset(projectID, offset + 1),
                    setHeightForUnassignedSectionEmptyRow(
                        getState().TableSizes.unassignedWorkHeight,
                        projectGroupingModeSelector(state)
                    )
                );
            }
        }

        dispatch(loadAssignmentsByTaskIDsFormService(args, chainActions));
    };
}

function addUnassignedProjectThunk(args): TWorkSchedulingThunkAction<void> {
    return function _addUnassignedProjectThunk(dispatch, getState) {
        const { unassignedTasks, projectID, startDate, endDate } = args;
        const state = getState();
        let limit = projectIDsWithinTableDataIDsForUnassignedSectionSelector(state).length;

        if (limit <= LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT) {
            limit += 1;
        }

        // getting all loaded projects for right sorting
        const { filterExpression } = getState().Filters[Sections.UNASSIGNED_WORK];
        const { projectsSortingCriteria } = state.SettingsState;
        const schedulingAreaData = getSchedulingAreaData(getState());
        const additionalSimpleFilter = prepareAreaAdditionalSimpleFilterForUnassignedProjects(
            schedulingAreaData,
            false
        );
        let filter;
        if (unassignedTasks[0].objCode === 'OPTASK') {
            filter = getUnassignedIssuesProjectsFilter(
                filterExpression,
                additionalSimpleFilter,
                startDate,
                endDate,
                limit,
                0,
                projectsSortingCriteria
            );
        } else {
            filter = getUnassignedAssignmentsProjectsFilter(
                filterExpression,
                additionalSimpleFilter,
                startDate,
                endDate,
                limit,
                0,
                projectsSortingCriteria
            );
        }
        return APIService.loadUnassignedTasksProjects(filter).then((loadedProjects) => {
            const projectsIDs = loadedProjects.map((item) => item.ID);
            const indexOfProjectID = projectsIDs.indexOf(projectID);
            const nextProjectID = projectsIDs[indexOfProjectID + 1];
            const indexOfNextProject =
                tableDataIDsForUnassignedSectionSelector(state).indexOf(nextProjectID);

            if (indexOfProjectID !== -1) {
                const addingIndex =
                    indexOfNextProject !== -1
                        ? indexOfNextProject
                        : tableDataIDsForUnassignedSectionSelector(state).length;

                const tableDataIDsForUnassignedSection = [projectID];
                if (isInProjectAreaAndGroupingMode(state)) {
                    const unassignedItem = unassignedTasks.find(
                        (unassignedTask) =>
                            unassignedTask.projectID === schedulingAreaData.schedulingAreaID
                    );
                    if (unassignedItem) {
                        tableDataIDsForUnassignedSection.push(`${projectID}_${unassignedItem.ID}`);
                    }
                }

                const chainActions = [
                    addUnassignedProjects([loadedProjects[indexOfProjectID]], unassignedTasks),
                    addTableDataIDsForUnassignedSection(
                        tableDataIDsForUnassignedSection,
                        '',
                        false,
                        addingIndex
                    ),
                    setHeightForUnassignedSectionEmptyRow(
                        getState().TableSizes.unassignedWorkHeight,
                        projectGroupingModeSelector(state)
                    ),
                ];

                dispatch(loadAssignmentsByTaskIDsFormService(args, chainActions));
            }
        });
    };
}

export const addUsersByAssignmentThunk = (userIDs) => {
    return function _addUserAfterAssignmentThunk(dispatch, getState) {
        const assignedSectionUsers = getState().Data.users;
        const notLoadedUsers = userIDs.filter((userID) => !assignedSectionUsers[userID]);
        if (notLoadedUsers.length) {
            dispatch(addUsersByAssignment(notLoadedUsers));
            dispatch(loadUsersThunk(0, notLoadedUsers.length, notLoadedUsers));
        }
    };
};

export const assignUserAfterDropping = (
    assignmentData: IAssignmentIds,
    assignmentFormData: IAssignmentFormData,
    assignmentID: string,
    idExpression: string,
    objCode: string,
    oldAssignmentData: IAssignmentIds,
    showProjectAndOrTasks?: TShowProjectAndOrTasks
): TWorkSchedulingThunkAction<any> => {
    return function _assignUserAfterDropping(dispatch, getState) {
        return APIService.assignObject(assignmentFormData, assignmentID, objCode).then(() => {
            const state = getState();
            const taskOrIssue =
                tasksAndIssuesSelector(state)[assignmentID] ||
                getUnassignedTaskSelector(state)(assignmentID);
            const stepUnit = stepUnitSelector(state);
            const isGhost = isTaskIssueGhost(
                {
                    plannedStartDate: taskOrIssue.plannedStartDate,
                    plannedCompletionDate: taskOrIssue.plannedCompletionDate,
                },
                {
                    startDate: startDateSelector(state),
                    endDate: endDateSelector(state),
                },
                stepUnit
            );

            const assignmentChangeConfig = {
                isGhost,
                highlightTask: true,
            };

            return dispatch(
                saveAssignmentThunk(
                    assignmentData,
                    oldAssignmentData,
                    assignmentID,
                    assignmentChangeConfig,
                    false,
                    true
                )
            ).then(() => {
                const newState = getState();
                const userData = userByIdSelector(newState, { userID: idExpression });

                if (showProjectAndOrTasks && userData && !userData.expanded) {
                    showProjectAndOrTasks(userData, { [assignmentID]: taskOrIssue });
                    dispatch(changeNodeArrowState(userData.ID, undefined));
                }
                dispatch(
                    actionChain([
                        setIntoAssignmentModeActions(assignmentID),
                        removeTemporaryWorkPerDayHours(),
                        removeWorkPerDayHoursOnDragHover(),
                    ])
                );
                internalEventEmitterSelector(newState).emit(RECOMPUTE_GRID_SIZE);
            });
        });
    };
};

export const swapUsers = (
    taskToProjectIDs: TTaskToProjectIDs[],
    selectedUser: TBulkAssignmentUserOption,
    changeFrom: IProjectAssignmentAssignedTo,
    userRoles: IProjectAssignmentRole[],
    searchedOnlyByProjects: boolean,
    affectedUserIDs: string[]
) => {
    return function _swapUsers(dispatch, getState): Promise<any> {
        const { taskOrIssueIDs, projectIDs } = getObjectIDsFromTaskToProjectIDs(taskToProjectIDs);
        const { roleIDs, roleNames } = getUserRolesDetails(userRoles);

        const swapUserApi = searchedOnlyByProjects
            ? APIService.swapUsersOnProjects(projectIDs, selectedUser.ID, changeFrom.ID, roleIDs)
            : APIService.swapUsersOnTasks(taskOrIssueIDs, selectedUser.ID, changeFrom.ID, roleIDs);

        dispatch(
            actionChain([
                setObjectsIntoAssignmentMode(taskOrIssueIDs),
                setUserIntoAssignmentMode(affectedUserIDs, true),
            ])
        );

        const newState = getState();

        return swapUserApi
            .then(() => {
                const isUserLoaded = getUserSelector(newState)(changeFrom.ID);
                const unassignedUserNodes = isUserLoaded?.nodes;

                taskToProjectIDs.forEach(({ taskOrIssueID: _taskOrIssueID, projectID }) => {
                    if (unassignedUserNodes?.[projectID]?.nodes) {
                        dispatch(
                            removeUserDataAfterBulkAction(changeFrom, _taskOrIssueID, projectID)
                        );
                    }
                });
                const notificationDetails = {
                    messageKey: `workloadbalancer.you.successfully.replace.work.items`,
                    fallback: `{selectedUser} replaced {changeFrom} {rolesCount, plural, =0 {} =1 {in the {roleNames} role} other {in the {roleNames} roles}} on { count, plural, one {# work item} other {# work items}}`,
                    args: {
                        selectedUser: selectedUser.name,
                        changeFrom: changeFrom.name,
                        roleNames,
                        rolesCount: roleNames.length,
                        count: taskOrIssueIDs.length,
                    },
                };

                return dispatch(
                    actionsAfterBulkAction(
                        affectedUserIDs,
                        taskOrIssueIDs,
                        taskToProjectIDs,
                        [selectedUser.ID],
                        notificationDetails,
                        BulkActions.REPLACE_USER
                    )
                );
            })
            .catch((error) => {
                const errorMessage =
                    error ??
                    getByMessageKeySync(
                        `unknown.server.error`,
                        `The server encountered an unknown error.`
                    );
                addToast('error', errorMessage);
            });
    };
};

export const unassignUserFromAssignments = (
    taskToProjectIDs: TTaskToProjectIDs[],
    userRoles: IProjectAssignmentRole[],
    unassignedUser: IProjectAssignmentAssignedTo,
    searchedOnlyByProjects: boolean,
    assignmentsIDs: TTaskOrIssueID[]
) => {
    return function _unassignUserFromAssignments(dispatch, getState): Promise<void> {
        const { taskOrIssueIDs, projectIDs } = getObjectIDsFromTaskToProjectIDs(taskToProjectIDs);
        const { roleIDs, roleNames } = getUserRolesDetails(userRoles);

        let unassignUserApi;
        if (userRoles.length < 1) {
            unassignUserApi = APIService.deleteUserAssignments(assignmentsIDs);
        } else {
            searchedOnlyByProjects
                ? (unassignUserApi = APIService.unassignUserFromProjects(
                      projectIDs,
                      unassignedUser.ID,
                      roleIDs
                  ))
                : (unassignUserApi = APIService.unassignUserFromTasks(
                      taskOrIssueIDs,
                      unassignedUser.ID,
                      roleIDs
                  ));
        }
        dispatch(
            actionChain([
                setObjectsIntoAssignmentMode(taskOrIssueIDs),
                setUserIntoAssignmentMode([unassignedUser], true),
            ])
        );
        const notificationDetails = {
            messageKey: `workloadbalancer.you.successfully.unassigned.work.items`,
            fallback: `You successfully unassigned {unassignedUser} on { count, plural, one {# work item} other {# work items}} {rolesCount, plural, =0 {} =1 {in the {roleNames} role} other {in the {roleNames} roles}}`,
            args: {
                unassignedUser: unassignedUser.name,
                count: taskOrIssueIDs.length,
                rolesCount: roleNames.length,
                roleNames,
            },
        };
        return unassignUserApi.then(() => {
            const isUserLoaded = getUserSelector(getState())(unassignedUser.ID);
            const unassignedUserNodes = isUserLoaded?.nodes;

            taskToProjectIDs.forEach(({ taskOrIssueID: _taskOrIssueID, projectID }) => {
                if (unassignedUserNodes?.[projectID]?.nodes) {
                    dispatch(
                        removeUserDataAfterBulkAction(unassignedUser, _taskOrIssueID, projectID)
                    );
                }
            });
            dispatch(
                dispatchFinalActionAfterBulkAction(
                    [unassignedUser.ID],
                    taskOrIssueIDs,
                    notificationDetails,
                    BulkActions.UN_ASSIGN_USER
                )
            );
        });
    };
};

export const actionsAfterBulkAction = (
    affectedUserIDs: TUserID[],
    taskOrIssueIDs: TTaskOrIssueID[],
    taskToProjectIDs: TTaskToProjectIDs[],
    swapUserID: TUserID[],
    notificationDetails: TLocalizationSingleProps<any>,
    bulkAction: BulkActions
) => {
    return function _actionsAfterBulkAction(dispatch, getState): Promise<any> {
        const listOfDispatchers: any[] = [];
        const userIDsInAssignedSection = Object.keys(usersSelector(getState())).filter((id) =>
            affectedUserIDs.includes(id)
        );

        if (userIDsInAssignedSection.length) {
            listOfDispatchers.push(dispatch(loadUsersAssignments(userIDsInAssignedSection, null)));
        }

        const { filterExpression } = getState().Filters[Sections.PEOPLE_WORKLOAD].usersFilter;
        const filterRules = filterExpression && filterExpression.rules;
        const schedulingAreaData = getSchedulingAreaData(getState());

        if (checkShouldAddUserByAssignment(schedulingAreaData.schedulingAreaObjCode, filterRules)) {
            listOfDispatchers.push(dispatch(addUsersByAssignmentThunk(swapUserID)));
        }

        let isFilterApplied = false;
        if (bulkAction === BulkActions.ASSIGN_USER) {
            isFilterApplied = isFilterAppliedInUnassignedSection(getState());
        }

        if (
            bulkAction === BulkActions.ASSIGN_USER &&
            (isFilterApplied || schedulingAreaData.schedulingAreaObjCode)
        ) {
            listOfDispatchers.push(dispatch(actionChain(cleanUpUnassignedBoardDataActions())));
            listOfDispatchers.push(dispatch(loadDataForUnassignedSection()));
        }
        const newState = getState();
        // it is for assigned section
        const loadedUsers = usersSelector(newState);
        return Promise.all(listOfDispatchers).then(() => {
            const affectedUsers = mergeWithLoadedUsersIDs(swapUserID, loadedUsers);
            if (!affectedUsers.length) {
                dispatch(
                    dispatchFinalActionAfterBulkAction(
                        affectedUserIDs,
                        taskOrIssueIDs,
                        notificationDetails,
                        bulkAction
                    )
                );
                return;
            }
            const userProjects = userAllProjectsSelector(getState(), { userID: affectedUsers });
            taskToProjectIDs.forEach(({ taskOrIssueID: _taskOrIssueID, projectID }) => {
                if (userProjects.indexOf(projectID) !== -1) {
                    dispatch(addAssignmentsThunk(affectedUsers, _taskOrIssueID, projectID, true));
                }
            });
            dispatch(
                dispatchFinalActionAfterBulkAction(
                    affectedUserIDs,
                    taskOrIssueIDs,
                    notificationDetails,
                    bulkAction
                )
            );
        });
    };
};

export const dispatchFinalActionAfterBulkAction = (
    affectedUserIDs: TUserID[],
    taskOrIssueIDs: TTaskOrIssueID[],
    notificationDetails: TLocalizationSingleProps<any>,
    bulkAction: BulkActions
): TWorkSchedulingThunkAction<void> => {
    return function _dispatchFinalActionAfterBulkAction(dispatch, getState): void {
        const chainedActions: Array<TModernSchedulingAction<any>> = [
            setIntoHighlightingMode(affectedUserIDs, taskOrIssueIDs),
        ];
        if (bulkAction !== BulkActions.ASSIGN_USER) {
            chainedActions.push(toggleSetting(settingNames.isBulkAssignmentPanelOpened, false));
        }

        const isFilterApplied = isFilterAppliedInUnassignedSection(getState());

        if (bulkAction === BulkActions.UN_ASSIGN_USER && isFilterApplied) {
            chainedActions.push(toggleLoading(Sections.UNASSIGNED_WORK, true));
            dispatch(actionChain(chainedActions));
            dispatch(loadDataForUnassignedSection()).then(() => {
                internalEventEmitterSelector(getState()).emit(RECOMPUTE_GRID_SIZE);
                const { messageKey, fallback, args } = notificationDetails;

                addToast('success', getByMessageKeySync(messageKey, fallback, args));
            });
            return;
        }

        dispatch(actionChain(chainedActions));
        internalEventEmitterSelector(getState()).emit(RECOMPUTE_GRID_SIZE);
        const { messageKey, fallback, args } = notificationDetails;

        addToast('success', getByMessageKeySync(messageKey, fallback, args));
    };
};

export const removeUserDataAfterBulkAction = (
    itemToUnassign: IProjectAssignmentAssignedTo,
    _taskOrIssueID: TTaskOrIssueID,
    projectID: TProjectID
) => {
    return function _removeUserDataAfterBulkAction(dispatch, getState): void {
        dispatch(
            actionChain([
                removeAssignedObject(
                    [itemToUnassign.ID],
                    _taskOrIssueID,
                    projectID,
                    projectGroupingModeSelector(getState())
                ),
                removeWorkPerDaysByUsersAssignments([itemToUnassign.ID], _taskOrIssueID),
                removeUserAssignments([itemToUnassign.ID], _taskOrIssueID),
            ])
        );
    };
};
