import _ from 'lodash';
import { TModernSchedulingAction } from '../../types';
import { IDataState, IRequestedSchedulesState, ISchedulesWorkDaysState } from '../IDataState';

export const ActionType = 'ADD_SCHEDULES_WORK_DAYS';

interface IAddSchedulesWorkDaysActionPayload {
    scheduleData: IRequestedSchedulesState;
}

type TAddSchedulesWorkDaysAction = TModernSchedulingAction<IAddSchedulesWorkDaysActionPayload>;

const addSchedulesWorkDays = (
    scheduleData: IRequestedSchedulesState
): TAddSchedulesWorkDaysAction => ({
    type: ActionType,
    payload: {
        scheduleData,
    },
});
export default addSchedulesWorkDays;

export function handle(state: IDataState, { payload }: TAddSchedulesWorkDaysAction): IDataState {
    const schedulesWorkDays: ISchedulesWorkDaysState = { ...state.schedulesWorkDays };
    _.forEach(payload.scheduleData, (scheduleItem, scheduleID) => {
        schedulesWorkDays[scheduleID] = {
            ...scheduleItem.workDays,
        };
    });

    return {
        ...state,
        schedulesWorkDays,
    };
}
