import _ from 'lodash';
import { findLastIndexUtil } from '../../../util/utilities';
import { TModernSchedulingAction } from '../../types';
import { IDataState, IUserState } from '../IDataState';
import { assignedSectionHeaderDataID } from '../../../constants/dataConstatnts';
import { tableDataIDsSelectorWithoutHeaderSelector } from '../selectors/tableDataIDsSelector';

export const ActionType = 'ADD_DATA_IDS';

export type TTableDataAction = TModernSchedulingAction<ITableDataActionPayload>;

interface ITableDataActionPayloadSettings {
    idExpression?: string;
    sliceIndex?: number | null;
    showMore?: boolean;
}

interface ITableDataActionPayload {
    IDs: string[];
    isInProjectGroupingMode: boolean;
    settings: ITableDataActionPayloadSettings;
}

const addTableDataIDs = (
    IDs: string[],
    isInProjectGroupingMode,
    settings = {}
): TTableDataAction => ({
    type: ActionType,
    payload: {
        IDs, // it can be array of idExpressions
        isInProjectGroupingMode,
        settings,
    },
});
export default addTableDataIDs;

function showInaccessible(isInProjectGroupingMode, idExpression, users): boolean {
    const idsArr = idExpression.split('_');
    const user: IUserState = users[idsArr[0]];

    if (!isInProjectGroupingMode) {
        return !!_.find(
            user.nodes,
            (nodeItem) =>
                nodeItem.inaccessibleNodesDates && nodeItem.inaccessibleNodesDates.length > 0
        );
    }
    return idsArr.length === 1
        ? !!user.inaccessibleProjectIDs.length
        : !!(
              user.nodes[idsArr[1]].inaccessibleNodesDates &&
              user.nodes[idsArr[1]].inaccessibleNodesDates.length
          );
}

export function handle(state: IDataState, { payload }: TTableDataAction): IDataState {
    const { IDs, isInProjectGroupingMode, settings } = payload;
    const { idExpression = '', showMore = false, sliceIndex = null } = settings;

    const withoutHeaderDataIDs = tableDataIDsSelectorWithoutHeaderSelector(state);

    const prefix = idExpression ? '_' : '';
    const loadedIDs = IDs.map((dataID) => `${idExpression}${prefix}${dataID}`);
    let slicingIndex;

    if (settings.sliceIndex != null) {
        slicingIndex = sliceIndex;
    } else {
        slicingIndex = idExpression
            ? findLastIndexUtil(withoutHeaderDataIDs, idExpression) + 1
            : withoutHeaderDataIDs.length;
    }

    const showMoreId = `${idExpression}_showMore`;
    if (showMore && withoutHeaderDataIDs.indexOf(showMoreId) === -1) {
        loadedIDs.push(showMoreId);
    }

    const inaccessibleId = `${idExpression}_showInaccessible`;
    if (
        idExpression &&
        !showMore &&
        showInaccessible(isInProjectGroupingMode, idExpression, state.users) &&
        withoutHeaderDataIDs.indexOf(inaccessibleId) === -1
    ) {
        loadedIDs.push(inaccessibleId);
    }
    const newDataIDs = [
        ...withoutHeaderDataIDs.slice(0, slicingIndex),
        ...loadedIDs,
        ...withoutHeaderDataIDs.slice(slicingIndex),
    ];

    return {
        ...state,
        tableDataIDs: assignedSectionHeaderDataID.concat(newDataIDs),
    };
}
