// Custom usePopover hook to manage popover state and
// calculate popover's position based on mouse and assignment positions

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { sizes } from '../constants/schedulingTableConstants';

export interface IPopoverState {
    popperProps: IPoperProps;
    updateCoords: (e: React.MouseEvent<HTMLDivElement>) => void;
    showPopper: () => void;
    hidePopper: (_?: React.MouseEvent<HTMLDivElement>, force?: boolean) => void;
}

interface IPoperProps {
    isPopperOpen: boolean;
    arrowPosRight: boolean;
    referenceElement: PopperVirtualReference;
}

interface IPoperOptions {
    parentContainerSelector: string;
    scrollContainerSelector: string;
    popperSizes: IPopperSizes;
    enterDelay?: number;
    exitDelay?: number;
    disabled: boolean;
}

interface PopperEvtCoords {
    mousePosLeft: number;
    assignmentTopPos: number;
    elemEnd: number;
    elemStart: number;
}

interface IPopperSizes {
    assignmentPopperWidth: number;
    arrowDistance: number;
    paddingFromContainer: number;
}

const popperEvtCoordsInitial = {
    mousePosLeft: 0,
    assignmentTopPos: 0,
    elemEnd: 0,
    elemStart: 0,
};

export class PopperVirtualReference {
    // eslint-disable-next-line no-useless-constructor
    constructor(private left: number, private top: number) {
        // Comment for eslint
    }

    getBoundingClientRect(): any {
        return {
            top: this.top,
            left: this.left,
            bottom: 0,
            right: 0,
            width: 0,
            height: 0,
        };
    }

    get clientWidth(): number {
        return this.getBoundingClientRect().width;
    }

    get clientHeight(): number {
        return this.getBoundingClientRect().height;
    }
}

export const initialPopperProps: IPoperProps = {
    isPopperOpen: false,
    arrowPosRight: false,
    referenceElement: new PopperVirtualReference(0, 0),
};

export const useAssignmentPopover = (popperOptions: IPoperOptions): IPopoverState => {
    const [popperProps, setPopperProps] = useState<IPoperProps>(initialPopperProps);

    const coordsRef = useRef<PopperEvtCoords>(popperEvtCoordsInitial);
    const delayTimeout = useRef<NodeJS.Timeout | null>(null);

    const {
        parentContainerSelector,
        scrollContainerSelector,
        popperSizes,
        enterDelay,
        exitDelay,
        disabled,
    } = popperOptions;

    const { assignmentPopperWidth, paddingFromContainer, arrowDistance } = useMemo(
        () => popperSizes,
        [popperSizes]
    );

    const addDelay = useCallback((callbackFn, delay) => {
        clearTimeout(delayTimeout.current!);
        if (delay) {
            delayTimeout.current = setTimeout(callbackFn, delay);
        } else {
            callbackFn();
        }
    }, []);

    const updateCoords = useCallback((evt: React.MouseEvent<HTMLDivElement>): void => {
        const targetBoundingClient = (evt.currentTarget as HTMLElement).getBoundingClientRect();
        const newCoords = {
            mousePosLeft: evt.clientX,
            assignmentTopPos: targetBoundingClient.y,
            elemEnd: targetBoundingClient.right,
            elemStart: targetBoundingClient.left,
        };
        coordsRef.current = newCoords;
    }, []);

    const showPopper = (): void => {
        addDelay(() => {
            if (!popperProps.isPopperOpen && !disabled) {
                const parentElement = document.querySelector(parentContainerSelector)!;

                const parentBoundingClient = parentElement.getBoundingClientRect();

                const { assignmentTopPos, mousePosLeft, elemEnd, elemStart } = coordsRef.current;

                const leftBound =
                    parentBoundingClient.left + sizes.tableLeftColumnWidth + paddingFromContainer;

                const rightBound = parentBoundingClient.right - paddingFromContainer;

                const popoverEndCoord = mousePosLeft + assignmentPopperWidth;

                const elemEndOverflow = Math.min(elemEnd, rightBound);

                let leftValue;

                let isArrowPosRight = false;

                const assgnLength = elemEnd - elemStart;

                // if doesn't overflow from right side of the task/window, show above mouse
                if (popoverEndCoord < elemEndOverflow) {
                    leftValue = Math.max(leftBound, mousePosLeft - arrowDistance);
                }
                // if assignment is smaller than popper, and there is enough space from right, show from left
                else if (
                    assgnLength < assignmentPopperWidth &&
                    elemStart + assignmentPopperWidth < rightBound
                ) {
                    leftValue = elemStart;
                }
                // if overflows from right and there is enough space from left, show from right
                else if (
                    popoverEndCoord > elemEndOverflow &&
                    elemEndOverflow - assignmentPopperWidth > leftBound
                ) {
                    leftValue = elemEndOverflow - assignmentPopperWidth;
                    isArrowPosRight = true;
                }
                // otherwise show from left
                else {
                    leftValue = Math.max(leftBound, elemStart);
                }

                const correctedLeftPos = leftValue + assignmentPopperWidth;

                setPopperProps({
                    isPopperOpen: true,
                    arrowPosRight: isArrowPosRight,
                    referenceElement: new PopperVirtualReference(
                        correctedLeftPos,
                        assignmentTopPos
                    ),
                });
            }
        }, enterDelay);
    };

    const hidePopper = useCallback(
        (_, force: boolean = false): void => {
            addDelay(
                () => {
                    setPopperProps(initialPopperProps);
                },
                force ? 0 : exitDelay
            );
        },
        [exitDelay, addDelay]
    );

    useEffect(() => {
        if (disabled) {
            hidePopper(null, true);
        }
    }, [disabled, hidePopper]);

    useEffect(() => {
        const forceHide = (): void => {
            hidePopper(null, true);
        };
        const scrollContainers = document
            .querySelector(parentContainerSelector)!
            .querySelectorAll(scrollContainerSelector);

        if (popperProps.isPopperOpen) {
            scrollContainers.forEach((scrollContainer) =>
                scrollContainer.addEventListener('scroll', forceHide)
            );
        }

        return (): void => {
            scrollContainers.forEach((scrollContainer) =>
                scrollContainer.removeEventListener('scroll', forceHide)
            );
        };
    }, [hidePopper, parentContainerSelector, scrollContainerSelector, popperProps.isPopperOpen]);

    return {
        popperProps,
        updateCoords,
        showPopper,
        hidePopper,
    };
};
