import React from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
    UnitOfTime,
} from '../../../data-flow/types';
import { IRoleSummaryLoading, RoleSummaryDataType } from '../../../data-flow/data/IDataState';
import { TableHeaderPeriods } from './TableHeaderPeriods';
import { IPeriods } from '../../../data-flow/dateRange/IDateRangeState';
import { stepUnitSelector } from '../../../data-flow/dateRange/selectors/stepUnitSelector';
import { periodsSelector } from '../../../data-flow/dateRange/selectors/periodsSelector';
import { endDateSelector } from '../../../data-flow/dateRange/selectors/endDateSelector';
import { getSchedulingAreaData } from '../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import {
    roleSummaryDataSelector,
    roleSummaryLoadingSelector,
} from '../../../data-flow/data/selectors/roleSummary/roleSummarySelectors';
import { IAreaState } from '../../../data-flow/areaData/areaRelatedInitialDataState';
import { loadProjectAssignments } from '../../../data-flow/thunks/loadProjectAssignmentsThunk/loadProjectAssignmentsThunk';
import { isEspProductEnabled } from '../../../data-flow/data/selectors/dataSelectors';

interface ITableHeaderUnassignedSectionRightSideStateToProps {
    periods: IPeriods[];
    endDateOfPeriod: Moment;
    schedulingAreaData: IAreaState;
    stepUnit: UnitOfTime;
    roleSummaryLoading: IRoleSummaryLoading;
    roleSummaryData: RoleSummaryDataType;
    espProductEnabled: boolean;
}

export const TableHeaderUnassignedSectionRightSideComponent: React.FunctionComponent<
    ITableHeaderUnassignedSectionRightSideStateToProps & IWorkSchedulingDispatchProp
> = React.memo(
    ({
        periods,
        stepUnit,
        endDateOfPeriod,
        schedulingAreaData,
        roleSummaryLoading,
        roleSummaryData,
        dispatch,
        espProductEnabled,
    }) => {
        const onMonthRoleAllocationOpenHandler = (
            startDate: Moment,
            endDate: Moment,
            roleSummaryMonthKey: string
        ): void => {
            if (schedulingAreaData.schedulingAreaID) {
                dispatch(
                    loadProjectAssignments(
                        schedulingAreaData.schedulingAreaID,
                        startDate,
                        endDate,
                        espProductEnabled,
                        roleSummaryMonthKey
                    )
                );
            }
        };

        return (
            <TableHeaderPeriods
                periods={periods}
                stepUnit={stepUnit}
                endDateOfPeriod={endDateOfPeriod}
                schedulingAreaObjCode={schedulingAreaData.schedulingAreaObjCode}
                onMonthRoleAllocationOpen={onMonthRoleAllocationOpenHandler}
                roleSummaryMonthlyLoading={roleSummaryLoading}
                roleSummaryData={roleSummaryData}
            />
        );
    }
);

const mapStateToProps = (
    state: IWorkSchedulingCombinedState
): ITableHeaderUnassignedSectionRightSideStateToProps => ({
    stepUnit: stepUnitSelector(state),
    periods: periodsSelector(state),
    endDateOfPeriod: endDateSelector(state),
    schedulingAreaData: getSchedulingAreaData(state),
    roleSummaryLoading: roleSummaryLoadingSelector(state),
    roleSummaryData: roleSummaryDataSelector(state),
    espProductEnabled: isEspProductEnabled(state),
});

export const TableHeaderUnassignedSectionRightSide = connect(mapStateToProps)(
    TableHeaderUnassignedSectionRightSideComponent
);
