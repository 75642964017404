import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'CHANGE_PROJECTS_DATA_REQUESTS_STATE';

interface IProjectsDataRequestsStatePayload {
    ID: string;
    isRequestSend: boolean;
    isDataLoaded: boolean;
}

type TChangeRequestSentModeAction = TModernSchedulingAction<IProjectsDataRequestsStatePayload>;

const changeProjectsDataRequestsState = (
    ID,
    isRequestSend,
    isDataLoaded
): TChangeRequestSentModeAction => ({
    type: ActionType,
    payload: {
        ID,
        isRequestSend,
        isDataLoaded,
    },
});
export default changeProjectsDataRequestsState;

export function handle(state: IDataState, { payload }: TChangeRequestSentModeAction): IDataState {
    const users = { ...state.users };
    const selectedUser = users[payload.ID];

    users[payload.ID] = {
        ...selectedUser,
        projectsDataRequestsState: {
            isRequestSend: payload.isRequestSend,
            isDataLoaded: payload.isDataLoaded,
        },
    };

    return {
        ...state,
        users,
    };
}
