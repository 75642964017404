import moment from 'moment';
import { getStorageUtil } from '@workfront/storage';
import { TimeUnit } from '../../constants/periodEnums';
import { TModernSchedulingAction } from '../types';
import { IDateRangeState } from './IDateRangeState';
import { DATE_FORMAT_TIMELINE } from '../../constants/dataConstatnts';

export const ActionType = 'CHANGE_PERIOD_START_DATE';

interface IChangePeriodStartDateActionPayload {
    activePeriodMode: string;
}

type TChangePeriodStartDateActionPayload =
    TModernSchedulingAction<IChangePeriodStartDateActionPayload>;

const changePeriodStartDate = (activePeriodMode): TChangePeriodStartDateActionPayload => ({
    type: ActionType,
    payload: {
        activePeriodMode,
    },
});

export default changePeriodStartDate;

export function handle(
    state: IDateRangeState,
    { payload }: TChangePeriodStartDateActionPayload
): IDateRangeState {
    const { startDate } = state;
    const { activePeriodMode } = payload;
    let newStartDate;

    if (activePeriodMode === TimeUnit.MONTH) {
        const dayInMonth = Math.floor(startDate.clone().daysInMonth()) / 2 - 1;
        const halfOfMonth = startDate.clone().startOf(TimeUnit.MONTH).add(dayInMonth, 'days');
        newStartDate = startDate.isBefore(halfOfMonth)
            ? startDate.clone().startOf(TimeUnit.MONTH)
            : startDate.clone().startOf(TimeUnit.MONTH).add(1, TimeUnit.MONTH);
    } else {
        newStartDate = startDate.clone().startOf(TimeUnit.WEEK);
    }

    getStorageUtil().set(
        'scheduling.timelineStartDate',
        moment(newStartDate).format(DATE_FORMAT_TIMELINE)
    );

    return {
        ...state,
        startDate: newStartDate,
    };
}
