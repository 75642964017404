import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IWorkSchedulingCombinedState, TModernSchedulingAction } from './types';

export const useAppDispatch = (): ThunkDispatch<
    IWorkSchedulingCombinedState,
    any,
    TModernSchedulingAction<any>
> => useDispatch();

export const useAppSelector: TypedUseSelectorHook<IWorkSchedulingCombinedState> = useSelector;
