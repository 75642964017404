import { primary } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import mitt from 'mitt';
import { Moment } from 'moment';
import * as React from 'react';
import styled, { css } from 'react-emotion';
import { connect } from 'react-redux';
import { RECOMPUTE_GRID_SIZE } from '../../../constants/events';
import { Sections, systemScrollbarSize } from '../../../constants/schedulingTableConstants';
import { IAreaState } from '../../../data-flow/areaData/areaRelatedInitialDataState';
import addTableDataIDs from '../../../data-flow/data/assignedDataActions/addTableDataIDs';
import changeUserNodesOffset from '../../../data-flow/data/assignedDataActions/changeOffset';
import setHeightForPeopleWorkloadEmptyRow from '../../../data-flow/data/assignedDataActions/setHeightForPeopleWorkloadEmptyRow';
import removeRow from '../../../data-flow/data/dataLoadingActions/removeRow';
import { IObjectState, IUserState } from '../../../data-flow/data/IDataState';
import addTableDataIDsForUnassignedSection from '../../../data-flow/data/unassignedDataActions/addTableDataIDsForUnassignedSection';
import changeUnassignedProjectOffset from '../../../data-flow/data/unassignedDataActions/changeUnassignedProjectOffset';
import { startDateSelector } from '../../../data-flow/dateRange/selectors/startDateSelector';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import { internalEventEmitterSelector } from '../../../data-flow/instances/internalEventEmitterSelector';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../data-flow/types';
import { getUserNodesIDs } from '../../../util/dataService';
import { selectUserIDFromExpression } from '../../../util/utilities';
import { getUserSelector } from '../../../data-flow/data/selectors/users/usersSelector';
import {
    projectGroupingModeSelector,
    showActualProgressSelector,
} from '../../../data-flow/settings/settingsSelector';
import { peopleWorkLoadHeightSelector } from '../../../data-flow/tableSizes/selectors/tableSizesSelectors';
import { tasksFromAssignmentsSelector } from '../../../data-flow/data/selectors/rereselect/tasksFromAssignmentsSelector/tasksFromAssignmentsSelector';
import { getSchedulingAreaData } from '../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { getUnassignedTasksProjectSelector } from '../../../data-flow/data/selectors/unassignedTasksProjectsSelectors/unassignedTasksProjectsSelectors';

export interface IShowMoreProps {
    idExpression: string;
}

interface IShowMoreState extends React.HTMLAttributes<HTMLDivElement> {
    userData: IUserState;
    projectGroupingMode: boolean;
    showActualProgress: boolean;
    idExpressionLength: number;
    expressionWithoutShowMore: string;
    idExpressionArr: string[];
    sectionType: Sections;
    startDate: Moment;
    unassignedProjectOffset: number;
    unassignedProjectNodes: string[];
    peopleWorkLoadHeight: number;
    internalEventEmitter: mitt.Emitter;
    userTasks: IObjectState;
    schedulingAreaData: IAreaState;
}

interface IShowMoreStyled {
    projectGroupingMode: boolean;
    sectionType: Sections;
}

type TShowMoreComponentProps = IWorkSchedulingDispatchProp & IShowMoreProps & IShowMoreState;

interface IShowMoreComponentState {
    ids: string[];
    idsToLoad: string[];
    newOffset: number;
}

class ShowMoreComponent extends React.Component<TShowMoreComponentProps, IShowMoreComponentState> {
    getIDsAndOffset = () => {
        const {
            idExpressionArr,
            idExpressionLength,
            projectGroupingMode,
            sectionType,
            showActualProgress,
            startDate,
            unassignedProjectNodes,
            unassignedProjectOffset,
            userData,
            userTasks,
            schedulingAreaData,
        } = this.props;
        let ids;
        let offset;

        if (sectionType === Sections.PEOPLE_WORKLOAD) {
            if (projectGroupingMode) {
                if (idExpressionLength === 1) {
                    ids = userData.projectIDs;
                    offset = userData.offset;
                } else {
                    ids = userData.nodes[idExpressionArr[1]].nodes;
                    offset = userData.nodes[idExpressionArr[1]].offset;
                }
            } else {
                const startDateArg = showActualProgress ? startDate : undefined;
                ids = getUserNodesIDs(userData.nodes, schedulingAreaData, userTasks, startDateArg);
                offset = userData.offset;
            }
        } else {
            ids = unassignedProjectNodes;
            offset = unassignedProjectOffset;
        }
        const idsToLoad = ids.slice(offset, ids.length);
        const newOffset = ids.length;

        return { ids, idsToLoad, newOffset };
    };

    loadMoreData = ({ ids, idsToLoad, newOffset }) => {
        const {
            idExpression,
            expressionWithoutShowMore,
            projectGroupingMode,
            sectionType,
            peopleWorkLoadHeight,
            showActualProgress,
        } = this.props;

        // without seTimeout doesn't rerender virtualized table
        if (sectionType === Sections.PEOPLE_WORKLOAD) {
            // setTimeout(() => {
            this.props.dispatch(removeRow(idExpression, Sections.PEOPLE_WORKLOAD)); // remove row showMore
            // });
            this.props.dispatch(
                actionChain([
                    changeUserNodesOffset(expressionWithoutShowMore, newOffset),
                    addTableDataIDs(idsToLoad, this.props.projectGroupingMode, {
                        idExpression: expressionWithoutShowMore,
                        showMore: ids.length > newOffset,
                    }),
                ])
            );
            if (systemScrollbarSize > 0) {
                this.props.dispatch(
                    setHeightForPeopleWorkloadEmptyRow(
                        peopleWorkLoadHeight,
                        showActualProgress,
                        projectGroupingMode
                    )
                );
            }
        } else {
            setTimeout(() => {
                this.props.dispatch(removeRow(idExpression, Sections.UNASSIGNED_WORK)); // remove row showMore
            });

            this.props.dispatch(
                changeUnassignedProjectOffset(expressionWithoutShowMore, newOffset)
            );
            this.props.dispatch(
                addTableDataIDsForUnassignedSection(
                    idsToLoad,
                    expressionWithoutShowMore,
                    ids.length > newOffset
                )
            );
        }
        this.props.internalEventEmitter.emit(RECOMPUTE_GRID_SIZE);
    };

    render() {
        const idsAndOffset = this.getIDsAndOffset();
        let showMoreMessage;

        const projectGroupingMode =
            this.props.projectGroupingMode && this.props.idExpressionArr.length === 1;
        if (projectGroupingMode && this.props.sectionType === Sections.PEOPLE_WORKLOAD) {
            showMoreMessage = (
                <Localization<string>
                    messageKey="resourcescheduling.show.more.projects.lowercase"
                    fallback="Show {0} more projects"
                    args={[idsAndOffset.idsToLoad.length]}
                />
            );
        } else {
            showMoreMessage = (
                <Localization
                    messageKey="resourcescheduling.show.more.lowercase"
                    fallback="Show {0} more"
                    args={[idsAndOffset.idsToLoad.length]}
                />
            );
        }

        return (
            <ShowMoreStyled
                projectGroupingMode={this.props.projectGroupingMode}
                onClick={() => this.loadMoreData(idsAndOffset)}
                data-testid="left-show-more"
                sectionType={this.props.sectionType}
                className="showMoreCell"
            >
                <span className={showMoreButtonStyle}>{showMoreMessage}</span>
            </ShowMoreStyled>
        );
    }
}
// TODO AREGA separate unassigned and assigned work functionality and html
function mapStateToProps(state: IWorkSchedulingCombinedState, ownProps) {
    const projectGroupingMode = projectGroupingModeSelector(state);

    if (ownProps.sectionType === Sections.UNASSIGNED_WORK) {
        const projectID = ownProps.idExpression.split('_')[0];
        const unassignedProject = getUnassignedTasksProjectSelector(state, { projectID });

        return {
            unassignedProjectOffset: unassignedProject.details.offset,
            unassignedProjectNodes: unassignedProject.nodes,
            projectGroupingMode,
        };
    }
    const userData: any = getUserSelector(state)(selectUserIDFromExpression(ownProps.idExpression)); // TODO correct this any to IUserState

    return {
        userData,
        projectGroupingMode,
        showActualProgress: showActualProgressSelector(state),
        peopleWorkLoadHeight: peopleWorkLoadHeightSelector(state),
        internalEventEmitter: internalEventEmitterSelector(state),
        startDate: startDateSelector(state),
        schedulingAreaData: getSchedulingAreaData(state),
        userTasks: tasksFromAssignmentsSelector(state, { userID: userData.ID }),
    };
}
// @ts-expect-error Remove expect-error, once the unassigned and assigned work functionality and html are separated
export const ShowMore = connect(mapStateToProps)(ShowMoreComponent);

const ShowMoreStyled = styled('div')<IShowMoreStyled>`
    height: 100%;
    line-height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${primary.gray(200)};
    padding-left: ${(props) => {
        if (props.sectionType === Sections.PEOPLE_WORKLOAD && props.projectGroupingMode) {
            return 44;
        }
        return props.sectionType === Sections.PEOPLE_WORKLOAD ? 36 : 32;
    }}px;
    background-color: ${(props) =>
        props.sectionType === Sections.PEOPLE_WORKLOAD ? primary.gray(50) : primary.white()};
`;

const showMoreButtonStyle = css`
    line-height: 34px;
    color: #0078d7;
    font-size: 14px;
    cursor: pointer;
`;
