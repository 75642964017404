import * as React from 'react';
import { connect } from 'react-redux';
import {
    ACCESSIBLE_PROJECT_PRIMARY_DARK_BACKGROUND_COLOR,
    ACCESSIBLE_PROJECT_PRIMARY_LIGHT_BACKGROUND_COLOR,
    ACCESSIBLE_PROJECT_PRIMARY_TEXT_COLOR,
    INACCESSIBLE_DURATION_COLOR,
    INACCESSIBLE_GAPS_COLOR,
    INACCESSIBLE_TEXT_COLOR,
} from '../../../../constants/colors';
import {
    IFullTimeOffSteps,
    IProjectColor,
    IStartEndDates,
    IWorkPerDayHours,
    TOrUndefined,
    TWorkPerDays,
} from '../../../../data-flow/data/IDataState';
import { IWorkSchedulingCombinedState } from '../../../../data-flow/types';
import {
    afterInaccessibleProjectRedesign,
    afterProject,
    beforeInaccessibleProjectRedesign,
    beforeProject,
} from '../../../styles/sharedStyles';
import { AssignmentDuration } from '../AssignmentDuration';
import { Sections } from '../../../../constants/schedulingTableConstants';
import {
    showActualProgressSelector,
    showAllocationsSelector,
} from '../../../../data-flow/settings/settingsSelector';
import { projectDurationSelector } from '../../../../data-flow/data/selectors/durations/durationsSelector';
import { getProjectSelector } from '../../../../data-flow/data/selectors/reselect/getProjectSelector/getProjectSelector';
import { workPerDaysSelectorForPeriodFromService } from '../../../../data-flow/data/selectors/rereselect/workPerDaysSelectorForPeriodFromService';
import { projectColorDeciderSelector } from '../../../../data-flow/data/selectors/projectStatusSelectors/projectStatusSelectors';

interface IProjectAssignmentProps {
    idExpression: string;
    isAssigned: boolean;
    fullTimeOffsStepsByDay: IFullTimeOffSteps;
    userScheduleID: string;
    sectionType: Sections;
    userID: string;
}

interface IProjectAssignmentMapStateToProps {
    durations: IStartEndDates[];
    name: string;
    isAccessible?: boolean;
    projectColoredData?: IProjectColor;
    isCompleted: boolean;
    workPerDays: number[] | undefined;
    crossedOutHoursForPeriod: number[] | undefined;
    showActualProgress: boolean;
    projectedDurations: IStartEndDates[];
}

export const ProjectAssignmentForPeopleWorkloadSectionComponent: React.FunctionComponent<
    IProjectAssignmentProps & IProjectAssignmentMapStateToProps
> = (props) => {
    let colorDark;
    let colorLight;
    let textColor;
    let leftArrowCssClass;
    let rightArrowCssClass;
    const { isAccessible, projectColoredData } = props;
    if (isAccessible) {
        colorDark = ACCESSIBLE_PROJECT_PRIMARY_DARK_BACKGROUND_COLOR;
        colorLight = ACCESSIBLE_PROJECT_PRIMARY_LIGHT_BACKGROUND_COLOR;
        textColor = projectColoredData?.textColor
            ? projectColoredData.textColor
            : ACCESSIBLE_PROJECT_PRIMARY_TEXT_COLOR;
        leftArrowCssClass = beforeProject(projectColoredData?.RGB);
        rightArrowCssClass = afterProject(projectColoredData?.RGB);
    } else {
        colorDark = INACCESSIBLE_DURATION_COLOR;
        colorLight = INACCESSIBLE_GAPS_COLOR;
        textColor = INACCESSIBLE_TEXT_COLOR;
        leftArrowCssClass = beforeInaccessibleProjectRedesign;
        rightArrowCssClass = afterInaccessibleProjectRedesign;
    }

    const { idExpression, ...rest } = props;
    const IDs = idExpression.split('_');

    return (
        <AssignmentDuration
            idExpression={IDs[1]}
            isProject
            isAccessible={props.isAccessible}
            colorDark={colorDark}
            colorLight={colorLight}
            textColor={textColor}
            rightArrowCssClass={rightArrowCssClass}
            leftArrowCssClass={leftArrowCssClass}
            {...rest}
            userID={IDs[0]}
        />
    );
};

function mapStateToProps(
    state: IWorkSchedulingCombinedState,
    ownProps
): IProjectAssignmentMapStateToProps {
    const idsArray = ownProps.idExpression.split('_');
    const nodeData = getProjectSelector(state)(idsArray[1]);
    const isShowAllocationOn = showAllocationsSelector(state);

    let workPerDays: TOrUndefined<TWorkPerDays>;
    let crossedOutHoursForPeriod;
    let workPerDaysWithCrossedOutHours: IWorkPerDayHours;

    if (isShowAllocationOn) {
        const data = {
            projectID: idsArray[1],
            userID: idsArray[0],
        };
        workPerDaysWithCrossedOutHours = workPerDaysSelectorForPeriodFromService(state, data);

        crossedOutHoursForPeriod = workPerDaysWithCrossedOutHours.crossedOutHoursForPeriod;
        workPerDays = workPerDaysWithCrossedOutHours.workPerDaysForPeriod;
    }
    const { plannedDurations, projectedDurations, isCompleted } = projectDurationSelector(state, {
        userID: idsArray[0],
        projectID: idsArray[1],
    });

    const projectColoredData = projectColorDeciderSelector(
        state,
        nodeData.ID,
        nodeData.groupID,
        nodeData.status
    );

    return {
        durations: plannedDurations,
        name: nodeData.name,
        isAccessible: nodeData.isAccessible,
        projectColoredData,
        isCompleted,
        workPerDays,
        crossedOutHoursForPeriod,
        showActualProgress: showActualProgressSelector(state),
        projectedDurations,
    };
}

export const ProjectAssignment = connect(mapStateToProps)(
    ProjectAssignmentForPeopleWorkloadSectionComponent
);
