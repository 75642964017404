import React, { ReactElement, useEffect, useRef } from 'react';

interface ISectionsResizeHelperProps {
    onTableMounted: (top: number) => void;
    onWindowResize: (top: number, e: any) => void;
}

export const SectionsResizeHelper = (props: ISectionsResizeHelperProps): ReactElement => {
    const divRef = useRef<HTMLDivElement>(null);
    const { onTableMounted, onWindowResize } = props;
    const resizeHandler = React.useCallback(
        (e) => onWindowResize(divRef.current!.getBoundingClientRect().top, e),
        [onWindowResize]
    );
    // useEffect calls once and doesn't compute top properly
    useEffect(() => {
        onTableMounted(divRef.current!.getBoundingClientRect().top);
    }, [onTableMounted]);

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [resizeHandler]);

    return <div ref={divRef} />;
};
