import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'ADD_USER_PROJECT_ID';

interface IAddUserProjectIDPayload {
    userID: string;
    projectID: string;
    sliceIndex: number | null;
}

type TAddUserProjectIDAction = TModernSchedulingAction<IAddUserProjectIDPayload>;

const addUserProjectID = (
    userID,
    projectID,
    sliceIndex: number | null = null
): TAddUserProjectIDAction => ({
    type: ActionType,
    payload: {
        userID,
        projectID,
        sliceIndex,
    },
});
export default addUserProjectID;

export function handle(state: IDataState, { payload }: TAddUserProjectIDAction): IDataState {
    const users = { ...state.users };
    const user = { ...users[payload.userID] };

    if (payload.sliceIndex !== null) {
        user.projectIDs = [
            ...user.projectIDs.slice(0, payload.sliceIndex),
            payload.projectID,
            ...user.projectIDs.slice(payload.sliceIndex),
        ];
    } else {
        user.projectIDs = [...user.projectIDs, payload.projectID];
    }

    users[payload.userID] = user;

    return {
        ...state,
        users,
    };
}
