import { IDataState, TDraggingFromArea } from '../../IDataState';
import { TModernSchedulingAction } from '../../../types';

export const ActionType = 'SET_DRAGGING_FROM_AREA';

interface ISetDraggingFromAreaPayload {
    draggingFromArea?: TDraggingFromArea;
}

type TSetDraggingFromAreaAction = TModernSchedulingAction<ISetDraggingFromAreaPayload>;

const setDraggingFromArea = (draggingFromArea?: TDraggingFromArea): TSetDraggingFromAreaAction => ({
    type: ActionType,
    payload: {
        draggingFromArea,
    },
});

export function handle(state: IDataState, { payload }: TSetDraggingFromAreaAction): IDataState {
    const { draggingFromArea = null } = payload;

    return {
        ...state,
        draggingFromArea,
    };
}

export default setDraggingFromArea;
