import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { workPerDaysByAssignmentsFromServiceSelector } from '../workPerDaysByAssignmentsFromServiceSelector';
import { stepsSelector } from '../../../dateRange/selectors/stepsSelector';
import { stepUnitSelector } from '../../../dateRange/selectors/stepUnitSelector';
import { unassignedWorkFilterExpressionSelector } from '../../../filters/selectors/filterIDSelector';
import { getRoleIdsForUnassignedSection } from '../../../../util/dataService';
import { getWorkPerDayForPeriodFromService } from '../../../../util/getWorkPerDayForPeriod';
import { recalculateDataForActiveView } from '../../../../util/utilities';
import { unassignedTasksSelector } from '../dataSelectors';

export const workPerDaysSelectorForPeriodUnassignedSectionService = createCachedSelector(
    [
        workPerDaysByAssignmentsFromServiceSelector,
        unassignedTasksSelector,
        stepsSelector,
        stepUnitSelector,
        unassignedWorkFilterExpressionSelector,
        (state, obj) => obj.taskID,
        (state, obj) => obj.projectID,
    ],
    (workPerDaysByAssignments, tasks, steps, stepUnit, filterExpression, taskID, projectID) => {
        const stepEndDate = steps[steps.length - 1].clone().endOf(stepUnit);
        let workPerDaysForPeriod: number[] = [];

        let taskIDs: string[] = [];
        if (taskID) {
            taskIDs = [taskID];
        } else if (projectID) {
            taskIDs = Object.keys(tasks).filter(
                (taskItemID) => tasks[taskItemID].projectID === projectID
            );
        } else {
            return [];
        }

        let roleAssignments: string[] = [];
        if (filterExpression) {
            roleAssignments = getRoleIdsForUnassignedSection(filterExpression);
        }

        const workPerDays = {
            workPerDaysForPeriod,
            temporaryWorkPerDays: {},
        };

        taskIDs.forEach((taskItemID) => {
            const task = tasks[taskItemID];
            const { assignments } = task;
            if (assignments?.length) {
                _.forEach(assignments, (assignment) => {
                    const stepStartDate = steps[0].clone().startOf(stepUnit);
                    getWorkPerDayForPeriodFromService(
                        stepStartDate,
                        stepEndDate,
                        { ...workPerDays, workPerDaysByAssignments },
                        assignment.ID,
                        {},
                        assignment.roleID,
                        roleAssignments
                    );
                });
            } else {
                const stepStartDate = steps[0].clone().startOf(stepUnit);
                getWorkPerDayForPeriodFromService(
                    stepStartDate,
                    stepEndDate,
                    { ...workPerDays, workPerDaysByAssignments: task.workPerDayListFromService },
                    null,
                    {}
                );
            }
        });

        workPerDaysForPeriod = recalculateDataForActiveView(
            workPerDaysForPeriod,
            stepUnit,
            steps[0]
        );
        return workPerDaysForPeriod;
    }
)((state, { taskID, projectID }) => taskID || projectID);
