import { Moment } from 'moment';
import { forEach } from 'lodash';
import { Assignment as assignmentObjCode } from 'workfront-objcodes';
import { TWorkSchedulingThunkAction } from '../../types';
import setRoleSummaryLoading from '../../data/assignedDataActions/roleSummary/setRoleSummaryLoading';
import { loadProjectAssignmentsFilter } from '../../../util/filters/filterUtil';
import { APIService } from '../../../services/api-services/apiService';
import {
    IWorkRequiredRoleSummaryData,
    ILoadAssignmentsByProjectResponse,
    LoadWorkPerDayListByAssignmentFromWPDServiceForProjectResponse,
    RoleSummaryKeys,
} from '../../data/IDataState';
import setRoleSummary from '../../data/assignedDataActions/roleSummary/setRoleSummary';
import {
    arraySum,
    convertMinuteToHour,
    getESPFormatQueryDate,
    getRoleSummaryMonthKey,
    sortByTitleCallback,
} from '../../../util/utilities';
import { RoleSummaryHourKey } from '../../../constants/RoleSummaryHourKey';

export const loadProjectAssignments = (
    projectID: string,
    startDate: Moment,
    endDate: Moment,
    espProductEnabled: boolean,
    roleSummaryMonthKey?: string
): TWorkSchedulingThunkAction<Promise<void>> => {
    return function _loadProjectAssignments(dispatch) {
        const monthKey = roleSummaryMonthKey || RoleSummaryKeys.MONTHLY;
        dispatch(setRoleSummaryLoading(true, monthKey));
        const queryFilters = loadProjectAssignmentsFilter(projectID, startDate, endDate);
        const dateForESPFormat = getESPFormatQueryDate(startDate);

        const preloadPromises = [APIService.loadAssignmentsByProject(queryFilters, ['role:name'])];

        if (espProductEnabled) {
            preloadPromises.push(
                APIService.loadRoleHoursForMonth(projectID, dateForESPFormat, dateForESPFormat)
            );
        }

        return Promise.all(preloadPromises).then((values: any[]) => {
            const assignmentList: ILoadAssignmentsByProjectResponse[][] = values[0];
            const assignments = [...assignmentList[0], ...assignmentList[1]];
            const assignmentIDs: string[] = [];
            const assignmentsById = {};
            assignments.forEach((assignment) => {
                assignmentIDs.push(assignment.ID);
                assignmentsById[assignment.ID] = assignment;
            });

            const WPDCallPromise: Promise<LoadWorkPerDayListByAssignmentFromWPDServiceForProjectResponse> =
                assignmentIDs.length
                    ? APIService.loadWorkPerDayListByAssignmentFromWPDServiceForProject(
                          assignmentIDs,
                          { startDate, endDate },
                          assignmentObjCode
                      )
                    : Promise.resolve(
                          [] as unknown as LoadWorkPerDayListByAssignmentFromWPDServiceForProjectResponse
                      );

            return WPDCallPromise.then(
                (
                    allocationsPerAssignment: LoadWorkPerDayListByAssignmentFromWPDServiceForProjectResponse
                ) => {
                    const roleIDSWithAllocationSummary: IESPHoursPerRole[] = espProductEnabled
                        ? values[1]
                        : [];

                    const roleSummaryForPeriod = prepareSumByRole(
                        allocationsPerAssignment,
                        assignmentsById,
                        dateForESPFormat,
                        espProductEnabled,
                        roleIDSWithAllocationSummary
                    );

                    dispatch(setRoleSummaryLoading(false, monthKey));
                    dispatch(
                        setRoleSummary(roleSummaryForPeriod, getRoleSummaryMonthKey(startDate))
                    );
                }
            );
        });
    };
};

export interface IESPHoursPerRole {
    id: string;
    name: string;
    hoursDistribution: Record<string, number>;
}

const prepareSumByRole = (
    allocationsPerAssignment: LoadWorkPerDayListByAssignmentFromWPDServiceForProjectResponse,
    assignmentsById: Record<string, ILoadAssignmentsByProjectResponse>,
    dateForESPFormat: string,
    espProductEnabled: boolean,
    initiativeHoursPerRole: IESPHoursPerRole[] = []
): IWorkRequiredRoleSummaryData[] => {
    const sumByRole: Record<string, IWorkRequiredRoleSummaryData> = {};

    forEach(allocationsPerAssignment, (allocationPerAssignment, assignmentID) => {
        const assignment = assignmentsById[assignmentID];
        if (assignment && assignment.role) {
            const dailyAllocations = Object.values(allocationPerAssignment);
            const roleID = assignment.role.ID;
            sumByRole[roleID] = {
                roleID,
                roleTitle: assignment.role.name,
                [RoleSummaryHourKey.PLANNED_HOUR]: convertMinuteToHour(arraySum(dailyAllocations)),
            };

            if (espProductEnabled) {
                sumByRole[roleID][RoleSummaryHourKey.APPROVED_HOUR] = undefined;
            }
        }
    });

    initiativeHoursPerRole.forEach((initiativeHourPerRole) => {
        if (initiativeHourPerRole.hoursDistribution[dateForESPFormat]) {
            const hour = initiativeHourPerRole.hoursDistribution[dateForESPFormat];
            if (sumByRole[initiativeHourPerRole.id]) {
                sumByRole[initiativeHourPerRole.id][RoleSummaryHourKey.APPROVED_HOUR] = hour;
            } else {
                sumByRole[initiativeHourPerRole.id] = {
                    roleID: initiativeHourPerRole.id,
                    roleTitle: initiativeHourPerRole.name,
                    [RoleSummaryHourKey.APPROVED_HOUR]: hour,
                };
            }
        }
    });

    return Object.values(sumByRole).sort(sortByTitleCallback);
};
