import { createSelector } from 'reselect';
import { IDateRangeState } from '../IDateRangeState';
import { endDateSelector } from './endDateSelector';
import { startDateSelector } from './startDateSelector';
import { TimeUnit } from '../../../constants/periodEnums';

export const monthsWeeksSelector = createSelector(
    [startDateSelector, endDateSelector],
    (startDate, endDate) => {
        const monthsWeeks: IDateRangeState['steps'] = [];

        while (startDate <= endDate) {
            monthsWeeks.push(startDate);
            startDate = startDate.clone().add(1, TimeUnit.WEEK);
        }
        return monthsWeeks;
    }
);
