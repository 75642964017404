import moment, { Moment } from 'moment';
import styled, { css } from 'react-emotion';
import { connect } from 'react-redux';
import React from 'react';
import { TimeUnit } from '../../../constants/periodEnums';
import { stepsSelector } from '../../../data-flow/dateRange/selectors/stepsSelector';
import { stepUnitSelector } from '../../../data-flow/dateRange/selectors/stepUnitSelector';
import { isSmallModeSelector } from '../../../data-flow/tableSizes/selectors/isSmallModeSelector';
import { IWorkSchedulingCombinedState, UnitOfTime } from '../../../data-flow/types';
import { weekMessageKeys, weekMessageKeysFallBacks } from '../../../messageKeysForDate';
import { getDateMessageKey } from '../../../util/utilities';
import { WeekModeContent } from './WeekModeContent';
import { TableHeaderStepCell } from './TableHeaderStepCell';
import { TableHeaderRightWrapperStyled } from './TableHeaderStyles';
import { MonthModeContent } from './MonthModeContent';
import { monthsWeeksSelector } from '../../../data-flow/dateRange/selectors/monthsWeeksSelector';
import { checkIsHalfWeeksForMonthView } from '../../../util/periodUtils/periodUtils';
import { startDateSelector } from '../../../data-flow/dateRange/selectors/startDateSelector';
import { endDateSelector } from '../../../data-flow/dateRange/selectors/endDateSelector';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { ICellStyle } from '../schedulingTables/SchedulingTableAssigned';

interface ITableHeaderStepsProps {
    style?: ICellStyle;
}

interface IWeekModeContentStateProps {
    steps: Moment[];
    stepUnit: UnitOfTime;
    isSmallMode: boolean;
    monthsWeeks: Moment[];
    startDate: Moment;
    endDate: Moment;
}

type TTableHeaderStepsProps = ITableHeaderStepsProps & IWeekModeContentStateProps;

const makeCentered = css`
    text-align: center;
`;

const dayModeContentCss = (isSmallMode: boolean): string => {
    return isSmallMode
        ? css`
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              font-size: 12px;
              flex-direction: column-reverse;
              span:first-child {
                  line-height: 12px;
              }
          `
        : '';
};

const DayModeContent = styled('div')<{ isSmallMode: boolean }>`
    margin-top: -1px;
    ${(props) => dayModeContentCss(props.isSmallMode)}
`;

export const TableHeaderStepsDisconnect: React.FunctionComponent<TTableHeaderStepsProps> =
    React.memo(function TableHeaderStepsDisconnect(props) {
        const { isSmallMode, startDate, endDate, stepUnit, monthsWeeks, steps, style } = props;

        const getContent = (step): JSX.Element => {
            const messageKeyIndex = step.clone().subtract(1, 'd').day();

            if (stepUnit === TimeUnit.DAY) {
                const day = step.format('DD');
                const weekMsgKey = getDateMessageKey(weekMessageKeys, messageKeyIndex);
                const weekMessage = localizationClient.getTextSync(
                    weekMsgKey,
                    getDateMessageKey(weekMessageKeysFallBacks, messageKeyIndex)
                );
                return (
                    <DayModeContent
                        tabIndex={0}
                        className={makeCentered}
                        id={`${day}-${weekMsgKey}`}
                        isSmallMode={isSmallMode}
                        aria-label={`${weekMessage} ${day}`}
                    >
                        <span>{weekMessage}</span>
                        <span> {day}</span>
                    </DayModeContent>
                );
            }
            if (stepUnit === TimeUnit.WEEK) {
                return <WeekModeContent step={step} isSmallMode={isSmallMode} />;
            }
            return <MonthModeContent step={step} isSmallMode={isSmallMode} />;
        };

        const headerSteps = stepUnit === TimeUnit.MONTH ? monthsWeeks : steps;
        const isHalfStartEndWeeks = checkIsHalfWeeksForMonthView(startDate, endDate, stepUnit);
        return (
            <TableHeaderRightWrapperStyled
                role="row"
                data-testid="grid-header-steps"
                isHalfStartEndWeeks={isHalfStartEndWeeks}
                style={style}
            >
                {headerSteps.map((step) => {
                    const isToday = step.format('LL') === moment().format('LL');
                    return (
                        <TableHeaderStepCell
                            role="cell"
                            key={step.unix()}
                            isToday={isToday}
                            isHalfEndWeek={isHalfStartEndWeeks.isHalfEndWeek}
                            stepUnit={stepUnit}
                        >
                            {getContent(step)}
                        </TableHeaderStepCell>
                    );
                })}
            </TableHeaderRightWrapperStyled>
        );
    });

const EMPTY_ARRAY = [];

const mapStateToProps = (state: IWorkSchedulingCombinedState): IWeekModeContentStateProps => {
    const stepUnit = stepUnitSelector(state);
    let monthsWeeks: Moment[] = EMPTY_ARRAY;
    if (stepUnit === TimeUnit.MONTH) {
        monthsWeeks = monthsWeeksSelector(state);
    }

    return {
        endDate: endDateSelector(state),
        isSmallMode: isSmallModeSelector(state),
        steps: stepsSelector(state),
        stepUnit,
        startDate: startDateSelector(state),
        monthsWeeks,
    };
};

export const TableHeaderSteps = connect(mapStateToProps)(TableHeaderStepsDisconnect);
