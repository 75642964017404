import styled, { css } from 'react-emotion';

export const inlineItems = `
	display: flex;
`;

export const inlineItemsCentered = css`
    ${inlineItems};
    align-items: center;
    position: relative;
`;

export const hourSharedStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    position: relative;
`;

export const hourChildSharedStyles = css`
    opacity: 0.8;
    font-size: 14px;
    display: block;
    z-index: 1;
    position: relative;
    line-height: 14px;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    padding: 0 1px;
`;

export const hourChildStyle = (
    isHalfTimeOff?: boolean,
    styles?: IStyles,
    isInAssignmentProcess?: boolean
): string => css`
    ${hourChildSharedStyles};
    color: ${styles ? styles.textColor : ''};
    padding-top: ${isHalfTimeOff ? '14px' : '0px'};
    opacity: ${isInAssignmentProcess && 0.6};
`;

export const HourChild = styled('div')<IHourChild>`
    ${hourChildSharedStyles};
    color: ${(props) => (props.styles ? props.styles.textColor : '')};
    padding-top: ${(props) => (props.isHalfTimeOff ? '14px' : '0px')};
    opacity: ${(props) => props.isInAssignmentProcess && 0.6};
`;

export interface IStyles {
    borderTop?: string;
    backgroundColor?: string;
    textColor?: string;
    isFullTimeOff?: boolean;
}

interface IHourChild {
    isHalfTimeOff?: boolean;
    styles?: IStyles;
    isInAssignmentProcess?: boolean;
}
