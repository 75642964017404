import { toast } from '@phoenix/all';
import { TWorkSchedulingThunkAction } from '../../types';
import {
    checkIsRoleApplicable,
    isUserAlreadyAssigned,
    updateRoleAssignments,
} from '../../../util/dragAndDropUtilities';
import actionChain from '../../higher-order-reducers/actionChain';
import { setIntoAssignmentModeActions } from '../../data/assignedDataActions/commonActionGroups/unassignActions';
import setUserIntoAssignmentMode from '../../data/assignedDataActions/setUserIntoAssignmentMode';
import setDraggingFromArea from '../../data/assignedDataActions/dragAndDrop/setDraggingFromArea';
import { IAssignmentsDetails, TUserID } from '../../data/IDataState';
import { restructureAssignmentDetails } from '../../../util/actionsComponentService';
import { assignUserAfterDropping } from '../changeAssignmentsThunk';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { IDraggableAssignment } from '../../../dragAndDrop/dragAndDropConfigurations';
import {
    userAssignmentsSelector,
    userRoleIdSelector,
    userRoleIdsSelector,
} from '../../data/selectors/users/usersSelector';
import { TShowProjectAndOrTasks } from '../../IGeneralStateTypes';
import { IAssignmentData } from '../../../util/dataStructures/IAssignmentIds';
import { Sections } from '../../../constants/schedulingTableConstants';
import {
    tasksAndIssuesSelector,
    unassignedTasksSelector,
} from '../../data/selectors/dataSelectors';

export const handleOnDropThunk = (
    item: IDraggableAssignment,
    userID: TUserID,
    showProjectAndOrTasks: TShowProjectAndOrTasks
): TWorkSchedulingThunkAction<any> => {
    return function _handleOnDropThunk(dispatch, getState) {
        const { ID, draggedFromUserID, objCode, sectionType } = item;
        const state = getState();
        const userAssignmentsList = userAssignmentsSelector(state, { userID });
        const userAssignments = Object.values(userAssignmentsList);
        const userRolesIDs = userRoleIdsSelector(state, { userID });
        const userRoleID = userRoleIdSelector(state, { userID });
        const projectID =
            sectionType === Sections.PEOPLE_WORKLOAD
                ? tasksAndIssuesSelector(state)[ID].projectID
                : unassignedTasksSelector(state)[ID].projectID;

        if (
            draggedFromUserID === userID ||
            isUserAlreadyAssigned(Object.values(userAssignments), ID)
        ) {
            dispatch(actionChain(setIntoAssignmentModeActions(ID)));
            return;
        }
        const users = [userID, draggedFromUserID];

        dispatch(actionChain([setUserIntoAssignmentMode(users, true), setDraggingFromArea()]));
        return item.loadTaskAssignments.then((assignmentsDetails: IAssignmentsDetails[] | null) => {
            if (assignmentsDetails) {
                const assignmentDetails = restructureAssignmentDetails(assignmentsDetails);
                let assignmentRoleIDs = assignmentDetails.roleIDs;
                let replacedRoles = {};
                const isRoleApplicable = checkIsRoleApplicable(assignmentRoleIDs, userRolesIDs);
                if (isRoleApplicable) {
                    [assignmentRoleIDs, replacedRoles] = updateRoleAssignments(
                        assignmentRoleIDs,
                        userRolesIDs,
                        userRoleID,
                        userID
                    );
                }
                const changedAssignmentData = {
                    ...assignmentDetails,
                    userIDs: [
                        ...assignmentDetails.userIDs.filter((user) => user !== draggedFromUserID),
                        userID,
                    ],
                    roleIDs: assignmentRoleIDs,
                };

                const assignmentData: IAssignmentData = {
                    USER: changedAssignmentData.userIDs,
                    ROLE: changedAssignmentData.roleIDs,
                    TEAMOB: changedAssignmentData.teamID ? [changedAssignmentData.teamID] : [],
                    replacedRoles,
                    usersRemovedFromRole: {},
                };
                const assignmentFormData = {
                    assignments: JSON.stringify(assignmentData),
                    projectId: projectID,
                };

                return dispatch(
                    assignUserAfterDropping(
                        changedAssignmentData,
                        assignmentFormData,
                        ID,
                        userID,
                        objCode,
                        assignmentDetails,
                        showProjectAndOrTasks
                    )
                ).then(() => {
                    toast.success(
                        localizationClient.getTextSync(
                            'resourcescheduling.assignment.success.notification',
                            'You successfully assigned the task'
                        )
                    );
                });
            }
            dispatch(
                actionChain([
                    setUserIntoAssignmentMode(users, false),
                    ...setIntoAssignmentModeActions(ID),
                ])
            );
        });
    };
};
