import { Project as projectObjCode } from 'workfront-objcodes';
import { TModernSchedulingAction } from '../../types';
import { IDataState, IRequestedProjectData } from '../IDataState';

export const ActionType = 'ADD_PROJECTS_DETAILS';

interface IAddProjectsActionDetailsPayload {
    projectsData: IRequestedProjectData[];
}

type TSecondLevelNodes = TModernSchedulingAction<IAddProjectsActionDetailsPayload>;

const addProjectsDetails = (projectsData: IRequestedProjectData[]): TSecondLevelNodes => ({
    type: ActionType,
    payload: {
        projectsData,
    },
});
export default addProjectsDetails;

export function handle(state: IDataState, { payload }: TSecondLevelNodes): IDataState {
    const projects = { ...state.projects };

    payload.projectsData.forEach((projectData) => {
        if (!projects[projectData.ID]) {
            projects[projectData.ID] = {
                ID: projectData.ID,
                name: projectData.name,
                objCode: projectObjCode,
                isAccessible: projectData.isAccessible,
                status: projectData.status,
                groupID: projectData.groupID,
            };
        }
    });

    return {
        ...state,
        projects,
    };
}
