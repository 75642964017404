import { Ellipse, primary } from '@phoenix/all';
import { cx } from 'emotion';
import * as React from 'react';
import { Project } from 'workfront-objcodes';
import { convertMinuteToHour, formatNumber, getPercentToShow } from '../../../util/utilities';
import {
    allocationHourCss,
    allocationHourStyle,
    bracketHourCss,
    crossedOutCss,
    setTextColorCss,
    weekEndDayCss,
} from '../../styles/allocationHourComponentStyles';

interface IAllocationHourProps {
    hour: number;
    cellWidth: number;
    isThereBeforeArrow: boolean;
    isThereAfterArrow: boolean;
    height: number;
    isTimeOff: boolean;
    isSinglePeriodTask: boolean;
    showCrossedOutStyle: boolean | null;
    crossedOutHour: number;
    isWeekView: boolean;
    objCode: string;
    showAllocationsInHoursMode: boolean;
    availableHour: number;
    scheduleHour: number;
    textColor?: string;
    isFromAssignmentBar: boolean;
    isWorkingDay: boolean;
}

export const AllocationHour: React.FunctionComponent<IAllocationHourProps> = (props) => {
    const {
        cellWidth,
        isThereBeforeArrow,
        isThereAfterArrow,
        height,
        isTimeOff,
        isSinglePeriodTask,
        showCrossedOutStyle,
        crossedOutHour,
        isWeekView,
        objCode,
        showAllocationsInHoursMode,
        textColor,
        isFromAssignmentBar,
        isWorkingDay,
    } = props;
    const hour = formatNumber(convertMinuteToHour(props.hour));
    const crossedOutHourWeekView = formatNumber(convertMinuteToHour(crossedOutHour));
    const showCrossedOutHoursInWeekView =
        isWeekView && Number(crossedOutHourWeekView) > 0 && objCode !== Project;
    const text = showAllocationsInHoursMode
        ? hour
        : getPercentToShow(props.availableHour, props.hour, props.scheduleHour);
    const allocationTextColor = setTextColorCss(
        isFromAssignmentBar ? textColor : primary.gray(700)
    );
    const crossedOutStyles = crossedOutCss(isFromAssignmentBar ? textColor : primary.gray(700));
    return (
        <div
            className={allocationHourStyle(
                cellWidth,
                isThereBeforeArrow,
                isThereAfterArrow,
                height,
                isSinglePeriodTask,
                isFromAssignmentBar,
                isTimeOff && Number(hour) === 0
            )}
        >
            <Ellipse
                testID="allocation-hour"
                text={text}
                className={cx(
                    allocationHourCss,
                    showCrossedOutStyle && !isWeekView ? crossedOutStyles : '',
                    allocationTextColor,
                    !isFromAssignmentBar && !isWorkingDay ? weekEndDayCss : ''
                )}
                onDoubleClick={(e) => e.preventDefault()}
            />
            {showCrossedOutHoursInWeekView && (
                <div
                    className={cx(
                        bracketHourCss(isFromAssignmentBar, isWorkingDay),
                        allocationHourCss,
                        allocationTextColor
                    )}
                >
                    <Ellipse
                        testID="allocation-hour-week-view"
                        text={crossedOutHourWeekView.toString()}
                        className={cx(
                            allocationHourCss,
                            crossedOutStyles,
                            allocationTextColor,
                            !isFromAssignmentBar && !isWorkingDay ? weekEndDayCss : ''
                        )}
                        onDoubleClick={(e) => e.preventDefault()}
                    />
                </div>
            )}
        </div>
    );
};
