import { Sections } from '../../../constants/schedulingTableConstants';
import { findLastIndexUtil } from '../../../util/utilities';
import { IDataState, TDataLoadingRowAction } from '../IDataState';
import {
    assignedSectionHeaderDataID,
    unassignedSectionHeaderDataID,
} from '../../../constants/dataConstatnts';
import { tableDataIDsForUnassignedSectionWithoutHeader } from '../selectors/dataSelectors';
import { tableDataIDsSelectorWithoutHeaderSelector } from '../selectors/tableDataIDsSelector';

export const ActionType = 'ADD_LOADING_ROW';

const addLoadingRow = (sectionType: Sections, idExpression = ''): TDataLoadingRowAction => ({
    type: ActionType,
    payload: {
        idExpression,
        sectionType,
    },
});
export default addLoadingRow;

// TODO reUse also in unassigned parts
export function handle(state: IDataState, { payload }: TDataLoadingRowAction): IDataState {
    const { idExpression, sectionType } = payload;

    const isUnassignedSection = sectionType === Sections.UNASSIGNED_WORK;

    let withoutHeaderDataIDs;
    if (isUnassignedSection) {
        withoutHeaderDataIDs = tableDataIDsForUnassignedSectionWithoutHeader(state);
    } else {
        withoutHeaderDataIDs = tableDataIDsSelectorWithoutHeaderSelector(state);
    }

    const sliceIndex = idExpression
        ? findLastIndexUtil(withoutHeaderDataIDs, idExpression) + 1
        : withoutHeaderDataIDs.length;

    const newDataIDs = [
        ...withoutHeaderDataIDs.slice(0, sliceIndex),
        `${idExpression}_loading`,
        ...withoutHeaderDataIDs.slice(sliceIndex),
    ];

    if (isUnassignedSection) {
        return {
            ...state,
            tableDataIDsForUnassignedSection: unassignedSectionHeaderDataID.concat(newDataIDs),
        };
    }
    return {
        ...state,
        tableDataIDs: assignedSectionHeaderDataID.concat(newDataIDs),
    };
}
