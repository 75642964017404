import { Button } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import SettingsIcon from 'phoenix-icons/dist/SettingsIcon.js';
import { css } from 'react-emotion';
import { connect } from 'react-redux';
import React, { memo, useCallback, useState } from 'react';
import { GlobalPropsContext } from '../../contexts/globalContexts';
import { getSchedulingAreaData } from '../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import toggleSetting, {
    settingNames,
} from '../../data-flow/settings/settingsActions/settingsActions';
import { isPanelOpenedSelector } from '../../data-flow/settings/settingsSelector';
import { IWorkSchedulingCombinedState } from '../../data-flow/types';
import {
    ISettingsComponentProps,
    ISettingsComponentState,
    SettingsStateActionTypes,
    TSettingsState,
    TSettingsStateAction,
} from './types';

import actionChain from '../../data-flow/higher-order-reducers/actionChain';

import { closeAllPanelsChain } from '../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import { prepareSettingsIconTestId } from '../../util/utilities';
import { getContextValue } from '../../contexts/checkContext';
import { getHeaderItemStyles } from '../styles/headerComponentStyles';

export function reducer(state: TSettingsState, action: TSettingsStateAction): TSettingsState {
    switch (action.type) {
        case SettingsStateActionTypes.SET_PROJECT_GROUPING:
            return { ...state, projectGrouping: action.projectGrouping };
        case SettingsStateActionTypes.SET_SHOW_REMAINING_HOURS:
            return { ...state, showRemainingHours: action.needToShow };
        case SettingsStateActionTypes.SHOW_ISSUES:
            return { ...state, showIssues: action.showIssues };
        case SettingsStateActionTypes.SHOW_ACTUAL_PROGRESS:
            return { ...state, showActualProgress: action.showActualProgress };
        case SettingsStateActionTypes.SHOW_COMPLETED_WORK:
            return { ...state, showCompletedWork: action.showCompletedWork };
        case SettingsStateActionTypes.RESET:
            return { ...action.state };
        default:
            return { ...state };
    }
}

export const SettingsComponent = ({
    dispatch,
    schedulingAreaObjCode,
    isPanelOpened,
}: ISettingsComponentProps & ISettingsComponentState): JSX.Element => {
    const globalPropsContext = React.useContext(GlobalPropsContext);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const toggleDialog = useCallback(
        () => {
            if (!getContextValue('sharableLink')) {
                globalPropsContext.minixClose();
            }
            dispatch(
                actionChain([
                    ...closeAllPanelsChain(),
                    toggleSetting(settingNames.isPanelOpened, !isPanelOpened),
                ])
            );
        },
        // "dispatch" reference is never going to change
        [setIsDialogOpen, isPanelOpened] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const settingsButtonClassName = getHeaderItemStyles(isDialogOpen || isPanelOpened);

    return (
        <Localization<string[]> messageKeys={['workloadbalancer.open.settings']}>
            {({ _t }) => {
                return (
                    <div className={projectGroupingCheckboxWrapper}>
                        {/* TODO resolve eslint issue */}
                        {/* eslint-disable-next-line react/button-has-type */}
                        <Button
                            text
                            data-testid={prepareSettingsIconTestId(
                                !!getContextValue('sharableLink'),
                                schedulingAreaObjCode
                            )}
                            onClick={toggleDialog}
                            className={settingsButtonClassName}
                            aria-haspopup="dialog"
                            aria-label={_t['workloadbalancer.open.settings']('Open settings')}
                        >
                            <SettingsIcon focusable="false" />
                        </Button>
                    </div>
                );
            }}
        </Localization>
    );
};

const projectGroupingCheckboxWrapper = css`
    display: flex;
    flex-direction: row;
    margin-left: auto;
`;

function mapStateToProps(state: IWorkSchedulingCombinedState): ISettingsComponentState {
    return {
        schedulingAreaObjCode: getSchedulingAreaData(state).schedulingAreaObjCode,
        isPanelOpened: isPanelOpenedSelector(state),
    };
}

export const Settings = connect(mapStateToProps)(memo(SettingsComponent));
