import { Task, OpTask, Project } from 'workfront-objcodes';

export type TFontType = 'passiveLink' | 'text';
export type TFontTypeTo = string | null;

export interface IFontType {
    type: TFontType;
    to: TFontTypeTo;
}

export const getFontType = (
    isAccessible: boolean | undefined,
    ID: string | undefined,
    objType: string
): IFontType => {
    const objName = {
        [Task]: 'task',
        [OpTask]: 'issue',
        [Project]: 'project',
    };
    if (isAccessible) {
        return {
            type: 'passiveLink',
            to: `/${objName[objType]}/view?ID=${ID}`,
        };
    }
    return {
        type: 'text',
        to: null,
    };
};
