import { IAssignmentIds } from './dataStructures/IAssignmentIds';

/**
 *
 * @param data
 */
export const minixDataRestructuring = (data): IAssignmentIds => {
    const userIDs: string[] = [];
    const roleIDs: string[] = [];
    const teamIDs: string[] = [];
    let teamID = null;

    if (data.teamAssignments[0] && data.teamAssignments[0].team.ID) {
        teamID = data.teamAssignments[0].team.ID;
    } else if (data.teamAssignment && data.teamAssignment.team.ID) {
        teamID = data.teamAssignment.team.ID;
    }

    const expectedStructure: IAssignmentIds = {
        userIDs,
        roleIDs,
        teamIDs,
        teamID,
    };

    if (data.teamAssignments && Array.isArray(data.teamAssignments)) {
        data.teamAssignments.forEach((assignment) => {
            if (assignment.teamID) {
                expectedStructure.teamIDs.push(assignment.teamID);
            }
        });
    }

    if (data.assignments && Array.isArray(data.assignments)) {
        data.assignments.forEach((assignment) => {
            if (assignment.assignedToID) {
                expectedStructure.userIDs.push(assignment.assignedToID);
            } else if (assignment.roleID) {
                expectedStructure.roleIDs.push(assignment.roleID);
            }
        });
    }

    return expectedStructure;
};
