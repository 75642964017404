import mitt from 'mitt';
import { Field } from '@workfront/panel-components';
import {
    IDataState,
    IProjectGroupingMode,
    IUnassignedTasksProjects,
    TIsPanelOpened,
    TMittEmitter,
    TOrUndefined,
    TUnassignedWorkHeight,
} from '../../data-flow/data/IDataState';
import { IWorkSchedulingDispatchProp } from '../../data-flow/types';
import { TAreaObjCodes } from '../../data-flow/areaData/areaRelatedInitialDataState';

export interface ISettingsComponentState {
    schedulingAreaObjCode: TAreaObjCodes;
    isPanelOpened: TIsPanelOpened;
}

export interface ISettingsPanelState {
    internalEventEmitter: mitt.Emitter;
    projectsForUnassignedSection: IUnassignedTasksProjects;
    unassignedTasks: IDataState['unassignedTasks'];
    unassignedWorkHeight: number;
    projectGroupingMode: IProjectGroupingMode;
    showIssues: boolean;
    showActualProgress: boolean;
    showCompletedWork: boolean;
    schedulingAreaObjCode: TAreaObjCodes;
    projectsSortingCriteria: TOrUndefined<Field>;
}

export interface IGroupByChangeHandlerProps extends IWorkSchedulingDispatchProp {
    unassignedWorkHeight: TUnassignedWorkHeight;
    projectGrouping: IProjectGroupingMode;
    internalEventEmitter: TMittEmitter;
    projectsForUnassignedSection: IUnassignedTasksProjects;
    schedulingAreaObjCode: TAreaObjCodes;
    unassignedTasks: IDataState['unassignedTasks'];
}

export type ISettingsComponentProps = IWorkSchedulingDispatchProp;

export type TSettingsState = {
    projectGrouping: boolean;
    showRemainingHours: boolean;
    showIssues: boolean;
    showActualProgress: boolean;
    showCompletedWork: boolean;
};

export type TSettingsStateAction =
    | { type: SettingsStateActionTypes.SET_PROJECT_GROUPING; projectGrouping: boolean }
    | { type: SettingsStateActionTypes.SET_SHOW_REMAINING_HOURS; needToShow: boolean }
    | { type: SettingsStateActionTypes.SHOW_ISSUES; showIssues: boolean }
    | { type: SettingsStateActionTypes.SHOW_ACTUAL_PROGRESS; showActualProgress: boolean }
    | { type: SettingsStateActionTypes.SHOW_COMPLETED_WORK; showCompletedWork: boolean }
    | { type: SettingsStateActionTypes.RESET; state: TSettingsState };

export enum SettingsStateActionTypes {
    SET_PROJECT_GROUPING = 'SET_PROJECT_GROUPING',
    SET_SHOW_REMAINING_HOURS = 'SET_SHOW_REMAINING_HOURS',
    SHOW_ISSUES = 'SHOW_ISSUES',
    SHOW_ACTUAL_PROGRESS = 'SHOW_ACTUAL_PROGRESS',
    SHOW_COMPLETED_WORK = 'SHOW_COMPLETED_WORK',
    RESET = 'RESET',
}
