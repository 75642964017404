import Mitt from 'mitt';
import { Moment } from 'moment';
import { applyMiddleware, combineReducers, createStore, ReducersMapObject } from 'redux';
import thunk from 'redux-thunk';
import { areaDataReducer } from './areaData/areaDataReducer';
import { getAreaRelatedInitialDataState } from './areaData/areaRelatedInitialDataState';
import { currentCustomerReducer } from './currentCustomer/currentCustomerReducer';
import { getCurrentCustomerState } from './currentCustomer/getCurrentCustomerState';
import { getCurrentUserInitialState } from './currentUser/currentUserInitialState';
import { currentUserReducer } from './currentUser/currentUserReducer';
import { getDataReducer } from './data/getDataReducer';
import { getInitialDataState } from './data/getInitialDataState';
import { getDateRangeReducer } from './dateRange/getDateRangeReducer';
import { getInitialDateRangeState } from './dateRange/getInitialDateRangeState';
import { errorDataReducer } from './errorData/errorDataReducer';
import { reloadDataReducer } from './reloadState/reloadDataReducer';
import { getInitialErrorState } from './errorData/getErrorInitialState';
import { getFiltersReducer } from './filters/getFiltersReducer';
import { getInitialFiltersState } from './filters/getInitialFiltersState';
import { supportActionChain } from './higher-order-reducers/actionChain';
import { projectTimelineInfoReducer } from './projectTimelineInfo/projecTimelineInfoReducer';
import { reduxCompose } from './reduxCompose';
import { getInitialReloadState } from './reloadState/getInitialReloadState';
import { getInitialSettingsState } from './settings/getInitialSettingsState';
import { getSettingsReducer } from './settings/getSettingsReducer';
import { getInitialTableSizesState } from './tableSizes/getInitialTableSizesState';
import { getTableSizesReducer } from './tableSizes/getTableSizesReducer';
import { projectColorsReducer } from './projectColors/projectColorsReducer';
import { getInitialState as getInitialProjectColorsState } from './projectColors/getInitialState';
import { IWorkSchedulingCombinedState, TWorkSchedulingCombinedStore } from './types';
import { ICurrentUser } from '../util/currentUserMapper';
import { getInitialStateProjectTimelineState } from './projectTimelineInfo/getInitialStateProjectTimelineState';
import { IGlobalPropsContext, IUnchangeableContext } from '../contexts/globalContexts';

// options passed from the monolith/QS
export interface IConfigureStoreOptions {
    startDate?: Moment;
    currentUser: ICurrentUser;
    filterID?: string;
    context: IUnchangeableContext & IGlobalPropsContext;
}

function getReducers(
    options: IConfigureStoreOptions
): ReducersMapObject<IWorkSchedulingCombinedState, any> {
    return {
        instances: (
            state = {
                internalEventEmitter: new Mitt(),
            }
        ) => state,
        Data: getDataReducer(getInitialDataState()),
        TableSizes: getTableSizesReducer(getInitialTableSizesState()),
        DateRange: getDateRangeReducer(getInitialDateRangeState(options)),
        SettingsState: getSettingsReducer(getInitialSettingsState()),
        Filters: getFiltersReducer(getInitialFiltersState()),
        currentUser: currentUserReducer(getCurrentUserInitialState(options)),
        currentCustomer: currentCustomerReducer(getCurrentCustomerState(options)),
        areaData: areaDataReducer(getAreaRelatedInitialDataState(options)),
        projectColors: projectColorsReducer(getInitialProjectColorsState()),
        projectTimelineInfo: projectTimelineInfoReducer(getInitialStateProjectTimelineState()),
        errorData: errorDataReducer(getInitialErrorState()),
        reloadState: reloadDataReducer(getInitialReloadState()),
    };
}

export function configureStore(
    options: IConfigureStoreOptions,
    initialState?
): TWorkSchedulingCombinedStore {
    return createStore(
        supportActionChain(combineReducers<IWorkSchedulingCombinedState>(getReducers(options))),
        initialState,
        reduxCompose({
            instanceName: '@wf-titan/modern-work-scheduling',
        })(applyMiddleware(thunk.withExtraArgument(options.context)))
    );
}
