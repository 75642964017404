import { WIDGET_HEIGHT_ADDITION_QS } from '../../../../../constants/dataConstatnts';
import { TMessageKey } from '../../../../../data-flow/data/IDataState';

export const NO_USERS_PADDING_FROM_TOP_COMMON = 100;
export const NO_USERS_PADDING_FROM_TOP_PROJECT_AREA = 40;
export const NO_USERS_PADDING_FROM_BOTTOM_COMMON = 0;

/**
 *
 * @param isInProjectScope
 */
export const getNoUsersPaddingTop = (isInProjectScope: boolean): number => {
    return isInProjectScope
        ? NO_USERS_PADDING_FROM_TOP_PROJECT_AREA
        : NO_USERS_PADDING_FROM_TOP_COMMON;
};

/**
 *
 * @param isInProjectScope
 */
export const getNoUsersPaddingBottom = (isInProjectScope: boolean): number => {
    // in some cases balancer is not full height, need some corrections
    return isInProjectScope
        ? NO_USERS_PADDING_FROM_TOP_PROJECT_AREA + WIDGET_HEIGHT_ADDITION_QS
        : NO_USERS_PADDING_FROM_BOTTOM_COMMON;
};

/**
 *
 * @param isInProjectScope
 */
export const getNoUserFirstLevelMessageKey = (isInProjectScope: boolean): TMessageKey => {
    return isInProjectScope
        ? {
              messageKey: 'workloadbalancer.no.work.items.assigned',
              fallBack: 'No work items have been assigned yet',
          }
        : {
              messageKey: 'resourceplanner.no.user.found',
              fallBack: 'No Users Found',
          };
};

/**
 *
 * @param isInProjectScope
 */
export const getNoUserSecondLevelMessageKey = (isInProjectScope: boolean): TMessageKey => {
    return isInProjectScope
        ? {
              messageKey: 'workloadbalancer.assign.them.from.unassigned.work.area',
              fallBack: 'Assign them from the Unassigned Work area to view them here.',
          }
        : {
              messageKey: 'resourceplanner.no.filter.results',
              fallBack: 'Revise your filters and try again.',
          };
};
