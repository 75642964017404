import {
    TIsInLoadingStatePeopleWorkloadSection,
    TLoadingStateUnassignedWorkSection,
} from '../../IDataState';
import { IWorkSchedulingCombinedState } from '../../../types';

export const loadingStateUnassignedWorkSectionSelector = (
    state
): TLoadingStateUnassignedWorkSection => {
    return state.Data.isInLoadingStateUnassignedWorkSection;
};

export function isInLoadingStatePeopleWorkloadSectionSelector(
    state: IWorkSchedulingCombinedState
): TIsInLoadingStatePeopleWorkloadSection {
    return state.Data.isInLoadingStatePeopleWorkloadSection;
}
