import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'ADD_DEFAULT_SCHEDULE_ID';

interface IAddDefaultScheduleIDActionPayload {
    ID: string;
}

type TAddDefaultScheduleIDAction = TModernSchedulingAction<IAddDefaultScheduleIDActionPayload>;

const addDefaultScheduleID = (ID): TAddDefaultScheduleIDAction => ({
    type: ActionType,
    payload: {
        ID,
    },
});
export default addDefaultScheduleID;

export function handle(state: IDataState, { payload }: TAddDefaultScheduleIDAction): IDataState {
    return {
        ...state,
        defaultScheduleID: payload.ID,
    };
}
