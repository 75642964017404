import { IProjectColor, IProjectColors } from '../../data-flow/data/IDataState';
import {
    convertHexToRGB,
    convertHexToRGBAList,
    convertHSVToRGB,
    convertHSVToRGBForText,
    convertRGBToHSV,
    getTextColorRGBByContrast,
    HSVConvertForProject,
    makeTaskRGBFromProjectRGB,
    RGBListToRGBA,
    RGBStringFromList,
} from '../../util/colorUtils';

export class ColorGeneratorService {
    public static getInstance(): ColorGeneratorService {
        if (!ColorGeneratorService.instance) {
            ColorGeneratorService.instance = new ColorGeneratorService();
        }

        return ColorGeneratorService.instance;
    }

    public static resetInstance(): ColorGeneratorService {
        ColorGeneratorService.instance = new ColorGeneratorService();

        return ColorGeneratorService.instance;
    }

    private static instance: ColorGeneratorService;
    private colors: IProjectColors = {};

    public getColors() {
        return this.colors;
    }

    /**
     *
     * @param colorHEX
     */
    public getColor(colorHEX: string) {
        const existingColor = this.colors[colorHEX];
        if (existingColor) {
            return existingColor;
        }

        return this.setColor(colorHEX);
    }

    /**
     *
     * @param colorHEX
     */
    public setColor(colorHEX: string) {
        this.colors[colorHEX] = ColorGeneratorService.calculateColor(colorHEX);
        return this.colors[colorHEX];
    }

    /**
     *
     * @param colorHEX
     */
    private static calculateColor(colorHEX): IProjectColor {
        // actual part
        const RGB = convertHexToRGB(colorHEX);
        const [r, g, b] = convertHexToRGBAList(colorHEX);
        const HSV = convertRGBToHSV(r, g, b);
        const [h, s, v] = HSV;

        // project converted part
        const [h_converted, s_converted, v_converted] = HSVConvertForProject(h, s, v);
        const [r_converted, g_converted, b_converted] = convertHSVToRGB(
            h_converted,
            s_converted,
            v_converted
        );
        const [r_converted_lighter, g_converted_lighter, b_converted_lighter] = convertHSVToRGB(
            h_converted,
            s_converted,
            v_converted - 2
        );
        const RGBConvertedForProject = RGBStringFromList(r_converted, g_converted, b_converted);
        const textColorDarkRGBList = convertHSVToRGBForText(h_converted);
        const [text_r, text_g, text_b] = getTextColorRGBByContrast(
            [r_converted, g_converted, b_converted],
            textColorDarkRGBList
        );
        const textColorRGB = RGBStringFromList(text_r, text_g, text_b);
        const [text_r_dark, text_g_dark, text_b_dark] = textColorDarkRGBList;
        const textColorDark = RGBStringFromList(text_r_dark, text_g_dark, text_b_dark);

        // task part
        const [r_task, g_task, b_task] = makeTaskRGBFromProjectRGB(
            r_converted,
            g_converted,
            b_converted
        );
        const taskRGB = RGBStringFromList(r_task, g_task, b_task);
        const taskHSV = convertRGBToHSV(r_task, g_task, b_task);
        const [h_task, s_task, v_task] = taskHSV;
        const [task_r_converted_lighter, task_g_converted_lighter, task_b_converted_lighter] =
            convertHSVToRGB(h_task, s_task, v_task - 2);
        const taskTextColorDark = convertHSVToRGBForText(h_task);
        const [task_text_r_dark, task_text_g_dark, task_text_b_dark] = taskTextColorDark;
        const [task_text_r, task_text_g, task_text_b] = getTextColorRGBByContrast(
            [r_task, g_task, b_task],
            taskTextColorDark
        );
        const taskColor = RGBStringFromList(task_text_r, task_text_g, task_text_b);

        return {
            HEX_actual: colorHEX,
            RGB_actual: RGB,
            HSV_actual: HSV,
            RGB: RGBConvertedForProject,
            RGB_lighter: RGBStringFromList(
                r_converted_lighter,
                g_converted_lighter,
                b_converted_lighter
            ),
            RGB_list: [r_converted, g_converted, b_converted],
            RGBA: {
                0: RGBConvertedForProject,
                15: RGBListToRGBA([r_converted, g_converted, b_converted], 15),
                35: RGBListToRGBA([r_converted, g_converted, b_converted], 35),
            },
            textColor: textColorRGB,
            textColorDark,
            taskRGB,
            taskRGB_lighter: RGBStringFromList(
                task_r_converted_lighter,
                task_g_converted_lighter,
                task_b_converted_lighter
            ),
            taskRGBA: {
                0: taskRGB,
                15: RGBListToRGBA([r_task, g_task, b_task], 15),
                35: RGBListToRGBA([r_task, g_task, b_task], 35),
            },
            taskColor,
            taskTextColorDark: RGBStringFromList(
                task_text_r_dark,
                task_text_g_dark,
                task_text_b_dark
            ),
        };
    }
}
