import { IAdditionalConfigForShareURL } from '../../../../util/utilities';
import { TAreaObjCodes } from '../../../../data-flow/areaData/areaRelatedInitialDataState';
import { generateUnsavedFilterPrefix } from '../../../../util/filters/filterUtil';
import { Sections } from '../../../../constants/schedulingTableConstants';

export const getAdditionalConfig = (
    schedulingAreaObjCode: TAreaObjCodes
): IAdditionalConfigForShareURL => {
    const additionalConfig = {};

    additionalConfig[`${Sections.PEOPLE_WORKLOAD}_${schedulingAreaObjCode}`] = localStorage.getItem(
        generateUnsavedFilterPrefix(Sections.PEOPLE_WORKLOAD, schedulingAreaObjCode)
    );

    return additionalConfig;
};
