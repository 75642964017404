import { TModernSchedulingAction } from '../../types';
import { IDataState, IRequestedUserData } from '../IDataState';

export const ActionType = 'ADD_USERS';

interface IUserDataActionPayload {
    userData: IRequestedUserData[];
}

type TUserDataAction = TModernSchedulingAction<IUserDataActionPayload>;

const addUsers = (userData): TUserDataAction => ({
    type: ActionType,
    payload: {
        userData,
    },
});
export default addUsers;

export function handle(state: IDataState, { payload }: TUserDataAction): IDataState {
    const users = { ...state.users };
    payload.userData.forEach((userItem: IRequestedUserData) => {
        const { ID, userRoles, scheduleID, ...restData } = userItem;
        const userRolesIDs = userRoles.map((roleData) => roleData.role.ID);
        const userScheduleID =
            scheduleID && state.calculateFTEAutomatic ? scheduleID : state.defaultScheduleID;

        users[ID] = {
            ID,
            hasAssignments: false,
            userRolesIDs,
            ...restData,
            scheduleID: userScheduleID,
            userWorkPerDayForPeriod: [],
            availableHoursForPeriod: [],
            purAvailableHoursForPeriod: [],
            timeOffs: {},
            expanded: false,
            projectIDs: [],
            inaccessibleProjectIDs: [],
            nodes: {},
            offset: 0,
            userDataRequestsState: {
                isRequestSend: false,
                isDataLoaded: false,
            },
            projectsDataRequestsState: {
                isRequestSend: false,
                isDataLoaded: false,
            },
            expandedProjectIDs: new Set<string>(),
            assignments: {},
            isInAssignmentProcess: false,
        };
    });

    return {
        ...state,
        users,
    };
}
