import { IWorkSchedulingCombinedState } from 'src/data-flow/types';
import createCachedSelector from 're-reselect';
import { contouredAssignmentsSelector } from '../contouredAssignmentsSelector';
import { getTaskSelector } from '../reselect/getTaskSelector/getTaskSelector';

export const isAssignmentContoured = createCachedSelector(
    contouredAssignmentsSelector,
    (state: IWorkSchedulingCombinedState, taskID: string) => getTaskSelector(state)(taskID),
    (state: IWorkSchedulingCombinedState, taskID: string, userID: string) => userID,
    (assignments, task, userID) => {
        const assignmentID = task?.assignmentUserIDToAssignmentID[userID];
        return assignments[assignmentID]?.isContoured ?? false;
    }
)((state: IWorkSchedulingCombinedState, taskID: string, userID: string) => {
    return `${taskID} ${userID}`;
});
