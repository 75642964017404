import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'REMOVE_HIGHLIGHTING_USERS';

interface ISetHighlightingUsersPayload {
    userIds: string[];
}

type TSetHighlightingUsersAction = TModernSchedulingAction<ISetHighlightingUsersPayload>;

const removeFromHighlightingMode = (userIds: string[]): TSetHighlightingUsersAction => ({
    type: ActionType,
    payload: {
        userIds,
    },
});
export default removeFromHighlightingMode;

export function handle(state: IDataState, { payload }: TSetHighlightingUsersAction): IDataState {
    const inHighlightingModeUsersAfterAssignmentUser = {
        ...state.inHighlightingModeUsersAfterAssignment.user,
    };

    payload.userIds.forEach((userID) => {
        if (inHighlightingModeUsersAfterAssignmentUser[userID]) {
            delete inHighlightingModeUsersAfterAssignmentUser[userID];
        }
    });

    return {
        ...state,
        inHighlightingModeUsersAfterAssignment: {
            user: inHighlightingModeUsersAfterAssignmentUser,
            object: {},
        },
    };
}
