import appColors from '../../../../../constants/appColors';
import { previousWPDStatuses } from '../../../../../util/chartUtils';
import { sizes } from '../../../../../constants/schedulingTableConstants';

type TPoint = Array<Array<string | number>>;

export const getStrokeColor = (isOverAllocated: boolean): string =>
    isOverAllocated ? appColors.overAllocated.hex : appColors.underAllocated['0.63'];

export const getY1 = (
    point: TPoint,
    iterationIndex: number,
    previousStatus?: string
): string | number => {
    let y1 = +point[0][1];
    if (iterationIndex === 0) {
        if (previousStatus === previousWPDStatuses.greater) {
            y1 += -sizes.chartSmallDiffForVisual;
        }
        if (previousStatus === previousWPDStatuses.smaller) {
            y1 += sizes.chartSmallDiffForVisual;
        }
    }

    return y1;
};

export const getX1 = (point: TPoint, iterationIndex: number): string | number => {
    return iterationIndex === 0 && point[0][0] === 0 ? sizes.chartSmallDiffForVisual : point[0][0];
};

export const getX2 = (point: TPoint, iterationIndex: number): string | number => {
    return iterationIndex === 0 && point[1][0] === 0 ? sizes.chartSmallDiffForVisual : point[1][0];
};

export const isFirstLineAndHasOverAllocation = (
    iterationIndex: number,
    isOverAllocated: boolean,
    prevIsOverAllocated: boolean
): boolean => iterationIndex === 0 && (isOverAllocated || prevIsOverAllocated);

export const getIsOverAllocatedGradientStart = (
    isOverAllocated: boolean,
    prevIsOverAllocated: boolean
): string => {
    return isOverAllocated && !prevIsOverAllocated
        ? appColors['79A6D7'].hex
        : appColors.overAllocated.hex;
};
