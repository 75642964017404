import { Localization } from '@workfront/localize-react';
import moment from 'moment';
import React from 'react';
import { toolTipContentTitle } from './styles/sharedStyles';

export const getHourAllocationDetail = (workPerDay, availableHours): JSX.Element => {
    let content;
    if (workPerDay > availableHours) {
        content = (
            <Localization<string> messageKey="schedule.overallocated" fallback="Overallocated" />
        );
    } else if (workPerDay === availableHours) {
        content = (
            <Localization<string>
                messageKey="schedule.fully.allocated"
                fallback="Fully allocated"
            />
        );
    } else {
        content = (
            <Localization<string> messageKey="schedule.underallocated" fallback="Underallocated" />
        );
    }

    return <span className={toolTipContentTitle}>{content}</span>;
};

export const changeHourFormat = (hour): string => {
    return moment(hour, 'HH:mm').format('hh:mm A');
};

export const getNonWorkHoursDuration = (nonWorkHours, isUserTimeOff): JSX.Element[] => {
    const workingHours: string[] = [];
    for (let i = 0; i < nonWorkHours.length - 1; i += 2) {
        workingHours.push(
            `${changeHourFormat(nonWorkHours[i])} - ${changeHourFormat(nonWorkHours[i + 1])}`
        );
    }

    return workingHours.map((hour) => {
        return (
            <span key={hour}>
                <Localization<string>
                    messageKey={
                        isUserTimeOff
                            ? 'user.personal.timeoff.non.working.hours'
                            : 'schedule.detail.tab.working.hours'
                    }
                    fallback={isUserTimeOff ? 'Non-Working hours:' : 'Working hours:'}
                />{' '}
                {hour}
                <br />
            </span>
        );
    });
};
