import * as React from 'react';
import { connect } from 'react-redux';
import setTableWidth, {
    TSetTableWidthAction,
} from '../../data-flow/tableSizes/actions/setTableWidth';

interface ITableWidthSetterDispatchProps {
    setTableWidth: (width: number) => TSetTableWidthAction;
}
interface ITableWidthSetterOwnProps {
    width: number;
}

type TTableWidthSetterProps = ITableWidthSetterDispatchProps & ITableWidthSetterOwnProps;

class TableWidthSetterDisconnected extends React.PureComponent<TTableWidthSetterProps> {
    componentDidMount(): void {
        this.props.setTableWidth(this.props.width);
    }

    componentDidUpdate(prevProps) {
        if (this.props.width !== prevProps.width) {
            this.props.setTableWidth(this.props.width);
        }
    }

    render(): React.ReactNode {
        return null;
    }
}

const mapDispatchToProps = (dispatch) => ({
    setTableWidth: (width) => dispatch(setTableWidth(width)),
});
export const TableWidthSetter = connect(null, mapDispatchToProps)(TableWidthSetterDisconnected);
