import React, { useMemo } from 'react';
import { cx } from 'react-emotion';
import { connect } from 'react-redux';
import { DATE_FORMAT } from '../../../../constants/dataConstatnts';
import { Sections } from '../../../../constants/schedulingTableConstants';
import {
    IFullTimeOffSteps,
    ITimeOffsState,
    IWorkDays,
    TTableDataIDs,
} from '../../../../data-flow/data/IDataState';
import { tableDataIDsSelector } from '../../../../data-flow/data/selectors/tableDataIDsSelector';
import { getFullTimeOffSteps } from '../../../../data-flow/data/selectors/dataSelectors';
import { IDateRangeState } from '../../../../data-flow/dateRange/IDateRangeState';
import { stepsSelector } from '../../../../data-flow/dateRange/selectors/stepsSelector';
import { IWorkSchedulingCombinedState, UnitOfTime } from '../../../../data-flow/types';
import { isWorkingDayBySchedule } from '../../../../util/utilities';
import {
    cellWithoutBorderBottom,
    cellWithoutBorderLeft,
    cellWrapper,
    greyCell,
    weekEndStyleGrey,
} from '../../../styles/sharedStyles';
import { Assignment } from '../Assignment';
import { InaccessibleAssignment } from './InaccessibleAssignment';
import { ProjectAssignment } from './ProjectAssignment';
import { getPeriodModesValues } from '../../../../util/periodUtils/periodUtils';
import { stepUnitSelector } from '../../../../data-flow/dateRange/selectors/stepUnitSelector';
import { getUserSelector } from '../../../../data-flow/data/selectors/users/usersSelector';
import {
    projectGroupingModeSelector,
    showAllocationsVisualizationSelector,
} from '../../../../data-flow/settings/settingsSelector';
import { timeOffsSelectorForWeekMonthViewCached } from '../../../../data-flow/data/selectors/rereselect/timeOffsSelectorForWeekMonthView';
import { isLastTaskRowSelector } from '../../../../data-flow/data/selectors/reselect/isLastTaskRowSelector/isLastTaskRowSelector';
import { isLastRowUnderUserSelector } from '../../../../data-flow/data/selectors/reselect/isLastRowUnderUserSelector';
import { scheduleWorkDaysSelector } from '../../../../data-flow/data/selectors/reselect/scheduleWorkDaysSelector/scheduleWorkDaysSelector';
import { timeOffsSelectorCached } from '../../../../data-flow/data/selectors/rereselect/timeOffsSelector';
import { NonUserFullTimeOffStyled } from './FullTimeOffs';

interface IAssignmentsStateProps extends Pick<IDateRangeState, 'steps'> {
    activePeriodMode: UnitOfTime;
    isLastRow: boolean;
    isLastRowUnderUser: boolean;
    scheduleWorkDays: IWorkDays;
    projectGroupingMode: boolean;
    isAllocationsVisualizationOn: boolean;
    userScheduleID: string;
    timeOffs: ITimeOffsState;
    timeOffsByDay: ITimeOffsState;
    tableDataIDs: TTableDataIDs;
}

interface IAssignmentsProps extends IAssignmentsStateProps {
    idExpression: string;
    isTheLastRow: boolean;
}

interface IAssignmentComponentProps extends IAssignmentsProps {
    idsArray: string[];
    fullTimeOffsStepsByDay: IFullTimeOffSteps;
}

interface IInaccessibleAssignmentComponentProps {
    userScheduleID: string;
    idExpression: string;
    idsArray: string[];
    fullTimeOffsStepsByDay: IFullTimeOffSteps;
}

export const assignedDataTestIds = {
    cellWeekend: 'assignment-cell-weekend',
    cellWeekday: 'assignment-cell-weekday',
};

const AssignmentsComponent: React.FunctionComponent<IAssignmentsProps> = React.memo((props) => {
    const {
        isAllocationsVisualizationOn,
        isLastRow,
        isLastRowUnderUser,
        projectGroupingMode,
        activePeriodMode,
        idExpression,
        scheduleWorkDays,
        steps,
        timeOffs,
        timeOffsByDay,
        tableDataIDs,
        userScheduleID,
    } = props;
    const idsArray = useMemo(() => idExpression.split('_'), [idExpression]);
    const isTaskOrIssueCondition = !projectGroupingMode || idsArray.length === 3;
    const { isDayMode, isWeekMode } = getPeriodModesValues(activePeriodMode);

    const fullTimeOffsSteps = getFullTimeOffSteps(timeOffs, isWeekMode);
    const fullTimeOffsStepsByDay = isWeekMode
        ? getFullTimeOffSteps(timeOffsByDay, !isWeekMode)
        : fullTimeOffsSteps;

    let assignmentComponent;
    const weekDayStyleVar = '';

    if (idsArray.indexOf('showInaccessible') !== -1) {
        assignmentComponent = getInaccessibleAssignment({
            idExpression,
            userScheduleID,
            idsArray,
            fullTimeOffsStepsByDay,
        });
    } else if (isTaskOrIssueCondition) {
        assignmentComponent = getAssignment({ ...props, idsArray, fullTimeOffsStepsByDay });
    } else {
        assignmentComponent = getProjectAssignment({ ...props, idsArray, fullTimeOffsStepsByDay });
    }

    const lastIdExpression = idsArray[idsArray.length - 1];

    const isProjectRowOpened = useMemo(
        () =>
            tableDataIDs.some((item) => {
                const splitIdExpression = item.split('_');
                return (
                    splitIdExpression.length === 3 &&
                    splitIdExpression[0] === idsArray[0] &&
                    splitIdExpression[1] === idsArray[1]
                );
            }),
        [tableDataIDs, idsArray]
    );
    const cellSolidBorderBottom = isProjectRowOpened && !isTaskOrIssueCondition;
    return (
        <>
            {assignmentComponent}
            <div
                className={cellWrapper}
                data-testid={`user-assignment-wrapper-${lastIdExpression}`}
            >
                {steps.map((step) => {
                    const stepDateString = step.format(DATE_FORMAT);
                    let isStriped;
                    if (isDayMode) {
                        isStriped =
                            !isWorkingDayBySchedule(scheduleWorkDays, step) ||
                            !!fullTimeOffsSteps[stepDateString];
                    } else {
                        isStriped = !!fullTimeOffsSteps[stepDateString];
                    }
                    const isFullTime = fullTimeOffsSteps?.[step.format(DATE_FORMAT)];
                    const allocationsVisualization = isAllocationsVisualizationOn
                        ? cellWithoutBorderLeft
                        : '';
                    const assignedCellDay = `assigned-cell-day_${idExpression}`;
                    return (
                        <div
                            data-testid={
                                isStriped
                                    ? assignedDataTestIds.cellWeekend
                                    : assignedDataTestIds.cellWeekday
                            }
                            key={step.unix()}
                            className={cx(
                                !cellSolidBorderBottom && !isLastRow && isTaskOrIssueCondition
                                    ? cellWithoutBorderBottom
                                    : '',
                                greyCell(isLastRowUnderUser, cellSolidBorderBottom),
                                isStriped
                                    ? weekEndStyleGrey(isLastRowUnderUser, cellSolidBorderBottom)
                                    : weekDayStyleVar,
                                allocationsVisualization,
                                assignedCellDay
                            )}
                        >
                            {isFullTime ? <NonUserFullTimeOffStyled /> : null}
                        </div>
                    );
                })}
            </div>
        </>
    );
});

const getInaccessibleAssignment = (props: IInaccessibleAssignmentComponentProps): JSX.Element => {
    const { idExpression, userScheduleID, idsArray, fullTimeOffsStepsByDay } = props;

    return (
        <InaccessibleAssignment
            IDs={idsArray}
            idExpression={idExpression}
            fullTimeOffsStepsByDay={fullTimeOffsStepsByDay}
            userScheduleID={userScheduleID}
            userID={idsArray[0]}
        />
    );
};

const getAssignment = (props: IAssignmentComponentProps): JSX.Element => {
    const {
        idExpression,
        projectGroupingMode,
        userScheduleID,
        isTheLastRow,
        idsArray,
        fullTimeOffsStepsByDay,
    } = props;

    const ID = projectGroupingMode ? idsArray[2] : idsArray[1];

    return (
        <Assignment
            ID={ID}
            idExpression={idExpression}
            sectionType={Sections.PEOPLE_WORKLOAD}
            isAssigned
            fullTimeOffsStepsByDay={fullTimeOffsStepsByDay}
            userScheduleID={userScheduleID}
            userID={idsArray[0]}
            isTheLastRow={isTheLastRow}
        />
    );
};

const getProjectAssignment = (props: IAssignmentComponentProps): JSX.Element => {
    const { idExpression, userScheduleID, idsArray, fullTimeOffsStepsByDay } = props;

    return (
        <ProjectAssignment
            idExpression={idExpression}
            isAssigned
            fullTimeOffsStepsByDay={fullTimeOffsStepsByDay}
            userScheduleID={userScheduleID}
            sectionType={Sections.PEOPLE_WORKLOAD}
            userID={idsArray[0]}
        />
    );
};

const mapStateToProps = (
    state: IWorkSchedulingCombinedState,
    ownProps: { idExpression: string }
): IAssignmentsStateProps => {
    const idsArray = ownProps.idExpression.split('_');
    const steps = stepsSelector(state);

    const user = getUserSelector(state)(idsArray[0]);
    const { isWeekMode } = getPeriodModesValues(stepUnitSelector(state));

    const timeOffsByDay = timeOffsSelectorCached(state, { userID: idsArray[0] });
    const timeOffs = isWeekMode
        ? timeOffsSelectorForWeekMonthViewCached(state, {
              userID: idsArray[0],
              scheduleID: user.scheduleID,
          })
        : timeOffsByDay;

    return {
        steps,
        activePeriodMode: stepUnitSelector(state),
        isLastRow: isLastTaskRowSelector(state)(ownProps.idExpression),
        isLastRowUnderUser: isLastRowUnderUserSelector(state)(ownProps.idExpression),
        scheduleWorkDays: scheduleWorkDaysSelector(state)(user.scheduleID),
        projectGroupingMode: projectGroupingModeSelector(state),
        isAllocationsVisualizationOn: showAllocationsVisualizationSelector(state),
        tableDataIDs: tableDataIDsSelector(state),
        userScheduleID: user.scheduleID,
        timeOffs,
        timeOffsByDay,
    };
};

export const Assignments = connect(mapStateToProps)(AssignmentsComponent);
