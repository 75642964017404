import { ButtonDropdown } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import * as React from 'react';
import { connect } from 'react-redux';
import {
    TimelineActions,
    TIMELINE_OPTIONS,
    TimeUnit,
    WEEKS,
} from '../../../../constants/periodEnums';
import { IWorkSchedulingDispatchProp, UnitOfTime } from '../../../../data-flow/types';
import { localizationClient } from '../../../../constants/LocalizationClientFactory';
import {
    timelinePeriodDropDownClosedStyles,
    timelinePeriodDropDownOpenedStyles,
    timelinePeriodStyles,
    timelineStyles,
} from '../../../styles/timelineStyles';
import {
    customOptionVisible,
    TimelineDateRangePicker,
} from '../TimelineDateRangePicker/TimelineDateRangePicker';
import { stepUnitSelector } from '../../../../data-flow/dateRange/selectors/stepUnitSelector';
import { periodModeSelector } from '../../../../data-flow/dateRange/selectors/periodModeSelector';
import changeActivePeriodMode from '../../../../data-flow/dateRange/changeActivePeriodMode';
import { getSchedulingAreaData } from '../../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { getContextValue } from '../../../../contexts/checkContext';
import { IHandleTimelineNavigationFunc } from '../Header';
import { editSettingsThunk } from '../../../../data-flow/thunks/settings';
import { periodTimeUnitSelector } from '../../../../data-flow/dateRange/selectors/periodTimeUnitSelector';
import { getActivePeriodMode } from '../../../../util/periodUtils/periodUtils';

interface ITimelineStateToProps {
    stepUnit: UnitOfTime;
    periodMode: number;
    schedulingAreaObjCode: string | undefined;
    periodTimeUnit: TimeUnit;
}

type ITimelineProps = IHandleTimelineNavigationFunc;

interface ITimeLinePeriodOptions {
    value: number;
    testID: string;
    label: string;
    timeUnit: TimeUnit;
}

type TTimelineProps = ITimelineStateToProps & ITimelineProps & IWorkSchedulingDispatchProp;

export class TimelineComponent extends React.PureComponent<
    TTimelineProps,
    { isOpenedTimelinePeriodDropDown: boolean }
> {
    constructor(props) {
        super(props);

        this.state = {
            isOpenedTimelinePeriodDropDown: false,
        };
    }

    timelinePeriodHandler = (periodMode, timeUnit): void => {
        if (this.props.periodMode === periodMode) {
            return;
        }

        const { stepUnit, dispatch, handleTimelineNavigation } = this.props;

        const activePeriodMode = getActivePeriodMode(periodMode, timeUnit, stepUnit);
        if (activePeriodMode) {
            dispatch(changeActivePeriodMode(activePeriodMode));
        }

        handleTimelineNavigation(TimelineActions.PERIOD_CHANGE, {
            isTodayActive: false,
            periodMode,
            timeUnit,
        });

        if (!getContextValue('sharableLink')) {
            dispatch(editSettingsThunk());
        }
    };

    changeDropDownOpenState = (): void => {
        this.setState((prevState) => ({
            isOpenedTimelinePeriodDropDown: !prevState.isOpenedTimelinePeriodDropDown,
        }));
    };

    render(): JSX.Element {
        const {
            periodMode,
            handleTimelineNavigation,
            stepUnit,
            schedulingAreaObjCode,
            periodTimeUnit,
        } = this.props;
        const { isOpenedTimelinePeriodDropDown } = this.state;

        const timeLineMessageKeys = getTimeLineMessageKeys();
        const showCustomOption = customOptionVisible(periodTimeUnit, periodMode);

        const customOption = twelveWeekOption;

        return (
            <Localization messageKeys={timeLineMessageKeys}>
                {(messages) => {
                    const timelinePeriodOptions = getTimelinePeriodOptions(messages);
                    const timelineOptionByPeriod = getTimelineOptionByPeriod(
                        timelinePeriodOptions,
                        periodMode,
                        stepUnit
                    );
                    return (
                        <div
                            className={timelineStyles}
                            data-testid="global_scheduling.timeline_period"
                        >
                            <ButtonDropdown
                                text
                                className={[
                                    timelinePeriodStyles,
                                    isOpenedTimelinePeriodDropDown
                                        ? timelinePeriodDropDownOpenedStyles
                                        : timelinePeriodDropDownClosedStyles,
                                ]}
                                selector="global_scheduling.timeline_period"
                                testID={`${
                                    getContextValue('sharableLink')
                                        ? 'global_scheduling.timeline_period_url'
                                        : 'global_scheduling.timeline_period'
                                }${schedulingAreaObjCode}`}
                                options={timelinePeriodOptions}
                                selectedOptions={[timelineOptionByPeriod]}
                                onChange={(timelinePeriod) =>
                                    this.timelinePeriodHandler(
                                        timelinePeriod.value,
                                        timelinePeriod.timeUnit
                                    )
                                }
                                onIsOpenChanged={this.changeDropDownOpenState}
                                aria-expanded={isOpenedTimelinePeriodDropDown}
                                aria-haspopup="true"
                                aria-label={`${localizationClient.getTextSync(
                                    'workloadbalancer.select.time.period',
                                    'Select time period, Currently selected value:'
                                )} ${
                                    showCustomOption
                                        ? customOption.fallback
                                        : timelineOptionByPeriod.label
                                }`}
                            >
                                {showCustomOption
                                    ? customOption.label
                                    : timelineOptionByPeriod.label}
                            </ButtonDropdown>

                            <TimelineDateRangePicker
                                handleTimelineNavigation={handleTimelineNavigation}
                            />
                        </div>
                    );
                }}
            </Localization>
        );
    }
}

export const TimeLineMessageKeysAndFallBacks = [
    {
        messageKey: 'widgets.daterangepicker.2week',
        fallBack: '2 weeks',
    },
    {
        messageKey: 'widgets.daterangepicker.4week',
        fallBack: '4 weeks',
    },
    {
        messageKey: 'widgets.daterangepicker.6week',
        fallBack: '6 weeks',
    },
    {
        messageKey: 'widgets.daterangepicker.12week',
        fallBack: '12 weeks',
    },
];

const twelveWeekOption = {
    label: <Localization<string> messageKey="widgets.daterangepicker.12week" fallback="12 weeks" />,
    value: WEEKS.W_12,
    fallback: '12 weeks',
};

export const getTimeLineMessageKeys = (): string[] => {
    return TIMELINE_OPTIONS.map((obj) => obj.messageKey);
};

const getTimelinePeriodOptions = (
    labels: Array<(fallBack) => string>
): ITimeLinePeriodOptions[] => {
    return TIMELINE_OPTIONS.map((period, index) => {
        return {
            value: period.value,
            timeUnit: period.timeUnit,
            label: labels[index](period.fallBack),
            testID: `global_scheduling.timeline_period.${period.value}${
                period.timeUnit === TimeUnit.WEEK ? 'week' : 'month'
            }`,
        };
    });
};

const getTimelineOptionByPeriod = (
    timelinePeriodOptions: ITimeLinePeriodOptions[],
    periodMode: number,
    stepUnit: UnitOfTime
): ITimeLinePeriodOptions => {
    return (
        (timelinePeriodOptions.find(function (option) {
            return stepUnit === TimeUnit.MONTH
                ? option.value === WEEKS.W_12
                : option.value === periodMode;
        }) as ITimeLinePeriodOptions) || timelinePeriodOptions[timelinePeriodOptions.length - 1]
    );
};

const mapStateToProps = (state): ITimelineStateToProps => {
    return {
        stepUnit: stepUnitSelector(state),
        periodMode: periodModeSelector(state),
        periodTimeUnit: periodTimeUnitSelector(state),
        schedulingAreaObjCode: getSchedulingAreaData(state).schedulingAreaObjCode,
    };
};

export const Timeline = connect(mapStateToProps)(TimelineComponent);
