import _ from 'lodash';
import { apiDateToDate } from '@workfront/fns';
import { userHasAssignments } from '../../../util/utilities';
import { TModernSchedulingAction } from '../../types';
import { IDataState, INodesDetailsState, IRequestedInaccessibleAssignment } from '../IDataState';
import { initialProjectNode } from './addAssignments';

export const ActionType = 'ADD_INACCESSIBLE_ASSIGNMENTS';

interface IAddInaccessibleAssignmentsActionPayload {
    inAccessibleAssignments: IRequestedInaccessibleAssignment[];
    usersIDs: string[];
}

type TAddInaccessibleAssignmentsAction =
    TModernSchedulingAction<IAddInaccessibleAssignmentsActionPayload>;

const addInaccessibleAssignments = (
    inAccessibleAssignments,
    usersIDs
): TAddInaccessibleAssignmentsAction => ({
    type: ActionType,
    payload: {
        inAccessibleAssignments,
        usersIDs,
    },
});
export default addInaccessibleAssignments;

export function handle(
    state: IDataState,
    { payload }: TAddInaccessibleAssignmentsAction
): IDataState {
    const users = { ...state.users };

    _.forEach(payload.usersIDs, (userID: string, index: number) => {
        // This action is called also when assign object to user so
        // Should be this check as you can assign object to user which is not in loaded users state
        if (users[userID]) {
            const nodesDetails: INodesDetailsState = { ...users[userID].nodes };
            const userInaccessibleProjectIDs = users[userID].inaccessibleProjectIDs;
            const inaccessibleProjectIDs = userInaccessibleProjectIDs
                ? [...userInaccessibleProjectIDs]
                : [];

            // adding inAccessibleAssignments
            payload.inAccessibleAssignments[index].result.forEach((inaccessibleAssignmentItem) => {
                if (users[userID].projectIDs.indexOf(inaccessibleAssignmentItem.projectID) === -1) {
                    inaccessibleProjectIDs.push(inaccessibleAssignmentItem.projectID);
                }

                if (!nodesDetails[inaccessibleAssignmentItem.projectID]) {
                    nodesDetails[inaccessibleAssignmentItem.projectID] = initialProjectNode;
                }

                let inaccessibleNodesWorkPerDaysFromService = {};

                inaccessibleNodesWorkPerDaysFromService = {
                    ...nodesDetails[inaccessibleAssignmentItem.projectID]
                        .inaccessibleNodesWorkPerDaysFromService,
                };

                _.forEach(inaccessibleAssignmentItem.workPerDay, (value, dateKey) => {
                    inaccessibleNodesWorkPerDaysFromService[dateKey] =
                        inaccessibleNodesWorkPerDaysFromService[dateKey]
                            ? inaccessibleNodesWorkPerDaysFromService[dateKey] + value
                            : value;
                });

                const projectedStartDate =
                    inaccessibleAssignmentItem.projectedStartDate ||
                    inaccessibleAssignmentItem.actualStartDate ||
                    inaccessibleAssignmentItem.plannedStartDate;

                const projectedCompletionDate =
                    inaccessibleAssignmentItem.projectedCompletionDate ||
                    inaccessibleAssignmentItem.actualCompletionDate ||
                    inaccessibleAssignmentItem.plannedCompletionDate;

                nodesDetails[inaccessibleAssignmentItem.projectID] = {
                    ...nodesDetails[inaccessibleAssignmentItem.projectID],
                    inaccessibleNodesWorkRequired:
                        inaccessibleAssignmentItem.workRequired +
                        nodesDetails[inaccessibleAssignmentItem.projectID]
                            .inaccessibleNodesWorkRequired,
                    inaccessibleNodesDates: [
                        ...nodesDetails[inaccessibleAssignmentItem.projectID]
                            .inaccessibleNodesDates,
                        {
                            startDate: apiDateToDate(inaccessibleAssignmentItem.plannedStartDate),
                            endDate: apiDateToDate(
                                inaccessibleAssignmentItem.plannedCompletionDate
                            ),
                            projectedStartDate: apiDateToDate(projectedStartDate),
                            projectedCompletionDate: apiDateToDate(projectedCompletionDate),
                        },
                    ],
                    inaccessibleNodesWorkPerDays: [
                        ...nodesDetails[inaccessibleAssignmentItem.projectID]
                            .inaccessibleNodesWorkPerDays,
                        inaccessibleAssignmentItem.workPerDay,
                    ],
                    inaccessibleNodesWorkPerDaysFromService,
                };
            });
            /// /

            users[userID] = {
                ...users[userID],
                inaccessibleProjectIDs: _.uniq(inaccessibleProjectIDs),
                nodes: nodesDetails,
                hasAssignments: userHasAssignments(nodesDetails),
            };
        }
    });

    return {
        ...state,
        users,
    };
}
