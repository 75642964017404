import { TWorkSchedulingThunkAction } from '../types';
import {
    getUserSelector,
    selectExpandedUsersIDs,
    selectUsersIDsForRenderedSection,
    userByIdSelector,
    userProjectNodesByIdSelector,
    usersSelector,
} from '../data/selectors/users/usersSelector';
import toggleLoading from '../data/dataLoadingActions/toggleLoading';
import { Sections } from '../../constants/schedulingTableConstants';
import { getScheduleIDs, getUserNodesIDs, sortByID } from '../../util/dataService';
import {
    projectGroupColorsSelector,
    selectDefaultScheduleID,
    tasksAndIssuesSelector,
} from '../data/selectors/dataSelectors';
import changeUsersDataRequestsState from '../data/assignedDataActions/changeUsersDataRequestsState';
import removeRow from '../data/dataLoadingActions/removeRow';
import {
    projectColorsModeSelector,
    projectGroupingModeSelector,
    projectsSortingCriteriaSelector,
    showActualProgressSelector,
} from '../settings/settingsSelector';
import actionChain from '../higher-order-reducers/actionChain';
import {
    getOffset,
    isColorModeProject,
    prepareColorsByProjectData,
    prepareColorsByProjectGroupData,
} from '../../util/utilities';
import { LOAD_PROJECTS_LIMIT, LOAD_TASKS_ISSUES_LIMIT } from '../../constants/dataConstatnts';
import { getSchedulingAreaData } from '../areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { startDateSelector } from '../dateRange/selectors/startDateSelector';
import { tasksFromAssignmentsSelector } from '../data/selectors/rereselect/tasksFromAssignmentsSelector/tasksFromAssignmentsSelector';
import changeUserNodesOffset from '../data/assignedDataActions/changeOffset';
import addTableDataIDs from '../data/assignedDataActions/addTableDataIDs';
import { loadUsersAssignments, openProjectTasks } from './loadDataThunk';
import { IRequestedProjectData, TProjectID, TUserID } from '../data/IDataState';
import changeProjectsDataRequestsState from '../data/nodeItemActions/changeProjectsDataRequestsState';
import addLoadingRow from '../data/dataLoadingActions/addLoadingRow';
import { APIService } from '../../services/api-services/apiService';
import addProjectGroupColors from '../data/assignedDataActions/addProjectGroupColors/addProjectGroupColors';
import addProjectsDetails from '../data/assignedDataActions/addProjectsDetails';
import { endDateSelector } from '../dateRange/selectors/endDateSelector';
import {
    ITasksAndProjects,
    sortByProjectID,
    sortProjectsByTasksDetails,
} from '../../util/changeProjectsSortingUtil';
import changeProjectIDsSorting from '../data/assignedDataActions/changeProjectIDsSorting';
import removeTableDataIDs from '../data/assignedDataActions/removeTableDataIDs';
import changeNodeArrowState from '../data/nodeItemActions/changeNodeArrowState';
import addProjectColors from '../projectColors/actions/addProjectColors/addProjectColors';
import { internalEventEmitterSelector } from '../instances/internalEventEmitterSelector';
import { RECOMPUTE_GRID_SIZE } from '../../constants/events';
import addAvailableHoursForPeriod from '../data/assignedDataActions/addAvailableHoursForPeriod';
import addSchedulesWorkDays from '../data/assignedDataActions/addSchedulesWorkDays';
import addTimeOffs from '../data/assignedDataActions/addTimeOffs';
import changeAllNodeArrowState from '../data/nodeItemActions/changeAllNodeArrowState';
import changePeriodStartDate from '../dateRange/changePeriodStartDate';
import { resetUsersData } from '../data/assignedDataActions/commonActionGroups/resetUserData';
import setHeightForPeopleWorkloadEmptyRow from '../data/assignedDataActions/setHeightForPeopleWorkloadEmptyRow';
import { getUserTasks } from '../data/selectors/reselect/getUserTasks';
import { peopleWorkLoadHeightSelector } from '../tableSizes/selectors/tableSizesSelectors';

export function loadMissingDataForAssignedSectionThunk(
    loadedUsersIDs: string[] = []
): TWorkSchedulingThunkAction<Promise<void>> {
    return function _loadMissingDataForAssignedSectionThunk(dispatch, getState) {
        const state = getState();
        const usersIDs: string[] = loadedUsersIDs.length
            ? loadedUsersIDs
            : selectUsersIDsForRenderedSection(state);

        if (!usersIDs.length) {
            return Promise.resolve().then(() => {
                dispatch(toggleLoading(Sections.PEOPLE_WORKLOAD, false));
            });
        }

        const periodStart = state.DateRange.startDate;
        const scheduleIDs = getScheduleIDs(
            usersSelector(state),
            usersIDs,
            selectDefaultScheduleID(state)
        );

        dispatch(changeUsersDataRequestsState(usersIDs, true, false));

        return Promise.all([
            dispatch(loadUsersAssignments(usersIDs, null)),
            dispatch(loadTimeOffs(scheduleIDs, usersIDs)),
        ]).then(() => {
            const chainedActions: any = [
                removeRow('_loading', ''),
                toggleLoading(Sections.PEOPLE_WORKLOAD, false),
                changeUsersDataRequestsState(usersIDs, true, true),
            ];

            // this check periodStart === getState().DateRange.startDate prevent duplications of result when quick clicking on next/prev button
            if (periodStart === getState().DateRange.startDate) {
                if (projectGroupingModeSelector(state) && !loadedUsersIDs.length) {
                    return dispatch(loadAssignedSectionProjectsDetailsThunk(usersIDs)).then(() => {
                        dispatch(actionChain(chainedActions));
                    });
                }
                const expandedUsersIDs: string[] = selectExpandedUsersIDs(state)(usersIDs);

                expandedUsersIDs.forEach((userID) => {
                    const newState = getState();
                    const userData = userByIdSelector(newState, { userID });
                    const offset = getOffset(userData.offset, LOAD_TASKS_ISSUES_LIMIT);
                    const schedulingAreaData = getSchedulingAreaData(newState);
                    const startDateArg = showActualProgressSelector(newState)
                        ? startDateSelector(newState)
                        : undefined;

                    const taskIssueIDs = getUserNodesIDs(
                        userData.nodes,
                        schedulingAreaData,
                        tasksFromAssignmentsSelector(newState, { userID }),
                        startDateArg
                    );
                    chainedActions.push(
                        changeUserNodesOffset(userID, offset),
                        addTableDataIDs(taskIssueIDs.slice(0, offset), false, {
                            idExpression: userID,
                            showMore: taskIssueIDs.length > offset,
                        })
                    );
                });
                dispatch(actionChain(chainedActions));
            }
        });
    };
}

export function loadTimeOffs(scheduleIDs: Set<string>, usersIDs: string[]) {
    return function _loadTimeOffs(dispatch, getState) {
        const state = getState();
        const { startDate } = state.DateRange;
        const endDate = endDateSelector(state);
        const periodStart = state.DateRange.startDate;

        return APIService.loadTimeOffsWorkingHours(usersIDs, startDate, endDate, scheduleIDs).then(
            ([
                usersTimeOffs,
                availableHoursForPeriod,
                scheduleTimeZonedData, // scheduleNonWorkingDays
            ]) => {
                // this check periodStart === getState().DateRange.startDate prevent duplications of result when quick clicking on next/prev button
                if (periodStart === getState().DateRange.startDate) {
                    const chainedActions: any[] = [
                        addAvailableHoursForPeriod(availableHoursForPeriod.result),
                        addSchedulesWorkDays(scheduleTimeZonedData.result),
                        addTimeOffs(usersTimeOffs, scheduleTimeZonedData.result, usersIDs),
                    ];

                    dispatch(actionChain(chainedActions));
                }
            }
        );
    };
}

interface ITaskOrIssueOnTopIDs {
    taskOrIssueID?: string;
    taskOrIssueOnTopProjectId?: string;
}

interface ILoadAssignedSectionProjectsDetailsThunkConfig {
    isUserClicked?: boolean;
    showLoading?: boolean;
    loadGroupRelatedEnums?: boolean;
    taskOrIssueOnTopIDs?: ITaskOrIssueOnTopIDs;
    isForAll?: boolean;
    projectGroupingMode?: boolean;
}

export function loadAssignedSectionProjectsDetailsThunk(
    usersIDs: TUserID[],
    config: ILoadAssignedSectionProjectsDetailsThunkConfig = {}
): TWorkSchedulingThunkAction<Promise<void>> {
    const {
        isUserClicked = false,
        showLoading = true,
        loadGroupRelatedEnums = false,
        taskOrIssueOnTopIDs = {},
        isForAll = false,
    } = config;
    const { taskOrIssueID, taskOrIssueOnTopProjectId } = taskOrIssueOnTopIDs;

    return function _loadAssignedSectionProjectsDetailsThunk(dispatch, getState) {
        const state = getState();
        const expandedUsersIDs = isUserClicked ? usersIDs : selectExpandedUsersIDs(state)(usersIDs);
        const { showActualProgress, projectsSortingCriteria } = state.SettingsState;

        if (!expandedUsersIDs.length) {
            return Promise.resolve();
        }

        const usersProjectIDs: string[][] = [];
        const periodStart = state.DateRange.startDate;
        const chainActionsBefore: any = [];

        expandedUsersIDs.forEach((ID) => {
            const userData = state.Data.users[ID];
            usersProjectIDs.push(userData.projectIDs);

            chainActionsBefore.push(changeProjectsDataRequestsState(ID, true, false));

            if (showLoading) {
                chainActionsBefore.push(addLoadingRow(Sections.PEOPLE_WORKLOAD, ID));
            }
        });

        dispatch(actionChain(chainActionsBefore));
        const chainedActions: any[] = [];
        return APIService.loadProjectsDetails(usersProjectIDs, projectsSortingCriteria)
            .then((usersProjectDetails: Array<{ result: IRequestedProjectData[] }>) => {
                const promises: Array<Promise<void>> = [];
                usersProjectDetails.forEach((projectDetails, index) => {
                    const loadedProjectIDs: TProjectID[] = [];
                    const loadedGroupData = {};
                    const projectGroupColors = projectGroupColorsSelector(state);
                    projectDetails.result.forEach((data) => {
                        if (loadGroupRelatedEnums) {
                            const groupIDAlreadyExists =
                                projectGroupColors && projectGroupColors[data.groupID];
                            if (!groupIDAlreadyExists && data.groupID) {
                                loadedGroupData[data.groupID] = data.groupID;
                            }
                        }
                        loadedProjectIDs.push(data.ID);
                    });

                    const promise: Promise<void> = APIService.getColorsByGroupIds(
                        Object.keys(loadedGroupData)
                    ).then((groupsColors) => {
                        if (loadGroupRelatedEnums) {
                            const groupsColorsRefactored = prepareColorsByProjectGroupData(
                                Object.keys(loadedGroupData),
                                groupsColors
                            );
                            chainedActions.push(addProjectGroupColors(groupsColorsRefactored));
                        }

                        const uID = expandedUsersIDs[index];
                        const stateAfterResponse = getState();

                        chainedActions.push(addProjectsDetails(projectDetails.result));

                        const projectNames: ITasksAndProjects['projects'] = {};
                        projectDetails.result.forEach((projectData) => {
                            projectNames[projectData.ID] = {
                                name: projectData.name,
                            };
                        });

                        let projectIDs = loadedProjectIDs;

                        if (!projectsSortingCriteria) {
                            const startDate = startDateSelector(stateAfterResponse);
                            const endDate = endDateSelector(stateAfterResponse);
                            const tasksAndProjects = {
                                tasks: tasksAndIssuesSelector(stateAfterResponse),
                                projects: projectNames,
                            };
                            const schedulingAreaData = getSchedulingAreaData(stateAfterResponse);

                            projectIDs = sortProjectsByTasksDetails(
                                loadedProjectIDs,
                                stateAfterResponse.Data.users,
                                uID,
                                tasksAndProjects,
                                { startDate, endDate },
                                showActualProgress,
                                schedulingAreaData
                            );
                            if (taskOrIssueOnTopProjectId) {
                                projectIDs.sort(sortByProjectID(taskOrIssueOnTopProjectId));
                            }
                        }

                        chainedActions.push(
                            changeProjectIDsSorting(projectIDs, uID),
                            changeProjectsDataRequestsState(uID, true, true)
                        );

                        if (
                            getUserSelector(stateAfterResponse)(uID).expanded &&
                            periodStart === stateAfterResponse.DateRange.startDate
                        ) {
                            const userData = getUserSelector(stateAfterResponse)(uID);
                            const insideNodes = projectGroupingModeSelector(state)
                                ? userData.nodes
                                : {};

                            const projectsOffset = getOffset(userData.offset, LOAD_PROJECTS_LIMIT);
                            const projectIDsToLoad = projectIDs.slice(0, projectsOffset);
                            chainedActions.push(
                                changeUserNodesOffset(uID, projectsOffset),
                                removeTableDataIDs(`${uID}_`),
                                addTableDataIDs(projectIDsToLoad, true, {
                                    idExpression: uID,
                                    showMore: projectsOffset < loadedProjectIDs.length,
                                })
                            );

                            if (taskOrIssueOnTopProjectId) {
                                // expanding project on drop

                                const nodesIDs = userProjectNodesByIdSelector(state, {
                                    userID: uID,
                                    projectID: taskOrIssueOnTopProjectId,
                                });
                                // sorting that assigned task be on top of expanded project
                                nodesIDs.sort(sortByID(nodesIDs, taskOrIssueID));

                                chainedActions.push(
                                    changeNodeArrowState(uID, taskOrIssueOnTopProjectId),
                                    addTableDataIDs(
                                        nodesIDs.slice(0, LOAD_TASKS_ISSUES_LIMIT),
                                        true,
                                        {
                                            idExpression: `${uID}_${taskOrIssueOnTopProjectId}`,
                                            showMore: nodesIDs.length > LOAD_TASKS_ISSUES_LIMIT,
                                        }
                                    )
                                );
                            }
                            Object.keys(insideNodes).forEach((insideNodeKey) => {
                                const node = insideNodes[insideNodeKey];

                                if (
                                    userData.expandedProjectIDs.has(insideNodeKey) &&
                                    node.nodes.length
                                ) {
                                    if (projectIDsToLoad.includes(insideNodeKey)) {
                                        const offset = getOffset(
                                            node.offset,
                                            LOAD_TASKS_ISSUES_LIMIT
                                        );
                                        chainedActions.push(
                                            addTableDataIDs(node.nodes.slice(0, offset), true, {
                                                idExpression: `${userData.ID}_${insideNodeKey}`,
                                                showMore: node.nodes.length > offset,
                                            }),
                                            changeUserNodesOffset(`${uID}_${insideNodeKey}`, offset)
                                        );
                                    } else {
                                        chainedActions.push(
                                            changeNodeArrowState(uID, insideNodeKey)
                                        );
                                    }
                                }
                            });

                            const projectColorMode = projectColorsModeSelector(state);
                            const colorModeProject = isColorModeProject(projectColorMode);

                            if (colorModeProject && loadedProjectIDs.length) {
                                const colorsByProjectID =
                                    prepareColorsByProjectData(loadedProjectIDs);
                                chainedActions.push(addProjectColors(colorsByProjectID));
                            }

                            chainedActions.push(
                                removeRow(`${uID}_loading`, Sections.PEOPLE_WORKLOAD)
                            );
                        }

                        internalEventEmitterSelector(stateAfterResponse).emit(RECOMPUTE_GRID_SIZE);
                    });

                    promises.push(promise);
                });

                return Promise.all(promises);
            })
            .then(() => {
                dispatch(actionChain(chainedActions));
                if (isForAll) {
                    dispatch(openProjectTasks(usersIDs));
                }
            });
    };
}

export function loadAssignedSectionMonthPeriodModeToggleThunk(usersIDs: string[], stepUnit) {
    return function _loadAssignedSectionMonthPeriodModeToggleThunk(dispatch, getState) {
        const oldState = getState();
        const expandedUsersIDs = selectExpandedUsersIDs(oldState)(usersIDs);
        const userDataBeforeNewDataIsGet = getUserSelector(oldState);
        const actions = [changePeriodStartDate(stepUnit), ...resetUsersData()];
        dispatch(actionChain(actions));

        return dispatch(loadMissingDataForAssignedSectionThunk(usersIDs)).then(() => {
            const state = getState();
            const projectGroupingMode = projectGroupingModeSelector(state);

            if (expandedUsersIDs.length && projectGroupingMode) {
                const projectsSortingCriteria = projectsSortingCriteriaSelector(state);
                const users = usersSelector(state);
                const usersProjectIDs: string[][] = [];
                const chainActionsBefore: any = [];
                expandedUsersIDs.forEach((ID) => {
                    const userData = users[ID];
                    usersProjectIDs.push(userData.projectIDs);
                    chainActionsBefore.push(
                        changeProjectsDataRequestsState(ID, true, false),
                        addLoadingRow(Sections.PEOPLE_WORKLOAD, ID)
                    );
                });
                dispatch(actionChain(chainActionsBefore));

                APIService.loadProjectsDetails(usersProjectIDs, projectsSortingCriteria).then(
                    (usersProjectDetails: Array<{ result: IRequestedProjectData[] }>) => {
                        const actionsChain: any = [];
                        usersProjectDetails.forEach((projectDetail, index) => {
                            const loadedProjectIDs: TProjectID[] = projectDetail.result.map(
                                (data) => data.ID
                            );
                            const uID = expandedUsersIDs[index];

                            const userData = userDataBeforeNewDataIsGet(uID);
                            const projectsOffset = getOffset(userData.offset, LOAD_PROJECTS_LIMIT);
                            const projectIDsToLoad = loadedProjectIDs.slice(0, projectsOffset);
                            actionsChain.push(
                                addProjectsDetails(projectDetail.result),
                                changeProjectIDsSorting(loadedProjectIDs, uID),
                                changeProjectsDataRequestsState(uID, true, true),
                                changeUserNodesOffset(uID, projectsOffset),
                                removeTableDataIDs(`${uID}_`),
                                addTableDataIDs(projectIDsToLoad, true, {
                                    idExpression: uID,
                                    showMore: projectsOffset < loadedProjectIDs.length,
                                })
                            );

                            const expandTasks = (): void => {
                                const getNodeIDsLimited = (nodesIDs: string[]): string[] => {
                                    return nodesIDs.slice(0, LOAD_TASKS_ISSUES_LIMIT);
                                };

                                const taskAndIssues = tasksAndIssuesSelector(state);
                                const taskAndIssuesProjectIds = Object.values(taskAndIssues).map(
                                    (taskAndIssue) => {
                                        return taskAndIssue.projectID;
                                    }
                                );
                                const { expandedProjectIDs } = userData;
                                const newUserDataSelector = getUserSelector(getState());
                                const newUserData = newUserDataSelector(uID);

                                expandedProjectIDs.forEach((projectId) => {
                                    if (taskAndIssuesProjectIds.includes(projectId)) {
                                        const idExpression = `${uID}_${projectId}`;
                                        const nodesIDs = newUserData.nodes[projectId].nodes;
                                        actionsChain.push(
                                            changeNodeArrowState(uID, projectId),
                                            changeUserNodesOffset(
                                                idExpression,
                                                LOAD_TASKS_ISSUES_LIMIT
                                            ),
                                            addTableDataIDs(getNodeIDsLimited(nodesIDs), true, {
                                                idExpression,
                                                showMore: nodesIDs.length > LOAD_TASKS_ISSUES_LIMIT,
                                            })
                                        );
                                    }
                                });
                            };

                            expandTasks();
                        });

                        actionsChain.push(
                            changeAllNodeArrowState(false, expandedUsersIDs),
                            toggleLoading(Sections.PEOPLE_WORKLOAD, false),
                            changeUsersDataRequestsState(usersIDs, true, true),
                            setHeightForPeopleWorkloadEmptyRow(
                                peopleWorkLoadHeightSelector(state),
                                showActualProgressSelector(state),
                                projectGroupingMode
                            )
                        );
                        dispatch(actionChain(actionsChain));
                    }
                );
            }
            if (expandedUsersIDs.length && !projectGroupingMode) {
                const expandUsersWhenProjectGroupingIsOff = (): void => {
                    const users = usersSelector(state);
                    const chainedActions: any[] = [];

                    const isActualProgressBarEnabledInSettings = showActualProgressSelector(state);
                    const schedulingAreaData = getSchedulingAreaData(state);
                    const startDate = startDateSelector(state);
                    expandedUsersIDs.forEach((ID) => {
                        const userData = users[ID];
                        const offset = getOffset(userData.offset, LOAD_TASKS_ISSUES_LIMIT);
                        const userTasks = getUserTasks(state)(ID);
                        const startDateArg = isActualProgressBarEnabledInSettings
                            ? startDate
                            : undefined;
                        const tasksIssuesIDs = getUserNodesIDs(
                            userData.nodes,
                            schedulingAreaData,
                            userTasks,
                            startDateArg
                        );

                        chainedActions.push(
                            changeNodeArrowState(ID, undefined),
                            changeUserNodesOffset(userData.ID, offset),
                            addTableDataIDs(tasksIssuesIDs.slice(0, offset), projectGroupingMode, {
                                idExpression: userData.ID,
                                showMore: tasksIssuesIDs.length > offset,
                            })
                        );
                    });
                    dispatch(actionChain(chainedActions));
                };
                expandUsersWhenProjectGroupingIsOff();
            }
        });
    };
}
