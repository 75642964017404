import { css } from 'emotion';

export const filterButtonPosition = css`
    position: absolute;
    right: 8px;
    height: 100%;
`;

export const centerFilterButton = css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
