import { TModernSchedulingAction } from '../../types';
import { IContouringCellInfo, IDataState, TContouringRowID } from '../IDataState';

export const ActionType = 'CHANGE_CONTOURING_ROW_STATE';

interface IEditRowPayload {
    rowID: TContouringRowID;
    contouringCellInfo?: IContouringCellInfo;
}

type TEditRowAction = TModernSchedulingAction<IEditRowPayload>;

const changeContouringRowState = (rowID, contouringCellInfo?): TEditRowAction => ({
    type: ActionType,
    payload: {
        rowID,
        contouringCellInfo,
    },
});
export default changeContouringRowState;

export const clearContouringRowState = (): TEditRowAction => ({
    type: ActionType,
    payload: {
        rowID: null,
        contouringCellInfo: undefined,
    },
});

export function handle(state: IDataState, { payload }: TEditRowAction): IDataState {
    const { rowID, contouringCellInfo } = payload;

    return {
        ...state,
        contouringRowState: {
            contouringRowID: rowID,
            contouringCellInfo,
        },
    };
}
