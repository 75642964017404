import * as React from 'react';
import { connect } from 'react-redux';
import {
    INACCESSIBLE_DURATION_COLOR,
    INACCESSIBLE_GAPS_COLOR,
    INACCESSIBLE_TEXT_COLOR,
} from '../../../../constants/colors';
import {
    TWorkPerDays,
    IFullTimeOffSteps,
    IStartEndDates,
} from '../../../../data-flow/data/IDataState';
import { IWorkSchedulingCombinedState } from '../../../../data-flow/types';
import { localizationClient } from '../../../../constants/LocalizationClientFactory';
import {
    afterInaccessiblePeriodRedesign,
    afterInaccessibleProjectRedesign,
    beforeInaccessiblePeriodRedesign,
    beforeInaccessibleProjectRedesign,
} from '../../../styles/sharedStyles';
import { AssignmentDuration } from '../AssignmentDuration';
import {
    projectGroupingModeSelector,
    showActualProgressSelector,
    showAllocationsSelector,
} from '../../../../data-flow/settings/settingsSelector';
import {
    inaccessibleProjectsWorkDurationSelector,
    inaccessibleWorkDurationSelector,
} from '../../../../data-flow/data/selectors/durations/durationsSelector';
import { inaccessibleWorkPerDaysSelectorFromServiceForPeriod } from '../../../../data-flow/data/selectors/rereselect/inaccessibleWorkPerDaysSelectorFromServiceForPeriod';

interface InaccessibleWorkProps {
    IDs: string[];
    idExpression: string;
    fullTimeOffsStepsByDay: IFullTimeOffSteps;
    userScheduleID: string;
    userID: string;
}

interface InaccessibleWorkMapStateToProps {
    durations: IStartEndDates[];
    isInaccessibleProject: boolean;
    isCompleted: boolean;
    isShowAllocationOn: boolean;
    projectedDurations: IStartEndDates[];
    showActualProgress: boolean;
    workPerDays: number[] | undefined;
}

export const InaccessibleWorkComponent: React.FunctionComponent<
    InaccessibleWorkProps & InaccessibleWorkMapStateToProps
> = (props) => {
    let leftArrowCssClass;
    let rightArrowCssClass;
    let name;

    if (props.isInaccessibleProject) {
        leftArrowCssClass = beforeInaccessibleProjectRedesign;

        rightArrowCssClass = afterInaccessibleProjectRedesign;

        name = localizationClient.getTextSync(
            'resourcescheduling.inaccessible.project',
            'Inaccessible projects'
        );
    } else {
        leftArrowCssClass = beforeInaccessiblePeriodRedesign;

        rightArrowCssClass = afterInaccessiblePeriodRedesign;

        name = localizationClient.getTextSync(
            'resourcescheduling.inaccessible.work',
            'Inaccessible work items'
        );
    }

    return (
        <AssignmentDuration
            idExpression={props.idExpression}
            durations={props.durations}
            isProject={props.isInaccessibleProject}
            isAccessible={false}
            colorDark={INACCESSIBLE_DURATION_COLOR}
            colorLight={INACCESSIBLE_GAPS_COLOR}
            textColor={INACCESSIBLE_TEXT_COLOR}
            leftArrowCssClass={leftArrowCssClass}
            projectedDurations={props.projectedDurations}
            rightArrowCssClass={rightArrowCssClass}
            workPerDays={props.workPerDays}
            name={name}
            isAssigned
            fullTimeOffsStepsByDay={props.fullTimeOffsStepsByDay}
            userScheduleID={props.userScheduleID}
            showActualProgress={props.showActualProgress}
        />
    );
};

function mapStateToProps(
    state: IWorkSchedulingCombinedState,
    ownProps
): InaccessibleWorkMapStateToProps {
    let durationData;
    let isInaccessibleProject = false;

    const isShowAllocationOn = showAllocationsSelector(state);
    let workPerDays: TWorkPerDays = [];

    if (projectGroupingModeSelector(state) && ownProps.IDs.length === 2) {
        isInaccessibleProject = true;
        durationData = inaccessibleProjectsWorkDurationSelector(state, {
            userID: ownProps.IDs[0],
        });
    } else {
        durationData = inaccessibleWorkDurationSelector(state, {
            userID: ownProps.IDs[0],
            projectID: projectGroupingModeSelector(state) && ownProps.IDs[1],
        });
    }

    if (ownProps.IDs.length === 2) {
        if (isShowAllocationOn) {
            workPerDays = inaccessibleWorkPerDaysSelectorFromServiceForPeriod(state, {
                userID: ownProps.IDs[0],
            });
        }
    } else {
        workPerDays = inaccessibleWorkPerDaysSelectorFromServiceForPeriod(state, {
            userID: ownProps.IDs[0],
            projectID: ownProps.IDs[1],
        });
    }

    return {
        durations: durationData.durations,
        isInaccessibleProject,
        isCompleted: durationData.isCompleted,
        isShowAllocationOn,
        projectedDurations: durationData.projectedDurations,
        showActualProgress: showActualProgressSelector(state),
        workPerDays,
    };
}

export const InaccessibleAssignment = connect(mapStateToProps)(InaccessibleWorkComponent);
