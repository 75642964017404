import { Action } from 'redux';
import {
    IModernSchedulingActionDefinition,
    TModernSchedulingAction,
    TModernSchedulingActionHandler,
} from './types';

function defaultActionHandler<S>(state: S, action: TModernSchedulingAction<any>): S {
    return {
        ...(state as any),
        ...action.payload,
    };
}

export function createReducer<S>(
    syncActions: Array<IModernSchedulingActionDefinition<S>>,
    initialState: S
) {
    const actionHandlers = new Map<string, TModernSchedulingActionHandler<any>>();

    function isRegisteredAction(action: Action): action is TModernSchedulingAction<any> {
        return actionHandlers.has(action.type);
    }

    syncActions.forEach(({ ActionType, handle }) => {
        if (actionHandlers.has(ActionType)) {
            throw new Error(
                `Action with type ${ActionType} is already registered. Consider choosing a different type for your action.`
            );
        }
        actionHandlers.set(ActionType, handle || defaultActionHandler);
    });

    return function (state: S | undefined, action: Action): S {
        if (state === undefined) {
            state = initialState;
        }
        if (isRegisteredAction(action)) {
            return actionHandlers.get(action.type)!(state, action);
        }
        // else {
        // 	throw new Error(`Reducer for ${action.type} is not registered`)
        // }
        return state;
    };
}
