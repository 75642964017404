import { getStorageUtil } from '@workfront/storage';
import moment from 'moment';
import { DATE_FORMAT_TIMELINE } from '../../constants/dataConstatnts';
import { TimeUnit, WEEKS } from '../../constants/periodEnums';
import { IDateRangeState } from './IDateRangeState';

export function getInitialDateRangeState(options?: any): IDateRangeState {
    let startDate;
    const storageUtil = getStorageUtil();

    if (options && options.startDate) {
        // this case is intended for integration test running
        startDate = options.startDate;
    } else {
        const startDateFromStorage = storageUtil.get('scheduling.timelineStartDate');
        startDate = startDateFromStorage
            ? moment(startDateFromStorage, DATE_FORMAT_TIMELINE)
            : moment().startOf('week');
    }
    return {
        periods: [],
        steps: [],
        stepUnit: TimeUnit.DAY,
        periodMode: WEEKS.W_4,
        periodTimeUnit: TimeUnit.WEEK,
        startDate: startDate.startOf('day'),
        isTodayActive: moment(startDate).isSame(moment().startOf('week')),
    };
}
