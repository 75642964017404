import { primary } from '@phoenix/all';
import React from 'react';
import appColors from '../../../../../constants/appColors';
import {
    getIsOverAllocatedGradientStart,
    getStrokeColor,
    getX1,
    getX2,
    getY1,
    isFirstLineAndHasOverAllocation,
} from './userFocusLineUtils';

const strokeWidth = 1;
const lineStyle = (stroke) => ({
    stroke,
    strokeWidth: `${strokeWidth}px`,
    fill: primary.white(),
});

interface IUserFocusLineProps extends React.HTMLAttributes<HTMLDivElement> {
    end: Array<number | string>;
    isOverAllocated?: boolean;
    previousStatus?: string;
    prevIsOverAllocated?: boolean;
    requiredLineColor?: string;
    skipLeftLine?: boolean;
    start: Array<number | string>;
    uniqueId: string | number;
}

export const testIds = {
    svgWrapper: 'line-svg-wrapper',
    linearGradient: 'line-linear-gradient',
    line: 'line-line',
};

const UserFocusLine: React.FunctionComponent<IUserFocusLineProps> = ({
    end,
    isOverAllocated = false,
    previousStatus,
    prevIsOverAllocated = false,
    requiredLineColor,
    skipLeftLine = false,
    start,
    uniqueId,
}) => {
    const points = [
        [start, [start[0], end[1]]],
        [[start[0], end[1]], end],
    ];

    let strokeColor = getStrokeColor(isOverAllocated);

    return (
        <>
            {points.map((point, i) => {
                let component;

                // these all corrections because of incorrectly line positioning.
                if (!(skipLeftLine && i === 0)) {
                    const commonPathProps = {
                        x1: getX1(point, i),
                        y1: getY1(point, i, previousStatus),
                        x2: getX2(point, i),
                        y2: point[1][1],
                    };
                    const firstLineAndHasOverAllocation = isFirstLineAndHasOverAllocation(
                        i,
                        isOverAllocated,
                        prevIsOverAllocated
                    );
                    strokeColor = requiredLineColor || strokeColor;

                    if (firstLineAndHasOverAllocation) {
                        let gradientStart = requiredLineColor;
                        let gradientMid = requiredLineColor;

                        if (!requiredLineColor) {
                            gradientStart = getIsOverAllocatedGradientStart(
                                isOverAllocated,
                                prevIsOverAllocated
                            );

                            gradientMid = appColors.CB6C79.hex;
                        }

                        const gradId = `${uniqueId}`;

                        component = (
                            <svg data-testid={testIds.svgWrapper} key={point.join()}>
                                <defs>
                                    <linearGradient
                                        {...commonPathProps}
                                        data-testid={testIds.linearGradient}
                                        gradientUnits="userSpaceOnUse"
                                        id={gradId}
                                    >
                                        <stop offset="0%" stopColor={gradientStart} />
                                        <stop offset="38%" stopColor={gradientMid} />
                                        <stop offset="100%" stopColor={strokeColor} />
                                    </linearGradient>
                                </defs>

                                <line
                                    {...commonPathProps}
                                    data-testid={testIds.line}
                                    stroke={`url(#${gradId})`}
                                    strokeWidth={strokeWidth}
                                />
                            </svg>
                        );
                    } else {
                        component = (
                            <svg key={point.join()}>
                                <line
                                    {...commonPathProps}
                                    data-testid={testIds.line}
                                    style={lineStyle(strokeColor)}
                                />
                            </svg>
                        );
                    }
                } else {
                    return null;
                }

                return component;
            })}
        </>
    );
};

export default UserFocusLine;
