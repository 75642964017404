import { ITableSizesState } from './ITableSizesState';
import { getLeftSidePanelWidthToLocalStorage } from '../../util/localStorage';
import { sizes } from '../../constants/schedulingTableConstants';

export function getInitialTableSizesState(/* options?: IConfigureStoreOptions */): ITableSizesState {
    return {
        cellWidth: 0,
        tableWidth: 0,
        unassignedWorkHeight: 0,
        peopleWorkLoadHeight: 0,
        availableHeight: 0,
        isDragging: false,
        snapToZero: false,
        isTableCollapsed: true,
        resizableHeight: '',
        tableLeftSidePanelWidth:
            getLeftSidePanelWidthToLocalStorage() || sizes.tableLeftColumnWidth,
    };
}
