import { TModernSchedulingAction } from 'src/data-flow/types';
import { ISettingsState } from '../ISettingsState';

export const ActionType = 'SET_SETTING';

interface ISetProjectSettings {
    settingName: string;
    hardCodeValue: boolean | undefined;
}

type TSetProjectSettings = TModernSchedulingAction<ISetProjectSettings>;

const toggleSetting = (settingName: string, hardCodeValue?: boolean): TSetProjectSettings => ({
    type: ActionType,
    payload: { settingName, hardCodeValue },
});
export default toggleSetting;

export function handle(state: ISettingsState, action): ISettingsState {
    const { settingName, hardCodeValue } = action.payload;
    const newValue = typeof hardCodeValue === 'undefined' ? !state[settingName] : hardCodeValue;

    return {
        ...state,
        [settingName]: newValue,
    };
}

export const settingNames = {
    showActualProgress: 'showActualProgress',
    showIssues: 'showIssues',
    showCompletedWork: 'showCompletedWork',
    projectGroupingMode: 'projectGroupingMode',
    showAllocationsVisualization: 'showAllocationsVisualization',
    showRoleSummary: 'showRoleSummary',
    isPanelOpened: 'isPanelOpened',
    isBulkAssignmentPanelOpened: 'isBulkAssignmentPanelOpened',
    sortUsersByRoleEnabled: 'sortUsersByRoleEnabled',
    projectsSortingCriteria: 'projectsSortingCriteria',
};
