import * as React from 'react';
import { connect } from 'react-redux';
import { Task, TObjCode } from 'workfront-objcodes';
import { Sections, taskPaddings } from '../../../../constants/schedulingTableConstants';
import {
    IObjectState,
    IProjectColor,
    TContouringRowID,
    TDate,
} from '../../../../data-flow/data/IDataState';
import { contouringRowIdSelector } from '../../../../data-flow/data/selectors/dataSelectors';
import { IWorkSchedulingCombinedState } from '../../../../data-flow/types';
import { checkShowContouring } from '../../../../util/actionsComponentService';
import { localizationClient } from '../../../../constants/LocalizationClientFactory';
import { TaskIssueNodeHTML } from '../TaskIssueNodeHTML';
import { projectGroupingModeSelector } from '../../../../data-flow/settings/settingsSelector';
import { isLastTaskRowSelector } from '../../../../data-flow/data/selectors/reselect/isLastTaskRowSelector/isLastTaskRowSelector';
import { isLastRowUnderUserSelector } from '../../../../data-flow/data/selectors/reselect/isLastRowUnderUserSelector';
import { getTaskSelector } from '../../../../data-flow/data/selectors/reselect/getTaskSelector/getTaskSelector';
import { inaccessibleItemsWorkRequiredSelector } from '../../../../data-flow/data/selectors/rereselect/inaccessibleItemsWorkRequiredSelector/inaccessibleItemsWorkRequiredSelector';
import { inaccessibleItemsWorkRequiredPerProjectSelector } from '../../../../data-flow/data/selectors/rereselect/workRequiredSelectors/workRequiredSelectors';
import { hasWorkRequiredEditAccessSelector } from '../../../../data-flow/currentUser/selectors/hasWorkRequiredEditAccessSelector';
import { projectColorDeciderSelector } from '../../../../data-flow/data/selectors/projectStatusSelectors/projectStatusSelectors';
import { isAssignmentContoured } from '../../../../data-flow/data/selectors/rereselect/isAssignmentContoured';

interface ITaskIssueNodeProps extends React.HTMLAttributes<HTMLDivElement> {
    idExpression: string;
}

interface ITaskIssueNodeState {
    name: string;
    ID: string;
    workRequired: number;
    isLastRow: boolean;
    isLastRowUnderUser: boolean;
    isInaccessible: boolean;
    projectGroupingMode: boolean;
    hasWorkRequiredEditAccess: boolean;
    assignAccess: boolean;
    contouringEditAccess: boolean;
    contouringRowID: TContouringRowID;
    objCode: TObjCode;
    plannedStartDate: TDate;
    plannedCompletionDate?: TDate;
    projectColoredData?: IProjectColor;
    isAccessible: boolean;
    isContoured: boolean;
}

function TaskIssueNodePeopleWorkloadSection(
    props: ITaskIssueNodeProps & ITaskIssueNodeState
): JSX.Element {
    const {
        assignAccess,
        hasWorkRequiredEditAccess,
        contouringEditAccess,
        contouringRowID,
        idExpression,
        projectColoredData,
        isAccessible,
        isContoured,
    } = props;
    const canEditContouring = checkShowContouring(
        assignAccess,
        Sections.PEOPLE_WORKLOAD,
        contouringEditAccess
    );

    return (
        <TaskIssueNodeHTML
            name={props.name}
            objCode={props.objCode}
            isLastRow={props.isLastRow}
            isLastRowUnderUser={props.isLastRowUnderUser}
            projectGroupingMode={props.projectGroupingMode}
            isInaccessible={props.isInaccessible}
            workRequired={props.workRequired}
            ID={props.ID}
            paddingLeft={
                !props.projectGroupingMode
                    ? taskPaddings.peopleWorkloadSectionPGOFF
                    : taskPaddings.peopleWorkloadSectionPGOn
            }
            sectionType={Sections.PEOPLE_WORKLOAD}
            showUnlockIcon={
                canEditContouring && hasWorkRequiredEditAccess && contouringRowID === idExpression
            }
            assignAccess={assignAccess}
            idExpression={idExpression}
            plannedStartDate={props.plannedStartDate}
            plannedCompletionDate={props.plannedCompletionDate}
            projectColoredData={projectColoredData}
            isAccessible={isAccessible}
            isAssignedTaskOrIssue
            isContoured={isContoured}
        />
    );
}
const emptyProjectData = {};
function mapStateToProps(state: IWorkSchedulingCombinedState, ownProps): ITaskIssueNodeState {
    let name: string;
    let workRequired: number;
    let ID: string;
    let isInaccessible: boolean;
    let hasWorkRequiredEditAccess: boolean;
    let assignAccess: boolean;
    let objCode: TObjCode;
    let plannedStartDate;
    let plannedCompletionDate;
    let isContoured: boolean = false;

    const idsArray = ownProps.idExpression.split('_');
    const userID = idsArray[0];
    const isLastTaskRow = isLastTaskRowSelector(state)(ownProps.idExpression);
    const isLastRowUnderUser = isLastRowUnderUserSelector(state)(ownProps.idExpression);
    const projectData = state.Data.projects[ownProps.projectID] || emptyProjectData;

    if (idsArray.indexOf('showInaccessible') !== -1) {
        name = localizationClient.getTextSync(
            'resourcescheduling.inaccessible.work',
            'Inaccessible work items'
        );
        ID = ownProps.idExpression;
        workRequired = projectGroupingModeSelector(state)
            ? inaccessibleItemsWorkRequiredPerProjectSelector(state, {
                  userID,
                  projectID: idsArray[1],
              })
            : inaccessibleItemsWorkRequiredSelector(state, { userID });
        isInaccessible = true;
        hasWorkRequiredEditAccess = false;
        assignAccess = false;
        objCode = Task;
    } else {
        ID = projectGroupingModeSelector(state) ? idsArray[2] : idsArray[1];
        const objectData: IObjectState = getTaskSelector(state)(ID);
        name = objectData.name;
        workRequired = objectData.workRequired[userID];
        isInaccessible = false;
        hasWorkRequiredEditAccess = hasWorkRequiredEditAccessSelector(state, objectData);
        assignAccess = objectData.assignAccess;
        objCode = objectData.objCode;
        plannedStartDate = objectData.plannedStartDate;
        plannedCompletionDate = objectData.plannedCompletionDate;
        isContoured = isAssignmentContoured(state, ID, userID);
    }

    return {
        name,
        objCode,
        ID,
        isLastRow: isLastTaskRow,
        isLastRowUnderUser,
        workRequired,
        isInaccessible,
        projectGroupingMode: projectGroupingModeSelector(state),
        hasWorkRequiredEditAccess,
        assignAccess,
        contouringEditAccess: state.currentUser.contouringEditAccess,
        contouringRowID: contouringRowIdSelector(state),
        plannedStartDate,
        plannedCompletionDate,
        projectColoredData: projectColorDeciderSelector(
            state,
            projectData.ID,
            projectData.groupID,
            projectData.status
        ),
        isAccessible: !!projectData.isAccessible,
        isContoured,
    };
}

export const TaskIssueNode = connect(mapStateToProps)(TaskIssueNodePeopleWorkloadSection);
