import { Button, DateRangePicker, HandleClickOutside, Tooltip } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import CalendarIcon from 'phoenix-icons/dist/CalendarIcon.js';
import DownCaratIcon from 'phoenix-icons/dist/DownCaratIcon.js';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { DATE_FORMAT_TIMELINE } from '../../../../constants/dataConstatnts';
import {
    MONTH_COUNT_IN_PERIOD,
    TimelineActions,
    TimeUnit,
    WEEKS,
} from '../../../../constants/periodEnums';
import { endDateSelector } from '../../../../data-flow/dateRange/selectors/endDateSelector';
import { IWorkSchedulingDispatchProp, UnitOfTime } from '../../../../data-flow/types';
import {
    dateRangePickerClosedStyles,
    dateRangePickerOpenedStyles,
    hideOnSmallScreen,
    timelineDateRangePickerButtonStyles,
    timelineDateRangePickerCaratStyles,
    timelineDateRangePickerIconStyles,
    timelineDateRangePickerStyles,
    timelineStyles,
} from '../../../styles/timelineStyles';
import { stepUnitSelector } from '../../../../data-flow/dateRange/selectors/stepUnitSelector';
import { getFormattedDateRange } from '../../../../util/utilities';
import { getPeriodModesValues } from '../../../../util/periodUtils/periodUtils';
import { getSchedulingAreaData } from '../../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { getContextValue } from '../../../../contexts/checkContext';
import { IGeneralStateTypes } from '../../../../data-flow/IGeneralStateTypes';
import { periodTimeUnitSelector } from '../../../../data-flow/dateRange/selectors/periodTimeUnitSelector';

type ITimelineDateRangePickerStateProps = Pick<
    IGeneralStateTypes,
    'periodMode' | 'endDate' | 'startDate' | 'stepUnit' | 'schedulingAreaObjCode' | 'periodTimeUnit'
>;

interface ITimelineDateRangePickerProps {
    handleTimelineNavigation;
}

type TTimelineProps = ITimelineDateRangePickerStateProps &
    ITimelineDateRangePickerProps &
    IWorkSchedulingDispatchProp;

interface ITimelineState {
    isPickerOpen: boolean;
    isLargeScreen: boolean;
}

export const thresholdForLargeScreen = 1460;
export const showPassiveCalendar = (periodMode: WEEKS, stepUnit: UnitOfTime): boolean => {
    return periodMode === WEEKS.W_12 && stepUnit !== TimeUnit.MONTH;
};

export const getMonthOptionValue = (stepUnit: UnitOfTime): boolean => {
    const { isMonthMode } = getPeriodModesValues(stepUnit);
    return isMonthMode;
};

export const customOptionVisible = (timeUnit: TimeUnit, periodMode: number): boolean =>
    timeUnit === TimeUnit.WEEK && periodMode === WEEKS.W_12;

export const getCalendarDetails = (
    stepUnit: UnitOfTime,
    periodMode: number
): {
    mode: string;
    monthRange: number | null;
} => {
    const showMonthOption = getMonthOptionValue(stepUnit);
    return {
        mode: showMonthOption ? `MODE_MONTH` : `MODE_WEEK${periodMode}`,
        monthRange: showMonthOption ? MONTH_COUNT_IN_PERIOD : null,
    };
};

export class TimelineDateRangePickerComponent extends React.PureComponent<
    TTimelineProps,
    ITimelineState
> {
    private buttonRef = React.createRef<HTMLButtonElement>();

    constructor(props) {
        super(props);
        this.state = {
            isPickerOpen: false,
            isLargeScreen: window.innerWidth >= thresholdForLargeScreen,
        };
    }

    componentDidMount(): void {
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize.bind(this));
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.onWindowResize.bind(this));
    }

    dateRangeHandler = (startDate): void => {
        if (this.props.startDate.isSame(startDate)) {
            return;
        }
        this.props.handleTimelineNavigation(TimelineActions.START_DATE_CHANGE, {
            isTodayActive: false,
            periodMode: this.props.periodMode,
            startDateProp: startDate,
            timeUnit: this.props.periodTimeUnit,
        });
        this.pickerToggle();
        this.focusToggleButton();
    };

    pickerToggle = (): void => {
        this.setState((state) => ({ isPickerOpen: !state.isPickerOpen }));
    };

    closePicker = (event): void => {
        if (
            event.type === 'keyup' ||
            (this.buttonRef.current && !this.buttonRef.current.contains(event.target))
        ) {
            this.setState({ isPickerOpen: false });
        }
        this.focusToggleButton();
    };

    focusToggleButton = (): void => {
        if (this.buttonRef.current) {
            this.buttonRef.current.focus();
        }
    };

    onWindowResize = (): void => {
        const width = window.innerWidth;
        const { isLargeScreen } = this.state;
        if (isLargeScreen && width < thresholdForLargeScreen) {
            this.setState({ isLargeScreen: false });
        } else if (!isLargeScreen && width >= thresholdForLargeScreen) {
            this.setState({ isLargeScreen: true });
        }
    };

    render(): JSX.Element {
        const { startDate, endDate, periodMode, stepUnit } = this.props;
        const { isPickerOpen, isLargeScreen } = this.state;
        const { mode, monthRange } = getCalendarDetails(stepUnit, periodMode);

        return (
            <div>
                <Localization<string[]> messageKeys={['workloadbalancer.select.calendar.date']}>
                    {({ _t }) => {
                        const buttonTextContent = getFormattedDateRange(
                            startDate,
                            endDate,
                            stepUnit
                        );
                        return (
                            <Tooltip
                                content={buttonTextContent}
                                className={timelineStyles}
                                keepTooltipHidden={isLargeScreen}
                            >
                                <Button
                                    className={[
                                        timelineDateRangePickerButtonStyles,
                                        isPickerOpen
                                            ? dateRangePickerOpenedStyles
                                            : dateRangePickerClosedStyles,
                                    ]}
                                    text
                                    aria-haspopup="true"
                                    aria-expanded={isPickerOpen}
                                    ref={this.buttonRef}
                                    onClick={this.pickerToggle}
                                    testID={`${
                                        !getContextValue('sharableLink')
                                            ? 'global_scheduling.date_range_picker.header'
                                            : 'global_scheduling.date_range_picker.header_url'
                                    }${this.props.schedulingAreaObjCode}`}
                                    aria-label={`${_t['workloadbalancer.select.calendar.date'](
                                        'Select calendar date, Currently selected value:'
                                    )} ${buttonTextContent}`}
                                >
                                    <span className={hideOnSmallScreen}>{buttonTextContent}</span>
                                    <CalendarIcon
                                        className={timelineDateRangePickerIconStyles}
                                        focusable="false"
                                    />
                                    <DownCaratIcon
                                        className={timelineDateRangePickerCaratStyles}
                                        focusable="false"
                                    />
                                </Button>
                            </Tooltip>
                        );
                    }}
                </Localization>

                {this.state.isPickerOpen && (
                    <HandleClickOutside onOutsideClick={this.closePicker} escapeListener>
                        {() => (
                            <div className={timelineDateRangePickerStyles}>
                                <DateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxValue={moment('1-1-2999', DATE_FORMAT_TIMELINE)}
                                    minValue={moment('1-1-1980', DATE_FORMAT_TIMELINE)}
                                    mode={mode}
                                    {...(monthRange && { monthRange })}
                                    onChange={(dateRange) =>
                                        this.dateRangeHandler(dateRange.startDate)
                                    }
                                    fromStartOfWeek
                                    testID="global_scheduling.date_range_picker"
                                    showPassiveCalendar={showPassiveCalendar(periodMode, stepUnit)}
                                />
                            </div>
                        )}
                    </HandleClickOutside>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state): ITimelineDateRangePickerStateProps => {
    return {
        periodMode: state.DateRange.periodMode,
        endDate: endDateSelector(state),
        startDate: state.DateRange.startDate,
        stepUnit: stepUnitSelector(state),
        schedulingAreaObjCode: getSchedulingAreaData(state).schedulingAreaObjCode,
        periodTimeUnit: periodTimeUnitSelector(state),
    };
};

export const TimelineDateRangePicker = connect(mapStateToProps)(TimelineDateRangePickerComponent);
