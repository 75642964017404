import createCachedSelector from 're-reselect';
import { IWorkSchedulingCombinedState } from '../../../types';
import { IProjectColor, TProjectID } from '../../../data/IDataState';

export const projectColorSelector = (
    state: IWorkSchedulingCombinedState,
    { projectID }: { projectID: TProjectID }
): IProjectColor => {
    return state.projectColors[projectID];
};

export const projectColorCachedSelector = createCachedSelector(
    [projectColorSelector],
    (projectColor: IProjectColor) => projectColor
)((state, { projectID }: { projectID: TProjectID }) => projectID);
