import { Sections } from '../../constants/schedulingTableConstants';

export const getOpenedDialogsDetails = (action, sectionType, dialogType) => {
    let type = 'Assigned';

    if (sectionType === Sections.UNASSIGNED_WORK) {
        type = 'Unassigned';
    }

    return `VB - People WB: NWE - ${action} ${dialogType} / ${type}`;
};

export const pendoAnalyticsTracker = (trackType, metadata = {}) => {
    if (window.pendo && window.pendo.track) {
        window.pendo.track(trackType, metadata);
    }
};

export const setNameForDoubleClickEventTracking = (action, isShowAllocationOn) => {
    return `VB - Global WB:'NWE - ${action} - Show Allocations ${
        isShowAllocationOn ? 'ON' : 'OFF'
    }`;
};

export const plnHoursChangeForPendoAnalytics = () => {
    return `VB - Global WB: 'NWE' - Contouring Save with Planned Hours Change`;
};
