import { getStorageUtil } from '@workfront/storage';
import moment from 'moment';
import { DATE_FORMAT_TIMELINE } from '../../../constants/dataConstatnts';
import {
    MONTH_COUNT_IN_PERIOD,
    TimelineActions,
    TimeUnit,
    WEEKS,
} from '../../../constants/periodEnums';
import { addSubtractDate } from '../../../util/utilities';
import { IDateRangeState, TDataRangeTimelineAction } from '../IDateRangeState';

export const ActionType = 'TIMELINE_NAVIGATION';

const timelineNavigation = (
    buttonType: TimelineActions,
    periodMode = 0,
    stDate = moment(),
    periodTimeUnit = TimeUnit.WEEK
): TDataRangeTimelineAction => ({
    type: ActionType,
    payload: {
        buttonType,
        periodMode,
        stDate,
        periodTimeUnit,
    },
});
export default timelineNavigation;

// TODO reUse also in unassigned parts
export function handle(
    state: IDateRangeState,
    { payload }: TDataRangeTimelineAction
): IDateRangeState {
    const { buttonType, periodMode, stDate, periodTimeUnit } = payload;
    const { startDate, stepUnit } = state;
    const storageUtil = getStorageUtil();

    if (buttonType === TimelineActions.INITIAL_SETUP) {
        return {
            ...state,
            startDate: startDate.startOf(periodTimeUnit),
            periodMode,
            periodTimeUnit,
        };
    }

    if (buttonType === TimelineActions.PERIOD_CHANGE) {
        if (
            periodMode === WEEKS.W_12 &&
            periodTimeUnit === TimeUnit.WEEK &&
            stepUnit === TimeUnit.MONTH
        ) {
            return {
                ...state,
                periodMode: MONTH_COUNT_IN_PERIOD,
                periodTimeUnit: TimeUnit.MONTH,
            };
        }

        return {
            ...state,
            periodMode,
            periodTimeUnit,
        };
    }

    let newStartDate;
    let newIsTodayActive;
    if (buttonType === TimelineActions.TODAY) {
        newStartDate = moment().startOf(
            stepUnit === TimeUnit.MONTH ? TimeUnit.MONTH : TimeUnit.WEEK
        );
        newIsTodayActive = true;
    } else {
        if (buttonType === TimelineActions.START_DATE_CHANGE) {
            newStartDate = stDate;
        } else {
            newStartDate = addSubtractDate(
                startDate,
                stepUnit,
                buttonType === TimelineActions.PREVIOUS
            );
        }
        const startUnit = stepUnit === TimeUnit.MONTH ? TimeUnit.MONTH : TimeUnit.WEEK;
        newIsTodayActive = moment(newStartDate).isSame(moment().startOf(startUnit));
    }
    storageUtil.set(
        'scheduling.timelineStartDate',
        moment(newStartDate).format(DATE_FORMAT_TIMELINE)
    );

    return {
        ...state,
        startDate: newStartDate,
        isTodayActive: newIsTodayActive,
    };
}
