import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { Text } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import {
    DragDropTypes,
    IDraggableAssignment,
} from '../../../dragAndDrop/dragAndDropConfigurations';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../data-flow/types';
import { IGeneralStateTypes, TShowProjectAndOrTasks } from '../../../data-flow/IGeneralStateTypes';
import {
    draggingFromAreaSelector,
    workPerDaysOnDragHoverSelector,
} from '../../../data-flow/data/selectors/dataSelectors';
import {
    TDraggingFromArea,
    TTemporaryWorkPerDays,
    TUserID,
} from '../../../data-flow/data/IDataState';
import {
    userAssignmentsSelector,
    userNameByIdSelector,
    userRoleIdSelector,
} from '../../../data-flow/data/selectors/users/usersSelector';
import { getSafeLabel } from '../../../util/utilities';
import { userDropOverlayBorderZIndex, userDropOverlayZIndex } from '../../../constants/zIndexes';
import appColors, { dragOverlayBackground } from '../../../constants/appColors';
import { handleOnDropThunk } from '../../../data-flow/thunks/handleOnDropThunk/handleOnDropThunk';
import { handleUserHoursUpdateOnHoverThunk } from '../../../data-flow/thunks/handleUserHoursUpdateOnHoverThunk/handleUserHoursUpdateOnHoverThunk';
import { sizes } from '../../../constants/schedulingTableConstants';

import removeFromHighlightingMode from '../../../data-flow/data/assignedDataActions/removeFromHighlightingMode';
import removeWorkPerDayHoursOnDragHover from '../../../data-flow/data/sharedActions/removeWorkPerDayHoursOnDragHover';
import { dropOverlayTextStyle } from '../UnassignedDropOverlay/UnassignedDropOverlayStyles';
import { getRoleByIdSelector } from '../../../data-flow/data/selectors/reselect/getRoleByIdSelector/getRoleByIdSelector';
import {
    cancelActionsOnHover,
    isUserAlreadyAssigned,
    isUserAlreadyHovered,
} from '../../../util/dragAndDropUtilities';
import { trackWorkSaved } from '../../../data-flow/thunks/cjaTracker';
import { internalEventEmitterSelector } from '../../../data-flow/instances/internalEventEmitterSelector';
import { CJA_ANALYIZING_TRACK } from '../../../constants/events';

interface IUserWrapperOnDragComponent {
    height: number;
    top: number | null;
    left?: number;
    showProjectAndOrTasks: TShowProjectAndOrTasks;
    userID: TUserID;
    withOverlay?: boolean;
    paddingFromTop?: number;
}

type IUserWrapperOnDragComponentStateToProps = Pick<
    IGeneralStateTypes,
    'draggingFromArea' | 'userName' | 'roleName' | 'userAssignments' | 'internalEventEmitter'
> & {
    workPerDaysOnDragHover: TTemporaryWorkPerDays;
};

const dataTestIDs = {
    user_drop_overlay: 'user_drop_overlay',
    user_drop_area: 'user_drop_area',
};

let dragHoverTimeout: NodeJS.Timeout | null = null;

export const UserWrapperOnDragInternal = (
    props: IUserWrapperOnDragComponent &
        IUserWrapperOnDragComponentStateToProps &
        IWorkSchedulingDispatchProp
): JSX.Element => {
    const {
        dispatch,
        userID,
        showProjectAndOrTasks,
        draggingFromArea,
        userName,
        withOverlay,
        roleName,
        height,
        top,
        left,
        paddingFromTop = 0,
        workPerDaysOnDragHover,
        userAssignments,
    } = props;

    const handleDrop = (assignmentItem: IDraggableAssignment): void => {
        dispatch(trackWorkSaved(assignmentItem.objCode, props.draggingFromArea));
        props.internalEventEmitter.emit(CJA_ANALYIZING_TRACK);
        dispatch(handleOnDropThunk(assignmentItem, userID, showProjectAndOrTasks));
    };

    const handleUserHoursUpdateOnHover = (assignmentItem: IDraggableAssignment): void => {
        const { draggedFromUserID, workPerDays, ID } = assignmentItem;
        if (
            cancelActionsOnHover(
                userID,
                draggedFromUserID,
                workPerDaysOnDragHover,
                workPerDays,
                dragHoverTimeout
            ) ||
            isUserAlreadyAssigned(Object.values(userAssignments), ID)
        ) {
            return;
        }

        dragHoverTimeout = setTimeout(() => {
            dispatch(handleUserHoursUpdateOnHoverThunk(assignmentItem, userID));
        }, 300);
    };

    const [{ isOver, canDrop, item }, dragRef] = useDrop({
        accept: DragDropTypes.ASSIGNMENT,
        drop: handleDrop,
        hover: handleUserHoursUpdateOnHover,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            item: monitor.getItem(),
        }),
    });

    useEffect(() => {
        if (!isOver && canDrop && dragHoverTimeout) {
            clearTimeout(dragHoverTimeout);
            dragHoverTimeout = null;
        }
    }, [isOver, canDrop]);

    useEffect(() => {
        if (!isOver && canDrop && isUserAlreadyHovered(workPerDaysOnDragHover, userID)) {
            dispatch(removeFromHighlightingMode([userID]));
            dispatch(removeWorkPerDayHoursOnDragHover());
        }
    }, [isOver, canDrop, workPerDaysOnDragHover, userID, dispatch]);

    const onDraggingArea =
        draggingFromArea &&
        isOver &&
        canDrop &&
        !isUserAlreadyAssigned(Object.values(userAssignments), item.ID);
    const safeUserAndRoleName = getSafeLabel(userName) + (roleName ? ` (${roleName})` : '');

    const component = (
        <>
            {withOverlay && onDraggingArea ? (
                <div
                    data-testid={`${dataTestIDs.user_drop_overlay}_${userID}`}
                    className={userOverlayStyle(
                        draggingFromArea,
                        height,
                        paddingFromTop,
                        top,
                        !!onDraggingArea
                    )}
                >
                    <Text.Large className={dropOverlayTextStyle}>
                        <Localization
                            messageKey="workloadbalancer.drop.here.to.assign.this.to"
                            fallback={`Drop here to assign this to ${safeUserAndRoleName}`}
                            args={{ '0': safeUserAndRoleName }}
                        />
                    </Text.Large>
                </div>
            ) : null}
            <div
                data-testid={`${dataTestIDs.user_drop_area}_${userID}`}
                ref={dragRef}
                className={userOverlayBorderStyle(draggingFromArea, height, top, !!onDraggingArea)}
            />
        </>
    );

    return (
        <div
            className={positioningWrapper}
            style={{ left: left! + sizes.gridLightGreyBorderWidth }}
        >
            {component}
        </div>
    );
};

export const positioningWrapper = css`
    position: absolute;
    top: 0;
    left: 0;
`;

const sharedStyle = css`
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const getOverlayBackgroundStyle = (onDraggingArea: boolean): string => {
    return onDraggingArea ? `background-color: ${dragOverlayBackground}` : '';
};

const userOverlayStyle = (
    draggingFromArea: TDraggingFromArea,
    height: number,
    paddingFromTop: number,
    top: number | null,
    onDraggingArea: boolean
): string => css`
    z-index: ${draggingFromArea ? userDropOverlayZIndex : -1};
    height: ${height}px;
    top: ${top}px;
    padding-top: ${paddingFromTop}px;
    ${getOverlayBackgroundStyle(onDraggingArea)};
    ${sharedStyle};
`;

const getUserOverlayBorder = (onDraggingArea: boolean): string => {
    return onDraggingArea
        ? `border: ${sizes.dragOverlayBorderSize}px dashed ${appColors.primary_blue_400.hex}`
        : '';
};

const getUserOverlayBorderRadius = (onDraggingArea: boolean): string => {
    return onDraggingArea ? `border-radius: 3px` : '';
};

const userOverlayBorderStyle = (
    draggingFromArea: TDraggingFromArea,
    height: number,
    top: number | null,
    onDraggingArea: boolean
): string => css`
    z-index: ${draggingFromArea ? userDropOverlayBorderZIndex : -1};
    height: ${height}px;
    top: ${top}px;
    ${getUserOverlayBorder(onDraggingArea)};
    ${getUserOverlayBorderRadius(onDraggingArea)};
    background-color: transparent;
    ${sharedStyle};
`;

const mapStateToProps = (
    state: IWorkSchedulingCombinedState,
    ownProps: IUserWrapperOnDragComponent
): IUserWrapperOnDragComponentStateToProps => {
    const { userID } = ownProps;
    const userRoleID = userRoleIdSelector(state, { userID });
    return {
        draggingFromArea: draggingFromAreaSelector(state),
        userName: userNameByIdSelector(state, { userID }),
        roleName: getRoleByIdSelector(state)(userRoleID),
        workPerDaysOnDragHover: workPerDaysOnDragHoverSelector(state),
        userAssignments: userAssignmentsSelector(state, { userID }),
        internalEventEmitter: internalEventEmitterSelector(state),
    };
};

export const UserWrapperOnDragComponent = connect(mapStateToProps)(UserWrapperOnDragInternal);
