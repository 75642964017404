export const messageKeysAndFallBacksForMonths = {
    'january.abbr': 'Jan',
    'february.abbr': 'Feb',
    'march.abbr': 'Mar',
    'april.abbr': 'Apr',
    'may.abbr': 'May',
    'june.abbr': 'Jun',
    'july.abbr': 'Jul',
    'august.abbr': 'Aug',
    'september.abbr': 'Sep',
    'october.abbr': 'Oct',
    'november.abbr': 'Nov',
    'december.abbr': 'Dec',
};

export const monthsMessageKeys = Object.keys(messageKeysAndFallBacksForMonths);

export const monthsMessageKeysFallBacks = Object.values(messageKeysAndFallBacksForMonths);

export const messageKeysAndFallBacksForWeeks = {
    'monday.abbr': 'Mon',
    'tuesday.abbr': 'Tue',
    'wednesday.abbr': 'Wed',
    'thursday.abbr': 'Thu',
    'friday.abbr': 'Fri',
    'saturday.abbr': 'Sat',
    'sunday.abbr': 'Sun',
};

export const weekMessageKeys = Object.keys(messageKeysAndFallBacksForWeeks);

export const weekMessageKeysFallBacks = Object.values(messageKeysAndFallBacksForWeeks);
