import { css } from 'react-emotion';
import { sizes } from '../../constants/schedulingTableConstants';

export const widthOffset = 5;

export const setTextColorCss = (color): string => css`
    color: ${color || ''};
`;

export const allocationHourCss = css`
    visibility: visible;
    transform: translate3d(0, 0, 0);
    display: inline-block;
    position: relative;
`;

export const crossedOutCss = (color): string => css`
    &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: ${color};
        position: absolute;
        top: 50%;
        left: 0;
    }
`;

export const weekEndDayCss = css`
    opacity: 0.5;
`;

export const bracketHourCss = (isFromAssignmentBar: boolean, isWorkingDay: boolean): string => css`
    margin-left: 4px;
    display: flex !important;
    align-items: center;
    &:after,
    &:before {
        ${!isFromAssignmentBar && !isWorkingDay ? 'opacity: 0.5;' : ''}
    }
    &:after {
        content: ')';
    }
    &:before {
        content: '(';
    }
`;

const calculateCellWidth = (width, isThereArrow, isSingleItem): number => {
    if (isThereArrow) {
        return width - 15;
    }
    return width - (isSingleItem ? 8 : widthOffset);
};

const lastOfTypeStyle = (
    cellWidth: number,
    isThereAfterArrow: boolean,
    isSinglePeriodTask: boolean
): string => {
    return !isSinglePeriodTask || (isSinglePeriodTask && isThereAfterArrow)
        ? `
        &:last-of-type {
            width: ${calculateCellWidth(cellWidth, isThereAfterArrow, isSinglePeriodTask)}px;
        };
`
        : ``;
};

export const allocationHourStyle = (
    cellWidth: number,
    isThereBeforeArrow: boolean,
    isThereAfterArrow: boolean,
    height: number,
    isSinglePeriodTask: boolean,
    isFromAssignmentBar?: boolean,
    isTimeOff?: boolean
): string => css`
    float: left;
    font-size: 14px;
    cursor: default;
    text-overflow: ellipsis;
    width: ${cellWidth}px;
    height: ${isFromAssignmentBar ? height : sizes.projectAssignmentHeightRedesign}px;
    border-left: ${isFromAssignmentBar ? 1 : 0}px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    line-height: ${isFromAssignmentBar ? height : sizes.projectAssignmentHeightRedesign}px;
    padding: 0 ${sizes.durationPadding + 1}px;
    opacity: ${isTimeOff ? 0.5 : 1};
    display: flex;
    justify-content: center;
    font-weight: ${isFromAssignmentBar ? 'normal' : 600};
    &:first-child {
        width: ${calculateCellWidth(cellWidth, isThereBeforeArrow, isSinglePeriodTask)}px;
        border-left: 0;
    }
    
    ${lastOfTypeStyle(cellWidth, isThereAfterArrow, isSinglePeriodTask)}
}`;
