import { Project as projectObjCode } from 'workfront-objcodes';
import { TModernSchedulingAction } from '../../types';
import { IDataState, IUnassignedAssignment } from '../IDataState';

export const ActionType = 'ADD_UNASSIGNED_TASKS_BY_PROJECT';

interface IAddUnassignedAssignmentsPayload {
    unassignedProjects: IUnassignedAssignment[];
    unassignedTasks: IUnassignedAssignment[];
}

type TAddUnassignedAssignmentsAction = TModernSchedulingAction<IAddUnassignedAssignmentsPayload>;

const addUnassignedProjects = (
    unassignedProjects,
    unassignedTasks
): TAddUnassignedAssignmentsAction => ({
    type: ActionType,
    payload: {
        unassignedProjects,
        unassignedTasks,
    },
});

export default addUnassignedProjects;

export function handle(
    state: IDataState,
    { payload }: TAddUnassignedAssignmentsAction
): IDataState {
    const unassignedProjects = { ...state.unassignedTasksProjects };

    payload.unassignedProjects.forEach((assignmentItem) => {
        if (!unassignedProjects[assignmentItem.ID]) {
            unassignedProjects[assignmentItem.ID] = {
                nodes: [],
                details: {
                    ID: assignmentItem.ID,
                    name: assignmentItem.name,
                    groupID: assignmentItem.groupID,
                    objCode: projectObjCode,
                    expanded: false,
                    offset: 0,
                    scheduleID: assignmentItem.scheduleID,
                    status: assignmentItem.status,
                },
            };
        }
    });

    payload.unassignedTasks.forEach((task) => {
        if (unassignedProjects[task.projectID].nodes.indexOf(task.ID) === -1) {
            unassignedProjects[task.projectID].nodes = [
                ...unassignedProjects[task.projectID].nodes,
                task.ID,
            ];
        }
    });

    return {
        ...state,
        unassignedTasksProjects: unassignedProjects,
    };
}
