import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'SET_PROJECT_LIFE_AFTER_DEATH';

interface IHasLifeAfterDeathPayload {
    hasLifeAfterDeath: boolean;
}

type THasLifeAfterDeathPayloadAction = TModernSchedulingAction<IHasLifeAfterDeathPayload>;

const setProjectLifeAfterDeath = (hasLifeAfterDeath): THasLifeAfterDeathPayloadAction => ({
    type: ActionType,
    payload: {
        hasLifeAfterDeath,
    },
});
export default setProjectLifeAfterDeath;

export function handle(
    state: IDataState,
    { payload }: THasLifeAfterDeathPayloadAction
): IDataState {
    return {
        ...state,
        hasProjectLifeAfterDeath: payload.hasLifeAfterDeath,
    };
}
