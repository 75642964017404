import React, { Suspense, lazy } from 'react';
import { IBulkAssignmentsContent } from './BulkAssignmentsContent';

const BulkAssignmentContent = lazy(() =>
    import('./BulkAssignmentsContent').then((module) => ({
        default: module.BulkAssignmentsContent,
    }))
);

export const BulkAssignmentWrapperForLazy: React.FunctionComponent<IBulkAssignmentsContent> = (
    props
) => {
    const { schedulingAreaData } = props;
    return (
        <Suspense fallback={null}>
            <BulkAssignmentContent schedulingAreaData={schedulingAreaData} />
        </Suspense>
    );
};
