import { getStorageUtil } from '@workfront/storage';
import { secondary } from '@phoenix/all';
import moment, { Moment } from 'moment';
import {
    CustomEnum,
    Portfolio,
    Program,
    Project,
    Role,
    Team,
    TObjCode,
    User,
} from 'workfront-objcodes';
import { Field, FieldGroup, TypeaheadValue } from '@workfront/panel-components';
import { LICENSE_TYPES } from '@wf-mfe/auth';
import { objectProjectedDates } from '../../constants/dataConstatnts';
import {
    ALL_USER_TASKS_DEFAULT_FILTER,
    customFieldPrefix,
    customTypeaheadObjectCode,
    FIELDS_GROUP_KEYS,
    FilterGroup,
    filterKeyPostfix,
    filterKeys,
    filterLocalStorageKey,
    projectFieldsToExclude,
    unassignedFilterGroups,
    UNSAVED_FILTER_DATA,
    unsavedFilterPrefix,
    usersRelatedGroupKeys,
    usersSortAlphabeticallyFilter,
    usersSortByRoleFilter,
} from '../../constants/filters/filterConstants';
import { OBJ_CODES } from '../../constants/filters/filterOptions';
import { Sections } from '../../constants/schedulingTableConstants';
import { IAreaState, TAreaObjCodes } from '../../data-flow/areaData/areaRelatedInitialDataState';
import addFilters, { TAddFiltersAction } from '../../data-flow/filters/filtersActions/addFilters';
import {
    IFilterItemState,
    IFilterRuleGroup,
    IFilterExpression,
    IFilterRule,
    ITritonFilter,
    IProjectStatusesForFilter,
    ISearchedItems,
    ISeparatedFilters,
    TFilterAPISearchObject,
    IFilterRuleMetadataLocal,
} from '../../data-flow/filters/IFiltersState';
import { IAddedByAssignmentUserData, IWorkSchedulingCombinedState } from '../../data-flow/types';
import { getUsersAddedByAssignmentFilter } from '../changeAssignmentsUtil';
import { getByMessageKeySync, getEndOfDay, getStartOfDay } from '../utilities';
import { APIService } from '../../services/api-services/apiService';
import { TOrUndefined } from '../../data-flow/data/IDataState';
import {
    ConvertedFilterExpression,
    ConvertedFilterToApiMode,
    IWorkRequiredSummaryForRoleFilter,
} from './filterUtilTypes';
import { unassignedWorkFilterIDSelector } from '../../data-flow/filters/selectors/filterIDSelector';
import { LightningIcon } from '../../components/filters/LightningIcon';

export const mutateRuleObject = (rule: IFilterRule): void => {
    const [fieldKey, fieldValue, customFieldValue] = rule.searchFieldMeta.item.value.split(':');
    const valueData = customFieldValue ? `${fieldKey}:${customFieldValue}` : fieldValue;
    const keyData = customFieldValue ? fieldValue : fieldKey;

    rule.searchFieldMeta = {
        item: {
            value: valueData,
        },
        /**
         * This property is added because in old implementation `group`
         * was valid property. After improvements on filter service, this field
         * was not needed anymore, but as in our package we used it, we map new data scheme
         * into old one.
         * */
        group: {
            key: keyData,
        },
    } as IFilterRuleMetadataLocal;
};

export const generateGroupKey = (filterData: IFilterExpression): IFilterExpression => {
    filterData.filterExpression.rules.forEach((rule) => {
        const filterRule = rule as IFilterRule;
        mutateRuleObject(filterRule);
    });

    return filterData;
};

export function separateUsersAndAssignmentsFilters(
    filterData: IFilterExpression
): ISeparatedFilters {
    const separatedFilters: ISeparatedFilters = {
        assignmentsFilter: {
            filterExpression: { ...filterData.filterExpression, rules: [], groups: [] },
        },
        usersFilter: {
            filterExpression: { ...filterData.filterExpression, rules: [], groups: [] },
        },
    };

    filterData.filterExpression.rules.forEach((rule: IFilterRule) => {
        mutateRuleObject(rule);

        const fieldMetaData = rule.searchFieldMeta as IFilterRuleMetadataLocal;
        const groupKey = fieldMetaData.group.key;

        const rules =
            usersRelatedGroupKeys.indexOf(groupKey) !== -1
                ? separatedFilters.usersFilter.filterExpression.rules
                : separatedFilters.assignmentsFilter.filterExpression.rules;

        rules.push(rule);
    });

    return separatedFilters;
}

const getFieldForAssignmentFilter = (
    groupKey: string,
    searchFieldValue: string,
    filterGroup: FilterGroup
): TOrUndefined<string> => {
    const isGroupKeyAssignment = groupKey === 'assignment';
    if (filterGroup === FilterGroup.assignments && isGroupKeyAssignment) {
        return searchFieldValue;
    }

    if (unassignedFilterGroups.has(filterGroup) && isGroupKeyAssignment) {
        return `assignments:${searchFieldValue}`;
    }
};

export const getSearchFiled = (
    groupKey: string,
    searchFieldValue: string,
    filterGroup: FilterGroup
): string => {
    const assignmentField = getFieldForAssignmentFilter(groupKey, searchFieldValue, filterGroup);
    if (assignmentField) {
        return assignmentField;
    }

    const newGroupKey = filterKeys[filterGroup][groupKey];

    if (isCustomField(searchFieldValue)) {
        /**
         * isCustomField case should be checked first,
         * before checking new newGroupKey case
         * */
        const [key, value] = searchFieldValue.split(':');
        const groupKeyData = newGroupKey?.key || groupKey;
        return `${key}:${groupKeyData}:${value}`;
    }
    if (newGroupKey) {
        const newSearchFiled = newGroupKey.searchField || searchFieldValue;
        return newGroupKey.skipKey ? newSearchFiled : `${newGroupKey.key}:${newSearchFiled}`;
    }

    return `${groupKey}:${searchFieldValue}`;
};

function addIsActiveFilter(
    filterFields: ConvertedFilterExpression,
    filter: ConvertedFilterToApiMode
): void {
    if (filterFields?.isActive) {
        filter.isActive = filterFields.isActive;
    }
}

export function convertFilterToApiMode(
    filterExpression: IFilterRuleGroup,
    filterGroup: FilterGroup
): ConvertedFilterToApiMode {
    let filter: ConvertedFilterToApiMode = {};

    if (filterExpression && filterExpression.rules) {
        filterExpression.rules.forEach((filterRule, index) => {
            const fieldMetaData = filterRule.searchFieldMeta as IFilterRuleMetadataLocal;
            const groupKey = fieldMetaData.group.key;
            const searchFieldValue = fieldMetaData.item.value;

            let searchValues: string[] = [];
            const searchField = getSearchFiled(groupKey, searchFieldValue, filterGroup);

            searchValues = filterRule.searchValues.map(String);
            const isConditionBetween = filterRule.condition === 'between';
            if (filterGroup === FilterGroup.users) {
                const field = `AND:${index}`;
                const fieldMod = `${searchField}_Mod`;

                const filterFields = getFilterFields(searchField, searchValues, isConditionBetween);

                filter[field] = {
                    ...filterFields,
                    [fieldMod]: filterRule.condition,
                };

                addIsActiveFilter(filterFields, filter);
            } else {
                const field = `AND:${index}:${searchField}`;
                const fieldMod = `AND:${index}:${searchField}_Mod`;

                const filterFields = getFilterFields(field, searchValues, isConditionBetween);

                filter = {
                    ...filter,
                    ...filterFields,
                    [fieldMod]: filterRule.condition,
                };
            }
        });
    }

    return filter;
}

export const getUsersFilter = (
    filterExpression,
    limit,
    offset,
    addedByAssignmentUsersData: IAddedByAssignmentUserData,
    sortByRole,
    additionalSimpleFilter = {}
): any => {
    const sortByRoleFilter = sortByRole ? usersSortByRoleFilter : {};

    const limitCriteria = {
        $$LIMIT: limit,
        $$FIRST: offset,
        ...sortByRoleFilter,
        ...usersSortAlphabeticallyFilter,
        isActive: 1,
        // Filter external users
        licenseType: LICENSE_TYPES.EXTERNAL,
        licenseType_Mod: 'ne',
    };

    const userFilterByAssignment = getUsersAddedByAssignmentFilter(addedByAssignmentUsersData);

    return {
        ...limitCriteria,
        ...additionalSimpleFilter,
        ...userFilterByAssignment,
        /**
         * This function MUST be destructured the last one, otherwise
         * the returned object will not be able to overwrite isActive property
         * of 'limitCriteria' object when needed
         * */
        ...convertFilterToApiMode(filterExpression, FilterGroup.users),
    };
};

/**
 *
 * @param schedulingAreaData
 * @param showAllUsers
 */
export const prepareAreaAdditionalSimpleFilterForUserApi = (
    schedulingAreaData: IAreaState,
    showAllUsers: boolean
): any => {
    switch (schedulingAreaData.schedulingAreaObjCode) {
        case Team:
            return {
                'teams:ID': [schedulingAreaData.schedulingAreaID],
                'teams:ID_Mod': 'in',
            };
        case Project:
            if (!showAllUsers) {
                return {
                    'userAssignments:projectID': [schedulingAreaData.schedulingAreaID],
                    'userAssignments:projectID_Mod': 'in',
                };
            }
            return {};
        default:
            return {};
    }
};

/**
 *
 * @param schedulingAreaData
 * @param isIssue
 */
export const prepareAreaAdditionalSimpleFilterForUnassignedProjects = (
    schedulingAreaData: IAreaState,
    isIssue: boolean
): any => {
    switch (schedulingAreaData.schedulingAreaObjCode) {
        case Team:
            return isIssue
                ? {
                      'opTasksOM:teamID': [schedulingAreaData.schedulingAreaID],
                      'opTasksOM:teamID_Mod': 'in',
                  }
                : {
                      'unassignedTasks:teamID': [schedulingAreaData.schedulingAreaID],
                      'unassignedTasks:teamID_Mod': 'in',
                  };
        case Project:
            return {
                ID: [schedulingAreaData.schedulingAreaID],
                ID_Mod: 'in',
            };
        default:
            return {};
    }
};

/**
 *
 * @param schedulingAreaData
 */
export const prepareAreaAdditionalSimpleFilterForUnassignedTasks = (
    schedulingAreaData: IAreaState
): any => {
    switch (schedulingAreaData.schedulingAreaObjCode) {
        case Team:
            return {
                teamID: [schedulingAreaData.schedulingAreaID],
                teamID_Mod: 'in',
            };
        case Project:
            return {
                'project:ID': [schedulingAreaData.schedulingAreaID],
                'project:ID_Mod': 'in',
            };
        default:
            return {};
    }
};

export const getDateRangeFilters = (
    startDate: Moment,
    endDate: Moment,
    filterObjectType: 'task:' | 'opTask:' | 'unassignedTasks:' | 'opTasksOM:' | '',
    startDateFieldName: string,
    endDateFieldName: string,
    showActualProgress?: boolean
): any => {
    const start = getStartOfDay(startDate);
    const end = getEndOfDay(endDate);

    const additionalFilters = showActualProgress
        ? {
              [`AND:ab:OR:b:${filterObjectType}${objectProjectedDates[filterObjectType].startDate}`]:
                  end,
              [`AND:ab:OR:b:${filterObjectType}${objectProjectedDates[filterObjectType].startDate}_Mod`]:
                  'lte',
              [`AND:ab:OR:b:${filterObjectType}${objectProjectedDates[filterObjectType].completionDate}`]:
                  start,
              [`AND:ab:OR:b:${filterObjectType}${objectProjectedDates[filterObjectType].completionDate}_Mod`]:
                  'gte',
          }
        : {};

    const fieldValue = [`${filterObjectType}${startDateFieldName}`];

    return {
        ...additionalFilters,
        [`AND:ab:OR:a:${fieldValue}`]: end,
        [`AND:ab:OR:a:${fieldValue}_Mod`]: 'lte',
        [`AND:ab:OR:a:${filterObjectType}${endDateFieldName}`]: start,
        [`AND:ab:OR:a:${filterObjectType}${endDateFieldName}_Mod`]: 'gte',
        [`AND:2:${filterObjectType}${endDateFieldName}`]: `FIELD:${fieldValue}`,
        [`AND:2:${filterObjectType}${endDateFieldName}_Mod`]: 'gt',
    };
};

export const getAssignmentsFilter = (
    filterExpression,
    startDate,
    endDate,
    obCode: 'task' | 'opTask',
    showActualProgress,
    showCompletedWork
): any => {
    let assignmentFilter = {};
    let additionalFilter = {};
    let completedFilter = {};

    if (filterExpression) {
        assignmentFilter = convertFilterToApiMode(filterExpression, FilterGroup.assignments);
    }

    if (obCode === 'task') {
        additionalFilter = {
            'task:numberOfChildren': 0,
            'task:numberOfChildren_Mod': 'eq',
        };
    }

    if (!showCompletedWork) {
        completedFilter = {
            [`${obCode}:actualCompletionDate_Mod`]: 'isnull',
        };
    }

    return {
        ...additionalFilter,
        ...completedFilter,
        ...getDateRangeFilters(
            startDate,
            endDate,
            `${obCode}:` as const,
            'plannedStartDate',
            'plannedCompletionDate',
            showActualProgress
        ),
        ...assignmentFilter,
        [`${obCode}:plannedStartDate_2_Sort`]: 'asc',
        [`${obCode}:plannedCompletionDate_3_Sort`]: 'asc',
        [`${obCode}:name_4_Sort`]: 'ciasc',
        ...getLimit(),
    };
};

export const getLimit = (
    limit = 2000,
    offset = 0
): {
    $$LIMIT: number;
    $$FIRST: number;
} => {
    return {
        $$LIMIT: limit,
        $$FIRST: offset,
    };
};

export const getUserReservedTimesFilter = (usersIDs, startDate, endDate): any => {
    return {
        userID: usersIDs,
        userID_Mod: 'in',
        ...getDateRangeFilters(moment(startDate), moment(endDate), '', 'startDate', 'endDate'),
        ...getLimit(),
    };
};

export const getUnassignedAssignmentsProjectsFilter = (
    filterExpression,
    additionalSimpleFilter,
    startDate,
    endDate,
    limit,
    offset,
    projectsSortingCriteria
): any => {
    let filter = {};
    const sortingKey = getProjectsSortingKey(projectsSortingCriteria);

    if (filterExpression) {
        filter = convertFilterToApiMode(filterExpression, FilterGroup.unassignedProject);
    }
    const isCaseSensitive = projectsSortingCriteria?.dataType === 'string' ? 'ciasc' : 'asc';

    const sortingFields = {
        ...(sortingKey ? { [`${sortingKey}_1_Sort`]: `${isCaseSensitive}` } : {}),
        plannedStartDate_2_Sort: 'asc',
        plannedCompletionDate_3_Sort: 'asc',
        name_4_Sort: 'ciasc',
    };
    return {
        'unassignedTasks:percentComplete': 100,
        'unassignedTasks:percentComplete_Mod': 'ne',
        'unassignedTasks:numberOfChildren': 0,
        'unassignedTasks:numberOfChildren_Mod': 'eq',
        ...getAdditionForRoleCase(filterExpression),
        ...getDateRangeFilters(
            startDate,
            endDate,
            'unassignedTasks:',
            'plannedStartDate',
            'plannedCompletionDate'
        ),
        ...filter,
        ...additionalSimpleFilter,
        ...sortingFields,
        ...getLimit(limit, offset),
    };
};

export const getUnassignedIssuesProjectsFilter = (
    filterExpression,
    additionalSimpleFilter,
    startDate,
    endDate,
    limit,
    offset,
    projectsSortingCriteria
): any => {
    let filter = {};
    const sortingKey = getProjectsSortingKey(projectsSortingCriteria);

    if (filterExpression) {
        filter = convertFilterToApiMode(filterExpression, FilterGroup.unassignedProject);
    }

    const sortingFields = {
        ...(sortingKey ? { [`${sortingKey}_1_Sort`]: 'ciasc' } : {}),
        plannedStartDate_2_Sort: 'asc',
        plannedCompletionDate_3_Sort: 'asc',
        name_4_Sort: 'ciasc',
    };

    return {
        'opTasksOM:percentComplete': 100,
        'opTasksOM:percentComplete_Mod': 'ne',
        'opTasksOM:numberOfChildren': 0,
        'opTasksOM:numberOfChildren_Mod': 'eq',
        ...getAdditionForRoleCase(filterExpression),
        ...getDateRangeFilters(
            startDate,
            endDate,
            'opTasksOM:',
            'plannedStartDate',
            'plannedCompletionDate'
        ),
        ...filter,
        ...additionalSimpleFilter,
        ...sortingFields,
        ...getLimit(limit, offset),
    };
};

/**
 *
 * @param filterExpression
 */
export const getAdditionForRoleCase = (
    filterExpression
): {
    'assignments:assignedToID_Mod'?: string;
} => {
    const hasRole = filterExpression?.rules?.find((rule) => {
        return rule.searchFieldMeta.group.key === OBJ_CODES.ROLE.groupKey;
    });

    if (hasRole) {
        return { 'assignments:assignedToID_Mod': 'isnull' };
    }

    return {};
};

interface IUnasssignedTasksFilter {
    filter: ConvertedFilterExpression;
    filterOptask: ConvertedFilterExpression;
}

export const getUnassignedTasksFilter = (
    filterExpression,
    additionalSimpleFilter,
    startDate,
    endDate,
    taskID: string | null = null,
    limit = 2000,
    offset = 0
): IUnasssignedTasksFilter => {
    let filter: ConvertedFilterToApiMode = {};
    let filterOptask: ConvertedFilterToApiMode = {};

    if (filterExpression) {
        filter = convertFilterToApiMode(filterExpression, FilterGroup.unassignedTasks);
        filterOptask = convertFilterToApiMode(filterExpression, FilterGroup.unassignedIssues);
    }

    if (taskID) {
        filter.ID = taskID;
        filter.ID_Mod = 'in';

        filterOptask.ID = taskID;
        filterOptask.ID_Mod = 'in';
    }

    const baseFilter = {
        'assignments:assignedToID_Mod': 'isnull',
        assignments_Join: 'allowingnull',
        numberOfChildren: 0,
        numberOfChildren_Mod: 'eq',
        percentComplete: '100',
        percentComplete_Mod: 'ne',
        ...additionalSimpleFilter,
        ...getDateRangeFilters(startDate, endDate, '', 'plannedStartDate', 'plannedCompletionDate'),
        plannedStartDate_1_Sort: 'asc',
        plannedCompletionDate_2_Sort: 'asc',
        'project:name_3_Sort': 'ciasc',
        name_5_Sort: 'ciasc',
        ...getLimit(limit, offset),
    };

    return {
        filter: { ...baseFilter, ...filter },
        filterOptask: { ...baseFilter, ...filterOptask },
    };
};

export const getUnsavedWlbFilter = (
    sectionType: Sections,
    schedulingArea: TAreaObjCodes
): IFilterExpression => {
    const unsavedFilter = localStorage.getItem(
        `${generateUnsavedFilterPrefix(sectionType, schedulingArea)}`
    );

    return unsavedFilter ? JSON.parse(unsavedFilter) : unsavedFilter;
};

export const getFilterNameByArea = (filterKey, schedulingArea: TAreaObjCodes): string => {
    const filterKeyWlb = filterKey + filterKeyPostfix;
    return !schedulingArea ? filterKeyWlb : schedulingArea + filterKeyWlb;
};

/**
 *
 * @param sectionType
 * @param userID
 * @param schedulingArea
 */
export const clearFilterDataFromStorage = (sectionType, userID, schedulingArea): void => {
    const storageFiltersByArea = getFilterNameByArea(
        filterLocalStorageKey[sectionType],
        schedulingArea
    );
    const storageFilters = JSON.parse(getStorageUtil().get(storageFiltersByArea));
    delete storageFilters[userID];

    getStorageUtil().set(storageFilters, JSON.stringify(storageFilters));
};

/**
 *
 * @param sectionType
 * @param userID
 * @param filterID
 * @param schedulingArea
 */
export const addFilterDataToStorage = (sectionType, userID, filterID, schedulingArea): void => {
    const storageFiltersByArea = getFilterNameByArea(
        filterLocalStorageKey[sectionType],
        schedulingArea
    );

    const storageFilters = JSON.parse(getStorageUtil().get(storageFiltersByArea)) || {};
    storageFilters[userID] = filterID;

    getStorageUtil().set(storageFiltersByArea, JSON.stringify(storageFilters));
};

/**
 *
 * @param filterDetails
 * @param sectionType
 */
export const addFilterActions = (
    filterDetails: ITritonFilter[],
    sectionType: Sections
): [TAddFiltersAction] => {
    return [addFilters(filterDetails, sectionType)];
};

export const generateUnsavedFilterPrefix = (
    sectionType: Sections,
    schedulingArea: TAreaObjCodes
): string => {
    const filterKey = unsavedFilterPrefix[sectionType]
        ? `${unsavedFilterPrefix[sectionType]}${UNSAVED_FILTER_DATA}`
        : UNSAVED_FILTER_DATA;
    return getFilterNameByArea(filterKey, schedulingArea);
};

/**
 *
 * @param filterID
 * @param filterName
 * @param filterWordTranslation
 */
export const getFilterNameOnButton = (
    filterID: IFilterItemState['filterID'],
    filterName: string,
    filterWordTranslation: string
): string => {
    if (filterID === ALL_USER_TASKS_DEFAULT_FILTER || !filterName) {
        return '';
    }
    return `${filterWordTranslation}: ${filterName}`;
};

export const getWorkRequiredSummaryForRoleFilter = (
    projectID: string
): IWorkRequiredSummaryForRoleFilter => {
    return {
        roleID_1_GroupBy: true,
        'role:name_2_GroupBy': true,
        workRequired_AggFunc: 'sum',
        roleID_Mod: 'notnull',
        projectID,
    };
};

export const loadProjectAssignmentsFilter = (
    projectID: string,
    taskPlannedStartDate: Moment,
    taskPlannedCompletionDate: Moment
): any => {
    return ['task', 'opTask'].map((objCode) =>
        prepareLoadProjectAssignmentsFilter(
            projectID,
            taskPlannedStartDate,
            taskPlannedCompletionDate,
            objCode
        )
    );
};

export const prepareLoadProjectAssignmentsFilter = (
    projectID: string,
    taskPlannedStartDate: Moment,
    taskPlannedCompletionDate: Moment,
    objCode: string
): any => {
    return {
        projectID,
        projectID_Mod: 'in',
        roleID_Mod: 'notblank',
        [`${objCode}:plannedStartDate`]: getEndOfDay(taskPlannedCompletionDate),
        [`${objCode}:plannedStartDate_Mod`]: 'lte',
        [`${objCode}:plannedCompletionDate`]: getStartOfDay(taskPlannedStartDate),
        [`${objCode}:plannedCompletionDate_Mod`]: 'gte',
        ...getLimit(),
    };
};

export const typeAheadMapper = (
    item: ISearchedItems | IProjectStatusesForFilter
): TypeaheadValue => {
    return {
        label: item.name,
        value: item.ID,
    };
};

export const objCodeMapper = {
    portfolio: Portfolio,
    'project:status': CustomEnum,
    project: Project,
    team: Team,
    role: Role,
    user: User,
    userAssignments: Project,
};

export const getFilterForApiSearch = (
    searchValue: {
        searchTerm: string;
        searchRegEx: RegExp;
    },
    objCode: TObjCode
): TFilterAPISearchObject => {
    return {
        name: `%${searchValue.searchTerm}%`,
        name_Mod: 'cilike',
        $$LIMIT: 100,
        ID: [],
        ID_Mod: 'notin',
        ...(objCode === User && { isActive: 1 }),
    };
};

export const getTypeaheadFieldMetadata = (
    objectCode: TObjCode,
    groupKey: string
): Field['typeaheadFieldMetadata'] => {
    const objectMapper = typeAheadMapper;
    return {
        loadPossibleValuesFn: (
            groupKeyValue,
            fieldKey,
            refObjCode,
            searchValue
        ): Promise<TypeaheadValue[]> => {
            const filters = getFilterForApiSearch(searchValue!, objectCode);
            return APIService.apiSearch(objectCode, filters, []).then((items: ISearchedItems[]) => {
                return items.map(objectMapper);
            });
        },
        loadSelectedObjectsFn: (groupKeyValue, ids): Promise<TypeaheadValue[]> => {
            return APIService.apiSearch(objectCode, { ID: ids }, []).then(
                (items: ISearchedItems[]) => {
                    return items.map(objectMapper);
                }
            );
        },
        idFieldKey: `${groupKey}:ID`,
        hasAvatar: false,
    };
};

export const getFieldsPerArea = (
    initialFields: Field[],
    schedulingAreaObjCode: TAreaObjCodes
): Field[] => {
    return initialFields.filter((field) => {
        const projectAreaFieldsCondition =
            schedulingAreaObjCode === Project &&
            (field.groupKey === 'project:status' ||
                field.groupKey === 'userAssignments' ||
                field.groupKey === 'portfolio');
        if (projectAreaFieldsCondition) {
            return false;
        }
        return objCodeMapper[field.groupKey] !== schedulingAreaObjCode;
    });
};

export const mergeFilterExpressions = (filterData: ITritonFilter[]): IFilterExpression => {
    const filterExpression: IFilterExpression = {
        filterExpression: {
            operator: 'AND',
            rules: [],
        },
    };

    filterData.forEach((element, index) => {
        if (index === 0) {
            filterExpression.filterExpression = element.filterExpression;
        } else {
            filterExpression.filterExpression.rules =
                filterExpression.filterExpression.rules.concat(element.filterExpression.rules);
        }
    });

    return filterExpression;
};

export const formatGroupKeys = (fields: Field[]): Field[] => {
    return fields.map((fieldData) => {
        const field = { ...fieldData };
        const fieldGroupKey = FIELDS_GROUP_KEYS[field.groupKey];
        field.key = isCustomField(field.key)
            ? `${customFieldPrefix}:${fieldGroupKey}:${field.key.slice(3)}`
            : `${fieldGroupKey}:${field.key}`;

        const wildCards = field.typeaheadFieldMetadata?.wildCards || [];
        if (field.key === `${FIELDS_GROUP_KEYS[Project]}:ID`) {
            wildCards.push({
                avatarIcon: LightningIcon,
                backgroundColor: secondary.yellow(200),
                label: getByMessageKeySync('workloadbalancer.this.project', 'This project'),
                value: '$$PROJECT.ID',
            } as any);
        }

        return {
            ...field,
            groupKey: fieldGroupKey,
            typeaheadFieldMetadata: {
                ...field.typeaheadFieldMetadata!,
                idFieldKey: `${fieldGroupKey}:${field?.typeaheadFieldMetadata?.idFieldKey}`,
                wildCards,
            },
        };
    });
};
export const filterUnfilterableFields = (field: FieldGroup): FieldGroup => {
    if (field.key === Project) {
        field.fields = field.fields.filter((fieldData) => {
            return !projectFieldsToExclude.includes(fieldData.key);
        });
        return field;
    }

    const fieldsToInclude = ['ID'];
    field.fields = field.fields.filter((fieldData) => {
        return fieldsToInclude.includes(fieldData.key);
    });

    return field;
};

export const formatAdvancedFieldsGroupKeys = (fieldsGroups: FieldGroup[]): FieldGroup[] => {
    return fieldsGroups.map((field) => {
        if (field.key === Portfolio || field.key === Program || field.key === Project) {
            field = filterUnfilterableFields(field);
        }

        field.fields = formatGroupKeys(field.fields);
        return field;
    });
};

export const isCustomField = (value: string): boolean => {
    return value.startsWith(customFieldPrefix);
};

export const isBetweenConditionConverter = (
    searchField: string,
    searchValues: string[]
): { [key: string]: string } => {
    return searchValues.reduce((initialObject, currentValue, index) => {
        const isEndValueOfBetween = index === 1;
        if (isEndValueOfBetween) {
            initialObject[`${searchField}_Range`] = currentValue;
        } else {
            initialObject[searchField] = currentValue;
        }
        return initialObject;
    }, {});
};

export const getFilterFields = (
    searchField: string,
    searchValues: string[],
    isConditionBetween: boolean
): ConvertedFilterExpression => {
    if (isConditionBetween) {
        return isBetweenConditionConverter(searchField, searchValues);
    }
    return {
        [searchField]: searchValues,
    };
};

export const getProjectsSortingKey = (fieldGroup: TOrUndefined<Field>): string | null => {
    if (!fieldGroup) {
        return null;
    }
    return fieldGroup.key.includes('DE') ? fieldGroup.key : fieldGroup.key.split(':')[1];
};

export const getProjectsSortingRecentFields = (
    projectsSortingCriteria,
    quickFilterFields
): FieldGroup[] => {
    if (
        !projectsSortingCriteria ||
        quickFilterFields.find((field) => field.key === projectsSortingCriteria.key)
    ) {
        return [];
    }

    return [
        {
            key: 'recent-fields',
            label: 'Recent fields',
            fields: [projectsSortingCriteria],
        },
    ];
};

export const isCustomTypeaheadObject = (groupKey: string): boolean => {
    return groupKey.startsWith(customTypeaheadObjectCode);
};

export const getObjectDirectFields = (
    objectRelatedData: FieldGroup[],
    objCode: TObjCode
): Field[] => {
    return objectRelatedData.find((objectData) => objectData.key === objCode)?.fields || [];
};

export const combineObjectRelatedDataFields = (
    objectRelatedData: FieldGroup[],
    objCode: TObjCode
): Field[] => {
    const objectDirectFields = getObjectDirectFields(objectRelatedData, objCode);
    objectRelatedData.forEach((objectData) => {
        if (objectData.key !== objCode && objectData.fields.length) {
            objectData.fields.forEach((field) => {
                field.groupKey = objCode;
                objectDirectFields.push(field);
            });
        }
    });
    return objectDirectFields;
};

export const isFilterAppliedInUnassignedSection = (
    state: IWorkSchedulingCombinedState
): boolean => {
    const unassignedFilterId = unassignedWorkFilterIDSelector(state);
    const isFilterApplied = unassignedFilterId?.length;

    return Boolean(isFilterApplied);
};
