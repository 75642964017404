import { primary } from '@phoenix/all';
import styled from 'react-emotion';
import { TABLE_HEADER_PERIOD_CELL_PADDING } from '../../TableHeaderPeriodCell';

export const MonthViewContentWrapper = styled('div')<{ toggleAble: boolean }>`
    display: flex;
    align-items: center;
    cursor: ${(props) => (props.toggleAble ? 'pointer' : 'default')};
    position: relative;
`;

const halfPadding = `-${TABLE_HEADER_PERIOD_CELL_PADDING / 2}px`;
const allocationMonthlyContainerPadding = '1rem';
export const containerMinWidth = 320;

export const AllocationMonthlyContainer = styled('div')<{
    fromRight: boolean;
    width?: number;
    top?: number;
    left?: number;
}>`
    position: fixed;
    left: ${(props) => (!props.fromRight ? halfPadding : 'initial')};
    right: ${(props) => (props.fromRight ? halfPadding : 'initial')};
    background-color: ${primary.white()};
    top: ${(props) => `${props.top}px`};
    width: calc(100% + ${TABLE_HEADER_PERIOD_CELL_PADDING}px);
    box-shadow: 0 0 0.5rem rgba(33, 38, 47, 0.2);
    border-radius: 0.25rem;
    padding: ${allocationMonthlyContainerPadding};
    min-width: ${containerMinWidth}px;
    width: ${(props) => `${props.width}px`};
    left: ${(props) => `calc(${props.left}px - ${allocationMonthlyContainerPadding})`};
    z-index: 1;
`;
