import { BulkActions } from '../../constants/bulkAssignmentsConstants';

export const testIDs = {
    fieldSelect: 'field-select',
    conditionSelect: 'condition-select',
    bulkAssignmentsContent: 'bulk-assignments-content',
    bulkAssignmentsButton: 'bulk-assignments-button',
    removeTaskFilter: 'remove-filter',
    inputField: 'input-field',
    filterSearchSelect: 'filter-search-select',
    bulkAssignmentButtonWrapper: 'bulk-assignment-button-wrapper',
    errorIconWrapper: 'error-icon-wrapper',
    streamApiSelect: 'stream-api-select',
    selectProjectTasks: 'select-project-tasks',
    filterWrapper: 'filter-wrapper',
    bulkActionsWrapper: 'bulk-actions-wrapper',
    selectComponentWrapper: 'select-component-wrapper',
    errorSmallIconWrapper: 'error-small-icon-wrapper',
    projectName: 'project-name',
    warningWrapper: 'warning-wrapper',
    assignUserRoleSelect: 'assign-user-role-select',
    assignUserUserSelect: 'assign-user-user-select',
    assignmentInProgress: 'bulk-loading-assignment-in-process',
    replaceUserUserSelect: 'replace-user-user-select',
    unassignUserUserSelect: 'unassign-user-user-select',
    cancel: 'bulk-assignment-cancel',
    assignUser: `bulk-assignment-${BulkActions.ASSIGN_USER}`,
    userRolesSelect: 'user-roles-select',
    infoMessage: 'bulk-info-message',
};
