import { ICurrentUserState } from './ICurrentUserState';

export function getCurrentUserInitialState(options): ICurrentUserState {
    const { currentUser = {} } = options;
    return {
        workRequiredEditPermission: false,
        contouringEditAccess: false,
        roleID: currentUser.roleID,
    };
}
