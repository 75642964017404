import { TModernSchedulingAction } from '../../types';
import { ISettingsState } from '../ISettingsState';

export const ActionType = 'SET_PROJECT_COLOR_SETTING';

interface ISetProjectColorSettinf {
    actionType: string;
}

type TSetProjectColorSettinf = TModernSchedulingAction<ISetProjectColorSettinf>;

const setProjectColorsSettingsMode = (actionType): TSetProjectColorSettinf => ({
    type: ActionType,
    payload: {
        actionType,
    },
});
export default setProjectColorsSettingsMode;

export function handle(
    state: ISettingsState,
    { payload }: TSetProjectColorSettinf
): ISettingsState {
    const { actionType } = payload;

    return {
        ...state,
        projectColorsMode: actionType,
    };
}
