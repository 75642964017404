import { css, cx } from 'react-emotion';
import React from 'react';
import { primary } from '@phoenix/all';
import { Project } from 'workfront-objcodes';
import { convertHexToRGBA } from '../../../../util/colorUtils';
import { DoneMark, MsgKeyCompleted } from '../DoneMark';
import { AssignmentTitle } from '../AssignmentTitle';
import { dataTestIds, IActualProgressBlockWrapper } from './ActualProgressBlockWrapper';
import { projectedAssignmentZIndex } from '../../../../constants/zIndexes';
import { getFontType } from '../../../../util/getFontType';
import { getLinkRelatedProps } from '../../left/ProjectName/ProjectName';
import { LeftRightArrowWrapper } from './LeftRightArrowWrapper';
import { ProjectAssignmentTitle } from '../ProjectAssignmentTitle';

export interface IActualAssignmentBar extends IActualProgressBlockWrapper {
    isThereProjectedBeforeArrow: boolean;
    isThereProjectedAfterArrow: boolean;
    calculatedAssignmentWidth: number;
    calculatedLeft: number;
    showPointerCursor: boolean;
    plannedAndActualAssignmentsOverlap?: boolean;
}

const actualAssignmentTestIds = {
    barId: (idExpression) => `${idExpression}_actual_progress_bar`,
};

export const ActualAssignmentBar: React.FunctionComponent<IActualAssignmentBar> = React.memo(
    function ActualAssignmentBar(props) {
        const {
            isTherePlannedBeforeArrow,
            isTherePlannedAfterArrow,
            projectedStartDate,
            projectedCompletionDate,
            startDate,
            endDate,
            stepUnit,
            color,
            isAccessible,
            height,
            isForProject,
            isCompleted,
            title,
            isThereProjectedBeforeArrow,
            isThereProjectedAfterArrow,
            calculatedAssignmentWidth,
            calculatedLeft,
            showPointerCursor,
            taskAssignmentBackgroundColor,
            plannedAndActualAssignmentsOverlap,
            idExpression,
            onMouseOver,
            onMouseMove,
            onMouseLeave,
            ID,
            durationPadding,
        } = props;

        let shouldCloseLeftArrow = false;
        let shouldCloseRightArrow = false;
        if (
            isTherePlannedBeforeArrow &&
            projectedStartDate &&
            startDate.isSame(projectedStartDate, stepUnit)
        ) {
            shouldCloseLeftArrow = true;
        }
        if (
            isTherePlannedAfterArrow &&
            projectedCompletionDate &&
            endDate.isSame(projectedCompletionDate, stepUnit)
        ) {
            shouldCloseRightArrow = true;
        }

        const doneMarkMsg = isForProject ? MsgKeyCompleted.PROJECT : MsgKeyCompleted.TASK;

        const fromLeft = calculatedAssignmentWidth + calculatedLeft;

        const fontComponentDetails = getFontType(isAccessible, idExpression, Project);
        const nameAsLinkProps = getLinkRelatedProps(true, fontComponentDetails);

        const isAssignmentAccessible = Boolean(isAccessible);
        const projectedAssignmentTitle = (
            <>
                {title ? (
                    <>
                        {isForProject ? (
                            <ProjectAssignmentTitle
                                showLockIcon={!isAssignmentAccessible}
                                isAccessible={isAssignmentAccessible}
                                title={title}
                                testID={dataTestIds.title_actual_progress}
                                nameAsLinkProps={nameAsLinkProps}
                            />
                        ) : (
                            <AssignmentTitle
                                assignmentColorText={primary.gray(700)}
                                shouldMarkAsCompleted={false}
                                title={title}
                                assignmentWidth={calculatedAssignmentWidth}
                                testID={dataTestIds.title_actual_progress}
                                textWeight={600}
                            />
                        )}
                    </>
                ) : null}
            </>
        );

        return (
            <LeftRightArrowWrapper
                isThereProjectedBeforeArrow={isThereProjectedBeforeArrow}
                isThereProjectedAfterArrow={isThereProjectedAfterArrow}
                isForProject={isForProject}
                height={height}
                color={color}
                taskAssignmentBackgroundColor={taskAssignmentBackgroundColor}
                plannedAndActualAssignmentsOverlap={plannedAndActualAssignmentsOverlap}
                fromLeft={fromLeft}
                durationPadding={durationPadding}
            >
                {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                <div
                    data-testid={actualAssignmentTestIds.barId(ID)}
                    {...(!isForProject && {
                        onMouseOver,
                        onMouseMove,
                        onMouseLeave,
                    })}
                    className={actualProgressBlockClass({
                        calculatedAssignmentWidth,
                        calculatedLeft,
                        isThereProjectedBeforeArrow,
                        isThereProjectedAfterArrow,
                        height,
                        color,
                        shouldCloseLeftArrow,
                        shouldCloseRightArrow,
                        showPointerCursor,
                        title,
                        isForProject,
                        durationPadding,
                    })}
                >
                    {projectedAssignmentTitle}
                    {title && isCompleted ? <DoneMark msg={doneMarkMsg} /> : null}
                </div>
            </LeftRightArrowWrapper>
        );
    }
);

export const borderColorRedesign = convertHexToRGBA(primary.blue(600), 10);

const noLeftBorder = css`
    border-left: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
`;

const noRightBorder = css`
    border-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
`;

interface IActualProgressBlock
    extends Pick<
        IActualAssignmentBar,
        | 'calculatedAssignmentWidth'
        | 'calculatedLeft'
        | 'isThereProjectedBeforeArrow'
        | 'isThereProjectedAfterArrow'
        | 'height'
        | 'color'
        | 'showPointerCursor'
        | 'title'
        | 'isForProject'
    > {
    shouldCloseLeftArrow: boolean;
    shouldCloseRightArrow: boolean;
    durationPadding: number;
}

const actualProgressBlockClass = ({
    calculatedAssignmentWidth,
    calculatedLeft,
    isThereProjectedBeforeArrow,
    isThereProjectedAfterArrow,
    height,
    showPointerCursor,
    title,
    isForProject,
    durationPadding,
}: IActualProgressBlock): string => {
    const baseRule = css`
        display: flex;
        align-items: center;
        width: ${calculatedAssignmentWidth}px;
        left: ${calculatedLeft}px;
        height: ${height}px;
        position: absolute;
        background: ${borderColorRedesign};
        cursor: ${showPointerCursor ? 'pointer' : 'default'};
        pointer-events: ${showPointerCursor || title ? '' : 'none'};
    `;

    if (!isForProject) {
        const projectedBeforeArrow = isThereProjectedBeforeArrow ? noLeftBorder : '';
        const projectedAfterArrow = isThereProjectedAfterArrow ? noRightBorder : '';

        return cx(
            baseRule,
            taskBarProjectedOverlay,
            css`
                ${projectedBeforeArrow};
                ${projectedAfterArrow}
            `
        );
    }
    const leftRightWidthOffset = 2 * durationPadding;
    const width = isForProject
        ? calculatedAssignmentWidth + leftRightWidthOffset
        : calculatedAssignmentWidth;
    const left = isForProject ? calculatedLeft - durationPadding : calculatedLeft;

    return cx(
        baseRule,
        css`
            ${projectBarProjectedOverlay(isThereProjectedBeforeArrow, isThereProjectedAfterArrow)};
            width: ${width}px;
            left: ${left}px;
        `
    );
};

const projectBarProjectedOverlay = (
    isThereProjectedBeforeArrow: boolean,
    isThereProjectedAfterArrow: boolean
): string => {
    const before = isThereProjectedBeforeArrow
        ? ''
        : css`
              &:before {
                  content: '';
                  border: 3px solid;
                  position: absolute;
                  left: 0;
                  top: 0;
                  border-color: ${primary.blue(600)} transparent transparent ${primary.blue(600)};
              }
          `;

    const after = isThereProjectedAfterArrow
        ? ''
        : css`
              &:after {
                  content: '';
                  border: 3px solid;
                  position: absolute;
                  right: 0;
                  top: 0;
                  border-color: ${primary.blue(600)} ${primary.blue(600)} transparent transparent;
              }
          `;

    return css`
        border-top: 2px solid ${primary.blue(600)};
        background: ${borderColorRedesign};
        z-index: ${projectedAssignmentZIndex};
        ${before};
        ${after};
    `;
};

const taskBarProjectedOverlay = css`
    border: 1px solid ${primary.blue(600)};
    background: ${borderColorRedesign};
    border-radius: 3px;
    box-shadow: 0 5px 2px -3px rgba(0, 0, 0, 0.25);
    z-index: ${projectedAssignmentZIndex};
`;
