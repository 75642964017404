import { IWorkSchedulingCombinedState } from '../../types';
import {
    TAvailableHeight,
    TIsDragging,
    TPeopleWorkLoadHeight,
    TResizableHeight,
    TSnapToZero,
    TTableLeftSidePanelWidth,
} from '../../data/IDataState';

export function peopleWorkLoadHeightSelector(
    state: IWorkSchedulingCombinedState
): TPeopleWorkLoadHeight {
    return state.TableSizes.peopleWorkLoadHeight;
}

export const isDraggingSelector = (state: IWorkSchedulingCombinedState): TIsDragging => {
    return state.TableSizes.isDragging;
};

export const snapToZeroSelector = (state: IWorkSchedulingCombinedState): TSnapToZero => {
    return state.TableSizes.snapToZero;
};

export function resizableHeightSelector(state: IWorkSchedulingCombinedState): TResizableHeight {
    return state.TableSizes.resizableHeight;
}

export function availableHeightSelector(state: IWorkSchedulingCombinedState): TAvailableHeight {
    return state.TableSizes.availableHeight;
}

export function tableLeftSidePanelWidthSelector(
    state: IWorkSchedulingCombinedState
): TTableLeftSidePanelWidth {
    return state.TableSizes.tableLeftSidePanelWidth;
}
