import { unassignedTasksSortObject } from '../../../constants/dataConstatnts';
import { sortObject } from '../../../util/utilities';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'SORT_UNASSIGNED_PROJECTS_TASKS';

interface ISortUnassignedProjectsTasksActionPayload {
    projectId: string;
}

type TSortUnassignedProjectsTasksAction =
    TModernSchedulingAction<ISortUnassignedProjectsTasksActionPayload>;

const sortUnassignedProjectsTasks = (projectId): TSortUnassignedProjectsTasksAction => ({
    type: ActionType,
    payload: {
        projectId,
    },
});
export default sortUnassignedProjectsTasks;

export function handle(
    state: IDataState,
    { payload }: TSortUnassignedProjectsTasksAction
): IDataState {
    const unassignedTasksProjects = { ...state.unassignedTasksProjects };
    const nodes = [...unassignedTasksProjects[payload.projectId].nodes].sort(
        sortObject(state.unassignedTasks, unassignedTasksSortObject)
    );

    unassignedTasksProjects[payload.projectId] = {
        ...unassignedTasksProjects[payload.projectId],
        nodes,
    };

    return {
        ...state,
        unassignedTasksProjects,
    };
}
