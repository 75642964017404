import { createSelector } from 'reselect';
import { IWorkSchedulingCombinedState } from '../../../types';
import { IProjectColor, TOrUndefined, TProjectID } from '../../IDataState';
import { projectColorsModeSelector } from '../../../settings/settingsSelector';
import { isColorModeProject, isColorModeProjectStatus } from '../../../../util/colorUtils';
import { projectGroupColorsSelector } from '../dataSelectors';
import { projectColorCachedSelector } from '../../../projectColors/selectors/projectColorSelector/projectColorSelector';

export const projectColorDeciderSelector = (
    state: IWorkSchedulingCombinedState,
    projectID: TProjectID,
    projectGroupId?: string,
    projectStatus?: string
): TOrUndefined<IProjectColor> => {
    const colorMode = projectColorsModeSelector(state);
    if (isColorModeProjectStatus(colorMode)) {
        return projectStatusColorSelector(state, projectGroupId, projectStatus);
    }

    if (isColorModeProject(colorMode)) {
        return projectColorCachedSelector(state, { projectID });
    }
};

export const projectStatusColorSelector = (
    state: IWorkSchedulingCombinedState,
    projectGroupId?: string,
    projectStatus?: string
): IProjectColor => {
    return projectStatusSelectorByKey(state, 'colors', projectGroupId, projectStatus);
};

export const projectStatusLabelSelector = (
    state: IWorkSchedulingCombinedState,
    projectGroupId?: string,
    projectStatus?: string
): string => {
    return projectStatusSelectorByKey(state, 'labels', projectGroupId, projectStatus);
};

export const getProjectStatusSelector = createSelector(
    [projectColorsModeSelector, projectGroupColorsSelector],
    (colorMode, groupColors) => {
        return (
            key: string,
            projectGroupId?: string,
            projectStatus?: string
        ): TOrUndefined<IProjectColor> => {
            let selector;

            if (isColorModeProjectStatus(colorMode)) {
                if (projectGroupId && projectStatus) {
                    selector = groupColors?.[projectGroupId]?.[key]?.[projectStatus];
                }
            }

            return selector;
        };
    }
);

export const projectStatusSelectorByKey = (
    state: IWorkSchedulingCombinedState,
    key: string,
    projectGroupId?: string,
    projectStatus?: string
): any => {
    const selector = getProjectStatusSelector(state);
    return selector(key, projectGroupId, projectStatus);
};
