import createCachedSelector from 're-reselect';
import _ from 'lodash';
import {
    userByIdSelector,
    userProjectNodesByIdSelector,
    userProjectNodeSelector,
} from '../../users/usersSelector';
import { tasksAndIssuesSelector } from '../../dataSelectors';

export const inaccessibleItemsWorkRequiredPerProjectSelector = createCachedSelector(
    [userProjectNodeSelector],
    (projectNode) => {
        return projectNode.inaccessibleNodesWorkRequired;
    }
)((state, { userID, projectID }) => `${userID}:${projectID}`);

export const projectWorkRequiredSelector = createCachedSelector(
    [
        userProjectNodesByIdSelector,
        tasksAndIssuesSelector,
        userByIdSelector,
        inaccessibleItemsWorkRequiredPerProjectSelector,
    ],
    (projectTasks, tasksAndIssues, user, inaccessibleWorkRequired) => {
        let projectWorkRequired: number = inaccessibleWorkRequired || 0;

        _.forEach(projectTasks, (obejctID) => {
            projectWorkRequired += tasksAndIssues[obejctID].workRequired[user.ID];
        });

        return projectWorkRequired;
    }
)((state, { userID, projectID }) => `${userID}:${projectID}`);
