import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'REMOVE_WORKING_HOURS';

interface IRemoveWorkingHoursActionPayload {
    usersIDs: string[];
}

type TRemoveWorkingHoursAction = TModernSchedulingAction<IRemoveWorkingHoursActionPayload>;

const removeWorkingHoursForPeriod = (usersIDs): TRemoveWorkingHoursAction => ({
    type: ActionType,
    payload: {
        usersIDs,
    },
});
export default removeWorkingHoursForPeriod;

export function handle(state: IDataState, { payload }: TRemoveWorkingHoursAction): IDataState {
    const users = { ...state.users };

    payload.usersIDs.forEach((userID: string) => {
        users[userID] = {
            ...users[userID],
            userWorkPerDayForPeriod: [],
        };
    });

    return {
        ...state,
        users,
    };
}
