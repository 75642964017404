import { createSelector } from 'reselect';
import { tableDataIDsForUnassignedSectionWithoutHeader } from '../dataSelectors';
import { TTableDataIDs } from '../../IDataState';
import { IWorkSchedulingCombinedState } from '../../../types';

export const projectIDsWithinTableDataIDsForUnassignedSectionSelector = createSelector(
    (state: IWorkSchedulingCombinedState) =>
        tableDataIDsForUnassignedSectionWithoutHeader(state.Data),
    (unassignedTableDataIDs): TTableDataIDs => {
        return unassignedTableDataIDs.filter((ID) => ID.split('_').length === 1);
    }
);
