import { BrowserClient } from '@workfront/localize-react';

export const localizationClient = new BrowserClient({
    namespace: ['redrock', 'filters', 'datatable-fvg'],
    enableTerminology: true,
});

export const LOCALE_CODE = localizationClient.getLocale();

export const clientFactory = (): BrowserClient => localizationClient;
