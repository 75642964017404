import { primary } from '@phoenix/all';
import styled, { css, keyframes } from 'react-emotion';

import appColors, { hex_E0E1E3, hex_ECECED, RGBA_COLORS } from '../../constants/appColors';
import {
    ACCESSIBLE_PROJECT_PRIMARY_DARK_BACKGROUND_COLOR,
    INACCESSIBLE_DURATION_COLOR,
} from '../../constants/colors';
import { Sections, sizes } from '../../constants/schedulingTableConstants';
import { inlineItemsCentered } from '../../styles';
import { transformRGBAColorKey } from '../../util/utilities';
import { beforePeriodZIndex } from '../../constants/zIndexes';

export const arrowsSharpness = 20;
export const beforeAndAfterPeriod = css`
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    z-index: ${beforePeriodZIndex};
`;

const allocationsEditingModeStyle = (allocationsEditingMode: boolean | string | null): string => {
    return allocationsEditingMode ? `background-color: ${primary.white()};` : '';
};

export const beforePeriodStyle = (
    borderColor: string | undefined,
    allocationsEditingMode: boolean | string | null,
    isInDisabledMode: boolean
): string => css`
    ${beforeAndAfterPeriod}
    ${allocationsEditingModeStyle(allocationsEditingMode)}
    opacity: ${isInDisabledMode ? 0.7 : 1};
    border-right: 10px solid ${borderColor};
`;

export const beforeProject = (statusColor?: string): string => css`
    ${beforeAndAfterPeriod};
    border-bottom-width: ${arrowsSharpness}px;
    border-top-width: ${arrowsSharpness}px;
    border-right: 10px solid ${statusColor || ACCESSIBLE_PROJECT_PRIMARY_DARK_BACKGROUND_COLOR};
    opacity: 0.2;
`;

export const beforeInaccessiblePeriodRedesign = css`
    ${beforeAndAfterPeriod};
    border-right: 10px solid ${INACCESSIBLE_DURATION_COLOR};
    opacity: 0.2;
`;

export const beforeInaccessibleProjectRedesign = css`
    ${beforeAndAfterPeriod};
    border-bottom-width: ${arrowsSharpness}px;
    border-top-width: ${arrowsSharpness}px;
    border-right: 10px solid ${INACCESSIBLE_DURATION_COLOR};
    opacity: 0.2;
`;

export const afterProject = (statusColor?: string): string => css`
    ${beforeAndAfterPeriod};
    border-bottom-width: ${arrowsSharpness}px;
    border-top-width: ${arrowsSharpness}px;
    border-left: 10px solid ${statusColor || ACCESSIBLE_PROJECT_PRIMARY_DARK_BACKGROUND_COLOR};
    opacity: 0.2;
`;

export const AfterPeriod = styled('span')<{
    borderColor: string | undefined;
    isInDisableMode: boolean;
}>`
    ${beforeAndAfterPeriod};
    opacity: ${(props) => (props.isInDisableMode ? 0.7 : 1)};
    border-left: 10px solid ${(props) => props.borderColor};
`;

export const afterInaccessibleProjectRedesign = css`
    ${beforeAndAfterPeriod};
    border-bottom-width: ${arrowsSharpness}px;
    border-top-width: ${arrowsSharpness}px;
    border-left: 10px solid ${INACCESSIBLE_DURATION_COLOR};
    opacity: 0.2;
`;

export const afterInaccessiblePeriodRedesign = css`
    ${beforeAndAfterPeriod};
    border-left: 10px solid ${INACCESSIBLE_DURATION_COLOR};
    opacity: 0.2;
`;

// todo under split, only user case, only visual ON
const cellStyle = (): string => css`
    border-left: ${sizes.gridLightGreyBorderWidth}px solid
        ${RGBA_COLORS[transformRGBAColorKey(hex_E0E1E3, 80)]};
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    min-width: ${sizes.cellMinWidthDayModeRedesign}px;
    border-bottom: ${sizes.borderMainWidthForGridView}px solid ${primary.gray(200)};
    &:last-child {
        border-right: ${sizes.gridLightGreyBorderWidth}px solid ${primary.gray(200)};
    }
    &:first-child {
        border-left: ${sizes.gridLightGreyBorderWidth}px solid ${primary.gray(200)};
    }
`;

export const cell = (): string => css`
    ${cellStyle()};
    padding: 5px 4px;
    position: relative;
    &:hover {
        cursor: default;
    }
    background-color: ${primary.white()};
`;

const styleBasedOnHoverable = (shouldBeHoverable): string | null =>
    shouldBeHoverable
        ? `&:hover {
				z-index: 2;
				box-shadow: ${appColors.visualizationOnItemHover['0.16']} -5px 0px 10px -5px, ${appColors.visualizationOnItemHover['0.16']} 5px 0px 10px -5px;
			}`
        : null;

export const userRowStyleOnAllocationVisualization = (shouldBeHoverable: boolean): string => css`
    ${cell()};
    padding: 0;
    border-left: none;
    ${styleBasedOnHoverable(shouldBeHoverable)}
`;

export const flexCell = (): string => css`
    ${cell()};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const unassignedCell = (cellSolidBorderBottom?: boolean): string => css`
    ${cell()};
    border-left: 1px solid ${RGBA_COLORS[transformRGBAColorKey(hex_E0E1E3, 50)]} !important;
    &:first-child {
        border-left: 1px solid ${primary.gray(200)} !important;
    }
    border-bottom: 1px ${cellSolidBorderBottom ? 'solid' : 'dashed'} ${primary.gray(200)};
`;

const borderBottomStyle = (isLastRowUnderUser, cellSolidBorderBottom): string =>
    !isLastRowUnderUser
        ? `1px ${cellSolidBorderBottom ? 'solid' : 'dashed'} ${primary.gray(200)}`
        : '';

export const greyCell = (isLastRowUnderUser, cellSolidBorderBottom?: boolean): string => css`
    ${cellStyle()};
    border-left: 1px solid ${RGBA_COLORS[transformRGBAColorKey(hex_E0E1E3, 50)]} !important;
    padding: 6px 4px;
    position: relative;
    &:hover {
        cursor: default;
    }
    background-color: ${primary.gray(50)};
    border-bottom: ${borderBottomStyle(isLastRowUnderUser, cellSolidBorderBottom)};
`;

export const emptyRowRightCell = (): string => css`
    ${cellStyle()};
    ${unassignedCell()};
    background-color: ${primary.white()};
    padding: 6px 4px;
    position: relative;
`;

export const cellWithoutBorderBottom = css`
    border-bottom: 0px !important;
`;

export const cellWithoutBorderLeft = css`
    border-left: 0px !important;
`;

export const weekEndStyle = (): string => css`
    ${cellStyle()};
    background: none !important;
    height: 100%;
`;

export const weekEndStyleGrey = (
    isLastRowUnderUser,
    cellSolidBorderBottom?: boolean
): string => css`
    ${cellStyle()};
    border-left: ${sizes.gridLightGreyBorderWidth}px solid
        ${RGBA_COLORS[transformRGBAColorKey(hex_E0E1E3, 50)]} !important;
    background-color: ${RGBA_COLORS[transformRGBAColorKey(hex_ECECED, 18)]};
    height: 100%;
    border-bottom: ${borderBottomStyle(isLastRowUnderUser, cellSolidBorderBottom)};
`;

export const loading = keyframes`
 0% {
    opacity: .6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .6;
  }
`;

export const whiteBackground = css`
    background-color: ${primary.gray(0)};
`;

export const animationStyle = css`
    opacity: 0.6;
    -webkit-animation: ${loading} 1s ease infinite;
    -moz-animation: ${loading} 1s ease infinite;
`;

export const greyLoadingCell = css`
    background-color: ${primary.gray(200)};
    border-radius: 2px;
    min-width: 30px;
    width: 100%;
    height: 30px;
    ${animationStyle}
`;

export const cellWrapper = css`
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
`;

export const toolTipContentTitle = css`
    font-weight: 600;
`;

export const sharedStyle = css`
    & {
        color: ${primary.white()};
    }
    ${inlineItemsCentered};
    border-radius: 3px;
    position: relative;
    height: ${sizes.taskAssignmentHeight}px;
    z-index: 2;
`;

export const setOpacity = (isComplete): number => {
    return isComplete ? 0.7 : 1;
};

interface ICompleteStyle {
    isAfterPeriod: boolean;
}

interface ITitleStyle {
    assignmentWidth: number;
    color?: string;
    isCompleted?: boolean;
    textWeight?: number;
}

export const CompleteStyle = styled('span')<ICompleteStyle>`
    position: absolute;
    right: ${(props) => (props.isAfterPeriod ? '40px' : '20px')};
    top: 50%;
    margin: -12px;
`;

export const assignmentDetails = css`
    display: flex;
    z-index: 0;
    overflow: hidden;
`;

export const TitleStyle = styled('div')<ITitleStyle>`
    line-height: 16px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    max-width: ${(props) => props.assignmentWidth - 8}px;
    color: ${(props) => props.color || primary.white()} !important;
    margin-left: 8px;
    margin-right: 8px;
    font-weight: ${(props) => (props.textWeight ? props.textWeight : '')};
    ${(props) => (props.isCompleted ? 'opacity: 70%;' : '')};
`;

export const headerItemsStyles = css`
    color: ${primary.gray(500)};
    margin-left: 4px !important;
    height: 30px !important;
    &:hover {
        text-decoration: none !important;
    }
    font-weight: 500;
    line-height: 30px;
    &:disabled {
        pointer-events: none;
    }
`;

export const arrowCaretCommon = css`
    padding-right: 4px;
`;

export const arrowCaret = css`
    ${arrowCaretCommon};
    padding-left: 12px !important;
`;

export const arrowCaretPW = css`
    ${arrowCaretCommon};
    padding-left: 24px !important;
`;

export const projectDetails = css`
    display: flex;
    align-items: center;
    min-width: 110px;
    flex: 1;
`;

export const workRequired = css`
    font-size: 12px;
    color: ${primary.gray(300)};
    cursor: default;
    max-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const workRequiredHourAbbr = css`
    font-size: 12px;
    color: ${primary.gray(300)};
    margin-right: 8px;
    cursor: default;
`;

export const wrapperDivStyled = css`
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    align-items: center;
    position: relative;
    border-bottom: 1px solid ${primary.gray(200)};
`;

export const loadingCssGrey = css`
    width: 100%;
    background-color: ${primary.gray(200)};
    border-radius: 3px;
    height: 26px;
    ${animationStyle}
`;

interface IDivContentStyle {
    loadingProjectItem: boolean;
    width?: number;
    marginLeft?: number;
}

const widthStyle = (width?: number): string => {
    return width ? `width: ${width}px;` : '';
};
const marginStyle = (marginLeft?: number): string => {
    return marginLeft ? `margin-left: ${marginLeft}px;` : '';
};

export const DivContentGrey = styled('div')<IDivContentStyle>`
    ${loadingCssGrey}
    ${(props) => widthStyle(props.width)}
	${(props) => marginStyle(props.marginLeft)}
`;

export const projectDiv = css`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    position: relative;
`;

export const taskIssueDiv = css`
    line-height: normal;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 8px;
    align-items: center;
`;

export const taskIssueDuration = css`
    font-size: 12px;
    color: ${primary.gray(300)};
    cursor: default;
    max-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const taskIssueDurationHourAbbr = css`
    font-size: 12px;
    color: ${primary.gray(300)};
    cursor: default;
`;

export const taskIssueNodeContainer = css`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const unlockIconStyles = css`
    padding-right: 4px;
    display: flex;
`;

export const tooltipContentStyles = css`
    max-width: 220px;
`;

export const TaskIssueNameStyled = styled('div')`
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    font-size: 14px;
    padding-right: 7px;
    max-width: 90%;
`;

export const WorkingHoursStyled = styled(`div`)`
    height: 14px; // this is as using Ellipse brings some height up/downs, and text is not centered
    display: flex;
    font-weight: bold;
`;

export const projectIcon = css`
    height: 16px;
    padding: 0 5px 0 1px;
`;

export interface ITAskIssueDivStyled {
    isThereUnassignedEmptySpace?: boolean;
    isLastRow: boolean;
    isLastRowUnderUser: boolean;
    projectGrouping: boolean;
    paddingLeft: number;
    sectionType?: Sections;
    isAssignedTaskOrIssue: boolean;
}

export interface IProjectDivStyled {
    sectionType?: Sections;
    isBottomLineHidden?: boolean;
    isLastRow: boolean;
    expanded: boolean;
}

export const rightPadding = css`
    margin-right: 16px;
`;

export const padding_r_6 = css`
    padding-right: 6px;
`;

export const padding_l_6 = css`
    padding-left: 6px;
`;

export const padding_rl_6 = css`
    ${padding_r_6}
    ${padding_l_6}
`;

export const iconsWrapper = css`
    display: flex;
`;
