import React from 'react';
import { Tooltip } from '@phoenix/all';
import { Moment } from 'moment';
import { cx } from 'react-emotion';
import { connect } from 'react-redux';
import {
    IFullTimeOffSteps,
    ITimeOffsState,
    IWorkDays,
    TMessageKey,
    TOrUndefined,
} from '../../../../data-flow/data/IDataState';
import { getPeriodModesValues } from '../../../../util/periodUtils/periodUtils';
import {
    getFullTimeOffRangeWithCount,
    getFullTimeOffSteps,
} from '../../../../data-flow/data/selectors/dataSelectors';
import {
    convertMinuteToHour,
    getDateMessageKey,
    isThereAnyWorkingDayInWeek,
    isWorkingDayBySchedule,
} from '../../../../util/utilities';
import { DATE_FORMAT } from '../../../../constants/dataConstatnts';
import { getTimeOffMessageKey } from '../../../../util/timeOffsService';
import { sizes } from '../../../../constants/schedulingTableConstants';
import { chartGetY, yScale } from '../../../../util/chartUtils';
import UserFocusChartComponent from '../../chart/UserFocusChartComponent';
import { HalfTimeOff } from './HalfTimeOff';
import { NoAllocation } from './NoAllocation';
import { Hour } from './Hour';
import {
    cell,
    userRowStyleOnAllocationVisualization,
    weekEndStyle,
} from '../../../styles/sharedStyles';
import { dataTestIds } from './UserHours';
import { AllocationTooltipContent } from './AllocationTooltipContent';
import { IWorkSchedulingCombinedState } from '../../../../data-flow/types';
import { availableHoursSelector } from '../../../../data-flow/data/selectors/reselect/availableHoursSelector/availableHoursSelector';
import { scheduleWorkDaysSelector } from '../../../../data-flow/data/selectors/reselect/scheduleWorkDaysSelector/scheduleWorkDaysSelector';
import { getUserSelector } from '../../../../data-flow/data/selectors/users/usersSelector';
import { scheduleHoursSelector } from '../../../../data-flow/data/selectors/rereselect/scheduleHoursSelector/scheduleHoursSelector';
import {
    showAllocationsInHoursModeSelector,
    showRemainingHoursSelector,
} from '../../../../data-flow/settings/settingsSelector';
import { IGeneralStateTypes } from '../../../../data-flow/IGeneralStateTypes';
import { FullTimeOffs, fullTimeOffsTestIds, RangeFullTimeOffStyled } from './FullTimeOffs';
import { weekMessageKeys } from '../../../../messageKeysForDate';

type IStepStateProps = Pick<
    IGeneralStateTypes,
    'showRemainingHours' | 'showAllocationsInHoursMode'
> & {
    availableHours: number[];
    scheduleWorkDays: IWorkDays;
    scheduleHours: { scheduleHours: number[]; averageOfMinutes: number };
};

type IStepsProps = Pick<
    IGeneralStateTypes,
    'steps' | 'activePeriodMode' | 'stepUnit' | 'userID' | 'isInAssignmentProcess'
> & {
    workPerDay: number[];
    timeOffs: ITimeOffsState;
    isAllocationsVisualizationOn: boolean;
};

interface AllocationConditionsProps {
    timeOffs: ITimeOffsState;
    isWorkingDay: boolean;
    fullTimeOffsSteps: IFullTimeOffSteps;
    stepDateString: string;
    hourPerDay: number;
}

type timeOffMessageKeyAndFallback = {
    timeOffMessageKey: TOrUndefined<string>;
    timeOffMessageFallback: TOrUndefined<string>;
};

export const StepsComponent: React.FC<IStepsProps & IStepStateProps> = React.memo((props) => {
    const {
        steps,
        activePeriodMode,
        availableHours,
        workPerDay,
        scheduleWorkDays,
        timeOffs,
        isAllocationsVisualizationOn,
        stepUnit,
        userID,
        scheduleHours,
        showRemainingHours,
        isInAssignmentProcess,
        showAllocationsInHoursMode,
    } = props;

    const { isDayMode, isWeekMode } = getPeriodModesValues(activePeriodMode);
    const fullTimeOffsSteps = getFullTimeOffSteps(timeOffs, isWeekMode);
    let prevWeight = 0;

    const fullTimeOffWithRange = getFullTimeOffRangeWithCount(timeOffs);
    const fullTimeOff = isWeekMode ? fullTimeOffsSteps : fullTimeOffWithRange;

    const stepsList = steps.map((step, index) => {
        let totalCount;
        let tooltipProps;
        let hasAllocation;
        let shouldWrapByTooltip = true;
        let component: JSX.Element | null = null;
        let timeOffOverlay: JSX.Element | null = null;

        const day = step.format('DD');
        const weekNumber = step.isoWeek() + 1;
        const hourPerDay = workPerDay[index] || 0;
        const stepDateString = step.format(DATE_FORMAT);
        const workPerDayHour = convertMinuteToHour(hourPerDay);
        const messageKeyIndex = step.clone().subtract(1, 'd').day();
        const timeOffAtStep = fullTimeOff?.[step.format(DATE_FORMAT)];
        const weekMsgKey = getDateMessageKey(weekMessageKeys, messageKeyIndex);
        const availableHourForUser = convertMinuteToHour(availableHours[index]);
        const isWorkingDay = shouldBeWorkingDay(isDayMode, scheduleWorkDays, step);
        const isWeekEnd = !isWorkingDay || fullTimeOffsSteps[stepDateString];
        const weekEndClass = isWeekEnd ? weekEndStyle() : '';

        const [timeOffMessageKey, timeOffMessage] = getTimeOffMessages(
            timeOffs,
            stepDateString,
            fullTimeOffsSteps,
            isWeekMode
        );
        const [halfTimeOffCondition, noAllocationCondition] = getAllocationConditions({
            timeOffs,
            isWorkingDay,
            fullTimeOffsSteps,
            stepDateString,
            hourPerDay,
        });

        if (timeOffAtStep) {
            hasAllocation = steps.some(
                (currentStep, currentIndex) =>
                    currentStep.isBetween(
                        timeOffAtStep.startDate,
                        timeOffAtStep.endDate,
                        undefined,
                        '[]'
                    ) && !!workPerDay[currentIndex]
            );

            totalCount = isWeekMode ? 1 : timeOffAtStep.totalDayOffCount;

            timeOffOverlay = (
                <RangeFullTimeOffStyled
                    stepCount={totalCount}
                    hasTopBorder
                    data-testid={
                        timeOffAtStep.isUserTimeOff
                            ? fullTimeOffsTestIds.fullTimeOff
                            : fullTimeOffsTestIds.fullException
                    }
                    isAllocationsVisualizationOn={isAllocationsVisualizationOn}
                >
                    {!hasAllocation ? (
                        <FullTimeOffs timeOffAtStep={timeOffAtStep} timeOffStepCount={totalCount} />
                    ) : null}
                </RangeFullTimeOffStyled>
            );
            component = timeOffOverlay;
        }

        if (isAllocationsVisualizationOn) {
            const height = sizes.tableRowHeightChartView;
            const scaledHeight = yScale(hourPerDay, stepUnit);
            const weight = chartGetY(height, scaledHeight);

            shouldWrapByTooltip = shouldBeWrapByTooltip(
                halfTimeOffCondition,
                noAllocationCondition,
                hourPerDay
            );

            component = (
                <>
                    {timeOffOverlay}
                    <UserFocusChartComponent
                        index={index}
                        availableHour={availableHours[index]}
                        height={height}
                        prevAvailableHour={availableHours[index - 1]}
                        prevWorkPerDay={workPerDay[index - 1]}
                        prevWeight={prevWeight}
                        isOverTimeOff={shouldWrapByTooltip}
                        scaledHeight={scaledHeight}
                        userID={userID}
                        weight={weight}
                        workPerDay={hourPerDay}
                    />
                </>
            );

            prevWeight = weight;

            tooltipProps = {
                nonWorkHours: timeOffs[stepDateString]?.nonWorkHours,
                isUserTimeOff: timeOffs[stepDateString]?.isUserTimeOff,
                ...timeOffMessage,
                averageOfSchedule: scheduleHours.averageOfMinutes,
                halfTimeOffCondition,
                isWeekMode,
            };
        } else if (halfTimeOffCondition) {
            component = (
                <HalfTimeOff
                    workingHours={availableHours[index]}
                    hourPerDay={hourPerDay}
                    showRemainingHours={showRemainingHours}
                    isHalfTimeOff={halfTimeOffCondition}
                    isInAssignmentProcess={isInAssignmentProcess}
                    showAllocationsInHoursMode={showAllocationsInHoursMode}
                    scheduleHour={scheduleHours.scheduleHours[index] || 0}
                />
            );

            tooltipProps = {
                nonWorkHours: timeOffs[stepDateString]?.nonWorkHours,
                isUserTimeOff: timeOffs[stepDateString]?.isUserTimeOff,
                ...timeOffMessage,
                averageOfSchedule: scheduleHours.averageOfMinutes,
                halfTimeOffCondition,
                isWeekMode,
            };
        } else if (noAllocationCondition) {
            component = (
                <NoAllocation
                    hourPerDay={hourPerDay}
                    showRemainingHours={showRemainingHours}
                    workingHours={availableHours[index]}
                    isInAssignmentProcess={isInAssignmentProcess}
                    showAllocationsInHoursMode={showAllocationsInHoursMode}
                />
            );

            tooltipProps = {
                ...timeOffMessage,
            };
        } else if (hourPerDay && !halfTimeOffCondition) {
            if (timeOffAtStep) {
                component = (
                    <>
                        <RangeFullTimeOffStyled
                            stepCount={totalCount}
                            hasTopBorder
                            isAllocationsVisualizationOn={isAllocationsVisualizationOn}
                        />
                        <Hour
                            hourPerDay={hourPerDay}
                            workingHours={availableHours[index]}
                            showRemainingHours={showRemainingHours}
                            isInAssignmentProcess={isInAssignmentProcess}
                            showAllocationsInHoursMode={showAllocationsInHoursMode}
                            scheduleHour={scheduleHours.scheduleHours[index]}
                            isNotWorkingDay={
                                !isWorkingDay || fullTimeOffsSteps[stepDateString] !== undefined
                            }
                        />
                    </>
                );
            } else {
                component = (
                    <Hour
                        hourPerDay={hourPerDay}
                        workingHours={availableHours[index]}
                        showRemainingHours={showRemainingHours}
                        isInAssignmentProcess={isInAssignmentProcess}
                        showAllocationsInHoursMode={showAllocationsInHoursMode}
                        scheduleHour={scheduleHours.scheduleHours[index]}
                        isNotWorkingDay={
                            !isWorkingDay || fullTimeOffsSteps[stepDateString] !== undefined
                        }
                    />
                );
            }

            tooltipProps = {
                ...timeOffMessageKey,
                isNotWorkingDay: !isWorkingDay,
            };
        }

        const toolTipContent = getTooltipContent({
            availableHourForUser,
            workPerDayHour,
            scheduleHour: scheduleHours.scheduleHours[index] || 0,
            showAllocationsInHoursMode,
            ...tooltipProps,
        });
        const visualizationOrCellClass = !isAllocationsVisualizationOn
            ? cell()
            : cx(
                  userRowStyleOnAllocationVisualization(shouldWrapByTooltip),
                  'user-row-style-on-allocation-visualization-selector'
              );

        return (
            <div
                // Because div is not interactive element, so tabindex with 0 value makes it interactive for voiceover
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                role="cell"
                key={step.unix()}
                aria-describedby={`${userID} ${day}-${weekMsgKey} ${weekNumber}-week`}
                className={`${visualizationOrCellClass} ${weekEndClass} assigned-cell-day_${userID}`}
                data-testid={
                    isWeekEnd ? dataTestIds.userHoursWeekend : dataTestIds.userHoursWeekday
                }
            >
                {shouldWrapByTooltip ? (
                    <Tooltip content={toolTipContent} delay={700}>
                        {component}
                    </Tooltip>
                ) : (
                    component
                )}
            </div>
        );
    });

    return <>{stepsList}</>;
});

const mapStateToProps = (state: IWorkSchedulingCombinedState, ownProps): IStepStateProps => {
    const user = getUserSelector(state)(ownProps.userID);
    return {
        availableHours: availableHoursSelector(state, { userID: ownProps.userID }),
        scheduleWorkDays: scheduleWorkDaysSelector(state)(user.scheduleID),
        scheduleHours: scheduleHoursSelector(state, { scheduleID: user.scheduleID }),
        showRemainingHours: showRemainingHoursSelector(state),
        showAllocationsInHoursMode: showAllocationsInHoursModeSelector(state),
    };
};

export const Steps = connect(mapStateToProps)(StepsComponent);

const shouldBeWorkingDay = (
    isDayMode: boolean,
    scheduleWorkDays: IWorkDays,
    step: Moment
): boolean => {
    return isDayMode
        ? isWorkingDayBySchedule(scheduleWorkDays, step)
        : isThereAnyWorkingDayInWeek(scheduleWorkDays);
};

const getTimeOffMessages = (
    timeOffs: ITimeOffsState,
    stepDateString: string,
    fullTimeOffsSteps: IFullTimeOffSteps,
    isWeekMode: boolean
): [timeOffMessageKeyAndFallback, timeOffMessageKeyAndFallback | { timeOffMessageKey: string }] => {
    let timeOffFullMessage: TOrUndefined<TMessageKey>;

    if (timeOffs[stepDateString]) {
        timeOffFullMessage = getTimeOffMessageKey(timeOffs[stepDateString].isUserTimeOff);
    } else if (fullTimeOffsSteps[stepDateString]) {
        timeOffFullMessage = getTimeOffMessageKey(fullTimeOffsSteps[stepDateString].isUserTimeOff);
    }

    const timeOffMessageKeyAndFallback = {
        timeOffMessageKey: timeOffFullMessage?.messageKey,
        timeOffMessageFallback: timeOffFullMessage?.fallBack,
    };
    const timeOffMessage = isWeekMode ? timeOffMessageKeyAndFallback : { timeOffMessageKey: '' };

    return [timeOffMessageKeyAndFallback, timeOffMessage];
};

const getAllocationConditions = (props: AllocationConditionsProps): [boolean, boolean] => {
    const { timeOffs, isWorkingDay, fullTimeOffsSteps, stepDateString, hourPerDay } = props;

    const halfTimeOffCondition =
        timeOffs[stepDateString] &&
        (timeOffs[stepDateString].nonWorkHours.length > 0 ||
            timeOffs[stepDateString].isPartiallyTimeOff);

    const noAllocationCondition =
        isWorkingDay && !fullTimeOffsSteps[stepDateString] && !halfTimeOffCondition && !hourPerDay;

    return [halfTimeOffCondition, noAllocationCondition];
};

const shouldBeWrapByTooltip = (
    halfTimeOffCondition: boolean,
    noAllocationCondition: boolean,
    hourPerDay: number
): boolean => {
    return !!(
        halfTimeOffCondition ||
        noAllocationCondition ||
        (hourPerDay && !halfTimeOffCondition)
    );
};

const getTooltipContent = (allocationTooltipProps): JSX.Element => {
    return <AllocationTooltipContent {...allocationTooltipProps} />;
};
