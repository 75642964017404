import { css } from 'react-emotion';
import { primary } from '@phoenix/all';

export const animationStyles = css`
    border: 0 dashed;
    border-color: transparent;
    animation-name: highlighting;
    animation-timing-function: ease;
    animation-duration: 3s;
    border-radius: 3px;
    z-index: 3;

    @keyframes highlighting {
        0% {
            border: 1px dashed ${primary.blue(400)};
            box-shadow: 0 2px 9px 1px rgba(0, 35, 65, 0.23);
            background: rgba(255, 255, 255, 0.7);
            z-index: 3;
        }

        70% {
            border: 1px dashed ${primary.blue(400)};
            box-shadow: 0 2px 9px 1px rgba(0, 35, 65, 0.23);
            background: rgba(255, 255, 255, 0.7);
            z-index: 3;
        }

        100% {
            border: 1px dashed ${primary.blue(25)};
            border-color: transparent;
            box-shadow: 0 2px 9px 1px rgba(0, 35, 65, 0);
            background: transparent;
            z-index: 3;
        }
    }
`;
