import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'REMOVE_UNASSIGNED_OBJECT';

interface IRemoveUnassignedObjectActionPayload {
    taskID: string;
    projectID: string;
    projectGroupingMode: boolean;
}

type IRemoveUnassignedObjectAction = TModernSchedulingAction<IRemoveUnassignedObjectActionPayload>;

const removeUnassignedObject = (
    taskID,
    projectID,
    projectGroupingMode
): IRemoveUnassignedObjectAction => ({
    type: ActionType,
    payload: {
        taskID,
        projectID,
        projectGroupingMode,
    },
});
export default removeUnassignedObject;

export function handle(state: IDataState, { payload }: IRemoveUnassignedObjectAction): IDataState {
    const unassignedTasks = { ...state.unassignedTasks };
    const tableDataIDsForUnassignedSection = [...state.tableDataIDsForUnassignedSection];
    const unassignedTasksProjects = { ...state.unassignedTasksProjects };
    const expandedUnassignedProjectIDs = new Set(state.expandedUnassignedProjectIDs);
    const taskIdExpression = payload.projectGroupingMode
        ? `${payload.projectID}_${payload.taskID}`
        : payload.taskID;

    const removeTableDataIDs = [taskIdExpression];

    const projectNodes = unassignedTasksProjects[payload.projectID]
        ? [...unassignedTasksProjects[payload.projectID].nodes]
        : [];

    delete unassignedTasks[payload.taskID];

    if (projectNodes.indexOf(payload.taskID) !== -1) {
        projectNodes.splice(projectNodes.indexOf(payload.taskID), 1);
        unassignedTasksProjects[payload.projectID].nodes = projectNodes;
    }

    if (
        unassignedTasksProjects[payload.projectID] &&
        !unassignedTasksProjects[payload.projectID].nodes.length
    ) {
        removeTableDataIDs.push(payload.projectID);
        expandedUnassignedProjectIDs.delete(payload.projectID);
        delete unassignedTasksProjects[payload.projectID];
    }

    removeTableDataIDs.forEach((idExpression) => {
        const index = tableDataIDsForUnassignedSection.indexOf(idExpression);

        if (index !== -1) {
            tableDataIDsForUnassignedSection.splice(index, 1);
        }
    });

    return {
        ...state,
        unassignedTasks,
        unassignedTasksProjects,
        tableDataIDsForUnassignedSection,
        expandedUnassignedProjectIDs,
    };
}
