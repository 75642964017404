import { Task } from 'workfront-objcodes';
import {
    INACCESSIBLE_ISSUE_BG_COLOR,
    INACCESSIBLE_TASK_BG_COLOR,
    ISSUE_BG_COLOR,
    TASK_BG_COLOR,
} from '../constants/colors';

export const getObjectBackgroundColor = (
    objType: string,
    isInDisableMode: boolean | undefined = false,
    isAssignmentOnDrag?: boolean
): string => {
    if (objType === Task) {
        if (!isAssignmentOnDrag) {
            return TASK_BG_COLOR;
        }
        if (isInDisableMode) {
            return INACCESSIBLE_TASK_BG_COLOR; // mean accessible tasks color in disable mode not real inaccessible
        }
        return TASK_BG_COLOR;
    }
    if (!isAssignmentOnDrag) {
        return ISSUE_BG_COLOR;
    }
    if (isInDisableMode) {
        return INACCESSIBLE_ISSUE_BG_COLOR; // mean accessible issue color in disable mode not real inaccessible
    }
    return ISSUE_BG_COLOR;
};
