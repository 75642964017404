import { createSelector } from 'reselect';
import { periodModeSelector } from '../../dateRange/selectors/periodModeSelector';
import { periodTimeUnitSelector } from '../../dateRange/selectors/periodTimeUnitSelector';
import {
    peopleWorkloadFilterIDSelector,
    unassignedWorkFilterIDSelector,
} from '../../filters/selectors/filterIDSelector';
import { unassignedWorkHeightSelector } from '../../tableSizes/selectors/unassignedWorkHeightSelector';
import { stepUnitSelector } from '../../dateRange/selectors/stepUnitSelector';
import {
    projectColorsModeSelector,
    projectGroupingModeSelector,
    showActualProgressSelector,
    showAllocationsInHoursModeSelector,
    showAllocationsSelector,
    showAllocationsVisualizationSelector,
    showCompletedWorkSelector,
    showIssuesSelector,
    showRemainingHoursSelector,
    showRoleSummarySelector,
    sortUsersByRoleSelector,
    projectsSortingCriteriaSelector,
    showAllUsersSelector,
} from '../settingsSelector';

export const settingsStateSelector = createSelector(
    [
        stepUnitSelector,
        projectColorsModeSelector,
        projectGroupingModeSelector,
        showAllocationsSelector,
        showAllocationsVisualizationSelector,
        showRoleSummarySelector,
        showRemainingHoursSelector,
        showIssuesSelector,
        showActualProgressSelector,
        showCompletedWorkSelector,
        showAllocationsInHoursModeSelector,
        sortUsersByRoleSelector,
        showAllUsersSelector,
    ],
    (
        stepUnit,
        projectColorsMode,
        projectGroupingMode,
        showAllocations,
        showAllocationsVisualization,
        showRoleSummary,
        showRemainingHours,
        showIssues,
        showActualProgress,
        showCompletedWork,
        showAllocationsInHoursMode,
        sortUsersByRoleEnabled,
        showAllUsers
    ) => {
        return {
            stepUnit,
            projectColorsMode,
            projectGroupingMode,
            showAllocations,
            showAllocationsVisualization,
            showRoleSummary,
            showRemainingHours,
            showIssues,
            showActualProgress,
            showCompletedWork,
            showAllocationsInHoursMode,
            sortUsersByRoleEnabled,
            showAllUsers,
        };
    }
);

export const restUSerPrefSettingsSelector = createSelector(
    [
        periodModeSelector,
        periodTimeUnitSelector,
        unassignedWorkHeightSelector,
        peopleWorkloadFilterIDSelector,
        unassignedWorkFilterIDSelector,
        projectsSortingCriteriaSelector,
    ],
    (
        timelinePeriodMode,
        timelinePeriodTimeUnit,
        unassignedWorkHeight,
        peopleWorkloadFilterID,
        unassignedWorkFilterID,
        projectsSortingCriteria
    ) => {
        return {
            timelinePeriodMode,
            timelinePeriodTimeUnit,
            unassignedWorkHeight,
            filterID: peopleWorkloadFilterID,
            'unassignedSection.filterID': unassignedWorkFilterID,
            projectsSortingCriteria,
        };
    }
);

export const settingsSelector = createSelector(
    [settingsStateSelector, restUSerPrefSettingsSelector],
    (settings, rest) => {
        return { ...settings, ...rest };
    }
);
