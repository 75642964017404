import { TModernSchedulingAction } from '../../types';
import { getInitialFiltersState } from '../getInitialFiltersState';
import { IFiltersState } from '../IFiltersState';
import { Sections } from '../../../constants/schedulingTableConstants';

export const ActionType = 'DELETE_FILTER';

interface IDeleteFilterActionPayload {
    section: Sections;
}

type TDeleteFilterAction = TModernSchedulingAction<IDeleteFilterActionPayload>;

const deleteFilter = (section: Sections): TDeleteFilterAction => ({
    type: ActionType,
    payload: {
        section,
    },
});
export default deleteFilter;

export function handle(state: IFiltersState, { payload }: TDeleteFilterAction): IFiltersState {
    const { section } = payload;

    let openState = {};

    openState = { isFilterOpen: state[section].isFilterOpen };
    return {
        ...state,
        [section]: {
            ...getInitialFiltersState()[section],
            ...openState,
        },
    };
}
