import { Project, Team } from 'workfront-objcodes';

export type TAreaObjCodes = typeof Team | typeof Project | '';

export interface IAreaState {
    schedulingAreaID?: string;
    schedulingAreaObjCode: TAreaObjCodes;
    schedulingAreaDisplayName: string;
}

export function getAreaRelatedInitialDataState(options): IAreaState {
    const { schedulingAreaData = {} } = options;

    return {
        schedulingAreaID: schedulingAreaData.ID,
        schedulingAreaObjCode: schedulingAreaData.objCode || '',
        schedulingAreaDisplayName: schedulingAreaData.displayName || '',
    };
}
