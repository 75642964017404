import _ from 'lodash';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'REMOVE_USERS_ASSIGNMENTS';

interface IRemoveUsersAssignmentsPayload {
    usersIDs: string[];
    objectID: string; // can be task or issue ID
}

type TRemoveUsersAssignmentsAction = TModernSchedulingAction<IRemoveUsersAssignmentsPayload>;

const removeUserAssignments = (
    usersIDs: string[],
    objectID: string
): TRemoveUsersAssignmentsAction => ({
    type: ActionType,
    payload: {
        usersIDs,
        objectID,
    },
});
export default removeUserAssignments;

export function handle(state: IDataState, { payload }: TRemoveUsersAssignmentsAction): IDataState {
    const users = { ...state.users };

    _.forEach(payload.usersIDs, (userID: string) => {
        const newAssignments = { ...users[userID].assignments };

        _.forEach(newAssignments, (objectID, assignmentID) => {
            if (objectID === payload.objectID) {
                delete newAssignments[assignmentID];
            }
        });

        users[userID] = {
            ...users[userID],
            assignments: newAssignments,
        };
    });

    return {
        ...state,
        users,
    };
}
