import { colorSettingModes } from '../../util/colorUtils';
import { ISettingsState } from './ISettingsState';

export function getInitialSettingsState(): ISettingsState {
    return {
        projectGroupingMode: true,
        showAllocations: false,
        showAllocationsVisualization: false,
        showRoleSummary: false,
        showRemainingHours: false,
        showIssues: false,
        showActualProgress: false,
        showCompletedWork: true,
        showAllocationsInHoursMode: true,
        projectColorsMode: colorSettingModes.default,
        sortUsersByRoleEnabled: false,
        isPanelOpened: false,
        isBulkAssignmentPanelOpened: false,
        projectsSortingCriteria: undefined,
        showAllUsers: false,
    };
}
