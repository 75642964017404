import { trackLaunchCustomEvent } from '@wf-mfe/logger';
import { IWorkSchedulingCombinedState, TWorkSchedulingThunkAction } from '../types';
import {
    assignedWorkFilterExpressionSelector,
    unassignedWorkFilterExpressionSelector,
    userFilterExpressionSelector,
} from '../filters/selectors/filterIDSelector';
import { getFormattedDateRange } from '../../util/utilities';
import { startDateSelector } from '../dateRange/selectors/startDateSelector';
import { endDateSelector } from '../dateRange/selectors/endDateSelector';
import { stepUnitSelector } from '../dateRange/selectors/stepUnitSelector';
import { settingsSelector } from '../settings/settingsActions/settingsSelector';
import { periodModeSelector } from '../dateRange/selectors/periodModeSelector';
import { periodTimeUnitSelector } from '../dateRange/selectors/periodTimeUnitSelector';
import { Sections } from '../../constants/schedulingTableConstants';
import { DurationTypeLabels } from '../../constants/dataConstatnts';

function extractUnassignedFilterRules(rules): string[] {
    const filterRules: string[] = [];
    rules.forEach((rule) => {
        filterRules.push(`${rule.selectedFieldKey} ${rule.condition}`);
    });
    return filterRules;
}

function extractFilterRules(assignmentRules, userFilterRules): string[] {
    const filterRules: string[] = [];
    assignmentRules.forEach((rule) => {
        filterRules.push(`${rule.selectedFieldKey} ${rule.condition}`);
    });
    userFilterRules.forEach((rule) => {
        filterRules.push(`${rule.selectedFieldKey} ${rule.condition}`);
    });
    return filterRules;
}

export function trackWorkSaved(objCode, sectionType): TWorkSchedulingThunkAction<Promise<void>> {
    return function _trackWorkSaved(dispatch, getState) {
        const state: IWorkSchedulingCombinedState = getState();
        const filterRules =
            sectionType === Sections.PEOPLE_WORKLOAD
                ? extractFilterRules(
                      assignedWorkFilterExpressionSelector(state)?.rules,
                      userFilterExpressionSelector(state)?.rules
                  )
                : extractUnassignedFilterRules(
                      unassignedWorkFilterExpressionSelector(state)?.rules
                  );
        trackLaunchCustomEvent('Work Assigned', {
            // @ts-expect-error no need to check types
            resourceMgmnt: {
                workType: objCode.toLowerCase(),
                assignmentStatus: sectionType,
                filterRuleWb: JSON.stringify(filterRules),
                allocationShown: settingsSelector(state).showAllocationsInHoursMode
                    ? 'hours'
                    : 'percentage',
                timeStep: stepUnitSelector(state),
                sourceNameWb:
                    state.areaData.schedulingAreaObjCode !== ''
                        ? state.areaData.schedulingAreaObjCode.toLowerCase()
                        : 'resourcing',
                budgetDatesWb: getFormattedDateRange(
                    startDateSelector(state),
                    endDateSelector(state),
                    stepUnitSelector(state)
                ),
                intervalTypeWb: `${periodModeSelector(state)} ${periodTimeUnitSelector(state)}`,
            },
        });
        return Promise.resolve();
    };
}

export function trackAllocationSaved(
    durationType,
    taskStatus
): TWorkSchedulingThunkAction<Promise<void>> {
    return function _trackAllocationSaved(dispatch, getState) {
        switch (durationType) {
            case 'D':
                durationType = DurationTypeLabels.EFFORT_DRIVEN;
                break;
            case 'W':
                durationType = DurationTypeLabels.CALCULATED_WORK;
                break;
            case 'A':
                durationType = DurationTypeLabels.CALCULATED_ASSIGNMENT;
                break;
            case 'S':
                durationType = DurationTypeLabels.SIMPLE;
                break;
            default:
                durationType = '';
        }
        const state: IWorkSchedulingCombinedState = getState();
        trackLaunchCustomEvent('Resource Allocation', {
            // @ts-expect-error no need to check types
            resourceMgmnt: {
                allocationShown: settingsSelector(state).showAllocationsInHoursMode
                    ? 'hours'
                    : 'percentage',
                timeStep: stepUnitSelector(state),
                sourceNameWb:
                    state.areaData.schedulingAreaObjCode !== ''
                        ? state.areaData.schedulingAreaObjCode.toLowerCase()
                        : 'resourcing',
                intervalTypeWb: `${periodModeSelector(state)} ${periodTimeUnitSelector(state)}`,
                durationType,
                taskStatus: taskStatus.toLowerCase(),
            },
        });
        return Promise.resolve();
    };
}

export function trackAnalyzing(): TWorkSchedulingThunkAction<Promise<void>> {
    return function _trackAnalyzing(dispatch, getState) {
        const state: IWorkSchedulingCombinedState = getState();
        trackLaunchCustomEvent('Analyzing Resources In WB', {
            // @ts-expect-error no need to check types
            resourceMgmnt: {
                assignedFilterRule: JSON.stringify(
                    extractFilterRules(
                        assignedWorkFilterExpressionSelector(state)?.rules,
                        userFilterExpressionSelector(state)?.rules
                    )
                ),
                unassignedFilterRule: JSON.stringify(
                    extractUnassignedFilterRules(
                        unassignedWorkFilterExpressionSelector(state)?.rules
                    )
                ),
                allocationShown: settingsSelector(state).showAllocationsInHoursMode
                    ? 'hours'
                    : 'percentage',
                timeStep: stepUnitSelector(state),
                sourceNameWb:
                    state.areaData.schedulingAreaObjCode !== ''
                        ? state.areaData.schedulingAreaObjCode.toLowerCase()
                        : 'resourcing',
                intervalTypeWb: `${periodModeSelector(state)} ${periodTimeUnitSelector(state)}`,
            },
        });
        return Promise.resolve();
    };
}

export function trackBulkAssignments(
    sectionType,
    actionType,
    numberOfItems
): TWorkSchedulingThunkAction<Promise<void>> {
    return function _trackBulkAssignments(dispatch, getState) {
        const state: IWorkSchedulingCombinedState = getState();
        const filterRules =
            sectionType === Sections.PEOPLE_WORKLOAD
                ? extractFilterRules(
                      assignedWorkFilterExpressionSelector(state)?.rules,
                      userFilterExpressionSelector(state)?.rules
                  )
                : extractUnassignedFilterRules(
                      unassignedWorkFilterExpressionSelector(state)?.rules
                  );
        trackLaunchCustomEvent('Bulk Assignment', {
            // @ts-expect-error no need to check types
            resourceMgmnt: {
                actionType,
                filterRuleWb: JSON.stringify(filterRules),
                sourceNameWb:
                    state.areaData.schedulingAreaObjCode !== ''
                        ? state.areaData.schedulingAreaObjCode.toLowerCase()
                        : 'resourcing',
                numberOfItems,
            },
        });
        return Promise.resolve();
    };
}
