import { Sections } from '../../constants/schedulingTableConstants';
import { IErrorState } from '../data/IDataState';

const DEFAULT_ERROR_STATE: IErrorState = {
    [Sections.PEOPLE_WORKLOAD]: {
        status: 0,
        message: '',
        isErrorThrown: false,
    },
    [Sections.UNASSIGNED_WORK]: {
        status: 0,
        message: '',
        isErrorThrown: false,
    },
};

export function getInitialErrorState(): IErrorState {
    return DEFAULT_ERROR_STATE;
}
