import React from 'react';
import ProjectIcon from 'phoenix-icons/dist/ProjectIcon.js';
import LockSmallIcon from 'phoenix-icons/dist/LockSmallIcon.js';
import { primary, Tooltip, Font } from '@phoenix/all';
import {
    projectTextBlock,
    textAndProjectIconColor,
    truncateText,
    truncateTextWrapper,
} from './AssignmentDuration';
import { ILinkRelatedProps } from '../left/ProjectName/ProjectName';

interface IProjectAssignmentTitle {
    showLockIcon: boolean;
    isAccessible: boolean;
    title: string;
    testID: string;
    nameAsLinkProps: ILinkRelatedProps;
}

const iconDimensions = 16;

export const ProjectAssignmentTitle: React.FunctionComponent<IProjectAssignmentTitle> = React.memo(
    function ProjectAssignmentTitle(props) {
        const { showLockIcon, isAccessible, title, testID, nameAsLinkProps } = props;

        return (
            <div data-testid={testID} className={projectTextBlock()}>
                <>
                    {showLockIcon ? (
                        <LockSmallIcon
                            color={primary.gray(500)}
                            height={iconDimensions}
                            width={iconDimensions}
                        />
                    ) : (
                        <ProjectIcon
                            color={textAndProjectIconColor(isAccessible)}
                            height={iconDimensions}
                            width={iconDimensions}
                        />
                    )}
                    <Tooltip className={truncateTextWrapper} content={title} delay={700}>
                        <Font
                            target={nameAsLinkProps.target}
                            type={nameAsLinkProps.type}
                            className={truncateText(isAccessible)}
                            to={nameAsLinkProps.to}
                        >
                            {title}
                        </Font>
                    </Tooltip>
                </>
            </div>
        );
    }
);
