import { Localization } from '@workfront/localize-react';
import React from 'react';
import { Sections } from '../../../constants/schedulingTableConstants';
import { FilterButton } from '../../filters/FilterButton';
import { cellPadding, sectionNameStyle, tableHeaderLeftStyles } from './TableHeaderStyles';
import { getContextValue } from '../../../contexts/checkContext';
import { TableHeaderArrowButton } from './TableHeaderArrowButton';
import { TABLE_HEADER_KEYS } from '../../../constants/tableHeaderKeys';
import { ICellStyle } from '../schedulingTables/SchedulingTableAssigned';

interface ITableHeaderLeftSideWithFilterIcon extends React.HTMLAttributes<HTMLDivElement> {
    section: Sections;
    style: ICellStyle;
}

export const TableHeaderLeftSideWithFilterIcon: React.FunctionComponent<ITableHeaderLeftSideWithFilterIcon> =
    React.memo(({ section, style }) => {
        return (
            <div className={tableHeaderLeftStyles} style={style} data-testid="grid-header-left">
                <div className={cellPadding}>
                    {getContextValue('sharableLink') ? (
                        ''
                    ) : (
                        <>
                            {section === Sections.PEOPLE_WORKLOAD ? (
                                <>
                                    <TableHeaderArrowButton />
                                    <h3 className={sectionNameStyle}>
                                        <Localization<string>
                                            messageKey={
                                                TABLE_HEADER_KEYS.assigned_work_title.messageKey
                                            }
                                            fallback={
                                                TABLE_HEADER_KEYS.assigned_work_title.fallBack
                                            }
                                        />
                                    </h3>
                                </>
                            ) : null}
                            <FilterButton section={section} />
                        </>
                    )}
                </div>
            </div>
        );
    });
