import * as React from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import ErrorIcon from 'phoenix-icons/dist/ErrorIcon.js';
import { primary } from '@phoenix/all';
import { Sections } from '../../../../constants/schedulingTableConstants';
import { IAreaState } from '../../../../data-flow/areaData/areaRelatedInitialDataState';
import { stepsSelector } from '../../../../data-flow/dateRange/selectors/stepsSelector';
import { isErrorThrownSelector } from '../../../../data-flow/errorData/errorDataSelector';
import { AssignmentLoading } from '../AssignmentLoading';
import { ErrorPage } from '../ErrorPage';
import { AssignmentsForUnassignedSection } from './Assignments';
import { EmptyRow } from './EmptyRow';
import { EmptyUnassignedSection } from './EmptyUnassignedSection';
import { projectGroupingModeSelector } from '../../../../data-flow/settings/settingsSelector';
import { getSchedulingAreaData } from '../../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { unassignedWorkFilterIDSelector } from '../../../../data-flow/filters/selectors/filterIDSelector';
import { IFilterItemState } from '../../../../data-flow/filters/IFiltersState';

interface IUnassignedWorkSectionRightCellProps extends React.HTMLAttributes<HTMLDivElement> {
    hasDataIDs: boolean;
    idExpression: string;
    tableViewportWidth?: number;
    leftSidePanelWidth: number;
}

interface IUnassignedWorkSectionRightCellStateProps {
    isInLoadingStateUnassignedWorkSection: string;
    steps: Moment[];
    projectGrouping: boolean;
    filterID: IFilterItemState['filterID'];
    schedulingAreaData: IAreaState;
    isErrorThrown: boolean;
}

export const UnassignedWorkSectionRightCellComponent = React.memo<
    IUnassignedWorkSectionRightCellProps & IUnassignedWorkSectionRightCellStateProps
>((props) => {
    const {
        isInLoadingStateUnassignedWorkSection,
        idExpression,
        filterID,
        schedulingAreaData,
        hasDataIDs,
        steps,
        isErrorThrown,
        tableViewportWidth,
        leftSidePanelWidth,
    } = props;
    let component;
    const loadingRow = idExpression && idExpression.split('_').indexOf('loading') !== -1;

    const isFilterApplied = !!filterID?.length;

    let filterCase = !isFilterApplied;
    if (schedulingAreaData.schedulingAreaID) {
        filterCase = false;
    }

    if (isInLoadingStateUnassignedWorkSection || loadingRow) {
        component = <AssignmentLoading />;
    } else if (!idExpression) {
        if (isErrorThrown) {
            component = (
                <ErrorPage
                    tableViewportWidth={tableViewportWidth}
                    leftSidePanelWidth={leftSidePanelWidth}
                >
                    <ErrorIcon color={primary.gray(400)} />
                </ErrorPage>
            );
        } else if (hasDataIDs) {
            component = <EmptyRow steps={steps} />;
        } else {
            component = (
                <EmptyUnassignedSection
                    tableViewportWidth={tableViewportWidth}
                    leftSidePanelWidth={leftSidePanelWidth}
                    schedulingAreaID={schedulingAreaData.schedulingAreaID}
                    isFilterApplied={isFilterApplied}
                />
            );
        }
    } else if (filterCase) {
        component = (
            <EmptyUnassignedSection
                tableViewportWidth={tableViewportWidth}
                leftSidePanelWidth={leftSidePanelWidth}
                schedulingAreaID={schedulingAreaData.schedulingAreaID}
                isFilterApplied={isFilterApplied}
            />
        );
    } else {
        component = (
            <AssignmentsForUnassignedSection idExpression={idExpression} isAssigned={false} />
        );
    }
    return component;
});

function mapStateToProps(state): IUnassignedWorkSectionRightCellStateProps {
    return {
        steps: stepsSelector(state),
        isInLoadingStateUnassignedWorkSection: state.Data.isInLoadingStateUnassignedWorkSection,
        projectGrouping: projectGroupingModeSelector(state),
        filterID: unassignedWorkFilterIDSelector(state),
        schedulingAreaData: getSchedulingAreaData(state),
        isErrorThrown: isErrorThrownSelector(state, Sections.UNASSIGNED_WORK),
    };
}

export const UnassignedWorkSectionRightCell = connect(mapStateToProps)(
    UnassignedWorkSectionRightCellComponent
);
