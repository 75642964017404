import React, { ReactNode, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

const Portal: React.FunctionComponent<{
    container: HTMLElement | null;
    children: ReactNode | null;
    width?: string;
}> = (props) => {
    const elem = useMemo(() => document.createElement('div'), []);
    if (props.width) {
        elem.style.width = props.width;
    }

    useEffect(() => {
        let container;
        if (props.container) {
            container = props.container;
            container.appendChild(elem);
        }

        return () => {
            if (container) {
                container.removeChild(elem);
            }
        };
    }, [props.container, elem]);

    return ReactDOM.createPortal(props.children, elem);
};

export default Portal;
