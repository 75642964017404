import moment from 'moment';
import { Task as taskObjCode } from 'workfront-objcodes';
import { UNASSIGNED_ASSIGNMENT_FIELDS } from '../../constants/dataConstatnts';
import { RECOMPUTE_GRID_SIZE } from '../../constants/events';
import { APIService } from '../../services/api-services/apiService';
import {
    pendoAnalyticsTracker,
    plnHoursChangeForPendoAnalytics,
} from '../../util/analytics/pendoAnalyticsTracker';
import removeAssignedObject from '../data/assignedDataActions/removeAssignedObject';
import setObjectsIntoAssignmentMode from '../data/assignedDataActions/setObjectsIntoAssignmentMode';
import updateWorkDetails from '../data/assignedDataActions/updateWorkDetails';
import {
    tableDataIDsForUnassignedSectionSelector,
    temporaryWorkPerDaysSelector,
} from '../data/selectors/dataSelectors';
import { endDateSelector } from '../dateRange/selectors/endDateSelector';
import actionChain from '../higher-order-reducers/actionChain';
import { internalEventEmitterSelector } from '../instances/internalEventEmitterSelector';
import { IWorkSchedulingCombinedState } from '../types';
import { removeUnassignedProjectAndTaskThunk } from './changeAssignmentsThunk';
import { loadUsersAssignments } from './loadDataThunk';
import { loadAssignmentsByTaskIDsFormService } from './loadWorkPerDayDataThunk';
import { loadObjectAssignments } from './loadObjectAssignments';
import { projectGroupingModeSelector } from '../settings/settingsSelector';
import removeWorkPerDayHoursOnDragHover from '../data/sharedActions/removeWorkPerDayHoursOnDragHover';
import { hasLoadedDataIDs } from '../../components/schedulingTable/schedulingTables/schedulingTableUtils';
import { Sections } from '../../constants/schedulingTableConstants';
import { isFilterAppliedInUnassignedSection } from '../../util/filters/filterUtil';
import removeObjectAssignmentsDetails from '../data/sharedActions/removeObjectAssignmentsDetails';
import removeTemporaryWorkPerDayHours from '../data/assignedDataActions/removeTemporaryWorkPerDayHours';

export const changeAssignmentWorkThunk: any = (
    IDs,
    work,
    chain,
    loadWorkDelegations,
    assignmentID
) => {
    return function _changeAssignmentWorkThunk(dispatch, getState) {
        const obectID = getState().SettingsState.projectGroupingMode ? IDs[2] : IDs[1];
        const userID = IDs[0];

        return dispatch(
            loadObjectAssignments(obectID, taskObjCode, false, loadWorkDelegations)
        ).then(() => {
            const affectedUsersIDs = Object.keys(
                getState().Data.tasksAndIssues[obectID].workRequired
            );
            const state: IWorkSchedulingCombinedState = getState();
            const stateTask = state.Data.tasksAndIssues[obectID];

            const apiRequest = APIService.api.get(
                taskObjCode,
                obectID,
                UNASSIGNED_ASSIGNMENT_FIELDS
            );

            return apiRequest.then((data) => {
                pendoTracker();

                // in this state current assignment work data is old and we need to reset this to
                // avoid use old data and load new assignments after update when it will be needed
                dispatch(removeObjectAssignmentsDetails([obectID]));

                const temporaryWorkPerDays = temporaryWorkPerDaysSelector(getState());

                if (temporaryWorkPerDays.assignmentID === assignmentID)
                    chain.push(removeTemporaryWorkPerDayHours());

                // The task duration is 0 or it isn't in current time frame, it should be removed
                if (
                    (work === 0 && data.plannedStartDate === data.plannedCompletionDate) ||
                    moment(data.plannedCompletionDate) < state.DateRange.startDate ||
                    moment(data.plannedStartDate) > endDateSelector(state)
                ) {
                    dispatch(removeUnassignedProjectAndTaskThunk(data.projectID, obectID));
                    dispatch(
                        actionChain([
                            removeAssignedObject(
                                affectedUsersIDs,
                                obectID,
                                data.projectID,
                                projectGroupingModeSelector(state)
                            ),
                            ...chain,
                            updateWorkDetails(userID, data, work),
                        ])
                    );
                    internalEventEmitterSelector(state).emit(RECOMPUTE_GRID_SIZE);
                    return data;
                }

                // the dates of task has been changed
                if (
                    stateTask.plannedStartDate !== data.plannedStartDate ||
                    stateTask.plannedCompletionDate !== data.plannedCompletionDate
                ) {
                    const changedDateActionChain = [
                        updateWorkDetails(userID, data, work),
                        removeWorkPerDayHoursOnDragHover(),
                    ];

                    if (temporaryWorkPerDays.assignmentID === assignmentID)
                        changedDateActionChain.push(removeTemporaryWorkPerDayHours());

                    dispatch(actionChain(changedDateActionChain));

                    dispatch(updateDataWhenTaskDatesChangedThunk(data, affectedUsersIDs));

                    return data;
                }

                // when only the contours has been changed
                dispatch(actionChain([...chain, updateWorkDetails(userID, data, work)]));

                return data;
            });
        });
    };
};

const updateDataWhenTaskDatesChangedThunk = (taskData, affectedUsersIDs) => {
    return function _updateDataWhenTaskDatesChangedThunk(dispatch, getState) {
        const state: IWorkSchedulingCombinedState = getState();
        const taskID = taskData.ID;
        const unassignedTask = state.Data.unassignedTasks[taskID];

        const isFilterApplied = isFilterAppliedInUnassignedSection(state);

        const doesUnassignedSectionHasItems = hasLoadedDataIDs(
            tableDataIDsForUnassignedSectionSelector(state),
            Sections.UNASSIGNED_WORK
        );

        if (unassignedTask && doesUnassignedSectionHasItems && isFilterApplied) {
            const args = {
                startDate: state.DateRange.startDate,
                endDate: endDateSelector(state),
                unassignedTasks: [taskData],
            };

            dispatch(loadAssignmentsByTaskIDsFormService(args, []));
        }

        // in loadUsersAssignments methods tasks/issues and users disable state is removed
        dispatch(loadUsersAssignments(affectedUsersIDs, taskID)).catch(() => {
            dispatch(
                actionChain([
                    setObjectsIntoAssignmentMode(null),
                    // setUserIntoAssignmentMode(affectedUsersIDs, false)
                ])
            );
        });
    };
};

const pendoTracker = (): void => {
    pendoAnalyticsTracker(plnHoursChangeForPendoAnalytics());
};
