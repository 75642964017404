import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'UPDATE_TASK_WORK_DETAILS';

interface ITaskDetails {
    ID: string;
    plannedCompletionDate: string;
    plannedStartDate: string;
}

interface IUpdateTaskWorkDetailsActionPayload {
    userID: string;
    work: number;
    objectDetails: ITaskDetails;
}

type TUpdateTaskWorkDetailsAction = TModernSchedulingAction<IUpdateTaskWorkDetailsActionPayload>;

const updateWorkDetails = (
    userID: string,
    objectDetails: ITaskDetails,
    work: number
): TUpdateTaskWorkDetailsAction => ({
    type: ActionType,
    payload: {
        userID,
        work,
        objectDetails,
    },
});
export default updateWorkDetails;

export function handle(state: IDataState, { payload }: TUpdateTaskWorkDetailsAction): IDataState {
    const tasksAndIssues = { ...state.tasksAndIssues };
    const taskData = payload.objectDetails;

    tasksAndIssues[taskData.ID] = {
        ...tasksAndIssues[taskData.ID],
        workRequired: {
            ...tasksAndIssues[taskData.ID].workRequired,
            [payload.userID]: payload.work,
        },
    };

    return {
        ...state,
        tasksAndIssues,
    };
}
