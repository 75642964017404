import { primary } from '@phoenix/all';
import { css } from 'emotion';
import { BIG_WIDGET_SIZE, WIDGET_RIGHT_FOR_QS } from '../../../constants/dataConstatnts';
import { filterContainerCommonZIndex } from '../../../constants/zIndexes';

const rightWidgetCommon = css`
    box-shadow: rgba(0, 35, 64, 0.2) 0 3px 6px 0;
    position: absolute;
    top: 0;
    z-index: ${filterContainerCommonZIndex};
    background: ${primary.white()};
    overflow: hidden;
`;

export const rightWidgetClassName_qs = css`
    ${rightWidgetCommon};
    height: 100%;
    right: ${WIDGET_RIGHT_FOR_QS};
`;

export const rightWidgetSizeClassName = css`
    width: ${BIG_WIDGET_SIZE}px;
`;
