import { apiDateToDate } from '@workfront/fns';
import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT, END_HOUR, HOUR_FORMAT, START_HOUR } from '../constants/dataConstatnts';
import {
    IRequestedSchedulesState,
    IRequestedUserTimeOffs,
    ITimeOffsState,
    IWorkDays,
    TMessageKey,
} from '../data-flow/data/IDataState';
import { getDayOfWeek, intersectWithScheduleWorkingHours, sortByStartDate } from './utilities';

interface INormalizedScheduleExceptions {
    [ID: string]: {
        workDays: IWorkDays;
        workingHoursByDay: {
            [date: string]: string[];
        };
        nonWorkDays: {
            [startDate: string]: IScheduleNonWorkDay;
        };
    };
}

interface IScheduleNonWorkDay {
    startDate: string;
    endDate: string;
    isUserTimeOff: boolean;
    nonWorkHours: string[];
}

export const normalizeScheduleExceptions = (
    schedules: IRequestedSchedulesState
): INormalizedScheduleExceptions => {
    const normalizeSchedules: INormalizedScheduleExceptions = {};

    _.forEach(schedules, (schedule, scheduleID) => {
        if (!normalizeSchedules[scheduleID]) {
            normalizeSchedules[scheduleID] = {
                workingHoursByDay: {},
                workDays: schedule.workDays,
                nonWorkDays: {},
            };
        }

        schedule.nonWorkDays.forEach((nonWorkDay) => {
            normalizeSchedules[scheduleID].nonWorkDays[nonWorkDay.nonWorkDate] = {
                startDate: nonWorkDay.nonWorkDate,
                endDate: nonWorkDay.nonWorkDate,
                nonWorkHours: nonWorkDay.workHours,
                isUserTimeOff: false,
            };
        });
    });

    return normalizeSchedules;
};

interface ITimeOffsItem {
    endDate: string;
    startDate: string;
    nonWorkHours: string[];
    isUserTimeOff: boolean;
}

interface INormalizedTimeOffs {
    [ID: string]: {
        [startDate: string]: ITimeOffsItem;
    };
}

export const normalizeUsersTimeOffs = (
    usersTimeOffs: IRequestedUserTimeOffs[],
    schedules: INormalizedScheduleExceptions,
    users: { [id: string]: { scheduleID: string } }
): INormalizedTimeOffs => {
    const normalizedTimeOffs = {};

    usersTimeOffs.forEach((timeOffData) => {
        if (!normalizedTimeOffs[timeOffData.userID]) {
            normalizedTimeOffs[timeOffData.userID] = {};
        }

        const { scheduleID } = users[timeOffData.userID];
        const scheduleWorkDays = schedules[scheduleID].workDays;
        const scheduleWorkingHoursByDay = schedules[scheduleID].workingHoursByDay;

        const startDateStep = moment(apiDateToDate(timeOffData.startDate)).startOf('day');
        const endDate = moment(apiDateToDate(timeOffData.endDate)).endOf('day');
        const startDateStr = startDateStep.format(DATE_FORMAT);
        const endDateStr = endDate.format(DATE_FORMAT);

        const startDateTime = moment(apiDateToDate(timeOffData.startDate)).format(HOUR_FORMAT);
        const endDateTime = moment(apiDateToDate(timeOffData.endDate)).format(HOUR_FORMAT);

        while (startDateStep <= endDate) {
            const stepStr = startDateStep.format(DATE_FORMAT);
            const dayOfWeekStartDate = getDayOfWeek(startDateStep);
            let startHour = START_HOUR;
            let endHour = END_HOUR;
            let hasWorkingHours = false;

            if (startDateTime !== START_HOUR && stepStr === startDateStr) {
                startHour = startDateTime;
                hasWorkingHours = true;
            }

            if (endDateTime !== END_HOUR && stepStr === endDateStr) {
                endHour = endDateTime;
                hasWorkingHours = true;
            }

            let nonWorkHours: string[] | null;
            if (hasWorkingHours) {
                nonWorkHours = intersectWithScheduleWorkingHours(
                    scheduleWorkingHoursByDay[stepStr] || scheduleWorkDays[dayOfWeekStartDate],
                    [startHour, endHour]
                );
            } else {
                nonWorkHours = [];
            }

            if (nonWorkHours) {
                normalizedTimeOffs[timeOffData.userID][stepStr] = {
                    startDate: stepStr,
                    endDate: stepStr,
                    isUserTimeOff: true,
                    nonWorkHours,
                };
            }

            startDateStep.add(1, 'day');
        }
    });

    return normalizedTimeOffs;
};

interface ITimeOffs {
    [userID: string]: ITimeOffsState;
}

export const combineUsersTimeOffsAndScheduleExceptions = (
    usersTimeOffs: INormalizedTimeOffs,
    schedulesExceptions: INormalizedScheduleExceptions,
    users: { [id: string]: { scheduleID: string } }
): ITimeOffs => {
    const timeOffs: ITimeOffs = {};

    _.forEach(users, (user, userID) => {
        const combinedUserTimeOffs = { ...usersTimeOffs[userID] };
        const { scheduleID } = users[userID];
        const scheduleExceptions =
            (schedulesExceptions[scheduleID] && schedulesExceptions[scheduleID].nonWorkDays) || [];

        if (!timeOffs[userID]) {
            timeOffs[userID] = {};
        }

        _.forEach(scheduleExceptions, (exception, startDate) => {
            if (combinedUserTimeOffs[startDate]) {
                combinedUserTimeOffs[startDate].nonWorkHours = [];
            } else {
                combinedUserTimeOffs[startDate] = { ...exception };
            }
        });

        const sortedTimeOffs = _.map(combinedUserTimeOffs, (newTimeOff) => newTimeOff).sort(
            sortByStartDate
        );

        sortedTimeOffs.forEach((item) => {
            const keys = Object.keys(timeOffs[userID]);
            const timeOffsPrevItem = timeOffs[userID][keys[keys.length - 1]];

            if (
                timeOffsPrevItem &&
                moment(item.startDate).diff(moment(timeOffsPrevItem.endDate), 'day') === 1 &&
                item.nonWorkHours.length === 0 &&
                timeOffsPrevItem.nonWorkHours.length === 0 &&
                timeOffsPrevItem.isUserTimeOff === item.isUserTimeOff
            ) {
                timeOffsPrevItem.endDate = item.endDate;
            } else {
                timeOffs[userID][item.startDate] = {
                    endDate: item.endDate,
                    nonWorkHours: item.nonWorkHours,
                    isUserTimeOff: item.isUserTimeOff,
                    isPartiallyTimeOff: !!item.nonWorkHours.length,
                    startDate: item.startDate,
                };
            }
        });
    });

    return timeOffs;
};

export const getTimeOffMessageKey = (isUserTimeOff: boolean | 'both'): TMessageKey => {
    if (isUserTimeOff === 'both') {
        return {
            messageKey: 'resourcescheduling.timeoff.and.holiday',
            fallBack: 'Time Off and Holiday',
        };
    }
    return isUserTimeOff
        ? {
              messageKey: 'resourcescheduling.timeoff',
              fallBack: 'Time Off',
          }
        : {
              messageKey: 'resourcescheduling.holiday',
              fallBack: 'Holiday',
          };
};
