import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'USERS_ON_PROJECT';

interface IUsersOnProjectActionPayload {
    usersOnProject: string[];
}

type TIUsersOnProjectActionPayload = TModernSchedulingAction<IUsersOnProjectActionPayload>;

const setUsersOnProject = (usersOnProject): TIUsersOnProjectActionPayload => ({
    type: ActionType,
    payload: {
        usersOnProject,
    },
});
export default setUsersOnProject;

export function handle(state: IDataState, { payload }: TIUsersOnProjectActionPayload): IDataState {
    return {
        ...state,
        usersOnProject: [...payload.usersOnProject],
    };
}
