import React from 'react';
import { ScrollbarPresenceParams } from 'react-virtualized/dist/es/Grid';
import { ScrollSyncChildProps, ScrollSyncState } from 'react-virtualized/dist/es/ScrollSync';
import { Sections } from '../../constants/schedulingTableConstants';

interface AreaData extends ScrollbarPresenceParams {
    type: Sections;
}

interface ScrollSyncCustomState extends ScrollSyncState {
    areasData: {
        [Sections.PEOPLE_WORKLOAD]: Omit<AreaData, 'type'>;
        [Sections.UNASSIGNED_WORK]: Omit<AreaData, 'type'>;
    };
}

export interface ScrollSyncCustomChildProps extends ScrollSyncChildProps {
    setAreasData: (areaData: AreaData) => void;
    areasData: ScrollSyncCustomState['areasData'];
}

interface ScrollSyncCustomProps {
    children: (props: ScrollSyncCustomChildProps) => React.ReactNode;
}

export default class ScrollSyncCustom extends React.PureComponent<
    ScrollSyncCustomProps,
    ScrollSyncCustomState
> {
    constructor(props, context) {
        super(props, context);

        this.state = {
            clientHeight: 0,
            clientWidth: 0,
            scrollHeight: 0,
            scrollLeft: 0,
            scrollTop: 0,
            scrollWidth: 0,
            areasData: {
                [Sections.PEOPLE_WORKLOAD]: {
                    horizontal: false,
                    size: 0,
                    vertical: false,
                },
                [Sections.UNASSIGNED_WORK]: {
                    horizontal: false,
                    size: 0,
                    vertical: false,
                },
            },
        };
        this.setAreasData = this.setAreasData.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    onScroll({
        clientHeight,
        clientWidth,
        scrollHeight,
        scrollLeft,
        scrollTop,
        scrollWidth,
    }): void {
        this.setState({
            clientHeight,
            clientWidth,
            scrollHeight,
            scrollLeft,
            scrollTop,
            scrollWidth,
        });
    }

    setAreasData({ type, vertical, size, horizontal }: AreaData): void {
        this.setState((prevState) => ({
            ...prevState,
            areasData: {
                ...prevState.areasData,
                [type]: {
                    horizontal,
                    size,
                    vertical,
                },
            },
        }));
    }

    render(): React.ReactNode {
        const { children } = this.props;
        const {
            clientHeight,
            clientWidth,
            scrollHeight,
            scrollLeft,
            scrollTop,
            scrollWidth,
            areasData,
        } = this.state;

        return children({
            clientHeight,
            clientWidth,
            onScroll: this.onScroll,
            setAreasData: this.setAreasData,
            scrollHeight,
            scrollLeft,
            scrollTop,
            scrollWidth,
            areasData,
        });
    }
}
