export enum TimeUnit {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
}

export enum TimelineActions {
    PREVIOUS = 'previous',
    TODAY = 'today',
    NEXT = 'next',
    PERIOD_CHANGE = 'period_change',
    START_DATE_CHANGE = 'startDate_change',
    INITIAL_SETUP = 'initial_setup',
}

export enum WEEKS {
    W_1 = 1,
    W_2 = 2,
    W_4 = 4,
    W_6 = 6,
    W_12 = 12,
}

export const MONTH_COUNT_IN_PERIOD = 3;

export const TIMELINE_OPTIONS = [
    {
        value: 1,
        timeUnit: TimeUnit.WEEK,
        messageKey: 'widgets.daterangepicker.1week',
        fallBack: '1 week',
    },
    {
        value: 2,
        timeUnit: TimeUnit.WEEK,
        messageKey: 'widgets.daterangepicker.2week',
        fallBack: '2 weeks',
    },
    {
        value: 4,
        timeUnit: TimeUnit.WEEK,
        messageKey: 'widgets.daterangepicker.4week',
        fallBack: '4 weeks',
    },
    {
        value: 6,
        timeUnit: TimeUnit.WEEK,
        messageKey: 'widgets.daterangepicker.6week',
        fallBack: '6 weeks',
    },
    {
        value: 3,
        timeUnit: TimeUnit.MONTH,
        messageKey: 'widgets.daterangepicker.3months',
        fallBack: '3 months',
    },
];
