import _ from 'lodash';
import { TModernSchedulingAction } from '../../types';
import { IDataState, INodesDetailsState, IUserState } from '../IDataState';
import { initialProjectNode } from './addAssignments';

export const ActionType = 'REMOVE_USERS_DATA';

interface IRemoveUsersDataActionPayload {
    needToKeepAssignmentsUnderTheProjects?: boolean;
}

type TRemoveUsersDataAction = TModernSchedulingAction<IRemoveUsersDataActionPayload>;

const removeUsersData = (
    needToKeepAssignmentsUnderTheProjects = false
): TRemoveUsersDataAction => ({
    type: ActionType,
    payload: {
        needToKeepAssignmentsUnderTheProjects,
    },
});
export default removeUsersData;

export function handle(state: IDataState, { payload }: TRemoveUsersDataAction): IDataState {
    const users = { ...state.users };

    _.map(users, (userItem: IUserState) => {
        const { ID, nodes, expandedProjectIDs, ...restData } = userItem;
        const newNodes: INodesDetailsState = {};

        if (payload.needToKeepAssignmentsUnderTheProjects) {
            Object.keys(nodes).forEach((projectID) => {
                if (expandedProjectIDs.has(projectID)) {
                    newNodes[projectID] = {
                        ...initialProjectNode,
                        offset: nodes[projectID].offset,
                    };
                }
            });
        }
        users[ID] = {
            ID,
            ...restData,
            timeOffs: {},
            nodes: newNodes,
            projectIDs: [],
            inaccessibleProjectIDs: [],
            userWorkPerDayForPeriod: [],
            purAvailableHoursForPeriod: [],
            availableHoursForPeriod: [],

            userDataRequestsState: {
                isDataLoaded: false,
                isRequestSend: false,
            },
            expandedProjectIDs,
            projectsDataRequestsState: {
                isRequestSend: false,
                isDataLoaded: false,
            },
            assignments: {},
        };
    });

    return {
        ...state,
        users,
        schedulesWorkDays: {},
    };
}
