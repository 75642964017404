import { Button, Tooltip } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import ViewAllocationsHourlyIcon from 'phoenix-icons/dist/ViewAllocationsHourlyIcon.js';
import * as React from 'react';
import { RoleSummaryKeys, TMessageKey } from '../../../data-flow/data/IDataState';
import { getHeaderItemStyles } from '../../styles/headerComponentStyles';
import { prepareTestId } from '../../../util/utilities';
import { useAppDispatch } from '../../../data-flow/hooks';
import { toggleRoleSummary } from '../../../data-flow/thunks/roleSummary/toggleRoleSummaryThunk';

interface IRoleSummarySetting {
    workloadBalancerRoleSummaryOn: boolean;
    sharableLink?: string;
    schedulingArea?: string;
}

export const RoleSummarySetting: React.FunctionComponent<IRoleSummarySetting> = ({
    workloadBalancerRoleSummaryOn,
    sharableLink,
    schedulingArea,
}) => {
    const dispatch = useAppDispatch();

    const workloadBalancerRoleSummaryKey: TMessageKey = workloadBalancerRoleSummaryOn
        ? {
              messageKey: 'workloadbalancer.hide.role.allocation',
              fallBack: 'Hide role allocation',
          }
        : {
              messageKey: 'workloadbalancer.show.role.allocation',
              fallBack: 'Show role allocation',
          };

    const roleSummaryClassName = getHeaderItemStyles(workloadBalancerRoleSummaryOn);

    const handleToggleRoleSummary = (): void => {
        dispatch(toggleRoleSummary(undefined, RoleSummaryKeys.TOTAL));
    };

    return (
        <Localization<string[]>
            messageKeys={[
                workloadBalancerRoleSummaryKey.messageKey,
                'workloadbalancer.open.role.allocation',
            ]}
        >
            {({ _t }) => {
                return (
                    <Tooltip
                        content={_t[workloadBalancerRoleSummaryKey.messageKey](
                            workloadBalancerRoleSummaryKey.fallBack
                        )}
                        delay={700}
                    >
                        <Button
                            text
                            className={roleSummaryClassName}
                            data-testid={prepareTestId(
                                'role-summary',
                                sharableLink,
                                schedulingArea
                            )}
                            onClick={handleToggleRoleSummary}
                            aria-expanded={workloadBalancerRoleSummaryOn}
                            aria-label={_t['workloadbalancer.open.role.allocation'](
                                'Open role allocation'
                            )}
                        >
                            <ViewAllocationsHourlyIcon focusable={false} />
                        </Button>
                    </Tooltip>
                );
            }}
        </Localization>
    );
};
