import { createSelector } from 'reselect';
import { schedulesWorkDaysSelector } from '../../dataSelectors';

export const scheduleWorkDaysSelector = createSelector(
    schedulesWorkDaysSelector,
    (schedulesWorkDays) => {
        return (scheduleID: string) => {
            return schedulesWorkDays[scheduleID];
        };
    }
);
