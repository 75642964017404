import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'ADD_USERS_BY_ASSIGNMENT';

interface IAddedUsersByAssignmentActionPayload {
    addedUsersByAssignment: string[];
}

type TIAddedUsersByAssignmentAction = TModernSchedulingAction<IAddedUsersByAssignmentActionPayload>;

const addUsersByAssignment = (addedUsersByAssignment): TIAddedUsersByAssignmentAction => ({
    type: ActionType,
    payload: {
        addedUsersByAssignment,
    },
});
export default addUsersByAssignment;

export function handle(state: IDataState, { payload }: TIAddedUsersByAssignmentAction): IDataState {
    return {
        ...state,
        addedUsersByAssignment: [
            ...state.addedUsersByAssignment,
            ...payload.addedUsersByAssignment,
        ],
    };
}
