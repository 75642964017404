import createCachedSelector from 're-reselect';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { userAssignmentsSelector, userProjectsSelector } from '../users/usersSelector';
import { stepsSelector } from '../../../dateRange/selectors/stepsSelector';
import { stepUnitSelector } from '../../../dateRange/selectors/stepUnitSelector';
import { showActualProgressSelector } from '../../../settings/settingsSelector';
import { DATE_FORMAT } from '../../../../constants/dataConstatnts';
import { addInaccessibleHoursToUserWorkPerDays } from '../../../../util/getWorkPerDayForPeriod';
import { recalculateDataForActiveView } from '../../../../util/utilities';
import { addUserHoursOnDropHover } from '../../../../util/workPerDayUtil';
import {
    tasksAndIssuesSelector,
    temporaryWorkPerDaysUserSelector,
    workPerDaysOnDragHoverUserSelector,
} from '../dataSelectors';
import { workPerDaysByAssignmentsFromServiceSelector } from '../workPerDaysByAssignmentsFromServiceSelector';
import {
    IRequestedTemporaryWorkPerDayFromService,
    IRequestedWorkPerDayFromService,
    IUserAssignmentsState,
    TasksAndIssuesRecord,
} from '../../IDataState';

export const userWorkPerDaysSelectorForPeriodFromService = createCachedSelector(
    [
        (state, { userID }) => userID,
        userProjectsSelector,
        userAssignmentsSelector,
        workPerDaysByAssignmentsFromServiceSelector,
        temporaryWorkPerDaysUserSelector,
        (state, { userID }) => workPerDaysOnDragHoverUserSelector(state, { userID }),
        stepsSelector,
        stepUnitSelector,
        showActualProgressSelector,
        tasksAndIssuesSelector,
    ],
    (
        userID,
        userNodes,
        assignments,
        workPerDaysByAssignments,
        temporaryWorkPerDays,
        temporaryWorkPerDaysOnUserHover,
        steps,
        stepUnit,
        actualProgress,
        tasksAndIssues
    ) => {
        const stepStartDate = steps[0].clone().startOf('day');
        const stepEndDate = steps[steps.length - 1].clone().endOf(stepUnit);

        let workPerDaySums = getWorkPerDaySums(
            assignments,
            stepStartDate,
            stepEndDate,
            tasksAndIssues,
            workPerDaysByAssignments,
            actualProgress,
            temporaryWorkPerDays
        );
        addInaccessibleHoursToUserWorkPerDays(userNodes, steps, stepEndDate, workPerDaySums);

        workPerDaySums = recalculateDataForActiveView(workPerDaySums, stepUnit, steps[0]);
        if (temporaryWorkPerDaysOnUserHover.length) {
            if (!workPerDaySums.length) {
                workPerDaySums = new Array(temporaryWorkPerDaysOnUserHover.length).fill(0);
            }
            workPerDaySums = addUserHoursOnDropHover(
                workPerDaySums,
                temporaryWorkPerDaysOnUserHover
            );
        }
        return workPerDaySums;
    }
)((state, { userID }) => userID);

export const getWorkPerDaySums = (
    assignments: IUserAssignmentsState,
    stepStartDate: Moment,
    stepEndDate: Moment,
    tasksAndIssues: TasksAndIssuesRecord,
    workPerDaysByAssignments: IRequestedWorkPerDayFromService,
    actualProgress: boolean,
    temporaryWorkPerDays: IRequestedTemporaryWorkPerDayFromService
): number[] => {
    const workPerDaySums: number[] = [];

    _.forEach(assignments, (taskID, assignmentID) => {
        const { actualCompletionDate } = tasksAndIssues[taskID];
        const stepStartDateClone = stepStartDate.clone();
        let index = 0;
        const workPerDay = workPerDaysByAssignments[assignmentID];
        while (stepStartDateClone < stepEndDate) {
            const isActualCompletionDateExist =
                actualCompletionDate && moment(actualCompletionDate).isBefore(stepStartDateClone);
            if (actualProgress && isActualCompletionDateExist) {
                return;
            }
            const dayWithWPDHour = stepStartDateClone.format(DATE_FORMAT);
            let workPerDayHours;
            if (
                temporaryWorkPerDays.assignmentID === assignmentID &&
                temporaryWorkPerDays.data[dayWithWPDHour] !== undefined
            ) {
                workPerDayHours = temporaryWorkPerDays.data[dayWithWPDHour] || 0;
            } else {
                workPerDayHours = (workPerDay && workPerDay[dayWithWPDHour]) || 0;
            }
            workPerDaySums[index] = (workPerDaySums[index] || 0) + workPerDayHours;
            stepStartDateClone.add(1, 'day');
            index += 1;
        }
    });

    return workPerDaySums;
};
