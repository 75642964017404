import React from 'react';
import styled from 'react-emotion';
import { rightWidgetClassName_qs, rightWidgetSizeClassName } from './rightWidgetHOCStyles';

interface IRightWidgetHOC {
    testID?: string;
    ignorePadding?: boolean;
}

export const RightHocDiv = styled('div')`
    ${rightWidgetClassName_qs};
    ${rightWidgetSizeClassName}
`;

export const RightWidgetHOC: React.FC<IRightWidgetHOC> = (props) => {
    const { testID = '', children } = props;

    return <RightHocDiv data-testid={testID}>{children}</RightHocDiv>;
};
