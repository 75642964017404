import { css } from 'emotion';
import styled from 'react-emotion';
import coffeeCup from '../../images/coffeeCup.svg';
import noUnassignedFilter from '../../images/noUnassignedFilter.svg';

export const emptyUnassignedTextStyle = css`
    text-align: center;
    background-color: white;
    color: #82878e;
    font-size: 14px;
    margin-top: 24px;
`;

export const EmptyUnassignedWrapper = styled('div')<{
    isFilterApplied: boolean;
    tableViewportWidth: number;
    leftSidePanelWidth: number;
}>`
    position: fixed;
    display: flex;
    width: ${(props) => props.tableViewportWidth - props.leftSidePanelWidth}px;
    flex-direction: column;
    pointer-events: none;
    padding: 40px;
    div {
        line-height: 20px;
    }
`;

export const coffeeCupImageCss = css`
    background: url(${coffeeCup}) 0 0 no-repeat;
    background-position: center fixed;
    height: 50px;
    width: 150px;
    margin: 0 auto;
`;

export const noFilterImageCss = css`
    background: url(${noUnassignedFilter}) 0 0 no-repeat;
    background-position: center fixed;
    height: 50px;
    width: 150px;
    margin: 0 auto;
`;

export const addFilterCss = css`
    display: flex;
    justify-content: center;
    padding-top: 16px;
`;
