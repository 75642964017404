import { TModernSchedulingAction } from '../../../types';
import { IDataState, IProjectGroupColorState } from '../../IDataState';

export const ActionType = 'ADD_PROJECT_GROUP_COLORS';

interface IAddProjectGroupColorsActionDetailsPayload {
    projectsGroupColors: {
        [groupId: string]: IProjectGroupColorState;
    };
}

type TAddProjectGroupColorsActionDetailsPayload =
    TModernSchedulingAction<IAddProjectGroupColorsActionDetailsPayload>;

const addProjectGroupColors = (
    projectsGroupColors
): TAddProjectGroupColorsActionDetailsPayload => ({
    type: ActionType,
    payload: {
        projectsGroupColors,
    },
});
export default addProjectGroupColors;

export function handle(
    state: IDataState,
    { payload }: TAddProjectGroupColorsActionDetailsPayload
): IDataState {
    return {
        ...state,
        projectGroupColors: { ...state.projectGroupColors, ...payload.projectsGroupColors },
    };
}
