import { TObjCode } from 'workfront-objcodes';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd/dist/types/types';
import { resetContouringEditMode } from '../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import { Sections, systemScrollbarSize } from '../constants/schedulingTableConstants';
import setHeightForPeopleWorkloadEmptyRow from '../data-flow/data/assignedDataActions/setHeightForPeopleWorkloadEmptyRow';
import { RECOMPUTE_GRID_SIZE } from '../constants/events';
import setObjectsIntoAssignmentMode from '../data-flow/data/assignedDataActions/setObjectsIntoAssignmentMode';
import actionChain from '../data-flow/higher-order-reducers/actionChain';
import {
    IAssignmentsDetails,
    IUnassignedTasksAssignment,
    TWorkPerDays,
} from '../data-flow/data/IDataState';
import setDraggingFromArea from '../data-flow/data/assignedDataActions/dragAndDrop/setDraggingFromArea';
import { setIntoAssignmentModeActions } from '../data-flow/data/assignedDataActions/commonActionGroups/unassignActions';
import removeWorkPerDayHoursOnDragHover from '../data-flow/data/sharedActions/removeWorkPerDayHoursOnDragHover';
import { loadObjectAssignments } from '../data-flow/thunks/loadObjectAssignments';

export const dragConfiguration = {
    beginDrag(props) {
        const {
            ID,
            idExpression,
            nodeData,
            objCode,
            dispatch,
            internalEventEmitter,
            isObjectInAssignmentMode,
            sectionType,
            workPerDays,
            contouringRowID,
        } = props;

        if (isObjectInAssignmentMode) {
            return;
        }

        if (contouringRowID) {
            dispatch(resetContouringEditMode());
        }

        const draggedFromUserID =
            sectionType === Sections.PEOPLE_WORKLOAD ? idExpression.split('_')[0] : '';

        // wrapped in timeout as when drag begins, dispatchers kill the element and rerenders
        // doing it in async way, will first let drag normally finalize beginDrag , after everything within timeout
        setTimeout(() => {
            const chainedActions: unknown[] = [
                setDraggingFromArea(sectionType),
                setObjectsIntoAssignmentMode([ID]),
            ];
            internalEventEmitter.emit(RECOMPUTE_GRID_SIZE);

            if (systemScrollbarSize > 0) {
                const { peopleWorkLoadHeight, showActualProgress, projectGroupingMode } = props;
                chainedActions.push(
                    setHeightForPeopleWorkloadEmptyRow(
                        peopleWorkLoadHeight,
                        showActualProgress,
                        projectGroupingMode
                    )
                );
            }
            dispatch(actionChain(chainedActions));
        }, 0);

        const objectCode = objCode || nodeData.objCode;
        let loadTaskAssignments = Promise.resolve([]);
        if (ID && objectCode) {
            loadTaskAssignments = dispatch(loadObjectAssignments(ID, objectCode, true));
        }

        return {
            ID,
            objCode: objectCode,
            draggedFromUserID,
            type: DragDropTypes.ASSIGNMENT,
            idExpression,
            workPerDays,
            assignments: nodeData && nodeData.assignments,
            sectionType,
            loadTaskAssignments,
        };
    },
    isDragging(props, monitor) {
        return props.ID === monitor.getItem().ID;
    },

    endDrag(props, monitor) {
        if (monitor.getDropResult() == null) {
            props.dispatch(
                actionChain([
                    removeWorkPerDayHoursOnDragHover(),
                    ...setIntoAssignmentModeActions(props.ID),
                ])
            );
        }
    },
};

export function collect(connect, monitor): ICollect {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
        getItem: monitor.getItem(),
    };
}

export interface ICollect {
    connectDragSource: ConnectDragSource;
    connectDragPreview: ConnectDragPreview;
    isDragging: boolean;
    getItem: IDraggableAssignment;
}

export enum DragDropTypes {
    ASSIGNMENT = 'Assignment',
}

export interface IDraggableAssignment {
    ID: string;
    objCode: TObjCode;
    draggedFromUserID: string;
    type: DragDropTypes;
    idExpression: string;
    workPerDays: TWorkPerDays;
    sectionType: Sections;
    assignments: IUnassignedTasksAssignment[];
    loadTaskAssignments: Promise<IAssignmentsDetails[] | null>;
}
