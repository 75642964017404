import { ICurrentCustomerState } from './ICurrentCustomerState';

export function getCurrentCustomerState(options): ICurrentCustomerState {
    const { currentCustomer = {} } = options;
    const customQuarterData = currentCustomer.isCustomQuarterEnabled
        ? currentCustomer.customQuarters
        : [];

    return {
        ID: currentCustomer.ID,
        timeZone: currentCustomer.timeZone,
        customQuarters: customQuarterData,
        currentLane: currentCustomer.currentLane,
    };
}
