import { ResponseHandler } from 'workfront-api';
import { Field, FieldGroup } from '@workfront/panel-components';
import _ from 'lodash';
import { getStandardHeaders, makeControllerCall } from '../api';
import {
    objCodesFilterFields,
    PROJECTS_SORT_QUICK_FILTER_FIELDS_PER_OBJECT,
    QUICK_FILTER_FIELDS_PER_OBJECT,
    UNASSIGNED_QUICK_FILTER_FIELDS_PER_OBJECT,
} from '../../../constants/filters/filterConstants';
import {
    objCodeMapper,
    combineObjectRelatedDataFields,
    formatAdvancedFieldsGroupKeys,
    formatGroupKeys,
    getObjectDirectFields,
    isCustomTypeaheadObject,
} from '../../../util/filters/filterUtil';
import { filterWildCards } from '../../../constants/filters/filterOptions';
import { IWorkItemStatuses } from '../../../data-flow/data/IDataState';

export function filterApiCall(
    endPoint: string,
    method?: 'GET' | 'POST',
    data?: Record<string, string | number | boolean>
): Promise<any> {
    const headers = getStandardHeaders();

    const options = {
        method: method || 'GET',
        credentials: 'same-origin',
        headers,
    } as RequestInit;

    if (data) {
        const bodyParts = Object.keys(data).map(
            (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        );
        if (options.method === 'GET') {
            endPoint += `?${bodyParts.join('&')}`;
        } else {
            options.body = bodyParts.join('&');
        }
    }

    return fetch(
        `/filter-service/filter${endPoint.charAt(0) === '/' ? '' : '/'}${endPoint}`,
        options
    )
        .then(ResponseHandler.success)
        .catch(ResponseHandler.failure);
}

export function getProjectsStatuses(): Promise<IWorkItemStatuses> {
    return makeControllerCall('/customenum/getMergedStatuses?statusType=STATUS_PROJ', 'GET');
}

export function getTasksStatuses(): Promise<IWorkItemStatuses> {
    return makeControllerCall('/customenum/getMergedStatuses?statusType=STATUS_TASK', 'GET');
}

export async function getFilterFields(): Promise<any> {
    const sliceCount = objCodesFilterFields.length / 2;
    const [firstSlice, secondSlice] = await Promise.all([
        makeControllerCall('objectMetadata', 'POST', {
            objCodes: objCodesFilterFields.slice(0, sliceCount),
        }),
        makeControllerCall('objectMetadata', 'POST', {
            objCodes: objCodesFilterFields.slice(sliceCount),
        }),
    ]);
    const data = _.merge(firstSlice, secondSlice);
    const skipFieldFn: any = (field) => !field.isFilterable;
    const quickFilterFields: Field[][] = [];
    const unassignedQuickFilterFields: Field[][] = [];
    const advancedFilterFields: FieldGroup[] = [];

    const projectsSortQuickFilterFields: Field[][] = [];
    const projectsSortAdvancedFilterFields: FieldGroup[] = [];
    const projectsSortSkipFieldFn: any = (field) => !field.isSortable;

    const { ObjectMetadataProcessor } = await import(
        /* webpackChunkName: "filter-redrock-integration" */ '@workfront/filter-redrock-integration'
    );

    objCodesFilterFields.forEach((objCode) => {
        const objectMetadataProcessor = new ObjectMetadataProcessor(
            objCode,
            data,
            skipFieldFn,
            filterWildCards
        );

        if (objCode === objCodeMapper.project) {
            const objectMetadataProcessorProjects = new ObjectMetadataProcessor(
                objCode,
                data,
                projectsSortSkipFieldFn,
                filterWildCards
            );
            projectsSortQuickFilterFields.push(
                objectMetadataProcessorProjects.getFieldsMetadata(
                    PROJECTS_SORT_QUICK_FILTER_FIELDS_PER_OBJECT
                )
            );
            projectsSortAdvancedFilterFields.push(
                objectMetadataProcessorProjects.getFieldGroups()[0]
            );
        }

        if (QUICK_FILTER_FIELDS_PER_OBJECT[objCode]) {
            quickFilterFields.push(
                objectMetadataProcessor.getFieldsMetadata(QUICK_FILTER_FIELDS_PER_OBJECT[objCode])
            );
        }

        if (UNASSIGNED_QUICK_FILTER_FIELDS_PER_OBJECT[objCode]) {
            unassignedQuickFilterFields.push(
                objectMetadataProcessor.getFieldsMetadata(
                    UNASSIGNED_QUICK_FILTER_FIELDS_PER_OBJECT[objCode]
                )
            );
        }

        const objectRelatedData = objectMetadataProcessor
            .getFieldGroups()
            .filter((group) => group.key === objCode || isCustomTypeaheadObject(group.key));
        const objectFields =
            objectRelatedData.length > 1
                ? combineObjectRelatedDataFields(objectRelatedData, objCode)
                : getObjectDirectFields(objectRelatedData, objCode);

        advancedFilterFields.push({
            ...objectMetadataProcessor.getFieldGroups()[0],
            fields: objectFields,
        });
    });
    return {
        quickFilterFields: formatGroupKeys(_.flatten(quickFilterFields)),
        unassignedQuickFilterFields: formatGroupKeys(_.flatten(unassignedQuickFilterFields)),
        advancedFilterFields: formatAdvancedFieldsGroupKeys(advancedFilterFields),
        projectsSortQuickFilterFields: formatGroupKeys(_.flatten(projectsSortQuickFilterFields)),
        projectsSortAdvancedFilterFields: formatAdvancedFieldsGroupKeys(
            projectsSortAdvancedFilterFields
        ),
    };
}
