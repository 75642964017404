import * as React from 'react';
import { css } from 'react-emotion';
import { Sections } from '../../../constants/schedulingTableConstants';
import { inlineItemsCentered } from '../../../styles';
import { PeopleWorkloadSectionRightCell } from './peopleWorkLoadSection/PeopleWorkloadSectionRightCell';
import { ShowMore } from './ShowMore';
import { UnassignedWorkSectionRightCell } from './unassignedSection/UnassignedWorkSectionRightCell';

interface IRightCellProps extends React.HTMLAttributes<HTMLDivElement> {
    idExpression: string;
    sectionType: Sections;
    hasDataIDs: boolean;
    isTheLastRow: boolean;
    tableViewportWidth?: number;
    leftSidePanelWidth?: number;
}

const rightCellStyles = css`
    ${inlineItemsCentered};
    flex: 1;
    position: relative;
    padding: 0 1px;
`;

export const RightCell: React.FunctionComponent<IRightCellProps> = React.memo((props) => {
    const {
        sectionType,
        idExpression,
        hasDataIDs,
        tableViewportWidth,
        leftSidePanelWidth,
        isTheLastRow,
    } = props;
    let component;

    if (idExpression && idExpression.indexOf('showMore') !== -1) {
        component = <ShowMore sectionType={sectionType} idExpression={idExpression} />;
    } else if (sectionType === Sections.UNASSIGNED_WORK) {
        component = (
            <UnassignedWorkSectionRightCell
                idExpression={idExpression}
                hasDataIDs={hasDataIDs}
                tableViewportWidth={tableViewportWidth}
                leftSidePanelWidth={leftSidePanelWidth as number}
            />
        );
    } else {
        component = (
            <PeopleWorkloadSectionRightCell
                idExpression={idExpression}
                isTheLastRow={isTheLastRow}
            />
        );
    }

    return (
        <div
            className={rightCellStyles}
            data-testid="right-cell"
            style={{
                height: 'inherit',
                backgroundColor: sectionType === Sections.UNASSIGNED_WORK ? 'white' : '',
            }}
        >
            {component}
        </div>
    );
});
