import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'REMOVE_OBJECT_ASSIGNMENTS_DETAILS';

interface IRemoveObjectAssignmentsDetailsPayload {
    objectIDs: string[];
}

type TRemoveObjectAssignmentsDetailsAction =
    TModernSchedulingAction<IRemoveObjectAssignmentsDetailsPayload>;

const removeObjectAssignmentsDetails = (objectIDs): TRemoveObjectAssignmentsDetailsAction => ({
    type: ActionType,
    payload: {
        objectIDs,
    },
});
export default removeObjectAssignmentsDetails;

export function handle(
    state: IDataState,
    { payload }: TRemoveObjectAssignmentsDetailsAction
): IDataState {
    const assignmentsDetails = { ...state.objectAssignmentDetails };
    payload.objectIDs.forEach((objectID) => {
        delete assignmentsDetails[objectID];
    });

    return {
        ...state,
        objectAssignmentDetails: assignmentsDetails,
    };
}
