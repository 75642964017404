import { Localization } from '@workfront/localize-react';
import React from 'react';
import { css, cx } from 'react-emotion';
import { Button, Tooltip } from '@phoenix/all';
import { connect } from 'react-redux';
import DownCaratIcon from 'phoenix-icons/dist/DownCaratIcon.js';
import RightCaratIcon from 'phoenix-icons/dist/RightCaratIcon.js';
import { Sections } from '../../../constants/schedulingTableConstants';
import {
    borderBottomRemovedStyle,
    borderTopRemovedStyle,
    cellPadding,
    sectionNameStyle,
    tableHeaderLeftStyles,
} from './TableHeaderStyles';
import { getContextValue } from '../../../contexts/checkContext';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { tableOpenedStateSelector } from '../../../data-flow/tableSizes/selectors/tableOpenedStateSelector';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../data-flow/types';
import {
    IProjectGroupingMode,
    TIsTableCollapsed,
    TMessageKeyWithAria,
} from '../../../data-flow/data/IDataState';
import toggleLoading from '../../../data-flow/data/dataLoadingActions/toggleLoading';
import { TableHeaderUnassignedArrowButton } from './TableHeaderUnassignedArrowButton';
import { projectGroupingModeSelector } from '../../../data-flow/settings/settingsSelector';
import { getSchedulingAreaData } from '../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { IAreaState } from '../../../data-flow/areaData/areaRelatedInitialDataState';
import { checkIsButtonVisible } from '../../../util/schedulingUtilities';
import { TableHeaderArrowButton } from './TableHeaderArrowButton';
import { hasLoadedDataIDs } from '../schedulingTables/schedulingTableUtils';
import { tableDataIDsForUnassignedSectionSelector } from '../../../data-flow/data/selectors/dataSelectors';
import { loadingStateUnassignedWorkSectionSelector } from '../../../data-flow/data/selectors/isInLoadingState/isInLoadingStateSelectors';
import { TABLE_HEADER_KEYS } from '../../../constants/tableHeaderKeys';
import { loadDataForUnassignedSection } from '../../../data-flow/thunks/unassignedSectionLoadDataThunk';

interface ITableHeaderUnassignedSectionLeftSideStateToProps {
    isTableCollapsed: TIsTableCollapsed;
    tableDataIDsForUnassignedSection: string[];
    isInLoadingStateUnassignedWorkSection: boolean;
    projectGroupingMode: IProjectGroupingMode;
    schedulingAreaData: IAreaState;
}

interface ITableHeaderUnassignedSectionLeftSideProps {
    toggleUnAssignedSection: () => void;
}

export const TableHeaderUnassignedSectionLeftSideComponent: React.FunctionComponent<
    ITableHeaderUnassignedSectionLeftSideStateToProps &
        ITableHeaderUnassignedSectionLeftSideProps &
        IWorkSchedulingDispatchProp
> = React.memo(
    ({
        isTableCollapsed,
        dispatch,
        toggleUnAssignedSection,
        tableDataIDsForUnassignedSection,
        isInLoadingStateUnassignedWorkSection,
        projectGroupingMode,
        schedulingAreaData,
    }) => {
        const isButtonEnable = checkIsButtonVisible(
            !isTableCollapsed,
            projectGroupingMode,
            tableDataIDsForUnassignedSection,
            schedulingAreaData.schedulingAreaObjCode
        );

        const [toggleBtnMsgKeys, buttonDataTestId]: [TMessageKeyWithAria, string] =
            !isTableCollapsed
                ? [TABLE_HEADER_KEYS.unassigned_work_collapse, 'unassignedWork_collapse']
                : [TABLE_HEADER_KEYS.unassigned_work_expand, 'unassignedWork_expand'];

        const handleClick = (): void => {
            toggleUnAssignedSection();
            if (
                isTableCollapsed &&
                !hasLoadedDataIDs(tableDataIDsForUnassignedSection, Sections.UNASSIGNED_WORK) &&
                !isInLoadingStateUnassignedWorkSection
            ) {
                dispatch(toggleLoading(Sections.UNASSIGNED_WORK, true));
                dispatch(loadDataForUnassignedSection());
            }
        };

        return (
            <div
                className={cx(
                    borderTopRemovedStyle,
                    tableHeaderLeftStyles,
                    borderBottomRemovedStyle
                )}
                data-testid="grid-header-left"
            >
                <div className={cellPadding}>
                    {getContextValue('sharableLink') ? (
                        <TableHeaderArrowButton />
                    ) : (
                        <TableHeaderUnassignedArrowButton
                            dataIDs={tableDataIDsForUnassignedSection}
                            isButtonEnable={isButtonEnable}
                        />
                    )}
                    <h3 className={sectionNameStyle}>
                        {getContextValue('sharableLink') ? (
                            <Localization<string>
                                messageKey={TABLE_HEADER_KEYS.assigned_work_title.messageKey}
                                fallback={TABLE_HEADER_KEYS.assigned_work_title.fallBack}
                            />
                        ) : (
                            <Localization<string>
                                messageKey={TABLE_HEADER_KEYS.unassigned_work_title.messageKey}
                                fallback={TABLE_HEADER_KEYS.unassigned_work_title.fallBack}
                            />
                        )}
                    </h3>
                    {!getContextValue('sharableLink') && (
                        <Tooltip
                            content={localizationClient.getTextSync(
                                toggleBtnMsgKeys.messageKey,
                                toggleBtnMsgKeys.fallBack
                            )}
                        >
                            <Button
                                text
                                className={arrowCaret}
                                onClick={handleClick}
                                aria-expanded={isTableCollapsed}
                                aria-label={localizationClient.getTextSync(
                                    toggleBtnMsgKeys.ariaMessageKey,
                                    toggleBtnMsgKeys.ariaFallBack
                                )}
                                data-testid={buttonDataTestId}
                            >
                                {!isTableCollapsed ? (
                                    <DownCaratIcon height={20} />
                                ) : (
                                    <RightCaratIcon height={20} />
                                )}
                            </Button>
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    }
);

const mapStateToProps = (
    state: IWorkSchedulingCombinedState
): ITableHeaderUnassignedSectionLeftSideStateToProps => ({
    isTableCollapsed: tableOpenedStateSelector(state),
    tableDataIDsForUnassignedSection: tableDataIDsForUnassignedSectionSelector(state),
    isInLoadingStateUnassignedWorkSection: loadingStateUnassignedWorkSectionSelector(state),
    projectGroupingMode: projectGroupingModeSelector(state),
    schedulingAreaData: getSchedulingAreaData(state),
});

export const TableHeaderUnassignedSectionLeftSide = connect(mapStateToProps)(
    TableHeaderUnassignedSectionLeftSideComponent
);

const arrowCaret = css`
    min-width: 40px;
    margin-right: -14px;
    display: inline-block !important;
    float: right;
    top: 7px;
    pointer-events: initial;
`;
