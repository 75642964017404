import { TModernSchedulingAction } from '../../../types';
import { IDataState } from '../../IDataState';

export const ActionType = 'SET_ESP_PRODUCT_ENABLED';

interface TEspProductEnabledDataActionPayload {
    status: boolean;
}

type TEspProductEnabledDataAction = TModernSchedulingAction<TEspProductEnabledDataActionPayload>;

const setEspProductEnabled = (status): TEspProductEnabledDataAction => ({
    type: ActionType,
    payload: {
        status,
    },
});

export function handle(state: IDataState, { payload }: TEspProductEnabledDataAction): IDataState {
    return {
        ...state,
        espProductEnabled: payload.status,
    };
}

export default setEspProductEnabled;
