import { Sections } from '../../constants/schedulingTableConstants';
import { IAbleToReload, IReloadState } from '../data/IDataState';
import { TModernSchedulingAction } from '../types';

export const ActionType = 'SET_RELOAD_STATE';

interface IChangeReloadStatePayload {
    reloadState: IAbleToReload;
    sectionType: Sections;
}

export type TChangeReloadStatePayload = TModernSchedulingAction<IChangeReloadStatePayload>;

const changeReloadState = (
    sectionType: Sections,
    reloadState: IAbleToReload
): TChangeReloadStatePayload => ({
    type: ActionType,
    payload: {
        sectionType,
        reloadState,
    },
});

export default changeReloadState;

export function handle(state: IReloadState, { payload }: TChangeReloadStatePayload): IReloadState {
    return {
        ...state,
        [payload.sectionType]: payload.reloadState,
    };
}
