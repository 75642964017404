import { changedAssignment } from '../../../util/contouringValidation';
import { TModernSchedulingAction } from '../../types';
import { IDataState, IRequestedTemporaryWorkPerDayFromService } from '../IDataState';

export const ActionType = 'CHANGE_ASSIGNMENT_WORK_PER_DAY_HOURS';

interface IChangeAssignmentsWorkPerDayHoursPayload {
    tempWorkPerDays: IRequestedTemporaryWorkPerDayFromService;
    IDs: string[];
}

type TChangeUserWorkPerDays = TModernSchedulingAction<IChangeAssignmentsWorkPerDayHoursPayload>;

const changeAssignmentWorkPerDayHours = (tempWorkPerDays, IDs): TChangeUserWorkPerDays => ({
    type: ActionType,
    payload: {
        tempWorkPerDays,
        IDs,
    },
});
export default changeAssignmentWorkPerDayHours;

export function handle(state: IDataState, { payload }: TChangeUserWorkPerDays): IDataState {
    const workPerDaysByAssignmentsFromService = { ...state.workPerDaysByAssignmentsFromService };
    const assignments = { ...state.users[payload.IDs[0]].assignments };

    const changedAssignmentID: string | undefined = changedAssignment(
        assignments,
        payload.IDs[payload.IDs.length - 1]
    );
    if (changedAssignmentID) {
        if (workPerDaysByAssignmentsFromService[changedAssignmentID]) {
            Object.keys(workPerDaysByAssignmentsFromService[changedAssignmentID]).forEach((key) => {
                if (
                    payload.tempWorkPerDays.assignmentID &&
                    payload.tempWorkPerDays.assignmentID === changedAssignmentID &&
                    payload.tempWorkPerDays.data[key] !== undefined
                ) {
                    workPerDaysByAssignmentsFromService[changedAssignmentID][key] =
                        payload.tempWorkPerDays.data[key];
                }
            });
        }
    }

    return {
        ...state,
        workPerDaysByAssignmentsFromService,
    };
}
