import { Localization } from '@workfront/localize-react';
import FilterIcon from 'phoenix-icons/dist/FilterIcon.js';
import React from 'react';
import { connect } from 'react-redux';
import { FilterButton as FilterIconButton } from '@workfront/panel-components';
import { Tooltip } from '@phoenix/all';
import { GlobalPropsContext } from '../../contexts/globalContexts';
import { closeAllPanelsChain } from '../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import changeFilterOpenState from '../../data-flow/filters/filtersActions/changeFilterOpenState';
import {
    IAssignedFiltersState,
    IUnassignedFiltersState,
} from '../../data-flow/filters/IFiltersState';
import actionChain from '../../data-flow/higher-order-reducers/actionChain';
import { IWorkSchedulingCombinedState, IWorkSchedulingDispatchProp } from '../../data-flow/types';
import {
    centerFilterButton,
    filterButtonPosition,
} from '../styles/filters/filterButtonComponentStyles';
import { Sections } from '../../constants/schedulingTableConstants';
import { unassignedWorkHeightSelector } from '../../data-flow/tableSizes/selectors/unassignedWorkHeightSelector';

const filterBtnMessageKeys = {
    [Sections.UNASSIGNED_WORK]: {
        messageKeys: [
            'workloadbalancer.open.unassigned.filters',
            'workloadbalancer.open.unassigned.nofilters',
        ],
        fallBacks: [
            'Open unassigned work filter, Currently selected value',
            'Open unassigned work filter, Currently no filter applied',
        ],
    },
    [Sections.PEOPLE_WORKLOAD]: {
        messageKeys: [
            'workloadbalancer.open.assigned.filters',
            'workloadbalancer.open.assigned.nofilters',
        ],
        fallBacks: [
            'Open assigned work filter, Currently selected value',
            'Open assigned work filter, Currently no filter applied',
        ],
    },
};

interface IFilterButtonProps extends IWorkSchedulingDispatchProp {
    section: string;
}

interface IFilterButtonStateToProps {
    filterData: IAssignedFiltersState | IUnassignedFiltersState;
    unassignedHeight: number;
}

export class FilterButtonComponent extends React.PureComponent<
    IFilterButtonProps & IFilterButtonStateToProps
> {
    static contextType = GlobalPropsContext;
    context!: React.ContextType<typeof GlobalPropsContext>;
    private buttonRef = React.createRef<HTMLButtonElement>();
    private apiObj: {
        setCount: (val) => void;
    } = {
        setCount: () => {
            // This is intentional, It is default value.
        },
    };

    componentDidMount(): void {
        const { section, unassignedHeight } = this.props;
        /**
         *  If unassigned section is already open, we don't want to focus the filter button.
         *  If unassigned section is already open `unassignedHeight` is 0,
         *  as 0 is default value for this state in reducer.
         * */
        const isUnassignedSectionOpen = unassignedHeight !== 0;
        if (section === Sections.UNASSIGNED_WORK && isUnassignedSectionOpen) {
            this.buttonRef.current?.focus();
        }
    }

    componentDidUpdate(prevProps: Readonly<IFilterButtonProps & IFilterButtonStateToProps>): void {
        const { appliedFiltersCount } = this.props.filterData;
        if (prevProps.filterData.appliedFiltersCount !== appliedFiltersCount) {
            this.apiObj.setCount(appliedFiltersCount);
        }
    }

    handleWidgetApi = (api): void => {
        const { appliedFiltersCount } = this.props.filterData;

        api.setCount(appliedFiltersCount);
        Object.assign(this.apiObj, api);
    };

    handleButtonClick = (): void => {
        if (this.props.filterData.isFilterOpen) {
            return;
        }

        this.context.minixClose();

        this.props.dispatch(
            actionChain([...closeAllPanelsChain(), changeFilterOpenState(true, this.props.section)])
        );
    };

    render(): JSX.Element {
        const { filterData, section } = this.props;
        const { filterName = '', appliedFiltersCount } = filterData;

        return (
            <div className={filterButtonPosition}>
                <Localization<string[]>
                    messageKeys={[
                        'uifilter',
                        'uifilter.plural',
                        'nofilter.applied',
                        ...filterBtnMessageKeys[section].messageKeys,
                    ]}
                >
                    {({ _t }) => {
                        const filters = _t['uifilter.plural']('Filters');
                        const noFilters = _t['nofilter.applied']('No filter applied');
                        const filterNameValue = filterName || noFilters;

                        const tooltipValue =
                            appliedFiltersCount > 1
                                ? `${appliedFiltersCount} ${filters}`
                                : filterNameValue;

                        return (
                            <Tooltip content={tooltipValue} className={centerFilterButton}>
                                <FilterIconButton
                                    ariaLabel={tooltipValue}
                                    dataTestId="open-filter-button"
                                    getApi={this.handleWidgetApi}
                                    icon={FilterIcon}
                                    onClick={this.handleButtonClick}
                                    withText={false}
                                />
                            </Tooltip>
                        );
                    }}
                </Localization>
            </div>
        );
    }
}

const mapStateToProps = (
    state: IWorkSchedulingCombinedState,
    ownProps: { section: string }
): IFilterButtonStateToProps => {
    return {
        filterData: state.Filters[ownProps.section],
        unassignedHeight: unassignedWorkHeightSelector(state),
    };
};

export const FilterButton = connect(mapStateToProps)(FilterButtonComponent);
