import { apiDateToDate } from '@workfront/fns';
import { OpTask as opTaskObjCode } from 'workfront-objcodes';
import { IAssignment } from 'workfront-typescript-definitions';
import {
    IAssignmentPredecessor,
    IRequestedAssignment,
    IRequestedPredecessor,
    IUserToAssignmentID,
    IUserToAssignmentStatus,
    TDate,
    TTaskOrOpTask,
} from '../IDataState';

export const getProjectIDs = (userProjectIds): any => {
    return userProjectIds ? [...userProjectIds] : [];
};

export const getTaskIssueNodeFromAssignment = (
    assignmentItem: IRequestedAssignment
): TTaskOrOpTask | undefined => {
    return assignmentItem.task || assignmentItem.opTask;
};

export const getProjectedStartCompletionFromNode = (
    taskIssueNode
): {
    projectedStartDate: TDate;
    projectedCompletionDate: TDate;
} => {
    let { projectedStartDate, projectedCompletionDate } = taskIssueNode;

    if (taskIssueNode.objCode === opTaskObjCode) {
        projectedStartDate = taskIssueNode.actualStartDate || taskIssueNode.plannedStartDate;
        projectedCompletionDate =
            taskIssueNode.actualCompletionDate || taskIssueNode.plannedCompletionDate;
    }

    return {
        projectedStartDate: apiDateToDate(projectedStartDate),
        projectedCompletionDate: apiDateToDate(projectedCompletionDate),
    };
};

export const flattenPredecessors = (
    predeccessors: IRequestedPredecessor[] = []
): IAssignmentPredecessor[] =>
    predeccessors.map((predecessorInstance) => predecessorInstance.predecessor);

export const mapAssignedUserIDToStatus = (assignments: IAssignment[]): IUserToAssignmentStatus => {
    const userIDToAssignmentStatus: IUserToAssignmentStatus = {};

    assignments?.forEach((assgn) => {
        if (assgn.assignedToID) userIDToAssignmentStatus[assgn.assignedToID] = assgn.status!;
    });

    return userIDToAssignmentStatus;
};

export const mapAssignedUserIDToAssignmentID = (
    assignments: IAssignment[]
): IUserToAssignmentID => {
    const assignmentUserIDToAssignmentID: IUserToAssignmentID = {};

    assignments?.forEach((assgn) => {
        if (assgn.assignedToID) assignmentUserIDToAssignmentID[assgn.assignedToID] = assgn.ID!;
    });

    return assignmentUserIDToAssignmentID;
};
