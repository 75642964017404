import React, { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import { cx } from 'emotion';
import { Popper } from 'react-popper';
import CalendarSmallIcon from 'phoenix-icons/dist/CalendarSmallIcon';
import HoursSmallIcon from 'phoenix-icons/dist/HoursSmallIcon';
import PredecessorsIcon from 'phoenix-icons/dist/PredecessorsIcon';
import TaskSmallIcon from 'phoenix-icons/dist/TaskSmallIcon';
import { Link } from 'react-router-dom';
import { Project, Task } from 'workfront-objcodes';
import { Font, primary, Tooltip, Button, Text } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import { TLocalizationMessageArgWithFallback } from '@workfront/localize-react/dist/typings/Localization';
import { convertMinuteToHour, formatNumber, getFormattedDateRange } from '../../../util/utilities';
import { TimeUnit } from '../../../constants/periodEnums';
import {
    IAssignmentParent,
    IAssignmentPredecessor,
    IObjectState,
    IUserToAssignmentStatus,
} from '../../../data-flow/data/IDataState';

import { getFontType } from '../../../util/getFontType';
import { getLinkRelatedProps, ILinkRelatedProps } from '../left/ProjectName/ProjectName';
import { PopperVirtualReference } from '../../../common-hooks/useAssignmentPopover';
import {
    popoverButtonStyles,
    popoverLinkStyles,
    popoverParentTaskTitle,
    popoverSeperator,
    popoverArrow,
    popoverArrowRight,
    popoverClass,
    popoverContentClass,
    popoverShow,
    popoverIndicatorWrapper,
    PopoverBody,
    PopoverHeader,
    PopoverTopRow,
    PopoverReadyIndicator,
    PopoverStyledColumn,
    PopoverStyledLine,
    PopoverStyledPredecessors,
} from '../../styles/AssignmentPopoverStyles';
import { STATUS_AWAITING_ACCEPTANCE } from '../../../constants/assignmentConstants';

export interface IAssignmentPopoverProps {
    show: () => void;
    hide: (e: React.MouseEvent<HTMLDivElement>, force?: boolean) => void;
    taskTitle: string;
    taskColor: string | undefined;
    textColor: string | undefined;
    plannedStartDate: Date | undefined;
    plannedCompletionDate: Date | undefined;
    arrowPosRight: boolean;
    referenceElement: PopperVirtualReference;
    predecessors: IAssignmentPredecessor[];
    parent: IAssignmentParent | null;
    canStart: boolean;
    projectID: string;
    taskID: string;
    isAssigned: boolean;
    projectName: string;
    assignmentUserIDToStatus?: IUserToAssignmentStatus;
    workRequired?: IObjectState['workRequired'];
    userID?: string;
    objCode: string;
}

export const assignmentPopoverTestIds = {
    popover: 'assignment_popover',
    parentTask: 'assignment_popover_parent_task',
};

interface IPredecessorProps {
    taskID: string;
    canStart: boolean;
    predecessors: IAssignmentPredecessor[];
    _t: Record<string, TLocalizationMessageArgWithFallback>;
}

const Predecessors: React.FunctionComponent<IPredecessorProps> = ({
    taskID,
    canStart,
    predecessors,
    _t,
}) => (
    <div>
        <Tooltip
            position="right"
            content={[
                <i>{_t['successor.dependency']('Dependent on:')} </i>,
                ...predecessors.map((item: IAssignmentPredecessor) => <p>{item.name}</p>),
            ]}
        >
            <Link
                to={`/task/${taskID}/allPredecessors`}
                className={popoverLinkStyles}
                target="_blank"
            >
                <Button role="button" text className={popoverButtonStyles}>
                    <PopoverStyledPredecessors isReady={canStart}>
                        <PopoverStyledColumn>
                            <PredecessorsIcon />
                            {predecessors.length}{' '}
                            {predecessors.length > 1
                                ? _t['task.lowercase.plural']('tasks')
                                : _t['task.lowercase']('task')}
                        </PopoverStyledColumn>
                    </PopoverStyledPredecessors>
                </Button>
            </Link>
        </Tooltip>
    </div>
);

const AssignmentPopover: React.FunctionComponent<IAssignmentPopoverProps> = (props) => {
    const {
        show,
        hide,
        workRequired,
        taskTitle,
        taskColor,
        textColor,
        plannedStartDate,
        arrowPosRight,
        referenceElement,
        plannedCompletionDate,
        predecessors,
        canStart,
        parent,
        taskID,
        projectName,
        projectID,
        assignmentUserIDToStatus,
        userID,
        objCode,
    } = props;

    const isAwaitingAcceptance = Boolean(
        assignmentUserIDToStatus &&
            userID &&
            assignmentUserIDToStatus[userID] === STATUS_AWAITING_ACCEPTANCE
    );

    const workHoursRequired = workRequired && userID ? workRequired[userID] : 0;

    const [open, setOpen] = useState(false);

    let parentLinkRelatedProps: ILinkRelatedProps;

    if (parent) {
        const parentComponentDetails = getFontType(true, parent.ID, Task);
        parentLinkRelatedProps = getLinkRelatedProps(true, parentComponentDetails);
    }

    const titleComponentDetails = getFontType(true, projectID, Project);
    const titleLinkRelatedProps = getLinkRelatedProps(true, titleComponentDetails);

    const subtitleComponentDetails = getFontType(true, taskID, objCode);
    const subtitleLinkRelatedProps = getLinkRelatedProps(true, subtitleComponentDetails);

    return createPortal(
        <Popper
            placement="top"
            modifiers={{
                offset: { offset: '0px, 0px' },
            }}
            referenceElement={referenceElement}
        >
            {({ ref, style, placement, arrowProps, scheduleUpdate }): ReactNode => (
                <>
                    {/* We don't want the popover to be focusable for now,
                        but mouseover and mouseleave event listeners needed
                        to keep the popover open when hovering over it */}
                    {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                    <div
                        role="region"
                        data-testid={assignmentPopoverTestIds.popover}
                        ref={ref}
                        style={style}
                        data-placement={placement}
                        className={cx(popoverClass, open && popoverShow)}
                        onMouseOver={show}
                        onMouseLeave={hide}
                    >
                        <Localization<string[]>
                            messageKeys={[
                                'hour.abbr.short',
                                'filter.readytostart',
                                'task.lowercase.plural',
                                'task.lowercase',
                                'notready',
                                'parenttask',
                                'successor.dependency',
                            ]}
                        >
                            {({ _t }): ReactNode => {
                                return (
                                    <div className={popoverContentClass}>
                                        {isAwaitingAcceptance && (
                                            <div className={popoverIndicatorWrapper}>
                                                <Tooltip
                                                    content={
                                                        canStart
                                                            ? _t['filter.readytostart'](
                                                                  'Ready to Start'
                                                              )
                                                            : _t.notready('Not ready')
                                                    }
                                                >
                                                    <PopoverReadyIndicator canStart={canStart} />
                                                </Tooltip>
                                            </div>
                                        )}
                                        <PopoverHeader taskColor={taskColor}>
                                            <Font
                                                style={{
                                                    color: textColor,
                                                    fontWeight: 600,
                                                    lineHeight: '18px',
                                                }}
                                                to={titleLinkRelatedProps.to}
                                                type={titleLinkRelatedProps.type}
                                                target={titleLinkRelatedProps.target}
                                            >
                                                {projectName}
                                            </Font>
                                            <br />
                                            <Font
                                                style={{
                                                    color: textColor,
                                                    fontSize: '12px',
                                                    fontWeight: 600,
                                                    lineHeight: '16px',
                                                }}
                                                to={subtitleLinkRelatedProps.to}
                                                type={subtitleLinkRelatedProps.type}
                                                target={subtitleLinkRelatedProps.target}
                                            >
                                                {taskTitle}
                                            </Font>
                                        </PopoverHeader>
                                        <PopoverBody>
                                            <PopoverTopRow>
                                                <PopoverStyledColumn>
                                                    <CalendarSmallIcon />
                                                    {getFormattedDateRange(
                                                        moment(plannedStartDate),
                                                        moment(plannedCompletionDate),
                                                        TimeUnit.DAY,
                                                        true
                                                    )}
                                                </PopoverStyledColumn>
                                                <div className={popoverSeperator} />
                                                {!!workRequired && (
                                                    <PopoverStyledColumn
                                                        style={{ display: 'flex' }}
                                                    >
                                                        <HoursSmallIcon />
                                                        <div>
                                                            {formatNumber(
                                                                convertMinuteToHour(
                                                                    workHoursRequired
                                                                )
                                                            )}
                                                            {_t['hour.abbr.short']('h')}
                                                        </div>
                                                    </PopoverStyledColumn>
                                                )}
                                                <div className={popoverSeperator} />
                                                {!!predecessors.length && (
                                                    <Predecessors
                                                        _t={_t}
                                                        taskID={taskID}
                                                        canStart={canStart}
                                                        predecessors={predecessors}
                                                    />
                                                )}
                                            </PopoverTopRow>
                                            {!!parent && (
                                                <div
                                                    data-testid={
                                                        assignmentPopoverTestIds.parentTask
                                                    }
                                                >
                                                    <PopoverStyledLine />
                                                    <Text.Small className={popoverParentTaskTitle}>
                                                        {_t.parenttask('Parent task')}
                                                    </Text.Small>
                                                    <PopoverStyledColumn>
                                                        <TaskSmallIcon />
                                                        <Font
                                                            style={{
                                                                color: primary.doctorStrange(),
                                                            }}
                                                            to={parentLinkRelatedProps.to}
                                                            type={parentLinkRelatedProps.type}
                                                            target={parentLinkRelatedProps.target}
                                                        >
                                                            {parent.name}
                                                        </Font>
                                                    </PopoverStyledColumn>
                                                </div>
                                            )}
                                        </PopoverBody>
                                        <InitialPositionFix
                                            scheduleUpdate={scheduleUpdate}
                                            setOpen={setOpen}
                                        />
                                    </div>
                                );
                            }}
                        </Localization>
                        <div
                            ref={arrowProps.ref}
                            className={cx(popoverArrow, arrowPosRight && popoverArrowRight)}
                        />
                    </div>
                </>
            )}
        </Popper>,
        document.getElementById('dialog-container') as HTMLElement
    );
};

// React-popper fix: Component to call scheduleUpdate, and only after show popover
const InitialPositionFix = React.memo(({ scheduleUpdate, setOpen }: any) => {
    useEffect(() => {
        let isMounted = true;
        setTimeout(() => {
            if (isMounted) {
                scheduleUpdate();
                setOpen(true);
            }
        }, 50);
        return (): void => {
            isMounted = false;
        };
    }, [scheduleUpdate, setOpen]);
    return null;
});

export default AssignmentPopover;
