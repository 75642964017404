import React, { createContext } from 'react';

let getConstFunction;

export const getContextValue = (feature) =>
    getConstFunction ? getConstFunction(feature) : undefined;

export const GetConst = createContext(getContextValue);

interface IUnchangeableContext {
    children: any;
    contextValues: {
        [constantValueName: string]: any;
    };
}

export class UnchangeableContext extends React.Component<IUnchangeableContext, any> {
    constructor(props) {
        super(props);

        getConstFunction = (checkValue) => props.contextValues[checkValue];

        this.state = {
            getContextValue: getConstFunction,
        };
    }

    render() {
        return (
            <GetConst.Provider value={this.state.getContextValue}>
                {this.props.children}
            </GetConst.Provider>
        );
    }
}
