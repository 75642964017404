import _ from 'lodash';
import { LOAD_ASSIGNMENTS_LIMIT, wpdCallMaxCount } from '../constants/dataConstatnts';
import {
    IContouredAssignments,
    IRequestedWorkPerDayFromService,
    LoadWorkPerDayListByAssignmentFromWPDServiceForProjectResponse,
} from '../data-flow/data/IDataState';
import { IAssignmentsObjects } from './dataStructures/IAssignmentaObjects';

export const concatWPDResponseData = (
    data
): Promise<LoadWorkPerDayListByAssignmentFromWPDServiceForProjectResponse> => {
    return data.reduce((accumulator, dataItem) => ({ ...accumulator, ...dataItem.result }), {});
};

export const modifyObjectsData = (assignmentIDs, objCode): IAssignmentsObjects => {
    return assignmentIDs.map((ID) => {
        return {
            objID: ID,
            objCode,
        };
    });
};

export const responsesToJSON = (responses: Response[]): Promise<any> => {
    return Promise.all(responses.map((response) => response.json())).then((data) => {
        return data.reduce((accumulator, dataItem) => {
            return [...accumulator, ...dataItem];
        }, []);
    });
};

export const modifyWorkPerDayResponseData = (
    workPerDayHours: any
): IRequestedWorkPerDayFromService => {
    const workPerDayModifiedData = {};
    if (workPerDayHours.length) {
        workPerDayHours.forEach((hourByDate) => {
            if (!workPerDayModifiedData[hourByDate.objID]) {
                workPerDayModifiedData[hourByDate.objID] = {
                    [hourByDate.date]: hourByDate.hour,
                };
            } else {
                workPerDayModifiedData[hourByDate.objID][hourByDate.date] = hourByDate.hour;
            }
        });
    }
    return workPerDayModifiedData;
};

export const modifyContouredAssignmentsResponseData = (
    contouredAssignments: IAssignmentsObjects[]
): IContouredAssignments => {
    const contouredAssignmentsModifiedData = {};
    contouredAssignments.forEach((assignment: IAssignmentsObjects) => {
        contouredAssignmentsModifiedData[assignment.objID] = {
            ...assignment,
        };
    });
    return contouredAssignmentsModifiedData;
};

export const getCountOfChunk = (assignmentIDs): number => {
    return Math.ceil(assignmentIDs.length / LOAD_ASSIGNMENTS_LIMIT);
};

export const getWPDHoursForTasksAndAssignments = (
    data: IRequestedWorkPerDayFromService[],
    taskID: string
): {
    taskHours: IRequestedWorkPerDayFromService | undefined;
    assignmentHours: IRequestedWorkPerDayFromService | undefined;
} => {
    let taskHours;
    let assignmentHours;
    if (data.length === 2) {
        taskHours = data[0];
        assignmentHours = data[1];
    } else if (data.length === 1) {
        if (Object.keys(data[0]).includes(taskID)) {
            taskHours = data[0];
        } else {
            assignmentHours = data[0];
        }
    }
    return {
        taskHours,
        assignmentHours,
    };
};

export const addUserHoursOnDropHover = (
    workPerDaySums: number[],
    temporaryWorkPerDays: number[]
): number[] => {
    return workPerDaySums.map((hour, index) => hour + temporaryWorkPerDays[index]);
};

export const isNextCallNeeded = (
    workPerDayData: IRequestedWorkPerDayFromService,
    callCount: number
): boolean => {
    return _.isEmpty(workPerDayData) && callCount <= wpdCallMaxCount;
};
