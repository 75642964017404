import { Sections } from '../../../constants/schedulingTableConstants';
import { IWorkSchedulingCombinedState } from '../../types';
import { IFilterItemState, IFilterRuleGroup, IProjectStatusesForFilter } from '../IFiltersState';

export const peopleWorkloadFilterIDSelector = (
    state: IWorkSchedulingCombinedState
): IFilterItemState['filterID'] => state.Filters[Sections.PEOPLE_WORKLOAD].filterID;

export const unassignedWorkFilterIDSelector = (
    state: IWorkSchedulingCombinedState
): IFilterItemState['filterID'] => state.Filters[Sections.UNASSIGNED_WORK].filterID;

export const unassignedWorkFilterExpressionSelector = (
    state: IWorkSchedulingCombinedState
): IFilterRuleGroup => {
    return <IFilterRuleGroup>state.Filters[Sections.UNASSIGNED_WORK].filterExpression;
};

export const assignedWorkFilterExpressionSelector = (
    state: IWorkSchedulingCombinedState
): IFilterRuleGroup => {
    return <IFilterRuleGroup>(
        state.Filters[Sections.PEOPLE_WORKLOAD].assignmentsFilter.filterExpression
    );
};

export const userFilterExpressionSelector = (
    state: IWorkSchedulingCombinedState
): IFilterRuleGroup => {
    return <IFilterRuleGroup>state.Filters[Sections.PEOPLE_WORKLOAD].usersFilter.filterExpression;
};

export const peopleWorkloadAppliedFilterCountSelector = (
    state: IWorkSchedulingCombinedState
): IFilterItemState['appliedFiltersCount'] =>
    state.Filters[Sections.PEOPLE_WORKLOAD].appliedFiltersCount;

export const projectStatusesSelector = (
    state: IWorkSchedulingCombinedState
): IProjectStatusesForFilter[] => state.Filters.projectStatuses;
