import * as React from 'react';
import { ModernWorkScheduling } from './components/ModernWorkScheduling';
import { CustomDragLayer } from './components/CustomDragLayer/CustomDragLayer';
import { DndWrapper } from './components/DndWrapper';

export const ModernWorkContainer: React.FunctionComponent = React.memo(() => {
    return (
        <DndWrapper>
            <ModernWorkScheduling />
            <CustomDragLayer />
        </DndWrapper>
    );
});
