export const ActionType = 'HO_ACTION_CHAIN';

const actionChain = (actions) => ({
    type: ActionType,
    payload: {
        actions: Array.isArray(actions) ? actions : [actions],
    },
});
export default actionChain;
/**
 * Higher-order reducer which makes possible dispatching a single action,
 * which consists of multiple actions handled in the given order
 * @param reducer
 * @returns {Function}
 *
 * @example
 * ```
 * import actionChain from "../higher-order-reducers/actionChain";
 *
 * dispatch(actionChain([
 *   deselectAllRows(),
 *   selectRow(inlineAddId),
 *   enterInlineEditMode(rowData, inlineEditableFields(state)[0])
 * ]));
 * ```
 */
export const supportActionChain = (reducer) => (state, action) => {
    if (action.type === ActionType) {
        return action.payload.actions.reduce(reducer, state);
    }
    return reducer(state, action);
};
