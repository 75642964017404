import { TObjCode, UIFilter } from 'workfront-objcodes';

export interface IAccessLevel {
    isAdmin: boolean;
    accessLevelPermissions: IAccessLevelPermissions[];
    licenseType: string;
}

export interface ICurrentUser {
    ID: string;
    name: string;
    isAdmin: boolean;
    accessLevel: IAccessLevel;
    homeGroupId: string;
    coreAction: string | null;
    filterAccessLevelPermission?: {
        forbiddenActions: string[];
    };
    license: {
        licenseType: IAccessLevel['licenseType'];
        licenseTypeVersion: ICustomer['licenseTypeVersion'];
    };
}

export interface IAccessLevelPermissions {
    ID: string;
    accessLevelID: string;
    coreAction: string;
    customerID: string;
    forbiddenActions: string[];
    isAdmin: boolean;
    objCode: TObjCode;
    objObjCode: TObjCode;
    secondaryActions: any[];
}

export interface IUser {
    ID: string;
    name: string;
    accessLevel: IAccessLevel;
    homeGroupID: string;
}

export interface ICustomer {
    licenseTypeVersion: number;
}

export const currentUserMapper = (currentUser: IUser, currentCustomer: ICustomer): ICurrentUser => {
    const currentUserObjDefault = {
        ID: currentUser.ID,
        name: currentUser.name,
        isAdmin: currentUser.accessLevel.isAdmin,
        accessLevel: currentUser.accessLevel,
        homeGroupId: currentUser.homeGroupID,
        license: {
            licenseType: currentUser.accessLevel.licenseType,
            licenseTypeVersion: currentCustomer?.licenseTypeVersion,
        },
    };

    const filterPermission = currentUser.accessLevel.accessLevelPermissions?.find(
        (permission) => permission.objObjCode === UIFilter
    );
    const currentUserObj: ICurrentUser = {
        ...currentUserObjDefault,
        coreAction: filterPermission ? filterPermission.coreAction : null,
    };
    if (filterPermission) {
        currentUserObj.filterAccessLevelPermission = {
            forbiddenActions: filterPermission.forbiddenActions,
        };
    }
    return currentUserObj;
};
