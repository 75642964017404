import { IDataState } from '../IDataState';
import { TModernSchedulingAction } from '../../types';

export const ActionType = 'SET_CALCULATE_FTE_AUTOMATIC';

interface ISetCalculateFTEAutomaticActionPayload {
    calculateFTEAutomatic: boolean;
}

type TSetCalculateFTEAutomaticAction =
    TModernSchedulingAction<ISetCalculateFTEAutomaticActionPayload>;

const setCalculateFTEAutomatic = (
    calculateFTEAutomatic: boolean
): TSetCalculateFTEAutomaticAction => ({
    type: ActionType,
    payload: {
        calculateFTEAutomatic,
    },
});
export default setCalculateFTEAutomatic;

export function handle(
    state: IDataState,
    { payload }: TSetCalculateFTEAutomaticAction
): IDataState {
    const { calculateFTEAutomatic } = payload;

    return { ...state, calculateFTEAutomatic };
}
