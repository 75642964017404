import _ from 'lodash';
import {
    combineUsersTimeOffsAndScheduleExceptions,
    normalizeScheduleExceptions,
    normalizeUsersTimeOffs,
} from '../../../util/timeOffsService';
import { TModernSchedulingAction } from '../../types';
import { IDataState, IRequestedSchedulesState, IRequestedUserTimeOffs } from '../IDataState';

export const ActionType = 'ADD_TIME_OFFS';

interface IAddTimeOffsPayload {
    usersTimeOffs: IRequestedUserTimeOffs[];
    scheduleExceptions: IRequestedSchedulesState;
    usersIDs: string[];
}

type TAddTimeOffsAction = TModernSchedulingAction<IAddTimeOffsPayload>;

const addTimeOffs = (
    usersTimeOffs: IRequestedUserTimeOffs[],
    scheduleExceptions: IRequestedSchedulesState,
    usersIDs: string[]
): TAddTimeOffsAction => ({
    type: ActionType,
    payload: {
        usersTimeOffs,
        scheduleExceptions,
        usersIDs,
    },
});
export default addTimeOffs;

export function handle(state: IDataState, { payload }: TAddTimeOffsAction): IDataState {
    const users = { ...state.users };
    const { usersTimeOffs, scheduleExceptions, usersIDs } = payload;

    const usersSchedules = {};

    usersIDs.forEach((id) => {
        usersSchedules[id] = { scheduleID: users[id].scheduleID };
    });

    const normalizedScheduleExceptions = normalizeScheduleExceptions(scheduleExceptions);
    const normalizedTimeOffs = normalizeUsersTimeOffs(
        usersTimeOffs,
        normalizedScheduleExceptions,
        usersSchedules
    );

    const timeOffs = combineUsersTimeOffsAndScheduleExceptions(
        normalizedTimeOffs,
        normalizedScheduleExceptions,
        usersSchedules
    );

    _.forEach(timeOffs, (timeOffData, userID) => {
        users[userID] = {
            ...users[userID],
            timeOffs: timeOffData,
        };
    });

    return {
        ...state,
        users,
    };
}
