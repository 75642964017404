import { IAccessLevel } from 'workfront-typescript-definitions';
import { TModernSchedulingAction } from '../../types';
import { ICurrentUserState } from '../ICurrentUserState';
import { ICurrentUser } from '../../../util/currentUserMapper';
import { OLD_LICENSE_TYPE } from '../../../constants/dataConstatnts';

export const ActionType = 'CHANGE_USER_CONTOURING_EDIT_ACCESS';

interface IChangeContouringEditAccessActionPayload {
    accessLevelData: IAccessLevel;
    license: ICurrentUser['license'];
}

const changeUserContouringEditAccess = (
    accessLevelData: IAccessLevel,
    license: ICurrentUser['license']
): TModernSchedulingAction<IChangeContouringEditAccessActionPayload> => ({
    type: ActionType,
    payload: {
        accessLevelData,
        license,
    },
});
export default changeUserContouringEditAccess;

export function handle(
    state: ICurrentUserState,
    { payload }: TModernSchedulingAction<IChangeContouringEditAccessActionPayload>
): ICurrentUserState {
    const { accessLevelData, license } = payload;
    let hasContouringEditAccess = false;
    const oldLicenseSystemIsEnabled = license.licenseTypeVersion === OLD_LICENSE_TYPE;
    const isWorkerLicense = license.licenseType === 'T';
    if (accessLevelData.isAdmin || (oldLicenseSystemIsEnabled && isWorkerLicense)) {
        hasContouringEditAccess = true;
    } else if (
        accessLevelData.accessLevelPermissions &&
        accessLevelData.accessLevelPermissions.length
    ) {
        const resourceManagerAccess = accessLevelData.accessLevelPermissions.find(
            (permission) => permission.objObjCode === 'BGHRIN'
        );
        hasContouringEditAccess = Boolean(
            resourceManagerAccess && resourceManagerAccess.coreAction === 'EDIT'
        );
    }

    return {
        ...state,
        contouringEditAccess: hasContouringEditAccess,
    };
}
