import * as React from 'react';
import { InaccessibleProjectNode } from './InaccessibleProjectNode';
import { ProjectNode } from './ProjectNode';
import { TaskIssueNode } from './TaskIssueNode';
import { UserNode } from './UserNode';
import { THandleProjectArrowClick, TOnUserArrowClick } from '../../../../data-flow/data/IDataState';
import { TShowProjectAndOrTasks } from '../../../../data-flow/IGeneralStateTypes';

export interface ILeftCellContentProps {
    idExpression: string;
    onUserArrowClick: TOnUserArrowClick;
    projectGroupingMode: boolean;
    showProjectAndOrTasks: TShowProjectAndOrTasks;
    handleProjectArrowClick: THandleProjectArrowClick;
}

export const PeopleWorkloadSectionContent = React.memo<ILeftCellContentProps>((props) => {
    const {
        idExpression,
        onUserArrowClick,
        projectGroupingMode,
        showProjectAndOrTasks,
        handleProjectArrowClick,
    } = props;
    const idsArray = idExpression.split('_');
    const userID = idsArray[0];
    const projectID = idsArray[1];
    let component;
    if (idsArray.length === 1) {
        component = (
            <UserNode
                userID={userID}
                showProjectAndOrTasks={showProjectAndOrTasks}
                onUserArrowClick={onUserArrowClick}
            />
        );
    } else if (projectGroupingMode && idsArray.length !== 3) {
        component =
            idsArray.indexOf('showInaccessible') === -1 ? (
                <ProjectNode
                    projectID={idsArray[1]}
                    userID={userID}
                    idExpression={idExpression}
                    handleProjectArrowClick={handleProjectArrowClick}
                />
            ) : (
                <InaccessibleProjectNode userID={userID} idExpression={idExpression} />
            );
    } else {
        component = (
            <TaskIssueNode idExpression={idExpression} userID={userID} projectID={projectID} />
        );
    }
    return component;
});
