import { cx } from 'emotion';
import { connect } from 'react-redux';
import React from 'react';
import { LeftCell } from '../left/LeftCell';
import { Sections } from '../../../constants/schedulingTableConstants';
import {
    TDraggingFromArea,
    THandleProjectArrowClick,
    TOnUnassignedProjectArrowClick,
    TOnUserArrowClick,
} from '../../../data-flow/data/IDataState';
import { TShowProjectAndOrTasks } from '../../../data-flow/IGeneralStateTypes';
import { RightCell } from '../right/RightCell';
import { IWorkSchedulingCombinedState } from '../../../data-flow/types';
import { draggingFromAreaSelector } from '../../../data-flow/data/selectors/dataSelectors';
import { TableHeaderSteps } from '../header/TableHeaderSteps';
import { TableHeaderUnassignedSectionLeftSide } from '../header/TableHeaderUnassignedSectionLeftSide';
import { TableHeaderUnassignedSectionRightSide } from '../header/TableHeaderUnassignedSectionRightSide';
import { hoverStyles, rowHoverClass, tableRowStyles, useMemoizedStyle } from './tableRowUtils';
import { TableHeaderLeftSideWithFilterIcon } from '../header/TableHeaderLeftSideWithFilter';
import { taskShadowClass } from '../../styles/AssignmentComponentStyles';
import { ICellStyle } from '../schedulingTables/SchedulingTableAssigned';

interface ITableRowUnassignStateToProps {
    draggingFromArea: TDraggingFromArea;
}

interface ITableRowUnassign {
    allocationsEditingMode: boolean;
    showAssignmentActions: boolean;
    idForShadowStyle: string;
    shouldHighlight?: boolean;
    userRow: boolean;
    idExpression: string;
    sectionType: Sections;
    hasDataIDs: boolean;
    isInLoadingState: boolean;
    onUnassignedProjectArrowClick: TOnUnassignedProjectArrowClick;
    showProjectAndOrTasks: TShowProjectAndOrTasks;
    handleProjectArrowClick: THandleProjectArrowClick;
    onUserArrowClick: TOnUserArrowClick;
    isTheLastRow: boolean;
    isLeftSide: boolean;
    isFirstRow: boolean;
    isSecondRow: boolean;
    style: ICellStyle;
    toggleUnAssignedSection: () => void;
    idsArray: string[];
    tableViewportWidth?: number;
    leftSidePanelWidth: number;
}
let correspondingHoveredElements: Element[] = [];

export const TableRowUnassignedComponent = React.memo<
    ITableRowUnassign & ITableRowUnassignStateToProps
>((props) => {
    const {
        draggingFromArea,
        idExpression,
        sectionType,
        hasDataIDs,
        isInLoadingState,
        onUnassignedProjectArrowClick,
        showProjectAndOrTasks,
        handleProjectArrowClick,
        onUserArrowClick,
        isTheLastRow,
        idForShadowStyle,
        allocationsEditingMode,
        userRow,
        showAssignmentActions,
        shouldHighlight,
        isLeftSide,
        style,
        isFirstRow,
        isSecondRow,
        idsArray,
        toggleUnAssignedSection,
        tableViewportWidth,
        leftSidePanelWidth,
    } = props;

    const isInDragState = Boolean(draggingFromArea);
    const hoverStyle = hoverStyles(idForShadowStyle, allocationsEditingMode, isInDragState);
    const shouldBeOver = !!(userRow && draggingFromArea);

    const tableRowStyle = tableRowStyles(shouldBeOver, hoverStyle, showAssignmentActions);

    const memoizedStyle = useMemoizedStyle(style);

    if (isFirstRow || isSecondRow) {
        if (isFirstRow) {
            if (isLeftSide) {
                return (
                    <TableHeaderUnassignedSectionLeftSide
                        toggleUnAssignedSection={toggleUnAssignedSection}
                    />
                );
            }
            return <TableHeaderUnassignedSectionRightSide />;
        }

        if (isLeftSide) {
            return (
                <TableHeaderLeftSideWithFilterIcon
                    style={memoizedStyle}
                    section={Sections.UNASSIGNED_WORK}
                />
            );
        }
        return <TableHeaderSteps style={memoizedStyle} />;
    }

    const mouseEnter = (): void => {
        if (idExpression) {
            if (isLeftSide) {
                correspondingHoveredElements = Array.from(
                    document.getElementsByClassName(`cell-weekday-unassigned_${idExpression}`)
                );
                correspondingHoveredElements.forEach((element) =>
                    element.classList.add(rowHoverClass)
                );

                document.getElementById(idForShadowStyle)?.classList.add(taskShadowClass);
            } else {
                const correspondingElement = document.getElementById(`left_cell_${idExpression}`);
                if (correspondingElement) {
                    correspondingElement.classList.add(rowHoverClass);
                    correspondingHoveredElements = [correspondingElement];
                }
            }
        }
    };

    const mouseLeave = (): void => {
        if (idExpression) {
            correspondingHoveredElements.forEach((element) =>
                element.classList.remove(rowHoverClass)
            );

            if (isLeftSide) {
                document.getElementById(idForShadowStyle)?.classList.remove(taskShadowClass);
            }
        }
    };

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
            <div
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
                className={cx(tableRowStyle, { [hoverStyle]: shouldHighlight })}
                role="row"
                style={style}
            >
                {isLeftSide ? (
                    <LeftCell
                        idExpression={idExpression}
                        sectionType={sectionType}
                        hasDataIDs={hasDataIDs}
                        isInLoadingState={isInLoadingState}
                        onUnassignedProjectArrowClick={onUnassignedProjectArrowClick}
                        showProjectAndOrTasks={showProjectAndOrTasks}
                        handleProjectArrowClick={handleProjectArrowClick}
                        onUserArrowClick={onUserArrowClick}
                        idsArray={idsArray}
                    />
                ) : (
                    <RightCell
                        idExpression={idExpression}
                        sectionType={sectionType}
                        hasDataIDs={hasDataIDs}
                        isTheLastRow={isTheLastRow}
                        tableViewportWidth={tableViewportWidth}
                        leftSidePanelWidth={leftSidePanelWidth}
                    />
                )}
            </div>
        </>
    );
});

const mapStateToProps = (state: IWorkSchedulingCombinedState): ITableRowUnassignStateToProps => {
    return {
        draggingFromArea: draggingFromAreaSelector(state),
    };
};

export const TableRowUnassigned = connect(mapStateToProps)(TableRowUnassignedComponent);
