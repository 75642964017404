import { TModernSchedulingAction } from '../../types';
import { IDataState, TObjectsInAssignmentMode } from '../IDataState';

export const ActionType = 'SET_OBJECT_INTO_ASSIGNMENT_MODE';

interface ISetObjectsIntoAssignmentMode {
    objectIDs: TObjectsInAssignmentMode;
}

type TEditRowAction = TModernSchedulingAction<ISetObjectsIntoAssignmentMode>;

const setObjectsIntoAssignmentMode = (objectIDs): TEditRowAction => ({
    type: ActionType,
    payload: {
        objectIDs,
    },
});
export default setObjectsIntoAssignmentMode;

export function handle(state: IDataState, { payload }: TEditRowAction): IDataState {
    const { objectIDs } = payload;
    return {
        ...state,
        objectsInAssignmentMode: objectIDs,
    };
}
