import { HoursModeActions } from '../../../constants/allocationsMode';
import { TModernSchedulingAction } from '../../types';
import { ISettingsState } from '../ISettingsState';

export const ActionType = 'SET_USER_ALLOCATIONS_MODE';

interface ISetAllocationsModePayload {
    actionType: string;
}

type TSetAllocationsModeAction = TModernSchedulingAction<ISetAllocationsModePayload>;

const userAllocationsMode = (actionType): TSetAllocationsModeAction => ({
    type: ActionType,
    payload: {
        actionType,
    },
});
export default userAllocationsMode;

export function handle(
    state: ISettingsState,
    { payload }: TSetAllocationsModeAction
): ISettingsState {
    const { actionType } = payload;

    const showAllocationsInHoursMode = actionType === HoursModeActions.HOURS;
    return {
        ...state,
        showAllocationsInHoursMode,
    };
}
