import _ from 'lodash';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'ADD_WORK_PER_DAYS_FOR_TASKS_FROM_SERVICE';

interface IAddWorkPerDaysForTasksFromServicePayload {
    workPerDay: any;
}

type TAddUnassignedAssignmentsAction =
    TModernSchedulingAction<IAddWorkPerDaysForTasksFromServicePayload>;

const addWorkPerDaysForTasksFromService = (workPerDay): TAddUnassignedAssignmentsAction => ({
    type: ActionType,
    payload: {
        workPerDay,
    },
});
export default addWorkPerDaysForTasksFromService;

export function handle(
    state: IDataState,
    { payload }: TAddUnassignedAssignmentsAction
): IDataState {
    const unassignedTasks = { ...state.unassignedTasks };
    _.forEach(payload.workPerDay, (workPerDayItem, ID) => {
        unassignedTasks[ID] = {
            ...unassignedTasks[ID],
            workPerDayListFromService: workPerDayItem,
        };
    });

    return {
        ...state,
        unassignedTasks,
    };
}
