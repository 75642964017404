import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'UPDATE_TASK_AND_ISSUE';

interface IUpdateTaskWorkDetailsActionPayload {
    assignmentID: string;
    updateData: Record<string, unknown>;
}

type TUpdateTaskWorkDetailsAction = TModernSchedulingAction<IUpdateTaskWorkDetailsActionPayload>;

const updateTaskAndIssue = (assignmentID, updateData): TUpdateTaskWorkDetailsAction => ({
    type: ActionType,
    payload: {
        assignmentID,
        updateData,
    },
});
export default updateTaskAndIssue;

export function handle(state: IDataState, { payload }: TUpdateTaskWorkDetailsAction): IDataState {
    const tasksAndIssues = { ...state.tasksAndIssues };

    if (tasksAndIssues) {
        const taskOrIssue = tasksAndIssues[payload.assignmentID];
        if (taskOrIssue) {
            tasksAndIssues[payload.assignmentID] = {
                ...tasksAndIssues[payload.assignmentID],
                ...payload.updateData,
            };
        }
    }

    return {
        ...state,
        tasksAndIssues,
    };
}
