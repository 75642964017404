/**
 * Created by anihambardzumyan on 4/25/17.
 */
import moment, { Moment } from 'moment';
import 'whatwg-fetch';
import { ResponseHandler } from 'workfront-api';
import { TFields } from 'workfront-api/typings/Api';
import { getSessionID, getXSRFToken } from 'workfront-cookie';
import {
    Assignment as assignmentObjCode,
    CustomEnum as customEnumObjCode,
    Project as projectObjCode,
    ReservedTime as reservedTimeObjCode,
    Schedule as scheduleObjCode,
    Task as taskObjCode,
    User as userObjCode,
    UserPrefValue as userPrefObjCode,
    Customer as customerObjCode,
    OpTask,
    User,
} from 'workfront-objcodes';
import { Field } from '@workfront/panel-components';
import {
    ASSIGNMENT_FIELDS,
    BULK_ASSIGNMENTS_FIELDS,
    BULK_ASSIGNMENT_OTHER_RECOMMENDED_FIELDS,
    DATE_FORMAT,
    ISSUES_FIELDS,
    LOAD_ASSIGNMENTS_LIMIT,
    PROJECT_FIELDS,
    TASKS_FIELDS,
    UNASSIGNED_ASSIGNMENT_FIELDS,
    UNASSIGNED_ISSUES_FIELDS,
    USERS_RESERVED_TIMES_FIELDS,
} from '../../constants/dataConstatnts';
import {
    IObjCode,
    IWorkPerDayList,
    ILoadAssignmentsByProjectResponse,
    LoadWorkPerDayListByAssignmentFromWPDServiceForProjectResponse,
    TUserID,
    TUserRoleId,
    TTaskOrIssueID,
    TProjectID,
    TOrUndefined,
} from '../../data-flow/data/IDataState';
import { IOtherRecommendedFilters, IOtherRecommendedUsersByRole } from './bulkAssignmentsService';
import { ISettingsState } from '../../data-flow/settings/ISettingsState';
import { IUserPrefSettings } from '../../data-flow/settings/IUserPrefSettings';
import {
    getAssignmentsFilter,
    getProjectsSortingKey,
    getUserReservedTimesFilter,
} from '../../util/filters/filterUtil';
import {
    combineAccessibleAssignments,
    combineInaccessibleAssignments,
    getEndOfDay,
    getStartOfDay,
} from '../../util/utilities';
import { concatWPDResponseData, getCountOfChunk } from '../../util/workPerDayUtil';
import { LoggerService } from '../logger/LoggerService';
import { getApi, makeControllerCall } from './api';
import { IAssignmentFormData } from '../../util/dataStructures/IAssignmentIds';
import { IRelevantAssignment, TCommonFilter } from '../../components/BulkAssignments/types';

export const APIService = {
    api: getApi(),

    // TODO we will need this when will implement Display Assignment Information
    /* getRoleAssignmentsCount: (IDs, objCode) => {
		const filters = {
			assignedToID_Mod: 'isnull',
			roleID_Mod: 'notnull',
			[`${objCode}ID`]: IDs,
			[`${objCode}ID_Mod`]: 'in'
		};

		return APIService.api
			.request(
				'ASSGN/report',
				{
					taskID_GroupBy: true,
					method: 'get',
					filters: JSON.stringify(filters)
				},
				undefined,
				'POST'
			)
			.catch(error => {
				throw error.message;
			});
	}, */
    /* loadUserTasksIssues: function(
		userIDs: string[],
		objCode: string,
		startDate: Moment,
		endDate: Moment,
		startIndex: number
	) {
		return APIService.api
			.batch(function(batchApi) {
				const batchArray = [];

				userIDs.forEach(userID => {
					let filter, fields;
					if (objCode === 'PROJ') {
						// TODO after backend endpoint delete this code
						filter = getProjectsFilter(userID, startDate, endDate, startIndex);
						fields = PROJECT_FIELDS;
					} else {
						filter = getTasksFilter(userID, startDate, endDate, startIndex);
						fields = TASKS_FIELDS;
					}
					// @ts-ignore
					batchArray.push(batchApi.search(objCode, filter, fields));
				});

				return batchArray;
			})
			.catch(function(error) {
				throw error.message;
			});
	}, */

    loadAssignmentsByProject(filters, fields): Promise<ILoadAssignmentsByProjectResponse[][]> {
        return APIService.api
            .batch(
                function (batchApi) {
                    return filters.map((filter) =>
                        batchApi.search(assignmentObjCode, filter, fields)
                    );
                },
                false,
                true
            )
            .catch(function (error) {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    loadUnassignedAssignments(filter) {
        return this.loadTasks(taskObjCode, filter, UNASSIGNED_ASSIGNMENT_FIELDS);
    },

    loadUnassignedIssuesAssignments(filter) {
        return this.loadTasks(OpTask, filter, UNASSIGNED_ISSUES_FIELDS);
    },

    loadUnassignedAssignmentsByProjects(
        filter,
        filterOptask,
        projectIDs: string[],
        issuesProjectIds: string[],
        isShowIssueEnabled: boolean
    ) {
        return APIService.api
            .batch(
                function (batchApi) {
                    const batchArray: string[] = [];

                    projectIDs.forEach((projectID) => {
                        filter.projectID = projectID;
                        filter.projectID_Mod = 'eq';

                        batchArray.push(
                            batchApi.search(taskObjCode, filter, UNASSIGNED_ASSIGNMENT_FIELDS)
                        );
                    });

                    if (isShowIssueEnabled) {
                        issuesProjectIds.forEach((projectID) => {
                            filterOptask.projectID = projectID;
                            filterOptask.projectID_Mod = 'eq';

                            batchArray.push(
                                batchApi.search(OpTask, filterOptask, UNASSIGNED_ISSUES_FIELDS)
                            );
                        });
                    }
                    return batchArray;
                },
                false,
                true
            )
            .catch(function (error) {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    loadTasks: (objCode, filter, fields) => {
        return APIService.apiSearch(objCode, filter, fields).catch(function (error) {
            LoggerService.log.error(error);
            throw error.message;
        });
    },

    loadUnassignedTasksProjects(filter) {
        return APIService.apiSearch(projectObjCode, filter, PROJECT_FIELDS).catch(function (error) {
            LoggerService.log.error(error);
            throw error;
        });
    },

    loadUnassignedIssuesProjects(filter) {
        return APIService.loadUnassignedTasksProjects(filter).catch(function (error) {
            LoggerService.log.error(error);
            throw error;
        });
    },

    loadESPProductEnabled() {
        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': getXSRFToken() || getSessionID() || '',
        };

        return fetch(`/attask/api-internal/${customerObjCode}/productEnabled`, {
            method: 'put',
            body: JSON.stringify({ product: 'scenario_planner' }),
            headers,
        })
            .then((response) => response.json(), ResponseHandler.failure)
            .catch((error) => {
                LoggerService.log.error(error);
                throw error;
            });
    },

    loadUsersAssignments(
        usersIDs: string[],
        filterExpression,
        objectDetails: null | IObjCode, // can be task or issue details
        startDate: Moment | null,
        endDate: Moment | null,
        settingsState: ISettingsState
    ) {
        const promises: Array<Promise<any>> = [];
        const { showIssues, showActualProgress, showCompletedWork } = settingsState;

        if (!objectDetails || objectDetails.objCode === taskObjCode) {
            const taskAssignmentFilter = getAssignmentsFilter(
                filterExpression,
                startDate,
                endDate,
                'task',
                showActualProgress,
                showCompletedWork
            );

            const objectDetailsPromise = objectDetails
                ? Promise.resolve(null)
                : APIService.loadUsersInaccessibleAssignments(
                      usersIDs,
                      taskAssignmentFilter,
                      startDate,
                      endDate
                  );

            promises.push(
                APIService.loadUsersObjectAssignments(
                    usersIDs,
                    taskAssignmentFilter,
                    'task',
                    objectDetails
                ),
                objectDetailsPromise
            );
        }

        if (showIssues && (!objectDetails || objectDetails.objCode !== taskObjCode)) {
            const issueAssignmentFilter = getAssignmentsFilter(
                filterExpression,
                startDate,
                endDate,
                'opTask',
                showActualProgress,
                showCompletedWork
            );

            const objectDetailsPromise = objectDetails
                ? Promise.resolve(null)
                : APIService.loadUsersInaccessibleAssignments(
                      usersIDs,
                      issueAssignmentFilter,
                      startDate,
                      endDate
                  );

            promises.push(
                APIService.loadUsersObjectAssignments(
                    usersIDs,
                    issueAssignmentFilter,
                    'opTask',
                    objectDetails
                ),
                objectDetailsPromise
            );
        }

        return Promise.all(promises)
            .then(
                ([
                    tasksAssignments,
                    inaccessibleTasksAssignments,
                    issuesAssignments,
                    inaccessibleIssuesAssignments,
                ]) => {
                    const inaccessibleAssignments = combineInaccessibleAssignments(
                        inaccessibleTasksAssignments,
                        inaccessibleIssuesAssignments
                    );
                    const objectsAssignments = combineAccessibleAssignments(
                        tasksAssignments,
                        issuesAssignments
                    );

                    return Promise.resolve([objectsAssignments, inaccessibleAssignments]);
                }
            )
            .catch(function (error) {
                LoggerService.log.error(error);
                throw error;
            });
    },

    loadUsersObjectAssignments(
        usersIDs: string[],
        filter,
        type: 'task' | 'opTask',
        objectDetails: null | IObjCode
    ) {
        return APIService.api
            .batch(
                function (batchApi) {
                    const batchArray: string[] = [];

                    const fields =
                        type === 'task'
                            ? [...ASSIGNMENT_FIELDS, ...TASKS_FIELDS]
                            : [...ASSIGNMENT_FIELDS, ...ISSUES_FIELDS];

                    usersIDs.forEach((userID) => {
                        const filterByUser = {
                            ...filter,
                            assignedToID_Mod: 'eq',
                            assignedToID: userID,
                        };

                        if (objectDetails) {
                            filterByUser[`${type}:ID`] = objectDetails.ID;
                            filterByUser[`${type}:ID_Mod`] = 'in';
                        }

                        batchArray.push(batchApi.search(assignmentObjCode, filterByUser, fields));
                    });

                    return batchArray;
                },
                false,
                true
            )
            .catch(function (error) {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    loadUsersInaccessibleAssignments(usersIDs: string[], filter, startDate, endDate) {
        return APIService.api
            .batch(
                function (batchApi) {
                    const batchArray: string[] = [];

                    usersIDs.forEach((userID) => {
                        const issuesFilter = {};

                        const filterByUser = {
                            ...issuesFilter,
                            ...filter,
                            assignedToID_Mod: 'eq',
                            assignedToID: userID,
                        };

                        batchArray.push(
                            batchApi.execute(
                                assignmentObjCode,
                                null,
                                'loadInAccessibleAssignments',
                                {
                                    updates: {
                                        filters: filterByUser,
                                        fromDate: startDate,
                                        toDate: endDate,
                                    },
                                }
                            )
                        );
                    });
                    return batchArray;
                },
                false,
                true
            )
            .catch(function (error) {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    editWorkPerDaysOfAssignment: (objID, workPerDayHours, editAccessNotRequired) => {
        return APIService.api
            .execute(assignmentObjCode, null, 'updateObjectWorkPerDays', {
                updates: {
                    objCode: assignmentObjCode,
                    objID,
                    hours: workPerDayHours,
                    editAccessNotRequired,
                },
            })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    loadWorkPerDayListByAssignmentFromWPDService: (assignmentIDs: string[], args, objCode) => {
        return APIService.loadWorkPerDayListByAssignmentFromWPDServiceRequest(
            assignmentIDs,
            args,
            objCode
        )
            .then((data: IWorkPerDayList[]) => concatWPDResponseData(data))
            .catch((error) => {
                LoggerService.log.error(error);
                throw error;
            });
    },

    loadWorkPerDayListByAssignmentFromWPDServiceForProject: (
        assignmentIDs: string[],
        args,
        objCode
    ): Promise<LoadWorkPerDayListByAssignmentFromWPDServiceForProjectResponse> => {
        return APIService.loadWorkPerDayListByAssignmentFromWPDServiceRequest(
            assignmentIDs,
            args,
            objCode
        )
            .then((data: IWorkPerDayList[]) => {
                return concatWPDResponseData(data);
            })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error;
            });
    },

    loadWorkPerDayListByAssignmentFromWPDServiceRequest: (
        assignmentIDs: string[],
        args,
        objCode
    ) => {
        const countOfChunk = getCountOfChunk(assignmentIDs);
        return APIService.api.batch(
            (batchApi) => {
                const batchArray: any = [];

                for (let i = 0; i < countOfChunk; i++) {
                    batchArray.push(
                        batchApi.execute(assignmentObjCode, null, 'getObjectsWorkPerDays', {
                            updates: {
                                objCode,
                                objIDs: assignmentIDs.slice(
                                    i * LOAD_ASSIGNMENTS_LIMIT,
                                    i * LOAD_ASSIGNMENTS_LIMIT + LOAD_ASSIGNMENTS_LIMIT
                                ),
                                fromDate: args.startDate.format(DATE_FORMAT),
                                toDate: args.endDate.format(DATE_FORMAT),
                            },
                        })
                    );
                }

                return batchArray;
            },
            false,
            true
        );
    },

    loadProjectsDetails: (
        usersProjectIDs: string[][],
        projectsSortingCriteria: TOrUndefined<Field>
    ) => {
        const sortingKey = getProjectsSortingKey(projectsSortingCriteria);

        return APIService.api
            .batch(
                (batchApi) => {
                    const batchArray: any = [];
                    usersProjectIDs.forEach((projectIDs) => {
                        batchArray.push(
                            batchApi.execute('PROJ', null, 'loadProjectsByProjectIDsSorted', {
                                updates: {
                                    projectIDs,
                                    sortingCriteria: sortingKey,
                                    dataType: projectsSortingCriteria?.dataType,
                                },
                            })
                        );
                    });

                    return batchArray;
                },
                false,
                true
            )
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    /**
     *
     * @param loadedGroupIDs
     */
    getColorsByGroupIds: (loadedGroupIDs: string[]) => {
        if (!loadedGroupIDs.length) {
            return Promise.resolve([]);
        }

        return APIService.api
            .batch(
                (batchApi) => {
                    const batchArray: any = [];
                    loadedGroupIDs.forEach((loadedGroupID) => {
                        if (!loadedGroupID) {
                            return Promise.resolve([]);
                        }
                        batchArray.push(
                            batchApi.request(
                                `${customEnumObjCode}/projectGroupStatuses`,
                                {
                                    groupID: loadedGroupID,
                                    includeHidden: true,
                                    method: 'GET',
                                },
                                undefined,
                                'get'
                            )
                        );
                    });
                    return batchArray;
                },
                false,
                true
            )
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    loadTimeOffsWorkingHours(usersIDs: string[], startDate, endDate, scheduleIDs: Set<string>) {
        const userReservedTimesFilter = getUserReservedTimesFilter(usersIDs, startDate, endDate);
        const scheduleIdsArray: string[] = [...scheduleIDs];

        return APIService.api
            .batch(
                (batchApi) => {
                    const batchArray: any = [];

                    if (usersIDs.length) {
                        batchArray.push(
                            batchApi.search(
                                reservedTimeObjCode,
                                userReservedTimesFilter,
                                USERS_RESERVED_TIMES_FIELDS
                            )
                        );

                        batchArray.push(
                            batchApi.execute(userObjCode, null, 'getUsersAvailableTime', {
                                updates: {
                                    userIDs: usersIDs,
                                    fromDate: getStartOfDay(startDate),
                                    toDate: getEndOfDay(endDate),
                                },
                            })
                        );
                    }

                    if (scheduleIdsArray.length) {
                        batchArray.push(
                            batchApi.execute(scheduleObjCode, null, 'getScheduleTimeZonedData', {
                                updates: {
                                    scheduleIDs: scheduleIdsArray,
                                    fromDate: moment(startDate).startOf('day').format(),
                                    toDate: moment(endDate).endOf('day').format(),
                                },
                            })
                        );
                    }

                    return batchArray;
                },
                false,
                true
            )
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    getSettings: (schedulingArea) => {
        return APIService.apiSearch(userPrefObjCode, { name: schedulingArea }, [])
            .then((result) => {
                return result[0] ? JSON.parse(result[0].value) : {};
            })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    editSettings(settings: IUserPrefSettings, schedulingArea: string) {
        APIService.api
            .create(userPrefObjCode, { name: schedulingArea, value: JSON.stringify(settings) })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    apiSearch: (objCode: string, filters, fields: string[]) => {
        // TODO add filters
        return APIService.api
            .search(
                objCode,
                {
                    filters: JSON.stringify(filters),
                    method: 'get',
                },
                fields,
                true
            )
            .catch((error) => {
                LoggerService.log.error(error);
                throw error;
            });
    },

    apiReport: (objCode: string, filters): Promise<any> => {
        return APIService.api
            .report(
                objCode,
                {
                    filters: JSON.stringify(filters),
                    method: 'get',
                },
                true
            )
            .catch((error) => {
                LoggerService.log.error(error);
                throw error;
            });
    },

    apiEdit: (objCode: string, ID, updates: any, fields?: TFields) => {
        return APIService.api.edit(objCode, ID, updates, fields).catch((error) => {
            LoggerService.log.error(error);
            throw error.message;
        });
    },

    getAllSchedules: (scheduleIdsArray, startDate, endDate) => {
        return APIService.api
            .execute(scheduleObjCode, null, 'getScheduleTimeZonedData', {
                updates: {
                    scheduleIDs: scheduleIdsArray,
                    fromDate: moment(startDate).startOf('day').format(),
                    toDate: moment(endDate).endOf('day').format(),
                },
            })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    apiRequest: (path: string, params: any, fields?: TFields, method?: string) => {
        return APIService.api.request(path, params, fields, method).catch((error) => {
            LoggerService.log.error(error);
            throw error.message;
        });
    },

    getProjectPreferences: () => {
        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': getXSRFToken() || getSessionID() || '',
        };

        return fetch('/preferences/api/v1/project/groups/system', {
            method: 'GET',
            credentials: 'same-origin',
            headers,
        })
            .then((response) => response.json(), ResponseHandler.failure)
            .catch((error) => {
                LoggerService.log.error(error);
                throw error;
            });
    },

    getCustomerPreferences: (groupId: string) => {
        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': getXSRFToken() || getSessionID() || '',
        };

        return fetch(
            `/attask/api-internal/CUSTPR/search?name=customer:calculateFTEAutomatic&name=project.mgmt:taskissue.delegate&$$groupID=${groupId}`,
            {
                method: 'GET',
                credentials: 'same-origin',
                headers,
            }
        )
            .then((response) => response.json(), ResponseHandler.failure)
            .catch((error) => {
                LoggerService.log.error(error);
                throw error;
            });
    },

    loadRoleHoursForMonth: (projectID: string, startDate: string, endDate: string) => {
        return fetch(
            `/esp/publishedInitiatives/rolesWithHoursDistribution?projectRkId=${projectID}&startDate=${startDate}&endDate=${endDate}`,
            {
                method: 'GET',
                credentials: 'same-origin',
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return [];
            })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    assignObject: (formData: IAssignmentFormData, objID: string, objCode: string) => {
        return makeControllerCall(`/worksheet/edit/${objCode}`, 'POST', {
            objID,
            form: JSON.stringify(formData),
        }).catch((error) => {
            LoggerService.log.error(error);
            throw error.message;
        });
    },

    assignUserToRoleOnTasks: (
        taskIDs: TTaskOrIssueID[],
        swapUserID: TUserID,
        swapRoleID: TUserRoleId,
        lockToRole: boolean
    ): Promise<void> => {
        return APIService.api
            .execute(assignmentObjCode, null, 'assignUserToRoleOnTasks', {
                updates: {
                    taskIDs,
                    swapUserID,
                    swapRoleID,
                    includeIssues: true,
                    lockToRole,
                },
                method: 'put',
            })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    assignUserToRoleOnProjects: (
        projectIDs: TProjectID[],
        swapUserID: TUserID,
        swapRoleID: TUserRoleId,
        lockToRole: boolean
    ): Promise<void> => {
        return APIService.api
            .execute(assignmentObjCode, null, 'assignUserToRoleOnProjects', {
                updates: {
                    projectIDs,
                    swapUserID,
                    swapRoleID,
                    includeIssues: true,
                    lockToRole,
                },
                method: 'put',
            })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    swapUsersOnTasks: (
        taskIDs: TTaskOrIssueID[],
        swapToUserID: TUserID,
        swapFromUserID: TUserID,
        swapRoleIDs: string[]
    ): Promise<void> => {
        return APIService.api
            .execute(assignmentObjCode, null, 'swapUsersOnTasks', {
                updates: {
                    taskIDs,
                    swapToUserID,
                    swapFromUserID,
                    swapRoleIDs,
                    includeIssues: true,
                    lockToRole: false,
                },
                method: 'put',
            })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    swapUsersOnProjects: (
        projectIDs: TProjectID[],
        swapToUserID: TUserID,
        swapFromUserID: TUserID,
        swapRoleIDs: string[]
    ): Promise<void> => {
        return APIService.api
            .execute(assignmentObjCode, null, 'swapUsersOnProjects', {
                updates: {
                    projectIDs,
                    swapToUserID,
                    swapFromUserID,
                    swapRoleIDs,
                    includeIssues: true,
                    lockToRole: false,
                },
                method: 'put',
            })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    unassignUserFromTasks: (
        taskIDs: TTaskOrIssueID[],
        unassignUserID: TUserID,
        swapRoleIDs: string[]
    ): Promise<void> => {
        return APIService.api
            .execute(assignmentObjCode, null, 'unassignUserFromTasks', {
                updates: {
                    taskIDs,
                    unassignUserID,
                    swapRoleIDs,
                    includeIssues: true,
                },
                method: 'put',
            })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    unassignUserFromProjects: (
        projectIDs: TProjectID[],
        unassignUserID: TUserID,
        swapRoleIDs: string[]
    ): Promise<void> => {
        return APIService.api
            .execute(assignmentObjCode, null, 'unassignUserFromProjects', {
                updates: {
                    projectIDs,
                    unassignUserID,
                    swapRoleIDs,
                    includeIssues: true,
                },
                method: 'put',
            })
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    deleteUserAssignments: (assignmentsIDs: TProjectID[]): Promise<void> => {
        return APIService.api
            .request(`/${assignmentObjCode}`, { ID: assignmentsIDs }, '*', 'DELETE')
            .catch((error) => {
                LoggerService.log.error(error);
                throw error.message;
            });
    },

    loadOtherRecommendedUsersByRole: (
        filter: IOtherRecommendedFilters
    ): Promise<IOtherRecommendedUsersByRole[]> => {
        return APIService.api.search(
            User,
            {
                filters: JSON.stringify({
                    ...filter,
                    $$LIMIT: 50,
                    $$FIRST: 0,
                    isActive: 1,
                }),
                method: 'get',
            },
            BULK_ASSIGNMENT_OTHER_RECOMMENDED_FIELDS,
            true
        );
    },

    loadRelevantAssignments: (
        taskFilter: TCommonFilter,
        issueFilter: TCommonFilter
    ): Promise<IRelevantAssignment[][]> => {
        const limitationFilter = {
            $$LIMIT: '2000',
        };
        const promises = [
            APIService.api.search(
                assignmentObjCode,
                { ...taskFilter, ...limitationFilter },
                BULK_ASSIGNMENTS_FIELDS
            ),
            APIService.api.search(
                assignmentObjCode,
                { ...issueFilter, ...limitationFilter },
                BULK_ASSIGNMENTS_FIELDS
            ),
        ];

        return Promise.all(promises).catch(function (error) {
            LoggerService.log.error(error);
            throw error.message;
        });
    },
};
