import { Field } from '@workfront/panel-components';
import { TModernSchedulingAction } from '../../types';
import { ISettingsState } from '../ISettingsState';

export const ActionType = 'SET_PROJECTS_SORTING_CRITERIA';

interface ISetProjectsSortingCriteria {
    actionType: Field;
}

type TSetProjectsSortingCriteriaAction = TModernSchedulingAction<ISetProjectsSortingCriteria>;

const setProjectsSortingCriteria = (actionType): TSetProjectsSortingCriteriaAction => ({
    type: ActionType,
    payload: {
        actionType,
    },
});
export default setProjectsSortingCriteria;

export function handle(
    state: ISettingsState,
    { payload }: TSetProjectsSortingCriteriaAction
): ISettingsState {
    return {
        ...state,
        projectsSortingCriteria: payload.actionType,
    };
}
