/**
 * This was moved from PeriodModeButtons.tsx component,
 * as cypress's webpack was transpiling whole PeriodModeButtons.tsx
 * file when testIdsPeriodModes constant was being used in cypress tests,
 * which was causing build errors as we encountered with filters-ui,
 * and panel-components packages.
 * */
export const testIdsPeriodModes = {
    dayView: 'switch-day-view',
    weekView: 'switch-week-view',
    monthView: 'switch-month-view',
};
