import { TModernSchedulingAction } from '../../../types';
import { IDataState, RoleSummaryKeys } from '../../IDataState';

export const ActionType = 'SET_ROLE_SUMMARY_ACTIVE_KEY';

interface IRoleSummaryActiveKeyActionPayload {
    status: boolean;
    activeKey: RoleSummaryKeys | string;
    cleanOthers?: boolean;
}

type TRoleSummaryActiveKeyAction = TModernSchedulingAction<IRoleSummaryActiveKeyActionPayload>;

const setRoleSummaryActiveKey = (status, activeKey, cleanOthers?): TRoleSummaryActiveKeyAction => ({
    type: ActionType,
    payload: {
        status,
        activeKey,
        cleanOthers,
    },
});
export default setRoleSummaryActiveKey;

export function handle(state: IDataState, { payload }: TRoleSummaryActiveKeyAction): IDataState {
    let roleSummaryActiveKeys: Array<RoleSummaryKeys | string>;

    if (payload.cleanOthers) {
        roleSummaryActiveKeys = [payload.activeKey];
    } else {
        roleSummaryActiveKeys = [...state.roleSummaryActiveKeys];
        const shouldAdd = payload.status;
        if (shouldAdd) {
            const alreadyExists = roleSummaryActiveKeys.find(
                (activeKey) => activeKey === payload.activeKey
            );
            if (!alreadyExists) {
                roleSummaryActiveKeys.push(payload.activeKey);
            }
        } else {
            roleSummaryActiveKeys = roleSummaryActiveKeys.filter(
                (activeKey) => activeKey !== payload.activeKey
            );
        }
    }

    return {
        ...state,
        roleSummaryActiveKeys,
    };
}
