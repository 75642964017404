import * as React from 'react';
import { connect } from 'react-redux';
import { LeftCellProjectLoading } from '../LeftCellProjectLoading';
import { LeftCellTaskLoading } from '../LeftCellTaskLoading';
import { UnassignedSectionContent } from './UnassignedSectionContent';
import { projectGroupingModeSelector } from '../../../../data-flow/settings/settingsSelector';
import {
    IUnassignedTaskProjectValue,
    TOnUnassignedProjectArrowClick,
} from '../../../../data-flow/data/IDataState';

interface IUnassignedWorkSectionLeftCellComponentProps
    extends React.HTMLAttributes<HTMLDivElement> {
    idExpression: string;
    onUnassignedProjectArrowClick: TOnUnassignedProjectArrowClick;
    hasDataIDs: boolean;
    idsArray: string[];
}

interface IUnassignedWorkSectionLeftCellComponentStateProps {
    isInLoadingStateUnassignedWorkSection: boolean;
    projectGrouping: boolean;
    projectData: IUnassignedTaskProjectValue | undefined;
}

export const UnassignedWorkSectionLeftCellComponent: React.FunctionComponent<
    IUnassignedWorkSectionLeftCellComponentProps & IUnassignedWorkSectionLeftCellComponentStateProps
> = React.memo((props) => {
    const {
        isInLoadingStateUnassignedWorkSection,
        projectGrouping,
        idExpression,
        onUnassignedProjectArrowClick,
        hasDataIDs,
        idsArray,
        projectData,
    } = props;
    let component;
    const projectGroupingMode = props.projectGrouping;
    const loadingRow = idExpression && idExpression.split('_').indexOf('loading') !== -1;

    if (isInLoadingStateUnassignedWorkSection || loadingRow) {
        if (projectGrouping) {
            component = <LeftCellProjectLoading />;
        } else {
            component = <LeftCellTaskLoading />;
        }
    } else {
        component = (
            <UnassignedSectionContent
                idExpression={idExpression}
                projectGroupingMode={projectGroupingMode}
                hasDataIDs={hasDataIDs}
                onUnassignedProjectArrowClick={onUnassignedProjectArrowClick}
                idsArray={idsArray}
                projectData={projectData}
            />
        );
    }
    return component;
});

function mapStateToProps(state, ownProps): IUnassignedWorkSectionLeftCellComponentStateProps {
    return {
        isInLoadingStateUnassignedWorkSection: state.Data.isInLoadingStateUnassignedWorkSection,
        projectGrouping: projectGroupingModeSelector(state),
        projectData: state.Data.unassignedTasksProjects[ownProps.idsArray[0]],
    };
}

export const UnassignedWorkSectionLeftCell = connect(mapStateToProps)(
    UnassignedWorkSectionLeftCellComponent
);
