import { sizes } from '../../../constants/schedulingTableConstants';
import { TModernSchedulingAction } from '../../types';
import { IDataState, TTableDataIDs } from '../IDataState';
import { unassignedSectionSliceIndex } from '../../../constants/dataConstatnts';

type TUnassignedWorkHeight = number;
type TIsProjectGroupingTurnedOn = boolean | undefined;

interface ISetHeightForUnassignedSectionEmptyRow {
    unassignedWorkHeight: TUnassignedWorkHeight;
    isProjectGroupingTurnedOn: TIsProjectGroupingTurnedOn;
}

export type TSetHeightForUnassignedSectionEmptyRow =
    TModernSchedulingAction<ISetHeightForUnassignedSectionEmptyRow>;

export const ActionType = 'SET_HEIGHT_FOR_UNASSIGNED_SECTION_EMPTY_ROW';

const setHeightForUnassignedSectionEmptyRow = (
    unassignedWorkHeight,
    isProjectGroupingTurnedOn = false
): TSetHeightForUnassignedSectionEmptyRow => ({
    type: ActionType,
    payload: {
        unassignedWorkHeight,
        isProjectGroupingTurnedOn,
    },
});
export default setHeightForUnassignedSectionEmptyRow;

export function handle(
    state: IDataState,
    action: TSetHeightForUnassignedSectionEmptyRow
): IDataState {
    const { unassignedWorkEmptyRowHeight } = state;
    const { unassignedWorkHeight, isProjectGroupingTurnedOn } = action.payload;
    const calculatedNewUnassignedWorkEmptyRowHeight = calcUnassignedWorkEmptyRowHeight(
        state.tableDataIDsForUnassignedSection,
        unassignedWorkHeight,
        isProjectGroupingTurnedOn
    );

    const mustBeChanged =
        unassignedWorkEmptyRowHeight !== calculatedNewUnassignedWorkEmptyRowHeight;

    return {
        ...state,
        unassignedWorkEmptyRowHeight: mustBeChanged
            ? calculatedNewUnassignedWorkEmptyRowHeight
            : unassignedWorkEmptyRowHeight,
    };
}

export const calcUnassignedWorkEmptyRowHeight = (
    tableDataIDsForUnassignedSection: TTableDataIDs,
    unassignedWorkHeight: TUnassignedWorkHeight,
    isProjectGroupingTurnedOn: TIsProjectGroupingTurnedOn
): number => {
    const { tableRowTaskHeight, tableRowProjectHeight, tableHeaderRowHeight } = sizes;
    const tableDataIDsForUnassignedSectionWithoutHeader = tableDataIDsForUnassignedSection.slice(
        unassignedSectionSliceIndex
    );

    const subtractiveHeightForEveryDataID: number = !isProjectGroupingTurnedOn
        ? tableDataIDsForUnassignedSectionWithoutHeader.length * tableRowTaskHeight
        : tableDataIDsForUnassignedSectionWithoutHeader.reduce((acc: number, dataID: string) => {
              const dataIDPartsQty: number = dataID.split('_').length;
              return dataIDPartsQty === 2 ? acc + tableRowTaskHeight : acc + tableRowProjectHeight;
          }, 0);

    return Math.max(
        0,
        unassignedWorkHeight - 2 * tableHeaderRowHeight - subtractiveHeightForEveryDataID
    );
};
