import { Action } from 'redux';
import { createReducer } from '../createReducer';
import * as addFilterID from './filtersActions/addFilterID';
import * as addFilters from './filtersActions/addFilters';
import * as addProjectStatusesForFilters from './filtersActions/addProjectStatusesForFilters';
import * as changeFilterOpenState from './filtersActions/changeFilterOpenState';
import * as deleteFilter from './filtersActions/deleteFilter';
import * as renameFilter from './filtersActions/renameFilter';
import { IFiltersState } from './IFiltersState';

export function getFiltersReducer(
    initialFilterState
): (state: IFiltersState | undefined, action: Action) => IFiltersState {
    return createReducer(
        [
            addFilterID,
            addFilters,
            changeFilterOpenState,
            addProjectStatusesForFilters,
            deleteFilter,
            renameFilter,
        ],
        initialFilterState as IFiltersState
    );
}
