import { Sections } from '../../constants/schedulingTableConstants';
import { WorkPerDayService } from '../../services/api-services/workPerDayService/workPerDayService';
import { getUnassignedTasksInfo } from '../../util/getUnassignedTasksAssignmentsUtil';
import addWorkPerDaysByAssignmentsFromService from '../data/sharedActions/addWorkPerDaysByAssignmentsFromService';
import actionChain from '../higher-order-reducers/actionChain';
import { toastByErrorCode } from './loadDataThunk';
import { serviceNamesPerKey } from '../../messageKeys';
import addWorkPerDaysForTasksFromService from '../data/unassignedDataActions/addWorkPerDaysForTasksFromService';
import addContouredAssignments from '../data/sharedActions/addContouredAssignments';
import addUnassignedAssignments from '../data/unassignedDataActions/addUnassignedAssignments';
import { getWPDHoursForTasksAndAssignments, isNextCallNeeded } from '../../util/workPerDayUtil';
import { separateThreadPoolDelay } from '../../util/changeAssignmentsUtil';
import { IRequestedWorkPerDayFromService } from '../data/IDataState';
import { actionsWhenError } from './unassignedSectionLoadDataThunk';

let wpdCallCountForAssignedSection = 1;
let wpdCallCountForUnassignedSection = 1;

export function loadWorkPerDayListForUsers(
    assignmentsIDs,
    usersIDs,
    dates,
    chainActions,
    objectID
) {
    return function _loadWorkPerDayListForUsers(dispatch) {
        const promises: Array<Promise<any>> = [
            WorkPerDayService.getWorkPerDayServiceApiCall(assignmentsIDs, dates)
                .then((workPerDayData) => {
                    if (
                        isNextCallNeeded(workPerDayData, wpdCallCountForAssignedSection) &&
                        objectID
                    ) {
                        wpdCallCountForAssignedSection += 1;
                        return separateThreadPoolDelay(2000).then(() => {
                            return dispatch(
                                loadWorkPerDayListForUsers(
                                    assignmentsIDs,
                                    usersIDs,
                                    dates,
                                    chainActions,
                                    objectID
                                )
                            );
                        });
                    }
                    wpdCallCountForAssignedSection = 1;
                    return dispatch(
                        finalActionsForAssignedSection(dates, workPerDayData, chainActions)
                    );
                })
                .catch((error) => {
                    toastByErrorCode(error.status, serviceNamesPerKey.workperday);
                }),
            WorkPerDayService.getContouredDataServiceApiCall(assignmentsIDs)
                .then((contouredAssignments) =>
                    dispatch(addContouredAssignments(contouredAssignments))
                )
                .catch((error) => {
                    toastByErrorCode(error.status, serviceNamesPerKey.workperday);
                }),
        ];

        return Promise.all(promises).then((data) => data?.[0]);
    };
}

export function loadAssignmentsByTaskIDsFormService(args, finalAction) {
    return function _loadAssignmentsByTaskIDsFormService(dispatch, getState) {
        const { startDate, endDate } = args;
        const [unassignedTasksAssignmentsIDs, unassignedTasksIDsWithNoAssignments] =
            getUnassignedTasksInfo(args.unassignedTasks);

        const WPDPromises: Array<Promise<any>> = [];
        if (unassignedTasksIDsWithNoAssignments.length) {
            WPDPromises.push(
                WorkPerDayService.getWorkPerDayServiceApiCall(unassignedTasksIDsWithNoAssignments, {
                    endDate,
                    startDate,
                })
            );
        }

        if (unassignedTasksAssignmentsIDs.length) {
            WPDPromises.push(
                WorkPerDayService.getWorkPerDayServiceApiCall(unassignedTasksAssignmentsIDs, {
                    endDate,
                    startDate,
                })
            );
        }
        return Promise.all(WPDPromises)
            .then((data: IRequestedWorkPerDayFromService[]) => {
                if (
                    data &&
                    data.length < 2 &&
                    isNextCallNeeded(data[0], wpdCallCountForUnassignedSection)
                ) {
                    wpdCallCountForUnassignedSection += 1;
                    return separateThreadPoolDelay(2000).then(() => {
                        return dispatch(loadAssignmentsByTaskIDsFormService(args, finalAction));
                    });
                }

                if (startDate === getState().DateRange.startDate) {
                    const wpdHours = getWPDHoursForTasksAndAssignments(
                        data,
                        unassignedTasksIDsWithNoAssignments[0]?.objID
                    );
                    const chainActions = [
                        addUnassignedAssignments(args.unassignedTasks),
                        ...finalAction,
                    ];
                    if (wpdHours.taskHours && wpdHours.assignmentHours) {
                        chainActions.push(
                            addWorkPerDaysForTasksFromService(wpdHours.taskHours),
                            addWorkPerDaysByAssignmentsFromService(wpdHours.assignmentHours)
                        );
                    } else if (wpdHours.taskHours) {
                        chainActions.push(addWorkPerDaysForTasksFromService(wpdHours.taskHours));
                    } else if (wpdHours.assignmentHours) {
                        chainActions.push(
                            addWorkPerDaysByAssignmentsFromService(wpdHours.assignmentHours)
                        );
                    }
                    wpdCallCountForUnassignedSection += 1;
                    dispatch(actionChain(chainActions));
                }
            })
            .catch((error) => {
                dispatch(actionChain(actionsWhenError(error, Sections.UNASSIGNED_WORK)));
            });
    };
}

const finalActionsForAssignedSection = (dates, workPerDayData, chainActions) => {
    return function _finalActionsForAssignedSection(dispatch, getState) {
        if (dates.startDate === getState().DateRange.startDate) {
            chainActions.push(addWorkPerDaysByAssignmentsFromService(workPerDayData));
            dispatch(actionChain(chainActions));
            return Promise.resolve();
        }
    };
};
