// This file is for mocks for chart loading view
export const workPerDayDailyMock = [
    0, 300, 500, 350, 300, 180, 0, 0, 300, 500, 350, 300, 180, 0, 0, 300, 500, 350, 300, 180, 0, 0,
    300, 500, 350, 300, 180, 0, 0, 300, 500, 350, 300, 180, 0, 0, 300, 500, 350, 300, 180, 0, 0,
    300, 500, 350, 300, 180, 0, 0, 300, 500, 350, 300, 180, 0, 0, 300, 500, 350, 300, 180, 0, 0,
    300, 500, 350, 300, 180, 0, 0, 300, 500, 350, 300, 180, 0, 0, 300, 500, 350, 300, 180,
];

export const workPerDayWeeklyMock = [
    1800, 2500, 2000, 1600, 1200, 500, 1800, 2500, 2000, 1600, 1200, 500,
];
export const workPerDayMonthMock = [5000, 8000, 10000];
