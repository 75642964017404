import * as React from 'react';
import { connect } from 'react-redux';
import { TObjCode } from 'workfront-objcodes';
import { Sections, taskPaddings } from '../../../../constants/schedulingTableConstants';
import { IProjectColor, IUnassignedTaskState, TDate } from '../../../../data-flow/data/IDataState';
import { IWorkSchedulingCombinedState } from '../../../../data-flow/types';
import { TaskIssueNodeHTML } from '../TaskIssueNodeHTML';
import { projectGroupingModeSelector } from '../../../../data-flow/settings/settingsSelector';
import { getUnassignedTaskSelector } from '../../../../data-flow/data/selectors/reselect/getUnassignedTaskSelector/getUnassignedTaskSelector';
import { isLastRowUnderUserSelector } from '../../../../data-flow/data/selectors/reselect/isLastRowUnderUserSelector';
import { projectColorDeciderSelector } from '../../../../data-flow/data/selectors/projectStatusSelectors/projectStatusSelectors';

interface ITaskIssueNodeProps extends React.HTMLAttributes<HTMLDivElement> {
    idExpression: string;
    isLastRowUnderUser: boolean;
}

interface ITaskIssueNodeState {
    name: string;
    ID: string;
    isLastRow: boolean;
    projectGroupingMode: boolean;
    objCode: TObjCode;
    assignAccess: boolean;
    plannedStartDate: TDate;
    plannedCompletionDate: TDate;
    isLastRowUnderUser: boolean;
    projectColoredData?: IProjectColor;
}

function TaskIssueNodeComponentUnassignedSection(
    props: ITaskIssueNodeProps & ITaskIssueNodeState
): JSX.Element {
    return (
        <TaskIssueNodeHTML
            name={props.name}
            objCode={props.objCode}
            isLastRow={props.isLastRow}
            isLastRowUnderUser={props.isLastRowUnderUser}
            projectGroupingMode={props.projectGroupingMode}
            isInaccessible={false}
            ID={props.ID}
            paddingLeft={
                !props.projectGroupingMode
                    ? taskPaddings.unassignedSectionPGOff
                    : taskPaddings.unassignedSectionPGOn
            }
            sectionType={Sections.UNASSIGNED_WORK}
            assignAccess={props.assignAccess}
            idExpression={props.idExpression}
            plannedStartDate={props.plannedStartDate}
            plannedCompletionDate={props.plannedCompletionDate}
            projectColoredData={props.projectColoredData}
            isAssignedTaskOrIssue={false}
            isAccessible
            isContoured={false}
        />
    );
}

const emptyProjectData = {};
function mapStateToProps(state: IWorkSchedulingCombinedState, ownProps): ITaskIssueNodeState {
    const idsArray = ownProps.idExpression.split('_');
    let isLastTaskRow;
    if (projectGroupingModeSelector(state)) {
        const unassignedTasksByProject = state.Data.unassignedTasksProjects[idsArray[0]]?.nodes;
        isLastTaskRow =
            idsArray[1] === unassignedTasksByProject[unassignedTasksByProject.length - 1];
    }
    const ID = projectGroupingModeSelector(state) ? idsArray[1] : idsArray[0];
    const unassignedTaskData: IUnassignedTaskState = getUnassignedTaskSelector(state)(ID);
    const isLastRowUnderUser = isLastRowUnderUserSelector(state)(ownProps.idExpression);
    const projectData =
        state.Data.unassignedTasksProjects[ownProps.projectID]?.details || emptyProjectData;

    return {
        name: unassignedTaskData.name,
        objCode: unassignedTaskData.objCode,
        assignAccess: unassignedTaskData.assignAccess,
        ID,
        isLastRow: isLastTaskRow,
        projectGroupingMode: projectGroupingModeSelector(state),
        plannedStartDate: unassignedTaskData.plannedStartDate,
        plannedCompletionDate: unassignedTaskData.plannedCompletionDate,
        projectColoredData: projectColorDeciderSelector(
            state,
            projectData.ID,
            projectData.groupID,
            projectData.status
        ),
        isLastRowUnderUser,
    };
}

export const TaskIssueNode = connect(mapStateToProps)(TaskIssueNodeComponentUnassignedSection);
