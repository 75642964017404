import {
    IProjectColorList,
    IProjectGroupData,
    TProjectColorsMode,
    TProjectID,
} from '../data-flow/data/IDataState';
import { ColorGeneratorService } from '../services/color-service/ColorGeneratorService';

export const transformRGBAColorKey = (hex: string, opacity: number): string => {
    return `${hex}_${opacity}`;
};

interface IColorsToTransformInoRGBA {
    hex: string;
    opacity: number;
}

export const getRGBAColors = (
    colorsToTransformInoRGBA: IColorsToTransformInoRGBA[]
): Record<string, string> => {
    return colorsToTransformInoRGBA
        .map((item) => {
            const rgbaConstants = {};
            rgbaConstants[transformRGBAColorKey(item.hex, item.opacity)] = convertHexToRGBA(
                item.hex,
                item.opacity
            );
            return rgbaConstants;
        })
        .reduce((item, nextItem) => {
            return { ...item, ...nextItem };
        }, {});
};

export const convertHexToRGB = (hex: string): string => {
    if (hex.includes('rgb')) {
        return hex;
    }
    const [r, g, b] = convertHexToRGBAList(hex);

    return RGBStringFromList(r, g, b);
};

export const convertHexToRGBA = (hex: string, opacity: number): string => {
    /**
     * This is needed because previously Phoenix colors returned
     * HEX values, but after using Spectrum colors in Phoenix, values
     * are returned as RGB.
     * Prev:
     * primary.blue(600) -> HEX
     * Now:
     * primary.blue(600) -> RGB
     * */
    if (hex.includes('rgb')) {
        const regex = /\((.*)\)/;
        const rgbNumbers = hex.match(regex);
        return `rgba(${rgbNumbers?.[1]},${opacity / 100})`;
    }
    const [r, g, b] = convertHexToRGBAList(hex);

    return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const convertHexToRGBAList = (hex: string): number[] => {
    const tempHex = hex.replace('#', '');
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return [r, g, b];
};

export const convertRGBToHSV = (r: number, g: number, b: number): number[] => {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h;
    const v = max;

    const d = max - min;
    const s = max === 0 ? 0 : d / max;

    if (max === min) {
        h = 0; // achromatic
    } else {
        // eslint-disable-next-line default-case
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }

        h /= 6;
    }

    return [Math.round(h * 100), Math.round(s * 100), Math.round(v * 100)];
};

export const HSVConvertForProject = (h: number, s: number, v: number): number[] => {
    const sRange = [50, 100];
    const vRange = [40, 70];
    let convertedS = s;
    let convertedV = v;

    if (s < sRange[0]) {
        convertedS = sRange[0] + (s / 100) * (sRange[1] - sRange[0]);
    }

    if (v < vRange[0] || v > vRange[1]) {
        convertedV = vRange[0] + (s / 100) * (vRange[1] - vRange[0]);
    }

    return [Math.round(h), Math.round(convertedS), Math.round(convertedV)];
};

export const convertHSVToRGB = (h: number, s: number, v: number): number[] => {
    h /= 100;
    s /= 100;
    v /= 100;
    let r;
    let g;
    let b;

    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);

    // eslint-disable-next-line default-case
    switch (i % 6) {
        case 0:
            r = v;
            g = t;
            b = p;
            break;
        case 1:
            r = q;
            g = v;
            b = p;
            break;
        case 2:
            r = p;
            g = v;
            b = t;
            break;
        case 3:
            r = p;
            g = q;
            b = v;
            break;
        case 4:
            r = t;
            g = p;
            b = v;
            break;
        case 5:
            r = v;
            g = p;
            b = q;
            break;
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

export const RGBStringFromList = (r: number, g: number, b: number): string => {
    return `rgb(${r},${g},${b})`;
};

export const makeTaskRGBFromProjectRGB = (r: number, g: number, b: number): number[] => {
    return [r, g, b].map((val) => {
        return Math.round(val + (255 - val) * 0.3);
    });
};

function luminanace(r: number, g: number, b: number): number {
    const a = [r, g, b].map((v) => {
        v /= 255;
        // eslint-disable-next-line no-restricted-properties
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export const contrast = (rgb1: number[], rgb2 = [255, 255, 255]): number => {
    const lum1 = luminanace(rgb1[0], rgb1[1], rgb1[2]);
    const lum2 = luminanace(rgb2[0], rgb2[1], rgb2[2]);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return Math.round(((brightest + 0.05) / (darkest + 0.05)) * 100) / 100;
};

export const getTextColorRGBByContrast = (
    backgroundRGBList: number[],
    defaultRGBDarkList: number[]
): number[] => {
    const whiteColorRGB = [255, 255, 255];
    const whiteColorsContrast = contrast(backgroundRGBList, whiteColorRGB);

    const compareRation = 4.5;

    if (whiteColorsContrast > compareRation) {
        return whiteColorRGB;
    }

    const defaultColorContrast = contrast(backgroundRGBList, defaultRGBDarkList);

    if (defaultColorContrast > compareRation) {
        return defaultRGBDarkList;
    }
    // blackColorRGB
    return [0, 0, 0];
};

export const convertHSVToRGBForText = (h: number): number[] => {
    return convertHSVToRGB(h, 100, 15);
};

export const RGBListToRGBA = (RGBArray: number[], opacity: number): string => {
    const [r, g, b] = RGBArray;
    return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const isColorModeDefault = (colorMode: TProjectColorsMode): boolean =>
    colorMode === colorSettingModes.default;

export const isColorModeProject = (colorMode: TProjectColorsMode): boolean =>
    colorMode === colorSettingModes.project;

export const isColorModeProjectStatus = (colorMode: TProjectColorsMode): boolean =>
    colorMode === colorSettingModes.projectstatus;

export const isAnyColorModeSelected = (colorMode: TProjectColorsMode): boolean =>
    isColorModeProjectStatus(colorMode) || isColorModeProject(colorMode);

const charactersToHexCodes = {
    '0': '0',
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    a: 'a',
    b: 'b',
    c: 'c',
    d: 'd',
    e: 'e',
    f: 'f',
    g: '0',
    h: '1',
    i: '2',
    j: '3',
    k: '4',
    l: '5',
    m: '6',
    n: '7',
    o: '8',
    p: '9',
    q: 'a',
    r: 'b',
    s: 'c',
    t: 'd',
    u: 'e',
    v: 'f',
    w: '0',
    x: '1',
    y: '2',
    z: '3',
};

export const getHEXColorFromProjectID = (projectID: TProjectID): string => {
    // seems we have a caching before this function call, so no need to have a cach on this
    const hexCode = projectID
        .substr(projectID.length - 6)
        .split('')
        .map((char) => charactersToHexCodes[char] || '9') // '9' just a random one
        .join('');

    return `#${hexCode}`;
};

export const prepareColorsByProjectGroupData = (
    groupIDList: string[],
    groupData: any[]
): IProjectGroupData => {
    const groupsColorsRefactored = {};
    const colorGeneratorService = ColorGeneratorService.getInstance();

    if (groupData && groupData.length) {
        groupIDList.forEach((groupID, index) => {
            const groupItems = groupData[index];
            if (groupItems) {
                groupItems.forEach((groupItem) => {
                    groupsColorsRefactored[groupID] = groupsColorsRefactored[groupID] || {
                        colors: {},
                        labels: {},
                    };
                    groupsColorsRefactored[groupID].colors[groupItem.value] =
                        colorGeneratorService.getColor(`#${groupItem.color}`);
                    groupsColorsRefactored[groupID].labels[groupItem.value] = groupItem.label;
                });
            }
        });
    }

    return groupsColorsRefactored;
};

export const prepareColorsByProjectData = (projectIDs: TProjectID[]): IProjectColorList => {
    const colors = {};
    const colorGeneratorService = ColorGeneratorService.getInstance();

    projectIDs.forEach((projectID) => {
        const uniqueHex = getHEXColorFromProjectID(projectID);
        colors[projectID] = colorGeneratorService.getColor(uniqueHex);
    });

    return colors;
};

export const colorSettingModes = {
    default: 'default',
    projectstatus: 'projectstatus',
    project: 'project',
};
