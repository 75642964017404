import { Font, primary, Tooltip } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import { Task, OpTask, TObjCode } from 'workfront-objcodes';
import React, { useMemo } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { Sections } from '../../../constants/schedulingTableConstants';
import { IProjectColor, TDate } from '../../../data-flow/data/IDataState';
import { getFontType } from '../../../util/getFontType';
import { convertMinuteToHour } from '../../../util/utilities';
import { ITAskIssueDivStyled, taskIssueDiv, TaskIssueNameStyled } from '../../styles/sharedStyles';
import { TaskIssueNodeHoursAndActions } from './TaskIssueNodeHoursAndActions';
import { PROJECT_TASK_TOOLTIP_DELAY } from '../../../constants/dataConstatnts';

interface ITaskIssueNodeProps {
    name: string;
    ID: string;
    idExpression: string;
    workRequired?: number;
    isLastRow: boolean;
    isLastRowUnderUser: boolean;
    isInaccessible: boolean;
    projectGroupingMode: boolean;
    paddingLeft: number;
    sectionType?: Sections;
    showUnlockIcon?: boolean;
    objCode: TObjCode;
    assignAccess: boolean;
    plannedStartDate: TDate;
    plannedCompletionDate: TDate;
    projectColoredData?: IProjectColor;
    isAccessible: boolean;
    isAssignedTaskOrIssue: boolean;
    isContoured: boolean;
}

export const TaskIssueNodeHTML: React.FC<ITaskIssueNodeProps> = (props) => {
    const {
        assignAccess,
        ID,
        idExpression,
        isInaccessible,
        isLastRow,
        isLastRowUnderUser,
        name,
        objCode,
        paddingLeft,
        plannedCompletionDate,
        plannedStartDate,
        projectGroupingMode,
        sectionType,
        showUnlockIcon,
        workRequired,
        projectColoredData,
        isAccessible,
        isAssignedTaskOrIssue,
        isContoured,
    } = props;

    const fontComponentDetails = getFontType(!isInaccessible, ID, objCode);
    const color = isInaccessible ? primary.gray(400) : primary.gray(600);

    const nodeMessageKey = {
        [Task]: {
            messageKey: 'task',
            fallBack: 'Task',
        },

        [OpTask]: {
            messageKey: 'issue',
            fallBack: 'Issue',
        },
    };

    const workRequiredHours =
        workRequired &&
        sectionType === Sections.PEOPLE_WORKLOAD &&
        convertMinuteToHour(workRequired);

    const leftVerticalLine = useMemo(() => {
        if (isAccessible) {
            return line(projectColoredData ? projectColoredData.RGB : primary.blue(500), isLastRow);
        }
        return line(primary.gray(200), isLastRow);
    }, [projectColoredData, isAccessible, isLastRow]);

    return (
        <Localization<string[]>
            messageKeys={[nodeMessageKey[objCode]?.messageKey, 'workloadbalancer.total.work']}
        >
            {({ _t }) => {
                return (
                    <TaskIssueDivStyled
                        className="taskCell"
                        isLastRow={isLastRow}
                        isLastRowUnderUser={isLastRowUnderUser}
                        data-testid={`task-issue-node-${ID}`}
                        projectGrouping={projectGroupingMode}
                        paddingLeft={paddingLeft}
                        sectionType={sectionType}
                        isAssignedTaskOrIssue={isAssignedTaskOrIssue}
                    >
                        {projectGroupingMode && <span className={leftVerticalLine} />}
                        <TaskIssueNameStyled>
                            <Font
                                type={fontComponentDetails.type}
                                to={fontComponentDetails.to}
                                target="_blank"
                                style={{ color }}
                                aria-label={`${_t[nodeMessageKey[objCode]?.messageKey](
                                    nodeMessageKey[objCode]?.fallBack
                                )}: ${name}, ${
                                    workRequiredHours
                                        ? _t['workloadbalancer.total.work'](
                                              'Total work required: {0} hours',
                                              workRequiredHours
                                          )
                                        : ''
                                }`}
                            >
                                <Tooltip content={name} delay={PROJECT_TASK_TOOLTIP_DELAY}>
                                    {name}
                                </Tooltip>
                            </Font>
                        </TaskIssueNameStyled>

                        <TaskIssueNodeHoursAndActions
                            assignAccess={assignAccess}
                            ID={ID}
                            idExpression={idExpression}
                            isInaccessible={isInaccessible}
                            objCode={objCode}
                            plannedCompletionDate={plannedCompletionDate}
                            plannedStartDate={plannedStartDate}
                            sectionType={sectionType}
                            showUnlockIcon={showUnlockIcon}
                            workRequired={workRequired}
                            isContoured={isContoured}
                        />
                    </TaskIssueDivStyled>
                );
            }}
        </Localization>
    );
};

export const borderBottomStyle = (
    isLastRowUnderUser: boolean,
    isLastRow: boolean,
    isAssignedTaskOrIssue: boolean
): string => {
    if (isLastRow) {
        if (isAssignedTaskOrIssue && isLastRowUnderUser) {
            return `1px solid ${primary.gray(200)}`;
        }
        return `1px dashed ${primary.gray(200)}`;
    }
    return '';
};

export const TaskIssueDivStyled = styled('div')<ITAskIssueDivStyled>`
    ${taskIssueDiv};
    position: relative;
    padding-left: ${(props) => props.paddingLeft}px;
    border-bottom: ${(props) =>
        borderBottomStyle(props.isLastRowUnderUser, props.isLastRow, props.isAssignedTaskOrIssue)};
    background-color: ${(props) =>
        props.sectionType === Sections.PEOPLE_WORKLOAD ? primary.gray(50) : ''};
`;

const line = (backgroundColor, isLastRow): string => css`
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    background-color: ${backgroundColor};
    height: calc(100% - ${isLastRow ? '4px' : '0px'});
    border-bottom-right-radius: ${isLastRow ? '2px' : ''};
`;
