import { IDataState } from '../IDataState';

export const ActionType = 'REMOVE_ALL_USERS';

const removeAllUsers = () => ({
    type: ActionType,
    payload: {},
});
export default removeAllUsers;

export function handle(state: IDataState): IDataState {
    return {
        ...state,
        users: {},
    };
}
