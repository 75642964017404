import { forEach, map } from 'lodash';
import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'REMOVE_WORK_PER_DAYS_BY_USERS_ASSIGNMENTS';

interface IRemoveWorkPerDaysByUsersAssignmentsPayload {
    usersIDs?: string[];
    objectID?: string; // can be task or issue ID
}

type TRemoveWorkPerDaysByUsersAssignmentsAction =
    TModernSchedulingAction<IRemoveWorkPerDaysByUsersAssignmentsPayload>;

const removeWorkPerDaysByUsersAssignments = (
    usersIDs?: string[],
    objectID?: string
): TRemoveWorkPerDaysByUsersAssignmentsAction => ({
    type: ActionType,
    payload: {
        usersIDs,
        objectID,
    },
});
export default removeWorkPerDaysByUsersAssignments;

export function handle(
    state: IDataState,
    { payload }: TRemoveWorkPerDaysByUsersAssignmentsAction
): IDataState {
    const workPerDays = { ...state.workPerDaysByAssignments }; // TODO remove This one when workPerDayServicePitboss will be removed
    const workPerDaysFromService = { ...state.workPerDaysByAssignmentsFromService };

    const usersIDs = !payload.usersIDs
        ? map(state.users, (userData, userID) => userID)
        : payload.usersIDs;

    forEach(usersIDs, (userID: string) => {
        const userAssignments = state.users[userID].assignments;

        forEach(userAssignments, (objectID, assignmentID) => {
            if (payload.objectID && payload.objectID === objectID) {
                delete workPerDays[assignmentID];
                delete workPerDaysFromService[assignmentID];
            }

            if (!payload.objectID) {
                delete workPerDays[assignmentID];
                delete workPerDaysFromService[assignmentID];
            }
        });
    });

    return {
        ...state,
        workPerDaysByAssignments: workPerDays,
        workPerDaysByAssignmentsFromService: workPerDaysFromService,
    };
}
