import { Sections } from '../../../constants/schedulingTableConstants';
import { getContextValue } from '../../../contexts/checkContext';
import { TWorkSchedulingThunkAction } from '../../types';
import { addFilterActions, getUnsavedWlbFilter } from '../../../util/filters/filterUtil';
import actionChain from '../../higher-order-reducers/actionChain';
import { filterApiCall } from '../../../services/api-services/filters/filterApiService';
import deleteFilter from '../../filters/filtersActions/deleteFilter';
import { loadProjectsStatusesThunk, throwErrorInToastAndRemoveFilter } from '../loadDataThunk';
import { IFilterExpression, IFilterRuleGroup, ITritonFilter } from '../../filters/IFiltersState';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { editSettingsThunk } from '../settings';
import {
    peopleWorkloadFilterIDSelector,
    unassignedWorkFilterIDSelector,
} from '../../filters/selectors/filterIDSelector';
import { isInProjectArea } from '../../../util/utilities';
import { IAreaState } from '../../areaData/areaRelatedInitialDataState';

export function getFiltersFilterExpression(
    area: IAreaState,
    sectionType: Sections,
    appliedFilterIDs: string[],
    filterExpression?: IFilterRuleGroup
): TWorkSchedulingThunkAction<Promise<any>> {
    return async function _getFiltersFilterExpression(dispatch) {
        let appliedFilterIDsInArray = Array.isArray(appliedFilterIDs)
            ? appliedFilterIDs
            : [appliedFilterIDs];
        const filterExpressionID = 'filterExpressionID';
        appliedFilterIDsInArray = appliedFilterIDsInArray.filter((id) => id !== filterExpressionID);
        let appliedFilters: ITritonFilter[] = [];
        const { schedulingAreaObjCode, schedulingAreaID } = area;

        const idQueryParams = appliedFilterIDsInArray.reduce(
            (params, id) => `${params}${params ? '&' : ''}ids=${id}`,
            ''
        );

        try {
            if (appliedFilterIDsInArray.length > 0) {
                appliedFilters = await filterApiCall(
                    `/ids?${idQueryParams}&fields=name,filterExpression`,
                    'GET'
                );
                if (isInProjectArea(schedulingAreaObjCode)) {
                    appliedFilters.forEach((filter) => {
                        if (
                            filter.filterExpression.rules[0].searchValues.includes('$$PROJECT.ID')
                        ) {
                            filter.filterExpression.rules[0].searchValues = [schedulingAreaID!];
                        }
                    });
                }
            }
            if (filterExpression) {
                const filterText = localizationClient.getTextSync('uifilter', 'Filter');
                const dummyFilterForApplied: any = {
                    name: `1 ${filterText}`,
                    ID: filterExpressionID,
                    filterExpression,
                };
                appliedFilters.push(dummyFilterForApplied);
            }

            dispatch(actionChain(addFilterActions(appliedFilters, sectionType)));
        } catch (error) {
            throwErrorInToastAndRemoveFilter(sectionType);
            dispatch(deleteFilter(sectionType));
            dispatch(editSettingsThunk());
        }
    };
}

export function loadInitFilterThunk(): TWorkSchedulingThunkAction<Promise<any>> {
    return function _loadInitFilterThunk(dispatch, getState) {
        const state = getState();
        const assignedFilterID = peopleWorkloadFilterIDSelector(state);
        const unassignedFilterID = unassignedWorkFilterIDSelector(state);
        const { schedulingAreaObjCode } = state.areaData;
        const filters: Array<Promise<void>> = [dispatch(loadProjectsStatusesThunk())];

        const unassignedFilterData: IFilterExpression = getUnsavedWlbFilter(
            Sections.UNASSIGNED_WORK,
            schedulingAreaObjCode
        );
        const assignedFilterData: IFilterExpression = getUnsavedWlbFilter(
            Sections.PEOPLE_WORKLOAD,
            schedulingAreaObjCode
        );
        if (!getContextValue('sharableLink')) {
            filters.push(
                dispatch(
                    getFiltersFilterExpression(
                        state.areaData,
                        Sections.UNASSIGNED_WORK,
                        unassignedFilterID as string[],
                        unassignedFilterData?.filterExpression
                    )
                )
            );
        }
        filters.push(
            dispatch(
                getFiltersFilterExpression(
                    state.areaData,
                    Sections.PEOPLE_WORKLOAD,
                    assignedFilterID as string[],
                    assignedFilterData?.filterExpression
                )
            )
        );
        return Promise.all(filters);
    };
}
