import { primary } from '@phoenix/all';
import styled, { css } from 'react-emotion';

interface INavBarSeparator {
    isRightSection?: boolean;
}

export const disabledStyleForButtons = css`
    cursor: default;
    &:focus {
        outline: 0;
    }
`;

export const politeMessageStyles = css`
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0px;
    position: absolute;
    width: 1px;
`;

export const headerButtonStyles = css`
    padding: 6px;
    box-sizing: border-box;
    min-width: 32px;
    color: ${primary.gray(600)};
    height: 32px;
    border-color: transparent !important;
    display: flex;
    align-items: center;
    font-weight: 400;
    justify-content: center;
    flex: 0 0 auto;
    border-radius: 4px;
    &:after {
        border-radius: 4px;
    }
    &:focus:enabled {
        border-color: transparent;
        background-color: transparent;
    }
    &:hover:enabled {
        border-color: ${primary.gray(100)} !important;
        background-color: ${primary.gray(100)} !important;
    }
`;

export const headerButtonsSelectedStyles = css`
    ${headerButtonStyles}
    margin-left: 6px;
    &:hover {
        background-color: ${primary.gray(50)} !important;
    }
`;

export const headerButtonsDisabledStyles = css`
    ${headerButtonStyles}
    margin-left: 6px;
    background-color: ${primary.gray(200)} !important;
    ${disabledStyleForButtons}
`;

export const navigationBar = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

export const NavBarSeparator = styled('span')<INavBarSeparator>`
    border-right: 1px solid ${primary.gray(200)};
    width: 0px;
    height: 24px;
    margin: 0 8px;
    &:first-child {
        margin-left: 0;
    }
`;

export const headerStyleQS = css`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    position: relative;
    margin-bottom: 0px;
    width: 100%;
`;

export const tableHeaderRightSection = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const tableHeaderLeftSection = css`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

export const resetButtonStyle = css`
    display: block;
    color: ${primary.blue(400)};
    font-weight: 600;
    pointer-events: auto;
`;

export const periodModeButtonsStyle = css`
    display: flex;
`;

export const navbarItemPassiveStyle = css`
    ${headerButtonStyles};
    &:hover {
        background-color: ${primary.gray(100)};
    }
`;

export const navbarItemActiveStyle = css`
    ${headerButtonStyles};
    background-color: ${primary.gray(200)} !important;
`;

export const headerIconButtonStyles = css`
    ${headerButtonStyles}
    width:32px;
    padding: 6px 0;
`;

export const headerIconButtonPassiveStyle = css`
    ${headerIconButtonStyles};
    &:hover {
        background-color: ${primary.gray(100)};
    }
`;

export const headerIconButtonActiveStyle = css`
    ${headerIconButtonStyles};
    background-color: ${primary.gray(200)} !important;
`;

export const headerPrevButtonStyles = css`
    margin-left: 4px !important;
    margin-right: 1px;
`;

export const getHeaderItemStyles = (isActive): string =>
    isActive ? headerIconButtonActiveStyle : headerIconButtonPassiveStyle;
