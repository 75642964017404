import { Sections } from '../../constants/schedulingTableConstants';
import { IErrorDetail, IErrorState } from '../data/IDataState';
import { TModernSchedulingAction } from '../types';

export const ActionType = 'SET_ERROR_STATE';

interface IChangeErrorStatePayload {
    errorData: IErrorDetail;
    sectionType: Sections;
}

export type TChangeErrorStatePayload = TModernSchedulingAction<IChangeErrorStatePayload>;

const changeErrorState = (
    sectionType: Sections,
    errorData: IErrorDetail
): TChangeErrorStatePayload => ({
    type: ActionType,
    payload: {
        sectionType,
        errorData,
    },
});

export default changeErrorState;

export function handle(state: IErrorState, { payload }: TChangeErrorStatePayload): IErrorState {
    return {
        ...state,
        [payload.sectionType]: payload.errorData,
    };
}
