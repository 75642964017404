import { TModernSchedulingAction } from '../../types';
import { IDataState, TTaskOrIssueID, TUserID } from '../IDataState';

export const ActionType = 'SET_HIGHLIGHTING_USERS';

interface ISettings {
    objectDetails?: Record<TTaskOrIssueID, any> | null; // it is need when task's date  became out of range
    highlightTask?: boolean;
}

interface ISetHighlightingUsersPayload {
    userIds: TUserID[];
    taskIDs: TTaskOrIssueID[]; // here  can be also issueID,
    settings?: ISettings;
}

type TSetHighlightingUsersAction = TModernSchedulingAction<ISetHighlightingUsersPayload>;

const setIntoHighlightingMode = (
    userIds: TUserID[],
    taskIDs: TTaskOrIssueID[],
    settings?: ISettings
): TSetHighlightingUsersAction => ({
    type: ActionType,
    payload: {
        userIds,
        taskIDs,
        settings,
    },
});

export function handle(state: IDataState, { payload }: TSetHighlightingUsersAction): IDataState {
    const inHighlightingModeUsersAfterAssignmentUser = {
        ...state.inHighlightingModeUsersAfterAssignment.user,
    };
    const inHighlightingModeUsersAfterAssignmentObject = {
        ...state.inHighlightingModeUsersAfterAssignment.object,
    };

    const { taskIDs, settings = {} as ISettings } = payload;
    const { objectDetails, highlightTask } = settings;

    taskIDs.forEach((taskID) => {
        const taskDetails =
            (objectDetails && objectDetails[taskID]) ||
            state.tasksAndIssues[taskID] ||
            state.unassignedTasks[taskID];

        if (taskDetails) {
            payload.userIds.forEach((userID) => {
                inHighlightingModeUsersAfterAssignmentUser[userID] = {
                    affectingDurationStartDate: taskDetails.plannedStartDate,
                    affectingDurationEndDate: taskDetails.plannedCompletionDate,
                };
            });
        }

        if (highlightTask) {
            inHighlightingModeUsersAfterAssignmentObject[taskID] = true;
        }
    });

    return {
        ...state,
        inHighlightingModeUsersAfterAssignment: {
            user: inHighlightingModeUsersAfterAssignmentUser,
            object: inHighlightingModeUsersAfterAssignmentObject,
        },
    };
}

export default setIntoHighlightingMode;
