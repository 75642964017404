import { css } from 'emotion';
import React, { useEffect, useRef, useState } from 'react';
import { Localization } from '@workfront/localize-react';
import { Text } from '@phoenix/all';
import { inlineItems } from '../../../styles';
import Portal from '../right/portal';
import { ContextSensitiveHelp } from './ContextSensitiveHelp';
import { Header } from './Header';
import { getContextValue } from '../../../contexts/checkContext';

/**
 * This is the id of toolbar, which is in QS
 * */
export const toolbarItemsId = 'toolbar-items';

interface ISchedulingHeader {
    onWindowResize: (top: number) => void;
}

export const feedbackBtnBoundedSelectorTestId = 'undefined-selected-value';

export const SchedulingHeader: React.FunctionComponent<ISchedulingHeader> = React.memo(
    ({ onWindowResize }) => {
        const isSharableView = getContextValue('sharableLink');
        const [toolbarWidth, setToolbarWidth] = useState<string | number>('auto');
        const headerRef = useRef<HTMLElement>(null);

        useEffect(() => {
            // Change tab label style to shrink toolbar only
            const tabLabel = document.querySelector<HTMLElement>(
                '[data-testid=parent-active-tab-label]'
            );
            if (tabLabel) tabLabel.style.whiteSpace = 'nowrap';

            const onHeaderResize = (): void => {
                const left = (headerRef.current as HTMLElement)?.getBoundingClientRect().left || 0;
                setToolbarWidth(window.innerWidth - left);
            };

            onHeaderResize();
            window.addEventListener('resize', onHeaderResize);
            return (): void => {
                window.removeEventListener('resize', onHeaderResize);
            };
        }, []);

        if (isSharableView) {
            /**
             * This part should be revisited when Sharable view becomes MFE
             * */
            return (
                <div className={flex}>
                    <Text.Regular>
                        <Localization<string>
                            messageKey="workloadbalancer"
                            fallback="Workload Balancer"
                        />
                    </Text.Regular>
                    <div className={contextSensitiveHelpWrapper}>
                        <ContextSensitiveHelp />
                    </div>
                    <Header
                        toolbarWidth={toolbarWidth}
                        headerRef={headerRef}
                        onWindowResize={onWindowResize}
                    />
                </div>
            );
        }

        return (
            <Portal container={document.getElementById(toolbarItemsId)} width="100%">
                <div className={verticallyCenter}>
                    <div data-testid={feedbackBtnBoundedSelectorTestId}>
                        {/**
                         * This is needed for feedback btn which is injected
                         * in Prod and Preview instances by Pendo. It is
                         * mandatory that the selector which is used as an anchor
                         * to have a child.
                         */}
                        <span>&nbsp;</span>
                    </div>
                    <ContextSensitiveHelp />
                    <Header
                        toolbarWidth={toolbarWidth}
                        headerRef={headerRef}
                        onWindowResize={onWindowResize}
                    />
                </div>
            </Portal>
        );
    }
);

const contextSensitiveHelpWrapper = css`
    margin-left: 10px;
`;

const flex = css`
    ${inlineItems};
    height: 41px;
    align-items: center;
    justify-content: flex-start;
    > span {
        margin-left: 14px;
    }
`;

const verticallyCenter = css`
    ${inlineItems};
    align-items: center;
    margin-left: 5px;
`;
