import mitt from 'mitt';
import React, { CSSProperties } from 'react';
import { cx } from 'react-emotion';
import { connect } from 'react-redux';
import {
    GridCellRenderer,
    ScrollParams,
    SectionRenderedParams,
} from 'react-virtualized/dist/es/Grid';
import { MultiGrid } from 'react-virtualized/dist/es/MultiGrid';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import { Moment } from 'moment';
import { ScrollSyncChildProps } from 'react-virtualized/dist/es/ScrollSync';
import ErrorIcon from 'phoenix-icons/dist/ErrorIcon.js';
import { primary } from '@phoenix/all';
import { RECOMPUTE_GRID_SIZE } from '../../../constants/events';
import { Sections, sizes, systemScrollbarSize } from '../../../constants/schedulingTableConstants';
import { GlobalPropsContext } from '../../../contexts/globalContexts';
import {
    IAssignmentDialogDetails,
    IObjectStateWithID,
    IUserState,
    TasksAndIssuesRecord,
    TContouringRowID,
    TDropdownOpened,
    TInHighlightingMode,
    TOrUndefined,
    TProjectID,
    TTableDataID,
    TTableDataIDs,
    TUserID,
} from '../../../data-flow/data/IDataState';
import {
    assignmentDialogDetailsSelector,
    contouringRowIdSelector,
    dropdownOpenedSelector,
    highlightingModeSelector,
    tableDataIDsForUnassignedSectionSelector,
} from '../../../data-flow/data/selectors/dataSelectors';
import { isErrorThrownSelector } from '../../../data-flow/errorData/errorDataSelector';
import { internalEventEmitterSelector } from '../../../data-flow/instances/internalEventEmitterSelector';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../data-flow/types';
import { getRowCount } from '../../../util/getRowCount';
import { getOffset, isColorModeProjectStatus, isInProjectArea } from '../../../util/utilities';
import { ErrorPage } from '../right/ErrorPage';
import { getSizesRowHeight, getTableRowHeightForAssignedSection } from '../utils';
import { getUserNodesIDs, sortByID } from '../../../util/dataService';
import {
    LOAD_PROJECTS_LIMIT,
    LOAD_TASKS_ISSUES_LIMIT,
    sectionsHeaderDataID,
} from '../../../constants/dataConstatnts';
import changeUserNodesOffset from '../../../data-flow/data/assignedDataActions/changeOffset';
import addTableDataIDs from '../../../data-flow/data/assignedDataActions/addTableDataIDs';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import { startDateSelector } from '../../../data-flow/dateRange/selectors/startDateSelector';
import { IAreaState } from '../../../data-flow/areaData/areaRelatedInitialDataState';
import setHeightForPeopleWorkloadEmptyRow, {
    TSetHeightForPeopleWorkloadSectionEmptyRow,
} from '../../../data-flow/data/assignedDataActions/setHeightForPeopleWorkloadEmptyRow';
import addLoadingRow from '../../../data-flow/data/dataLoadingActions/addLoadingRow';
import removeTableDataIDs from '../../../data-flow/data/assignedDataActions/removeTableDataIDs';
import changeNodeArrowState from '../../../data-flow/data/nodeItemActions/changeNodeArrowState';
import { sortByProjectID } from '../../../util/changeProjectsSortingUtil';
import { tableDataIDsSelector } from '../../../data-flow/data/selectors/tableDataIDsSelector';
import {
    projectColorsModeSelector,
    projectGroupingModeSelector,
    showActualProgressSelector,
    showAllocationsVisualizationSelector,
} from '../../../data-flow/settings/settingsSelector';
import { peopleWorkLoadHeightSelector } from '../../../data-flow/tableSizes/selectors/tableSizesSelectors';
import { unassignedWorkHeightSelector } from '../../../data-flow/tableSizes/selectors/unassignedWorkHeightSelector';
import { getNodeIDs } from '../../../data-flow/data/selectors/reselect/getNodeIDs';
import { getUserTasks } from '../../../data-flow/data/selectors/reselect/getUserTasks';
import { getSchedulingAreaData } from '../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { UserWrapperOnDragComponent } from '../UserWrapperOnDrag/UserWrapperOnDragComponent';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { TableRowAssigned } from '../tableRows/TableRowAssigned';
import {
    filterProjectTaskAndIssuesById,
    getHideScrollbarClass,
    getVerticalScrollWidthPerArea,
    getWidthPerColumn,
    hasLoadedDataIDs,
    recomputeGridColumn,
    shouldBodyHaveScrollBarPlaceholder,
    shouldHeaderHaveScrollBarPlaceholder,
} from './schedulingTableUtils';
import {
    bottomRightGridLeftBorder,
    gridForAssigned,
    hideUnassignedScrollbarWhenCollapsed,
    resetOutline,
    schedulingTableStyled,
    scrollBarsPlaceholder,
    scrollBarVerticalPlaceholder,
    setRightOverlayWidth,
    zIndexForErrorPage,
} from './schedulingTableStyles';
import { TableWidthSetter } from '../TableWidthSetter';
import { ScrollSyncCustomChildProps } from '../../Scheduling/ScrollSyncCustom';
import { loadAssignedSectionProjectsDetailsThunk } from '../../../data-flow/thunks/assignedSectionLoadDataThunk';

export type ISchedulingTableState = Record<string, never>;

type TNodeIDs = string[];

interface ISchedulingTableAssignedStateProps {
    assignmentDialogDetails: IAssignmentDialogDetails;
    contouringRowID: TContouringRowID;
    isActualProgressBarEnabledInSettings: boolean;
    internalEventEmitter: mitt.Emitter;
    isAllocationsVisualizationOn: boolean;
    projectGroupingMode: boolean;
    unassignedWorkEmptyRowHeight: number;
    unassignedWorkHeight: number;
    dropdownOpened: TDropdownOpened;
    startDate: Moment;
    schedulingAreaData: IAreaState;
    peopleWorkLoadHeight: number;
    projectColorsMode: string;
    tableDataIDs: TTableDataIDs;
    tableDataIDsForUnassignedSection: TTableDataIDs;
    getNodeIDS: (userID: string, projectID: string) => TNodeIDs;
    getUserTasksById: (userID: string) => TasksAndIssuesRecord;
    inHighlightingMode: TInHighlightingMode;
    isErrorThrown: boolean;
}

export interface ISchedulingTableAssignedProps {
    sectionType: Sections;
    isInInitialState?: boolean;
    dataIDs: string[];
    onScrollHandler: (scrollArguments: ScrollParams) => void; // TODO ask Sassoun
    sectionRenderedHandler?: (sectionRenderedParams: SectionRenderedParams) => void;
    isTableCollapsed: boolean;
    gridHeight: number;
    tableMinWidth: number;
    isInLoadingState: boolean;
    isAssignmentDialogOpen: boolean;
    onScroll: ScrollSyncChildProps['onScroll'];
    scrollLeft: ScrollSyncChildProps['scrollLeft'];
    areasData: ScrollSyncCustomChildProps['areasData'];
    setAreasData: ScrollSyncCustomChildProps['setAreasData'];
    leftSidePanelWidth: number;
}

type TShowMoreComponentProps = IWorkSchedulingDispatchProp;

type TSchedulingTableProps = ISchedulingTableAssignedStateProps &
    React.PropsWithChildren<ISchedulingTableAssignedProps> &
    TShowMoreComponentProps;

const gridAriaLabel = {
    messageKey: 'resourcescheduling.assigned.work',
    fallBack: 'Assigned Work',
};
const recomputeGridColumnWidthOnStepUnitChange = recomputeGridColumn();

export interface ICellStyle {
    top: number;
    left: number;
    height: number;
    width: number;
    position: CSSProperties['position'];
}

class SchedulingTableAssignedDisconnected extends React.PureComponent<
    TSchedulingTableProps,
    ISchedulingTableState
> {
    static contextType = GlobalPropsContext;
    context!: React.ContextType<typeof GlobalPropsContext>;
    gridRef = React.createRef<MultiGrid>();

    componentDidMount(): void {
        this.props.internalEventEmitter.on(RECOMPUTE_GRID_SIZE, this.handleRecomputeGridSize);
    }

    componentDidUpdate(prevProps: Readonly<TSchedulingTableProps>): void {
        if (
            prevProps.isInLoadingState !== this.props.isInLoadingState ||
            prevProps.unassignedWorkEmptyRowHeight !== this.props.unassignedWorkEmptyRowHeight
        ) {
            this.handleRecomputeGridSize(0);
        }

        if (prevProps.leftSidePanelWidth !== this.props.leftSidePanelWidth) {
            this.handleRecomputeGridSize(1);
        }
    }

    componentWillUnmount(): void {
        this.props.internalEventEmitter.off(RECOMPUTE_GRID_SIZE, this.handleRecomputeGridSize);
        this.context.minixClose();
    }

    handleRecomputeGridSize = (rowIndex): void => {
        this.recomputeGridSizeAfterRow(rowIndex || 0);
    };

    recomputeGridSizeAfterRow = (rowIndex = 0): void => {
        if (this.gridRef.current) {
            this.gridRef.current.recomputeGridSize({ rowIndex });
        }
    };

    cellRenderer: GridCellRenderer = ({ columnIndex, rowIndex, style }) => {
        const isLeftSide = columnIndex === 0;
        const isFirstRow = rowIndex === 0;
        const { inHighlightingMode, assignmentDialogDetails, sectionType, contouringRowID } =
            this.props;
        const assignmentDialogDetailsID = assignmentDialogDetails.ID;
        const idExpression = this.getIDExpression(rowIndex);
        const idsArr = idExpression ? idExpression.split('_') : [];
        const objID = idsArr[idsArr.length - 1];
        const idForShadowStyle = `${sectionType === Sections.PEOPLE_WORKLOAD}_${objID}`;
        const allocationsEditingMode = !!contouringRowID && contouringRowID === idExpression;
        const hasLoadedData = hasLoadedDataIDs(this.props.dataIDs, sectionType);
        const isTheLastRow = hasLoadedData && rowIndex === this.props.dataIDs.length - 1;
        return (
            <TableRowAssigned
                isLeftSide={isLeftSide}
                isFirstRow={isFirstRow}
                key={`row-${sectionType}-${
                    this.props.isInLoadingState ? rowIndex : this.props.dataIDs[rowIndex]
                }`}
                idForShadowStyle={idForShadowStyle}
                allocationsEditingMode={allocationsEditingMode}
                userRow={idsArr.length === 1}
                showAssignmentActions={
                    (assignmentDialogDetailsID === idExpression &&
                        !this.props.assignmentDialogDetails.positionX) ||
                    this.props.dropdownOpened === idExpression
                }
                shouldHighlight={inHighlightingMode.object[objID]}
                idExpression={idExpression}
                sectionType={sectionType}
                hasDataIDs={hasLoadedData}
                isInLoadingState={this.props.isInLoadingState}
                onUnassignedProjectArrowClick={this.onUnassignedProjectArrowClickHandler}
                showProjectAndOrTasks={this.showProjectAndOrTasks}
                handleProjectArrowClick={this.handleProjectArrowClick}
                onUserArrowClick={this.onUserArrowClickHandler}
                isTheLastRow={isTheLastRow}
                style={style as ICellStyle}
            />
        );
    };

    onUserArrowClickHandler = (userID: TUserID): void => {
        const rowIndex = this.getRowIndex(userID);
        this.recomputeGridSize(rowIndex);
    };

    onUnassignedProjectArrowClickHandler = (projectID: TProjectID): void => {
        const rowIndex = this.getUnassingedRowIndex(projectID);
        this.recomputeGridSize(rowIndex);
    };

    showProjectAndOrTasks = (
        userData: IUserState,
        taskOrIssueOnTop: TasksAndIssuesRecord = {}
    ): void => {
        const { projectGroupingMode } = this.props;
        if (projectGroupingMode) {
            const rowIndex = this.getRowIndex(userData.ID);
            this.showProject(userData, rowIndex, taskOrIssueOnTop);
        } else {
            this.showTasks(userData, taskOrIssueOnTop);
        }
    };

    setHeightForPeopleWorkSection = (): TSetHeightForPeopleWorkloadSectionEmptyRow[] => {
        if (systemScrollbarSize > 0) {
            const {
                peopleWorkLoadHeight,
                isActualProgressBarEnabledInSettings,
                projectGroupingMode,
            } = this.props;
            return [
                setHeightForPeopleWorkloadEmptyRow(
                    peopleWorkLoadHeight,
                    isActualProgressBarEnabledInSettings,
                    projectGroupingMode
                ),
            ];
        }

        return [];
    };

    isUserStillExpanded = (userData): TOrUndefined<TTableDataID> => {
        // this case verifies that user is still open.
        // when we expand user and collapse very fast in project area (where user expand means also project expand)
        // we face a case when that adding task ID's are called after user delete
        const { tableDataIDs, schedulingAreaData } = this.props;
        return tableDataIDs.find(
            (tableDataID) => tableDataID === `${userData.ID}_${schedulingAreaData.schedulingAreaID}`
        );
    };

    getProjectAreaActionsOnUserExpand = (userData): any[] => {
        // in project area state when opening user,
        // the project where balancer is integrated should be open by default
        const { schedulingAreaData } = this.props;
        const { schedulingAreaID, schedulingAreaObjCode } = schedulingAreaData;
        const userID = userData.ID;

        const chainedActions: unknown[] = [];
        if (isInProjectArea(schedulingAreaObjCode)) {
            const idExpression = `${userID}_${schedulingAreaID}`;
            let projectTaskAndIssues: IObjectStateWithID[] = [];

            if (schedulingAreaData.schedulingAreaID) {
                const userTasks = this.getUserTasks(userData.ID);

                projectTaskAndIssues = filterProjectTaskAndIssuesById(
                    userTasks,
                    schedulingAreaData.schedulingAreaID
                );
            }

            const nodeIDs: string[] = projectTaskAndIssues.map((taskAndIssue) => taskAndIssue.ID);

            chainedActions.push(removeTableDataIDs(`${userID}_${schedulingAreaID}_`));
            chainedActions.push(changeNodeArrowState(userID, schedulingAreaID));
            chainedActions.push(changeUserNodesOffset(idExpression, LOAD_TASKS_ISSUES_LIMIT));
            chainedActions.push(
                addTableDataIDs(nodeIDs.slice(0, LOAD_TASKS_ISSUES_LIMIT), true, {
                    idExpression,
                    showMore: nodeIDs.length > LOAD_TASKS_ISSUES_LIMIT,
                })
            );
        }

        return chainedActions;
    };

    recomputeGridSize = (rowIndex): void => {
        this.props.internalEventEmitter.emit(RECOMPUTE_GRID_SIZE, rowIndex);
    };

    showProject = (userData, rowIndex, taskOrIssueOnTop: TasksAndIssuesRecord = {}): void => {
        const { projectColorsMode, dispatch } = this.props;
        const taskOrIssue = Object.values(taskOrIssueOnTop)[0];
        const taskOrIssueID = Object.keys(taskOrIssueOnTop)[0];
        const taskOrIssueOnTopProjectId = taskOrIssue?.projectID;
        const userID = userData.ID;

        if (!userData.expanded) {
            const chainedActionsForCollapsedUser: unknown[] = [];
            if (!userData.projectsDataRequestsState.isRequestSend) {
                dispatch(
                    loadAssignedSectionProjectsDetailsThunk([userID], {
                        isUserClicked: true,
                        loadGroupRelatedEnums: isColorModeProjectStatus(projectColorsMode),
                        taskOrIssueOnTopIDs: {
                            taskOrIssueID,
                            taskOrIssueOnTopProjectId,
                        },
                    })
                ).then(() => {
                    if (this.isUserStillExpanded(userData)) {
                        const projectAreaRelatedActionList =
                            this.getProjectAreaActionsOnUserExpand(userData);
                        chainedActionsForCollapsedUser.push(...projectAreaRelatedActionList);
                    }

                    chainedActionsForCollapsedUser.push(...this.setHeightForPeopleWorkSection());
                    dispatch(actionChain(chainedActionsForCollapsedUser));

                    // even though recomputeGridSize is called in handleArrowClick
                    // then called after that, so here also need to call recomputeGridSize
                    this.recomputeGridSize(rowIndex);
                });
                return;
            }
            if (!userData.projectsDataRequestsState.isDataLoaded) {
                dispatch(addLoadingRow(Sections.PEOPLE_WORKLOAD, userID));
                return;
            }
        }

        const offset = getOffset(userData.offset, LOAD_PROJECTS_LIMIT);
        const projectIDs = taskOrIssueOnTopProjectId
            ? userData.projectIDs.sort(sortByProjectID(taskOrIssueOnTopProjectId))
            : userData.projectIDs;

        const chainedActions: unknown[] = [];

        chainedActions.push(
            ...[
                changeUserNodesOffset(userID, offset),
                addTableDataIDs(projectIDs.slice(0, offset), true, {
                    idExpression: userID,
                    showMore: projectIDs.length > offset,
                }),
            ]
        );

        if (taskOrIssueOnTopProjectId) {
            // expanding project on drop
            const nodesIDs = this.props.getNodeIDS(userID, taskOrIssueOnTopProjectId);
            // sorting that assigned task be on top of expanded project
            nodesIDs.sort(sortByID(nodesIDs, taskOrIssueID));

            chainedActions.push(
                changeNodeArrowState(userID, taskOrIssueOnTopProjectId),
                addTableDataIDs(this.getNodeIDsLimited(nodesIDs), true, {
                    idExpression: `${userID}_${taskOrIssueOnTopProjectId}`,
                    showMore: nodesIDs.length > LOAD_TASKS_ISSUES_LIMIT,
                })
            );
        }

        const projectAreaRelatedActions = this.getProjectAreaActionsOnUserExpand(userData);
        chainedActions.push(...projectAreaRelatedActions, ...this.setHeightForPeopleWorkSection());

        dispatch(actionChain(chainedActions));
    };

    showTasks = (userData, taskOrIssueOnTop: TasksAndIssuesRecord = {}): void => {
        const {
            dispatch,
            projectGroupingMode,
            startDate,
            isActualProgressBarEnabledInSettings,
            schedulingAreaData,
        } = this.props;
        const taskOrIssueOnTopID = taskOrIssueOnTop ? Object.keys(taskOrIssueOnTop)[0] : undefined;
        const userTasks = this.getUserTasks(userData.ID);
        const startDateArg = isActualProgressBarEnabledInSettings ? startDate : undefined;
        const tasksIssuesIDs = getUserNodesIDs(
            userData.nodes,
            schedulingAreaData,
            userTasks,
            startDateArg,
            taskOrIssueOnTopID
        );
        const offset = getOffset(userData.offset, LOAD_TASKS_ISSUES_LIMIT);
        const chainedActions: any = [
            changeUserNodesOffset(userData.ID, offset),
            addTableDataIDs(tasksIssuesIDs.slice(0, offset), projectGroupingMode, {
                idExpression: userData.ID,
                showMore: tasksIssuesIDs.length > offset,
            }),
        ];
        chainedActions.push(...this.setHeightForPeopleWorkSection());
        dispatch(actionChain(chainedActions));
    };

    handleProjectArrowClick = (userID: TUserID, expanded: boolean, projectID: TProjectID): void => {
        const { dispatch } = this.props;
        const rowIndex = this.getRowIndex(`${userID}_${projectID}`);

        dispatch(
            actionChain(this.getProjectArrowClickChain(userID, expanded, projectID, rowIndex))
        );
        this.recomputeGridSize(rowIndex);
    };

    getRowIndex = (key: string): number => this.props.tableDataIDs.indexOf(key) || 0;

    getUnassingedRowIndex = (key: string): number =>
        this.props.tableDataIDsForUnassignedSection.indexOf(key) || 0;

    getProjectArrowClickChain = (
        userID: TUserID,
        expanded: boolean,
        projectID: TProjectID,
        rowIndex: number
    ): unknown[] => {
        let collapseExpandChain: any = [];
        if (!expanded) {
            collapseExpandChain = this.handleArrowClickForCollapsedStateChain(
                rowIndex,
                userID,
                projectID
            );
        } else {
            collapseExpandChain = this.handleArrowClickForExpandedStateChain(rowIndex);
        }

        return [
            changeNodeArrowState(userID, projectID),
            ...collapseExpandChain,
            ...this.setHeightForPeopleWorkSection(),
        ];
    };

    handleArrowClickForCollapsedStateChain = (
        rowIndex: number,
        userID: TUserID,
        projectID: TProjectID
    ): unknown[] => {
        const idExpression = this.getIDExpression(rowIndex);
        const nodesIDs = this.props.getNodeIDS(userID, projectID);

        return [
            changeUserNodesOffset(idExpression, LOAD_TASKS_ISSUES_LIMIT),
            addTableDataIDs(this.getNodeIDsLimited(nodesIDs), true, {
                idExpression,
                showMore: nodesIDs.length > LOAD_TASKS_ISSUES_LIMIT,
            }),
        ];
    };

    handleArrowClickForExpandedStateChain = (rowIndex: number): unknown[] => {
        const idExpression = this.getIDExpression(rowIndex);
        return [removeTableDataIDs(`${idExpression}_`), changeUserNodesOffset(idExpression, 0)];
    };

    getIDExpression = (rowIndex: number): string => {
        return this.props.dataIDs[rowIndex];
    };

    getIDExpressionWithoutHeader = (rowIndex: number): string => {
        return this.props.dataIDs.slice(1)[rowIndex];
    };

    getNodeIDsLimited = (nodesIDs: TNodeIDs): TNodeIDs => {
        return nodesIDs.slice(0, LOAD_TASKS_ISSUES_LIMIT);
    };

    getUserTasks = (userID: TUserID): TasksAndIssuesRecord => {
        return this.props.getUserTasksById(userID);
    };

    // TODO create selectors for it, separate Sections.UNASSIGNED_WORK from assigned
    getRowHeight = ({ index }): number => {
        let tableRowHeight;
        const { isAllocationsVisualizationOn, dataIDs } = this.props;
        const sizesRowHeight = getSizesRowHeight(isAllocationsVisualizationOn);

        if (dataIDs[index]?.includes(sectionsHeaderDataID)) {
            return sizes.tableHeaderRowHeight;
        }

        if (this.props.isInLoadingState) {
            tableRowHeight = sizesRowHeight;
        } else if (dataIDs) {
            const idsArray = dataIDs[index].split('_').filter((el) => el !== 'loading');
            tableRowHeight = getTableRowHeightForAssignedSection(
                idsArray,
                sizesRowHeight,
                this.props.projectGroupingMode
            );
        } else {
            tableRowHeight = sizesRowHeight;
        }
        return tableRowHeight;
    };

    cellRangeRenderer = (props): JSX.Element[] => {
        const {
            cellCache, // Temporary cell cache used while scrolling
            styleCache, // Temporary style (size & position) cache used while scrolling,
            columnSizeAndPositionManager, // @see CellSizeAndPositionManager,
            isScrolling, // The Grid is currently being scrolled
            rowSizeAndPositionManager, // @see CellSizeAndPositionManager,
            rowStartIndex, // Index of first row (inclusive) to render
            rowStopIndex, // Index of last row (inclusive) to render
        } = props;

        const renderedCells: any[] = [];
        const areOffsetsAdjusted =
            columnSizeAndPositionManager.areOffsetsAdjusted() ||
            rowSizeAndPositionManager.areOffsetsAdjusted();

        const state = {
            cellCache,
            styleCache,
            canCacheStyle: !isScrolling && !areOffsetsAdjusted,
            expandedUserGroupHeight: 0,
            expandedUserGroupTop: null,
            pendingUser: null,
            filterByUserId: (filteringUserID: TUserID) => (tableDataID) =>
                tableDataID.includes(filteringUserID),
            rowIndex: 0,
            isLeftColumn:
                columnSizeAndPositionManager.getSizeAndPositionOfCell(0).size ===
                sizes.tableLeftColumnWidth,
            isTopRight: props.parent.props.className.includes('topRight'),
        };

        for (let rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
            state.rowIndex = rowIndex;
            this.setRowRenderedCells(renderedCells, props, state);
        }

        return renderedCells;
    };

    setRowRenderedCells = (renderedCells: any[], props, state): void => {
        const {
            columnStartIndex, // Index of first column (inclusive) to render
            columnStopIndex, // Index of last column (inclusive) to render
            rowSizeAndPositionManager,
            rowStopIndex,
        } = props;

        state.rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(state.rowIndex);

        const idExpression = this.getIDExpressionWithoutHeader(state.rowIndex);
        state.idParts = [];
        state.userAndExpandedItems = [];
        if (idExpression) {
            const { tableDataIDs } = this.props;
            state.idParts = idExpression.split('_');
            state.userID = state.idParts[0];
            state.userAndExpandedItems = tableDataIDs
                .slice(0, rowStopIndex + 2)
                .filter(state.filterByUserId(state.userID));
        }

        for (let columnIndex = columnStartIndex; columnIndex <= columnStopIndex; columnIndex++) {
            state.columnIndex = columnIndex;
            this.setColumnRenderedCells(renderedCells, props, state);
        }
    };

    setColumnRenderedCells = (renderedCells: any[], props, state): void => {
        const style = this.processAndGetStyles(props, state);
        const renderedCell = this.processAndGetRenderedCell(props, state, style);

        if (!renderedCell) {
            return;
        }

        const userChanged = state.pendingUser && state.pendingUser !== state.userID;
        const lastRow = props.rowStopIndex === state.rowIndex;
        const rowHeight = state.rowDatum.size || this.getRowHeight({ index: state.rowIndex });
        if (!state.userHeight) {
            // as first row is user
            state.userHeight = rowHeight;
        }

        const isDraggableArea = !state.isLeftColumn && !state.isTopRight;
        if (userChanged && isDraggableArea) {
            renderedCells.push(
                <UserWrapperOnDragComponent
                    height={state.expandedUserGroupHeight}
                    top={state.expandedUserGroupTop}
                    left={props.scrollLeft}
                    paddingFromTop={state.userHeight}
                    userID={state.pendingUser}
                    showProjectAndOrTasks={this.showProjectAndOrTasks}
                    withOverlay
                />
            );

            state.expandedUserGroupHeight = 0;
            state.expandedUserGroupTop = null;
        }

        if (renderedCell.props.role) {
            return;
        }
        state.expandedUserGroupHeight += rowHeight;
        renderedCells.push(
            React.cloneElement(renderedCell, {
                role: 'gridcell',
                className: state.isTopRight ? hideUnassignedScrollbarWhenCollapsed : '',
            })
        );

        if (state.userAndExpandedItems.length > 1) {
            if (state.expandedUserGroupTop === null) {
                state.expandedUserGroupTop = style.top;
            }

            if (state.idParts.length > 0) {
                state.pendingUser = state.userID;
            }

            if (state.pendingUser && lastRow && isDraggableArea) {
                renderedCells.push(
                    <UserWrapperOnDragComponent
                        height={state.expandedUserGroupHeight}
                        top={state.expandedUserGroupTop}
                        left={props.scrollLeft}
                        paddingFromTop={state.userHeight}
                        userID={state.pendingUser}
                        showProjectAndOrTasks={this.showProjectAndOrTasks}
                        withOverlay
                    />
                );

                state.expandedUserGroupHeight = 0;
                state.expandedUserGroupTop = null;
            }
        } else if (state.userID && isDraggableArea) {
            renderedCells.push(
                React.cloneElement(
                    <UserWrapperOnDragComponent
                        height={rowHeight}
                        top={style.top}
                        left={props.scrollLeft}
                        userID={state.userID}
                        showProjectAndOrTasks={this.showProjectAndOrTasks}
                    />
                )
            );
            state.expandedUserGroupHeight = 0;
            state.expandedUserGroupTop = null;
            state.pendingUser = null;
        }
    };

    processAndGetStyles = (props, state): any => {
        const {
            horizontalOffsetAdjustment, // Horizontal pixel offset (required for scaling)
            columnSizeAndPositionManager, // @see CellSizeAndPositionManager,
            deferredMeasurementCache,
            verticalOffsetAdjustment,
        } = props;
        const key = `${state.rowIndex}-${state.columnIndex}`;
        let style;

        if (state.canCacheStyle && state.styleCache[key]) {
            style = state.styleCache[key];
        } else if (
            deferredMeasurementCache &&
            !deferredMeasurementCache.has(state.rowIndex, state.columnIndex)
        ) {
            style = {
                height: 'auto',
                left: 0,
                position: 'absolute',
                top: 0,
                width: 'auto',
            };
        } else {
            const columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(
                state.columnIndex
            );
            style = {
                height: state.rowDatum.size,
                left: columnDatum.offset + horizontalOffsetAdjustment,
                position: 'absolute',
                top: state.rowDatum.offset + verticalOffsetAdjustment,
                width: columnDatum.size,
            };

            state.styleCache[key] = style;
        }

        return style;
    };

    processAndGetRenderedCell = (props, state, style): any => {
        const {
            cellRenderer, // Cell renderer prop supplied to Grid
            horizontalOffsetAdjustment, // Horizontal pixel offset (required for scaling)
            isScrolling,
            verticalOffsetAdjustment,
            isScrollingOptOut,
            visibleColumnIndices,
            visibleRowIndices,
        } = props;

        const isVisible =
            state.columnIndex >= visibleColumnIndices.start &&
            state.columnIndex <= visibleColumnIndices.stop &&
            state.rowIndex >= visibleRowIndices.start &&
            state.rowIndex <= visibleRowIndices.stop;
        const key = `${state.rowIndex}-${state.columnIndex}`;

        const cellRendererParams = {
            columnIndex: state.columnIndex,
            isScrolling,
            isVisible,
            key,
            // eslint-disable-next-line no-restricted-globals
            parent,
            rowIndex: state.rowIndex,
            style,
        };

        let renderedCell;

        if (
            (isScrollingOptOut || isScrolling) &&
            !horizontalOffsetAdjustment &&
            !verticalOffsetAdjustment
        ) {
            if (!state.cellCache[key]) {
                state.cellCache[key] = cellRenderer(cellRendererParams);
            }

            renderedCell = state.cellCache[key];
        } else {
            renderedCell = cellRenderer(cellRendererParams);
        }

        return renderedCell;
    };

    render(): JSX.Element {
        const {
            gridHeight,
            children,
            sectionType,
            dataIDs,
            onScroll,
            scrollLeft,
            projectGroupingMode,
            isInLoadingState,
            isAssignmentDialogOpen,
            tableMinWidth,
            setAreasData,
            areasData,
            leftSidePanelWidth,
            isErrorThrown,
        } = this.props;

        const rowCount = getRowCount(
            sectionType,
            gridHeight,
            projectGroupingMode,
            dataIDs.length,
            isInLoadingState
        );

        const hideScrollbar = getHideScrollbarClass(isInLoadingState, isAssignmentDialogOpen);

        const dragAndDropAdditionalProps = { cellRangeRenderer: this.cellRangeRenderer };

        const { assignedScrollWidth, unassignedScrollWidth } =
            getVerticalScrollWidthPerArea(areasData);

        const shouldScrollBarsPlaceholderBeAdded = shouldBodyHaveScrollBarPlaceholder(
            assignedScrollWidth,
            unassignedScrollWidth,
            Sections.PEOPLE_WORKLOAD
        );

        const shouldScrollBarVerticalPlaceholderBeAddedForHeaders =
            shouldHeaderHaveScrollBarPlaceholder(assignedScrollWidth, unassignedScrollWidth);

        const leftHorizontalBorderClass =
            hasLoadedDataIDs(this.props.dataIDs, sectionType) || isInLoadingState
                ? bottomRightGridLeftBorder
                : '';

        const { size } = areasData[Sections.PEOPLE_WORKLOAD];
        return (
            <div className={schedulingTableStyled}>
                <AutoSizer
                    className="grid-wrapper"
                    onResize={() => {
                        if (this.gridRef.current) {
                            this.gridRef.current.recomputeGridSize({ columnIndex: 1 });
                        }
                    }}
                >
                    {({ width }) => {
                        // Autosizer rounds up width with floating point values, here correction is done to avoid double scrollbar issues
                        const correctedWidth = width - 1;

                        return (
                            <>
                                <TableWidthSetter width={width} />
                                {isErrorThrown && (
                                    <ErrorPage
                                        tableViewportWidth={this.gridRef.current?.props.width}
                                        leftSidePanelWidth={leftSidePanelWidth}
                                        isInAssignedSection
                                    >
                                        <ErrorIcon color={primary.gray(400)} />
                                    </ErrorPage>
                                )}
                                {children}
                                <MultiGrid
                                    key={sectionType}
                                    aria-label={localizationClient.getTextSync(
                                        gridAriaLabel.messageKey,
                                        gridAriaLabel.fallBack
                                    )}
                                    {...dragAndDropAdditionalProps}
                                    cellRenderer={this.cellRenderer}
                                    enableFixedColumnScroll
                                    onScrollbarPresenceChange={(param) => {
                                        setAreasData({ ...param, type: Sections.PEOPLE_WORKLOAD });
                                    }}
                                    hideTopRightGridScrollbar
                                    hideBottomLeftGridScrollbar
                                    fixedColumnCount={1}
                                    fixedRowCount={1}
                                    onScroll={(params) => {
                                        if (!isInLoadingState) {
                                            /**
                                             * These functions are called on first render,
                                             * but that is unnecessary
                                             * */
                                            this.props.onScrollHandler(params);
                                            onScroll(params);
                                        }
                                    }}
                                    scrollLeft={scrollLeft}
                                    estimatedColumnSize={2}
                                    columnWidth={({ index: columnIndex }): number => {
                                        recomputeGridColumnWidthOnStepUnitChange(
                                            tableMinWidth,
                                            this.gridRef.current
                                        );
                                        return getWidthPerColumn(
                                            columnIndex,
                                            tableMinWidth,
                                            width,
                                            leftSidePanelWidth,
                                            size
                                        );
                                    }}
                                    columnCount={2}
                                    rowCount={rowCount}
                                    classNameTopRightGrid={cx(
                                        'topRight',
                                        shouldScrollBarVerticalPlaceholderBeAddedForHeaders
                                            ? scrollBarVerticalPlaceholder
                                            : ''
                                    )}
                                    classNameBottomLeftGrid={hideScrollbar}
                                    classNameBottomRightGrid={cx(
                                        `${sectionType}_BottomRightGrid`,
                                        resetOutline,
                                        gridForAssigned,
                                        hideScrollbar,
                                        leftHorizontalBorderClass,
                                        setRightOverlayWidth(
                                            width - assignedScrollWidth,
                                            leftSidePanelWidth
                                        ),
                                        shouldScrollBarsPlaceholderBeAdded
                                            ? scrollBarsPlaceholder
                                            : zIndexForErrorPage
                                    )}
                                    height={gridHeight <= 0 ? 0 : gridHeight}
                                    onSectionRendered={this.props.sectionRenderedHandler}
                                    rowHeight={this.getRowHeight}
                                    width={correctedWidth}
                                    ref={this.gridRef}
                                />
                            </>
                        );
                    }}
                </AutoSizer>
            </div>
        );
    }
}

export const SchedulingTableAssigned = connect<
    ISchedulingTableAssignedStateProps,
    Record<string, never>,
    ISchedulingTableAssignedProps,
    IWorkSchedulingCombinedState
>((state) => {
    return {
        getUserTasksById: getUserTasks(state),
        internalEventEmitter: internalEventEmitterSelector(state),
        isActualProgressBarEnabledInSettings: showActualProgressSelector(state),
        projectGroupingMode: projectGroupingModeSelector(state),
        unassignedWorkHeight: unassignedWorkHeightSelector(state),
        unassignedWorkEmptyRowHeight: state.Data.unassignedWorkEmptyRowHeight,
        dropdownOpened: dropdownOpenedSelector(state),
        assignmentDialogDetails: assignmentDialogDetailsSelector(state),
        contouringRowID: contouringRowIdSelector(state),
        isAllocationsVisualizationOn: showAllocationsVisualizationSelector(state),
        startDate: startDateSelector(state),
        schedulingAreaData: getSchedulingAreaData(state),
        peopleWorkLoadHeight: peopleWorkLoadHeightSelector(state),
        projectColorsMode: projectColorsModeSelector(state),
        tableDataIDs: tableDataIDsSelector(state),
        tableDataIDsForUnassignedSection: tableDataIDsForUnassignedSectionSelector(state),
        inHighlightingMode: highlightingModeSelector(state),
        getNodeIDS: getNodeIDs(state),
        isErrorThrown: isErrorThrownSelector(state, Sections.PEOPLE_WORKLOAD),
    };
})(SchedulingTableAssignedDisconnected);
