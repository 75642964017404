import { Project, Task } from 'workfront-objcodes';
import { Sections } from '../../../../constants/schedulingTableConstants';
import toggleLoading from '../../dataLoadingActions/toggleLoading';
import removeProjectsAndTasks from '../../sharedActions/removeProjectsAndTasks';
import removeTableDataIDsForUnassignedSection from '../../unassignedDataActions/removeTableDataIDsForUnassignedSection';
import changeUnassignedDataRequestMode from '../../unassignedDataActions/changeUnassignedDataRequestMode';
import removeExpandedFlagsForUnassignedSection from '../../unassignedDataActions/removeExpandedFlagsForUnassignedSection';

export const unassignedSectionResetActions = [
    toggleLoading(Sections.UNASSIGNED_WORK, true),
    removeProjectsAndTasks(Sections.UNASSIGNED_WORK),
    removeTableDataIDsForUnassignedSection(''),
    changeUnassignedDataRequestMode(false, Project),
    changeUnassignedDataRequestMode(false, Task),
    removeExpandedFlagsForUnassignedSection(),
];

export const resetUnassignedSectionData = () => unassignedSectionResetActions;
