import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'REMOVE_INACCESSIBLE_PROJECT';

interface IRemoveInaccessibleProjectActionPayload {
    userID: string;
    projectID: string;
}

type TRemoveInaccessibleProjectAction =
    TModernSchedulingAction<IRemoveInaccessibleProjectActionPayload>;

const removeInaccessibleProject = (userID, projectID): TRemoveInaccessibleProjectAction => ({
    type: ActionType,
    payload: {
        userID,
        projectID,
    },
});
export default removeInaccessibleProject;

export function handle(
    state: IDataState,
    { payload }: TRemoveInaccessibleProjectAction
): IDataState {
    const users = { ...state.users };
    const inaccessibleProjectIDs = [...users[payload.userID].inaccessibleProjectIDs];

    inaccessibleProjectIDs.splice(inaccessibleProjectIDs.indexOf(payload.projectID), 1);

    users[payload.userID] = {
        ...users[payload.userID],
        inaccessibleProjectIDs,
    };

    return {
        ...state,
        users,
    };
}
