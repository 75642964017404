import * as React from 'react';
import { connect } from 'react-redux';
import { Sections } from '../../../../constants/schedulingTableConstants';
import { isErrorThrownSelector } from '../../../../data-flow/errorData/errorDataSelector';
import { LeftCellProjectLoading } from '../LeftCellProjectLoading';
import { PeopleWorkloadSectionContent } from './PeopleWorkloadSectionContent';
import { UserLoading } from './UserLoading';
import { THandleProjectArrowClick, TOnUserArrowClick } from '../../../../data-flow/data/IDataState';
import {
    IGeneralStateTypes,
    TShowProjectAndOrTasks,
} from '../../../../data-flow/IGeneralStateTypes';
import { projectGroupingModeSelector } from '../../../../data-flow/settings/settingsSelector';
import { isInLoadingStatePeopleWorkloadSectionSelector } from '../../../../data-flow/data/selectors/isInLoadingState/isInLoadingStateSelectors';

interface IPeopleWorkloadSectionLeftCellProps extends React.HTMLAttributes<HTMLDivElement> {
    idExpression: string;
    onUserArrowClick: TOnUserArrowClick;
    projectGrouping: boolean;
    showProjectAndOrTasks: TShowProjectAndOrTasks;
    handleProjectArrowClick: THandleProjectArrowClick;
}

type IPeopleWorkloadSectionLeftCellStateToProps = Pick<
    IGeneralStateTypes,
    'isInLoadingStatePeopleWorkloadSection' | 'projectGrouping'
> & {
    isErrorThrown: boolean;
};

export const PeopleWorkloadSectionLeftCellPropsComponent = React.memo<
    IPeopleWorkloadSectionLeftCellProps & IPeopleWorkloadSectionLeftCellStateToProps
>((props) => {
    const {
        idExpression,
        isInLoadingStatePeopleWorkloadSection,
        onUserArrowClick,
        projectGrouping,
        showProjectAndOrTasks,
        handleProjectArrowClick,
        isErrorThrown,
    } = props;
    let component;
    const loadingRow = idExpression && idExpression.split('_').indexOf('loading') !== -1;

    if (isErrorThrown) {
        return null;
    }
    if (isInLoadingStatePeopleWorkloadSection) {
        component = <UserLoading />;
    } else if (loadingRow) {
        if (idExpression.split('_')[0].length > 1) {
            component = <LeftCellProjectLoading />;
        } else {
            component = <UserLoading isLazyLoaded />;
        }
    } else {
        component = (
            <PeopleWorkloadSectionContent
                idExpression={idExpression}
                onUserArrowClick={onUserArrowClick}
                projectGroupingMode={projectGrouping}
                showProjectAndOrTasks={showProjectAndOrTasks}
                handleProjectArrowClick={handleProjectArrowClick}
            />
        );
    }
    return component;
});

function mapStateToProps(state): IPeopleWorkloadSectionLeftCellStateToProps {
    return {
        isInLoadingStatePeopleWorkloadSection: isInLoadingStatePeopleWorkloadSectionSelector(state),
        projectGrouping: projectGroupingModeSelector(state),
        isErrorThrown: isErrorThrownSelector(state, Sections.PEOPLE_WORKLOAD),
    };
}

export const PeopleWorkloadSectionLeftCell = connect(mapStateToProps)(
    PeopleWorkloadSectionLeftCellPropsComponent
);
