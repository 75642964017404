import { Api, ResponseHandler } from 'workfront-api';
import { getXSRFToken } from 'workfront-cookie';

const getApi: { (): Api; instance?: Api } = function () {
    if (!getApi.instance) {
        getApi.instance = new Api({
            url: document.location.origin,
            version: 'internal',
        });
    }

    getApi.instance.setXSRFToken(getXSRFToken());

    return getApi.instance;
};
export { getApi };

export function getStandardHeaders() {
    const headers = new Headers();
    headers.append('Content-type', 'application/x-www-form-urlencoded');
    headers.append('X-Requested-With', 'XMLHttpRequest');
    headers.append('X-XSRF-TOKEN', getXSRFToken()!);

    return headers;
}

export function makeControllerCall<T = any>(
    endPoint: string,
    method?: 'GET' | 'POST',
    data?: { [key: string]: any }
): Promise<T> {
    const headers = getStandardHeaders();

    const options = {
        method: method || 'GET',
        credentials: 'same-origin',
        headers,
    } as RequestInit;

    if (data) {
        const bodyParts = Object.keys(data).map(
            (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        );
        if (options.method === 'GET') {
            endPoint += `?${bodyParts.join('&')}`;
        } else {
            options.body = bodyParts.join('&');
        }
    }

    return fetch(`/internal${endPoint.charAt(0) === '/' ? '' : '/'}${endPoint}`, options).then(
        ResponseHandler.success
    );
}
