import { Sections, sizes } from '../../constants/schedulingTableConstants';
import { DAYS_COUNT_IN_WEEK, WIDGET_RIGHT_FOR_QS } from '../../constants/dataConstatnts';
import { getContextValue } from '../../contexts/checkContext';
import { UnitOfTime } from '../../data-flow/types';
import { isInProjectArea } from '../../util/utilities';
import { getPeriodModesValues } from '../../util/periodUtils/periodUtils';
import { MONTH_COUNT_IN_PERIOD } from '../../constants/periodEnums';
import { isDateChanged } from '../../util/actionsComponentService';
import { TDraggingFromArea } from '../../data-flow/data/IDataState';
import { ICompletenessStatusChanged } from '../../data-flow/thunks/changeAssignmentsThunk';

export const calculateTableMinWidth = (
    activePeriodMode: UnitOfTime,
    periodMode: number,
    tableLeftSidPanelWidth: number
): number => {
    const { isDayMode, isWeekMode } = getPeriodModesValues(activePeriodMode);

    if (isDayMode) {
        return (
            tableLeftSidPanelWidth +
            sizes.cellMinWidthDayModeRedesign * DAYS_COUNT_IN_WEEK * periodMode +
            1
        );
    }
    if (isWeekMode) {
        return tableLeftSidPanelWidth + sizes.cellMinWidthWeeksMode * periodMode + 1;
    }
    return tableLeftSidPanelWidth + sizes.cellMinWidthMonthMode * MONTH_COUNT_IN_PERIOD;
};

export const canShowRoleSummary = (
    isRoleSummaryOpen: boolean,
    schedulingAreaObjCode: string
): boolean => {
    return isRoleSummaryOpen && isInProjectArea(schedulingAreaObjCode);
};

export const isWidgetOpen = (
    isFilterOpen: boolean,
    showRoleSummary: boolean,
    isSettingPanelOpened: boolean,
    isBulkAssignmentPanelOpened: boolean,
    isMinixOpened: boolean
): boolean => {
    return (
        isFilterOpen ||
        showRoleSummary ||
        isSettingPanelOpened ||
        isBulkAssignmentPanelOpened ||
        isMinixOpened
    );
};

export const getWidgetWidth = (
    widgetOpen: boolean,
    showRoleSummary: boolean,
    isSettingPanelOpened: boolean,
    isBulkAssignmentPanelOpened: boolean,
    isMinixOpened: boolean,
    filterContainerWidth: number
): number => {
    const minixWidthDifferenceFromStandardPanelSize = isMinixOpened ? 14 : 0;
    const widthDependingOnQS_RR =
        filterContainerWidth + WIDGET_RIGHT_FOR_QS - minixWidthDifferenceFromStandardPanelSize;
    return isWidgetOpen(
        widgetOpen,
        showRoleSummary,
        isSettingPanelOpened,
        isBulkAssignmentPanelOpened,
        isMinixOpened && !getContextValue('sharableLink')
    ) && widthDependingOnQS_RR > 0
        ? widthDependingOnQS_RR
        : 0;
};

export const isMinixUpdated = (type: string): boolean => type === 'minixUpdated';

export const hasAssignmentOrTeamAssignment = (updatedData): boolean | undefined => {
    return (
        updatedData &&
        (updatedData.assignments !== undefined ||
            updatedData.teamAssignment !== undefined ||
            updatedData.teamAssignments !== undefined)
    );
};

export const getCompletenessStatusChanged = (
    updatedData,
    oldData
): ICompletenessStatusChanged | undefined => {
    let completenessStatusChanged;
    if (
        (updatedData && 'actualCompletionDate' in updatedData) ||
        (oldData && 'actualCompletionDate' in oldData)
    ) {
        completenessStatusChanged = {
            changed: true,
            newValue:
                updatedData && updatedData.actualCompletionDate
                    ? updatedData.actualCompletionDate
                    : undefined,
        };
    }

    return completenessStatusChanged;
};

export const oldOrUpdatedDataExists = (data): boolean => data && data.oldData && data.updatedData;

export const isPlannedCompletionOrStartDatesChanged = (oldData, updatedData): boolean => {
    return (
        isDateChanged(oldData.plannedCompletionDate, updatedData.plannedCompletionDate) ||
        isDateChanged(oldData.plannedStartDate, updatedData.plannedStartDate)
    );
};

export const canShowUnassignedDropOverlay = (
    draggingFromArea: TDraggingFromArea,
    isUnassignedSectionCollapsed: boolean
): boolean => !isUnassignedSectionCollapsed && draggingFromArea === Sections.PEOPLE_WORKLOAD;
