export const resizeLineZIndex = 6;

// phoenix tooltip container,
// do not know why our scheduling has the same ID as phoenix tooltip container, so styles are applied
export const filterContainerCommonZIndex = 1002;

export const allocationDropdownWrapperZIndex = filterContainerCommonZIndex + 1; // 1003

export const tableHeaderTopRow = resizeLineZIndex + 1; // 7
export const dateRangePickerZIndex = tableHeaderTopRow + 1; // 8
export const tableHeaderRow = 3;

export const beforePeriodZIndex = 3;
export const unassignedDropOverlayZIndex = beforePeriodZIndex + 1; // 4

export const draggableItemZIndex = 999;
export const userDropOverlayZIndex = draggableItemZIndex + 1; // 1000
export const userDropOverlayBorderZIndex = userDropOverlayZIndex + 1; // 1001
export const widgetTransparentBgZIndex = 33;
export const widgetBottomLineZIndex = widgetTransparentBgZIndex + 22;
export const doneMarkZIndex = 8;
export const editableAssignmentZIndex = 7;
export const projectedAssignmentZIndex = 6;
export const projectedAssignmentArrowsZIndex = 5;
