import { primary } from '@phoenix/all';
import { css } from 'react-emotion';
import { thresholdForLargeScreen } from '../schedulingTable/header/TimelineDateRangePicker/TimelineDateRangePicker';
import { headerButtonStyles } from './headerComponentStyles';

export const timelineStyles = css`
    display: inherit;
`;

export const timelineHeaderStyles = css`
    color: ${primary.gray(600)} !important;
    padding-left: 8px;
    :hover {
        background-color: ${primary.gray(100)};
    }
`;

export const dropdownButtonStyles = css`
    ${headerButtonStyles};
    ${timelineHeaderStyles};
    width: auto;
    padding-right: 28px;
    padding-left: 6px;

    span {
        top: 0;
        bottom: 0;
        margin: auto;
    }
`;

export const periodModeDropdownStyles = css`
    &[selector='global_scheduling.period_mode'] {
        ${dropdownButtonStyles}
    }
`;

// doing the same with classname brought incorrect styles
export const timelinePeriodStyles = css`
    &[selector='global_scheduling.timeline_period'] {
        ${dropdownButtonStyles}
        min-width: 92px;
    }
`;

export const timelinePeriodDropDownOpenedStyles = css`
    &[selector='global_scheduling.timeline_period'],
    &[selector='global_scheduling.period_mode'],
    &[selector='project-colors-settings-dropdown'] {
        background-color: ${primary.gray(200)} !important;
        border-color: ${primary.gray(200)} !important;
    }
`;

export const timelinePeriodDropDownClosedStyles = css`
    &[selector='global_scheduling.timeline_period'],
    &[selector='global_scheduling.period_mode'],
    &[selector='project-colors-settings-dropdown'] {
        background-color: ${primary.white()} !important;
    }
`;

export const timelineDateRangePickerStyles = css`
    position: absolute;
    margin-left: 8px;
    margin-top: 3px;
    width: 228px;
`;

export const timelineDateRangePickerButtonStyles = css`
    ${headerButtonStyles}
    ${timelineHeaderStyles};

    align-items: center;
    line-height: 30px;
    padding-left: 6px;
`;

export const hideOnSmallScreen = css`
    @media (max-width: ${thresholdForLargeScreen - 1}px) {
        display: none;
    }
`;

export const timelineDateRangePickerIconStyles = css`
    @media (min-width: ${thresholdForLargeScreen}px) {
        display: none;
    }
`;

export const dateRangePickerOpenedStyles = css`
    &[data-testid='global_scheduling.date_range_picker.header'] {
        background-color: ${primary.gray(200)} !important;
        border-color: ${primary.gray(200)} !important;
    }
    &:focus {
        outline: 0;
    }
`;

export const dateRangePickerClosedStyles = css`
    &[data-testid='global_scheduling.date_range_picker.header'] {
        background-color: ${primary.white()} !important;
        &:hover {
            background-color: ${primary.gray(100)} !important;
        }
    }
`;

export const timelineDateRangePickerCaratStyles = css`
    margin-left: 4px;
    width: 16px;
    height: 16px;
    margin-right: 0px;
    top: 7px;
    align-self: center;
    line-height: 100%;
    vertical-align: middle;
`;
