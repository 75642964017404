import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'CHANGE_USERS_DATA_REQUESTS_STATE';

interface IChangeUsersDataRequestsStatePayload {
    usersIDs: string[];
    isRequestSend: boolean;
    isDataLoaded: boolean;
}

type TUsersDataRequestsStateAction = TModernSchedulingAction<IChangeUsersDataRequestsStatePayload>;

const changeUsersDataRequestsState = (
    usersIDs,
    isRequestSend,
    isDataLoaded
): TUsersDataRequestsStateAction => ({
    type: ActionType,
    payload: { usersIDs, isRequestSend, isDataLoaded },
});
export default changeUsersDataRequestsState;

export function handle(
    state: IDataState,
    { payload }: TModernSchedulingAction<IChangeUsersDataRequestsStatePayload>
): IDataState {
    const users = { ...state.users };

    payload.usersIDs.forEach((userID) => {
        users[userID].userDataRequestsState = {
            isRequestSend: payload.isRequestSend,
            isDataLoaded: payload.isDataLoaded,
        };
    });

    return {
        ...state,
        users,
    };
}
