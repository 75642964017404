import { primary } from '@phoenix/all';
import * as React from 'react';
import styled from 'react-emotion';
import { HourChild, hourSharedStyles } from '../../../../styles';
import { convertMinuteToHour, getHourToShow } from '../../../../util/utilities';

interface INoAllocationProps extends React.HTMLAttributes<HTMLDivElement> {
    hourPerDay: number;
    workingHours: number;
    isInAssignmentProcess: boolean;
    showRemainingHours: boolean;
    className?: string;
    isHalfTimeOff?: boolean;
    showAllocationsInHoursMode: boolean;
}

export const NoAllocation: React.FunctionComponent<INoAllocationProps> = React.memo(
    ({
        hourPerDay,
        showRemainingHours,
        workingHours,
        isInAssignmentProcess,
        className,
        isHalfTimeOff,
        showAllocationsInHoursMode,
    }) => {
        const allocatedHours = convertMinuteToHour(hourPerDay);
        const totalAvailableHours = convertMinuteToHour(workingHours);

        if (showAllocationsInHoursMode) {
            const hour = getHourToShow(showRemainingHours, totalAvailableHours, allocatedHours);
            return (
                <NoAllocationStyled aria-label={`value is ${hour}`} className={className}>
                    <HourChild
                        isHalfTimeOff={isHalfTimeOff}
                        isInAssignmentProcess={isInAssignmentProcess}
                    >
                        {hour}
                    </HourChild>
                </NoAllocationStyled>
            );
        }
        const percent = showRemainingHours ? '-100%' : '0%';
        return (
            <NoAllocationStyled aria-label={`value is ${percent}`} className={className}>
                <HourChild
                    isHalfTimeOff={isHalfTimeOff}
                    isInAssignmentProcess={isInAssignmentProcess}
                >
                    {percent}
                </HourChild>
            </NoAllocationStyled>
        );
    }
);

export const NoAllocationStyled = styled('div')`
    ${hourSharedStyles};
    z-index: 3;
    height: 30px;
    width: 100%;
    border: 1px solid ${primary.blue(100)};
    background-color: transparent;
    color: ${primary.gray(400)};
`;
