import { Avatar, Button, Tooltip, primary } from '@phoenix/all';
import DownCaratIcon from 'phoenix-icons/dist/DownCaratIcon.js';
import RightCaratIcon from 'phoenix-icons/dist/RightCaratIcon.js';
import * as React from 'react';
import styled, { css, cx } from 'react-emotion';
import { connect } from 'react-redux';
import ProjectHeroIcon from 'phoenix-icons/dist/ProjectHeroIcon.js';
import { systemScrollbarSize } from '../../../../constants/schedulingTableConstants';
import changeUserNodesOffset from '../../../../data-flow/data/assignedDataActions/changeOffset';
import removeTableDataIDs from '../../../../data-flow/data/assignedDataActions/removeTableDataIDs';
import {
    TOnUserArrowClick,
    TUserID,
    TUserIsInAssignmentProcess,
} from '../../../../data-flow/data/IDataState';
import changeNodeArrowState from '../../../../data-flow/data/nodeItemActions/changeNodeArrowState';
import setHeightForPeopleWorkloadEmptyRow from '../../../../data-flow/data/assignedDataActions/setHeightForPeopleWorkloadEmptyRow';
import { usersOnProjectSelector } from '../../../../data-flow/data/selectors/dataSelectors';
import actionChain from '../../../../data-flow/higher-order-reducers/actionChain';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../../data-flow/types';
import { getByMessageKeySync, getSafeLabel, prepareTestId } from '../../../../util/utilities';
import { localizationClient } from '../../../../constants/LocalizationClientFactory';
import {
    IGeneralStateTypes,
    TShowProjectAndOrTasks,
} from '../../../../data-flow/IGeneralStateTypes';
import { getUserSelector } from '../../../../data-flow/data/selectors/users/usersSelector';
import {
    projectGroupingModeSelector,
    showActualProgressSelector,
    showAllUsersSelector,
} from '../../../../data-flow/settings/settingsSelector';
import { peopleWorkLoadHeightSelector } from '../../../../data-flow/tableSizes/selectors/tableSizesSelectors';
import { isInAssignmentProcessSelector } from '../../../../data-flow/data/selectors/reselect/isInAssignmentProcessSelector';
import { getSchedulingAreaData } from '../../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { getContextValue } from '../../../../contexts/checkContext';
import { getRoleByIdSelector } from '../../../../data-flow/data/selectors/reselect/getRoleByIdSelector/getRoleByIdSelector';
import { tableDataIDsSelector } from '../../../../data-flow/data/selectors/tableDataIDsSelector';
import { isProjectAccessible } from '../../utils';

export interface IUserDataProps {
    userID: TUserID;
    onUserArrowClick: TOnUserArrowClick;
    showProjectAndOrTasks: TShowProjectAndOrTasks;
}

type IUserDataStateToProps = Pick<
    IGeneralStateTypes,
    | 'userData'
    | 'roleName'
    | 'projectGrouping'
    | 'showActualProgress'
    | 'peopleWorkLoadHeight'
    | 'isInAssignmentProcess'
    | 'schedulingAreaData'
    | 'tableDataIDs'
> & {
    usersOnProject: string[];
    showAllUsers: boolean;
};

export const testIds = {
    userOnProject: 'user-on-this-project',
};

type TUserNodeComponentProps = IUserDataProps & IUserDataStateToProps & IWorkSchedulingDispatchProp;

class UserNodeComponent extends React.PureComponent<TUserNodeComponentProps> {
    setHeightForPeopleWorkSection = (chainedActions): void => {
        if (systemScrollbarSize > 0) {
            chainedActions.push(
                setHeightForPeopleWorkloadEmptyRow(
                    this.props.peopleWorkLoadHeight,
                    this.props.showActualProgress,
                    this.props.projectGrouping
                )
            );
        }
    };

    handleArrowClick = (): void => {
        const {
            userData,
            showProjectAndOrTasks,
            projectGrouping,
            tableDataIDs,
            dispatch,
            onUserArrowClick,
        } = this.props;
        const chainedActions: any = [changeNodeArrowState(userData.ID, undefined)];

        if (!userData.expanded) {
            showProjectAndOrTasks(userData);
        } else {
            if (projectGrouping) {
                const openProjects = tableDataIDs.filter((key) => {
                    return isProjectAccessible(key);
                });
                openProjects.forEach((idExpression) =>
                    chainedActions.push(changeUserNodesOffset(idExpression, 0))
                );
            }
            chainedActions.push(
                changeNodeArrowState(userData.ID, undefined, {
                    needToDeleteExpandedProjectsIDs: true,
                }),
                removeTableDataIDs(`${userData.ID}_`),
                changeUserNodesOffset(userData.ID, 0)
            );
            this.setHeightForPeopleWorkSection(chainedActions);
        }

        dispatch(actionChain(chainedActions));

        onUserArrowClick(userData.ID);
    };

    render(): JSX.Element {
        const {
            userData,
            roleName,
            isInAssignmentProcess,
            schedulingAreaData,
            userID,
            usersOnProject,
            showAllUsers,
        } = this.props;
        const { schedulingAreaObjCode } = schedulingAreaData;
        const userName = getSafeLabel(userData.name);

        return (
            <UserStyled
                tabIndex={0}
                hasAssignments={userData.hasAssignments}
                isInAssignmentProcess={isInAssignmentProcess}
                data-testid={userData.ID}
            >
                {userData.hasAssignments && (
                    <Button
                        text
                        className={arrowCaret}
                        onClick={this.handleArrowClick}
                        data-testid={prepareTestId(
                            userData.expanded ? 'user_collapse' : 'user_expand',
                            getContextValue('sharableLink'),
                            schedulingAreaObjCode
                        )}
                        aria-expanded={userData.expanded}
                        aria-label={`${localizationClient.getTextSync(
                            'user',
                            'User'
                        )}: ${userName}`}
                        disabled={isInAssignmentProcess}
                    >
                        {userData.expanded ? (
                            <DownCaratIcon color={primary.gray(500)} height={16} width={16} />
                        ) : (
                            <RightCaratIcon color={primary.gray(500)} height={16} width={16} />
                        )}
                    </Button>
                )}
                <div className={cx(avatarWrapper, 'avatar-wrapper')}>
                    <Avatar
                        showLabel
                        label={userName}
                        subLabel={roleName}
                        showTooltip={false}
                        avatarURL={
                            this.props.userData.avatarDate
                                ? `/internal/user/avatar?ID=${this.props.userData.ID}&size=SMALL`
                                : ''
                        }
                    />
                    {/* this element for describing table cell */}
                    <HiddenElement id={userID}>{userName}</HiddenElement>
                </div>
                {showAllUsers && usersOnProject.includes(userID) && (
                    <Tooltip
                        content={getByMessageKeySync(
                            'workloadbalancer.on.the.project',
                            'On the project'
                        )}
                        delay={700}
                        position="top"
                        data-testid={testIds.userOnProject}
                        className={projectIcon}
                        icon={<ProjectHeroIcon />}
                    />
                )}
            </UserStyled>
        );
    }
}

function mapStateToProps(state: IWorkSchedulingCombinedState, ownProps): IUserDataStateToProps {
    const { userID } = ownProps;
    const userData = getUserSelector(state)(userID);

    return {
        userData,
        roleName: getRoleByIdSelector(state)(userData.roleID),
        projectGrouping: projectGroupingModeSelector(state),
        showActualProgress: showActualProgressSelector(state),
        peopleWorkLoadHeight: peopleWorkLoadHeightSelector(state),
        isInAssignmentProcess: isInAssignmentProcessSelector(state)(userID),
        schedulingAreaData: getSchedulingAreaData(state),
        tableDataIDs: tableDataIDsSelector(state),
        usersOnProject: usersOnProjectSelector(state),
        showAllUsers: showAllUsersSelector(state),
    };
}

export const UserNode = connect(mapStateToProps)(UserNodeComponent);

const getOpacity = (isInAssignmentProcess: TUserIsInAssignmentProcess): string => {
    return isInAssignmentProcess ? `opacity: 0.6 !important` : ``;
};

const UserStyled = styled('div')<any>`
    height: 100%;
    line-height: 100%;
    border-bottom: 1px solid ${primary.gray(200)};
    display: flex;
    align-items: center;
    position: relative;
    padding-left: ${(props) => (props.hasAssignments ? '0px' : '36px')};
    cursor: default;
    ${(props) => getOpacity(props.isInAssignmentProcess)};
    h5 {
        font-weight: 600 !important;
        color: ${primary.gray()} !important;
    }

    & .avatar-wrapper > div {
        & > div {
            > h5 {
                font-size: 14px;
            }
            > span {
                line-height: 1.25;
            }
        }
    }
`;

const projectIcon = css`
    position: absolute;
    right: 9px;
    top: 9px;
`;
const arrowCaret = css`
    padding: 0px 8px 0px 12px !important;
    outline: none;
    pointer-events: initial;
`;

const avatarWrapper = css`
    max-width: calc(100% - 80px);
`;

const HiddenElement = styled('span')`
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
`;
