import { css } from 'emotion';
import styled from 'react-emotion';
import { primary } from '@phoenix/all';
import { unassignedDropOverlayZIndex } from '../../../constants/zIndexes';
import { sizes } from '../../../constants/schedulingTableConstants';

export const notStuckToEdgeValue = 1;
export const UnassignedDropOverlayStyled = styled('div')<{ leftSidePanelWidth: number }>`
    z-index: ${unassignedDropOverlayZIndex};
    position: absolute;
    width: calc(100% - ${(props) => props.leftSidePanelWidth + notStuckToEdgeValue}px);
    height: calc(100% - ${sizes.tableHeaderRowHeight * 2}px);
    top: ${sizes.tableHeaderRowHeight * 2}px;
    left: ${(props) => props.leftSidePanelWidth}px;
    border: 2px dashed ${primary.blue(400)};
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

export const dropOverlayTextStyle = css`
    font-weight: 600;
`;
