import * as React from 'react';
import { connect } from 'react-redux';
import { Sections } from '../../../../constants/schedulingTableConstants';
import {
    IProjectColor,
    TOnUnassignedProjectArrowClick,
} from '../../../../data-flow/data/IDataState';
import { tableDataIDsForUnassignedSectionSelector } from '../../../../data-flow/data/selectors/dataSelectors';
import changeUnassignedNodeArrowState from '../../../../data-flow/data/unassignedDataActions/changeUnassignedNodeArrowState';

import actionChain from '../../../../data-flow/higher-order-reducers/actionChain';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../../data-flow/types';
import { ProjectNodeHTML } from '../ProjectNodeHTML/ProjectNodeHTML';
import { projectGroupingModeSelector } from '../../../../data-flow/settings/settingsSelector';
import { unassignedWorkHeightSelector } from '../../../../data-flow/tableSizes/selectors/unassignedWorkHeightSelector';
import { getSchedulingAreaData } from '../../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import {
    projectColorDeciderSelector,
    projectStatusLabelSelector,
} from '../../../../data-flow/data/selectors/projectStatusSelectors/projectStatusSelectors';
import { arrowCollapsedStateActions, arrowExpandedStateActions } from '../../utils';

interface IProjectNodeComponents {
    isThereUnassignedEmptySpace: boolean;
    unassignedWorkHeight: number;
    projectGroupingMode: boolean;
    name: string;
    isAccessible: boolean;
    expanded: boolean;
    nodesIDs: string[]; // here this is the tasks IDs
    isBottomLineHidden: boolean;
    isLastRow: boolean;
    projectColoredData?: IProjectColor;
    projectStatusLabel?: string;
    schedulingAreaObjCode: string | undefined;
}

export interface IProjectNodeComponentProps extends IWorkSchedulingDispatchProp {
    idExpression: string;
    sectionType: Sections;
    projectID: string;
    onUnassignedProjectArrowClick: TOnUnassignedProjectArrowClick;
}

export const ProjectNodeComponentUnassignedSection: React.FC<
    IProjectNodeComponents & IProjectNodeComponentProps
> = (props) => {
    const handleArrowClick = (): void => {
        const { expanded, projectID, dispatch, onUnassignedProjectArrowClick } = props;
        dispatch(changeUnassignedNodeArrowState(projectID));
        if (!expanded) {
            handleArrowClickForCollapsedState();
        } else {
            handleArrowClickForExpandedState();
        }
        onUnassignedProjectArrowClick(projectID);
    };

    const handleArrowClickForCollapsedState = (): void => {
        const { nodesIDs, idExpression, dispatch, unassignedWorkHeight, projectGroupingMode } =
            props;
        dispatch(
            actionChain(
                arrowCollapsedStateActions(
                    idExpression,
                    nodesIDs,
                    unassignedWorkHeight,
                    projectGroupingMode
                )
            )
        );
    };

    const handleArrowClickForExpandedState = (): void => {
        const { idExpression, dispatch, unassignedWorkHeight, projectGroupingMode } = props;

        dispatch(
            actionChain(
                arrowExpandedStateActions(idExpression, unassignedWorkHeight, projectGroupingMode)
            )
        );
    };

    return (
        <ProjectNodeHTML
            isBottomLineHidden={props.isBottomLineHidden}
            name={props.name}
            isAccessible={props.isAccessible}
            expanded={props.expanded}
            projectID={props.projectID}
            handleArrowClick={handleArrowClick}
            projectType="unassigned_Project"
            sectionType={Sections.UNASSIGNED_WORK}
            isLastRow={props.isLastRow}
            projectColoredData={props.projectColoredData}
            projectStatusLabel={props.projectStatusLabel}
            schedulingAreaObjCode={props.schedulingAreaObjCode}
        />
    );
};

function mapStateToProps(state: IWorkSchedulingCombinedState, ownProps): IProjectNodeComponents {
    const projectData = state.Data.unassignedTasksProjects[ownProps.projectID];
    const isProjectExpanded = projectData.details.expanded;
    const tableDataIdsForUnassignedSection = tableDataIDsForUnassignedSectionSelector(state);
    const isThereUnassignedEmptySpace = state.Data.unassignedWorkEmptyRowHeight > 0;
    const isLastRow =
        tableDataIdsForUnassignedSection.indexOf(ownProps.projectID) >=
        tableDataIdsForUnassignedSection.length - 1;
    const isBottomLineHidden = !isProjectExpanded && !isThereUnassignedEmptySpace && isLastRow;

    return {
        isLastRow,
        isBottomLineHidden,
        isThereUnassignedEmptySpace,
        projectGroupingMode: projectGroupingModeSelector(state),
        unassignedWorkHeight: unassignedWorkHeightSelector(state),
        name: projectData.details.name,
        isAccessible: true,
        nodesIDs: projectData.nodes,
        expanded: isProjectExpanded,
        projectColoredData: projectColorDeciderSelector(
            state,
            projectData.details.ID,
            projectData.details.groupID,
            projectData.details.status
        ),
        projectStatusLabel: projectStatusLabelSelector(
            state,
            projectData.details.groupID,
            projectData.details.status
        ),
        schedulingAreaObjCode: getSchedulingAreaData(state).schedulingAreaObjCode,
    };
}

export const ProjectNode = connect(mapStateToProps)(ProjectNodeComponentUnassignedSection);
