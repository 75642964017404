import { primary } from '@phoenix/all';
import * as React from 'react';
import { cx } from 'react-emotion';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import {
    workPerDayDailyMock,
    workPerDayWeeklyMock,
    workPerDayMonthMock,
} from '../../../../constants/chartLoadingMocks';
import { sizes } from '../../../../constants/schedulingTableConstants';
import { stepsSelector } from '../../../../data-flow/dateRange/selectors/stepsSelector';
import { IWorkSchedulingCombinedState, UnitOfTime } from '../../../../data-flow/types';
import { chartGetY, yScale } from '../../../../util/chartUtils';
import {
    animationStyle,
    cellWrapper,
    flexCell,
    greyLoadingCell,
    userRowStyleOnAllocationVisualization,
    whiteBackground,
} from '../../../styles/sharedStyles';
import UserFocusChartComponent from '../../chart/UserFocusChartComponent';
import { getPeriodModesValues } from '../../../../util/periodUtils/periodUtils';
import { stepUnitSelector } from '../../../../data-flow/dateRange/selectors/stepUnitSelector';
import { showAllocationsVisualizationSelector } from '../../../../data-flow/settings/settingsSelector';

interface IUserLoadingComponentProps {
    idExpression?: string;
}

interface IUserLoadingComponentStateProps {
    isAllocationsVisualizationOn: boolean;
    activePeriodMode: UnitOfTime;
    steps: Moment[];
}

export const testIds = {
    rightCellUserLoading: 'right-cell-user-loading',
    rightCellUserChartLoading: 'right-cell-user-chart--loading',
    rightCellUserNumberLoading: 'right-cell-user-number--loading',
};

export const getWorkPerDayMock = (activePeriodMode: UnitOfTime): number[] => {
    const { isDayMode, isWeekMode } = getPeriodModesValues(activePeriodMode);
    if (isDayMode) {
        return workPerDayDailyMock;
    }
    if (isWeekMode) {
        return workPerDayWeeklyMock;
    }
    return workPerDayMonthMock;
};

export const UserLoadingComponent: React.FunctionComponent<
    IUserLoadingComponentProps & IUserLoadingComponentStateProps
> = (props) => {
    const { steps, isAllocationsVisualizationOn, activePeriodMode, idExpression } = props;

    const height = sizes.tableRowHeightChartView;
    let prevWeight = 0;

    return (
        <div
            className={cx(isAllocationsVisualizationOn ? whiteBackground : '', cellWrapper)}
            data-testid={testIds.rightCellUserLoading}
        >
            {isAllocationsVisualizationOn
                ? steps.map((step, index) => {
                      const workPerDay = getWorkPerDayMock(activePeriodMode);
                      const scaledHeight = yScale(workPerDay[index], activePeriodMode);
                      const weight = chartGetY(height, scaledHeight);

                      const component = (
                          <div
                              key={step.unix()}
                              className={cx(
                                  userRowStyleOnAllocationVisualization(false),
                                  'user-row-style-on-allocation-visualization-selector',
                                  animationStyle
                              )}
                              data-testid={testIds.rightCellUserChartLoading}
                          >
                              <UserFocusChartComponent
                                  index={index}
                                  availableHour={workPerDay[index]}
                                  height={height}
                                  prevAvailableHour={workPerDay[index - 1]}
                                  prevWorkPerDay={workPerDay[index - 1]}
                                  scaledHeight={scaledHeight}
                                  weight={weight}
                                  workPerDay={workPerDay[index]}
                                  requiredLineColor={primary.gray(300)}
                                  requiredFillColor={primary.gray(200)}
                                  prevWeight={prevWeight}
                              />
                          </div>
                      );
                      prevWeight = weight;
                      return component;
                  })
                : steps.map((step) => (
                      <div
                          key={step.unix()}
                          className={cx(flexCell(), `assigned-cell-day_${idExpression}`)}
                          data-testid={testIds.rightCellUserNumberLoading}
                      >
                          <div className={greyLoadingCell} />
                      </div>
                  ))}
        </div>
    );
};

const mapStateToProps = (state: IWorkSchedulingCombinedState): IUserLoadingComponentStateProps => ({
    activePeriodMode: stepUnitSelector(state),
    steps: stepsSelector(state),
    isAllocationsVisualizationOn: showAllocationsVisualizationSelector(state),
});

export const UserLoading = connect(mapStateToProps)(UserLoadingComponent);
