import _ from 'lodash';
import { Moment } from 'moment';
import { usersTasksSortObject } from '../constants/dataConstatnts';
import { getInitialAreaDataState } from '../data-flow/areaData/getInitialAreaDataState';
import { INodesDetailsState, IUserState } from '../data-flow/data/IDataState';
import { isInProjectArea, sortObject, sortObjectByPlannedAndProjectedDates } from './utilities';
import { IFilterRuleGroup, IFilterRuleMetadataLocal } from '../data-flow/filters/IFiltersState';
import { OBJ_CODES } from '../constants/filters/filterOptions';
import { objCodeMapper } from './filters/filterUtil';

export const getUserNodesIDs = (
    nodesDetails: INodesDetailsState,
    schedulingAreaData = getInitialAreaDataState(),
    tasks = {},
    startDate?: Moment,
    taskOrIssueOnTopID?: string
): string[] => {
    const combinedNodesIDs: string[] = [];
    const { schedulingAreaObjCode, schedulingAreaID = '' } = schedulingAreaData;

    let skipAreaID = false;
    if (isInProjectArea(schedulingAreaObjCode) && nodesDetails[schedulingAreaID]) {
        // in case tasks is empty, area project id has higher priority anyway
        combinedNodesIDs.push(...nodesDetails[schedulingAreaID].nodes);
        skipAreaID = true;
    }

    _.forEach(nodesDetails, (nodes, ID) => {
        const shouldSkip = skipAreaID && ID === schedulingAreaID;
        if (!shouldSkip) {
            combinedNodesIDs.push(...nodes.nodes);
        }
    });

    if (!_.isEmpty(tasks)) {
        if (startDate) {
            combinedNodesIDs.sort(
                sortObjectByPlannedAndProjectedDates(tasks, usersTasksSortObject, startDate)
            );
        } else {
            combinedNodesIDs.sort(sortObject(tasks, usersTasksSortObject));
        }

        if (isInProjectArea(schedulingAreaObjCode)) {
            combinedNodesIDs.sort(sortAssignmentsByProjectID(tasks, schedulingAreaID));
        }

        if (taskOrIssueOnTopID) {
            combinedNodesIDs.sort(sortByID(tasks, taskOrIssueOnTopID));
        }
    }
    return combinedNodesIDs;
};

/**
 *
 * @param tasks
 * @param taskOrIssueID
 */
export const sortByID = (tasks, taskOrIssueID?: string) => {
    return (a, b) => {
        if (a === taskOrIssueID && b !== taskOrIssueID) {
            return -1;
        }
        if (a !== taskOrIssueID && b === taskOrIssueID) {
            return 1;
        }

        return 0;
    };
};

/**
 *
 * @param tasks
 * @param schedulingAreaID
 */
export const sortAssignmentsByProjectID = (tasks, schedulingAreaID?: string) => {
    return (a, b) => {
        const firstItemProjectId = tasks[a].projectID;
        const secondItemProjectId = tasks[b].projectID;
        if (firstItemProjectId === schedulingAreaID && secondItemProjectId !== schedulingAreaID) {
            return -1;
        }
        if (firstItemProjectId !== schedulingAreaID && secondItemProjectId === schedulingAreaID) {
            return 1;
        }

        return 0;
    };
};

export const getScheduleIDs = (
    users: { [id: string]: IUserState },
    usersIDs: string[],
    defaultSchedule: string
): Set<string> => {
    const scheduleIDs = new Set<string>();

    usersIDs.forEach((userID) => {
        scheduleIDs.add(users[userID].scheduleID || defaultSchedule);
    });

    return scheduleIDs;
};

export const checkHasRoleFilter = (filterExpression: IFilterRuleGroup | undefined): boolean => {
    let result = false;
    if (filterExpression) {
        result = filterExpression.rules.some((rule) => {
            const fieldMetaData = rule.searchFieldMeta as IFilterRuleMetadataLocal;
            return fieldMetaData.group.key === OBJ_CODES.ROLE.groupKey;
        });
    }
    return result;
};

export const getRoleIdsForUnassignedSection = (filterExpression: IFilterRuleGroup): string[] => {
    let roleAssignmentsIDs: string[] = [];

    filterExpression.rules.forEach((rule) => {
        const fieldMetaData = rule.searchFieldMeta as IFilterRuleMetadataLocal;
        const objCode = objCodeMapper[fieldMetaData.group.key];
        if (objCode === OBJ_CODES.ROLE.code) {
            roleAssignmentsIDs = roleAssignmentsIDs.concat(rule.searchValues);
        }
    });

    return roleAssignmentsIDs;
};
