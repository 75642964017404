import { css } from 'react-emotion';
import { primary } from '@phoenix/all';
import stripes from '../../../images/stripes.svg';
import { sizes } from '../../../constants/schedulingTableConstants';
import { positioningWrapper } from '../UserWrapperOnDrag/UserWrapperOnDragComponent';
import { notStuckToEdgeValue } from '../UnassignedDropOverlay/UnassignedDropOverlayStyles';
import { resizeLineZIndex } from '../../../constants/zIndexes';

export const grid = css`
    border-top: 0;
    .ReactVirtualized__Grid__innerScrollContainer {
        background: url(${stripes}) 0 0 repeat;
        z-index: 0;
    }
    will-change: initial !important;
`;

export const gridForAssigned = css`
    ${grid};
    .ReactVirtualized__Grid__innerScrollContainer {
        margin-left: -${sizes.gridLightGreyBorderWidth}px;
    }
`;

export const bottomRightGridLeftBorder = css`
    border-left: ${sizes.gridLightGreyBorderWidth}px solid ${primary.gray(200)} !important;
`;

export const schedulingTableStyled = css`
    min-height: 36px;
    *,
    *:after,
    *:before {
        box-sizing: border-box;
    }

    .grid-wrapper {
        height: 100% !important;
        width: 100% !important;
    }
`;

export const resetOutline = css`
    outline: none !important;
    &:focus {
        box-shadow: none !important;
    }
`;

export const hideScrollbar = css`
    overflow: hidden !important;
`;

export const setRightOverlayWidth = (width: number, leftSidePanelWidth: number): string => {
    return css`
        .${positioningWrapper} {
            width: ${width - leftSidePanelWidth - notStuckToEdgeValue}px;
        }
    `;
};

export const hideUnassignedScrollbarWhenCollapsed = css`
    background: ${primary.gray(50)};
`;

const resizeHandlerStyle = `
	{
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		height: 2px;
		width: 100%;
		z-index: 100;
		background: ${primary.blue(400)};
	}
	`;

const resizeHandlerHorizontalStyle = `
	{
		content: '';
		position: absolute;
		top: 0;
		height: 100%;
		width: 2px;
		z-index: 100;
		background: ${primary.blue(400)};
		right: 5px;
	}
`;

export const resizeHandlerDragging = css`
    &: after ${resizeHandlerStyle};
    z-index: ${resizeLineZIndex};
`;

export const resizeHandlerHorizontalHover = css`
	&:hover:after${resizeHandlerHorizontalStyle};
    &:active:after${resizeHandlerHorizontalStyle};
	z-index: ${resizeLineZIndex};
`;

export const resizeHandlerHover = css`
	&:hover:after${resizeHandlerStyle};
	z-index: ${resizeLineZIndex};
`;

export const resizeHorizontalLine = css`
    background: transparent;
    position: fixed !important;
    pointer-events: none;
    z-index: 10;

    & > span {
        pointer-events: auto;
    }
`;

export const zIndexForErrorPage = css`
    z-index: 1;
`;
export const leftSidePanelScroll = css`
    box-shadow: 8px 0px 12px -11px rgba(0, 35, 64, 0.32);
`;

export const scrollBarsPlaceholder = css`
    overflow-y: scroll !important;
`;

export const scrollBarVerticalPlaceholder = css`
    ${zIndexForErrorPage}
    overflow-y: scroll !important;
`;

export const getHorizontalScrollClass = (scrollbarSize: number): string => {
    return css`
        height: calc(100% + ${scrollbarSize}px) !important;
    `;
};
