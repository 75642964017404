import { IDateRangeState } from './IDateRangeState';
import { TimeUnit } from '../../constants/periodEnums';

export const ActionType = 'CHANGE_ACTIVE_PERIOD_MODE';

const changeActivePeriodMode = (activePeriodMode?: string) => ({
    type: ActionType,
    payload: activePeriodMode,
});

export default changeActivePeriodMode;

export function handle(state: IDateRangeState, action): IDateRangeState {
    const activePeriodMode = action.payload;

    return {
        ...state,
        stepUnit: activePeriodMode || TimeUnit.DAY,
    };
}
