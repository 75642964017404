import { IAccessLevel } from 'workfront-typescript-definitions';
import { TModernSchedulingAction } from '../../types';
import { ICurrentUserState } from '../ICurrentUserState';

export const ActionType = 'CHANGE_WORK_REQUIRED_PERMISSION';

interface IChangeWorkRequiredActionPayload {
    accessLevelData: IAccessLevel;
}

type TChangeWorkRequiredAction = TModernSchedulingAction<IChangeWorkRequiredActionPayload>;

const changeWorkRequiredPermission = (
    accessLevelData: IAccessLevel
): TChangeWorkRequiredAction => ({
    type: ActionType,
    payload: {
        accessLevelData,
    },
});
export default changeWorkRequiredPermission;

export function handle(
    state: ICurrentUserState,
    { payload }: TChangeWorkRequiredAction
): ICurrentUserState {
    const { accessLevelData } = payload;
    let hasWorkRequiredEditPermission = false;

    if (accessLevelData.isAdmin) {
        hasWorkRequiredEditPermission = true;
    } else if (accessLevelData.accessLevelPermissions) {
        hasWorkRequiredEditPermission = accessLevelData.accessLevelPermissions.some(
            (item) =>
                item.objObjCode === 'PLNHR' &&
                item.secondaryActions.indexOf('PLANNED_HOURS_CONTOURING') !== -1
        );
    }

    return {
        ...state,
        workRequiredEditPermission: hasWorkRequiredEditPermission,
    };
}
