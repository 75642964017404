import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { cx } from 'emotion';
import { AssigmentPositioningStyled } from '../../styles/allocationsComponentStyles';
import { AfterPeriod, assignmentDetails, beforePeriodStyle } from '../../styles/sharedStyles';
import { AssignmentStyled } from '../../styles/AssignmentComponentStyles';
import { checkIsPenultimateDayInView } from '../../../util/durationStylesCalculation';
import { isIssue } from '../../../util/utilities';
import { GlobalPropsContext } from '../../../contexts/globalContexts';
import { IAssignmentBarProps } from './AssignmentBar';
import {
    getAssignmentBackground,
    getAssignmentBackgroundColor,
    getAssignmentTextColor,
} from '../utils';
import { AssignmentTitle } from './AssignmentTitle';
import { IProjectColor } from '../../../data-flow/data/IDataState';

export interface IAssignmentBarSkeletonProps
    extends Pick<
        IAssignmentBarProps,
        | 'isDraggingEnabled'
        | 'isThereBeforeArrow'
        | 'isThereAfterArrow'
        | 'shouldMarkAsCompleted'
        | 'isComplete'
        | 'ID'
        | 'calculatedAssignmentWidth'
        | 'isAssigned'
        | 'sectionType'
        | 'isInDisableMode'
        | 'stepUnit'
        | 'assignmentWidth'
        | 'endDate'
        | 'nodeData'
    > {
    projectColoredData?: IProjectColor;
}

export const assignmentBarSkeletonDataTestIds = {
    beforePeriod: 'beforePeriod-skeleton',
    afterPeriod: 'afterPeriod-skeleton',
    assignmentContainer: (ID: string) => `${ID}-skeleton-container`,
    assignment: (ID: string) => `${ID}-skeleton`,
};

export const AssignmentBarSkeleton: React.FunctionComponent<IAssignmentBarSkeletonProps> =
    React.memo((props) => {
        const {
            nodeData,
            endDate,
            assignmentWidth,
            stepUnit,
            projectColoredData,
            isInDisableMode,
            sectionType,
            isAssigned,
            calculatedAssignmentWidth,
            ID,
            isComplete,
            shouldMarkAsCompleted,
            isDraggingEnabled,
            isThereBeforeArrow,
            isThereAfterArrow,
        } = props;

        const {
            objCode,
            name: title,
            plannedCompletionDate,
            areHoursNotEqualsBeforeContouringSave,
        } = nodeData;

        const divRef = useRef<HTMLDivElement>();
        useEffect(() => {
            const minimumWidth = 200;
            if (calculatedAssignmentWidth > minimumWidth) {
                requestAnimationFrame(() => {
                    if (divRef?.current) {
                        divRef.current.style.setProperty('width', `${minimumWidth}px`);
                    }
                });
            }
        }, [calculatedAssignmentWidth]);

        const globalPropsContext = React.useContext(GlobalPropsContext);

        const assignmentEndDate = moment(plannedCompletionDate);

        const canApplyNewColors = projectColoredData && !isIssue(objCode);

        const isPenultimateDayInView = checkIsPenultimateDayInView(
            stepUnit,
            endDate,
            assignmentEndDate
        );

        const assignmentColorBG = getAssignmentBackgroundColor(
            canApplyNewColors,
            projectColoredData,
            objCode,
            isInDisableMode
        );

        const assignmentBackground = getAssignmentBackground(
            canApplyNewColors,
            projectColoredData,
            assignmentColorBG,
            isInDisableMode,
            isAssigned,
            sectionType,
            objCode
        );

        const assignmentColorText = getAssignmentTextColor(canApplyNewColors, projectColoredData);

        const allocationsEditingMode = false;

        return (
            <AssigmentPositioningStyled
                allocationsEditingMode={allocationsEditingMode}
                areHoursNotEqualsBeforeContouringSave={areHoursNotEqualsBeforeContouringSave}
                data-testid={assignmentBarSkeletonDataTestIds.assignmentContainer(ID)}
            >
                {isThereBeforeArrow && (
                    <span
                        data-testid={assignmentBarSkeletonDataTestIds.beforePeriod}
                        className={cx(
                            beforePeriodStyle(assignmentColorBG, allocationsEditingMode, false),
                            'beforePeriodStyle'
                        )}
                    />
                )}
                {calculatedAssignmentWidth > 0 ? (
                    <AssignmentStyled
                        backGroundColor={assignmentBackground}
                        isThereBeforeArrow={isThereBeforeArrow}
                        isThereAfterArrow={isThereAfterArrow}
                        isPenultimateDayInView={isPenultimateDayInView}
                        isComplete={isComplete}
                        assignmentWidth={calculatedAssignmentWidth}
                        allocationsEditingMode={allocationsEditingMode}
                        showPointerCursor={!!globalPropsContext.minixState || isDraggingEnabled}
                        isDragging
                        innerRef={divRef}
                        data-testid={assignmentBarSkeletonDataTestIds.assignment(ID)}
                        IDExpressionForMinixState={null}
                        areHoursNotEqualsBeforeContouringSave={undefined}
                        idExpression=""
                        isInDisableMode={false}
                        isThreeDotItemClicked={false}
                        minixState={null}
                    >
                        <div className={assignmentDetails}>
                            <AssignmentTitle
                                assignmentColorText={assignmentColorText}
                                shouldMarkAsCompleted={shouldMarkAsCompleted}
                                title={title}
                                assignmentWidth={assignmentWidth}
                            />
                        </div>
                    </AssignmentStyled>
                ) : null}

                {isThereAfterArrow && (
                    <AfterPeriod
                        data-testid={assignmentBarSkeletonDataTestIds.afterPeriod}
                        borderColor={assignmentColorBG}
                        isInDisableMode={false}
                        className="afterPeriodStyle"
                    />
                )}
            </AssigmentPositioningStyled>
        );
    });
