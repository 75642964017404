export const TABLE_HEADER_KEYS = {
    work_collapse: {
        messageKey: 'workloadbalancer.collapse.assigned.section.tooltip',
        fallBack: 'Collapse all',
    },
    work_expand: {
        messageKey: 'workloadbalancer.expand.assigned.section.tooltip',
        fallBack: 'Expand all',
    },
    assigned_work_title: {
        messageKey: 'resourcescheduling.assigned.work',
        fallBack: 'Assigned Work',
    },
    unassigned_work_title: {
        messageKey: 'resourcescheduling.unassigned.work',
        fallBack: 'Unassigned Work',
    },
    unassigned_work_collapse: {
        messageKey: 'workloadbalancer.collapse.unassigned.section.tooltip',
        fallBack: 'Collapse unassigned work',
        ariaMessageKey: 'workloadbalancer.collapse.unassigned.section',
        ariaFallBack: 'Collapse unassigned work section',
    },
    unassigned_work_expand: {
        messageKey: 'workloadbalancer.expand.unassigned.section.tooltip',
        fallBack: 'Expand unassigned work',
        ariaMessageKey: 'workloadbalancer.expand.unassigned.section',
        ariaFallBack: 'Expand unassigned work section',
    },
};
