import { compose } from 'redux';

export const hasReduxDevTools = !!(
    typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
);

export function reduxCompose({ instanceName }) {
    if (hasReduxDevTools) {
        const devToolsCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
        return devToolsCompose({
            name: `${instanceName} [${document.title || ''}]`,
            trace: true,
            traceLimit: 25,
            // serialize: {
            // 	replacer: (key, value) => {
            // 		if (value instanceof Map) {
            // 			return convertMapToObject(value)
            // 		} else if (value instanceof Set) {
            // 			return convertSetToObject(value)
            // 		}
            // 		return value
            // 	}
            // }
        });
    }
    return compose;
}
