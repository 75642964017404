import { ISettingsState } from '../ISettingsState';

export const ActionType = 'SHOW_ALLOCATIONS';

const showAllocations = () => ({
    type: ActionType,
    payload: {},
});
export default showAllocations;

export function handle(state: ISettingsState): ISettingsState {
    return {
        ...state,
        showAllocations: !state.showAllocations,
    };
}
