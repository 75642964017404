import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { userByIdSelector } from '../../users/usersSelector';

export const inaccessibleItemsWorkRequiredSelector = createCachedSelector(
    [userByIdSelector],
    (user) => {
        return _.reduce(
            user.nodes,
            (accumulator, nodeItem) => accumulator + nodeItem.inaccessibleNodesWorkRequired,
            0
        );
    }
)((state, { userID }) => userID);
