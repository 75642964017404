import { TModernSchedulingAction } from '../../types';
import { IDataState, TDataLoadingRowAction } from '../IDataState';
import { assignedSectionHeaderDataID } from '../../../constants/dataConstatnts';
import { tableDataIDsSelectorWithoutHeaderSelector } from '../selectors/tableDataIDsSelector';

export const ActionType = 'REMOVE_TABLE_DATA_IDS';

interface ITableDataIDsActionPayload {
    idExpression: string;
    allExcept?: boolean;
}

export type TTableDataIDsAction = TModernSchedulingAction<ITableDataIDsActionPayload>;

const removeTableDataIDs = (idExpression, allExcept = false): TTableDataIDsAction => ({
    type: ActionType,
    payload: {
        idExpression,
        allExcept,
    },
});
export default removeTableDataIDs;

export function handle(state: IDataState, { payload }: TDataLoadingRowAction): IDataState {
    const { idExpression, allExcept } = payload;
    const withoutHeaderDataIDs = tableDataIDsSelectorWithoutHeaderSelector(state);
    let newDataIDs: string[] = ([] as string[]).concat(withoutHeaderDataIDs);

    if (idExpression === 'all') {
        newDataIDs = [];
    } else if (idExpression === '') {
        newDataIDs = withoutHeaderDataIDs.filter((tableDataID) => tableDataID.indexOf('_') === -1);
    } else if (idExpression && allExcept) {
        newDataIDs = withoutHeaderDataIDs.filter(
            (tableDataID) =>
                tableDataID.indexOf(idExpression) !== -1 || tableDataID.indexOf('_') === -1
        );
    } else {
        const subNodeIDs = withoutHeaderDataIDs.filter((item) => item.indexOf(idExpression) !== -1);

        if (subNodeIDs.length) {
            newDataIDs = [
                ...withoutHeaderDataIDs.slice(0, withoutHeaderDataIDs.indexOf(subNodeIDs[0])),
                ...withoutHeaderDataIDs.slice(
                    withoutHeaderDataIDs.indexOf(subNodeIDs[0]) + subNodeIDs.length
                ),
            ];
        }
    }

    return {
        ...state,
        tableDataIDs: assignedSectionHeaderDataID.concat(newDataIDs),
    };
}
