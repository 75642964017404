import { TModernSchedulingAction } from '../../types';
import { ITableSizesState } from '../ITableSizesState';
import { TTableLeftSidePanelWidth } from '../../data/IDataState';

interface ISetTableLeftSidePanelWidthActionPayload {
    leftSidePanelWidth: TTableLeftSidePanelWidth;
}

export type ISetTableLeftSidePanelWidthAction =
    TModernSchedulingAction<ISetTableLeftSidePanelWidthActionPayload>;

export const ActionType = 'SET_TABLE_LEFT_SIDE_PANEL_WIDTH';

const setTableLeftSidePanelWidth = (
    leftSidePanelWidth: number
): ISetTableLeftSidePanelWidthAction => ({
    type: ActionType,
    payload: {
        leftSidePanelWidth,
    },
});
export default setTableLeftSidePanelWidth;

export function handle(
    state: ITableSizesState,
    action: ISetTableLeftSidePanelWidthAction
): ITableSizesState {
    return {
        ...state,
        tableLeftSidePanelWidth: action.payload.leftSidePanelWidth,
    };
}
