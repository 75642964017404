import { IFilterRule, IFilterRuleMetadataLocal } from '../data-flow/filters/IFiltersState';
import { IAddedByAssignmentUser, IAddedByAssignmentUserData } from '../data-flow/types';
import { IAssignmentIds, IUsersState } from './dataStructures/IAssignmentIds';
import { isInGlobalArea, isInProjectArea } from './utilities';
import { TAreaObjCodes } from '../data-flow/areaData/areaRelatedInitialDataState';
import { TUserID } from '../data-flow/data/IDataState';

/**
 *
 * @param data
 */

export const isRolesChanged = (newData: IAssignmentIds, oldData: IAssignmentIds): boolean => {
    if (
        (newData.roleIDs && oldData.roleIDs && newData.roleIDs.length !== oldData.roleIDs.length) ||
        (oldData.roleIDs.length && !newData.roleIDs)
    ) {
        return true;
    }

    return oldData.roleIDs.some((userID) => newData.roleIDs.indexOf(userID) === -1);
};

export const getUsersIDs = (idsArr1: string[], idsArr2: string[]): TUserID[] => {
    return idsArr1.filter((userID) => idsArr2.indexOf(userID) === -1);
};

export const getLoadedUsersIDs = (idsArr1: string[], idsArr2: string[], loadedUsers): TUserID[] => {
    return idsArr1.filter((userID) => idsArr2.indexOf(userID) === -1 && loadedUsers[userID]);
};

export const mergeWithLoadedUsersIDs = (idsArr: string[], loadedUsers: IUsersState): TUserID[] => {
    return idsArr.filter((userID) => !!loadedUsers[userID]);
};

// added wait before wpd call to ensure service readiness
export const separateThreadPoolDelay = (delay = 1200): Promise<[]> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([]);
        }, delay);
    });
};

export const getUsersAddedByAssignmentFilter = (
    addedByAssignmentUsersData: IAddedByAssignmentUserData
): IAddedByAssignmentUser | Record<string, unknown> => {
    const { usersAddedByAssignmentIDs, excludedUsersIDs } = addedByAssignmentUsersData;
    if (usersAddedByAssignmentIDs.length || excludedUsersIDs.length) {
        let addedUsers = excludedUsersIDs;
        let ID_Mod = 'notin';
        if (usersAddedByAssignmentIDs.length) {
            addedUsers = usersAddedByAssignmentIDs;
            ID_Mod = 'in';
        }
        return { ID: addedUsers, ID_Mod };
    }
    return {};
};

export const checkIsUserFilteredByProject = (filterRules: IFilterRule[]): boolean => {
    return filterRules.some((filterRule) => {
        const fieldMetaData = filterRule.searchFieldMeta as IFilterRuleMetadataLocal;
        return fieldMetaData.group.key === 'userAssignments';
    });
};

export const checkShouldAddUserByAssignment = (
    areaDataObjCode: TAreaObjCodes,
    filterRules?: IFilterRule[]
): boolean => {
    return (
        isInProjectArea(areaDataObjCode) ||
        (isInGlobalArea(areaDataObjCode) &&
            Array.isArray(filterRules) &&
            checkIsUserFilteredByProject(filterRules))
    );
};
