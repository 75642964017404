import { CustomEnum, OpTask, Portfolio, Project, Role, Task, Team, User } from 'workfront-objcodes';
import { Field, FieldGroup, FieldType } from '@workfront/panel-components';
import _ from 'lodash';
import { IAreaState, TAreaObjCodes } from '../../data-flow/areaData/areaRelatedInitialDataState';
import { getInitialAreaDataState } from '../../data-flow/areaData/getInitialAreaDataState';
import { getByMessageKeySync, isInProjectArea, isInTeamArea } from '../../util/utilities';
import { Sections } from '../schedulingTableConstants';
import {
    getFieldsPerArea,
    getTypeaheadFieldMetadata,
    typeAheadMapper,
} from '../../util/filters/filterUtil';
import { IProjectStatusesForFilter, TFilterLabels } from '../../data-flow/filters/IFiltersState';
import { localizationClient } from '../LocalizationClientFactory';
import { FIELDS_GROUP_KEYS, usersOnProjectGroupKey } from './filterConstants';

export const FILTER_TYPES = {
    TYPE_AHEAD_FILTER: 'TypeAheadFilter',
    AUTOCOMPLETE_FILTER: 'AutoCompleteFilter',
};

export const OBJ_CODES = {
    PORTFOLIOS: {
        groupKey: 'portfolio',
        code: Portfolio,
    },
    PROJECT_STATUSES: {
        groupKey: 'project:status',
        code: CustomEnum,
    },
    PROJECT: {
        groupKey: 'project',
        code: Project,
    },
    TEAM: {
        groupKey: 'team',
        code: Team,
    },
    ROLE: {
        groupKey: 'role',
        code: Role,
    },
    USER: {
        groupKey: 'user',
        code: User,
    },
    PROJECT_USERS: {
        groupKey: 'userAssignments',
        code: Project,
    },
};

type TWildcardsReturnType =
    | {
          [User]: string;
      }
    | {
          [Role]: string;
      }
    | {
          teams: string;
          [Team]: string;
      };

type TWildcardsOptions<Type> = {
    [Property in keyof Type];
};

export const getWildCards = (labels, code): TWildcardsOptions<TWildcardsReturnType> => {
    const wildCardData = {
        [OBJ_CODES.TEAM.code]: {
            [OBJ_CODES.TEAM.code]: `{${labels.myHomeTeam}}`,
            teams: `{${labels.allMyTeams}}`,
        },
        [OBJ_CODES.ROLE.code]: {
            [OBJ_CODES.ROLE.code]: `{${labels.myRole}}`,
        },
        [OBJ_CODES.USER.code]: {
            [OBJ_CODES.USER.code]: `{${labels.me}}`,
        },
    };

    return wildCardData[code] ? wildCardData[code] : {};
};

export const getFilterExpandableItems = (
    labels: TFilterLabels,
    projectStatuses: IProjectStatusesForFilter[],
    sectionType,
    schedulingAreaData: IAreaState = getInitialAreaDataState()
): any[] => {
    // Team area: both assigned and unassigned do not have filter by team option
    // Project area: Unassigned section should not have Portfolios, Projects, Project statuses filters
    // Project area: Assigned section should not have Users on projects filter

    const filterCriteria = {
        $$LIMIT: 100,
    };
    const inAssignedSection = sectionType === Sections.PEOPLE_WORKLOAD;
    const inUnassignedSection = !inAssignedSection;
    const { schedulingAreaObjCode, schedulingAreaID } = schedulingAreaData;
    const isInProjectScope = isInProjectArea(schedulingAreaObjCode);
    const isInTeamScope = isInTeamArea(schedulingAreaObjCode);

    const expandableItems: any = [];

    const skipPortfolios = isInProjectScope && inUnassignedSection;
    if (!skipPortfolios) {
        expandableItems.push({
            labelKey: labels.portLabel,
            filterType: FILTER_TYPES.TYPE_AHEAD_FILTER,
            groupKey: OBJ_CODES.PORTFOLIOS.groupKey,
            objCode: OBJ_CODES.PORTFOLIOS.code,
            wildCards: {},
            filterCriteria,
        });
    }

    const skipProjectStatuses = isInProjectScope && inUnassignedSection;
    if (!skipProjectStatuses) {
        expandableItems.push({
            labelKey: labels.projStatusLabel,
            filterType: FILTER_TYPES.AUTOCOMPLETE_FILTER,
            groupKey: OBJ_CODES.PROJECT_STATUSES.groupKey,
            objCode: OBJ_CODES.PROJECT_STATUSES.code,
            wildCards: {},
            optionList: projectStatuses,
        });
    }

    const skipProjects = isInProjectScope && inUnassignedSection;
    if (!skipProjects) {
        expandableItems.push({
            labelKey: labels.projLabel,
            filterType: FILTER_TYPES.TYPE_AHEAD_FILTER,
            groupKey: OBJ_CODES.PROJECT.groupKey,
            objCode: OBJ_CODES.PROJECT.code,
            wildCards: {},
            filterCriteria,
        });
    }

    const skipTeams = isInTeamScope;
    if (!skipTeams) {
        expandableItems.push({
            labelKey: labels.teamLabel,
            filterType: FILTER_TYPES.TYPE_AHEAD_FILTER,
            groupKey: OBJ_CODES.TEAM.groupKey,
            objCode: OBJ_CODES.TEAM.code,
            wildCards: {
                ...getWildCards(labels, OBJ_CODES.TEAM.code),
            },
            filterCriteria,
        });
    }

    expandableItems.push({
        labelKey: labels.roleLabel,
        filterType: FILTER_TYPES.TYPE_AHEAD_FILTER,
        groupKey: OBJ_CODES.ROLE.groupKey,
        objCode: OBJ_CODES.ROLE.code,
        wildCards: {
            ...getWildCards(labels, OBJ_CODES.ROLE.code),
        },
        filterCriteria,
    });

    const skipUsers = inUnassignedSection;
    if (!skipUsers) {
        const additionalFilterForTeamScope = {
            teamIDs: schedulingAreaID,
            teamIDs_Mod: 'in',
        };
        const filterCriteriaForUsers = isInTeamScope
            ? { ...additionalFilterForTeamScope, ...filterCriteria }
            : filterCriteria;

        expandableItems.push({
            labelKey: labels.usersLabel,
            filterType: FILTER_TYPES.TYPE_AHEAD_FILTER,
            groupKey: OBJ_CODES.USER.groupKey,
            objCode: OBJ_CODES.USER.code,
            wildCards: {
                ...getWildCards(labels, OBJ_CODES.USER.code),
            },
            filterCriteria: {
                ...filterCriteriaForUsers,
                isActive: 1,
            },
        });
    }

    const skipProjectUsers = inUnassignedSection || isInProjectScope;

    if (!skipProjectUsers) {
        expandableItems.push({
            labelKey: labels.projUserLabel,
            placeholder: labels.projLabel,
            filterType: FILTER_TYPES.TYPE_AHEAD_FILTER,
            groupKey: OBJ_CODES.PROJECT_USERS.groupKey,
            objCode: OBJ_CODES.PROJECT_USERS.code,
            filterCriteria,
        });
    }

    return expandableItems;
};

export const getUsersOnProjectFilterField = (): Field => {
    return {
        key: `${usersOnProjectGroupKey}:${FIELDS_GROUP_KEYS[Project]}`,
        label: localizationClient.getTextSync(FIELDS_GROUP_KEYS[Project], 'Project'),
        groupKey: usersOnProjectGroupKey,
        groupLabel: localizationClient.getTextSync(FIELDS_GROUP_KEYS[User], 'User'),
        type: FieldType.NAME,
        dataType: 'string',
        typeaheadFieldMetadata: getTypeaheadFieldMetadata(Project, usersOnProjectGroupKey),
    };
};

export const mutateQuickFilterFields = (
    sectionType: Sections,
    schedulingAreaObjCode: TAreaObjCodes,
    quickFilterFields: Field[],
    projectStatuses: IProjectStatusesForFilter[]
): Field[] => {
    // be aware this function mutates quickFilterFields
    const projectStatus = quickFilterFields.find(
        (quickFilterField) => quickFilterField.key === 'project:status'
    );

    if (projectStatus && projectStatus.typeaheadFieldMetadata) {
        projectStatus.typeaheadFieldMetadata = {
            ...projectStatus.typeaheadFieldMetadata,
            loadPossibleValuesFn: () => Promise.resolve(projectStatuses.map(typeAheadMapper)),
        };
    }

    if (sectionType === Sections.PEOPLE_WORKLOAD) {
        return getFieldsPerArea(
            [...quickFilterFields, getUsersOnProjectFilterField()],
            schedulingAreaObjCode
        );
    }

    const unassignedFilterFields = quickFilterFields.filter(
        (field) => field.groupKey !== OBJ_CODES[User].groupKey
    );
    return getFieldsPerArea(unassignedFilterFields, schedulingAreaObjCode);
};

export const getAdvancedFilterFields = (
    sectionType: Sections,
    advancedFilterFields: FieldGroup[]
): FieldGroup[] => {
    if (sectionType === Sections.UNASSIGNED_WORK) {
        const advancedFilterFieldsObj = _.cloneDeep(advancedFilterFields);
        return advancedFilterFieldsObj.filter((fieldObj) => {
            if (fieldObj.key === Role || fieldObj.key === Team) {
                /**
                 * In unassigned section `role` and `team` can't be
                 * filtered with `contains` condition
                 * */
                fieldObj.fields = fieldObj.fields.map((field) =>
                    field.key === `${FIELDS_GROUP_KEYS[fieldObj.key]}:name`
                        ? { ...field, type: FieldType.REFERENCE }
                        : field
                );
                return true;
            }

            if (fieldObj.key === Task) {
                fieldObj.fields = fieldObj.fields.filter(
                    (field) => !field.key.match(/completion/i)
                );
                return true;
            }

            return fieldObj.key !== User && fieldObj.key !== OpTask;
        });
    }
    return advancedFilterFields;
};

export const filterWildCards = {
    me: getByMessageKeySync('filters.user.wildcards.me', 'Me (logged in user)'),
    homeTeamID: getByMessageKeySync('filters.user.wildcards.team', 'My home team'),
    teamIDs: getByMessageKeySync('filters.user.wildcards.teams', 'All my teams'),
    roleID: getByMessageKeySync('filters.user.wildcards.role', 'My primary role'),
    roleIDs: getByMessageKeySync('filters.user.wildcards.roles', 'All my roles'),
    companyID: getByMessageKeySync('filters.user.wildcards.company', 'My company'),
    homeGroupID: getByMessageKeySync('filters.user.wildcards.Group', 'My home group'),
    otherGroupIDs: getByMessageKeySync('filters.user.wildcards.groups', 'All my groups'),
    categoryID: getByMessageKeySync('filters.user.wildcards.customForm', 'My custom form'),
    accessLevelID: getByMessageKeySync('filters.user.wildcards.access', 'My access level'),
};
