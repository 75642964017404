import createCachedSelector from 're-reselect';
import { stepsSelector } from '../../../../dateRange/selectors/stepsSelector';
import { stepUnitSelector } from '../../../../dateRange/selectors/stepUnitSelector';
import {
    calculateMultipleScheduleHours,
    getAverageOfWeekInMinutes,
    getDayOfWeek,
    recalculateDataForActiveView,
} from '../../../../../util/utilities';
import { IWorkSchedulingCombinedState } from '../../../../types';
import { IWorkDays } from '../../../IDataState';
import { selectDefaultScheduleID } from '../../dataSelectors';

export const scheduleWorkDaysByID = (
    state: IWorkSchedulingCombinedState,
    { ...obj }
): IWorkDays => {
    if (obj.scheduleID) {
        return state.Data.schedulesWorkDays[obj.scheduleID];
    }
    return state.Data.schedulesWorkDays[selectDefaultScheduleID(state)];
};

export const scheduleHoursSelector = createCachedSelector(
    [scheduleWorkDaysByID, stepsSelector, stepUnitSelector],
    (schedule, steps, stepUnit) => {
        const stepEndDate = steps[steps.length - 1].clone().endOf(stepUnit);
        const stepStartDate = steps[0].clone().startOf(stepUnit);
        const scheduleHours: number[] = [];
        let averageOfMinutes = 0;

        if (schedule) {
            let index = 0;
            while (stepStartDate <= stepEndDate) {
                scheduleHours[index] = 0;
                const dayOfWeek = getDayOfWeek(stepStartDate);
                if (schedule[dayOfWeek] && schedule[dayOfWeek].length) {
                    scheduleHours[index] = calculateMultipleScheduleHours(
                        schedule[dayOfWeek],
                        scheduleHours[index]
                    );
                }
                stepStartDate.add(1, 'day');
                index += 1;
            }

            averageOfMinutes = getAverageOfWeekInMinutes(Object.values(schedule));
        }
        return {
            scheduleHours: recalculateDataForActiveView(scheduleHours, stepUnit, steps[0]),
            averageOfMinutes,
        };
    }
)((_, { scheduleID, sectionType }) => `${scheduleID}_${sectionType || ''} || ''}`);
