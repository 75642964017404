import { IFiltersState } from '../IFiltersState';

export const ActionType = 'CHANGE_FILTER_OPEN_STATE';

const changeFilterOpenState = (openState: boolean, section: string): any => ({
    type: ActionType,
    payload: { openState, section },
});
export default changeFilterOpenState;

export function handle(state: IFiltersState, { payload }): IFiltersState {
    return {
        ...state,
        [payload.section]: {
            ...state[payload.section],
            isFilterOpen: payload.openState,
        },
    };
}
