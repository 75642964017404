import { createContext } from 'react';
import { ICurrentUser } from '../util/currentUserMapper';

export interface IGlobalPropsContext {
    minixOpen: (arg: {
        objCode: string;
        ID: string | [];
        messageForEmptySelection?: { messageKey: string; fallback: string };
    }) => void;
    minixClose: () => void;
    minixState: { ID: string } | null;
}

export interface IUnchangeableContext {
    noAccess: boolean;
    currentUser: ICurrentUser;
    switchToOtherView: () => void;
    sharableLink?: string;
}

export const GlobalPropsContext = createContext<IGlobalPropsContext>({
    minixOpen: () => {},
    minixClose: () => {},
    minixState: null,
});
