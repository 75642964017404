import { TModernSchedulingAction } from '../../types';
import { IDataState, IRequestedWorkPerDay } from '../IDataState';

export const ActionType = 'ADD_WORK_PER_DAYS_BY_ASSIGNMENTS';

interface IAddWorkPerDaysByAssignmentsPayload {
    workPerDayData: IRequestedWorkPerDay;
}

type TAddWorkPerDaysByAssignmentAction =
    TModernSchedulingAction<IAddWorkPerDaysByAssignmentsPayload>;

const addWorkPerDaysByAssignments = (
    workPerDayData: IRequestedWorkPerDay
): TAddWorkPerDaysByAssignmentAction => ({
    type: ActionType,
    payload: {
        workPerDayData,
    },
});
export default addWorkPerDaysByAssignments;

export function handle(
    state: IDataState,
    { payload }: TAddWorkPerDaysByAssignmentAction
): IDataState {
    return {
        ...state,
        workPerDaysByAssignments: { ...state.workPerDaysByAssignments, ...payload.workPerDayData },
    };
}
