import { primary } from '@phoenix/all';
import * as React from 'react';
import { css, cx } from 'react-emotion';
import { hourChildStyle, hourSharedStyles, IStyles } from '../../../../styles';
import { isOverAllocated as isOverAllocatedCheck } from '../../../../util/chartUtils';
import { convertMinuteToHour, getHourToShow, getPercentToShow } from '../../../../util/utilities';

interface IHourProps extends React.HTMLAttributes<HTMLDivElement> {
    hourPerDay: number;
    workingHours: number;
    showRemainingHours: boolean;
    isHalfTimeOff?: boolean;
    isInAssignmentProcess: boolean;
    showAllocationsInHoursMode: boolean;
    scheduleHour: number;
    isNotWorkingDay?: boolean;
}

export const Hour: React.FunctionComponent<IHourProps> = React.memo((props) => {
    const hourPerDay = convertMinuteToHour(props.hourPerDay);
    const workingHours = convertMinuteToHour(props.workingHours);
    const isOverAllocated = isOverAllocatedCheck(props.hourPerDay, props.workingHours);
    const scheduleHour = convertMinuteToHour(props.scheduleHour);
    const hoursAreEqual = hourPerDay === workingHours;
    const userAllocationClassName = isOverAllocated ? '' : 'user-not-overallocated';
    const styles: IStyles = {
        backgroundColor: 'transparent',
        borderTop: '0',
        textColor: primary.white(),
    };

    if (isOverAllocated) {
        styles.backgroundColor = '#FAE5E5'; // TODO change colors when phoenix secondary colors variables will be ready
        styles.textColor = '#D30002';
        styles.borderTop = '#D30002';
    } else if (hoursAreEqual) {
        styles.backgroundColor = primary.blue(50);
        styles.textColor = primary.blue(500);
        styles.borderTop = primary.blue(500);
    } else {
        styles.textColor = primary.blue(500);
        styles.borderTop = '0';
    }

    return (
        <div
            className={cx(
                userAllocationClassName,
                hourStyle(styles, (hourPerDay / workingHours) * 100, isOverAllocated),
                props.className
            )}
        >
            <div
                className={hourChildStyle(props.isHalfTimeOff, styles, props.isInAssignmentProcess)}
            >
                {props.showAllocationsInHoursMode
                    ? getHourToShow(props.showRemainingHours, workingHours, hourPerDay)
                    : getPercentToShow(
                          workingHours,
                          hourPerDay,
                          scheduleHour,
                          props.showRemainingHours,
                          props.isNotWorkingDay
                      )}
            </div>
        </div>
    );
});

const underAllocationCss = (isOverAllocated: boolean, height: number): string | undefined => {
    if (!isOverAllocated) {
        return css`
            &:after {
                content: '';
                bottom: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: ${height}%;
                min-height: 2px;
                background-color: ${primary.blue(50)};
            }
        `;
    }
};

const hourStyle = (styles: IStyles, height: number, isOverAllocated: boolean) => css`
    ${hourSharedStyles}
    z-index: 3;
    border-top: 1px solid ${styles.borderTop};
    height: 30px;
    width: 100%;
    background-color: ${styles.backgroundColor};
    ${underAllocationCss(isOverAllocated, height)};
`;
