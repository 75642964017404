import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT, unassignedSectionSliceIndex } from '../../../constants/dataConstatnts';
import { IWorkSchedulingCombinedState } from '../../types';
import {
    ITimeOffsState,
    TasksAndIssuesRecord,
    IAssignmentsDetails,
    IAffectingDuration,
    TInHighlightingMode,
    IRequestedWorkPerDayFromService,
    TProjectID,
    TTableDataIDs,
    THasMoreHours,
    THasMoreUnassignedTasks,
    IAssignmentDialogDetails,
    TIDExpressionForMinixState,
    TContouringRowID,
    TEspProductEnabled,
    TDraggingFromArea,
    IContouringCellInfo,
    TDropdownOpened,
    TObjectsInAssignmentMode,
    TTemporaryWorkPerDays,
    IRequestedTemporaryWorkPerDayFromService,
    IProjectState,
    IProjectGroupColorStateList,
    IRoleState,
    ISchedulesWorkDaysState,
    IFullTimeOffStepsWithTimestampInfo,
    TUnassignedTasks,
    IDataState,
} from '../IDataState';
import { getFullTimeOffStepUnit } from '../../../util/periodUtils/periodUtils';
import { initialEditedWorkPerDays } from '../getInitialDataState';

export const tasksAndIssuesSelector = (
    state: IWorkSchedulingCombinedState
): TasksAndIssuesRecord => {
    return state.Data.tasksAndIssues;
};

export const usersOnProjectSelector = (state: IWorkSchedulingCombinedState): string[] => {
    return state.Data.usersOnProject;
};
export const unassignedTasksSelector = (state: IWorkSchedulingCombinedState): TUnassignedTasks => {
    return state.Data.unassignedTasks;
};

export const projectsSelector = (
    state: IWorkSchedulingCombinedState
): { [id: string]: IProjectState } => {
    return state.Data.projects;
};

export const projectGroupColorsSelector = (
    state: IWorkSchedulingCombinedState
): IProjectGroupColorStateList => {
    return state.Data.projectGroupColors;
};

export const rolesSelector = (state: IWorkSchedulingCombinedState): IRoleState => {
    return state.Data.roles;
};

export const schedulesWorkDaysSelector = (
    state: IWorkSchedulingCombinedState
): ISchedulesWorkDaysState => {
    return state.Data.schedulesWorkDays;
};

export const isUnassignedTasksProjectsRequestSendSelector = (
    state: IWorkSchedulingCombinedState
): boolean => {
    return state.Data.isUnassignedTasksProjectsRequestSend;
};

export const isUnassignedTasksRequestSendSelector = (
    state: IWorkSchedulingCombinedState
): boolean => {
    return state.Data.isUnassignedTasksRequestSend;
};

/**
 *
 * @param state
 */
export const tableDataIDsForUnassignedSectionSelector = (
    state: IWorkSchedulingCombinedState
): TTableDataIDs => {
    return state.Data.tableDataIDsForUnassignedSection;
};

export const tableDataIDsForUnassignedSectionWithoutHeader = (
    stateOfData: IWorkSchedulingCombinedState['Data']
): TTableDataIDs => {
    return stateOfData.tableDataIDsForUnassignedSection.slice(unassignedSectionSliceIndex);
};

/**
 *
 * @param tasksAndIssues
 * @param taskIssueID
 * @param nextTaskIssueID
 * @param projectID
 */
export const isLastNodeForProject = (
    tasksAndIssues: TasksAndIssuesRecord,
    taskIssueID?: string,
    nextTaskIssueID?: string,
    projectID?: string
): boolean => {
    const isCurrentNodeRelatedToAreaProject =
        taskIssueID &&
        tasksAndIssues[taskIssueID] &&
        tasksAndIssues[taskIssueID].projectID === projectID;
    const isNextNodeRelatedToAreaProject =
        nextTaskIssueID &&
        tasksAndIssues[nextTaskIssueID] &&
        tasksAndIssues[nextTaskIssueID].projectID === projectID;

    return !!(isCurrentNodeRelatedToAreaProject && !isNextNodeRelatedToAreaProject);
};

export const getFullTimeOffSteps = (
    timeOffs: ITimeOffsState,
    isWeekMode: boolean
): IFullTimeOffStepsWithTimestampInfo => {
    const stepUnit = getFullTimeOffStepUnit(isWeekMode);
    const dates: IFullTimeOffStepsWithTimestampInfo = {};

    _.forEach(timeOffs, (timeOff, startDateStr) => {
        if (!timeOff.isPartiallyTimeOff && !timeOff.nonWorkHours.length) {
            let dateStep = moment(startDateStr);
            const endDate = moment(timeOff.endDate);

            while (dateStep <= endDate) {
                dates[dateStep.format(DATE_FORMAT)] = {
                    isUserTimeOff: timeOff.isUserTimeOff,
                    startDate: timeOff.startDate,
                    endDate: timeOff.endDate,
                };
                dateStep = dateStep.add(1, stepUnit);
            }
        }
    });

    return dates;
};

export const getFullTimeOffRangeWithCount = (
    timeOffs: ITimeOffsState
): IFullTimeOffStepsWithTimestampInfo => {
    const dates: IFullTimeOffStepsWithTimestampInfo = {};

    _.forEach(timeOffs, (timeOff) => {
        if (!timeOff.isPartiallyTimeOff && !timeOff.nonWorkHours.length) {
            const startDate = moment(timeOff.startDate);
            const endDate = moment(timeOff.endDate);
            const totalDayOffCount = endDate.diff(startDate, 'days') + 1;
            dates[timeOff.startDate] = {
                isUserTimeOff: timeOff.isUserTimeOff,
                totalDayOffCount,
                startDate: timeOff.startDate,
                endDate: timeOff.endDate,
            };
        }
    });

    return dates;
};

export const selectDefaultScheduleID = (state: IWorkSchedulingCombinedState): string => {
    return state.Data.defaultScheduleID;
};

export const objectsAssignmentDetailsSelector = (
    state: IWorkSchedulingCombinedState
): { [taskID: string]: IAssignmentsDetails[] } => state.Data.objectAssignmentDetails;

export const objectsDelegationDetailsSelector = (
    state: IWorkSchedulingCombinedState
): IDataState['objectDelegationDetails'] => state.Data.objectDelegationDetails;

export const loadWorkDelegationsSelector = (state: IWorkSchedulingCombinedState): boolean =>
    state.Data.loadWorkDelegations;

export function contouringRowIdSelector(state: IWorkSchedulingCombinedState): TContouringRowID {
    return state.Data.contouringRowState.contouringRowID;
}

export function contouringCellInfoSelector(
    state: IWorkSchedulingCombinedState
): IContouringCellInfo | undefined {
    return state.Data.contouringRowState.contouringCellInfo;
}

export function assignmentDialogDetailsSelector(
    state: IWorkSchedulingCombinedState
): IAssignmentDialogDetails {
    return state.Data.assignmentDialogDetails;
}

export function dropdownOpenedSelector(state: IWorkSchedulingCombinedState): TDropdownOpened {
    return state.Data.dropdownOpened;
}

export function changedAssignmentSelector(
    state: IWorkSchedulingCombinedState
): TObjectsInAssignmentMode {
    return state.Data.objectsInAssignmentMode;
}

export const highlightingModeUserSelector = (
    state: IWorkSchedulingCombinedState,
    { ...obj }
): IAffectingDuration => {
    return highlightingModeSelector(state).user[obj.userID];
};

export const highlightingModeSelector = (
    state: IWorkSchedulingCombinedState
): TInHighlightingMode => {
    return state.Data.inHighlightingModeUsersAfterAssignment;
};

export function temporaryWorkPerDaysSelector(
    state: IWorkSchedulingCombinedState
): IRequestedWorkPerDayFromService | Record<string, any> {
    return state.Data.temporaryWorkPerDays;
}

export function temporaryWorkPerDaysUserSelector(
    state: IWorkSchedulingCombinedState,
    { ...obj }
): IRequestedTemporaryWorkPerDayFromService {
    if (state.Data.temporaryWorkPerDays.userID === obj.userID) {
        return state.Data.temporaryWorkPerDays;
    }
    return initialEditedWorkPerDays;
}

export function workPerDaysOnDragHoverSelector(
    state: IWorkSchedulingCombinedState
): TTemporaryWorkPerDays {
    return state.Data.workPerDaysOnDragHover;
}

const initialTemporaryWorkPerDays = [];

export function workPerDaysOnDragHoverUserSelector(
    state: IWorkSchedulingCombinedState,
    { ...obj }
): number[] {
    if (workPerDaysOnDragHoverSelector(state)[obj.userID]) {
        return workPerDaysOnDragHoverSelector(state)[obj.userID];
    }
    return initialTemporaryWorkPerDays;
}

export function isEspProductEnabled(state: IWorkSchedulingCombinedState): TEspProductEnabled {
    return state.Data.espProductEnabled;
}

export function draggingFromAreaSelector(state: IWorkSchedulingCombinedState): TDraggingFromArea {
    return state.Data.draggingFromArea;
}

export const expandedUnassignedProjectIDsSelector = (
    state: IWorkSchedulingCombinedState
): Set<TProjectID> => {
    return state.Data.expandedUnassignedProjectIDs;
};

export const hasMoreUsersSelector = (state: IWorkSchedulingCombinedState): THasMoreHours => {
    return state.Data.hasMoreUsers;
};

export const hasMoreUnassignedTasksSelector = (
    state: IWorkSchedulingCombinedState
): THasMoreUnassignedTasks => {
    return state.Data.hasMoreUnassignedTasks;
};

export const IDExpressionForMinixStateSelector = (
    state: IWorkSchedulingCombinedState
): TIDExpressionForMinixState => {
    return state.Data.IDExpressionForMinixState;
};
