import { Sections } from '../../../constants/schedulingTableConstants';
import { IDataState, TDataLoadingRowAction } from '../IDataState';
import {
    assignedSectionHeaderDataID,
    unassignedSectionHeaderDataID,
} from '../../../constants/dataConstatnts';
import { tableDataIDsForUnassignedSectionWithoutHeader } from '../selectors/dataSelectors';
import { tableDataIDsSelectorWithoutHeaderSelector } from '../selectors/tableDataIDsSelector';

export const ActionType = 'REMOVE_LOADING_ROW';

const removeRow = (idExpression, sectionType: Sections | ''): TDataLoadingRowAction => ({
    type: ActionType,
    payload: {
        idExpression,
        sectionType,
    },
});
export default removeRow;
// TODO reuse it in unassigned part
export function handle(state: IDataState, { payload }: TDataLoadingRowAction): IDataState {
    const { idExpression, sectionType } = payload;

    const isUnassignedSection = sectionType === Sections.UNASSIGNED_WORK;
    let withoutHeaderDataIDs;
    let indexOfRemovingRow;

    if (isUnassignedSection) {
        withoutHeaderDataIDs = tableDataIDsForUnassignedSectionWithoutHeader(state);

        indexOfRemovingRow = withoutHeaderDataIDs.indexOf(idExpression);
    } else {
        withoutHeaderDataIDs = tableDataIDsSelectorWithoutHeaderSelector(state);

        indexOfRemovingRow = withoutHeaderDataIDs.indexOf(idExpression);
    }

    const newDataIDs = [
        ...withoutHeaderDataIDs.slice(0, indexOfRemovingRow),
        ...withoutHeaderDataIDs.slice(indexOfRemovingRow + 1),
    ];

    if (indexOfRemovingRow !== -1) {
        if (isUnassignedSection) {
            return {
                ...state,
                tableDataIDsForUnassignedSection: unassignedSectionHeaderDataID.concat(newDataIDs),
            };
        }
        return {
            ...state,
            tableDataIDs: assignedSectionHeaderDataID.concat(newDataIDs),
        };
    }

    return {
        ...state,
    };
}
