import { TModernSchedulingAction } from '../../types';
import { IDataState, TWorkPerDays } from '../IDataState';
import { convertMinuteToHour } from '../../../util/utilities';

export const ActionType = 'ADD_TEMPORARY_WORK_PER_DAY_HOURS_AFTER_DRAG_ACTION';

interface IAddTemporaryWorkPerDayHoursAfterDragHoverPayload {
    assignmentWorkPerDays: TWorkPerDays;
    userID: string;
}

type IAddTemporaryWorkPerDayHoursAfterDragAction =
    TModernSchedulingAction<IAddTemporaryWorkPerDayHoursAfterDragHoverPayload>;

const addTemporaryWorkPerDayHoursAfterDragAction = (
    assignmentWorkPerDays,
    userID
): IAddTemporaryWorkPerDayHoursAfterDragAction => ({
    type: ActionType,
    payload: { assignmentWorkPerDays, userID },
});

export function handle(
    state: IDataState,
    { payload }: TModernSchedulingAction<IAddTemporaryWorkPerDayHoursAfterDragHoverPayload>
): IDataState {
    const { assignmentWorkPerDays, userID } = payload;
    assignmentWorkPerDays.forEach((hour) => convertMinuteToHour(hour));

    return {
        ...state,
        workPerDaysOnDragHover: {
            [userID]: payload.assignmentWorkPerDays,
        },
    };
}

export default addTemporaryWorkPerDayHoursAfterDragAction;
