import { apiDateToDate } from '@workfront/fns';
import mitt from 'mitt';
import { Moment } from 'moment';
import {
    Schedule as scheduleObjCode,
    TObjCode,
    Task,
    OpTask,
    Assignment,
} from 'workfront-objcodes';
import { IAppearingRowIndexes, TModernSchedulingAction } from '../types';
import { RoleSummaryHourKey } from '../../constants/RoleSummaryHourKey';
import { Sections } from '../../constants/schedulingTableConstants';
import { IAssignmentsObjects } from '../../util/dataStructures/IAssignmentaObjects';

type TaskIssueID = string;
export type TasksAndIssuesRecord = Record<TaskIssueID, IObjectState>;

export type RoleSummaryDataType =
    | Record<RoleSummaryKeys, IWorkRequiredRoleSummaryData[]>
    | Record<string, IWorkRequiredRoleSummaryData[]>;

export type IRoleSummaryLoading = Record<string | RoleSummaryKeys, boolean>;

export type LoadWorkPerDayListByAssignmentFromWPDServiceForProjectResponse = Record<
    string,
    Record<string, number>
>;

interface ISimpleRole {
    ID: string;
    name: string;
    objCode: string;
}

export interface ILoadAssignmentsByProjectResponse {
    ID: string;
    objCode: string;
    role: ISimpleRole;
}

export type TUsers = {
    [id: string]: IUserState;
};

export type TUnassignedTasks = {
    [taskID: string]: IUnassignedTaskState;
};

export interface IDataState {
    users: TUsers;
    usersOnProject: string[];
    addedUsersByAssignment: TAddedUsersByAssignment;
    roles: IRoleState;
    tableDataIDs: TTableDataIDs;
    tableDataIDsForUnassignedSection: TTableDataIDs;
    hasMoreUnassignedTasks: THasMoreUnassignedTasks;
    hasMoreUsers: THasMoreHours;
    objectAssignmentDetails: {
        [taskID: string]: IAssignmentsDetails[];
    };
    objectDelegationDetails: {
        [taskID: string]: IDelegationsDetails[];
    };
    tasksAndIssues: TasksAndIssuesRecord;
    projects: {
        [id: string]: IProjectState;
    };
    projectGroupColors: IProjectGroupColorStateList;
    unassignedTasks: TUnassignedTasks;
    expandedUnassignedProjectIDs: Set<TProjectID>;
    unassignedTasksProjects: IUnassignedTasksProjects;
    isUnassignedTasksProjectsRequestSend: boolean;
    isUnassignedTasksRequestSend: boolean;
    isInLoadingStatePeopleWorkloadSection: TIsInLoadingStatePeopleWorkloadSection;
    isInLoadingStateUnassignedWorkSection: TLoadingStateUnassignedWorkSection;
    schedulesWorkDays: ISchedulesWorkDaysState;
    defaultScheduleID: string;
    appearingRowIndexes: IAppearingRowIndexes;
    unassignedWorkEmptyRowHeight: TUnassignedWorkEmptyRowHeight;
    peopleWorkloadEmptyRowHeight: boolean;
    dropdownOpened: TDropdownOpened;
    workPerDaysByAssignments: IRequestedWorkPerDay;
    workPerDaysByAssignmentsFromService: IRequestedWorkPerDayFromService;
    contouredAssignments: IContouredAssignments;
    temporaryWorkPerDays: IRequestedTemporaryWorkPerDayFromService;
    workPerDaysOnDragHover: TTemporaryWorkPerDays;
    objectsInAssignmentMode: TObjectsInAssignmentMode;
    IDExpressionForMinixState: string | null;
    inHighlightingModeUsersAfterAssignment: TInHighlightingMode;
    assignmentDialogDetails: IAssignmentDialogDetails;
    hasProjectLifeAfterDeath: boolean;
    contouringRowState: {
        contouringRowID: TContouringRowID;
        contouringCellInfo?: IContouringCellInfo;
    };
    roleSummaryData: RoleSummaryDataType;
    roleSummaryLoading: IRoleSummaryLoading;
    roleSummaryActiveKeys: Array<RoleSummaryKeys | string>;
    espProductEnabled: TEspProductEnabled;
    draggingFromArea: TDraggingFromArea;
    loadWorkDelegations: boolean;
    calculateFTEAutomatic: boolean;
}

export interface IContouringCellInfo {
    scheduleHour: number;
    availableHour: number;
}

export type TAssignmentDialogID = string | null;

export interface IAssignmentDialogDetails {
    ID: TAssignmentDialogID;
    positionX: number;
    positionY: number;
    showLoading: boolean;
}

export type IUnassignedTaskProjectValue = {
    nodes: string[];
    details: IProjectState & { expanded: boolean };
};

export interface IUnassignedTasksProjects {
    [projectID: string]: IUnassignedTaskProjectValue;
}

export interface IAffectingDuration {
    affectingDurationStartDate: string;
    affectingDurationEndDate: string;
}

export interface IUserState {
    ID: TUserID;
    name: TUserName;
    avatarDate: string;
    userRolesIDs: TUserRoleIds;
    roleID: TUserRoleId;
    scheduleID: string;
    objCode: string;
    userWorkPerDayForPeriod: number[];
    availableHoursForPeriod: number[];
    purAvailableHoursForPeriod: number[];
    projectIDs: TProjectID[]; // here we keep sorted projects IDs which have at least one accessible task (sorted By projects start date)
    inaccessibleProjectIDs: string[]; // Here we keep projects IDs which haven't accessible tasks or issues
    nodes: INodesDetailsState;
    offset: number;
    expanded: boolean;
    timeOffs: ITimeOffsState;
    hasAssignments: boolean;
    expandedProjectIDs: Set<TProjectID>;
    userDataRequestsState: {
        isRequestSend: boolean;
        isDataLoaded: boolean;
    };

    projectsDataRequestsState: {
        isRequestSend: boolean;
        isDataLoaded: boolean;
    };
    assignments: IUserAssignmentsState;
    isInAssignmentProcess: TUserIsInAssignmentProcess;
}

export interface IUserAssignmentsState {
    [assignmentID: string]: string; // it is task ID
}

export interface INodesDetailsState {
    [projectID: string]: INodesDetailsItemState;
}

export interface INodesDetailsItemState {
    nodes: string[]; // it is the array of tasks issues IDs
    inaccessibleNodesWorkRequired: number;
    inaccessibleNodesDates: IInaccessibleItemsDates[];
    inaccessibleNodesWorkPerDays: number[][];
    inaccessibleNodesWorkPerDaysFromService: Record<string, number>;
    offset: number; // is used when projectGrouping is on
}

export interface IInaccessibleItemsDates {
    startDate: TDate;
    endDate: TDate;
    projectedStartDate: TDate;
    projectedCompletionDate: TDate;
}

export interface IRoleState {
    [id: string]: string;
}

export interface ITimeOffsState {
    [startDate: string]: ITimeOffStateItem;
}

export interface ITimeOffStateItem {
    endDate: string;
    startDate: string;
    isUserTimeOff: boolean | 'both';
    isPartiallyTimeOff: boolean;
    nonWorkHours: string[];
}

export interface IWorkDays {
    Monday: string[];
    Saturday: string[];
    Sunday: string[];
    Friday: string[];
    Thursday: string[];
    Tuesday: string[];
    Wednesday: string[];
}

export interface ISchedule {
    ID: string;
    name: string;
    objCode: typeof scheduleObjCode;
    isDefault: boolean;
}

export interface ISchedulesWorkDaysState {
    [scheduleID: string]: IWorkDays;
}

export interface IRequestedSchedulesState {
    [scheduleID: string]: {
        nonWorkDays: ISchedulesNonWorkDays[];
        workDays: IWorkDays;
    };
}

export interface ISchedulesNonWorkDays {
    nonWorkDate: string;
    workHours: string[];
}

export interface IAvailableHoursForPeriod {
    AVL: {
        [userID: string]: number[];
    };
    PAVL: {
        [userID: string]: number[];
    };
}

export interface IObjectState {
    name: string;
    projectGroupID: string;
    projectID: TProjectID;
    projectStatus: string;
    projectName: string;
    plannedStartDate: TDate;
    plannedCompletionDate: TDate;
    projectedStartDate: TDate;
    projectedCompletionDate: TDate;
    actualCompletionDate: TDate;
    workRequired: {
        [userID: string]: number;
    };
    objCode: TObjCode;
    assignAccess: boolean;
    workRequiredEditAccess: boolean;
    areHoursNotEqualsBeforeContouringSave: boolean;
    scheduleID?: string;
    isWorkRequiredLocked?: boolean;
    isDurationLocked?: boolean;
    plannedHours: number;
    duration: number;
    durationType: string;
    status: string;
    durationUnit: string;
    recurrenceRuleID: string | null;
    parent: IAssignmentParent | null;
    predecessors: IAssignmentPredecessor[];
    canStart: boolean;
    assignmentUserIDToStatus: IUserToAssignmentStatus;
    assignmentUserIDToAssignmentID: IUserToAssignmentID;
}

export interface IErrorDetail {
    status: number;
    message: string;
    isErrorThrown: boolean;
}
export interface IAbleToReload {
    isAbleToReload: boolean;
}

export interface IErrorState {
    [Sections.PEOPLE_WORKLOAD]: IErrorDetail;
    [Sections.UNASSIGNED_WORK]: IErrorDetail;
}
export interface IReloadState {
    [Sections.PEOPLE_WORKLOAD]: IAbleToReload;
    [Sections.UNASSIGNED_WORK]: IAbleToReload;
}

export interface IAssignmentParent {
    ID: string;
    name: string;
}

export interface IAssignmentPredecessor {
    ID: string;
    name: string;
}

export interface IUserToAssignmentStatus {
    [userID: string]: string;
}

export interface IUserToAssignmentID {
    [userID: string]: string;
}

export interface IObjectStateWithID extends IObjectState {
    ID: string;
}

export interface IUnassignedAssignmentDetails {
    ID: string;
    roleID: string;
}

export interface IUnassignedTaskState {
    name: string;
    projectGroupID: string;
    projectID: TProjectID;
    projectStatus: string;
    projectName: string;
    plannedStartDate: TDate;
    plannedCompletionDate: TDate;
    projectedStartDate?: TDate;
    projectedCompletionDate?: TDate;
    actualCompletionDate?: TDate;
    objCode: TObjCode;
    assignAccess: boolean;
    assignments: IUnassignedAssignmentDetails[];
    workPerDayList: number[];
    workPerDayListFromService: Record<string, unknown>;
    areHoursNotEqualsBeforeContouringSave?: boolean;
    scheduleID: string;
    isWorkRequiredLocked?: boolean;
    isDurationLocked?: boolean;
    plannedHours: number;
    duration: number;
    durationType: string;
    status: string;
    durationUnit: string;
    recurrenceRuleID: string | null;
    parent: IAssignmentParent | null;
    predecessors: IAssignmentPredecessor[];
    canStart?: boolean;
    assignmentUserIDToStatus?: IUserToAssignmentStatus;
}

interface IProjectColorRGBAList {
    [opacityLevel: number]: string;
}

export interface IProjectColor {
    HEX_actual: string;
    HSV_actual: number[];
    RGB_actual: string;
    RGB: string;
    RGB_lighter: string;
    RGB_list: number[];
    RGBA: IProjectColorRGBAList;
    textColor: string;
    textColorDark: string;
    taskRGB: string;
    taskRGB_lighter: string;
    taskColor: string;
    taskRGBA: IProjectColorRGBAList;
    taskTextColorDark: string;
}

export interface IProjectColorList {
    [projectID: TProjectID]: IProjectColor;
}

export interface IProjectTimelineInfo {
    hoursPerDay: number;
    daysPerWeek: number;
    weeksPerMonth: number;
}

export interface IProjectColors {
    [colorHEX: string]: IProjectColor;
}

export interface IProjectLabels {
    [projectStatusCode: string]: string;
}

export interface IProjectGroupData {
    [groupdID: string]: {
        colors: IProjectColors;
        labels: IProjectLabels;
    };
}

export interface IProjectState {
    ID: TProjectID;
    name: string;
    objCode: string;
    groupID: string;
    isAccessible?: boolean;
    offset?: number;
    scheduleID?: string;
    status: string;
    statusColor?: string;
}

export interface IProjectGroupColorState {
    [colorValue: string]: IProjectGroupData;
}

export interface IProjectGroupColorStateList {
    [groupID: string]: IProjectGroupColorState;
}

export interface IRequestedProjectData {
    ID: string;
    isAccessible: boolean;
    groupID: string;
    name: string;
    status: string;
    statusColor?: string;
}

export interface IRequestedUserData {
    ID: string;
    name: string;
    objCode: string;
    roleID: string;
    scheduleID: string;
    userRoles: IRequestedUserRoles[];
    avatarDate: string;
}

export interface IRequestedUnassignedProjectData {
    ID: string;
    name: string;
    objCode: string;
    scheduleID: string;
    groupID: string;
    status: string;
}

export interface IRequestedUserRoles {
    objCode: string;
    role: {
        ID: string;
        name: string;
        objCode: string;
    };
}

export interface IRequestedPredecessor {
    objCode: TObjCode;
    predecessor: IAssignmentPredecessor;
}

export interface IRequestedAssignmentStatus {
    ID: string;
    assignedToID: string;
    status: string;
}

export interface IRequestedAssignmentTask {
    ID: string;
    name: string;
    objCode: string;
    projectID: TProjectID;
    plannedStartDate: string;
    plannedCompletionDate: string;
    durationType: string;
    permissions: {
        actions: string[];
    };
    project: {
        status: string;
        name: string;
    };
    status: string;
    isDurationLocked?: boolean;
    isWorkRequiredLocked: boolean;
    workRequired: number;
    recurrenceRuleID: string | null;
    duration: number;
    durationUnit: string;
    parent: IAssignmentParent | null;
    predecessors: IRequestedPredecessor[];
    canStart: boolean;
    assignments: IRequestedAssignmentStatus[];
}

export interface IRequestedAssignmentOpTask {
    ID: string;
    name: string;
    objCode: string;
    projectID: TProjectID;
    plannedStartDate: string;
    plannedCompletionDate: string;
    permissions: {
        actions: string[];
    };
    project: {
        status: string;
        name: string;
    };
    status: string;
}

export type TTaskOrOpTask = IRequestedAssignmentTask | IRequestedAssignmentOpTask;

export interface IRequestedAssignment {
    ID: string;
    assignedToID: string;
    objCode: string;
    task?: IRequestedAssignmentTask;
    opTask?: IRequestedAssignmentOpTask;
    workRequired: number;
}

export interface IUnassignedAssignment {
    ID: string;
    name: string;
    objCode: typeof Task | typeof OpTask;
    plannedStartDate: string;
    plannedCompletionDate: string;
    projectID: TProjectID;
    permissions: {
        actions: string[];
    };
    assignments: IUnassignedTasksAssignment[];
    workPerDayList: number[];
    project: {
        ID: TProjectID;
        scheduleID: string;
        groupID: string;
        status: string;
        name: string;
    };
    groupID: string;
    status: string;
    scheduleID?: string;
    workRequired: number;
    isWorkRequiredLocked?: boolean;
    isDurationLocked?: boolean;
    duration: number;
    durationType: string;
    durationUnit: string;
    recurrenceRuleID: string | null;
    parent: IAssignmentParent | null;
    predecessors: IRequestedPredecessor[];
}

export interface IUnassignedTasksAssignment {
    ID: string;
    objCode: typeof Assignment;
    assignedToID: string | null;
    roleID: string;
}

export interface IUnassignedProjects {
    ID: string;
    name: string;
    objCode: string;
    plannedStartDate: string;
    plannedCompletionDate: string;
}

export interface IRequestedInaccessibleAssignment {
    result: Array<{
        ID: string;
        plannedStartDate: TDate;
        plannedCompletionDate: TDate;
        projectedStartDate: TDate;
        projectedCompletionDate: TDate;
        actualStartDate: TDate;
        actualCompletionDate: TDate;
        projectID: TProjectID;
        workPerDay: number[];
        workRequired: number;
    }>;
}

export interface IRequestedUserTimeOffs {
    ID: string;
    endDate: string;
    objCode: string;
    startDate: string;
    userID: string;
}

export interface IWorkPerDayList {
    result: IRequestedWorkPerDay;
}

export interface IRequestedWorkPerDay {
    [assignmentId: string]: number[];
}

export interface IRequestedWorkPerDayFromService {
    [assignmentId: string]: Record<string, unknown>;
}

export interface IContouredAssignments {
    [assignmentId: string]: IAssignmentsObjects;
}

export interface INumberComparison {
    roundedDiff: number;
    roundedDiffClean: number;
    diff: number;
    diffClean: number;
}

export interface IRequestedTemporaryWorkPerDayFromService {
    userID: TUserID | null;
    projectID?: TProjectID;
    assignmentID: TAssignmentID | null;
    data: Record<string, unknown>;
}

interface IDataLoadingRowActionPayload {
    idExpression: string | '';
    sectionType: Sections | '';
    allExcept?: boolean;
}

interface IToggleLoadingAction {
    loadingState: boolean;
    sectionType: Sections;
}

export interface TMessageKey {
    messageKey: string;
    fallBack: string;
}

export interface TMessageKeyWithAria extends TMessageKey {
    ariaMessageKey: string;
    ariaFallBack: string;
}

export interface IWorkItemStatuses {
    [item: string]: {
        equatesWithValue: string;
        value: string;
        label: string;
        color: string;
    };
}

export type TDataLoadingRowAction = TModernSchedulingAction<IDataLoadingRowActionPayload>;

export type TToggleLoadingAction = TModernSchedulingAction<IToggleLoadingAction>;

export interface IWorkRequiredRoleSummaryData {
    roleID: string;
    roleTitle: string;
    [RoleSummaryHourKey.PLANNED_HOUR]?: number;
    [RoleSummaryHourKey.APPROVED_HOUR]?: number;
}

export enum RoleSummaryKeys {
    TOTAL = 'total',
    MONTHLY = 'monthly',
}

export interface IAssignmentsDetails {
    ID: string;
    type: string;
    objCode: string;
    isPrimary?: boolean;
    assignmentPercent?: number;
    work?: number;
    role?: IAssignmentDetails | null;
    roles?: IRoleDetails | null;
    assignedTo?: IAssignmentDetails | null;
    team?: IAssignmentDetails | null;
}

export interface IAssignmentDetails {
    objCode: string;
    name: string;
    ID: string;
    avatarDate: string;
}

export interface IDelegationsDetails {
    delegatedFrom: IAssignmentDetails;
    delegatedTo: IAssignmentDetails;
}

export interface IRoleDetails {
    ID: string;
    name: string;
}

export interface IUserRoleDetails {
    objCode: TObjCode;
    role: {
        ID: string;
        name: string;
        objCode: TObjCode;
    };
}

export interface IFullTimeOffSteps {
    [stepDate: string]: { isUserTimeOff: boolean | 'both' };
}

export interface IDropdownOption {
    messageKey: string;
    label: string;
    value: string;
    toolTipContent: {
        messageKey: string;
        fallBack: string;
    };
}

export interface IFullTimeOffStepsWithTimestampInfo extends IFullTimeOffSteps {
    [stepDate: string]: {
        isUserTimeOff: boolean | 'both';
        totalDayOffCount?: number;
        startDate: string;
        endDate: string;
    };
}

export interface IObjCode {
    ID: string;
    objCode: string;
}

export interface IStartEndDates {
    startDate: TDate;
    endDate: TDate;
}

export interface IWorkPerDayHours {
    workPerDaysForPeriod: TWorkPerDays;
    crossedOutHoursForPeriod: number[];
}

export interface IRoleWorkDiff {
    roleID: string;
    diff: number;
}

export type THandleProjectArrowClick = (
    userID: TUserID,
    expanded: boolean,
    projectID: TProjectID
) => void;

export type TOnUserArrowClick = (userID: TUserID) => void;
export type TOnUnassignedProjectArrowClick = (projectID: TProjectID) => void;

export type TUserID = string;
export type TTaskOrIssueID = string;
export type TTeamID = string;
export type TProjectID = string;
export type TDate = ReturnType<typeof apiDateToDate>;
export type TIsObjectHighlighted = boolean;
export type TWorkPerDays = number[];
export type TRoleIDs = string[];
export type TTemporaryWorkPerDays = Record<string, any>;

export type TBulkAssignmentUserOption = {
    ID: TUserID;
    name: string;
    avatarURL: string;
    disabled?: boolean;
    subLabel?: string;
};

export type TInHighlightingMode = {
    user: {
        [userID: string]: IAffectingDuration;
    };
    object: {
        [taskOrIssueID: TTaskOrIssueID]: TIsObjectHighlighted;
    };
};

export type IProjectGroupingMode = boolean;
export type TDraggingFromArea = Sections | null;
export type TTableDataID = string;
export type TTableDataIDs = TTableDataID[];
export type THasMoreHours = boolean;
export type THasMoreUnassignedTasks = boolean;
export type TIsDragging = boolean;
export type TSnapToZero = boolean;
export type TShowRemainingHours = boolean;
export type TShowAllocationsInHoursMode = boolean;
export type TSortUsersByRoleEnabled = boolean;
export type TProjectColorsMode = string;
export type TUnassignedWorkHeight = number;
export type TPeopleWorkLoadHeight = number;
export type TTableLeftSidePanelWidth = number;
export type TResizableHeight = string;
export type TAvailableHeight = number;
export type TIsTableCollapsed = boolean;
export type TLoadedUsersCount = number;
export type TLoadedUnassignedProjects = number;
export type TIsInLoadingStatePeopleWorkloadSection = boolean;
export type TLoadingStateUnassignedWorkSection = boolean;
export type TPeriodMode = number;
export type TShowActualProgress = boolean;
export type TMittEmitter = mitt.Emitter;
export type TTableWidth = number;
export type TIDExpressionForMinixState = string | null;
export type TShowRoleSummary = boolean;
export type TContouringRowID = string | null;
export type TShowIssues = boolean;
export type TStartDate = Moment;
export type TEndDate = Moment;
export type TAddedUsersByAssignment = string[];
export type TEspProductEnabled = boolean;
export type TDropdownOpened = string | null; // row id for opened dropdown;
export type TObjectsInAssignmentMode = string[] | null;
export type TSteps = Moment[];
export type TWorkRequired = number;
export type TUnassignedWorkEmptyRowHeight = number;
export type TOrUndefined<T> = T | undefined;
export type TAssignmentID = string;
export type TUserRoleId = string;
export type TUserName = string;
export type TRoleName = string;
export type TUserRoleIds = TUserRoleId[];
export type TUserIsInAssignmentProcess = boolean;
export type TIsPanelOpened = boolean;
export type TIsBulkAssignmentPanelOpened = boolean;
