import { HandleClickOutside, Tooltip, Button } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import CheckmarkIcon from 'phoenix-icons/dist/CheckmarkIcon.js';
import LinkIcon from 'phoenix-icons/dist/LinkIcon.js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { settingsSelector } from '../../../../data-flow/settings/settingsActions/settingsSelector';
import { IWorkSchedulingCombinedState } from '../../../../data-flow/types';
import { prepareShareUrl } from '../../../../util/utilities';
import {
    CheckMarkIconContainer,
    ShareLinkContainer,
    ShareLinkPopover,
    Title,
    Url,
} from '../../../styles/shareWorkloadBalancerStyles';
import { IAreaState } from '../../../../data-flow/areaData/areaRelatedInitialDataState';
import { getSchedulingAreaData } from '../../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { getAdditionalConfig } from './utils';
import { getHeaderItemStyles } from '../../../styles/headerComponentStyles';

interface IShareWorkloadBalancerStateToProps {
    schedulingAreaData: IAreaState;
    settings: unknown;
}

interface IShareWorkloadBalancerProps {
    shareLinkButtonClicked: () => void;
}

type IShareWorkloadBalancerAllProps = IShareWorkloadBalancerStateToProps &
    IShareWorkloadBalancerProps;

export const ShareWorkloadBalancerDisconnected: React.FunctionComponent<
    IShareWorkloadBalancerAllProps
> = ({ shareLinkButtonClicked, schedulingAreaData, settings }) => {
    const { schedulingAreaObjCode } = schedulingAreaData;
    const inputRef = useRef<HTMLInputElement>();
    const popperRef = useRef<HTMLDivElement>();

    const [showPopper, setShowPopper] = useState(false);
    const [generatedUrl, setGeneratedUrl] = useState('');

    const handleClick = useCallback(
        (e, toggle) => {
            shareLinkButtonClicked();
            if (!toggle && popperRef.current && popperRef.current.contains(e.target)) {
                return;
            }

            if (toggle) {
                const additionalConfig = getAdditionalConfig(
                    schedulingAreaData.schedulingAreaObjCode
                );
                const url = prepareShareUrl(settings, schedulingAreaData, additionalConfig);
                setGeneratedUrl(url);

                Promise.resolve().then(() => {
                    if (inputRef && inputRef.current) {
                        inputRef.current.select();
                        document.execCommand('copy');
                    }
                });
            }

            setShowPopper(toggle);
        },
        [schedulingAreaData, settings, shareLinkButtonClicked]
    );

    useEffect(() => {
        if (generatedUrl && inputRef.current) {
            inputRef.current.select();
            document.execCommand('copy');
        }
    }, [generatedUrl, showPopper]);

    const shareButtonClassName = getHeaderItemStyles(showPopper);

    return (
        <Localization<string[]>
            messageKeys={[
                'resourcescheduling.url.sharable.link',
                'workloadbalancer.generate.sharable.link',
                'workloadbalancer.generate.url.copy',
                'workloadbalancer.generate.url.copy',
            ]}
        >
            {({ _t }) => {
                return (
                    <HandleClickOutside onOutsideClick={() => setShowPopper(false)} escapeListener>
                        {() => (
                            <ShareLinkContainer>
                                <Tooltip
                                    content={_t['resourcescheduling.url.sharable.link'](
                                        'Sharable link'
                                    )}
                                >
                                    <Button
                                        text
                                        className={shareButtonClassName}
                                        onClick={(e) => handleClick(e, !showPopper)}
                                        data-testid={`generate-url${schedulingAreaObjCode}`}
                                        aria-label={_t['workloadbalancer.generate.sharable.link'](
                                            'Generate sharable link'
                                        )}
                                        aria-haspopup="true"
                                        aria-expanded={showPopper}
                                    >
                                        <LinkIcon focusable="false" />
                                    </Button>
                                </Tooltip>
                                <ShareLinkPopover
                                    innerRef={popperRef}
                                    data-testid="generate-url-popover"
                                    showPopper={showPopper}
                                >
                                    <Title>
                                        <CheckMarkIconContainer>
                                            <CheckmarkIcon focusable="false" color="white" />
                                        </CheckMarkIconContainer>
                                        <Localization<string>
                                            messageKey="resourcescheduling.url.copy"
                                            fallback="Link copied to clipboard"
                                        />
                                    </Title>
                                    <Url
                                        wrap="off"
                                        aria-label={_t['workloadbalancer.generate.url.copy'](
                                            'Generated link copied to clipboard'
                                        )}
                                        innerRef={inputRef}
                                        value={generatedUrl}
                                    />
                                </ShareLinkPopover>
                            </ShareLinkContainer>
                        )}
                    </HandleClickOutside>
                );
            }}
        </Localization>
    );
};

const mapStateToProps = (
    state: IWorkSchedulingCombinedState
): IShareWorkloadBalancerStateToProps => ({
    settings: settingsSelector(state),
    schedulingAreaData: getSchedulingAreaData(state),
});

export const ShareWorkloadBalancer = connect(mapStateToProps)(ShareWorkloadBalancerDisconnected);
