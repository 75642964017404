import { LabelDropdown, Option, Options, Tooltip } from '@phoenix/all';
import * as React from 'react';
import { Manager, Reference } from 'react-popper';
import { connect } from 'react-redux';
import { OpTask, Task } from 'workfront-objcodes';
import { GlobalPropsContext } from '../../../contexts/globalContexts';
import changeContouringRowState from '../../../data-flow/data/assignedDataActions/changeContouringRowState';
import {
    contouringRowIdSelector,
    loadWorkDelegationsSelector,
} from '../../../data-flow/data/selectors/dataSelectors';
import changeDropdownOpened from '../../../data-flow/data/sharedActions/changeDropdownOpenedState';
import changeIDExpressionForMinixState from '../../../data-flow/data/sharedActions/changeIDExpressionForMinixState';
import { endDateSelector } from '../../../data-flow/dateRange/selectors/endDateSelector';
import { startDateSelector } from '../../../data-flow/dateRange/selectors/startDateSelector';
import { stepUnitSelector } from '../../../data-flow/dateRange/selectors/stepUnitSelector';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import { IWorkSchedulingCombinedState, TTypeOfIDs } from '../../../data-flow/types';
import { getDropDownOptions } from '../../../util/actionsComponentService';
import {
    getOpenedDialogsDetails,
    pendoAnalyticsTracker,
} from '../../../util/analytics/pendoAnalyticsTracker';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { AssignmentDialog, openAssignmentDialog } from './AssignmentDialog';
import { ThreeDot } from './ThreeDot';
import { closeAllPanelsChain } from '../../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import { isTaskIssueGhost } from '../../../util/utilities';
import { showAllocationsInHoursModeSelector } from '../../../data-flow/settings/settingsSelector';
import { objectAssignmentsDetailsSelector } from '../../../data-flow/data/selectors/reselect/objectAssignmentsDetailsSelector/objectAssignmentsDetailsSelector';
import { getContextValue } from '../../../contexts/checkContext';

const useDropdownState = (dispatch, idExpression): void => {
    React.useEffect(
        () => {
            dispatch(changeDropdownOpened(idExpression));
            return () => dispatch(changeDropdownOpened(null));
        },
        // "dispatch" reference is never going to change
        [idExpression] // eslint-disable-line react-hooks/exhaustive-deps
    );
};

const CustomOptions: React.FC<any> = ({ dispatch, idExpression, ...props }) => {
    useDropdownState(dispatch, idExpression);

    const dropdownLabels = {
        'resourcescheduling.assign.this.to': localizationClient.getTextSync(
            'resourcescheduling.assign.this.to',
            'Assign this to'
        ),
        'workloadbalancer.edit.allocation': localizationClient.getTextSync(
            'workloadbalancer.edit.allocation',
            'Edit allocations'
        ),
        'minix.open': localizationClient.getTextSync('minix.open', 'Open summary'),
    };

    const options = getDropDownOptions(
        dropdownLabels,
        props.assignAccess,
        props.sectionType,
        props.contouringEditAccess,
        props.isGhost
    );

    return (
        <Options disableMark={false} {...props}>
            {options.map((item) => {
                const { value, label } = item;
                const optionProps = {
                    value,
                    key: value,
                    testID: value,
                };
                if (item.toolTipContent) {
                    return (
                        <Option {...optionProps}>
                            <Tooltip
                                content={localizationClient.getTextSync(
                                    item.toolTipContent.messageKey,
                                    item.toolTipContent.fallBack
                                )}
                                position="right-end"
                            >
                                <span aria-label={label}>{label}</span>
                            </Tooltip>
                        </Option>
                    );
                }

                return (
                    <Option {...optionProps} label={label}>
                        {label}
                    </Option>
                );
            })}
        </Options>
    );
};

const openDialog = (actionType, props, globalPropsContext): void => {
    const {
        objCode,
        ID,
        hasAssignmentsDetails,
        dispatch,
        idExpression,
        sectionType,
        contouringRowID,
        loadWorkDelegations,
    } = props;

    if (actionType === 'minixOpen') {
        globalPropsContext.minixOpen({ objCode, ID });
        pendoAnalyticsTracker(
            getOpenedDialogsDetails('Open', sectionType, 'Summary from 3 dot menu')
        );
        dispatch(
            actionChain([changeIDExpressionForMinixState(idExpression), ...closeAllPanelsChain()])
        );
    } else if (actionType === 'assignmentWidgetOpen') {
        const IDs: TTypeOfIDs = {
            ID,
            idExpression,
            contouringRowID,
        };
        openAssignmentDialog(
            dispatch,
            objCode,
            hasAssignmentsDetails,
            IDs,
            sectionType,
            loadWorkDelegations
        );
    } else {
        dispatch(changeContouringRowState(idExpression));
    }
};

export const AssignmentActions: React.FC<any> = (props: any) => {
    const globalPropsContext = React.useContext(GlobalPropsContext);
    const {
        objCode,
        sectionType,
        plannedStartDate,
        plannedCompletionDate,
        startDate,
        endDate,
        stepUnit,
        dispatch,
        idExpression,
        assignAccess,
        ID,
        contouringEditAccess,
        showAllocationsInHoursMode,
        assignmentWidth,
        allocationsRef,
        lastCellAllocationNumber,
        lastCellAllocationWidth,
    } = props;

    if (getContextValue('sharableLink')) {
        return null;
    }

    const isGhost = isTaskIssueGhost(
        {
            plannedStartDate,
            plannedCompletionDate,
        },
        {
            startDate,
            endDate,
        },
        stepUnit
    );
    const btnMessageKey = {
        [Task]: {
            messageKey: 'workloadbalancer.open.task.menu',
            fallBack: 'Open task options menu',
        },
        [OpTask]: {
            messageKey: 'workloadbalancer.open.issue.menu',
            fallBack: 'Open issue options menu',
        },
    };

    return (
        <>
            <Manager>
                <Reference>
                    {({ ref }) => (
                        <div ref={ref}>
                            <LabelDropdown
                                ariaLabel={localizationClient.getTextSync(
                                    btnMessageKey[objCode].messageKey,
                                    btnMessageKey[objCode].fallBack
                                )}
                                onChange={(actionType) => {
                                    openDialog(actionType, props, globalPropsContext);
                                }}
                                options={
                                    <CustomOptions
                                        dispatch={dispatch}
                                        idExpression={idExpression}
                                        assignAccess={assignAccess}
                                        sectionType={sectionType}
                                        contouringEditAccess={contouringEditAccess}
                                        isGhost={isGhost}
                                        showAllocationsInHoursMode={showAllocationsInHoursMode}
                                    />
                                }
                                trigger={
                                    <ThreeDot
                                        assignmentWidth={assignmentWidth}
                                        sectionType={sectionType}
                                        allocationsRef={allocationsRef}
                                        lastCellAllocationNumber={lastCellAllocationNumber}
                                        lastCellAllocationWidth={lastCellAllocationWidth}
                                        objCode={objCode}
                                    />
                                }
                            />
                        </div>
                    )}
                </Reference>

                <AssignmentDialog
                    ID={ID}
                    idExpression={idExpression}
                    objCode={objCode}
                    sectionType={sectionType}
                    isGhost={isGhost}
                />
            </Manager>
        </>
    );
};

const mapStateToProps = (state: IWorkSchedulingCombinedState, ownProps): any => {
    return {
        hasAssignmentsDetails: !!objectAssignmentsDetailsSelector(state)(ownProps.ID),
        contouringEditAccess: state.currentUser.contouringEditAccess,
        stepUnit: stepUnitSelector(state),
        endDate: endDateSelector(state),
        startDate: startDateSelector(state),
        contouringRowID: contouringRowIdSelector(state),
        showAllocationsInHoursMode: showAllocationsInHoursModeSelector(state),
        loadWorkDelegations: loadWorkDelegationsSelector(state),
    };
};

export const AssignmentActionsComponent = connect(mapStateToProps)(AssignmentActions);
