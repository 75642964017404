import { primary } from '@phoenix/all';
import MoreStrokeIcon from 'phoenix-icons/dist/MoreStrokeIcon.js';
import * as React from 'react';
import { connect } from 'react-redux';
import { TObjCode } from 'workfront-objcodes';
import { resetContouringEditMode } from '../../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import { contouringRowIdSelector } from '../../../data-flow/data/selectors/dataSelectors';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../data-flow/types';
import { Sections } from '../../../constants/schedulingTableConstants';
import { showAllocationsSelector } from '../../../data-flow/settings/settingsSelector';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { TContouringRowID } from '../../../data-flow/data/IDataState';

interface IThreeDotProps {
    assignmentWidth: number;
    lastCellAllocationWidth: number;
    sectionType: Sections;
    allocationsRef: any;
    lastCellAllocationNumber: number | undefined;
    objCode: TObjCode;
}

interface IThreeDotStateProps {
    isShowAllocationOn: boolean;
    contouringRowID: TContouringRowID;
}

const ThreeDotComponent = React.memo<
    IWorkSchedulingDispatchProp & IThreeDotProps & IThreeDotStateProps
>((props) => {
    const threeDotIconClickHandler = (): void => {
        if (props.contouringRowID) {
            props.dispatch(resetContouringEditMode());
        }
    };

    return (
        <>
            {/* TODO resolve eslint issue */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
                data-testid="three-dot"
                onClick={threeDotIconClickHandler}
                style={{ height: '24px' }}
                aria-label={localizationClient.getTextSync(
                    'workloadbalancer.open.actions.menu',
                    'Open actions menu'
                )}
            >
                <MoreStrokeIcon color={primary.gray(600)} />
            </div>
        </>
    );
});

const mapStateToProps = (state: IWorkSchedulingCombinedState): IThreeDotStateProps => {
    return {
        isShowAllocationOn: showAllocationsSelector(state),
        contouringRowID: contouringRowIdSelector(state),
    };
};

export const ThreeDot = connect(mapStateToProps)(ThreeDotComponent);
