import { TModernSchedulingAction } from '../../../types';
import { IProjectColorList } from '../../../data/IDataState';

export const ActionType = 'ADD_PROJECT_COLORS';

interface IAddProjectColorsActionDetailsPayload {
    projectColors: IProjectColorList;
}

export type TAddProjectColorsActionDetailsPayload =
    TModernSchedulingAction<IAddProjectColorsActionDetailsPayload>;

const addProjectColors = (
    projectColors: IProjectColorList
): TAddProjectColorsActionDetailsPayload => ({
    type: ActionType,
    payload: {
        projectColors,
    },
});

export default addProjectColors;

export function handle(
    state: IProjectColorList,
    { payload }: TAddProjectColorsActionDetailsPayload
): IProjectColorList {
    return {
        ...state,
        ...payload.projectColors,
    };
}
