import _ from 'lodash';
import { TModernSchedulingAction } from '../../types';
import { IAvailableHoursForPeriod, IDataState } from '../IDataState';

export const ActionType = 'ADD_AVAILABLE_HOURS_FOR_PERIOD';

interface IAddAvailableHoursForPeriodPayload {
    availableHoursForPeriod: IAvailableHoursForPeriod;
}

type IAddAvailableHoursForPeriodAction =
    TModernSchedulingAction<IAddAvailableHoursForPeriodPayload>;

const addAvailableHoursForPeriod = (
    availableHoursForPeriod
): IAddAvailableHoursForPeriodAction => ({
    type: ActionType,
    payload: {
        availableHoursForPeriod,
    },
});
export default addAvailableHoursForPeriod;

export function handle(
    state: IDataState,
    { payload }: IAddAvailableHoursForPeriodAction
): IDataState {
    const users = { ...state.users };

    _.forEach(payload.availableHoursForPeriod.PAVL, (purAvailableHours, userID) => {
        users[userID] = {
            ...users[userID],
            availableHoursForPeriod: payload.availableHoursForPeriod.AVL[userID] || [],
            purAvailableHoursForPeriod: purAvailableHours || [],
        };
    });

    return {
        ...state,
        users,
    };
}
