import { TModernSchedulingAction } from '../../types';
import { IDataState, TContouringRowID } from '../IDataState';

export const ActionType = 'CHANE_ASSIGNMENT_DIALOG_DETAILS';

interface IEditRowPayload {
    rowID: TContouringRowID;
    position: {
        x: number;
        y: number;
    };
    showLoading: boolean;
}

type TEditRowAction = TModernSchedulingAction<IEditRowPayload>;

const changeAssignmentDialogDetails = (rowID, position?, showLoading?): TEditRowAction => ({
    type: ActionType,
    payload: {
        rowID,
        position,
        showLoading,
    },
});
export default changeAssignmentDialogDetails;

export function handle(state: IDataState, { payload }: TEditRowAction): IDataState {
    const { rowID, position, showLoading } = payload;
    let positionX = 0;
    let positionY = 0;

    if (position && rowID) {
        positionX = position.x;
        positionY = position.y;
    }

    return {
        ...state,
        assignmentDialogDetails: {
            ID: rowID,
            positionX,
            positionY,
            showLoading,
        },
    };
}
