import { Moment } from 'moment';
import { TimeUnit } from '../../constants/periodEnums';
import { dateFormats } from '../../constants/dataConstatnts';
import { UnitOfTime } from '../../data-flow/types';
import { getWeekEnd } from '../periodUtils/periodUtils';
import { localizationClient } from '../../constants/LocalizationClientFactory';
import { convertMinuteToHour } from '../utilities';

export const getAriaLabelForHour = (
    date: Moment,
    stepUnit: UnitOfTime,
    hoursDiff: number,
    workRequired: number
): string => {
    const dateFormat = dateFormats[stepUnit] || dateFormats[TimeUnit.DAY];
    const hourDiffInfo: string =
        hoursDiff === 0 ? '' : ` ${inputHourDiffInfo(hoursDiff, workRequired)}`;
    if (stepUnit === TimeUnit.WEEK) {
        const endOfWeek = getWeekEnd(date);
        const week = localizationClient.getTextSync('week', 'Week');
        return `${week} ${date.clone().isoWeek() + 1} ${date.format(
            dateFormat
        )} - ${endOfWeek.format(dateFormat)}${hourDiffInfo}`;
    }
    return date.format(dateFormat) + hourDiffInfo;
};

export const inputHourDiffInfo = (hoursDiff: number, workRequired: number): string => {
    const totalWorkRequired = localizationClient.getTextSync('hour.total', 'Total');
    const difference = localizationClient.getTextSync('resourceplanner.diff', 'Difference');

    return `${difference} ${convertMinuteToHour(
        hoursDiff
    )} ${totalWorkRequired} ${convertMinuteToHour(workRequired)}`;
};

export const getAriaLabelForSaveButton = (
    isSaveDisabled: boolean,
    hoursDiff: number,
    workRequired: number
): string => {
    const saveButtonMessage = localizationClient.getTextSync(
        'workloadbalancer.save.allocation',
        'Save Allocations'
    );
    const notEqualWarningMessage = localizationClient.getTextSync(
        'workloadbalancer.allocation.does.not.equal',
        "Your new allocation doesn't match. Please update your numbers."
    );
    if (!isSaveDisabled) return saveButtonMessage;
    return `${saveButtonMessage} ${inputHourDiffInfo(
        hoursDiff,
        workRequired
    )} ${notEqualWarningMessage}`;
};
