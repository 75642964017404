import { cx } from 'emotion';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { Sections } from '../../../../constants/schedulingTableConstants';
import { tableDataIDsForUnassignedSectionSelector } from '../../../../data-flow/data/selectors/dataSelectors';
import { stepsSelector } from '../../../../data-flow/dateRange/selectors/stepsSelector';
import { IWorkSchedulingCombinedState } from '../../../../data-flow/types';
import { cellWithoutBorderBottom, cellWrapper, unassignedCell } from '../../../styles/sharedStyles';
import { Assignment } from '../Assignment';
import { ProjectAssignment } from './ProjectAssignment';
import { projectGroupingModeSelector } from '../../../../data-flow/settings/settingsSelector';

interface IAssignmentsProps {
    idExpression: string;
    isAssigned: boolean;
}

interface IAssignmentsStateToProps {
    isThereUnassignedEmptySpace: boolean;
    steps: Moment[];
    projectGroupingMode: boolean;
    isLastRowUnderProject: boolean;
    isLastRow: boolean;
    unassignedTasksTableIds: string[];
}

const AssignmentsComponent: React.FunctionComponent<
    IAssignmentsProps & IAssignmentsStateToProps
> = (props) => {
    const { projectGroupingMode, idExpression, unassignedTasksTableIds } = props;
    const idsArray = idExpression.split('_');
    let assignmentComponent;
    const isTaskOrIssueCondition =
        (projectGroupingMode && idsArray.length === 2) ||
        (!projectGroupingMode && idsArray.length === 1);
    if (idsArray.length === 1 && projectGroupingMode) {
        assignmentComponent = (
            <ProjectAssignment
                idExpression={props.idExpression}
                isAssigned={false}
                sectionType={Sections.UNASSIGNED_WORK}
            />
        );
    } else {
        assignmentComponent = (
            <Assignment
                ID={projectGroupingMode ? idsArray[1] : idsArray[0]}
                sectionType={Sections.UNASSIGNED_WORK}
                isAssigned={false}
                idExpression={idExpression}
                isTheLastRow={false}
            />
        );
    }

    const isLastRowAssignmentUnderTheProject =
        isTaskOrIssueCondition && !props.isLastRowUnderProject;
    const isLastRowProjectWithEmptySpaceUnderIt =
        props.isLastRow && !props.isThereUnassignedEmptySpace;

    const lastIdExpression = idsArray[idsArray.length - 1];
    const firstIdExpression = idsArray[0];
    const isRowOpened = useMemo(() => {
        return unassignedTasksTableIds.some((item) => {
            const splitIdExpression = item.split('_');
            return splitIdExpression.length === 2 && splitIdExpression[0] === firstIdExpression;
        });
    }, [firstIdExpression, unassignedTasksTableIds]);
    const cellSolidBorderBottom = !isTaskOrIssueCondition && isRowOpened;
    return (
        <React.Fragment>
            {assignmentComponent}
            <div
                className={cellWrapper}
                data-testid={`user-assignment-wrapper-${lastIdExpression}`}
            >
                {props.steps.map((step) => {
                    return (
                        <div
                            data-testid="assignment-cell-weekday"
                            key={step.unix()}
                            className={cx(
                                isLastRowAssignmentUnderTheProject ||
                                    isLastRowProjectWithEmptySpaceUnderIt
                                    ? cellWithoutBorderBottom
                                    : '',
                                unassignedCell(cellSolidBorderBottom),
                                `cell-weekday-unassigned_${idExpression}`
                            )}
                        />
                    );
                })}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (
    state: IWorkSchedulingCombinedState,
    ownProps: { idExpression: string }
): IAssignmentsStateToProps => {
    const idsArray = ownProps.idExpression.split('_');
    const steps = stepsSelector(state);
    let isLastRowUnderProject = false;
    let isLastRow = false;
    if (projectGroupingModeSelector(state)) {
        const unassignedTasksByProject = state.Data.unassignedTasksProjects[idsArray[0]].nodes;
        isLastRowUnderProject =
            idsArray[1] === unassignedTasksByProject[unassignedTasksByProject.length - 1];
        isLastRow =
            tableDataIDsForUnassignedSectionSelector(state).indexOf(ownProps.idExpression) >=
            tableDataIDsForUnassignedSectionSelector(state).length - 1;
    }

    return {
        isThereUnassignedEmptySpace: state.Data.unassignedWorkEmptyRowHeight > 0,
        unassignedTasksTableIds: tableDataIDsForUnassignedSectionSelector(state),
        steps,
        projectGroupingMode: projectGroupingModeSelector(state),
        isLastRowUnderProject,
        isLastRow,
    };
};

export const AssignmentsForUnassignedSection = connect(mapStateToProps)(AssignmentsComponent);
