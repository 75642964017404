import { primary } from '@phoenix/all';
import styled, { css } from 'react-emotion';

import { sizes } from '../../../constants/schedulingTableConstants';
import { inlineItems } from '../../../styles';
import { IStartEndWeeks } from '../../../util/periodUtils/periodUtils';

export const cellPadding = css`
    padding: 0px 13px 0px 36px;
    line-height: ${sizes.tableHeaderRowHeight}px;
    display: flex;
    justify-content: space-between;
`;

export const sectionNameStyle = css`
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 70%;
    display: inline-block;
    overflow: hidden;
    z-index: 1;
`;
export const arrowButtonCollapseAndExpand = css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    left: 0;
`;
export const borderBottomRemovedStyle = css`
    border-bottom: 0 !important;
`;

export const borderTopRemovedStyle = css`
    border-top: 0 !important;
`;

export const tableHeaderLeftStyles = css`
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    position: relative;
    text-transform: uppercase;
    flex: 0 0 ${sizes.tableLeftColumnWidth}px;
    background-color: ${primary.gray(50)};
    border-bottom: 1px solid ${primary.gray(200)};
    border-top: 1px solid ${primary.gray(200)};
`;

// TODO where is flex-shrink: 1;
export const tableHeaderRightWrapper = css`
    ${inlineItems};
    z-index: 1;
    background-color: ${primary.gray(50)};
    border-bottom: 1px solid ${primary.gray(200)};
    border-top: 1px solid ${primary.gray(200)};
    flex-basis: 0;
    flex-grow: 1;
    border-right: 1px solid ${primary.gray(200)};
`;

const paddingLeft = `padding-left: 40px; border-left: 1px solid ${primary.gray(200)}`;

const paddingRight = `padding-right: 40px; border-right: 1px solid ${primary.gray(200)}`;

export const TableHeaderRightWrapperStyled = styled('div')<{ isHalfStartEndWeeks: IStartEndWeeks }>`
    ${tableHeaderRightWrapper}
    ${(props) => props.isHalfStartEndWeeks.isHalfStartWeek && paddingLeft};
    ${(props) => props.isHalfStartEndWeeks.isHalfEndWeek && paddingRight};
`;
