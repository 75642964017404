import React from 'react';
import { css, cx } from 'react-emotion';
import { primary } from '@phoenix/all';
import { dataTestIds } from './ActualProgressBlockWrapper';
import { arrowsSharpness, beforeAndAfterPeriod } from '../../../styles/sharedStyles';
import { sizes } from '../../../../constants/schedulingTableConstants';
import { borderColorRedesign, IActualAssignmentBar } from './ActualAssignmentBar';
import { projectedAssignmentArrowsZIndex } from '../../../../constants/zIndexes';

interface ILeftRightArrowWrapper
    extends Pick<
        IActualAssignmentBar,
        | 'isThereProjectedBeforeArrow'
        | 'isThereProjectedAfterArrow'
        | 'isForProject'
        | 'color'
        | 'height'
        | 'taskAssignmentBackgroundColor'
        | 'plannedAndActualAssignmentsOverlap'
        | 'durationPadding'
    > {
    fromLeft: number;
}

export const leftRightArrowDataTestIds = {
    left_arrow_without_overlap: 'left_arrow_without_overlap',
    right_arrow_without_overlap: 'right_arrow_without_overlap',
};

export const LeftRightArrowWrapper: React.FunctionComponent<ILeftRightArrowWrapper> = React.memo(
    function LeftRightArrowWrapper(props) {
        const {
            isThereProjectedBeforeArrow,
            isThereProjectedAfterArrow,
            isForProject,
            color,
            height,
            taskAssignmentBackgroundColor,
            plannedAndActualAssignmentsOverlap,
            fromLeft,
            durationPadding,
        } = props;

        const taskAssignmentWithoutOverlap = !plannedAndActualAssignmentsOverlap && !isForProject;

        const leftArrowWithoutOverlap = isThereProjectedBeforeArrow && taskAssignmentWithoutOverlap;
        const rightArrowWithoutOverlap = isThereProjectedAfterArrow && taskAssignmentWithoutOverlap;

        const projectedLeftArrow = (
            <>
                {leftArrowWithoutOverlap && (
                    <div
                        data-testid={leftRightArrowDataTestIds.left_arrow_without_overlap}
                        className={leftArrowWithoutOverlapClass}
                    />
                )}
                {isThereProjectedBeforeArrow && (
                    <div
                        data-testid={dataTestIds.left_arrow_actual_progress}
                        className={beforePeriodOverlay({
                            isForProject,
                            borderColor: color,
                            height,
                            taskAssignmentBackgroundColor,
                            plannedAndActualAssignmentsOverlap,
                            durationPadding,
                        })}
                    />
                )}
            </>
        );

        const projectedRightArrow = (
            <>
                {isThereProjectedAfterArrow && (
                    <div
                        data-testid={dataTestIds.right_arrow_actual_progress}
                        className={afterPeriodOverlay({
                            isForProject,
                            fromLeft,
                            borderColor: color,
                            height,
                            taskAssignmentBackgroundColor,
                            plannedAndActualAssignmentsOverlap,
                            durationPadding,
                        })}
                    />
                )}
                {rightArrowWithoutOverlap && (
                    <div
                        data-testid={leftRightArrowDataTestIds.right_arrow_without_overlap}
                        className={rightArrowWithoutOverlapClass(fromLeft)}
                    />
                )}
            </>
        );

        return (
            <>
                {projectedLeftArrow}
                {props.children}
                {projectedRightArrow}
            </>
        );
    }
);
const sideLength = 16;

const withoutOverlapBaseClass = css`
    height: ${sideLength}px;
    width: ${sideLength}px;
    border: 1px solid ${primary.blue(600)};
    border-right-width: 0;
    border-bottom-width: 0;
    position: absolute;
    z-index: ${projectedAssignmentArrowsZIndex};
`;

const leftArrowWithoutOverlapClass = css`
    ${withoutOverlapBaseClass};
    transform: rotate(-45deg) skew(-8deg, -8deg);
    left: 6px;
`;

const rightArrowWithoutOverlapClass = (fromLeft: number): string => css`
    ${withoutOverlapBaseClass};
    transform: rotate(135deg) skew(-8deg, -8deg);
    left: ${fromLeft - 8}px;
`;

const borderWidths = (isForProject?: boolean): string => {
    const thickerArrow = `border-bottom-width: 15px; border-top-width: 15px;`;
    return isForProject ? `${thickerArrow}` : '';
};

interface IAfterBeforePeriodOverlay
    extends Pick<
        IActualAssignmentBar,
        | 'isForProject'
        | 'height'
        | 'taskAssignmentBackgroundColor'
        | 'plannedAndActualAssignmentsOverlap'
        | 'durationPadding'
    > {
    fromLeft?: number;
    borderColor: IActualAssignmentBar['color'];
}

const beforePeriodOverlay = ({
    isForProject,
    height,
    taskAssignmentBackgroundColor,
    plannedAndActualAssignmentsOverlap,
    durationPadding,
}: IAfterBeforePeriodOverlay): string => {
    const baseRule = css`
        ${beforeAndAfterPeriod}
        ${borderWidths(isForProject)}
        position: absolute;
        left: 4px;
    `;
    const afterAndBefore = !plannedAndActualAssignmentsOverlap
        ? ''
        : css`
              &:before {
                  ${baseRule};
                  content: '';
                  position: absolute;
                  top: -13px;
                  left: 1px;
                  border-right: 10px solid ${taskAssignmentBackgroundColor};
              }
              &:after {
                  ${baseRule};
                  content: '';
                  position: absolute;
                  top: -13px;
                  left: 0;
                  border-right: 10px solid ${borderColorRedesign};
              }
          `;

    if (!isForProject) {
        const borderRightColor = !plannedAndActualAssignmentsOverlap
            ? borderColorRedesign
            : primary.blue(600);

        return cx(
            baseRule,
            css`
                position: absolute;
                left: 4px;
                border-right: 10px solid ${borderRightColor};
                z-index: ${projectedAssignmentArrowsZIndex};
                ${afterAndBefore};
            `
        );
    }
    const left = isForProject ? 0 : durationPadding;
    return cx(
        baseRule,
        css`
            height: ${height}px;
            border-bottom-width: ${arrowsSharpness}px;
            border-top-width: ${arrowsSharpness}px;
            border-right: ${sizes.assignmentArrowSize}px solid ${borderColorRedesign};
            left: ${left}px;
        `
    );
};

const afterPeriodOverlay = ({
    isForProject,
    fromLeft,
    height,
    taskAssignmentBackgroundColor,
    plannedAndActualAssignmentsOverlap,
    durationPadding,
}: IAfterBeforePeriodOverlay): string => {
    const baseRule = css`
        ${beforeAndAfterPeriod}
        ${borderWidths(isForProject)};
        position: absolute;
        left: ${fromLeft}px;
    `;

    const afterAndBefore = !plannedAndActualAssignmentsOverlap
        ? ''
        : css`
              &:before {
                  ${baseRule};
                  content: '';
                  position: absolute;
                  top: -13px;
                  right: 1px;
                  left: unset;
                  border-left: 10px solid ${taskAssignmentBackgroundColor};
              }
              &:after {
                  ${baseRule};
                  content: '';
                  position: absolute;
                  top: -13px;
                  right: 1px;
                  left: unset;
                  border-left: 10px solid ${borderColorRedesign};
              }
          `;

    if (!isForProject) {
        const borderLeftColor = !plannedAndActualAssignmentsOverlap
            ? borderColorRedesign
            : primary.blue(600);

        return cx(
            baseRule,
            css`
                position: absolute;
                border-left: 10px solid ${borderLeftColor};
                z-index: ${projectedAssignmentArrowsZIndex};
                ${afterAndBefore};
            `
        );
    }
    const leftOffset = fromLeft as number;
    const left = isForProject ? leftOffset + durationPadding : fromLeft;
    return cx(
        baseRule,
        css`
            height: ${height}px;
            border-bottom-width: ${arrowsSharpness}px;
            border-top-width: ${arrowsSharpness}px;
            border-left: ${sizes.assignmentArrowSize}px solid ${borderColorRedesign};
            left: ${left}px;
        `
    );
};
