export enum SortModeActions {
    BY_ROLE = 'by_role',
    ALPHABETICALLY = 'alphabetically',
}

export const SORT_USERS_MODES = {
    by_role: {
        messageKey: 'workloadbalancer.settings.sort.by.primary.role',
        fallBack: 'By Primary Role',
        ariaMessageKey: 'workloadbalancer.settings.sort.by.role.description',
        ariaFallBack: 'Sort users by their Primary Role',
        type: SortModeActions.BY_ROLE,
    },
    alphabetically: {
        messageKey: 'alphabetically',
        fallBack: 'Alphabetically',
        ariaMessageKey: 'workloadbalancer.settings.sort.alphabetically.description',
        ariaFallBack: 'Sort users in alphabetical order',
        type: SortModeActions.ALPHABETICALLY,
    },
};
