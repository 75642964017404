// ready to start logic from productivity/home repository

import moment from 'moment';
import { apiDateToDate } from '@workfront/fns';

const TWO_WEEKS_FROM_TODAY = moment().add(14, 'days').startOf('day');

function lessThanTwoWeeksFromToday(taskOrIssue, propertyName): boolean {
    if (taskOrIssue[propertyName] == null) {
        return false;
    }

    const date = moment(apiDateToDate(taskOrIssue[propertyName]));

    return date.isValid() && date.isBefore(TWO_WEEKS_FROM_TODAY);
}

export function readyToStart(taskOrIssue): boolean {
    if (taskOrIssue && taskOrIssue.canStart) {
        return (
            ['plannedStartDate', 'projectedStartDate'].some(function (propertyName) {
                return (
                    taskOrIssue[propertyName] == null ||
                    lessThanTwoWeeksFromToday(taskOrIssue, propertyName)
                );
            }) || lessThanTwoWeeksFromToday(taskOrIssue, 'handoffDate')
        );
    }
    return false;
}
