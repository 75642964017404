import styled, { css } from 'react-emotion';
import { primary } from '@phoenix/all';

const synchroniseVerticalScrollStyle = `
    &::after {
       content: '';
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       pointer-events: none;
    }`;
export const SchedulingWrapperStyled = styled('div')<{
    decreaseFromWidth: number;
    sharableLink?: string;
    isAssignmentDialogOpen: boolean | '' | null;
}>`
    flex: 1 1 0%;
    overflow-x: ${(props) => (props.isAssignmentDialogOpen ? 'hidden' : 'auto')};
    position: relative;
    height: 100%;
    width: calc(100% - ${(props) => props.decreaseFromWidth}px);
    ${synchroniseVerticalScrollStyle}
`;

export const containerClassName = css`
    flex: 1 1 0%;
    position: relative;
    height: 100%;
`;

export const resizable_base_style = css`
    width: 100%;
    position: absolute;
    transform: scale(0, 0);
    left: 0;
    flex: 0;
`;

export const rootElementStyle = css`
    background-color: ${primary.white()};
    button {
        &:focus {
            box-shadow: rgb(159, 222, 253) 0px 0px 0px 3px !important;
            outline: none !important;
        }
    }
`;
