import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT } from '../constants/dataConstatnts';
import {
    INodesDetailsItemState,
    IRequestedTemporaryWorkPerDayFromService,
    IRequestedWorkPerDay,
    IRequestedWorkPerDayFromService,
} from '../data-flow/data/IDataState';

export const getWorkPerDayForPeriodFromService = (
    stepStartDate,
    stepEndDate,
    workPerDays,
    assignmentID,
    paramsForCalculatingCrossedHours,
    assignmentsRoleID: string = '',
    roleAssignments: string[] = []
): void => {
    let index = 0;

    while (stepStartDate < stepEndDate) {
        const dayWithWPDHour = stepStartDate.format(DATE_FORMAT);
        const workPerDayHours = calculateWorkPerDayHourService(
            assignmentID,
            workPerDays.workPerDaysByAssignments,
            workPerDays.temporaryWorkPerDays,
            dayWithWPDHour,
            assignmentsRoleID,
            roleAssignments
        );
        workPerDays.workPerDaysForPeriod[index] =
            (workPerDays.workPerDaysForPeriod[index] || 0) + workPerDayHours;

        if (paramsForCalculatingCrossedHours.showActualProgress) {
            const { actualCompletionDate, crossedOutHoursForPeriod } =
                paramsForCalculatingCrossedHours;
            calculateCrossedOutHoursForPeriod(
                actualCompletionDate,
                stepStartDate,
                crossedOutHoursForPeriod,
                index,
                workPerDayHours
            );
        }
        stepStartDate.add(1, 'day');
        index += 1;
    }
};

export const calculateWorkPerDayHourService = (
    assignmentID: string,
    workPerDaysByAssignments: IRequestedWorkPerDayFromService,
    temporaryWorkPerDays: IRequestedTemporaryWorkPerDayFromService,
    dayWithWPDHour: string,
    assignmentsRoleID: string,
    roleAssignments: string[]
): unknown => {
    if (assignmentID) {
        if (workPerDaysByAssignments[assignmentID]) {
            return calculateWPDHoursFormAssignmentsService(
                roleAssignments,
                workPerDaysByAssignments,
                temporaryWorkPerDays,
                assignmentID,
                dayWithWPDHour,
                assignmentsRoleID
            );
        }
    }
    return workPerDaysByAssignments[dayWithWPDHour] || 0;
};

export const calculateWPDHoursFormAssignmentsService = (
    roleAssignments: string[],
    workPerDaysByAssignments: IRequestedWorkPerDayFromService,
    temporaryWorkPerDays: IRequestedTemporaryWorkPerDayFromService,
    assignmentID: string,
    dayWithWPDHour: string,
    assignmentsRoleID: string
): unknown => {
    if (roleAssignments.length) {
        return calculateRoleAssignments(
            assignmentsRoleID,
            roleAssignments,
            workPerDaysByAssignments,
            assignmentID,
            dayWithWPDHour
        );
    }
    if (
        temporaryWorkPerDays.assignmentID === assignmentID &&
        temporaryWorkPerDays.data[dayWithWPDHour] !== undefined
    ) {
        return temporaryWorkPerDays.data[dayWithWPDHour] || 0;
    }
    return workPerDaysByAssignments[assignmentID][dayWithWPDHour] || 0;
};

export const calculateRoleAssignments = (
    assignmentsRoleID: string,
    roleAssignments: string[],
    workPerDaysByAssignments: IRequestedWorkPerDay | IRequestedWorkPerDayFromService,
    assignmentID: string,
    dayWithWPDHour: number | string
): unknown => {
    if (assignmentsRoleID && roleAssignments.indexOf(assignmentsRoleID) !== -1) {
        return workPerDaysByAssignments[assignmentID][dayWithWPDHour] || 0;
    }
    return 0;
};
const calculateCrossedOutHoursForPeriod = (
    actualCompletionDate,
    stepStartDate,
    crossedOutHoursForPeriod,
    crossedOutHourIndex,
    workPerDayHours
): void => {
    if (actualCompletionDate && moment(actualCompletionDate).isBefore(moment(stepStartDate))) {
        crossedOutHoursForPeriod[crossedOutHourIndex] =
            (crossedOutHoursForPeriod[crossedOutHourIndex] || 0) + workPerDayHours;
    } else {
        crossedOutHoursForPeriod[crossedOutHourIndex] =
            crossedOutHoursForPeriod[crossedOutHourIndex] || 0;
    }
};

export const addInaccessibleItemsWorkPerDay = (
    inaccessibleItemsWorkPerDay,
    workPerDaysForPeriod
): void => {
    if (inaccessibleItemsWorkPerDay) {
        for (let i = 0; i < workPerDaysForPeriod.length; ++i) {
            if (
                inaccessibleItemsWorkPerDay[i] !== undefined &&
                inaccessibleItemsWorkPerDay[i] !== null
            ) {
                workPerDaysForPeriod[i] += inaccessibleItemsWorkPerDay[i];
            }
        }
    }
};

export const getInacessibleItems = (
    isProjectGroupingMode,
    projectID,
    projects,
    inAccessibleProjectIDs
): INodesDetailsItemState[] => {
    let inAccessibleItems: INodesDetailsItemState[] = [];

    if (isProjectGroupingMode) {
        if (projectID) {
            inAccessibleItems.push(projects[projectID]);
        } else {
            inAccessibleItems = inAccessibleProjectIDs.map((id: string) => projects[id]);
        }
    } else {
        inAccessibleItems = Object.keys(projects)
            .map((key) => projects[key])
            .filter(
                (project: INodesDetailsItemState) => project.inaccessibleNodesDates.length !== 0
            );
    }
    return inAccessibleItems;
};

export const addInaccessibleHoursToUserWorkPerDays = (
    userNodes,
    steps,
    stepEndDate,
    workPerDaySums
): void => {
    _.forEach(userNodes, (node) => {
        if (!_.isEmpty(node.inaccessibleNodesWorkPerDaysFromService)) {
            const stepStartDate = steps[0].clone().startOf('day');
            let index = 0;

            while (stepStartDate < stepEndDate) {
                const dayWithWPDHour = stepStartDate.format(DATE_FORMAT);
                const workPerDayHours =
                    node.inaccessibleNodesWorkPerDaysFromService[dayWithWPDHour] || 0;
                workPerDaySums[index] = (workPerDaySums[index] || 0) + workPerDayHours;
                stepStartDate.add(1, 'day');
                index += 1;
            }
        }
    });
};
