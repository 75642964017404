import { TModernSchedulingAction } from '../../types';
import { ITableSizesState } from '../ITableSizesState';

interface ISetAvailableHeightActionPayload {
    availableHeight: number;
}

export type TSetAvailableHeightAction = TModernSchedulingAction<ISetAvailableHeightActionPayload>;

export const ActionType = 'SET_AVAILABLE_HEIGHT';

const setAvailableHeight = (availableHeight: number): TSetAvailableHeightAction => ({
    type: ActionType,
    payload: {
        availableHeight,
    },
});
export default setAvailableHeight;

export function handle(
    state: ITableSizesState,
    action: TSetAvailableHeightAction
): ITableSizesState {
    return {
        ...state,
        availableHeight: action.payload.availableHeight,
    };
}
