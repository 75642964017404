import { primary } from '@phoenix/all';
import styled from 'react-emotion';
import { sizes } from '../../../constants/schedulingTableConstants';

export const TABLE_HEADER_PERIOD_CELL_PADDING = 12;

interface ITableHeaderPeriodCellProps {
    periodCellWidth: number;
    periodCellMinWidth: number;
}

export const TableHeaderPeriodCell = styled('div')<ITableHeaderPeriodCellProps>`
    padding: 0px ${TABLE_HEADER_PERIOD_CELL_PADDING}px;
    border-left: 1px solid ${primary.gray(200)};
    width: ${(props) => props.periodCellWidth}px;
    min-width: ${(props) => props.periodCellMinWidth}px;
    height: ${`${sizes.tableHeaderRowHeight}px`};
`;
