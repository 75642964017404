import { TModernSchedulingAction } from '../../types';
import { IDataState } from '../IDataState';

export const ActionType = 'CHANGE_NODE_ARROW_STATE';

interface IChangeNodeArrowStatePayload {
    ID: string;
    secondLevelID: string | undefined;
    settings: {
        needToDeleteExpandedProjectsIDs?: boolean;
        deleteAllExceptID?: boolean;
    };
}

type TChangeNodeArrowStateAction = TModernSchedulingAction<IChangeNodeArrowStatePayload>;

const changeNodeArrowState = (ID, secondLevelID, settings = {}): TChangeNodeArrowStateAction => ({
    type: ActionType,
    payload: {
        ID,
        secondLevelID, // in our case it is the projectID
        settings,
    },
});
export default changeNodeArrowState;

export function handle(state: IDataState, { payload }: TChangeNodeArrowStateAction): IDataState {
    const users = { ...state.users };
    const { settings } = payload;
    const { needToDeleteExpandedProjectsIDs = false, deleteAllExceptID = false } = settings;

    if (needToDeleteExpandedProjectsIDs) {
        users[payload.ID] = {
            ...users[payload.ID],
            expandedProjectIDs: new Set(),
        };
    } else if (deleteAllExceptID) {
        Object.keys(users).forEach((userID) => {
            if (userID !== payload.ID) {
                users[userID] = {
                    ...users[userID],
                    expandedProjectIDs: new Set(),
                    expanded: false,
                };
            }
        });
    } else if (payload.secondLevelID) {
        const newExpandedAssignedProjectIDs: Set<string> = new Set(
            users[payload.ID].expandedProjectIDs
        );

        if (users[payload.ID].expandedProjectIDs.has(payload.secondLevelID)) {
            newExpandedAssignedProjectIDs.delete(payload.secondLevelID);
        } else {
            newExpandedAssignedProjectIDs.add(payload.secondLevelID);
        }

        users[payload.ID] = {
            ...users[payload.ID],
            expandedProjectIDs: newExpandedAssignedProjectIDs,
        };
    } else {
        users[payload.ID] = {
            ...users[payload.ID],
            expanded: !users[payload.ID].expanded,
        };
    }

    return {
        ...state,
        users,
    };
}
