import createCachedSelector from 're-reselect';
import _ from 'lodash';
import {
    userAssignmentsSelector,
    userInaccessibleProjectsSelector,
    userProjectNodesByIdSelector,
    userProjectsSelector,
} from '../users/usersSelector';
import { workPerDaysByAssignmentsFromServiceSelector } from '../workPerDaysByAssignmentsFromServiceSelector';
import { stepsSelector } from '../../../dateRange/selectors/stepsSelector';
import {
    projectGroupingModeSelector,
    showActualProgressSelector,
} from '../../../settings/settingsSelector';
import { stepUnitSelector } from '../../../dateRange/selectors/stepUnitSelector';
import {
    addInaccessibleItemsWorkPerDay,
    getInacessibleItems,
    getWorkPerDayForPeriodFromService,
} from '../../../../util/getWorkPerDayForPeriod';
import { recalculateDataForActiveView } from '../../../../util/utilities';
import { tasksAndIssuesSelector, temporaryWorkPerDaysSelector } from '../dataSelectors';

import {
    IInaccessibleItemsDates,
    INodesDetailsItemState,
    INodesDetailsState,
} from '../../IDataState';
import { DATE_FORMAT } from '../../../../constants/dataConstatnts';

const inaccessibleWorkPerDaysSelectorForPeriodFromService = createCachedSelector(
    [
        projectGroupingModeSelector,
        (state, obj) => obj.projectID,
        stepsSelector,
        userProjectsSelector,
        userInaccessibleProjectsSelector,
        stepUnitSelector,
    ],
    (
        isProjectGroupingMode,
        projectID,
        steps,
        projects: INodesDetailsState,
        inAccessibleProjectIDs,
        stepUnit
    ) => {
        const inAccessibleItems = getInacessibleItems(
            isProjectGroupingMode,
            projectID,
            projects,
            inAccessibleProjectIDs
        );
        const workPerDaysForPeriod: number[] = [];
        const stepEndDate = steps[steps.length - 1].clone().endOf(stepUnit);

        _.forEach(inAccessibleItems, (project: INodesDetailsItemState) => {
            _.forEach(
                project.inaccessibleNodesDates,
                (inaccessibleNodeDates: IInaccessibleItemsDates, nodeIndex: number) => {
                    const stepStartDate = steps[0].clone().startOf(stepUnit);
                    let index = 0;

                    while (stepStartDate < stepEndDate) {
                        const diffByDay = stepStartDate.format(DATE_FORMAT);
                        const workPerDayHours =
                            project.inaccessibleNodesWorkPerDays[nodeIndex][diffByDay] || 0;

                        workPerDaysForPeriod[index] =
                            (workPerDaysForPeriod[index] || 0) + workPerDayHours;
                        stepStartDate.add(1, 'day');
                        index += 1;
                    }
                }
            );
        });

        return recalculateDataForActiveView(workPerDaysForPeriod, stepUnit, steps[0]);
    }
)(
    (state, { projectID, userID }) =>
        `${userID}:${projectID || 'inaccessible'}:${projectGroupingModeSelector(state)}`
);

export const workPerDaysSelectorForPeriodFromService = createCachedSelector(
    [
        userAssignmentsSelector,
        workPerDaysByAssignmentsFromServiceSelector,
        temporaryWorkPerDaysSelector,
        tasksAndIssuesSelector,
        stepsSelector,
        (state, obj) => (obj.taskID ? obj.taskID : userProjectNodesByIdSelector(state, obj)), // TODO change taskID it can be also IssueID
        (state, obj) =>
            !projectGroupingModeSelector(state) || obj.taskID
                ? undefined
                : inaccessibleWorkPerDaysSelectorForPeriodFromService(state, obj),
        stepUnitSelector,
        showActualProgressSelector,
    ],
    (
        assignments,
        workPerDaysByAssignmentsFromService,
        temporaryWorkPerDays,
        tasksAndIssues,
        steps,
        taskIssueIDs: string[] | string,
        inaccessibleItemsWorkPerDay: number[] | undefined,
        stepUnit,
        showActualProgress
    ) => {
        // this method is really crazy, be carefull here
        let workPerDaysForPeriod: number[] = [];
        let crossedOutHoursForPeriod: number[] = [];

        const stepEndDate = steps[steps.length - 1].clone().endOf(stepUnit);

        if (typeof taskIssueIDs === 'string') {
            taskIssueIDs = [taskIssueIDs];
        }

        const workPerDays = {
            workPerDaysForPeriod,
            workPerDaysByAssignments: workPerDaysByAssignmentsFromService,
            temporaryWorkPerDays,
        };
        _.forEach(assignments, (taskID, assignmentID) => {
            const stepStartDate = steps[0].clone().startOf(stepUnit);
            if (taskIssueIDs.indexOf(taskID) !== -1) {
                const { actualCompletionDate } = tasksAndIssues[taskID];
                getWorkPerDayForPeriodFromService(
                    stepStartDate,
                    stepEndDate,
                    workPerDays,
                    assignmentID,
                    {
                        actualCompletionDate,
                        showActualProgress,
                        crossedOutHoursForPeriod,
                    }
                );
            }
        });

        addInaccessibleItemsWorkPerDay(inaccessibleItemsWorkPerDay, workPerDaysForPeriod);
        workPerDaysForPeriod = recalculateDataForActiveView(
            workPerDaysForPeriod,
            stepUnit,
            steps[0]
        );
        crossedOutHoursForPeriod = recalculateDataForActiveView(
            crossedOutHoursForPeriod,
            stepUnit,
            steps[0]
        );

        return { workPerDaysForPeriod, crossedOutHoursForPeriod };
    }
)((state, { taskID, userID, projectID }) => `${userID}:${taskID || projectID}`);
