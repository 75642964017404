import moment, { Moment } from 'moment';
import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { stepsSelector } from '../../../dateRange/selectors/stepsSelector';
import { stepUnitSelector } from '../../../dateRange/selectors/stepUnitSelector';
import { ITimeOffsState, ITimeOffStateItem, IWorkDays } from '../../IDataState';
import { getDayOfWeek } from '../../../../util/utilities';
import { DATE_FORMAT } from '../../../../constants/dataConstatnts';
import { timeOffsSelectorCached } from './timeOffsSelector';
import { scheduleWorkDaysByID } from './scheduleHoursSelector/scheduleHoursSelector';

interface IWorkingDaysInWeek {
    [key: string]: number;
}

export const timeOffsSelectorForWeekMonthViewCached = createCachedSelector(
    [
        (state, { userID }) => timeOffsSelectorCached(state, { userID }),
        stepsSelector,
        (state, { scheduleID }) => scheduleWorkDaysByID(state, { scheduleID }),
        stepUnitSelector,
    ],
    (timeOffs, steps, schedule, stepUnit) => {
        if (!schedule) {
            return {};
        }
        const weekTimeOffsArr: ITimeOffStateItem[] = [];
        const weekTimeOffs = {};

        steps.forEach((step) => {
            const stepStart = step.clone().startOf(stepUnit);
            const stepEnd = step.clone().endOf(stepUnit);
            const workingDaysInWeek = getWorkingDaysInWeek(schedule, stepStart, stepEnd);

            const initialSizeOfWorkingDaysInWeek = _.size(workingDaysInWeek);

            const [hasPartiallyTimeOffInWeek, isUserTimeOff] = processWorkingDaysInWeek(
                timeOffs,
                workingDaysInWeek
            );

            // Combining sequential dates
            const sizeWorkingDaysInWeek = _.size(workingDaysInWeek);
            const partiallyTimeOffCondition =
                hasPartiallyTimeOffInWeek ||
                (initialSizeOfWorkingDaysInWeek > sizeWorkingDaysInWeek &&
                    sizeWorkingDaysInWeek > 0);

            if (!sizeWorkingDaysInWeek || partiallyTimeOffCondition) {
                const weekTimeOffsLastItem = weekTimeOffsArr[weekTimeOffsArr.length - 1];
                const endDateStr = stepEnd.format(DATE_FORMAT);

                if (
                    weekTimeOffsLastItem &&
                    moment(weekTimeOffsLastItem.endDate).add(1, 'day').format(DATE_FORMAT) ===
                        step.format(DATE_FORMAT) &&
                    !partiallyTimeOffCondition &&
                    !weekTimeOffsLastItem.isPartiallyTimeOff
                ) {
                    weekTimeOffsLastItem.endDate = endDateStr;
                    if (weekTimeOffsLastItem.isUserTimeOff !== isUserTimeOff) {
                        weekTimeOffsLastItem.isUserTimeOff = 'both';
                    }
                } else {
                    weekTimeOffsArr.push({
                        startDate: step.format(DATE_FORMAT),
                        endDate: endDateStr,
                        isUserTimeOff: isUserTimeOff || false,
                        isPartiallyTimeOff: partiallyTimeOffCondition,
                        nonWorkHours: [],
                    });
                }
            }
        });

        weekTimeOffsArr.forEach((weekTimeOffItem) => {
            weekTimeOffs[weekTimeOffItem.startDate] = { ...weekTimeOffItem };
        });

        return weekTimeOffs;
    }
)((state, { userID, scheduleID }) => `${userID}_${scheduleID || ''}`);

const getWorkingDaysInWeek = (
    schedule: IWorkDays,
    stepStart: Moment,
    stepEnd: Moment
): IWorkingDaysInWeek => {
    const workingDaysInWeek: IWorkingDaysInWeek = {};

    while (stepStart <= stepEnd) {
        const dayOfWeek = getDayOfWeek(stepStart);

        if (schedule && schedule[dayOfWeek] && schedule[dayOfWeek].length) {
            workingDaysInWeek[stepStart.format(DATE_FORMAT)] = 1;
        }

        stepStart.add(1, 'day');
    }

    return workingDaysInWeek;
};

const processWorkingDaysInWeek = (
    timeOffs: ITimeOffsState,
    workingDaysInWeek: IWorkingDaysInWeek
): [boolean, boolean | undefined | 'both'] => {
    let hasPartiallyTimeOffInWeek: boolean = false;
    let isUserTimeOff: boolean | undefined | 'both';

    _.forEach(timeOffs, (timeOffDetails, timeOffStartDate) => {
        const timeOffStep = moment(timeOffStartDate);
        const timeOffEnd = moment(timeOffDetails.endDate);

        while (timeOffStep <= timeOffEnd) {
            const timeOffStepStr = timeOffStep.format(DATE_FORMAT);

            if (workingDaysInWeek[timeOffStepStr]) {
                if (!timeOffDetails.nonWorkHours.length) {
                    delete workingDaysInWeek[timeOffStepStr];
                } else {
                    hasPartiallyTimeOffInWeek = true;
                }

                if (typeof isUserTimeOff === 'undefined') {
                    isUserTimeOff = timeOffDetails.isUserTimeOff;
                } else if (
                    (isUserTimeOff && !timeOffDetails.isUserTimeOff) ||
                    (!isUserTimeOff && timeOffDetails.isUserTimeOff)
                ) {
                    isUserTimeOff = 'both';
                }
            }

            timeOffStep.add(1, 'day');
        }
    });

    return [hasPartiallyTimeOffInWeek, isUserTimeOff];
};
