import React from 'react';
import { getChartBarGradientColors } from '../../../../../util/chartUtils';

interface IUserFocusBarChartProps extends React.HTMLAttributes<HTMLDivElement> {
    height: number;
    isOverAllocated: boolean;
    overAllocationOpacityLevel?: number;
    requiredFillColor?: string;
    x: number;
    y: number;
}

export const testIds = {
    svgWrapper: 'chart-svg-wrapper',
    linearGradient: 'chart-linear-gradient',
    rect: 'chart-rect',
};

const UserFocusBarChart: React.FunctionComponent<IUserFocusBarChartProps> = ({
    height,
    isOverAllocated,
    overAllocationOpacityLevel = 0,
    requiredFillColor,
    x,
    y,
}) => {
    const colors = requiredFillColor
        ? [requiredFillColor]
        : getChartBarGradientColors(isOverAllocated);

    let opacity: string | number | undefined;
    if (requiredFillColor) {
        opacity = 1;
    } else {
        opacity = !isOverAllocated ? '0.5' : overAllocationOpacityLevel;
    }

    let gradientId: string;
    if (requiredFillColor) {
        gradientId = 'loading';
    } else {
        gradientId = isOverAllocated ? `over_allocated_${opacity}` : `not_over_allocated`;
    }

    return (
        <>
            <svg data-testid={testIds.svgWrapper}>
                <defs>
                    <linearGradient
                        data-testid={testIds.linearGradient}
                        gradientTransform="rotate(90)"
                        id={gradientId}
                    >
                        {Object.keys(colors).map((gradKey) => (
                            <stop
                                key={gradKey}
                                offset={`${gradKey}%`}
                                stopColor={`${colors[gradKey]}`}
                            />
                        ))}
                    </linearGradient>
                </defs>
                <rect
                    data-testid={testIds.rect}
                    fill={`url('#${gradientId}')`}
                    height={height}
                    x={x}
                    y={y}
                    width="100%"
                    opacity={opacity}
                />
            </svg>
        </>
    );
};

export default UserFocusBarChart;
