import React, { ReactElement, useEffect, useRef } from 'react';
import BulkAssignmentsIcon from 'phoenix-icons/dist/BulkAssignmentsIcon.js';
import { connect } from 'react-redux';
import { css, cx } from 'react-emotion';
import { Button, Text, Tooltip } from '@phoenix/all';

import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import toggleSetting, {
    settingNames,
} from '../../../data-flow/settings/settingsActions/settingsActions';
import { testIDs } from '../../BulkAssignments/bulkAssignmentsTestIDs';
import { getByMessageKeySync } from '../../../util/utilities';
import { TIsBulkAssignmentPanelOpened } from '../../../data-flow/data/IDataState';
import { navbarItemActiveStyle, navbarItemPassiveStyle } from '../../styles/headerComponentStyles';
import { isBulkAssignmentPanelOpenedSelector } from '../../../data-flow/settings/settingsSelector';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../data-flow/types';
import { closeAllPanelsChain } from '../../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';

interface IBulkAssignmentsButtonStateProps {
    isBulkAssignmentPanelOpened: TIsBulkAssignmentPanelOpened;
}

const BulkAssignmentsButtonComponent = React.memo<
    IBulkAssignmentsButtonStateProps & IWorkSchedulingDispatchProp
>((props) => {
    const { dispatch, isBulkAssignmentPanelOpened } = props;

    const bulkAssignmentBtnRef = useRef<HTMLButtonElement | null>(null);

    const handleBulkAssignmentsPanelStateChange = (): void => {
        dispatch(
            actionChain([
                ...closeAllPanelsChain(),
                toggleSetting(
                    settingNames.isBulkAssignmentPanelOpened,
                    !isBulkAssignmentPanelOpened
                ),
            ])
        );
    };

    const bulkAssignmentMessage = getByMessageKeySync(
        'workloadbalancer.bulk.assignments',
        'Bulk Assignments'
    );

    useEffect(() => {
        let keypressHandler;
        if (isBulkAssignmentPanelOpened) {
            keypressHandler = (e: KeyboardEvent): void => {
                if (e.code === 'Escape') {
                    dispatch(toggleSetting(settingNames.isBulkAssignmentPanelOpened, false));
                    bulkAssignmentBtnRef.current?.focus();
                }
            };

            document.addEventListener('keyup', keypressHandler);
        }

        return (): void => {
            keypressHandler && document.removeEventListener('keypress', keypressHandler);
        };
        // Because this effect must work only when isBulkAssignmentPanelOpened is changing.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBulkAssignmentPanelOpened]);

    return (
        <Tooltip className={tooltipWrapper} content={bulkAssignmentMessage} delay={700}>
            <Button
                text
                iconSize={24}
                ref={bulkAssignmentBtnRef}
                aria-label={bulkAssignmentMessage}
                data-testid={testIDs.bulkAssignmentsButton}
                onClick={handleBulkAssignmentsPanelStateChange}
                icon={(): ReactElement => (
                    <BulkAssignmentsIcon className={buttonStyle} focusable="false" />
                )}
                className={cx(
                    isBulkAssignmentPanelOpened ? navbarItemActiveStyle : navbarItemPassiveStyle,
                    bulkAssignmentsWrapperClass
                )}
            >
                <Text.Regular className={textClass}>{bulkAssignmentMessage}</Text.Regular>
            </Button>
        </Tooltip>
    );
});

const mapStateToProps = (
    state: IWorkSchedulingCombinedState
): IBulkAssignmentsButtonStateProps => ({
    isBulkAssignmentPanelOpened: isBulkAssignmentPanelOpenedSelector(state),
});

export const BulkAssignmentsButton = connect(mapStateToProps)(BulkAssignmentsButtonComponent);

const buttonStyle = css`
    & path:first-child {
        fill: none !important;
        stroke: currentColor;
    }
`;

const textClass = css`
    margin-left: 4px;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const bulkAssignmentsWrapperClass = css`
    padding: 6px;
    display: flex;
    align-items: center;
    min-width: 115px;
    flex-shrink: 1;
    &:hover:enabled {
        text-decoration: none;
    }
    & button {
        flex-shtink: 1;
    }
`;

export const tooltipWrapper = css`
    display: grid;
`;
