import { TModernSchedulingAction } from '../../../types';
import { IDataState, IWorkRequiredRoleSummaryData, RoleSummaryKeys } from '../../IDataState';

export const ActionType = 'SET_ROLE_SUMMARY';

interface IRoleSummaryDataActionPayload {
    roleSummaryData: IWorkRequiredRoleSummaryData[];
    key: RoleSummaryKeys | string;
}

type TRoleSummaryDataAction = TModernSchedulingAction<IRoleSummaryDataActionPayload>;

const setRoleSummary = (roleSummaryData, key): TRoleSummaryDataAction => ({
    type: ActionType,
    payload: {
        roleSummaryData,
        key,
    },
});

export function handle(state: IDataState, { payload }: TRoleSummaryDataAction): IDataState {
    const roleSummaryData = { ...state.roleSummaryData };
    roleSummaryData[payload.key] = payload.roleSummaryData.map((roleSummary) => ({
        ...roleSummary,
    }));

    return {
        ...state,
        roleSummaryData,
    };
}

export default setRoleSummary;
