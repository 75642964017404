import { createSelector } from 'reselect';
import _ from 'lodash';
import { userIDsSelector, usersSelector } from '../users/usersSelector';
import { tasksAndIssuesSelector } from '../dataSelectors';

export const userTasksSelector = createSelector(
    [usersSelector, userIDsSelector, tasksAndIssuesSelector],
    (users, userIDs, tasks) => {
        return userIDs.reduce((acc, userID) => {
            const userTasks: any = {};
            _.forEach(users[userID].assignments, (taskID) => {
                userTasks[taskID] = tasks[taskID];
            });
            return {
                ...acc,
                [userID]: userTasks,
            };
        }, {});
    }
);
export const getUserTasks = createSelector(
    userTasksSelector,
    (usersTasks) => (userID) => usersTasks[userID]
);
