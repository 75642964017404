import * as React from 'react';
import { DivContentGrey, wrapperDivStyled } from '../../styles/sharedStyles';

export const LeftCellProjectLoading: React.FunctionComponent = () => {
    const smallOneWidth = 26;
    const marginLeft = 8;
    return (
        <div className={wrapperDivStyled} data-testid="left-cell-project-loading">
            <>
                <DivContentGrey loadingProjectItem width={smallOneWidth} />
                <DivContentGrey loadingProjectItem marginLeft={marginLeft} />
            </>
        </div>
    );
};
