import { Button, Tooltip, primary } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import FullscreenIcon from 'phoenix-icons/dist/FullscreenIcon';
import MinimizeIcon from 'phoenix-icons/dist/MinimizeIcon.js';
import screenfull from 'screenfull';
import { TMessageKey } from '../../../data-flow/data/IDataState';
import { headerIconButtonStyles } from '../../styles/headerComponentStyles';

export interface IFullScreenMode {
    onWindowResize: (top: number) => void;
}

const useFullScreenHandler = (): {
    setStylesForFullScreen: () => void;
    removeStylesForFullScreen: () => void;
} => {
    const secondaryNavElement = useRef<HTMLElement | null>();
    const navigationElement = useRef<HTMLElement | null>();
    const pageHeaderElement = useRef<HTMLElement | null>();
    const pageToolbarElement = useRef<HTMLElement | null>();

    useLayoutEffect(() => {
        secondaryNavElement.current = document.getElementById('secondary-nav');
        navigationElement.current = document.getElementById('navigation');
        pageHeaderElement.current = document.getElementById('page-header');
        pageToolbarElement.current = document.getElementById('page-toolbar');
    }, []);

    const setStylesForFullScreen = useCallback(() => {
        secondaryNavElement.current?.style.setProperty('display', 'none');
        navigationElement.current?.style.setProperty('display', 'none');
        pageHeaderElement.current?.style.setProperty('display', 'none');
        pageToolbarElement.current?.style.setProperty('background-color', primary.white());
    }, []);

    const removeStylesForFullScreen = useCallback(() => {
        secondaryNavElement.current?.style.removeProperty('display');
        navigationElement.current?.style.removeProperty('display');
        pageHeaderElement.current?.style.removeProperty('display');
        pageToolbarElement.current?.style.removeProperty('background-color');
    }, []);

    return { setStylesForFullScreen, removeStylesForFullScreen };
};

const messageKeysForFullScreen: string[] = [
    'workloadbalancer.maximize.timeline',
    'workloadbalancer.minimize.timeline',
];

const fullScreenMessageKeysAndFallbacks = {
    maximize: {
        messageKey: messageKeysForFullScreen[0],
        fallBack: 'Full screen',
    },
    minimize: {
        messageKey: messageKeysForFullScreen[1],
        fallBack: 'Exit full screen',
    },
};

const getMessageKeyAndFallbacks = (isFullScreen: boolean): TMessageKey => {
    return isFullScreen
        ? fullScreenMessageKeysAndFallbacks.minimize
        : fullScreenMessageKeysAndFallbacks.maximize;
};

export const FullScreenMode: React.FunctionComponent<IFullScreenMode> = React.memo(
    ({ onWindowResize }) => {
        const [isFullScreen, setIsFullScreen] = useState(false);

        const { setStylesForFullScreen, removeStylesForFullScreen } = useFullScreenHandler();
        const handleOnClick = useCallback(() => {
            if (screenfull.isEnabled) {
                screenfull.toggle();
            }
        }, []);

        useEffect(() => {
            if (screenfull.isEnabled) {
                const fullScreenHandler = (): void => {
                    setIsFullScreen(screenfull.isFullscreen);
                    onWindowResize(0);
                    if (screenfull.isFullscreen) {
                        setStylesForFullScreen();
                    } else {
                        removeStylesForFullScreen();
                    }

                    // Trigger window resize event to recompute react-virtualized grid
                    window.dispatchEvent(new Event('resize'));
                };
                screenfull.on('change', fullScreenHandler);
                return function cleanup() {
                    screenfull.off('change', fullScreenHandler);
                };
            }
        }, [onWindowResize, setStylesForFullScreen, removeStylesForFullScreen]);

        const fullScreenIconKeysAndFallbacks: TMessageKey = getMessageKeyAndFallbacks(isFullScreen);

        return (
            <Localization<string[]> messageKeys={messageKeysForFullScreen}>
                {({ _t }) => {
                    return (
                        <Tooltip
                            content={_t[fullScreenIconKeysAndFallbacks.messageKey](
                                fullScreenIconKeysAndFallbacks.fallBack
                            )}
                            delay={700}
                        >
                            {!isFullScreen ? (
                                <Button
                                    text
                                    data-testid="enter-fullscreen-button"
                                    onClick={handleOnClick}
                                    className={headerIconButtonStyles}
                                    aria-label={_t[fullScreenIconKeysAndFallbacks.messageKey](
                                        fullScreenIconKeysAndFallbacks.fallBack
                                    )}
                                >
                                    <FullscreenIcon focusable="false" />
                                </Button>
                            ) : (
                                <Button
                                    text
                                    data-testid="exit-fullscreen-button"
                                    onClick={handleOnClick}
                                    className={headerIconButtonStyles}
                                    aria-label={_t[fullScreenIconKeysAndFallbacks.messageKey](
                                        fullScreenIconKeysAndFallbacks.fallBack
                                    )}
                                >
                                    <MinimizeIcon focusable="false" />
                                </Button>
                            )}
                        </Tooltip>
                    );
                }}
            </Localization>
        );
    }
);
