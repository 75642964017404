import { Button, primary, Tooltip } from '@phoenix/all';
import DownCaratIcon from 'phoenix-icons/dist/DownCaratIcon.js';
import RightCaratIcon from 'phoenix-icons/dist/RightCaratIcon.js';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import mitt from 'mitt';
import { ThunkDispatch } from 'redux-thunk';
import { localizationClient } from '../../../constants/LocalizationClientFactory';
import { IWorkSchedulingCombinedState, TModernSchedulingAction } from '../../../data-flow/types';
import {
    TasksAndIssuesRecord,
    TTableDataIDs,
    TUserID,
    TUsers,
} from '../../../data-flow/data/IDataState';
import changeAllNodeArrowState from '../../../data-flow/data/nodeItemActions/changeAllNodeArrowState';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import changeNodeArrowState from '../../../data-flow/data/nodeItemActions/changeNodeArrowState';
import removeTableDataIDs from '../../../data-flow/data/assignedDataActions/removeTableDataIDs';
import changeUserNodesOffset from '../../../data-flow/data/assignedDataActions/changeOffset';
import {
    projectColorsModeSelector,
    projectGroupingModeSelector,
    showActualProgressSelector,
} from '../../../data-flow/settings/settingsSelector';
import { isColorModeProjectStatus } from '../../../util/colorUtils';
import { getUserNodesIDs } from '../../../util/dataService';
import {
    getInitialHeightsOfTables,
    getOffset,
    getRowCountRelatedToHeight,
} from '../../../util/utilities';
import { LOAD_TASKS_ISSUES_LIMIT, sectionsHeaderDataID } from '../../../constants/dataConstatnts';
import addTableDataIDs from '../../../data-flow/data/assignedDataActions/addTableDataIDs';
import { getUserTasks } from '../../../data-flow/data/selectors/reselect/getUserTasks';
import { getSchedulingAreaData } from '../../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { startDateSelector } from '../../../data-flow/dateRange/selectors/startDateSelector';
import { IAreaState } from '../../../data-flow/areaData/areaRelatedInitialDataState';
import { internalEventEmitterSelector } from '../../../data-flow/instances/internalEventEmitterSelector';
import { RECOMPUTE_GRID_SIZE } from '../../../constants/events';
import { usersSelector } from '../../../data-flow/data/selectors/users/usersSelector';
import { tableDataIDsSelector } from '../../../data-flow/data/selectors/tableDataIDsSelector';
import { arrowButtonCollapseAndExpand } from './TableHeaderStyles';
import { isProjectAccessible } from '../utils';
import { TABLE_HEADER_KEYS } from '../../../constants/tableHeaderKeys';
import { loadAssignedSectionProjectsDetailsThunk } from '../../../data-flow/thunks/assignedSectionLoadDataThunk';

interface ITableHeaderButtonProps {
    dispatch: ThunkDispatch<IWorkSchedulingCombinedState, any, TModernSchedulingAction<any>>;
}

interface ITableHeaderButtonStateProps {
    users: TUsers;
    tableDataIDs: TTableDataIDs;
    projectGroupingMode: boolean;
    projectColorsMode: string;
    getUserTasksById: (userID: string) => TasksAndIssuesRecord;
    isActualProgressBarEnabledInSettings: boolean;
    startDate: Moment;
    schedulingAreaData: IAreaState;
    internalEventEmitter: mitt.Emitter;
}

let userCount = 0;
const TableHeaderArrowButtonComponent = React.memo(
    (props: ITableHeaderButtonProps & ITableHeaderButtonStateProps): ReactElement => {
        const {
            tableDataIDs,
            users,
            dispatch,
            projectColorsMode,
            projectGroupingMode,
            getUserTasksById,
            isActualProgressBarEnabledInSettings,
            startDate,
            schedulingAreaData,
            internalEventEmitter,
        } = props;
        const [isAnyExpanded, setIsAnyExpanded] = useState(false);
        const [isAllExpanded, setIsAllExpanded] = useState(false);
        const [isUsersHasAssignments, setIsUsersHasAssignments] = useState(false);
        const tableDataIDsWithoutLoadingAndHeader = tableDataIDs.filter(
            (id) => id !== '_loading' && id !== sectionsHeaderDataID
        );
        const usersIds = Object.keys(users);
        const { peopleWorkLoadHeight } = getInitialHeightsOfTables(0);
        const offsetCount = getRowCountRelatedToHeight(peopleWorkLoadHeight);
        const isButtonEnable =
            users[usersIds[0]]?.userDataRequestsState?.isDataLoaded && isUsersHasAssignments;
        const buttonColor = primary.gray(isButtonEnable ? 700 : 300);

        useEffect(() => {
            if (usersIds.length) {
                const isLeastOneUserHasAssignment: boolean = usersIds.some(
                    (id) => users[id].hasAssignments
                );
                setIsUsersHasAssignments(isLeastOneUserHasAssignment);
            }
        }, [users, usersIds]);

        useEffect(() => {
            if (tableDataIDsWithoutLoadingAndHeader.length > usersIds.length) {
                setIsAnyExpanded(true);
            } else {
                setIsAnyExpanded(false);
            }
        }, [tableDataIDsWithoutLoadingAndHeader.length, users, usersIds.length]);

        const tooltipBtnMsgKey = isAnyExpanded
            ? TABLE_HEADER_KEYS.work_collapse
            : TABLE_HEADER_KEYS.work_expand;

        const openProjectInGroupingMode = useCallback(
            (usersIDs: TUserID[]): void => {
                dispatch(
                    loadAssignedSectionProjectsDetailsThunk(usersIDs, {
                        isUserClicked: true,
                        loadGroupRelatedEnums: isColorModeProjectStatus(projectColorsMode),
                        taskOrIssueOnTopIDs: {},
                        isForAll: true,
                        projectGroupingMode,
                    })
                );
            },
            [dispatch, projectColorsMode, projectGroupingMode]
        );

        const openProjectInNotGroupingMode = useCallback(
            (usersIDs: TUserID[]): any[] => {
                const actions: any[] = [];

                usersIDs.forEach((id) => {
                    const userTasks = getUserTasksById(users[id].ID);
                    const startDateArg = isActualProgressBarEnabledInSettings
                        ? startDate
                        : undefined;

                    const tasksIssuesIDs = getUserNodesIDs(
                        users[id].nodes,
                        schedulingAreaData,
                        userTasks,
                        startDateArg
                    );
                    const offset = getOffset(users[id].offset, LOAD_TASKS_ISSUES_LIMIT);
                    actions.push(
                        changeUserNodesOffset(users[id].ID, offset),
                        addTableDataIDs(tasksIssuesIDs.slice(0, offset), projectGroupingMode, {
                            idExpression: users[id].ID,
                            showMore: tasksIssuesIDs.length > offset,
                        })
                    );
                });

                return actions;
            },
            [
                getUserTasksById,
                isActualProgressBarEnabledInSettings,
                projectGroupingMode,
                schedulingAreaData,
                startDate,
                users,
            ]
        );

        const handleClick = useCallback((): void => {
            const actions: any = [changeAllNodeArrowState(isAnyExpanded)];
            const newUsersIds: TUserID[] = usersIds.filter((id) => users[id].hasAssignments);

            if (!isAnyExpanded) {
                if (projectGroupingMode) {
                    openProjectInGroupingMode(newUsersIds);
                } else {
                    actions.push(...openProjectInNotGroupingMode(newUsersIds));
                }
                setIsAllExpanded(true);
            } else {
                if (projectGroupingMode) {
                    tableDataIDsWithoutLoadingAndHeader
                        .filter((id) => isProjectAccessible(id))
                        .forEach((idExpression) =>
                            actions.push(changeUserNodesOffset(idExpression, 0))
                        );
                }

                for (const id of newUsersIds) {
                    actions.push(
                        changeNodeArrowState(users[id].ID, undefined, {
                            needToDeleteExpandedProjectsIDs: true,
                        }),
                        removeTableDataIDs(`${users[id].ID}_`),
                        changeUserNodesOffset(users[id].ID, 0)
                    );
                }
                setIsAllExpanded(false);
            }
            dispatch(actionChain(actions));
            internalEventEmitter.emit(RECOMPUTE_GRID_SIZE);
        }, [
            dispatch,
            isAnyExpanded,
            openProjectInGroupingMode,
            openProjectInNotGroupingMode,
            projectGroupingMode,
            internalEventEmitter,
            tableDataIDsWithoutLoadingAndHeader,
            users,
            usersIds,
        ]);

        useEffect(() => {
            const currentUsersCount = usersIds.length;

            if (
                isAllExpanded &&
                currentUsersCount > userCount &&
                offsetCount !== currentUsersCount
            ) {
                const usersIdsSlice = usersIds.slice(userCount || offsetCount);

                for (const userId of usersIdsSlice) {
                    if (!users[userId]?.userDataRequestsState?.isDataLoaded) {
                        return;
                    }
                }
                userCount = currentUsersCount;

                const usersHasAssignments = usersIdsSlice.filter(
                    (id): boolean => users[id].hasAssignments
                );

                const actions: any = [changeAllNodeArrowState(false, usersHasAssignments)];
                if (projectGroupingMode) {
                    openProjectInGroupingMode(usersHasAssignments);
                } else {
                    actions.push(...openProjectInNotGroupingMode(usersHasAssignments));
                }

                dispatch(actionChain(actions));
            }
            internalEventEmitter.emit(RECOMPUTE_GRID_SIZE);
        }, [
            dispatch,
            internalEventEmitter,
            isAllExpanded,
            offsetCount,
            openProjectInGroupingMode,
            openProjectInNotGroupingMode,
            projectGroupingMode,
            users,
            usersIds,
        ]);

        return (
            <Tooltip
                content={localizationClient.getTextSync(
                    tooltipBtnMsgKey.messageKey,
                    tooltipBtnMsgKey.fallBack
                )}
                className={arrowButtonCollapseAndExpand}
            >
                <Button
                    text
                    onClick={handleClick}
                    data-testid={
                        isAnyExpanded ? 'assigned_collapseAll_button' : 'assigned_expandAll_button'
                    }
                    disabled={!isButtonEnable}
                >
                    {isAnyExpanded ? (
                        <DownCaratIcon color={buttonColor} height={16} width={16} />
                    ) : (
                        <RightCaratIcon color={buttonColor} height={16} width={16} />
                    )}
                </Button>
            </Tooltip>
        );
    }
);

const mapStateToProps = (state: IWorkSchedulingCombinedState): ITableHeaderButtonStateProps => ({
    tableDataIDs: tableDataIDsSelector(state),
    users: usersSelector(state),
    projectGroupingMode: projectGroupingModeSelector(state),
    projectColorsMode: projectColorsModeSelector(state),
    getUserTasksById: getUserTasks(state),
    isActualProgressBarEnabledInSettings: showActualProgressSelector(state),
    schedulingAreaData: getSchedulingAreaData(state),
    startDate: startDateSelector(state),
    internalEventEmitter: internalEventEmitterSelector(state),
});

export const TableHeaderArrowButton = connect(mapStateToProps)(TableHeaderArrowButtonComponent);
