import React, { useRef, useState } from 'react';
import { Moment } from 'moment';
import { HandleClickOutside } from '@phoenix/all';
import DownCaratIcon from 'phoenix-icons/dist/DownCaratIcon.js';
import { AllocationMonthlyCompactContainer } from '@workfront/pandora-components';
import ReactDOM from 'react-dom';
import { TimeUnit } from '../../../../../constants/periodEnums';
import {
    AllocationMonthlyContainer,
    containerMinWidth,
    MonthViewContentWrapper,
} from './TableHeaderMonthContentStyles';
import {
    getEndOfPeriodClean,
    getStartOfPeriod,
    getStartOfPeriodClean,
} from '../../../../../util/utilities';
import { IWorkRequiredRoleSummaryData } from '../../../../../data-flow/data/IDataState';
import { BIG_WIDGET_SIZE, DATE_FORMAT_MONTH } from '../../../../../constants/dataConstatnts';
import { sizes } from '../../../../../constants/schedulingTableConstants';
import { modernDnDContext } from '../../../../DndWrapper';
import { useAppSelector } from '../../../../../data-flow/hooks';
import { showRoleSummarySelector } from '../../../../../data-flow/settings/settingsSelector';
import { localizationClient } from '../../../../../constants/LocalizationClientFactory';

interface ITableHeaderMonthContentProps {
    unit: Moment;
    onMonthRoleAllocationOpen: (start: Moment, end: Moment) => void;
    onMoreClick: () => void;
    toggleAbleHeader?: boolean;
    roleSummaryMonthlyLoading?: boolean;
    roleSummaryMonthData: IWorkRequiredRoleSummaryData[];
    isLastOne: boolean;
}

export const testIDs = {
    monthlyContainerWrapper: 'monthly-container-wrapper',
    monthlyContainerDataWrapper: 'monthly-container-data-wrapper',
};

const positionParams = {
    width: 0,
    left: 0,
    top: 0,
};

export const TableHeaderMonthContent: React.FunctionComponent<ITableHeaderMonthContentProps> =
    React.memo((props: ITableHeaderMonthContentProps) => {
        const {
            unit,
            toggleAbleHeader = false,
            onMonthRoleAllocationOpen,
            roleSummaryMonthlyLoading = false,
            roleSummaryMonthData = [],
            isLastOne,
        } = props;
        const divRef = useRef<HTMLDivElement>(null);

        const currentMonth: string = getStartOfPeriod(unit, TimeUnit.MONTH, DATE_FORMAT_MONTH);

        const currentMonthText = getStartOfPeriodClean(unit, TimeUnit.MONTH)
            .toDate()
            .toLocaleDateString(localizationClient.getTranslationLocale(), {
                month: 'long',
                year: 'numeric',
            });

        const [showMonthlyContainer, toggleShowMonthlyContainer] = useState(false);

        const toggleMonthlyContainerHandler = (status: boolean): void => {
            if (status) {
                onMonthRoleAllocationOpen(
                    getStartOfPeriodClean(unit, TimeUnit.MONTH),
                    getEndOfPeriodClean(unit, TimeUnit.MONTH)
                );
            }
            toggleShowMonthlyContainer(status);
        };

        const headerKeyUpHandler = (event): void => {
            if (event.key === 'Enter') {
                toggleMonthlyContainerHandler(!showMonthlyContainer);
            }
        };

        let onMoreClick;
        let monthViewAdditionalParams = {};
        if (toggleAbleHeader) {
            onMoreClick = props.onMoreClick;
            monthViewAdditionalParams = {
                tabIndex: 0,
                role: 'button',
                onClick: () => toggleMonthlyContainerHandler(!showMonthlyContainer),
                onKeyUp: headerKeyUpHandler,
            };
        }
        const isRoleSummaryOpen = useAppSelector((state) => showRoleSummarySelector(state));

        if (divRef.current) {
            const { width, left, top, right } = divRef.current.getBoundingClientRect();
            positionParams.width = width;
            positionParams.left = left;
            positionParams.top = top + sizes.tableHeaderRowHeight;
            if (isRoleSummaryOpen) {
                const rightAvailableSpaceWhenPanelIsOpen = right - BIG_WIDGET_SIZE;
                const maxWidth = Math.max(containerMinWidth, width);
                if (rightAvailableSpaceWhenPanelIsOpen > maxWidth) {
                    positionParams.left -= rightAvailableSpaceWhenPanelIsOpen - maxWidth;
                }
            }
        }

        return (
            <MonthViewContentWrapper
                toggleAble={toggleAbleHeader}
                data-testid={testIDs.monthlyContainerWrapper}
                innerRef={divRef}
                {...monthViewAdditionalParams}
            >
                <div>{currentMonthText.toUpperCase()}</div>
                {toggleAbleHeader ? <DownCaratIcon height={14} /> : null}

                {showMonthlyContainer ? (
                    <HandleClickOutside
                        onOutsideClick={() => toggleMonthlyContainerHandler(false)}
                        escapeListener
                    >
                        {() =>
                            ReactDOM.createPortal(
                                <AllocationMonthlyContainer
                                    {...positionParams}
                                    fromRight={isLastOne}
                                    data-testid={testIDs.monthlyContainerDataWrapper}
                                    onClick={(e) => e.stopPropagation()}
                                    onKeyUp={(e) => e.stopPropagation()}
                                >
                                    <AllocationMonthlyCompactContainer
                                        periodText={currentMonth}
                                        onMoreClick={onMoreClick}
                                        loading={roleSummaryMonthlyLoading}
                                        bodyRows={roleSummaryMonthData}
                                    />
                                </AllocationMonthlyContainer>,
                                document.getElementById(modernDnDContext) as HTMLElement
                            )
                        }
                    </HandleClickOutside>
                ) : null}
            </MonthViewContentWrapper>
        );
    });
