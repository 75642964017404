import createCachedSelector from 're-reselect';
import { userByIdSelector } from '../../users/usersSelector';
import { stepUnitSelector } from '../../../../dateRange/selectors/stepUnitSelector';
import { getPeriodModesValues } from '../../../../../util/periodUtils/periodUtils';
import { transFormStepDataForWeekView } from '../../../../../util/utilities';

export const purAvailableHoursSelector = createCachedSelector(
    [userByIdSelector, stepUnitSelector],
    (user, activePeriodMode) => {
        const { isWeekMode } = getPeriodModesValues(activePeriodMode);
        return !isWeekMode
            ? user.purAvailableHoursForPeriod
            : transFormStepDataForWeekView(user.purAvailableHoursForPeriod);
    }
)((state, { userID }) => `${userID}`);
