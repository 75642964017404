import { createSelector } from 'reselect';
import { getUnitOfTime } from '../../../util/utilities';
import { periodModeSelector } from './periodModeSelector';
import { startDateSelector } from './startDateSelector';
import { stepUnitSelector } from './stepUnitSelector';
import { MONTH_COUNT_IN_PERIOD, TimeUnit } from '../../../constants/periodEnums';
import { TEndDate } from '../../data/IDataState';

const endDateSelectorCache = {};

export const endDateSelector = createSelector(
    [startDateSelector, periodModeSelector, stepUnitSelector],
    (startDate, periodMode, stepUnit): TEndDate => {
        const startDateISO = startDate.toISOString();

        if (stepUnit === TimeUnit.MONTH) {
            if (!endDateSelectorCache[startDateISO]) {
                endDateSelectorCache[startDateISO] = startDate
                    .clone()
                    .add(MONTH_COUNT_IN_PERIOD - 1, TimeUnit.MONTH)
                    .endOf(TimeUnit.MONTH);
            }
            return endDateSelectorCache[startDateISO];
        }

        const cacheKey = `${startDateISO}_${periodMode}_${getUnitOfTime(stepUnit)}`;

        if (!endDateSelectorCache[cacheKey]) {
            endDateSelectorCache[cacheKey] = startDate
                .clone()
                .add(periodMode, getUnitOfTime(stepUnit))
                .subtract(1, 'day');
        }

        return endDateSelectorCache[cacheKey];
    }
);
