import { TModernSchedulingAction } from '../../types';
import { IContouredAssignments, IDataState } from '../IDataState';
import { IAssignmentsObjects } from '../../../util/dataStructures/IAssignmentaObjects';

export const ActionType = 'ADD_CONTOURED_ASSIGNMENTS';

interface IAddContouredAssignmentsPayload {
    contouredAssignments: Record<string, IAssignmentsObjects>;
}

type TAddContouredAssignmentsAction = TModernSchedulingAction<IAddContouredAssignmentsPayload>;

const addContouredAssignments = (
    contouredAssignments: IContouredAssignments
): TAddContouredAssignmentsAction => ({
    type: ActionType,
    payload: {
        contouredAssignments,
    },
});
export default addContouredAssignments;

export function handle(state: IDataState, { payload }: TAddContouredAssignmentsAction): IDataState {
    return {
        ...state,
        contouredAssignments: { ...state.contouredAssignments, ...payload.contouredAssignments },
    };
}
