import { Moment } from 'moment';
import { LOCALE_CODE } from '../../constants/LocalizationClientFactory';
import { MONTH_COUNT_IN_PERIOD, TimeUnit, WEEKS } from '../../constants/periodEnums';
import {
    DATE_FORMAT_MONTH_ONLY_SHORT,
    DATE_FORMAT_MONTH_SHORT,
    DATE_FORMAT_YEAR,
} from '../../constants/dataConstatnts';
import { UnitOfTime } from '../../data-flow/types';
import { TOrUndefined } from '../../data-flow/data/IDataState';
import { getByMessageKeySync, prepareTestId } from '../utilities';
import { testIdsPeriodModes } from '../../components/schedulingTable/header/PeriodModeButtons/PeriodModeButtonsConstants';

interface IPeriodModes {
    isDayMode: boolean;
    isWeekMode: boolean;
    isMonthMode: boolean;
}

export const getPeriodModesValues = (activePeriodMode: UnitOfTime): IPeriodModes => {
    return {
        isDayMode: activePeriodMode === TimeUnit.DAY,
        isWeekMode: activePeriodMode === TimeUnit.WEEK,
        isMonthMode: activePeriodMode === TimeUnit.MONTH,
    };
};

export const isOneWeekMode = (period: number, timeUnit: TimeUnit): boolean =>
    period === WEEKS.W_1 && timeUnit === TimeUnit.WEEK;

export const isWeekRangeMode = (period: number, timeUnit: TimeUnit): boolean =>
    period !== WEEKS.W_12 && timeUnit === TimeUnit.WEEK;

export const getActivePeriodMode = (
    period: number,
    timeUnit: TimeUnit,
    stepUnit: UnitOfTime
): TOrUndefined<TimeUnit> => {
    if (stepUnit !== TimeUnit.DAY && isOneWeekMode(period, timeUnit)) {
        return TimeUnit.DAY;
    }
    if (stepUnit === TimeUnit.MONTH && timeUnit !== TimeUnit.MONTH) {
        return TimeUnit.WEEK;
    }
    if (timeUnit === TimeUnit.MONTH) {
        return TimeUnit.MONTH;
    }
};

export interface IStartEndWeeks {
    isHalfStartWeek: boolean;
    isHalfEndWeek: boolean;
}
export const checkIsHalfWeeksForMonthView = (
    startDate: Moment,
    endDate: Moment,
    stepUnit: UnitOfTime
): IStartEndWeeks => {
    if (stepUnit !== TimeUnit.MONTH) {
        return {
            isHalfStartWeek: false,
            isHalfEndWeek: false,
        };
    }
    const startOfFirstWeek = startDate.clone().startOf(TimeUnit.WEEK);
    const endOfLastWeek = endDate.clone().endOf(TimeUnit.WEEK);
    return {
        isHalfStartWeek: startOfFirstWeek.isBefore(startDate),
        isHalfEndWeek: endOfLastWeek.isAfter(endDate),
    };
};

export const checkStartDateShouldBeChanged = (
    stepUnit: UnitOfTime,
    activePeriodMode: string
): boolean => {
    return stepUnit === TimeUnit.MONTH || activePeriodMode === TimeUnit.MONTH;
};

export function transformStepDataForMonthView(
    transformableArray: number[] | Uint16Array,
    startDate: Moment
): number[] {
    const monthsTotalWPDs: number[] = [];
    let startIndex = 0;

    for (let i = 0; i < MONTH_COUNT_IN_PERIOD; i++) {
        const sliceIndex = Math.floor(startDate.clone().daysInMonth()) + startIndex;
        const hoursOfMonth = transformableArray.slice(startIndex, sliceIndex);

        monthsTotalWPDs[i] = sumArrayHours(hoursOfMonth);
        startDate = startDate.clone().add(1, TimeUnit.MONTH);
        startIndex = sliceIndex;
    }

    return monthsTotalWPDs;
}

const sumArrayHours = (hoursOfMonth): number => {
    return hoursOfMonth.reduce((hour1, hour2) => hour1 + hour2, 0);
};

export const getFullTimeOffStepUnit = (isWeekMode): TimeUnit => {
    return isWeekMode ? TimeUnit.WEEK : TimeUnit.DAY;
};

export const getDayViewPeriodContent = (unit: Moment, key: number): string => {
    const currentMonth = getCurrentMonth(unit, key);

    if (currentMonth) {
        const currentYear = getCurrentYear(unit, key);
        const month = localizeMonth(unit);
        return `- ${month.toUpperCase()} ${currentYear}` || '';
    }
    const weeksEnd = getWeekEnd(unit);
    const previousWeekLastDay = getPreviousWeekLastDay(unit);
    const monthIsChanged = getMonthIsChanged(previousWeekLastDay, weeksEnd, key);
    const yearIsChanged = getYearIsChanged(previousWeekLastDay, weeksEnd, key);
    return formatDayViewContent(weeksEnd, yearIsChanged, monthIsChanged);
};

export const localizeMonth = (moment: Moment): string => {
    const date = new Date();
    date.setMonth(moment.month());
    const month = date.toLocaleDateString(LOCALE_CODE, { month: 'short' });
    return month;
};

export const formatDayViewContent = (
    date: Moment,
    yearIsChanged: boolean,
    monthIsChanged: boolean
): string => {
    let format;
    const year = date.format(DATE_FORMAT_YEAR);
    const month = localizeMonth(date).toUpperCase();

    if (yearIsChanged) {
        format = `- ${month} ${year}`;
    } else if (monthIsChanged) {
        format = `- ${month}`;
    }

    return format || '';
};

export const getWeekEnd = (unit: Moment): Moment => {
    return unit.clone().add(1, TimeUnit.WEEK).subtract(1, TimeUnit.DAY);
};

export const getPreviousWeekLastDay = (unit: Moment): Moment => {
    return unit.clone().subtract(1, TimeUnit.DAY);
};

export const getMonthIsChanged = (
    previousWeekLastDay: Moment,
    weeksEnd: Moment,
    key: number
): boolean => {
    return (
        key !== 0 &&
        previousWeekLastDay.format(DATE_FORMAT_MONTH_ONLY_SHORT) !==
            weeksEnd.format(DATE_FORMAT_MONTH_ONLY_SHORT)
    );
};

export const getYearIsChanged = (
    previousWeekLastDay: Moment,
    weeksEnd: Moment,
    key: number
): boolean => {
    return (
        key !== 0 &&
        previousWeekLastDay.format(DATE_FORMAT_YEAR) !== weeksEnd.format(DATE_FORMAT_YEAR)
    );
};

export const getCurrentMonth = (unit: Moment, key: number): string => {
    return key === 0 ? `- ${unit.format(DATE_FORMAT_MONTH_SHORT).toUpperCase()}` : '';
};

export const getCurrentYear = (unit: Moment, key: number): string => {
    return key === 0 ? `${unit.format(DATE_FORMAT_YEAR)}` : '';
};

export const getMonthIsChangedWeekMode = (endDateOfPeriod: Moment, unit: Moment): boolean => {
    return (
        endDateOfPeriod.format(DATE_FORMAT_MONTH_ONLY_SHORT) !==
        unit.format(DATE_FORMAT_MONTH_ONLY_SHORT)
    );
};

export const getYearIsChangedWeekMode = (endDateOfPeriod: Moment, unit: Moment): boolean => {
    return endDateOfPeriod.format(DATE_FORMAT_YEAR) !== unit.format(DATE_FORMAT_YEAR);
};

export const getSelectedPeriodModeIndex = (isDayMode, isWeekMode): number => {
    if (isDayMode) return 0;
    if (isWeekMode) return 1;
    return 2;
};

interface IPeriodModeOptionButton {
    testID: string;
    ariaLabel: string;
    value: TimeUnit;
    label: string;
    disabled?: boolean;
}

export const getPeriodModeOptions = (
    sharableLink,
    schedulingArea,
    oneWeekMode,
    weekRangeMode
): IPeriodModeOptionButton[] => [
    {
        testID: prepareTestId(testIdsPeriodModes.dayView, sharableLink, schedulingArea),
        ariaLabel: getByMessageKeySync(
            'resourcescheduling.switch.to.day.view',
            'Switch to day view'
        ),
        value: TimeUnit.DAY,
        label: getByMessageKeySync('day', 'day'),
    },
    {
        testID: prepareTestId(testIdsPeriodModes.weekView, sharableLink, schedulingArea),
        ariaLabel: getByMessageKeySync(
            'resourcescheduling.switch.to.week.view',
            'Switch to week view'
        ),
        value: TimeUnit.WEEK,
        label: getByMessageKeySync('week', 'week'),
        disabled: oneWeekMode,
    },
    {
        testID: prepareTestId(testIdsPeriodModes.monthView, sharableLink, schedulingArea),
        ariaLabel: getByMessageKeySync(
            'resourcescheduling.switch.to.month.view',
            'Switch to month view'
        ),
        value: TimeUnit.MONTH,
        label: getByMessageKeySync('month', 'month'),
        disabled: weekRangeMode,
    },
];

export const getPeriodBtnTabIndex = (isActive): number => (isActive ? 0 : -1);
