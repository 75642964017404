import { TModernSchedulingAction } from '../../../types';
import { IDataState, IRoleWorkDiff, RoleSummaryKeys } from '../../IDataState';
import { RoleSummaryHourKey } from '../../../../constants/RoleSummaryHourKey';

export const ActionType = 'UPDATE_ROLE_SUMMARY';

interface IRoleSummaryUpdateDataActionPayload {
    roleSummaryUpdateData: IRoleWorkDiff;
    key: RoleSummaryKeys;
    updatingKey: RoleSummaryHourKey;
}

type TRoleSummaryUpdateDataAction = TModernSchedulingAction<IRoleSummaryUpdateDataActionPayload>;

const updateRoleSummaryItem = (
    roleSummaryUpdateData,
    updatingKey,
    key = RoleSummaryKeys.TOTAL
): TRoleSummaryUpdateDataAction => ({
    type: ActionType,
    payload: {
        roleSummaryUpdateData,
        updatingKey,
        key,
    },
});

export function handle(state: IDataState, { payload }: TRoleSummaryUpdateDataAction): IDataState {
    const roleSummaryData = { ...state.roleSummaryData };
    const roleSummaryDataByKey = roleSummaryData[payload.key];
    const updatingOne = roleSummaryDataByKey.find((roleSummary) => {
        return roleSummary.roleID === payload.roleSummaryUpdateData.roleID;
    });

    if (
        updatingOne &&
        typeof updatingOne[payload.updatingKey] === 'number' &&
        payload.roleSummaryUpdateData.diff
    ) {
        updatingOne[payload.updatingKey]! += payload.roleSummaryUpdateData.diff;
    }

    return {
        ...state,
        roleSummaryData,
    };
}

export default updateRoleSummaryItem;
