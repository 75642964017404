import React from 'react';
import { Moment } from 'moment';
import { Localization } from '@workfront/localize-react';
import { getDateMessageKey } from '../../../../util/utilities';
import { monthsMessageKeys, monthsMessageKeysFallBacks } from '../../../../messageKeysForDate';
import {
    getMonthIsChangedWeekMode,
    getYearIsChangedWeekMode,
} from '../../../../util/periodUtils/periodUtils';
import { DATE_FORMAT_YEAR } from '../../../../constants/dataConstatnts';

interface ITableHeaderWeekContentProps {
    unit: Moment;
    endDateOfPeriod: Moment;
}

export const TableHeaderWeekContent: React.FunctionComponent<ITableHeaderWeekContentProps> =
    React.memo((props: ITableHeaderWeekContentProps) => {
        const { endDateOfPeriod, unit } = props;
        const monthIsChanged = getMonthIsChangedWeekMode(endDateOfPeriod, unit);
        const yearIsChanged = getYearIsChangedWeekMode(endDateOfPeriod, unit);

        return (
            <div>
                {monthIsChanged && (
                    <>
                        <Localization<string>
                            messageKey={getDateMessageKey(monthsMessageKeys, unit.month())}
                            fallback={getDateMessageKey(monthsMessageKeysFallBacks, unit.month())}
                        />{' '}
                        {yearIsChanged && unit.format(DATE_FORMAT_YEAR)}
                        {' - '}
                    </>
                )}
                <Localization<string>
                    messageKey={getDateMessageKey(monthsMessageKeys, endDateOfPeriod.month())}
                    fallback={getDateMessageKey(
                        monthsMessageKeysFallBacks,
                        endDateOfPeriod.month()
                    )}
                />
                {` ${endDateOfPeriod.format(DATE_FORMAT_YEAR).toUpperCase()}`}
            </div>
        );
    });
