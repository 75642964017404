import { IDataState } from '../IDataState';
import { TModernSchedulingAction } from '../../types';

export const ActionType = 'REMOVE_USERS_ADDED_BY_ASSIGNMENT';

type TRemoveUsersAddedByAssignmentAction = TModernSchedulingAction<Record<string, unknown>>;

const removeUsersAddedByAssignments = (): TRemoveUsersAddedByAssignmentAction => ({
    type: ActionType,
    payload: {},
});
export default removeUsersAddedByAssignments;

export function handle(state: IDataState) {
    return {
        ...state,
        addedUsersByAssignment: [],
    };
}
