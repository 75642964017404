import { primary, secondary } from '@phoenix/all';
import styled, { css } from 'react-emotion';
import { ModifierFn } from 'popper.js';
import {
    projectMarginBottomForActualProgressRedesign,
    sizes,
} from '../../constants/schedulingTableConstants';
import { editableAssignmentZIndex } from '../../constants/zIndexes';
import { widthOffset } from './allocationHourComponentStyles';

export type allocationsEditingModeType = boolean | string | null;

const getWrapperMargin = (
    leftPosition,
    allocationsEditingMode,
    areHoursNotEqualsBeforeContouringSave
): number => {
    const positionDeviationWhenHoursAreNotEqual = 5;
    const positionDeviationWhenHoursAreEqual = 4;
    const positionDeviationNotInEditingMode = 0;

    if (allocationsEditingMode) {
        return (
            leftPosition -
            (areHoursNotEqualsBeforeContouringSave
                ? positionDeviationWhenHoursAreNotEqual
                : positionDeviationWhenHoursAreEqual)
        ); // todo what is this ?
    }
    return (
        leftPosition - positionDeviationNotInEditingMode // todo what is this ?
    );
};

export const AssignmentWrapperStyled = styled('div')<{
    allocationsEditingMode: allocationsEditingModeType;
}>`
    z-index: 2;
    display: flex;
    position: relative;
    ${(props) => props.allocationsEditingMode && 'z-index: 4;'}
`;

const notEqualsBeforeContouringSaveStyle = (
    areHoursNotEqualsBeforeContouringSave?: boolean
): string => {
    return areHoursNotEqualsBeforeContouringSave ? `border: 1px dashed ${secondary.red(400)};` : '';
};
const allocationEditingModeStyle = (
    allocationsEditingMode?: allocationsEditingModeType,
    areHoursNotEqualsBeforeContouringSave?: boolean
): string => {
    if (!allocationsEditingMode) {
        return '';
    }
    return `align-items: center;
			position: relative;
			opacity: 1;
			z-index: ${editableAssignmentZIndex};
			box-shadow: rgba(2, 16, 27, 0.16) 0px 0px 13px 0px, rgba(13, 45, 70, 0.17) 3px 13px 16px 3px;
			transition: visibility 0s ease 0s;
			width: fit-content;
			border-radius: 3px;
			padding: ${sizes.borderWidth}px;
			background-clip: inherit;
			background-color: white;
			${notEqualsBeforeContouringSaveStyle(areHoursNotEqualsBeforeContouringSave)}
			`;
};
export const AssigmentPositioningStyled = styled('div')<{
    allocationsEditingMode?: boolean | string | null;
    areHoursNotEqualsBeforeContouringSave?: boolean;
    shouldShowActualProgress?: boolean;
}>`
    display: flex;
    ${(props) =>
        allocationEditingModeStyle(
            props.allocationsEditingMode,
            props.areHoursNotEqualsBeforeContouringSave
        )}
`;

export const AssignmentPreWrapperStyled = styled('div')<{
    allocationsEditingMode?: allocationsEditingModeType;
    areHoursNotEqualsBeforeContouringSave?: boolean;
    leftPosition: number;
    isThereBeforeArrow: boolean;
    showActualProgress?: boolean;
}>`
    display: flex;
    flex-direction: column;
    position: relative;
    ${(props) =>
        props.showActualProgress
            ? `margin-bottom: ${projectMarginBottomForActualProgressRedesign}px;`
            : ''};
    margin-left: ${(props) =>
        getWrapperMargin(
            props.leftPosition,
            props.allocationsEditingMode,
            props.areHoursNotEqualsBeforeContouringSave
        )}px;
`;

export const AllocationsStyled = styled('div')<{
    isProject?: boolean;
    isAccessible: boolean;
    isCompleted: boolean;
    allocationsEditingMode: allocationsEditingModeType;
    isFromAssignmentBar: boolean;
}>`
    position: absolute;
    top: ${(props) => (props.isProject || !props.isAccessible ? -1 : 0)}px;
    left: ${(props) => (props.isProject || !props.isAccessible ? -1 : 0)}px;
    width: ${(props) => (props.isProject || !props.isAccessible ? 'calc(100% + 2px)' : '100%')};
    color: ${(props) => (props.isAccessible ? primary.white() : primary.gray(400))};
    z-index: ${(props) => (props.isFromAssignmentBar ? 0 : 66)};
    display: flex;
    ${(props) => props.allocationsEditingMode && 'background-color: white'};
    ${(props) => (props.isCompleted ? 'opacity: 70%;' : '')};
    ${(props) =>
        props.isProject && !props.isFromAssignmentBar ? `padding: 0 ${widthOffset}px;` : ''};
`;

const allocationsEditingModeBackgroundColor = (
    allocationsEditingMode: allocationsEditingModeType,
    bgColor: string
): string => {
    return allocationsEditingMode ? `background-color: ${bgColor}` : '';
};

const getBorderRadius = (isThereAfterArrow: boolean, isThereBeforeArrow: boolean): string => {
    if (!isThereAfterArrow && !isThereBeforeArrow) {
        return '3px';
    }

    if (isThereAfterArrow && !isThereBeforeArrow) {
        return '3px 0 0 3px';
    }

    if (!isThereAfterArrow && isThereBeforeArrow) {
        return '0 3px 3px 0';
    }

    return '0';
};

export const hoursListStyle = (
    isThereAfterArrow: boolean,
    isThereBeforeArrow: boolean,
    allocationsEditingMode: allocationsEditingModeType,
    bgColor: string
): string => css`
    ${allocationsEditingModeBackgroundColor(allocationsEditingMode, bgColor)};
    border-radius: ${getBorderRadius(isThereAfterArrow, isThereBeforeArrow)};
    width: 100%;
`;

const primaryGreen = primary.gray(100);

export const allocationButtonStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 26px;
    width: 26px;
    min-width: 26px;
    padding: 0;
    background-color: white;
    cursor: pointer;
    border-radius: 3px;
    &:hover {
        background-color: ${primaryGreen};
    }
`;

export const errorStyle = css`
    border: 1px dashed ${secondary.red(400)};
    margin-left: -2px;
`;

const stylesForDifferentPlacements = (
    isArrowOrPenultimateDay: boolean,
    dataPlacement: string,
    isLastRow: boolean
): {
    top: string;
    borderBottom?: string;
    borderRadius?: string;
    boxShadow?: string;
    borderTop?: string;
} => {
    if (isArrowOrPenultimateDay) {
        if (dataPlacement === 'top-end') {
            return {
                top: '-4px',
                borderBottom: `1px solid ${primaryGreen}`,
                borderRadius: `3px 3px 0 0`,
                boxShadow: `0px 2px 0px 0px white`,
            };
        }
        return {
            top: '4px',
            borderTop: `1px solid ${primaryGreen}`,
            borderRadius: `0 0 3px 3px`,
            boxShadow: `0px -2px 0px 0px white`,
        };
    }
    return { top: `${isLastRow ? 26 : -26}px` };
};
export const allocationsModifiers = (
    isArrowOrPenultimateDay: boolean,
    isLastRow: boolean
): {
    customStyle: {
        enabled: boolean;
        fn: ModifierFn;
    };
} => {
    return {
        customStyle: {
            enabled: true,
            fn: (data) => {
                data.styles = {
                    ...data.styles,
                    zIndex: '5',
                    ...stylesForDifferentPlacements(
                        isArrowOrPenultimateDay,
                        data.placement,
                        isLastRow
                    ),
                };
                return data;
            },
        },
    };
};
