import { IDraggableAssignment } from '../../../dragAndDrop/dragAndDropConfigurations';
import { TUserID, TWorkPerDays } from '../../data/IDataState';
import { TWorkSchedulingThunkAction } from '../../types';
import {
    getAssignmentsCount,
    getFinalWorkPerDaysOnHover,
} from '../../../util/dragAndDropUtilities';
import removeFromHighlightingMode from '../../data/assignedDataActions/removeFromHighlightingMode';
import { Sections } from '../../../constants/schedulingTableConstants';
import actionChain from '../../higher-order-reducers/actionChain';
import addTemporaryWorkPerDayHoursAfterDragHover from '../../data/sharedActions/addTemporaryWorkPerDayHoursAfterDragHover';
import setIntoHighlightingMode from '../../data/assignedDataActions/setIntoHighlightingMode';
import { highlightingModeSelector } from '../../data/selectors/dataSelectors';
import { userRoleIdsSelector } from '../../data/selectors/users/usersSelector';

export const handleUserHoursUpdateOnHoverThunk = (
    item: IDraggableAssignment,
    userID: TUserID
): TWorkSchedulingThunkAction<any> => {
    return function _handleUserHoursUpdateOnHoverThunk(dispatch, getState) {
        const state = getState();
        const { workPerDays, sectionType, assignments } = item;

        const actions: any[] = [];

        const inHighlightingMode = highlightingModeSelector(state);
        const highlightedUsers = Object.keys(inHighlightingMode.user);
        if (userID !== highlightedUsers[0]) {
            actions.push(removeFromHighlightingMode(highlightedUsers));
        }
        let finalWorkPerDaysForHover: TWorkPerDays;
        if (sectionType === Sections.UNASSIGNED_WORK) {
            const userRolesIDs = userRoleIdsSelector(state, { userID });

            const assignmentsCount: number = getAssignmentsCount(assignments, userRolesIDs);
            finalWorkPerDaysForHover = getFinalWorkPerDaysOnHover(workPerDays, assignmentsCount);
        } else {
            finalWorkPerDaysForHover = workPerDays;
        }

        dispatch(
            actionChain([
                ...actions,
                addTemporaryWorkPerDayHoursAfterDragHover(finalWorkPerDaysForHover, userID),
                setIntoHighlightingMode([userID], [item.ID]),
            ])
        );
    };
};
