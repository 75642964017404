import styled from 'react-emotion';
import { css } from 'emotion';
import { primary, dropshadow, alerts } from '@phoenix/all';
import { assignmentPopoverSizes } from '../../constants/schedulingTableConstants';

export const popoverParentTaskTitle = css`
    line-height: 12px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 8px;
    height: 12px;
    letter-spacing: 1px;
`;

export const popoverClass = css`
    width: ${assignmentPopoverSizes.assignmentPopperWidth}px;
    z-index: 15;
    opacity: 0;
    transition: opacity 0.3s;
    padding-bottom: 15px;
`;

export const popoverContentClass = css`
    box-shadow: ${dropshadow.MEDIUM};
    border-radius: 4px;
`;

export const popoverLinkStyles = css`
    display: flex;
`;

export const popoverShow = css`
    opacity: 1;
`;

export const popoverArrow = css`
    border-width: 0 6.75px 9px 6.75px;
    border-color: transparent transparent ${primary.gray(0)} transparent;
    border-style: solid;
    bottom: 6px;
    position: absolute;
    transform: rotate(180deg);
    width: 0;
    left: 20px;
`;

export const popoverArrowRight = css`
    left: auto;
    right: 20px;
`;

export const popoverSeperator = css`
    flex-grow: 1;
    max-width: 16px;
`;

export const popoverButtonStyles = css`
    padding: 4px 6px;
    font-size: 1rem;
    margin: -5px 0 -5px -6px;
    font-weight: normal;
    border-radius: 3px;
    &:hover {
        background: ${primary.gray(100)};
    }
`;

export const popoverIndicatorWrapper = css`
    width: 13px;
    height: 13px;
    position: absolute;
    top: 8px;
    right: 8px;
`;

export const PopoverHeader = styled('div')`
    padding: 8px 24px 8px 16px;
    border-top-left-radius: 4px;
    min-height: 51.5px;
    border-top-right-radius: 4px;
    background-color: ${(props: { taskColor: string | undefined }): string =>
        props.taskColor || ''};
`;

export const PopoverBody = styled(`div`)`
    padding: 16px 16px 24px;
    background-color: ${primary.gray(0)};
    color: ${primary.gray(600)};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 56px;
`;

export const PopoverTopRow = styled(`div`)`
    display: flex;
`;

export const PopoverStyledColumn = styled(`div`)`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    min-height: 22px;
    & svg {
        flex-shrink: 0;
        margin-right: 4px;
    }
`;

const detectIndicatorColor = ({ canStart }: { canStart: boolean }): string => {
    return canStart ? alerts.success(400) : primary.gray(400);
};

export const PopoverReadyIndicator = styled('div')`
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: ${detectIndicatorColor};
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.8);
`;

const detectPredecessorColor = ({ isReady }: { isReady: boolean }): string => {
    return isReady ? alerts.success(400) : primary.doctorStrange();
};

export const PopoverStyledPredecessors = styled('div')`
    display: flex;
    color: ${detectPredecessorColor};
    & svg path {
        fill: ${detectPredecessorColor};
    }
`;

export const PopoverStyledLine = styled('hr')`
    margin: 12px 0;
`;
