import { createMFELogger } from '@wf-mfe/logger';
import * as packageInfo from '../../../package.json';

export class LoggerService {
    public static log: any;

    public static getInstance(): LoggerService {
        const { name } = packageInfo;
        if (!LoggerService.instance) {
            LoggerService.instance = new LoggerService();
            LoggerService.log = createMFELogger({ name });
        }

        return LoggerService.instance;
    }

    public static makeInstance(): LoggerService {
        return LoggerService.getInstance();
    }

    private static instance: LoggerService;
}
